
import asideComponent from '@/components/template/aside-user.vue'
import listAddress from '@/components/share/list-address.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'AccountTaxAddress',
  components: {
    'aside-component': asideComponent,
    'list-address': listAddress,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      invoiceAddress: [],
      pagetype: 'invoice',
      isShow: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  mounted() {
    this.getAllInvoiceData()
    // this.$utils.insertBefore('.container-overflow', 'element-warning', '5555')
  },
  beforeDestroy() {
    // this.$utils.removeElement('.element-warning')
  },
  methods: {
    async getAllInvoiceData() {
      await this.$profileProvider
        .getAllInvoiceDataById(this.userProfile.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 200) {
              let data = result.data.items
              this.invoiceAddress = data
              this.isShow =
                this.invoiceAddress.length > 0 ? data[0].isShow : false
            }
          },
          (error) => {
            console.log('error', error)
          }
        )
    },
  },
}
