import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1840364d"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default})
