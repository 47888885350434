
import DeliveryItems from '@/components/checkout/delivery-items.vue'
import InputText from '@/components/share/input-text.vue'
import ApproverDropdown from '@/components/checkout/approver-dropdown.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import SearchSelect from '@/components/share/search-select.vue'
import { mapGetters, mapActions } from 'vuex'
import modalWarning from '@/components/modal/checkout-warning'
import modalWarningShipping from '@/components/modal/warning-shipping'
import Multiselect from 'vue-multiselect'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  layout: 'checkout-nav',
  name: 'CheckoutB2B',
  components: {
    'delivery-items': DeliveryItems,
    'input-text': InputText,
    'approver-dropdown': ApproverDropdown,
    'summary-component': SummaryComponent,
    'search-select': SearchSelect,
    'modal-warning': modalWarning,
    'modal-warning-shipping': modalWarningShipping,
    Multiselect,
    modalCoupon: async () =>
      await import('@/components/suggest-coupon/modalCoupon.vue'),
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'cart',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      filter: '',
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderTemp: {},
      productGroups: [],
      selectedShipping: {},
      selectedInvoice: {},
      shippingList: [],
      invoiceList: [],
      switchLayout: false,
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        used_amt: 0,
        budget_amt: 0,
        remain_amt: 0,
        variant: 'primary',
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
      },
      totalExceptVat: 0,
      totalExcludedVat: 0,
      vat: 0,
      approval: [],
      approverLevel: 1,
      sameInvoice: false,
      contactBack: false,
      sameApprover: 'confirm',
      uploadingPOFlag: false,
      isUploadPOFail: false,
      modal: {
        warning: false,
        shipping: false,
      },
      warningMessages: [],
      popoverDelivery: false,
      popoverInvoice: false,
      warningShippingItems: [],
      t1cCardNo: '',
      isUpdateThe1Fail: false,
      isEditThe1: false,
      edit: {
        t1cCardNo: '',
      },
      is_remark_active: false,
      // approver email
      isApproveRemark: false,
      optionsApproverEmail: [],
      items_ga: [],
      isDisableButton: false,
      isModalCoupon: false,
      modalData: {},
    }
  },
  async beforeDestroy() {
    this.$nuxt.$emit('cookieShift', { isShift: false })
  },
  computed: {
    ...mapGetters('order', ['displayPaymentStep']),
    ...mapGetters('user', ['userInfo', 'userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    displayMobile() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.user_master.phones.length
      ) {
        let mobileObj = this.orderTemp.user_master.phones.find(
          (q) => q.type === 'Mobile'
        )
        return mobileObj !== undefined ? mobileObj.value : '-'
      } else return '-'
    },
    displayPhone() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.user_master.phones.length
      ) {
        let phoneObj = this.orderTemp.user_master.phones.find(
          (q) => q.type === 'Phone'
        )
        if (phoneObj !== undefined)
          return this.$utils.isValid(phoneObj.extension)
            ? `${phoneObj.value} #${phoneObj.extension}`
            : phoneObj.value
        else return '-'
      } else return '-'
    },
    // displayShippingPhone() {
    //   if (
    //     Object.keys(this.orderTemp).length &&
    //     this.orderTemp.default_shipping.phones.length
    //   ) {
    //     let phoneObj = this.orderTemp.default_shipping.phones
    //     if (phoneObj !== undefined)
    //       return this.$utils.isValid(phoneObj.extension)
    //         ? `${phoneObj.value} #${phoneObj.extension}`
    //         : phoneObj.value
    //     else return '-'
    //   } else return '-'
    // },
    displayShippingPhone() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.default_shipping.phones &&
        this.orderTemp.default_shipping.phones.length
      ) {
        let phoneObj = this.orderTemp.default_shipping.phones.find(
          (q) => q.type === 'Phone'
        )
        if (phoneObj !== undefined)
          return this.$utils.isValid(phoneObj.extension)
            ? `${phoneObj.value} #${phoneObj.extension}`
            : phoneObj.value
        else return '-'
      } else return '-'
    },
    displayShippingMobile() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.default_shipping.phones &&
        this.orderTemp.default_shipping.phones.length
      ) {
        let phoneObj = this.orderTemp.default_shipping.phones.find(
          (q) => q.type === 'Mobile'
        )
        if (phoneObj !== undefined) return phoneObj.value
        else return '-'
      } else return '-'
    },
    isEprocuerment() {
      if (Object.keys(this.orderTemp).length)
        return this.orderTemp.account_channel === 'eprocurement' ? true : false
      else return false
    },
    isAccountTypeCorporate() {
      return this.profileType === 'standard' &&
        this.userProfile.type === 'Corporate'
        ? true
        : false
    },
    isAutoApprove() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.account_channel === 'eprocurement'
      ) {
        let approverLevel1List =
          this.orderTemp.company_requesterline.approval.find(
            (q) => q.level === 1
          )
        if (approverLevel1List !== undefined) {
          console.log('approverLevel1List', approverLevel1List)
          let approverUserOpenIdList = approverLevel1List.approver.map(
            (q) => q.user_open_id
          )
          let requesterUserOpenId =
            this.orderTemp.company_requesterline.requester.user_open_id
          return approverUserOpenIdList.includes(requesterUserOpenId)
        } else return false
      } else return false
    },
    isUseBudget() {
      if (
        Object.keys(this.orderTemp).length &&
        this.orderTemp.account_channel === 'eprocurement'
      )
        return this.orderTemp.companies_budget_control_id !== null
          ? true
          : false
      else return false
    },
    isDisabled() {
      return Object.keys(this.orderTemp).length &&
        this.orderTemp.postcode_restriction !== undefined &&
        this.orderTemp.postcode_restriction.is_show_delivery_restriction
        ? true
        : false
    },
    isAccountTypePersonal() {
      return this.profileType === 'standard' &&
        this.userProfile.type === 'Personal'
        ? true
        : false
    },
  },
  async mounted() {
    if (this.$utils.isValid(this.$route.params.tempId)) {
      //get temp
      // console.log('userProfile', this.userProfile)
      if (
        this.profileType === 'standard' &&
        this.userProfile.type === 'Corporate'
      ) {
        await this.getAccountUsersAll()
      }

      await this.getOrderTemporary()
      // Cookie.remove('coupons')
    } else this.$router.push({ path: this.$i18n.path('cart') })
    // this.filter = {
    //   text: this.selectedShipping.text,
    //   value: this.selectedShipping.value,
    // }
  },
  updated() {
    this.$_onGetInsider({
      page: {
        type: 'Checkout',
        custom: {
          category_name: null,
        },
      },
    })
  },
  watch: {
    orderTemp: {
      handler: function () {
        setTimeout(() => this.onCheckProductDeveliryRestrictionModal(), 100)
      },
      deep: true,
    },
    is_remark_active: {
      handler: function () {
        if (!this.is_remark_active) {
          this.orderTemp.approver_email = ''
          this.orderTemp.remark_to_approver = ''
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('order', ['setDisplayPaymentStep']),
    async getAccountUsersAll() {
      const result = await this.$profileProvider.getAccountUsersAll(
        this.userProfile.id
      )
      if (result.status === 200) {
        console.log('getAccountUsersAll', result)
        if (result.data.items !== null && result.data.items !== undefined) {
          const usersData = result.data.items.filter((q) => q.is_user_active)
          if (usersData !== null && usersData !== undefined) {
            console.log('usersData', usersData)
            usersData.forEach((element) => {
              this.optionsApproverEmail.push({
                user_open_id: element.user_open_id,
                email: element.email,
              })
            })
          }
        }
      }
    },
    async getOrderTemporary() {
      const result = await this.$shoppingCartProvider.getOrderTemporary(
        this.$route.params.tempId
      )
      console.log('result', result)
      if (result.status === 404 || result.status === 400) {
        this.$router.push({ path: this.$i18n.path('cart') })
      }
      if (result.status === 200) {
        this.orderTemp = result.data
        this.checkDisplayPaymentStep()
        this.shippingList = []
        this.invoiceList = []
        this.t1cCardNo = this.orderTemp.t1c_no
        this.summary = {
          net_amt: this.orderTemp.summary.net_amt,
          net_deliveryfee_excvat: this.orderTemp.summary.net_deliveryfee_excvat,
          nonvat_prod_net_amt: this.orderTemp.summary.nonvat_prod_net_amt,
          vat_amt: this.orderTemp.summary.vat_amt,
          vat_prod_net_amt: this.orderTemp.summary.vat_prod_net_amt,
          voucher_disc_amt_excvat:
            this.orderTemp.summary.voucher_disc_amt_excvat,
          voucher_no: this.orderTemp?.voucher_no,
          other_disc_amt_incvat: this.orderTemp.summary.other_disc_amt_incvat,
          other_disc_amt_excvat: this.orderTemp.summary.other_disc_amt_excvat,
          payment_disc_amt_excvat:
            this.orderTemp.summary.payment_disc_amt_excvat,
          payment_disc_code: this.orderTemp.summary.payment_disc_code,
          is_use_coupon: this.orderTemp.is_use_coupon,
          used_voucher_type: this.orderTemp?.voucher_type, //used_voucher_type
          promo_disc_amt_incvat: this.orderTemp.summary?.promo_disc_amt_incvat,
          promo_disc_amt_excvat: this.orderTemp.summary?.promo_disc_amt_excvat,

          // dcc
          net_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.net_discount_delivery_fee_exc_vat,
          total_delivery_fee_exc_vat:
            this.orderTemp.summary.total_delivery_fee_exc_vat,
          segment_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.segment_discount_delivery_fee_exc_vat,
          other_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.other_discount_delivery_fee_exc_vat,
          account_segment: this.orderTemp.account_segment,
          // multi coupon
          seller_voucher_disc_amt_excvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_excvat,
          seller_voucher_disc_amt_incvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_incvat,
          seller_voucher_no: this.orderTemp.summary?.seller_voucher_no,
          seller_voucher_type: this.orderTemp.summary?.seller_voucher_type,
          delivery_fee_voucher_no: this.orderTemp?.delivery_fee_voucher_no,
          is_use_coupon_delivery: this.orderTemp?.is_use_coupon_delivery,
          warning_messages: this.orderTemp?.warning_messages,
          voucher_discount_delivery_fee_exc_vat:
            this.orderTemp?.summary?.voucher_discount_delivery_fee_exc_vat,
        }
        if (this.isEprocuerment && this.isUseBudget)
          this.budget = this.orderTemp.company_budget_control.period
        this.selectedShipping = {
          value: this.orderTemp.default_shipping.id,
          text: `${this.orderTemp.default_shipping.contact} ${this.orderTemp.default_shipping.address_1}
          ${this.orderTemp.default_shipping.address_2}`,
        }
        if (this.orderTemp.account_shippings.length) {
          this.orderTemp.account_shippings.forEach((item) => {
            this.shippingList.push({
              value: item.id,
              text: `${item.contact} \r\n${item.address_1} ${item.address_2}`,
            })
          })
        }
        if (!this.isEprocuerment) {
          this.selectedInvoice = {
            value: this.orderTemp.default_invoice.id,
            text: `${this.orderTemp.default_invoice.address_1}
            ${this.orderTemp.default_invoice.address_2} ${this.orderTemp.default_invoice.address_3} ${this.orderTemp.default_invoice.address_4}`,
          }
          if (this.orderTemp.account_invoices.length) {
            this.orderTemp.account_invoices.forEach((item) => {
              this.invoiceList.push({
                value: item.id,
                text: `${item.address_1} ${item.address_2} ${item.address_3} ${item.address_4}`,
              })
            })
          }
        }

        if (this.orderTemp.account_channel === 'eprocurement')
          this.approval = this.orderTemp.company_requesterline.approval
        this.productGroups = []
        // if (this.orderTemp.items !== null && this.orderTemp.items.length) {
        //   let groupVendorType = this.orderTemp.items.reduce((h, value) => {
        //     // console.log('h', h)
        //     // console.log('value', value)
        //     return Object.assign(h, {
        //       [value.vendor_type]: (h[value.vendor_type] || []).concat(value),
        //     })
        //   }, {})
        //   console.log('groupVendorType', groupVendorType)
        //   let groupProduct = {}
        //   Object.keys(groupVendorType).forEach((groupKey) => {
        //     if (groupKey === 'OFMStock')
        //       groupProduct[groupKey] = groupVendorType[groupKey]
        //     else {
        //       //DropShip,Marketplace
        //       let subGroup = groupVendorType[groupKey].reduce((h, value) => {
        //         return Object.assign(h, {
        //           [value.vendor_code]: (h[value.vendor_code] || []).concat(
        //             value
        //           ),
        //         })
        //       }, {})
        //       console.log(`${groupKey}`, subGroup)
        //       Object.keys(subGroup).forEach((subgroupKey) => {
        //         return (groupProduct[`${groupKey}_${subgroupKey}`] =
        //           subGroup[subgroupKey])
        //       })
        //     }
        //   })
        //   console.log('groupProduct', groupProduct)

        //   Object.keys(groupProduct).forEach((groupKey) => {
        //     return this.productGroups.push({
        //       soldBy:
        //         groupKey === 'OFMStock' || groupKey.includes('DropShip')
        //           ? 'OfficeMate'
        //           : groupProduct[groupKey][0].vendor_name[this.$i18n.locale],
        //       FulfilledBy:
        //         groupKey === 'OFMStock'
        //           ? 'OfficeMate'
        //           : groupProduct[groupKey][0].vendor_name[this.$i18n.locale],
        //       items: groupProduct[groupKey],
        //       premium: [],
        //       // shipping
        //       shippingOption: [
        //         {
        //           type: 'OFFICEMATE SHIPPING',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 0,
        //         },
        //         {
        //           type: 'BANGKOK SAMEDAY',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 80,
        //         },
        //         {
        //           type: 'EXPRESS DELEVERY',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 200,
        //         },
        //       ],
        //       summary: {
        //         // is_use_coupon: subOrder.is_use_coupon,
        //         // used_voucher_type: subOrder.used_voucher_type,
        //         promo_disc_amt_excvat: groupKey.promo_disc_amt_excvat,
        //         voucher_disc_amt_excvat: groupKey.voucher_disc_amt_excvat,

        //         nonvat_prod_net_amt: groupKey.nonvat_prod_net_amt,
        //         vat_prod_net_amt: groupKey.vat_prod_net_amt,
        //         total_delivery_fee_exc_vat: groupKey.total_delivery_fee_exc_vat,
        //         net_discount_delivery_fee_exc_vat:
        //           groupKey.net_discount_delivery_fee_exc_vat,
        //         vat_amt: groupKey.vat_amt,
        //         net_amt: groupKey.net_amt,

        //         // modal summary
        //         minimum_order_delivery_fee_exc_vat:
        //           groupKey.minimum_order_delivery_fee_exc_vat,
        //         weight_order_delivery_fee_exc_vat:
        //           groupKey.weight_order_delivery_fee_exc_vat,
        //         // total_delivery_fee_exc_vat:
        //         //   subOrder.total_delivery_fee_exc_vat,
        //         voucher_discount_delivery_fee_exc_vat:
        //           groupKey.voucher_discount_delivery_fee_exc_vat,
        //         segment_discount_delivery_fee_exc_vat:
        //           groupKey.segment_discount_delivery_fee_exc_vat,
        //         other_discount_delivery_fee_exc_vat:
        //           groupKey.other_discount_delivery_fee_exc_vat,
        //         net_deliveryfee_excvat: groupKey.net_deliveryfee_excvat,
        //       },
        //     })
        //   })
        //   this.productGroups.map((group) => {
        //     console.log('group', group)
        //     group.premiums = group.items.filter((q) => q.is_free)
        //     group.items = group.items.filter((q) => !q.is_free)
        //     if (group.items.length) {
        //       const maxLeadTime = group.items.reduce((prev, current) => {
        //         return prev.delivery_lead_time > current.delivery_lead_time
        //           ? prev
        //           : current
        //       })
        //       group.delivery_lead_time = this.$utils.formatDateExcludeTime(
        //         maxLeadTime.delivery_lead_time
        //       )
        //     }
        //     group.items.map((item) => {
        //       item.product_name = item.product_names.find(
        //         (q) => q.type === 'Online'
        //       ).display_name
        //     })
        //     if (group.premiums.length) {
        //       group.premiums.map((item) => {
        //         item.product_name = item.product_names.find(
        //           (q) => q.type === 'Online'
        //         ).display_name
        //       })
        //     }
        //   })
        // }
        let items_ga = []
        this.orderTemp.sub_orders.forEach((subOrder) => {
          items_ga.push(subOrder.items)
          this.productGroups.push({
            account_segment: this.orderTemp?.account_segment,
            soldBy: subOrder?.vendor?.sold_by?.[this.$i18n.locale],
            FulfilledBy: subOrder?.vendor?.fulfilled_by?.[this.$i18n.locale],
            // soldBy:
            //   subOrder?.vendor.vendor_type === 'OFMStock' ||
            //   subOrder?.vendor.vendor_type.includes('DropShip')
            //     ? 'OfficeMate'
            //     : subOrder?.vendor.display_name[this.$i18n.locale],
            // FulfilledBy:
            //   subOrder?.vendor.vendor_type === 'OFMStock'
            //     ? 'OfficeMate'
            //     : subOrder?.vendor?.fulfilled_by[this.$i18n.locale],
            items: subOrder.items,
            delivery_lead_time: subOrder?.vendor.delivery_lead_time,
            vendorCode: subOrder?.vendor?.code,
            vendorType: subOrder?.vendor.vendor_type,
            premiums: subOrder.premium_items, // []
            // shipping
            shippingOption: [
              {
                type: 'OFFICEMATE SHIPPING',
                description:
                  'Delivery to your preferred address in 2-5 working days',
                cost: 0,
              },
              {
                type: 'BANGKOK SAMEDAY',
                description:
                  'Delivery to your preferred address in 2-5 working days',
                cost: 80,
              },
              {
                type: 'EXPRESS DELEVERY',
                description:
                  'Delivery to your preferred address in 2-5 working days',
                cost: 200,
              },
            ],
            // dcc
            delivery_lead_time_start: subOrder.vendor?.delivery_lead_time_start,
            delivery_lead_time_end: subOrder.vendor?.delivery_lead_time_end,
            delivery_area_type: subOrder.vendor.delivery_area_type,
            summary: {
              // is_use_coupon: subOrder.is_use_coupon,
              used_voucher_type: subOrder.summary.voucher_type,
              promo_disc_amt_excvat: subOrder.summary.promo_disc_amt_excvat,
              voucher_disc_amt_excvat: subOrder.summary.voucher_disc_amt_excvat,

              nonvat_prod_net_amt: subOrder.summary.nonvat_prod_net_amt,
              vat_prod_net_amt: subOrder.summary.vat_prod_net_amt,
              total_delivery_fee_exc_vat:
                subOrder.summary.total_delivery_fee_exc_vat,
              net_discount_delivery_fee_exc_vat:
                subOrder.summary.net_discount_delivery_fee_exc_vat,
              vat_amt: subOrder.summary.vat_amt,
              net_amt: subOrder.summary.net_amt,

              // modal summary
              minimum_order_delivery_fee_exc_vat:
                subOrder.summary.minimum_order_delivery_fee_exc_vat,
              weight_order_delivery_fee_exc_vat:
                subOrder.summary.weight_order_delivery_fee_exc_vat,
              // total_delivery_fee_exc_vat:
              //   subOrder.total_delivery_fee_exc_vat,
              voucher_discount_delivery_fee_exc_vat:
                subOrder.summary.voucher_discount_delivery_fee_exc_vat,
              segment_discount_delivery_fee_exc_vat:
                subOrder.summary.segment_discount_delivery_fee_exc_vat,
              other_discount_delivery_fee_exc_vat:
                subOrder.summary.other_discount_delivery_fee_exc_vat,
              net_deliveryfee_excvat: subOrder.summary.net_deliveryfee_excvat,
              other_disc_amt_incvat: subOrder.summary.other_disc_amt_incvat,
              other_disc_amt_excvat: subOrder.summary.other_disc_amt_excvat,
              // multi coupon
              seller_voucher_disc_amt_excvat:
                subOrder.summary?.seller_voucher_disc_amt_excvat,
              seller_voucher_disc_amt_incvat:
                subOrder.summary?.seller_voucher_disc_amt_incvat,
              seller_voucher_no: subOrder.summary?.seller_voucher_no,
              seller_voucher_type: subOrder.summary?.seller_voucher_type,
              delivery_fee_voucher_no: subOrder?.delivery_fee_voucher_no,
              is_use_coupon_delivery: subOrder?.is_use_coupon_delivery,
              voucher_discount_delivery_fee_exc_vat:
                subOrder?.summary?.voucher_discount_delivery_fee_exc_vat,
            },
            // multi coupon
            seller_voucher_no: subOrder.seller_voucher_no,
            seller_voucher_type: subOrder.seller_voucher_type,
            warning_messages: subOrder.warning_messages,
          })
        })
        console.log('coupon', this.productGroups)
        // multi coupon set cookie coupon
        let couponCookie = []
        this.orderTemp.sub_orders.forEach((e) => {
          if (e.seller_voucher_no)
            couponCookie.push(
              `${e.vendor?.vendor_type}-${e.vendor?.code}-${e.seller_voucher_no}`
            )
        })
        console.log('couponCookie', couponCookie)

        Cookie.set(
          `coupons_${this.openId}-${this.userProfile.id}`,
          {
            voucher_no: this.orderTemp?.voucher_no,
            delivery_fee_voucher_no: this.orderTemp?.delivery_fee_voucher_no,
            selected_suborders: couponCookie,
          },
          {
            expires: 1,
            secure: true,
            sameSite: 'none',
          }
        )

        this.items_ga = items_ga.flat()
        console.log('ga4', this.items_ga)
        // this.productGroups.map((group) => {
        //   group.premiums = group.items.filter((q) => q.is_free)
        //   group.items = group.items.filter((q) => !q.is_free)
        //   group.items.map((item) => {
        //     item.display_price_exclude_vat = item.price_disc_excvat
        //     item.product_name = {
        //       th: item.product_name_th,
        //       en: item.product_name_en,
        //     }
        //   })
        //   if (group.premiums.length) {
        //     group.premiums.map((item) => {
        //       item.display_price_exclude_vat = item.price_disc_excvat
        //       item.product_name = {
        //         th: item.product_name_th,
        //         en: item.product_name_en,
        //       }
        //     })
        //   }
        // })

        // setTimeout(() => this.onCheckProductDeveliryRestrictionModal(), 100)
        // gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'checkout',
          basketTotal: this.$utils.formatPrice(
            this.summary.net_amt + this.summary.vat_amt,
            2,
            true
          ),
          ecommerce: {
            checkout: {
              actionField: { step: 2 },
              products: this.items_ga
                ? this.items_ga.map((item) => {
                    return {
                      id: item.sku_code,
                      price: this.$utils.formatPrice(
                        item.price_incvat,
                        2,
                        true
                      ),
                      quantity: item.quantity,
                    }
                  })
                : [],
            },
          },
        })

        // gtm GA4
        const shipping_tier = this.productGroups.map((item) => {
          return item.FulfilledBy
        })
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'add_shipping_info',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            order: {
              shipping_info: {
                recipient_name: this.orderTemp.default_shipping.contact,
                address: `${this.orderTemp.default_shipping.address_1} ${this.orderTemp.default_shipping.address_2} ${this.orderTemp.default_shipping.address_3} ${this.orderTemp.default_shipping.address_4}`,
                phone: this.displayShippingPhone,
                mobile: this.displayShippingMobile,
              },
              tax_invoice: {
                tax_invoice_name: this.orderTemp.default_invoice.address_1,
                address: `${this.orderTemp.default_invoice.address_2} ${this.orderTemp.default_invoice.address_3} ${this.orderTemp.default_invoice.address_4}`,
                tax_id_number: this.orderTemp.default_invoice.tax_id,
                the1_number: this.t1cCardNo,
                po_number: this.orderTemp.po_no,
                po_upload_success: this.orderTemp.po_upload_name ? 'yes' : 'no',
              },
              remark: {
                send_to_approver: this.is_remark_active ? 'yes' : 'no',
                approver_email: this.orderTemp.approver_email,
              },
              value: this.summary.net_amt + this.summary.vat_amt,
              coupon_discount: this.summary.voucher_disc_amt_excvat,
              coupon: this.summary.voucher_no,
              total_price_no_tax: this.summary.nonvat_prod_net_amt,
              total_price_before_tax: this.summary.vat_prod_net_amt,
              shipping: this.summary.net_deliveryfee_excvat || 0.0,
              tax: this.summary.vat_amt,
              total_discount: this.summary.other_disc_amt_incvat,
            },
            currency: 'THB',
            value: this.summary.net_amt + this.summary.vat_amt,
            shipping_tier: shipping_tier.join(','),
            items: this.items_ga.map((item, index) => {
              return {
                item_id: item.sku_code,
                item_name: item.product_names[1]?.display_name.th,
                index: index,
                item_brand: item.brand?.display_name.en,
                item_category: item.categories[2]?.display_name.en,
                item_category2: item.categories[1]?.display_name.en,
                item_category3: item.categories[0]?.display_name.en,
                price: item.price_incvat,
                discount: item.price_disc_incvat - item.price_incvat,
                quantity: item.quantity,
                ...this.$store.getters['user/userPromotionTrack'],
                item_list_id:
                  (item.is_flash_sale && 'flash_sale') ||
                  (item.is_promotion && 'promotions') ||
                  '',
                item_list_name:
                  (item.is_flash_sale && 'flash sale') ||
                  (item.is_promotion && 'Promotions') ||
                  '',
              }
            }),
          },
        })
      }
    },
    async checkDisplayPaymentStep() {
      let result = false
      if (this.isEprocuerment) {
        result = this.orderTemp.company_master.is_use_cash_payment
          ? true
          : false
      } else result = true
      this.setDisplayPaymentStep(result)
    },
    async onSelectedShipping(event) {
      console.log(event)
      this.popoverDelivery = false
      await this.updateOrderTemporaryShipping(event.value)
    },
    async onSelectedInvoice(event) {
      // console.log(event)
      this.popoverInvoice = false
      await this.updateOrderTemporaryInvoice(event.value)
    },
    async updateOrderTemporaryDetails() {
      this.isDisableButton = true
      let apiData = JSON.stringify({
        po_no: this.orderTemp.po_no,
        delivery_remark: this.orderTemp.delivery_remark,
        approver_email: this.orderTemp.approver_email,
        remark_to_approver: this.orderTemp.remark_to_approver,
        is_auto_approval: this.orderTemp.is_auto_approval,
        is_request_call_back: this.orderTemp.is_request_call_back,
      })
      console.log('apiData', apiData)
      const result =
        await this.$shoppingCartProvider.updateOrderTemporaryDetails(
          this.orderTemp.id,
          apiData
        )
      console.log('updateOrderTemporaryDetails', result)
      if (result.status === 204) {
        // Cookie.remove('coupons')
        if (this.displayPaymentStep) {
          this.$router.push({
            path: this.$i18n.path('checkout/payment'),
            name:
              this.$i18n.locale === 'th'
                ? 'checkout-payment-tempId'
                : 'lang-checkout-payment-tempId',
            params: { tempId: this.$route.params.tempId },
          })
        } else {
          this.createOrder()
        }
      } else {
        this.onCheckProductDeveliryRestrictionModal()
        this.isDisableButton = false
      }
    },
    async createOrder() {
      //gtm
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'checkout',
        basketTotal: this.$utils.formatPrice(
          this.summary.net_amt + this.summary.vat_amt,
          2,
          true
        ),
        ecommerce: {
          checkout: {
            actionField: { step: 4, option: 'Credit' },
            products: this.items_ga
              ? this.items_ga.map((item) => {
                  return {
                    id: item.sku_code,
                    price: this.$utils.formatPrice(item.price_incvat, 2, true),
                    quantity: item.quantity,
                  }
                })
              : [],
          },
        },
      })

      let apiData = JSON.stringify({
        temp_id: this.$route.params.tempId,
        user_id: this.openId,
        user_name: `${this.userInfo.first_name.th} ${this.userInfo.last_name.th}`,
        email: `${this.userInfo.email}`,
      })
      await this.$orderProvider.createOrder(apiData).then((result) => {
        if (result.status === 201) {
          console.log(result.data)
          if (
            result.data.warning_messages.length != null &&
            result.data.warning_messages.length
          ) {
            this.modal.warning = true
            this.warningMessages = result.data.warning_messages
          } else {
            this.$router.push({
              path: this.$i18n.path('checkout/complete'),
              name: 'checkout-complete-orderId',
              params: { orderId: result.data.order_id },
            })
          }
        } else if (result.status === 412) {
          if (result.data.message) {
            this.modal.warning = true
            this.warningMessages.push({ message: result.data.message })
          }
        }
      })
    },
    async updateOrderTemporaryShipping(shipId) {
      // console.log('shipId', shipId)
      const result =
        await this.$shoppingCartProvider.updateOrderTemporaryShipping(
          this.$route.params.tempId,
          shipId
        )
      if (result.status === 204) {
        await this.getOrderTemporary()
      }
    },
    async updateOrderTemporaryInvoice(invoiceId) {
      const result =
        await this.$shoppingCartProvider.updateOrderTemporaryInvoice(
          this.$route.params.tempId,
          invoiceId
        )
      if (result.status === 204) {
        await this.getOrderTemporary()
      }
    },
    onSubmitCheckout() {
      this.$refs.orderTempDetailForm.validate().then((result) => {
        if (result) {
          this.updateOrderTemporaryDetails()
          Cookie.set(
            `coupons_${this.openId}-${this.userProfile.id}`,
            {
              voucher_no: '',
              delivery_fee_voucher_no: '',
              selected_suborders: [],
            },
            {
              expires: 1,
              secure: true,
              sameSite: 'none',
            }
          )
          // gtm GA4
          const shipping_tier = this.productGroups.map((item) => {
            return item.FulfilledBy
          })
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'confirmed_order',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              value: this.summary.net_amt + this.summary.vat_amt,
              shipping_tier: shipping_tier.join(','),
              items: this.items_ga.map((item, index) => {
                return {
                  item_id: item.sku_code,
                  item_name: item.product_names[1]?.display_name.th,
                  index: index,
                  item_brand: item.brand?.display_name.en,
                  item_category: item.categories[2]?.display_name.en,
                  item_category2: item.categories[1]?.display_name.en,
                  item_category3: item.categories[0]?.display_name.en,
                  price: item.price_incvat,
                  discount: item.price_disc_incvat - item.price_incvat,
                  quantity: item.quantity,
                  ...this.$store.getters['user/userPromotionTrack'],
                }
              }),
            },
          })
        }
      })
    },
    getFile() {
      this.approve.fileName = ''
      document.getElementById('file').value = ''
      document.getElementById('file').click()
    },
    async importFile(e) {
      // if (this.createModel.items.length <= 0) {
      // this.approve.fileName = e.target.files[0].name
      console.log(e.target.files[0])
      if (e.target.files.length) {
        await this.$refs['uploadFile']
          .validate(e.target.files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              await this.uploadPOFile(e.target.files[0])
            } else console.log('not valid')
          })
      }
    },
    async uploadPOFile(file) {
      this.uploadingPOFlag = true
      let formData = new FormData()
      formData.append('po_upload_name', file.name)
      formData.append('file', file)

      formData.append('content_description', 'test upload file')
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      }
      await this.$shoppingCartProvider
        .uploadPOFile(this.orderTemp.id, formData, config, false)
        .then(
          (result) => {
            console.log(result)
            if (result.status === 201) {
              if (result.data !== '') {
                this.orderTemp.po_upload_name = file.name
                this.orderTemp.po_upload_url = result.data
                this.isUploadPOFail = false
              } else this.isUploadPOFail = true
              // this.getOrderTemporary()
            } else {
              this.isUploadPOFail = true
            }
          },
          (error) => {
            console.log('uploadFile', error.responsed)
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.uploadingPOFlag = false
          }, 100)
        })
    },
    async removePOFile() {
      let apiData = JSON.stringify({
        is_remove_po_file: true,
      })

      const result =
        await this.$shoppingCartProvider.updateOrderTemporaryDetails(
          this.orderTemp.id,
          apiData
        )
      if (result.status === 204) {
        this.orderTemp.po_upload_name = ''
        this.orderTemp.po_upload_url = ''
      }
    },
    viewPOFile() {
      window.open(this.orderTemp.po_upload_url, '_blank')
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      // console.log(this.approverLevel)
    },
    onSelectApprover(value) {
      this.selectedApprover = value
      // console.log(this.selectedApprover)
    },
    onConfirmModalWarning() {
      this.$router.push({
        path: this.$i18n.path('cart'),
      })
    },
    onConfirmModalWarningShipping() {
      this.warningShippingItems = []
      this.modal.shipping = false
    },
    onCheckProductDeveliryRestrictionModal() {
      if (
        this.orderTemp.postcode_restriction !== undefined &&
        this.orderTemp.postcode_restriction.is_show_delivery_restriction
      ) {
        this.modal.shipping = true
        this.warningShippingItems =
          // this.orderTemp.postcode_restriction.orderTempItems
          this.orderTemp.postcode_restriction.order_temporary_items
      }
    },
    onSetCookieShift(height) {
      if (height > 0) {
        this.$nuxt.$emit('cookieShift', {
          isShift: true,
          height: height,
        })
      }
    },
    onSelectApproverEmail(e) {
      console.log('ApproverEmail', e)
      this.orderTemp.approver_email = e.email
    },
    async onRemoveVoucher(payload) {
      const {
        voucher_no,
        delivery_fee_voucher_no,
        // isSellerCoupon,
        seller,
        type,
      } = payload
      const voucher_type = seller?.voucher_type
      let coupon_type = type
      const vendor_code =
        coupon_type === 'Seller' || coupon_type === 'DropShip'
          ? seller?.vendor_code
          : ''

      if (!type) {
        if (voucher_no) {
          coupon_type = 'Global'
        }
        if (delivery_fee_voucher_no) {
          coupon_type = 'Delivery'
        }
      }

      let select_voucher = []
      if (voucher_no) {
        select_voucher.push({
          op: 'remove', //<required> <option:"add,remove">
          voucher_type: coupon_type, //<required> <option:"Global,Seller,Delivery">
          voucher_no: voucher_no, //<required>
          vendor_type: voucher_type || '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
          vendor_code: vendor_code, //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
        })
      }
      if (delivery_fee_voucher_no) {
        select_voucher.push({
          op: 'remove', //<required> <option:"add,remove">
          voucher_type: coupon_type, //<required> <option:"Global,Seller,Delivery">
          voucher_no: delivery_fee_voucher_no, //<required>
          vendor_type: voucher_type || '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
          vendor_code: vendor_code || '', //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
        })
      }
      await this.onUpdateCalculateCart(select_voucher)
    },
    async onRemoveSubVoucher(payload) {
      console.log('onRemoveSubVoucher', payload)
      const { seller_voucher_no, vendorCode, vendorType } = payload
      let select_voucher = [
        {
          op: 'remove',
          voucher_type: 'Seller',
          voucher_no: seller_voucher_no,
          vendor_type: vendorType,
          vendor_code: vendorCode,
        },
      ]

      await this.onUpdateCalculateCart(select_voucher)
    },
    // async onSubmitCoupon(payload) {
    // console.log('onSubmitCoupon', payload)
    // const {
    //   voucher_no,
    //   delivery_fee_voucher_no,
    //   // isSellerCoupon,
    //   seller,
    //   type,
    // } = payload
    // let voucher_type = seller?.voucher_type
    // let coupon_type = type
    // if (!type) {
    //   if (voucher_no) {
    //     coupon_type = 'Global'
    //   }
    //   // if (delivery_fee_voucher_no) {
    //   //   coupon_type = 'Delivery'
    //   // }
    // }

    // let select_voucher = []
    // // all coupon
    // if (voucher_no) {
    //   select_voucher.push({
    //     op: 'add', //<required> <option:"add,remove">
    //     voucher_type: coupon_type, //<required> <option:"Global,Seller,Delivery">
    //     voucher_no: voucher_no, //<required>
    //     vendor_type: voucher_type || '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
    //     vendor_code: voucher_type || '', //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
    //   })
    // } else {
    //   // remove global coupon from coupon modal
    //   if (this.orderTemp.voucher_no)
    //     select_voucher.push({
    //       op: 'remove', //<required> <option:"add,remove">
    //       voucher_type: 'Global', //<required> <option:"Global,Seller,Delivery">
    //       voucher_no: this.orderTemp.voucher_no, //<required>
    //       vendor_type: '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
    //       vendor_code: '', //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
    //     })
    // }

    // // only delivery
    // if (delivery_fee_voucher_no) {
    //   select_voucher.push({
    //     op: 'add', //<required> <option:"add,remove">
    //     voucher_type: 'Delivery', //<required> <option:"Global,Seller,Delivery">
    //     voucher_no: delivery_fee_voucher_no, //<required>
    //     vendor_type: voucher_type || '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
    //     vendor_code: voucher_type || '', //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
    //   })
    // } else {
    //   // remove delivery coupon from coupon modal
    //   if (this.orderTemp.delivery_fee_voucher_no)
    //     select_voucher.push({
    //       op: 'remove', //<required> <option:"add,remove">
    //       voucher_type: 'Delivery', //<required> <option:"Global,Seller,Delivery">
    //       voucher_no: this.orderTemp.delivery_fee_voucher_no, //<required>
    //       vendor_type: '', // <required: if voucher_type = 'Seller'><options: "OFMStock", "DropShip">
    //       vendor_code: '', //<required: if voucher_type = 'Seller' and vendor_type = 'DropShip'>
    //     })
    // }

    // await this.onUpdateCalculateCart(select_voucher)
    // },
    async onSubmitCoupon(payload) {
      console.log('onSubmitCoupon', payload)

      const { voucher_no, delivery_fee_voucher_no, seller, type } = payload
      const voucher_type = seller?.voucher_type || ''
      const coupon_type = type || (voucher_no ? 'Global' : '')
      const vendor_code =
        coupon_type === 'Seller' || coupon_type === 'DropShip'
          ? seller?.code
          : ''
      let select_voucher = []

      // Global coupon handling
      if (voucher_no) {
        select_voucher.push({
          op: 'add',
          voucher_type: coupon_type,
          voucher_no,
          vendor_type: voucher_type,
          vendor_code: vendor_code,
        })
      } else if (this.orderTemp.voucher_no) {
        select_voucher.push({
          op: 'remove',
          voucher_type: 'Global',
          voucher_no: this.orderTemp.voucher_no,
          vendor_type: '',
          vendor_code: '',
        })
      }

      // Delivery coupon handling
      if (!type)
        if (delivery_fee_voucher_no) {
          select_voucher.push({
            op: 'add',
            voucher_type: 'Delivery',
            voucher_no: delivery_fee_voucher_no,
            vendor_type: voucher_type,
            vendor_code: vendor_code,
          })
        } else if (this.orderTemp.delivery_fee_voucher_no) {
          select_voucher.push({
            op: 'remove',
            voucher_type: 'Delivery',
            voucher_no: this.orderTemp.delivery_fee_voucher_no,
            vendor_type: '',
            vendor_code: '',
          })
        }

      await this.onUpdateCalculateCart(select_voucher)
    },
    async onUpdateCalculateCart(select_voucher) {
      let body = {
        profile_id: this.userProfile.id,
        requesterline_id: this.orderTemp.companies_requester_line_id || '',
        is_use_budget_control: this.userProfile.is_use_budget_control,
        coupons: select_voucher,
      }

      const result = await this.$shoppingCartProvider.updateCalculateCart(
        `${this.$route.params.tempId}`,
        body
      )
      if (result.status === 204) {
        // remove cookie coupon
        await this.getOrderTemporary()
      }
      console.log('updateCalculateCart', result)
    },
    onModalCoupon(e) {
      this.isModalCoupon = true
      this.modalData = e
    },
  },
}
