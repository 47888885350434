
import { mapGetters } from 'vuex'

export default {
  props: {
    segment: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    shippingObj: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      group: {},
      // isModalOpen: false
    }
  },
  // created() {
  //   if (process.client) {
  //     this.$nuxt.$on('modalShippingItems', (data, isModalOpen) => {
  //       if (!this.isModalOpen && isModalOpen) {
  //         this.$nextTick(() => {
  //           this.onReset()
  //           this.$refs['modal-shipping-items'].show()
  //           this.group = { ...data }
  //           console.log('groups', data)
  //           this.isModalOpen = true
  //         })
  //       }
  //     })
  //   }
  // },
  watch: {
    show: function (value) {
      if (value) {
        this.$nextTick(() => {
          // this.onReset()
          this.group = { ...this.shippingObj }
          this.$refs['modal-shipping-items'].show()

          // console.log('groups', data)
          // this.isModalOpen = true
        })
      } else {
        this.$refs['modal-shipping-items'].hide()
      }
    },
  },
  // beforeDestroy() {
  //   if (process.client) {
  //     this.$nuxt.$off('modalShippingItems')
  //   }
  // },
  computed: {
    ...mapGetters('user', [
      'userProfile',
      // 'userInfo',
      'profileType',
      // 'userCostcenter',
    ]),
    isShowNetDeliveryfeeExcvat() {
      let isShow = true
      if (
        this.group.summary.voucher_discount_delivery_fee_exc_vat === 0 &&
        this.group.summary.segment_discount_delivery_fee_exc_vat === 0 &&
        this.group.summary.other_discount_delivery_fee_exc_vat === 0
      ) {
        isShow = false
      }

      return isShow
    },
  },
  mounted() {
    // this.$refs['modal-shipping'].show()
    // this.onReset()
  },
  methods: {
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    // onDeliveryArea(type) {
    //   let delivery = ''
    //   if (this.group.vendorType === 'DropShip') {
    //     if (this.group.delivery_area_type === 'BKK') {
    //       delivery = this.$t.summaryDCC.drop_ship.bkk
    //     } else if (this.group.delivery_area_type === 'UPC') {
    //       delivery = this.$t.summaryDCC.drop_ship.upc
    //     } else if (this.group.delivery_area_type === 'REMOTE') {
    //       delivery = this.$t.summaryDCC.drop_ship.remote
    //     }
    //   } else {
    //     if (this.group.delivery_area_type === 'BKK') {
    //     } else if (this.group.delivery_area_type === 'UPC') {
    //     } else if (this.group.delivery_area_type === 'REMOTE') {
    //     }
    //   }
    //   delivery = this.$t.summaryDCC[type][this.group.delivery_area_type]
    // },
    // onReset() {},
    onClose() {
      this.$refs['modal-shipping-items'].hide()
      this.$emit('hidden')
      // this.isModalOpen = false
      // setTimeout(() => {
      //   this.onReset()
      // }, 500)
    },
  },
}
