
import ModalWarning from '@/components/modal/modalWarning.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListOrderComponent',
  components: {
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    ModalWarning,
  },
  props: {
    isApprove: {
      type: Boolean,
      default: false,
    },
    typeOrder: {
      type: String,
      default: '',
    },
    orderDocument: {
      type: Object,
      default: {
        pageNo: 1,
        pageSize: 20,
        items: [],
        totalRecords: 0,
        totalPages: 0,
        pageIndex: 1,
      },
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      maxItemslength: 0,
      document: {
        pageNo: 1,
        pageSize: 20,
        items: [],
        totalRecords: 0,
        totalPages: 0,
        pageIndex: 1,
      },
      filter: {
        startDate: '',
        endDate: '',
        orderId: '',
        pageNo: 1,
        pageSize: 20,
        pageIndex: 1,
      },
      loadFlag: true,
      budget: {
        period: {},
      },
      onlyApproveFlag: false,
      warningMessages: [],
      modalWarning: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'profileType']),
    ...mapGetters('authen', ['openId']),
    isSelectedAll() {
      let checkItem = this.items.filter((q) => q.isChecked)
      console.log('checkItem', checkItem)
      return checkItem.length === this.items.length ? true : false
    },
    filterItems() {
      if (this.items.length) {
        if (this.typeOrder === 'waiting') {
          if (this.onlyApproveFlag) {
            return this.items.filter((q) => q.hasApprove)
          } else return this.items
        } else return this.items
      } else return []
    },
  },
  watch: {
    // isApprove: {
    //   handler: function (val) {
    //     console.log(val)
    //   },
    //   deep: true,
    // },
    // typeOrder: {
    //   handler: function (val) {
    //     this.page = 0
    //     this.items = []
    //     this.filter = ''
    //     this.onLoadItems(val)
    //   },
    //   deep: true,
    // },
    orderDocument: {
      handler: function () {
        this.document = Object.assign({}, this.orderDocument)
        this.maxItemslength = this.document.totalRecords
        this.filter.pageNo = this.document.pageNo
        this.filter.pageSize = this.document.pageSize
        if (this.document.items.length) {
          this.items = this.document.items
        } else {
          this.items = []
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.lazyLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoad)
  },
  created() {
    // this.onLoadItems('order')
  },
  mounted() {
    this.document = Object.assign({}, this.orderDocument)
    this.maxItemslength = this.document.totalRecords
    this.filter.pageNo = this.document.pageNo
    this.filter.pageSize = this.document.pageSize
    if (this.document.items.length) {
      this.items = this.document.items
    } else {
      this.items = []
    }
  },
  methods: {
    resetLoadFlag() {
      this.loadFlag = true
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    onSelectAll(event) {
      console.log('event', event)
      this.items.forEach((item) => {
        if (item.hasApprove) item.isChecked = event
      })
    },
    onCkecked(orderId) {
      let checked = this.items[
        this.items.findIndex((e) => e.order_id === orderId)
      ].isChecked
        ? true
        : false
      return checked
    },
    onSelectOrder(event, orderId) {
      this.items[
        this.items.findIndex((e) => e.order_id === orderId)
      ].isChecked = event
    },
    async onExpand(index, order) {
      const element = document.querySelectorAll('.media-order')[index]
      if (
        element.querySelectorAll('.collapse-transition')[0].clientHeight === 0
      )
        this.getOrderBudget(order)

      this.$nextTick(() => {
        element.classList.toggle('active')
        // element.querySelectorAll('.collapse-transition')[0].style.height =
        //   element.querySelectorAll('.collapse-transition')[0].clientHeight > 0
        //     ? '0px'
        //     : '104px'
      })
    },
    async getOrderBudget(order) {
      const result = await this.$orderProvider.getOrderBudget(
        order.approval_information.companies_budget_control_id,
        order.company_id,
        false
      )
      if (result.status === 200) {
        order.budget = result.data
        order.budget = {
          id: result.data.id,
          period: {
            used_amt:
              result.data.period !== null ? result.data.period.used_amt : 0,
            budget_amt:
              result.data.period !== null ? result.data.period.budget_amt : 0,
            remain_amt:
              result.data.period !== null ? result.data.period.remain_amt : 0,
            original_amt:
              result.data.period !== null ? result.data.period.original_amt : 0,
          },
          sum_order_total_amt: result.data.sum_order_total_amt,
          control_level: result.data.control_level,
          period_type: result.data.period_type,
          variant: 'primary',
        }
        // this.document.items.map((item) => {
        //   if (item.order_id === order.order_id) item.budget = result.data
        // })
      }
    },
    imgCount(object) {
      return object.filter((i, index) => index <= 3)
    },
    onFilter() {
      this.filter.pageNo = 1
      this.$emit('filter', this.filter, false)
    },
    async onApproveAll() {
      // console.log('approve all')
      let filterOrder = this.items.filter((q) => q.isChecked)
      if (filterOrder.length) {
        let apiData = JSON.stringify({
          documents: filterOrder.map((item) => {
            return {
              order_object_id: item.id,
              order_id: item.order_id,
              action: 'Approve',
            }
          }),
          user_id: this.openId,
          remark: '',
        })
        console.log('apiData', apiData)
        await this.approveOrders(apiData)
      }
    },
    async onApprove(order) {
      if (order !== undefined) {
        let apiData = JSON.stringify({
          documents: [
            {
              order_object_id: order.id,
              order_id: order.order_id,
              action: 'Approve',
            },
          ],
          user_id: this.openId,
          remark: '',
        })
        await this.approveOrders(apiData)
      }
    },
    async approveOrders(body) {
      const result = await this.$orderProvider.approveOrders(body)
      console.log(result)
      if (result.status === 200) {
        if (!result.data.warning_messages.length) {
          this.onFilter()
        } else {
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
      }
    },
    dateRangeSelected(event) {
      console.log(event)
      if (event.from !== '' && event.to !== '') {
        this.filter.startDate = event.from
        this.filter.endDate = event.to
        this.filter.startDate = this.$utils.formatDateExcludeTime(
          this.filter.startDate,
          'YYYY-MM-DD:HH:mm:ss ZZ'
        )
        this.filter.endDate = this.$utils.formatDateExcludeTime(
          this.filter.endDate,
          'YYYY-MM-DD:HH:mm:ss ZZ'
        )
      } else {
        this.filter.startDate = null
        this.filter.endDate = null
      }
    },
    async lazyLoad() {
      // console.log(Math.ceil(this.maxItemslength / 5))
      if (this.typeOrder !== 'waiting') {
        if (this.items.length < this.maxItemslength && this.items.length)
          if (
            document.getElementById('load-items').getBoundingClientRect().y <
              window.innerHeight - 200 &&
            this.loadFlag
          )
            if (this.document.pageNo < this.document.totalPages) {
              this.loadFlag = false
              await this.$emit('filter', this.filter, true)
            }
      }
    },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    viewOrderDetail(orderId) {
      this.$router.push({
        path: this.$i18n.path('account/order'),
        name:
          this.$i18n.locale === 'th'
            ? 'account-order-id'
            : 'lang-account-order-id',
        params: { id: orderId },
        query: { mode: 'view' },
      })
    },
  },
}
