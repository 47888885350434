
import SliderBanners from '@/components/share/slider-banners.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PromotionsPage',
  components: {
    SliderBanners,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      images: [],
      banner_sub1: {
        src: '',
        url: '',
        title: '',
      },
      banner_sub2: {
        src: '',
        url: '',
        title: '',
      },
      banner_campaign: [],
      isBannerHome: false,
      isBannerCampaigns: false,
      skeletonPage: true,
      count: 6,
      currentPage: 1,
      pageSize: 12,
      loadFlag: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  beforeMount() {
    window.addEventListener('scroll', this.lazyLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoad)
  },
  mounted() {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    this.getฺBannerHome()
    this.getBannerCampaigns()
  },
  updated() {
    this.skeletonPage = false
    this.$_onGetInsider({
      page: {
        type: 'Promotion',
        custom: {
          category_name: null,
        },
      },
    })
  },
  methods: {
    async lazyLoad() {
      if (this.loadFlag)
        if (
          window.innerHeight -
            this.$refs['ref-promotions'].getBoundingClientRect().bottom >
          -120
        ) {
          this.loadFlag = false
          await this.getBannerCampaigns()
        }
    },
    async getฺBannerHome() {
      // this.isBannerHome = false
      let params = this.createProfileParams()
      await this.$profileProvider.getฺBannerHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('banner', data)
            let hero = data
              .filter((a) => a.position === 'hero')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (hero.length > 0) {
              hero.forEach((e, index) => {
                this.images.push({
                  alt: e.title,
                  url: e.path_url,
                  path: e.image.url,
                  asset: false,
                  title: e.title,
                  gtmSeq: index + 1,
                  active_from: e.active_from,
                  active_to: e.active_to,
                })
              })
              console.log('object', this.images)
            }
            // let sub1 = data.find(
            //   (a) => a.position === 'sub1' && a.sequence === 1
            // )
            // if (sub1 !== undefined) {
            //   this.banner_sub1 = {
            //     src: sub1.image.url,
            //     url: sub1.path_url,
            //     title: sub1.title,
            //   }
            // }
            // let sub2 = data.find(
            //   (a) => a.position === 'sub2' && a.sequence === 1
            // )
            // if (sub2 !== undefined) {
            //   this.banner_sub2 = {
            //     src: sub2.image.url,
            //     url: sub2.path_url,
            //     title: sub2.title,
            //   }
            // }
            let sub1 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'sub1')
            if (sub1 !== undefined) {
              this.banner_sub1 = {
                src: sub1.image.url,
                url: sub1.path_url,
                title: sub1.title,
                alt: sub1.title,
                gtmSeq: 1,
                active_from: sub1.active_from,
                active_to: sub1.active_to,
              }
            }
            console.log('sub1', sub1)
            let sub2 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'sub2')
            if (sub2 !== undefined) {
              this.banner_sub2 = {
                src: sub2.image.url,
                url: sub2.path_url,
                title: sub2.title,
                alt: sub2.title,
                gtmSeq: 2,
                active_from: sub2.active_from,
                active_to: sub2.active_to,
              }
            }
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
      this.isBannerHome = true
    },
    createProfileParams() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type ?? '')
      }
      return params
    },
    async getBannerCampaigns() {
      // this.isBannerCampaigns = false
      let params = new URLSearchParams()
      params.append('page', this.currentPage)
      params.append('pageSize', this.pageSize)

      await this.$profileProvider.getBannerCampaignsData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data.items
            console.log('getBannerCampaigns', data)
            if (result.data.items.length) {
              data.forEach((e) => {
                this.banner_campaign.push(e)
              })
              setTimeout(() => {
                this.currentPage++
                this.loadFlag = true
              }, 100)
            } else {
              this.loadFlag = false
            }
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
      this.isBannerCampaigns = true
    },
  },
}
