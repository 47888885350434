
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'UserEdit',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      edit: {
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: false,
        system: 'OnlineEpro',
        default_language: '',
      },
      account: {
        email: '',
        first_name: {},
        last_name: {},
        phone: {},
        mobile: {},
        is_user_active: false,
        default_language: '',
      },
      isPhone: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'userInfo']),
    requiredIsPhone() {
      return this.edit.phone.value === '' && this.edit.mobile.value === ''
    },
  },
  watch: {
    'edit.phone.value': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'edit.phone.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    validateEditSubmit() {
      this.$refs.editUserForm.validate().then((result) => {
        if (result) {
          if (this.userProfile.account_channel === 'standard') {
            if (
              this.edit.phone.value === '' &&
              this.edit.phone.extension !== ''
            ) {
              this.isPhone = true
            } else {
              this.updateUserData()
              this.isPhone = false
            }
          }
        }
      })
    },
    async getUserData() {
      await this.$profileProvider
        .getAccountUsersById(this.userProfile.id, this.$route.params.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('getAccountUsersById', data)
              this.account = {
                user_id: data.id,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                phone:
                  data.phones !== undefined
                    ? data.phones.find((a) => a.type === 'Phone') ??
                      this.edit.phone
                    : '',
                mobile:
                  data.phones !== undefined
                    ? data.phones.find((a) => a.type === 'Mobile') ??
                      this.edit.mobile
                    : '',
                is_user_active: data.accounts.find(
                  (a) => a.id === this.userProfile.id
                ).is_user_active,
                default_language: data.default_language.toUpperCase(),
              }
            }
          },
          (error) => {
            console.log('getUserDataById', error.responsed)
          }
        )
    },
    async updateUserData() {
      this.edit.email = this.account.email
      this.edit.is_user_active = this.account.is_user_active
      this.edit.default_language = this.account.default_language.toLowerCase()
      console.log('object', this.edit)
      await this.$profileProvider
        .updateAccountUsersById(
          this.userProfile.id,
          this.account.user_id,
          this.edit
        )
        .then(
          (result) => {
            if (result.status === 204) {
              this.onCancel()
            }
          },
          (error) => {
            console.log('updateAccountUsersById', error.responsed)
          }
        )
    },
    onCancel() {
      this.$router.push({
        path: this.$i18n.path(`account/user`),
      })
    },
    onChangeLanguage(lang) {
      this.account.default_language = lang
    },
  },
}
