
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  name: 'DatePickerComponent',
  components: {
    DatePicker,
  },
  props: {
    placeholder: { type: String, default: 'เลือกวันที่' },
    label: {
      type: String,
      default: '',
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    hours: {
      type: Object,
      default: () => {
        return {
          start: 0,
          length: 24,
        }
      },
    },
    defaultDate: {
      type: Object,
      default: () => {
        return {
          from: '',
          to: '',
        }
      },
    },
    defaultBase: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: true,
    },
    dateTime: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Boolean,
      default: false,
    },
    th: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => {
        return []
      },
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    setOffsetTime: {
      type: Boolean,
      default: true,
    },
    notBeforeToday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: [],
      datePicker: {},
      isEmpty: '',
      type: '',
      format: '',
      setHours: [],
      lang: {
        days: [
          this.$t('dd.01'),
          this.$t('dd.02'),
          this.$t('dd.03'),
          this.$t('dd.04'),
          this.$t('dd.05'),
          this.$t('dd.06'),
          this.$t('dd.07'),
        ],
        months: [
          this.$t('mm.01'),
          this.$t('mm.02'),
          this.$t('mm.03'),
          this.$t('mm.04'),
          this.$t('mm.05'),
          this.$t('mm.06'),
          this.$t('mm.07'),
          this.$t('mm.08'),
          this.$t('mm.09'),
          this.$t('mm.10'),
          this.$t('mm.11'),
          this.$t('mm.12'),
        ],
        formatLocale: {
          monthsShort: [
            this.$t('mm.01'),
            this.$t('mm.02'),
            this.$t('mm.03'),
            this.$t('mm.04'),
            this.$t('mm.05'),
            this.$t('mm.06'),
            this.$t('mm.07'),
            this.$t('mm.08'),
            this.$t('mm.09'),
            this.$t('mm.10'),
            this.$t('mm.11'),
            this.$t('mm.12'),
          ],
        },
      },
    }
  },
  watch: {
    errors: function (val) {
      console.log(val)
    },
    defaultDate: function () {
      if (this.defaultDate.from && this.defaultDate.to) {
        this.date = [
          new Date(this.defaultDate.from),
          new Date(this.defaultDate.to),
        ]
      } else if (this.defaultDate.from === '' && this.defaultDate.to === '') {
        this.date = []
        this.datePicker = {}
      }
    },
    defaultBase: function (val) {
      this.date = new Date(val)
    },
    deep: true,
  },
  mounted() {
    this.setHours = Array.from({ length: this.hours.length }).map(
      (_, i) => i + this.hours.start
    )
    this.$nextTick(() => {
      if (this.range) {
        if (this.defaultDate.from && this.defaultDate.to)
          this.date = [
            new Date(this.defaultDate.from),
            new Date(this.defaultDate.to),
          ]
        console.log('date :>> ', this.date)
      } else {
        this.date = new Date(this.defaultBase)
        console.log('base', this.defaultBase)
      }
    })
    if (this.dateTime) {
      this.type = 'datetime'
      this.format = 'DD/MM/YYYY HH:mm'
    } else if (this.month) {
      this.type = 'month'
      this.format = 'MM/YYYY'
    } else {
      this.type = 'date'
      this.format = 'DD/MM/YYYY'
    }
  },
  methods: {
    setNotBeforeToday(date) {
      return this.notBeforeToday
        ? date < new Date(new Date().setHours(0, 0, 0, 0))
        : null
    },
    setNotBeforeTime(date) {
      return date.getHours() < this.hours.start
    },
    onClear() {
      this.date = []
      this.datePicker = {}
      this.$emit('selected', this.datePicker)
    },
    onConfirm() {
      if (this.range) {
        this.datePicker.from = this.date[0]
          ? this.$utils.ToISOString(this.date[0])
          : ''
        if (this.date[1]) {
          if (this.setOffsetTime) this.date[1].setHours(16, 59, 59, 999)
          this.datePicker.to = this.$utils.ToISOString(this.date[1])
        } else {
          this.datePicker.to = ''
        }
      } else {
        //this.datePicker = this.date
        console.log('object :>> ', this.date)
        this.datePicker = this.$utils.ToISOString(this.date)
      }
      this.$emit('selected', this.datePicker)
    },
  },
}
