
import { mapGetters } from 'vuex'

export default {
  name: 'SuggestCouponComponent',
  props: {
    // activeTab: {
    //   type: String,
    //   default: '',
    // },
    // isDelivery: {
    //   type: Boolean,
    //   default: true,
    // },
    // showTab: {
    //   type: Array,
    //   default: function () {
    //     return ['Brand', 'Seller', 'Platform', 'NewCustomer']
    //   },
    // },
    modalData: {
      type: Object,
      default: function () {},
    },
    show: { type: Boolean, default: false },
  },
  components: {
    couponItem: async () =>
      await import('@/components/suggest-coupon/couponItem.vue'),
  },
  data() {
    return {
      isDelivery: false,
      showTab: [
        'Brand',
        'Seller',
        'Platform',
        'NewCustomer',
        'DeliveryDiscount',
      ],
      // seller_id: '',
      searchCoupon: '',
      activeTab: '',
      getSelectedCoupon: '',
      getSelectedDeliveryCoupon: '',
      coupons: [],
      couponsResult: {},
      imgWarningMessageList: '',
      imgWarningMessageResult: '',
      warningMessageList: '',
      warningMessageListDesc: '',
      warningMessageResult: '',
      // warningMessageResultDesc: '',
      promotionNo: '',
      isSearchResult: false,
      isSearchLoadingSkeleton: false,
      isShowCouponDetail: false,
      couponDetail: '',
      typingTimeout: null,
      tabTimeout: null,
      lazyList: false,
      perPage: 12,
      currentPage: 1,
      // totalPage: 1,
      isLoadingLists: false,
      isSkeletonListLoading: false,
      isDisableTab: false,
      hasNextPage: false,

      // new
      // couponsBrand: [],
      // couponsSeller: [],
      // couponsPlatform: [],
      // couponsNewCustomer: [],
      // hasNextPageBrand: false,
      // hasNextPageSeller: false,
      // hasNextPagePlatform: false,
      // hasNextPageNewCustomer: false,
      // pageNoBrand: 1,
      // pageNoSeller: 1,
      // pageNoPlatform: 1,
      // pageNoNewCustomer: 1,
      isDisabledAllTab: true,
      // delivery-charge
      // isSuggestTab: true,
      isSingleTab: false,
      product_include_flag: false,
      coupon_code: '',
      isModalOpen: false,
      isSellerCoupon: false,
      seller: {},
      type: '', // Seller
      otherType: '', // Global, Delivery
      // isModalOpen: false,
      ref: '',
      modalShow: false,
    }
  },
  created() {
    // if (process.client) {
    //   this.$bus.$on('modalCoupon', (data) => {
    //     if (!this.isOpen && data.isModalOpen) {
    //       this.$nextTick(() => {
    //         this.onReset()
    //         if (data?.tab.length) {
    //           this.showTab = data.tab
    //         }
    //         this.activeTab = this.showTab.includes(data?.active)
    //           ? data?.active
    //           : this.showTab[0]
    //         this.isSingleTab = data.isSingleTab
    //         this.isDelivery = data.isDelivery
    //         // this.isSuggestTab = [
    //         //   'Brand',
    //         //   'Seller',
    //         //   'Platform',
    //         //   'NewCustomer',
    //         // ].includes(data)
    //         this.coupons = []
    //         // this.$refs['modal-coupon'].show()
    //         this.modalShow = true
    //         this.isModalOpen = true
    //         //set default
    //         this.getSelectedCoupon = data.selected_coupon || ''
    //         this.getSelectedDeliveryCoupon = data.selected_delivery || ''
    //         // seller
    //         console.log('modalCoupon', data)
    //         this.isSellerCoupon = data.isSellerCoupon || false
    //         this.seller = data.seller || {}
    //         this.type = data.type || ''
    //         // this.seller_id = data.seller_id || ''
    //       })
    //     }
    //   })
    // }
  },
  watch: {
    activeTab: {
      handler: function (val) {
        this.isLoadingLists = true
        this.isSkeletonListLoading = false
        this.coupons = []
        let num = 0
        if (this.showTab.includes(val)) {
          num = this['coupons' + val].length
        }
        // switch (val) {
        //   case 'Brand':
        //     num = this.couponsBrand.length
        //     break
        //   case 'Seller':
        //     num = this.couponsSeller.length
        //     break
        //   case 'Platform':
        //     num = this.couponsPlatform.length
        //     break
        //   case 'NewCustomer':
        //     num = this.couponsNewCustomer.length
        //     break
        // }

        this.onGetCouponList(num)
        // if (this.tabTimeout) clearTimeout(this.tabTimeout)
        // clearTimeout(this.tabTimeout)
        // this.tabTimeout = setTimeout(() => {
        //   this.onGetCouponList()
        // }, 500)
      },
      deep: true,
    },
    // couponDetail: {
    //   handler: function (val) {
    //     this.isShowCouponDetail = val.length
    //   },
    //   deep: true,
    // },
    searchCoupon: {
      handler: function (val) {
        if (!val) {
          this.isSearchResult = false
          this.couponsResult = {}
        }
        // if (this.typingTimeout) clearTimeout(this.typingTimeout)

        // if (val.length >= 4) {
        //   this.isSearchResult = true
        //   this.isSearchLoadingSkeleton = true
        //   // Clear the previous timer
        //   clearTimeout(this.typingTimeout)
        //   this.typingTimeout = setTimeout(() => {
        //     this.onGetCoupon()
        //   }, 2000)
        // } else if (val.length <= 3) {
        //   this.isSearchResult = false
        //   this.couponsResult = {}
        // }
      },
      deep: true,
    },
    show: function (value) {
      if (value) {
        this.onReset()
        if (this.modalData?.tab.length) {
          this.showTab = this.modalData.tab
        }
        this.activeTab = this.showTab.includes(this.modalData?.active)
          ? this.modalData?.active
          : this.showTab[0]
        this.isSingleTab = this.modalData.isSingleTab
        this.isDelivery = this.modalData.isDelivery
        this.coupons = []
        // this.$refs['modal-coupon'].show()
        this.modalShow = true
        this.isModalOpen = true

        //set default
        this.getSelectedCoupon = this.modalData.selected_coupon || ''
        this.getSelectedDeliveryCoupon = this.modalData.selected_delivery || ''
        // seller
        console.log('modalCoupon', this.modalData)
        this.isSellerCoupon = this.modalData.isSellerCoupon || false
        this.seller = this.modalData.seller || {}
        this.type = this.modalData.type || ''
        // this.seller_id = this.modalData.seller_id || ''
      }
    },
  },
  beforeDestroy() {
    // if (process.client) {
    // this.$nuxt.$off('modalCoupon')
    // }
  },
  computed: {
    ...mapGetters('user', [
      'userProfile',
      // 'userInfo',
      'profileType',
      // 'userCostcenter',
    ]),
    couponsType() {
      let coupons = []
      coupons = [...this['coupons' + this.activeTab]] || []
      // switch (this.activeTab) {
      //   case 'Brand':
      //     coupons = [...this.couponsBrand]
      //     break
      //   case 'Seller':
      //     coupons = [...this.couponsSeller]
      //     break
      //   case 'Platform':
      //     coupons = [...this.couponsPlatform]
      //     break
      //   case 'NewCustomer':
      //     coupons = [...this.couponsNewCustomer]
      //     break

      //   default:
      //     coupons = []
      // }
      return coupons
      // return this.coupons.filter((a) => a.coupon_type === this.activeTab)
      // const uniqueCouponCodes = new Set()
      // return this.coupons.filter((coupon) => {
      //   if (
      //     coupon.coupon_type === this.activeTab &&
      //     !uniqueCouponCodes.has(coupon.coupon_code)
      //   ) {
      //     uniqueCouponCodes.add(coupon.coupon_code)
      //     return true
      //   }
      //   return false
      // })
    },
  },
  mounted() {
    // this.$refs['modal-coupon'].show()
    // this.onReset()
  },
  methods: {
    onChangeTab(tab) {
      if (!this.isDisableTab) {
        this.activeTab = tab
        // this.totalPage = 1
        // this.hasNextPage = false
        // this.currentPage = 1
      }
    },
    onSubmitCoupon() {
      // if (this.getSelectedCoupon || this.getSelectedDeliveryCoupon) {
      this.$emit('onSubmitCoupon', {
        voucher_no: this.getSelectedCoupon,
        delivery_fee_voucher_no: this.getSelectedDeliveryCoupon,
        isSellerCoupon: this.isSellerCoupon,
        seller: this.seller,
        type: this.type,
        // selected_coupon: group.seller_voucher_no,
      })
      this.onClose()
      console.log(`submi ${this.getSelectedCoupon}`)
      // }
    },
    onReset() {
      this.currentPage = 1
      this.isSearchResult = false
      this.isShowCouponDetail = false
      this.getSelectedCoupon = ''
      this.getSelectedDeliveryCoupon = ''
      this.searchCoupon = ''
      this.couponDetail = ''
      this.couponsResult = {}
      this.activeTab = ''
      this.promotionNo = ''
      this.imgWarningMessageList = ''
      this.imgWarningMessageResult = ''
      this.warningMessageList = ''
      this.warningMessageListDesc = ''
      this.warningMessageResult = ''
      // this.warningMessageResultDesc = ''
      this.seller = {}
      this.type = ''

      this.showTab.forEach((e) => {
        this['coupons' + e] = []
        this['hasNextPage' + e] = false
        this['pageNo' + e] = 1
      })

      // this.couponsBrand = []
      // this.hasNextPageBrand = false
      // this.pageNoBrand = 1

      // this.couponsSeller = []
      // this.hasNextPageSeller = false
      // this.pageNoSeller = 1

      // this.couponsPlatform = []
      // this.hasNextPagePlatform = false
      // this.pageNoPlatform = 1

      // this.couponsNewCustomer = []
      // this.hasNextPageNewCustomer = false
      // this.pageNoNewCustomer = 1

      this.product_include_flag = false
      // this.coupons = []
      // this.hasNextPage = false
      this.coupon_code = ''
    },
    onClose() {
      // this.isModalOpen = false
      // this.$refs[`modal-coupon-${this.ref}`].hide()
      this.modalShow = false
      this.$emit('hidden')
    },
    onCloseDetail() {
      this.isShowCouponDetail = false
      setTimeout(() => {
        this.couponDetail = ''
        this.promotionNo = ''
      }, 1000)
    },
    async onGetCouponList(num) {
      if (num <= 0) {
        this.isDisableTab = true
        this.imgWarningMessageList = ''
        this.warningMessageList = ''
        this.warningMessageListDesc = ''
        let params = new URLSearchParams()
        params.append('account_id', this.userProfile.id)
        params.append('account_channel', this.profileType)
        params.append(
          'company_id',
          this.profileType === 'eprocurement' ? this.userProfile.id : ''
        )
        if (
          this.profileType === 'eprocurement' &&
          this.$utils.isObjectValid(this.userProfile)
        ) {
          if (this.$utils.isValid(this.userProfile.categories_type)) {
            params.append(
              'user_category_type',
              this.userProfile.categories_type
            )
          }
        } else if (this.profileType === 'standard') {
          params.append('user_category_type', 'Standard')
        }
        // params.append('page', this.currentPage)

        params.append('page', this['pageNo' + this.activeTab])
        // switch (this.activeTab) {
        //   case 'Brand':
        //     params.append('page', this.pageNoBrand)
        //     break
        //   case 'Seller':
        //     params.append('page', this.pageNoSeller)
        //     break
        //   case 'Platform':
        //     params.append('page', this.pageNoPlatform)
        //     break
        //   case 'NewCustomer':
        //     params.append('page', this.pageNoNewCustomer)
        //     break
        // }
        params.append('page_size', this.perPage)
        params.append('coupon_types', this.activeTab) // []

        // multi-coupon
        params.append('seller_id', this.seller?.code || '')
        params.append('is_strict_avalible', false)

        let responsed = await this.$shoppingCartProvider.getCouponsSuggestion(
          params
        )
        console.log('getCouponByCode', responsed)
        if (responsed.status) {
          if (responsed.data?.items?.length) {
            this['coupons' + this.activeTab] = [
              ...this['coupons' + this.activeTab],
              ...responsed.data.items,
            ]
            this['hasNextPage' + this.activeTab] = responsed.data?.has_next_page
            this['pageNo' + this.activeTab] = responsed.data?.page_no

            // switch (this.activeTab) {
            //   case 'Brand':
            //     this.couponsBrand = [
            //       ...this.couponsBrand,
            //       ...responsed.data.items,
            //     ]
            //     this.hasNextPageBrand = responsed.data?.has_next_page
            //     this.pageNoBrand = responsed.data?.page_no
            //     break
            //   case 'Seller':
            //     this.couponsSeller = [
            //       ...this.couponsSeller,
            //       ...responsed.data.items,
            //     ]
            //     this.hasNextPageSeller = responsed.data?.has_next_page
            //     this.pageNoSeller = responsed.data?.page_no
            //     break
            //   case 'Platform':
            //     this.couponsPlatform = [
            //       ...this.couponsPlatform,
            //       ...responsed.data.items,
            //     ]
            //     this.hasNextPagePlatform = responsed.data?.has_next_page
            //     this.pageNoPlatform = responsed.data?.page_no
            //     break
            //   case 'NewCustomer':
            //     this.couponsNewCustomer = [
            //       ...this.couponsNewCustomer,
            //       ...responsed.data.items,
            //     ]
            //     this.hasNextPageNewCustomer = responsed.data?.has_next_page
            //     this.pageNoNewCustomer = responsed.data?.page_no
            //     break

            //   default:
            //     this.coupons = []
            // }
            this.isDisabledAllTab = false
          }
          if (responsed.data?.code) {
            this.isDisabledAllTab = responsed.data?.code === 'C707'

            this.imgWarningMessageList =
              responsed.data?.image_url ||
              'https://ofm-cdn0.ofm.co.th/images/logo/ic_coupon_not_available.png'
            this.warningMessageList = responsed.data?.message
              ? responsed.data.message[this.$i18n.locale]
              : this.$t(`suggestCoupon.default-title-warning`)
            this.warningMessageListDesc = responsed.data?.message_description
              ? responsed.data.message_description[this.$i18n.locale]
              : this.$t(`suggestCoupon.default-desc-warning`)

            this['hasNextPage' + this.activeTab] = false
            // switch (this.activeTab) {
            //   case 'Brand':
            //     this.hasNextPageBrand = false
            //     break
            //   case 'Seller':
            //     this.hasNextPageSeller = false
            //     break
            //   case 'Platform':
            //     this.hasNextPagePlatform = false
            //     break
            //   case 'NewCustomer':
            //     this.hasNextPageNewCustomer = false
            //     break
            // }
            // this.hasNextPageBrand = false
            // this.hasNextPageSeller = false
            // this.hasNextPagePlatform = false
            // this.hasNextPageNewCustomer = false
            // this.pageNoBrand = 1
            // this.pageNoSeller = 1
            // this.pageNoPlatform = 1
            // this.pageNoNewCustomer = 1
          }
          // this.hasNextPage = responsed.data?.has_next_page
          // this.totalPage = responsed.data?.total_pages || 0
        }
      }
      this.isDisableTab = false
      this.isSkeletonListLoading = false

      this.isLoadingLists = false
      this.lazyList = true
      console.log('onGetCouponList')
    },
    onShowPromotionDetail(obj) {
      console.log('promotion_detail_html', obj)
      this.coupon_code = obj?.coupon_code
      this.couponDetail = obj?.promotion_detail_html
      this.promotionNo = obj?.promotion_no
      this.product_include_flag = obj?.product_include_flag
      if (this.couponDetail) this.isShowCouponDetail = true
    },
    async handleScrollLoadingCoupon(event) {
      const { target } = event
      // Calculates how far the user has scrolled
      const atBottom =
        target.scrollHeight - target.scrollTop - 240 <= target.clientHeight
      // console.log('bottom', atBottom, this.lazyList)
      // if (atBottom && this.totalPage !== this.currentPage) {
      if (atBottom) {
        if (this.lazyList) {
          // console.log('load')
          this.lazyList = false

          this.isSkeletonListLoading = this['hasNextPage' + this.activeTab]
          this['pageNo' + this.activeTab] = this['hasNextPage' + this.activeTab]
            ? this['pageNo' + this.activeTab] + 1
            : this['pageNo' + this.activeTab]
          await this.onGetCouponList(!this['hasNextPage' + this.activeTab])

          // alert()
          // switch (this.activeTab) {
          //   case 'Brand':
          //     this.isSkeletonListLoading = this.hasNextPageBrand
          //     this.pageNoBrand = this.hasNextPageBrand
          //       ? this.pageNoBrand + 1
          //       : this.pageNoBrand
          //     await this.onGetCouponList(!this.hasNextPageBrand)
          //     // this.lazyList = true
          //     break
          //   case 'Seller':
          //     this.isSkeletonListLoading = this.hasNextPageSeller
          //     this.pageNoSeller = this.hasNextPageSeller
          //       ? this.pageNoSeller + 1
          //       : this.pageNoSeller
          //     await this.onGetCouponList(!this.hasNextPageSeller)
          //     // this.lazyList = true
          //     break
          //   case 'Platform':
          //     this.isSkeletonListLoading = this.hasNextPagePlatform
          //     this.pageNoPlatform = this.hasNextPagePlatform
          //       ? this.pageNoPlatform + 1
          //       : this.pageNoPlatform
          //     await this.onGetCouponList(!this.hasNextPagePlatform)
          //     // this.lazyList = true
          //     break
          //   case 'NewCustomer':
          //     this.isSkeletonListLoading = this.hasNextPageNewCustomer
          //     this.pageNoNewCustomer = this.hasNextPageNewCustomer
          //       ? this.pageNoNewCustomer + 1
          //       : this.pageNoNewCustomer
          //     await this.onGetCouponList(!this.hasNextPageNewCustomer)
          //     // this.lazyList = true
          //     break
          // }

          // console.log('Scrolled to the bottom!' + this.currentPage)
        }
      }
    },
    async onGetCoupon() {
      this.imgWarningMessageResult = ''
      this.warningMessageResult = ''
      // this.warningMessageResultDesc = ''
      let params = new URLSearchParams()
      params.append('account_id', this.userProfile.id)
      params.append('account_channel', this.profileType)
      params.append(
        'company_id',
        this.profileType === 'eprocurement' ? this.userProfile.id : ''
      )
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }
      // multi-coupon
      this.showTab.forEach((e) => {
        params.append('coupon_types', e)
      })

      params.append('seller_id', this.seller?.code || '')
      params.append('is_strict_avalible', false)

      let responsed = await this.$shoppingCartProvider.getCouponByCode(
        this.searchCoupon,
        params
      )
      console.log('getCouponByCode', responsed)
      if (responsed.status) {
        if (responsed.data?.coupon_code) {
          this.couponsResult = responsed.data
        }

        if (responsed.data?.code) {
          this.imgWarningMessageResult =
            responsed.data?.image_url ||
            'https://ofm-cdn0.ofm.co.th/images/logo/ic_coupon_not_available.png'
          this.warningMessageResult = responsed.data?.message
            ? responsed.data.message[this.$i18n.locale]
            : this.$t(`suggestCoupon.default-title-warning`)
          // this.warningMessageResultDesc = responsed.data?.message_description
          //   ? responsed.data.message_description[this.$i18n.locale]
          //   : this.$t(`suggestCoupon.default-desc-warning`)
          this.couponsResult = {}
        }
        this.isSearchLoadingSkeleton = false
        // this.isSearchResult = true
        if (responsed.status === 404) {
          this.isSearchResult = false
          this.couponsResult = {}
        }
      } else {
        this.couponsResult = {}
        this.isSearchLoadingSkeleton = false
        // this.isSearchResult = true
      }
    },
    onSearchCoupon() {
      if (this.searchCoupon) {
        this.isSearchResult = true
        this.isSearchLoadingSkeleton = true
      }
      this.onGetCoupon()
    },
  },
}
