
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  layout: 'main',
  name: 'ManageRoleCreate',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    // ConfirmRemoveModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmModal: false,
      departmentSelect: '',
      departmentData: [],
      levels: [],
      level_index: 0,
      optionsMultiselect: [],
      approvers: [],
      users: {
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
      },
      role_level: [],
      cost_center: [],
      temp_approver: [],
      temp_approval: [],
      requester_lines: [],
      is_send_api: false,
      is_error: false,
      is_period: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'userCostcenter']),
  },
  watch: {
    departmentData: {
      handler: function (val) {
        val.forEach((v) => {
          this.optionsMultiselect[
            this.optionsMultiselect.findIndex((e) => e.value === v.value)
          ]['$isDisabled'] = true
        })
        if (val.length && !this.levels.length) {
          this.onCreateLevels()
        } else if (!val.length && this.levels.length) {
          this.levels = []
        }
      },
      deep: false,
    },
  },
  created() {},
  mounted() {
    this.getUserById()
    this.getRoleApproverEmail()
  },
  methods: {
    validateBeforeSubmit() {
      this.$refs.addRequesterLineForm.validate().then((result) => {
        if (result) this.onSave()
      })
    },
    onCheckLimit() {
      this.is_error =
        this.levels.findIndex(
          (e, index) =>
            index > 0 &&
            parseInt(e.limit) < parseInt(this.levels[index - 1].limit)
        ) >= 0
          ? true
          : false
      console.log(this.is_error)
    },
    async getUserById() {
      await this.$profileProvider
        .getCompaniesRoleMasterData(this.$route.params.id, this.userProfile.id)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            this.users = {
              user_open_id: data.user_open_id,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
            }
          }
          this.getCostCenters()
        })
    },
    async getCostCenters() {
      await this.$profileProvider
        .getCostCenterDataById(this.userProfile.id, this.$route.params.id)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('getCostCenterDataById :>> ', data)
            data.forEach((element) => {
              this.optionsMultiselect.push({
                text: '[' + element.code + '] ' + element.name.th,
                value: element.code,
                id: element.id,
                name: element.name,
              })
            })
          }
        })
    },
    async getRoleApproverEmail() {
      await this.$profileProvider
        .getRoleApproverEmailById(this.userProfile.id, false)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            this.role_level = []
            data.forEach((e, i) => {
              this.role_level.push({
                index: i + 1,
                user_open_id: e.user_open_id,
                text: e.first_name.th + ' ' + e.last_name.th,
                email: e.email,
                first_name: e.first_name,
                last_name: e.last_name,
                $isDisabled: false,
                credit_limit: 0,
                period: 0,
              })
            })
          }
        })
    },
    onAddDept() {
      console.warn('this.departmentSelect :>> ', this.departmentSelect)
      this.$nextTick(() => {
        if (this.departmentSelect)
          this.departmentData.push(this.departmentSelect)
        this.departmentSelect = ''
      })
    },
    onCreateLevels() {
      this.getRoleApproverEmail()
      this.$nextTick(() => {
        this.levels.push({
          levelIndex: 0,
          limit: '',
          period: '',
          approverSelect: '',
          approverList: [],
          optionsApprover: this.role_level,
        })
      })
    },
    onRemoveLevels(index) {
      if (this.levels.length > 1) this.levels.splice(index, 1)
    },
    onRemoveDept(val) {
      if (this.departmentData.length !== 1) {
        this.optionsMultiselect[
          this.optionsMultiselect.findIndex((e) => e.value === val)
        ]['$isDisabled'] = false
        this.departmentData.splice(
          this.departmentData.findIndex((e) => e.value === val),
          1
        )
      } else {
        this.showConfirmModal = true
      }
    },
    onEmpty() {
      this.optionsMultiselect.forEach((e) => {
        e['$isDisabled'] = false
      })

      // this.departmentData = []
      this.approvers = []
      this.levels = []
      this.onCreateLevels()
    },
    onAddApprover(index) {
      let levels = this.levels[index]
      this.level_index = index + 1
      this.$nextTick(() => {
        // this.approvers.push(levels.approverSelect)

        levels.approverList.push(levels.approverSelect)
        levels.approverSelect = ''

        levels.approverList.forEach((v) => {
          v.level = this.level_index
          levels.optionsApprover[
            levels.optionsApprover.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveApprover(index, email) {
      this.$nextTick(() => {
        // remove disabled
        this.levels[index].optionsApprover[
          this.levels[index].optionsApprover.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove approver
        this.levels[index].approverList.splice(
          this.levels[index].approverList.findIndex((e) => e.email === email),
          1
        )
      })
    },
    async onSave() {
      this.requester_lines = []

      this.is_error =
        this.levels.findIndex(
          (e, index) =>
            (index === 0 && parseInt(e.limit) === 0) ||
            (index > 0 &&
              parseInt(e.limit) <= parseInt(this.levels[index - 1].limit))
        ) >= 0
          ? false
          : true

      this.is_period = this.levels.some((e) => parseInt(e.period) === 0)
        ? false
        : true

      if (this.is_error && this.is_period) {
        this.departmentData.forEach((element) => {
          this.temp_approver = []
          this.temp_approval = []

          let i = 0
          for (let index = 1; index <= this.level_index; index++) {
            // let approver = this.approvers.filter((a) => a.level === index)
            let approver = this.levels[i].approverList.filter(
              (a) => a.level === index
            )
            this.temp_approval.push({
              level: index,
              credit_limit: parseInt(this.levels[i].limit),
              period: parseInt(this.levels[i].period),
              type: 'Any',
              approver: approver,
            })
            i++
          }

          this.requester_lines.push({
            company_id: this.userProfile.id,
            status: 'Active',
            costcenter: {
              id: element.id,
              code: element.value,
              name: element.name,
            },
            requester: {
              user_open_id: this.users.user_open_id,
              email: this.users.email,
              first_name: this.users.first_name,
              last_name: this.users.last_name,
            },
            approval: this.temp_approval,
            user_id: 'System',
            user_name: 'OnlineEpro',
          })
        })
        console.log('requester_lines', this.requester_lines)

        await this.$profileProvider
          .createRequesterLinesData(this.requester_lines)
          .then(
            (result) => {
              if (result.status === 201) {
                this.onGoBack()
              }
            },
            (error) => {
              console.log('createRequesterLinesData', error.responsed)
            }
          )
      }
    },
    onGoBack() {
      this.$router.push({
        path: this.$i18n.path(
          'company/user/manage/setting/' + this.$route.params.id
        ),
      })
    },
  },
}
