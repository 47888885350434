
export default {
  props: {
    noFooter: {
      type: Boolean,
      default: false,
    },
    counts: {
      type: Number,
      default: 6,
    },
  },
}
