
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    warningMessages: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {}
  },
  watch: {
    show: function (value) {
      if (value) {
        this.$refs['modal-warning'].show()
      }
    },
  },
  methods: {
    onClose() {
      this.$refs['modal-warning'].hide()
      this.$emit('hidden')
    },
    isUseThEnArrayMessage(Msg) {
      if (Object.keys(Msg).indexOf('message') > -1) {
        return true
      } else {
        return false
      }
    },
  },
}
