
export default {
  name: 'ApproverDropdown',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
    approverSelect: {
      type: String,
      default: 'รายชื่อผู้อนุมัติ',
    },
  },
  data() {
    return {
      isApproved: true,
      isWaiting: false,
      isRejected: false,
    }
  },
  watch: {
    // items: function (val) {
    //   if (val.length > 0) {
    //     this.approverSelect = {}
    //   }
    // },
  },
  methods: {
    displayBadge(status, approveStatus) {
      return status === approveStatus ? true : false
    },
    // onSelectApprover(data) {
    //   // this.approverSelect = data
    //   // this.$emit('selected', data)
    // },
  },
}
