
export default {
  name: 'ImageZoomComponent',
  props: {
    imgPath: { type: String, default: '' },
    imgZoomPath: { type: String, default: '' },
    asset: { type: Boolean, default: false },
    ratio: { type: Number, default: 1.5 },
    seo: { type: String, default: '' },
    isOnlineOnly: { type: Boolean, default: false },
    isFlashSale: { type: Boolean, default: false },
    //isOutofstockFlash: { type: Boolean, default: false },
    isDiscountRate: { type: Boolean, default: false },
    isHotFlashSale: { type: Boolean, default: false },
    // isOverlay: { type: Boolean, default: false },
    display_discount_rate: { type: Number, default: 0 },
    // overlay_web_image: { type: Array, default: () => [] },
    // overlay_web_image: { type: String, default: '' },
    updateTimestamp: { type: String, default: '' },
    isInstallmentBySku: { type: Boolean, default: false },
    isOverlay: { type: Object, default: () => {} },
  },
  data() {
    return {
      isError: false,
      overlay: false,
    }
  },
  computed: {
    image() {
      return this.asset ? require('@/assets' + this.imgPath) : this.imgPath
    },
    zoomPath() {
      return this.asset
        ? require('@/assets' + this.imgZoomPath)
        : this.imgZoomPath
    },
    imageZoom() {
      return 'background-image: url(' + this.zoomPath + ')'
    },
  },
  created() {
    this.id = this.generateId()
  },
  methods: {
    generateId() {
      let result = Math.random().toString(36).substring(7)
      return result
    },
    zoomIn(event, id, ratio) {
      if (!this.isError) {
        this.overlay = true
        let zoom = document.getElementById(id + '_zoom')
        let img = document.getElementById(id)
        let posX = event.offsetX ? event.offsetX : event.pageX - img.offsetLeft
        let posY = event.offsetY ? event.offsetY : event.pageY - img.offsetTop
        let posRatio = (zoom.offsetWidth / img.offsetWidth) * ratio
        let widthRatio = img.offsetWidth * ratio
        let heightRatio = img.offsetHeight * ratio

        zoom.style.opacity = 1
        zoom.style.backgroundPosition =
          -posX * posRatio + 'px ' + -posY * posRatio + 'px'
        zoom.style.width = widthRatio + 'px'
        zoom.style.height = heightRatio + 'px'
        zoom.style.backgroundSize = widthRatio * ratio + widthRatio + 'px'
      }
    },
    zoomOut(event, id) {
      if (!this.isError) {
        this.overlay = false
        let element = document.getElementById(id + '_zoom')
        element.style.opacity = 0
      }
    },
  },
  renderError() {
    this.isError = true
  },
}
