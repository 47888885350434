
export default {
  name: 'BreadCrumbDepartmentComponent',
  props: {
    condition: { type: Object, default: Object },
    category: { type: Array, default: Array },
    categoryName: { type: String, default: '' },
    subCategory: { type: Array, default: Array },
    active: { type: String, default: '' },
  },
  data() {
    return {
      activeCategory: Number,
      categoryLv: [],
    }
  },
  watch: {
    // activeCategory: {
    //   handler: function (val) {
    //     if (val === 'Chair') {
    //       this.subCategory = [
    //         {
    //           index: 1,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/product/O/F/OFMA010470.jpg?imwidth=260',
    //           catName: 'Office Chair',
    //           alt: 'เก้าอี้สำนักงาน',
    //         },
    //         {
    //           index: 2,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM0510890.jpg?imwidth=260',
    //           catName: 'Table',
    //           alt: 'โต๊ะ',
    //         },
    //       ]
    //     } else if (val === 'Decoration') {
    //       this.subCategory = [
    //         {
    //           index: 1,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //         {
    //           index: 2,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/1246-t.jpg?imwidth=260',
    //           catName: 'Mailbox',
    //           alt: 'ตู้จดหมาย',
    //         },
    //         {
    //           index: 3,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //         {
    //           index: 4,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/1246-t.jpg?imwidth=260',
    //           catName: 'Mailbox',
    //           alt: 'ตู้จดหมาย',
    //         },
    //         {
    //           index: 5,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //         {
    //           index: 6,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/1246-t.jpg?imwidth=260',
    //           catName: 'Mailbox',
    //           alt: 'ตู้จดหมาย',
    //         },
    //         {
    //           index: 7,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //         {
    //           index: 8,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/1246-t.jpg?imwidth=260',
    //           catName: 'Mailbox',
    //           alt: 'ตู้จดหมาย',
    //         },
    //         {
    //           index: 9,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //         {
    //           index: 10,
    //           catUrl: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/1246-t.jpg?imwidth=260',
    //           catName: 'Mailbox',
    //           alt: 'ตู้จดหมาย',
    //         },
    //         {
    //           index: 11,
    //           url: '',
    //           catImage:
    //             'https://aumento.officemate.co.th/media/catalog/category/994-t.jpg?imwidth=260',
    //           catName: 'Clock',
    //           alt: 'นาฬิกา',
    //         },
    //       ]
    //     } else {
    //       this.subCategory = []
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    // this.category.push(
    //   {
    //     index: 1,
    //     url: '',
    //     catImage:
    //       'https://aumento.officemate.co.th/media/catalog/category/956-t.jpg?imwidth=260',
    //     catName: 'Chair',
    //     alt: 'เก้าอี้',
    //   },
    //   {
    //     index: 2,
    //     catUrl: '',
    //     catImage:
    //       'https://aumento.officemate.co.th/media/catalog/category/991-t.jpg?imwidth=260',
    //     catName: 'Decoration',
    //     alt: 'ของแต่งทั่วไป',
    //   }
    // )
    this.activeCategory = this.active
  },
  mounted() {},
  methods: {
    onActive(id, cat) {
      // if (this.activeCategory > 0) {
      //   this.activeCategory = nameLvOne + 1
      // }
      this.activeCategory = cat
      const routeName = id + '-' + cat
      this.$emit('emitRoute', routeName)
    },
    onCategory(id) {
      this.$router.push({
        path: this.$nuxt._route.params.department + '/' + id,
      })
    },
    onSubCategory(index) {
      this.active = index
    },
  },
}
