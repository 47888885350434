
// import asideComponent from '@/components/template/aside-user.vue'
import OrderItems from '@/components/order/order-items.vue'
import ApproverDropdown from '@/components/checkout/approver-dropdown.vue'
import ListOrderProcess from '@/components/order/list-process.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import ApproveModal from '@/components/modal/confirmApproveOrder.vue'
import ModalWarning from '@/components/modal/modalWarning.vue'

import { mapGetters } from 'vuex'
import { exportOrderDocument } from '@/plugins/export-pdf.js'
import {
  exportExcelOrderDocument,
  exportCSVOrderDocument,
} from '@/plugins/export-excel.js'
export default {
  layout: 'blank-nav',
  name: 'ApproveOrder',
  components: {
    // 'aside-component': asideComponent,
    'order-item': OrderItems,
    ApproverDropdown,
    ListOrderProcess,
    'summary-component': SummaryComponent,
    ApproveModal,
    ModalWarning,
    notfound: async () => await import('@/components/share/notfound.vue'),
  },
  data() {
    return {
      modalTitle: '',
      showApproveModal: false,
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      productGroups: [],
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        period: {
          used_amt: 0,
          budget_amt: 0,
          remain_amt: 0,
          original_amt: 0,
        },
        sum_order_total_amt: 0,
        control_level: '',
        period_type: '',
        variant: 'primary',
      },
      items: [],
      approval: [],
      approverLevel: 1,
      activityLogs: [],
      isGR: false,
      warningMessages: [],
      modalWarning: false,
      approverAction: '',
      isLoading: true,
      isNotFound: false,
      isProcess: true,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
          ? true
          : false
      else return false
    },
    isUseBudget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.account_channel === 'eprocurement'
      )
        return this.orderDocument.approval_information
          .companies_budget_control_id !== null
          ? true
          : false
      else return false
    },
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    isAccountTypePersonal() {
      if (
        this.profileType === 'standard' &&
        this.userProfile.type === 'Personal'
      ) {
        return true
      }
      return false
    },
    hasApprovePermission() {
      if (!Object.keys(this.orderDocument).length) {
        return false
      }
      if (this.orderDocument.status !== 'Waiting') {
        return false
      } else if (this.orderDocument.account_channel === 'eprocurement') {
        if (
          this.orderDocument.approval_status === 'Waiting' &&
          this.orderDocument.approval_information !== null
        ) {
          let currentLevel =
            this.orderDocument.approval_information.current_level
          let approvalCurrentLevel =
            this.orderDocument.approval_information.approval.find(
              (q) => q.level === currentLevel
            )
          if (approvalCurrentLevel !== undefined) {
            return approvalCurrentLevel.approver.some(
              (q) =>
                q.user_open_id === this.$route.query.user_open_id &&
                q.approval_status !== 'Approve'
            )
          } else return false
        } else if (this.orderDocument.approval_status === 'WaitingAdmin') {
          return true
        } else return false
      } else {
        return this.orderDocument.approval_status === 'Waiting' ? true : false
      }
      // if (
      //   Object.keys(this.orderDocument).length &&
      //   this.orderDocument.account_channel === 'eprocurement'
      // )
      //   if (
      //     this.orderDocument.approval_status === 'WaitingAdmin' &&
      //     this.isAdmin
      //   )
      //     return true
      //   else if (this.orderDocument.approval_status === 'Waiting') {
      //     if (this.orderDocument.approval_information !== null) {
      //       let currentLevel = this.orderDocument.approval_information
      //         .current_level
      //       let approvalCurrentLevel = this.orderDocument.approval_information.approval.find(
      //         (q) => q.level === currentLevel
      //       )
      //       if (approvalCurrentLevel !== undefined) {
      //         return approvalCurrentLevel.approver.some(
      //           (q) =>
      //             q.user_open_id === this.openId &&
      //             q.approval_status !== 'Approve'
      //         )
      //       } else return false
      //     } else return false
      //   } else return false
    },
    hasCancelPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        return this.orderDocument.payment_status !== 'Complete' ? true : false
      } else return false
    },
    isNotWaitingExpire() {
      if (
        (Object.keys(this.orderDocument).length &&
          this.orderDocument.status === 'InProgress') ||
        this.orderDocument.status === 'ReadyToShip' ||
        this.orderDocument.status === 'Shipping' ||
        this.orderDocument.status === 'Delivered' ||
        this.orderDocument.status === 'Complete' ||
        this.orderDocument.status === 'Cancel'
      )
        return true
      else return false
    },
    isOrderExpire() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Expire'
      )
        return true
      else return false
    },
    isWaiting() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      )
        return true
      else return false
    },
  },
  watch: {},
  async mounted() {
    console.log(this.$route.params.id)
    if (!this.$utils.isValid(this.$route.params.id)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบเลขออเดอร์', en: 'order id not found' },
      })
      this.modalWarning = true
    } else if (!this.$utils.isValid(this.$route.query.verify_key)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบ verify key', en: 'verify key not found' },
      })
      this.modalWarning = true
    } else if (!this.$utils.isValid(this.$route.query.user_open_id)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบ user id', en: 'user id not found' },
      })
      this.modalWarning = true
    } else {
      await this.getOrderDocument()
    }
  },
  methods: {
    onApproverAction(action) {
      this.approverAction = action
      this.showApproveModal = true
    },
    displayApproveBadge(status, approval) {
      return status === approval.level_status ? true : false
    },
    async getOrderDocument() {
      await this.$orderProvider
        .getOrderDocumentFromEmail(
          this.$route.params.id,
          this.$route.query.verify_key
        )
        .then((result) => {
          if (result.status === 200) {
            this.isLoading = false
            this.orderDocument = result.data
            this.getOrderActivityLogs()
            // this.summary = {
            //   net_amt: this.orderDocument.net_amt,
            //   net_deliveryfee_excvat: this.orderDocument.net_deliveryfee_excvat,
            //   nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
            //   vat_amt: this.orderDocument.vat_amt,
            //   vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
            //   voucher_disc_amt_excvat:
            //     this.orderDocument.voucher_disc_amt_excvat,
            //   voucher_no: this.orderDocument.voucher_no,
            //   other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
            //   other_disc_amt: this.orderDocument.other_disc_amt_excvat,
            //   payment_disc_amt_excvat:
            //     this.orderDocument.payment_disc_amt_excvat,
            //   payment_disc_code: this.orderDocument.payment_disc_code,
            //   is_use_coupon: this.orderDocument.is_use_coupon,
            //   used_voucher_type: this.orderDocument.used_voucher_type,
            // }
            this.summary = {
              net_amt: this.orderDocument.summary.net_amt,
              net_deliveryfee_excvat:
                this.orderDocument.summary.net_deliveryfee_excvat,
              nonvat_prod_net_amt:
                this.orderDocument.summary.nonvat_prod_net_amt,
              vat_amt: this.orderDocument.summary.vat_amt,
              vat_prod_net_amt: this.orderDocument.summary.vat_prod_net_amt,
              voucher_disc_amt_excvat:
                this.orderDocument.summary.voucher_disc_amt_excvat,
              voucher_no: this.orderDocument?.voucher_no,
              other_disc_amt_incvat:
                this.orderDocument.summary.other_disc_amt_incvat,
              other_disc_amt_excvat:
                this.orderDocument.summary.other_disc_amt_excvat,
              payment_disc_amt_excvat:
                this.orderDocument.summary.payment_disc_amt_excvat,
              payment_disc_code: this.orderDocument.summary.payment_disc_code,
              is_use_coupon: this.orderDocument.is_use_coupon,
              used_voucher_type: this.orderDocument?.voucher_type, // used_voucher_type
              promo_disc_amt_incvat:
                this.orderDocument.summary?.promo_disc_amt_incvat,
              promo_disc_amt_excvat:
                this.orderDocument.summary?.promo_disc_amt_excvat,

              // dcc
              net_discount_delivery_fee_exc_vat:
                this.orderDocument.summary.net_discount_delivery_fee_exc_vat,
              total_delivery_fee_exc_vat:
                this.orderDocument.summary.total_delivery_fee_exc_vat,
              segment_discount_delivery_fee_exc_vat:
                this.orderDocument.summary
                  .segment_discount_delivery_fee_exc_vat,
              other_discount_delivery_fee_exc_vat:
                this.orderDocument.summary.other_discount_delivery_fee_exc_vat,
              account_segment: this.orderDocument.account_segment,
              // multi coupon
              seller_voucher_disc_amt_excvat:
                this.orderDocument.summary?.seller_voucher_disc_amt_excvat,
              seller_voucher_disc_amt_incvat:
                this.orderDocument.summary?.seller_voucher_disc_amt_incvat,
              seller_voucher_no: this.orderDocument.summary?.seller_voucher_no,
              seller_voucher_type:
                this.orderDocument.summary?.seller_voucher_type,
              delivery_fee_voucher_no:
                this.orderDocument?.delivery_fee_voucher_no,
              is_use_coupon_delivery:
                this.orderDocument?.is_use_coupon_delivery,
              voucher_discount_delivery_fee_exc_vat:
                this.orderDocument?.summary
                  ?.voucher_discount_delivery_fee_exc_vat,
            }
            if (this.isEprocuerment) {
              // this.budget = this.orderDocument.budget
              if (this.orderDocument.status === 'Waiting')
                this.getOrderBudget(this.orderDocument)
              this.approval = this.orderDocument.approval_information.approval
              this.approverLevel =
                this.orderDocument.approval_information.current_level
            }
            this.productGroups = []
            this.orderDocument.sub_orders.forEach((subOrder) => {
              this.productGroups.push({
                account_segment: this.orderDocument?.account_segment,
                soldBy: subOrder?.vendor?.sold_by?.[this.$i18n.locale],
                // subOrder?.vendor.vendor_type === 'OFMStock' ||
                // subOrder?.vendor.vendor_type.includes('DropShip')
                //   ? 'OfficeMate'
                //   : subOrder?.vendor?.display_name?.[this.$i18n.locale],
                FulfilledBy:
                  subOrder?.vendor?.fulfilled_by?.[this.$i18n.locale],
                // subOrder?.vendor.vendor_type === 'OFMStock'
                //   ? 'OfficeMate'
                //   : subOrder?.vendor?.fulfilled_by?.[this.$i18n.locale],
                items: subOrder.items,
                delivery_lead_time: subOrder?.vendor.delivery_lead_time_end,
                vendorType: subOrder?.vendor.vendor_type,
                premiums: subOrder.premium_items, // []
                // shipping
                shippingOption: [
                  {
                    type: 'OFFICEMATE SHIPPING',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 0,
                  },
                  {
                    type: 'BANGKOK SAMEDAY',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 80,
                  },
                  {
                    type: 'EXPRESS DELEVERY',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 200,
                  },
                ],
                // dcc
                delivery_lead_time_start:
                  subOrder.vendor?.delivery_lead_time_start,
                delivery_lead_time_end: subOrder.vendor?.delivery_lead_time_end,
                delivery_area_type: subOrder.vendor.delivery_area_type,
                summary: {
                  promo_disc_amt_excvat: subOrder.summary.promo_disc_amt_excvat,
                  voucher_disc_amt_excvat:
                    subOrder.summary.voucher_disc_amt_excvat,

                  nonvat_prod_net_amt: subOrder.summary.nonvat_prod_net_amt,
                  vat_prod_net_amt: subOrder.summary.vat_prod_net_amt,
                  total_delivery_fee_exc_vat:
                    subOrder.summary.total_delivery_fee_exc_vat,
                  net_discount_delivery_fee_exc_vat:
                    subOrder.summary.net_discount_delivery_fee_exc_vat,
                  vat_amt: subOrder.summary.vat_amt,
                  net_amt: subOrder.summary.net_amt,

                  // modal summary
                  minimum_order_delivery_fee_exc_vat:
                    subOrder.summary.minimum_order_delivery_fee_exc_vat,
                  weight_order_delivery_fee_exc_vat:
                    subOrder.summary.weight_order_delivery_fee_exc_vat,
                  voucher_discount_delivery_fee_exc_vat:
                    subOrder.summary.voucher_discount_delivery_fee_exc_vat,
                  segment_discount_delivery_fee_exc_vat:
                    subOrder.summary.segment_discount_delivery_fee_exc_vat,
                  other_discount_delivery_fee_exc_vat:
                    subOrder.summary.other_discount_delivery_fee_exc_vat,
                  net_deliveryfee_excvat:
                    subOrder.summary.net_deliveryfee_excvat,
                  other_disc_amt_incvat: subOrder.summary.other_disc_amt_incvat,
                  other_disc_amt_excvat: subOrder.summary.other_disc_amt_excvat,
                  // multi coupon
                  seller_voucher_disc_amt_excvat:
                    subOrder.summary?.seller_voucher_disc_amt_excvat,
                  seller_voucher_disc_amt_incvat:
                    subOrder.summary?.seller_voucher_disc_amt_incvat,
                  seller_voucher_no: subOrder.summary?.seller_voucher_no,
                  seller_voucher_type: subOrder.summary?.seller_voucher_type,
                  delivery_fee_voucher_no: subOrder?.delivery_fee_voucher_no,
                  is_use_coupon_delivery: subOrder?.is_use_coupon_delivery,
                  voucher_discount_delivery_fee_exc_vat:
                    subOrder?.summary?.voucher_discount_delivery_fee_exc_vat,
                },
              })
            })
            // this.orderDocument.sub_orders.forEach((subOrder) => {
            //   this.productGroups.push({
            //     soldBy:
            //       subOrder.vendor_type === 'OFMStock' ||
            //       subOrder.vendor_type.includes('DropShip')
            //         ? 'OfficeMate'
            //         : `${
            //             this.$i18n.locale === 'th'
            //               ? subOrder.vendor_name_th
            //               : subOrder.vendor_name_en
            //           }`,
            //     FulfilledBy:
            //       subOrder.vendor_type === 'OFMStock'
            //         ? 'OfficeMate'
            //         : `${
            //             this.$i18n.locale === 'th'
            //               ? subOrder.vendor_name_th
            //               : subOrder.vendor_name_en
            //           }`,
            //     items: subOrder.items,
            //     delivery_lead_time: this.$utils.formatDateExcludeTime(
            //       subOrder.delivery_lead_time
            //     ),
            //     premiums: [],
            //     // shipping
            //     shippingOption: [
            //       {
            //         type: 'OFFICEMATE SHIPPING',
            //         description:
            //           'Delivery to your preferred address in 2-5 working days',
            //         cost: 0,
            //       },
            //       {
            //         type: 'BANGKOK SAMEDAY',
            //         description:
            //           'Delivery to your preferred address in 2-5 working days',
            //         cost: 80,
            //       },
            //       {
            //         type: 'EXPRESS DELEVERY',
            //         description:
            //           'Delivery to your preferred address in 2-5 working days',
            //         cost: 200,
            //       },
            //     ],
            //   })
            // })
            this.productGroups.map((group) => {
              group.premiums = group.items.filter((q) => q.is_free)
              group.items = group.items.filter((q) => !q.is_free)
              group.items.map((item) => {
                item.display_price_exclude_vat = item.price_disc_excvat
                item.product_name = {
                  th: item.product_name_th,
                  en: item.product_name_en,
                }
              })
              if (group.premiums.length) {
                group.premiums.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
              }
            })
          } else {
            this.isNotFound = true
            this.isLoading = false
          }
        })
    },
    async getOrderActivityLogs() {
      await this.$orderProvider
        .getOrderActivityLogs(this.$route.params.id)
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    async getOrderBudget(order) {
      const result = await this.$orderProvider.getOrderBudget(
        order.approval_information.companies_budget_control_id,
        order.company_id,
        false
      )
      if (result.status === 200) {
        this.budget = result.data
      }
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      console.log(this.approverLevel)
    },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    onConfirmApprove(payload) {
      console.log(payload)
      this.approveOrder(payload.action, payload.remark)
    },
    async approveOrder(action, remark) {
      let apiData = JSON.stringify({
        documents: [
          {
            order_object_id: this.orderDocument.id,
            order_id: this.orderDocument.order_id,
            action: action,
          },
        ],
        user_id: this.$route.query.user_open_id,
        remark: remark,
      })
      const result = await this.$orderProvider.approveOrders(apiData)
      if (result.status === 200) {
        if (!result.data.warning_messages.length) {
          this.isProcess = false
          this.getOrderDocument()
          this.getOrderActivityLogs()
        } else {
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
      }
    },
    orderDocumentStatus() {
      switch (this.orderDocument.status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
    async exportPDF(type, language) {
      await this.$orderProvider
        .getOrderDocumentFromEmail(
          this.$route.params.id,
          this.$route.query.verify_key,
          type === 'purchase' ? true : false
        )
        .then((result) => {
          if (result.status === 200) {
            if (
              result.data !== null &&
              result.data !== undefined &&
              Object.keys(result.data).length
            )
              exportOrderDocument(result.data, language, type)
          }
        })
    },
    async exportExcel() {
      exportExcelOrderDocument(this.orderDocument)
    },
    async exportCSV() {
      exportCSVOrderDocument(this.orderDocument)
    },
  },
}
