import { render, staticRenderFns } from "./total-1.vue?vue&type=template&id=933d2764"
import script from "./total-1.vue?vue&type=script&lang=js"
export * from "./total-1.vue?vue&type=script&lang=js"
import style0 from "./total-1.vue?vue&type=style&index=0&id=933d2764&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatePicker: require('/usr/src/nuxt-app/components/share/date-picker.vue').default})
