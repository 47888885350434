
import { mapGetters } from 'vuex'
import ItemsAddToCartComponent from '@/components/share/item-add-to-cart.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'OTPModalComponent',
  components: {
    'item-add-to-cart': ItemsAddToCartComponent,
    ProductPagination,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // title: {
    //   type: String,
    //   default: '',
    // },
    filters: { type: Object, default: () => {} },
    view: { type: String, default: 'grid' },
    listName: { type: String, default: '' },
    queryIdEventAlgolia: { type: String, default: '' },
    // triggerEventAlgolia: { type: String, default: '' },
    // indexEventAlgolia: { type: String, default: null },
    // page: { type: Number, default: 0 },
    showFlashSale: { type: Boolean, default: false },
    addonId: { type: String, default: '' },
  },
  data() {
    return {
      isLoading: true,
      addonSelected: [],
      page: 1,
      perPage: 50,
      pageTotal: 0,
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      itemsPromotionDiscount: [],
      conditionPromotions: [],
      isLoadingPagination: false,
    }
  },
  watch: {
    show: function (value) {
      if (value) {
        this.isLoading = true
        this.itemsPromotionDiscount = []
        this.page = 1
        this.getItems(this.addonId)
        this.getPromotionDiscountConditions(this.addonId)
        this.$refs.addon.show()
      } else {
        this.$refs.addon.hide()
      }
    },
    // addonId: function (value) {
    //   this.getItems(value)
    // },
    // immediate: true,
  },
  computed: {
    // ...mapGetters('authen', ['openId']),
    // ...mapGetters('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      // 'userInfo',
      'profileType',
      'userCostcenter',
    ]),
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onClose() {
      this.addonSelected = []
      this.$refs.addon.hide()
      this.$emit('hidden')
    },
    positionOnPage(index) {
      return this.$eventInsightsAlgolia.calculatePositionAlgolia(
        index,
        this.page,
        this.perPage
      )
    },
    // async createParams() {
    //   let params = new URLSearchParams()
    //   console.log('promotionId', this.addonId)
    //   params.append('accountChannel', this.profileType)
    //   params.append('promotion_id', this.addonId)
    //   params.append('userOpenId', this.cartId)
    //   params.append('accountId', this.userProfile.id)

    //   if (this.profileType === 'standard') {
    //     params.append('userCategoryType', 'Standard')
    //   }
    //   return params
    // },
    async getItems(addonId) {
      // this.itemsPromotionDiscount = []
      // this.conditionPromotions = []
      console.log('promotionId', addonId)
      const params = new URLSearchParams()
      params.append('promotion_id', addonId)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id)
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      let responsed = await this.$productProvider.getPromotionDiscountSeeAll(
        params
      )
      console.log('PromotionDiscount', responsed)
      if (responsed.status === 200) {
        // this.conditionPromotions = responsed.data.headData.conditions
        // this.itemsPromotionDiscount = []
        // var resultProductDiscount = responsed.data.items.filter(
        //   (q) => q.id !== this.dataSku.sku_code
        // )
        this.isLoadingPagination = false
        this.itemsPromotionDiscount = responsed.data.items
        this.pageTotal = responsed.data.totalRecords
        // this.page = responsed.data.pageNo
        // this.pageNo = responsed.data.pageSize
        // responsed.data.items.forEach((item, index) => {
        //   console.log('resultProductDiscount', item)
        //   item.skus.forEach((sku) => {
        //     console.log('sku', sku)
        //     sku.product_name = sku.product_names.find(
        //       (q) => q.type.toLowerCase() === 'online'
        //     ).display_name
        //     this.itemsPromotionDiscount.push(sku)
        //   })
        // })
        this.isLoading = false
        this.$refs.addOnItems.scrollTo({
          top: 0,
          // behavior: 'smooth',
        })
      } else {
        this.isLoading = false
      }
    },
    async getPromotionDiscountConditions(addonId) {
      const params = new URLSearchParams()
      params.append('promotion_id', addonId)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id)
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      let responsed = await this.$shoppingCartProvider.getPromotionDiscountpdp(
        params
      )
      if (responsed.status === 200) {
        this.conditionPromotions = responsed.data.conditions
      }
    },
    onSelected(sku) {
      console.log('selected', this.addonSelected)
      if (this.addonSelected.includes(sku)) {
        console.log('remove', sku)
        this.addonSelected.splice(this.addonSelected.indexOf(sku), 1)
      } else if (
        this.addonSelected.length < 3 &&
        !this.addonSelected.includes(sku)
      ) {
        console.log('add', sku)
        this.addonSelected.push(sku)
      }
    },
    onRemove(sku) {
      this.addonSelected.splice(this.addonSelected.indexOf(sku), 1)
    },
    isAddCart(sku) {
      return ['ready_to_ship', 'lead_time', 'preorder'].includes(
        sku.display_status
      )
    },
    onConfirm() {
      this.$emit('addItemsAddon', this.addonSelected)
    },
    async onPagination(page) {
      // this.$refs.addOnItems.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
      this.page = page
      this.isLoadingPagination = true
      await this.getItems(this.addonId)
      console.log('onPagination', this.page)
    },
  },
}
