
export default {
  props: {
    payments: {
      type: Array,
      default: () => {
        return []
      },
    },
    bank_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedId: '',
    }
  },
  methods: {
    onSelectInstallmentCart(data) {
      this.$emit('onChange', data)
    },
    onShowInActive(data) {
      if (!data.is_active) {
        this.selectedId = data.bank_id
        this.$emit('onShowInActive', data)
      } else {
        this.selectedId = ''
      }
    },
  },
}
