
import PrintingModal from '@/components/printing/modal-preview'
export default {
  name: 'OrderPrintingItems',
  components: {
    PrintingModal,
  },
  props: {
    productGroups: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    showTrackingTimeline: {
      type: Array,
      default: () => {
        return []
      },
    },
    // mode: {
    //   type: String,
    //   default: 'view',
    // },
    // canRemove: {
    //   type: Boolean,
    //   default: false,
    // },
    // isReserveStock: {
    //   type: Boolean,
    //   default: false,
    // },
    isCartHeader: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalPreviewData: {},
      groups: [],
      trackingStatusList: [
        {
          index: 1,
          value: 'InProgress',
        },
        {
          index: 2,
          value: 'ReadyToShip',
        },
        {
          index: 3,
          value: 'Shipping',
        },
        {
          index: 4,
          value: 'Delivered',
        },
        {
          index: 5,
          value: 'Complete',
        },
      ],
    }
  },
  computed: {
    // editMode() {
    //   return this.mode === 'edit' ? true : false
    // },
  },
  watch: {
    productGroups: {
      handler: function () {
        this.groups = this.productGroups
      },
      deep: true,
    },
  },
  mounted() {
    this.groups = this.productGroups
  },
  methods: {
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    checkActiveDeliveryTrackingStatus(checkIndex, currentStatus) {
      let trackingStatus = this.trackingStatusList.find(
        (q) => q.value === currentStatus
      )
      return trackingStatus !== undefined && trackingStatus.index >= checkIndex
    },
    // viewTracking(trackingNo) {
    //   let url = `${process.env.NUXT_ENV_ORDER_TRACKING_URL}${trackingNo}`
    //   window.open(url, '_blank')
    // },
    onModalPreview(data) {
      this.modalPreviewData = data
    },
  },
}
