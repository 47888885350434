
export default {
  name: 'instoryBannerComponent',
  props: {
    items: { type: Object, default: {} },
  },
  data() {
    return {
      contentCenter: false,
      // ref: 'instory-banner',
      // swiperOption: {
      //   lazy: true,
      //   slidesPerView: 12,
      // },
    }
  },
  mounted() {
    setTimeout(function () {
      const sliders = document.querySelectorAll('.wrapper-instory-banner')
      console.log('sliders', sliders)
      sliders.forEach((slider, index) => {
        let isDown = false
        let startX
        let scrollLeft
        // btn
        let next = slider.querySelector('.categories-caret-next')
        let prev = slider.querySelector('.categories-caret-prev')
        window.addEventListener(
          'resize',
          function () {
            if (slider.offsetWidth < slider.scrollWidth) {
              next.classList.add('active')
            } else {
              next.classList.remove('active')
            }
          },
          true
        )
        if (slider.offsetWidth < slider.scrollWidth) {
          next.classList.add('active')
        } else {
          next.classList.remove('active')
        }
        // click next
        next.addEventListener('click', (e) => {
          slider.scrollLeft = slider.scrollWidth - slider.offsetWidth
          next.classList.remove('active')
          prev.classList.add('active')
        })
        // click prev
        prev.addEventListener('click', (e) => {
          slider.scrollLeft = 0
          prev.classList.remove('active')
          next.classList.add('active')
        })
        // btn
        slider.addEventListener(
          'mousedown',
          (e) => {
            isDown = true
            startX = window.event.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
            document.body.style.setProperty('cursor', 'grabbing', 'important')
          },
          { passive: true }
        )

        window.addEventListener(
          'mouseleave',
          () => {
            isDown = false
            slider.classList.remove('active')
            document.body.style.cursor = 'default'
          },
          { passive: true }
        )
        window.addEventListener(
          'mouseup',
          () => {
            isDown = false
            slider.classList.remove('active')
            document.body.style.cursor = 'default'
          },
          { passive: true }
        )
        // window
        // window.addEventListener(
        //   'mousemove',
        //   (e) => {
        //     if (!isDown) return
        //     slider.classList.add('active')
        //     // window.event.preventDefault()
        //     const x = window.event.pageX - slider.offsetLeft
        //     const move = x - startX
        //     slider.scrollLeft = scrollLeft - move
        //     document.body.style.setProperty('cursor', 'grabbing')
        //   },
        //   { passive: true }
        // )
        // window
        window.addEventListener(
          'mousemove',
          (e) => {
            if (!isDown) return
            slider.classList.add('active')
            // window.event.preventDefault()
            const x = window.event.pageX - slider.offsetLeft
            const move = x - startX
            slider.scrollLeft = scrollLeft - move
            document.body.style.setProperty('cursor', 'grabbing')
            // active next
            if (
              slider.scrollWidth - slider.scrollLeft - 20 <
              slider.offsetWidth
            ) {
              next.classList.remove('active')
            } else {
              next.classList.add('active')
            }
            // active prev
            if (slider.scrollLeft < 20) {
              prev.classList.remove('active')
            } else {
              prev.classList.add('active')
            }
          },
          { passive: true }
        )
        // mobile
        slider.addEventListener(
          'touchmove',
          (e) => {
            // active next
            if (
              slider.scrollWidth - slider.scrollLeft - 20 <
              slider.offsetWidth
            ) {
              next.classList.remove('active')
            } else {
              next.classList.add('active')
            }
            // active prev
            if (slider.scrollLeft < 20) {
              prev.classList.remove('active')
            } else {
              prev.classList.add('active')
            }
          },
          { passive: true }
        )
      })
    }, 100)
    this.checkScrollbar()
    window.addEventListener('resize', this.checkScrollbar)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScrollbar)
  },
  methods: {
    // onSwiperReady() {
    //   this.$refs[this.ref].$swiper.$el[0].children[0].onclick =
    //     this.onClickPerSlide
    // },
    // onClickPerSlide() {},
    checkScrollbar() {
      const instory = this.$refs['wrapper-instory-banner']
      this.contentCenter = instory
        ? instory.scrollWidth > instory.clientWidth
        : false
    },
    instoryImgSize(imgSize) {
      let size = '100'
      switch (imgSize) {
        case 'small':
          size = '100'
          break
        case 'medium':
          size = '120'
          break
        case 'large':
          size = '140'
          break
        case 'xlarge':
          size = '160'
          break
      }

      return size
    },
  },
}
