
import asideComponent from '@/components/template/aside-user.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'NewsList',
  components: {
    'aside-component': asideComponent,
    ProductPagination,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      filter: '',
      news: [],
      oldnews: [],
      // news: [
      //   {
      //     index: 1,
      //     id: 'news001',
      //     title:
      //       'แจ้งข่าวประชาสัมพันธ์เรื่องการสั่งซื้อสินค้าในองค์กร ประจำเดือน มิ.ย. 64',
      //     updateDate: '2021/05/25 11:30:00',
      //     detail:
      //       'ออฟฟิศเมท Online เว็บไซต์ขายของออนไลน์ที่ตอบโจทย์ทุกธุรกิจ ช้อปง่ายๆได้ตลอด 24 ชั่วโมง มีสินค้าให้เลือกช้อปมากกว่า 15,000 รายการ ',
      //     isActive: true,
      //     isFile: true,
      //     activeDate: {
      //       beginDate: '2021/05/25 11:30:00',
      //       endDate: '2021/05/25 11:30:00',
      //     },
      //   },
      //   {
      //     index: 2,
      //     id: 'news002',
      //     title:
      //       'Elon Musk ปั่นต่อ กล่าว Tesla ยังไม่ได้ขาย Bitcoin ราคา BTC ดีดขึ้น 2,500 ดอลลาร์',
      //     updateDate: '2021/05/25 11:30:00',
      //     detail:
      //       'พร้อมรองรับทุกความต้องการของธุรกิจ อาทิ เช่น อุปกรณ์คอมพิวเตอร์และไอที, เฟอร์นิเจอร์, อุปกรณ์สำนักงาน, งานปริ้น และ สินค้าพรีเมี่ยม สินค้าอื่นๆอีกมามาย, ช้อปออนไลน์ที่ออฟฟิศเมท มั่นใจในคุณภาพด้วยบริการรับประกันคืนสินค้านาน 30 วัน* พร้อมรับส่วนลดและโปรโมชั่นพิเศษมากมาย พิเศษยิ่งกว่าด้วยบริการจัดส่งฟรี** เมื่อซื้อสินค้าครบ 499.- และ รับคะแนน The 1 ทุกการช้อป, เพราะทุกธุรกิจต้องการพาร์ทเนอร์ที่เข้าใจ ออฟฟิศเมทจึงมุ่งมั่นที่จะพัฒนาประสบการณ์การช้อปปิ้งออนไลน์ที่ดีที่สุดให้กับคุณอย่างไม่หยุดยั้ง เพื่อให้ธุรกิจของคุณเติบโตไปพร้อมๆกับเรา',
      //     isActive: false,
      //     isFile: false,
      //     activeDate: {
      //       beginDate: '2021/05/25 11:30:00',
      //       endDate: '2021/05/25 11:30:00',
      //     },
      //   },
      // ],
      page: 1,
      perPage: 10,
      pageTotal: 0,
      isAdmin: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    filter: function (val) {
      let result = []
      this.news = this.oldnews
      result = this.news.filter(
        (a) => a.title.includes(val) || a.detail.includes(val)
      )
      if (val !== '' && result.length > 0) {
        this.news = []
        this.news = result
      }
    },
  },
  mounted() {
    this.pageTotal = this.news.length
    this.getNews()
  },
  methods: {
    onCreate() {
      this.$router.push({ path: this.$i18n.path('company/news/create') })
    },
    onEdit(id) {
      this.$router.push({ path: this.$i18n.path('company/news/edit/' + id) })
    },
    async onPagination(page) {
      this.page = page
      await this.getNews()
    },
    async getNews() {
      await this.setting()
      let params = new URLSearchParams()
      params.append('is_active', false)
      params.append('is_admin', this.isAdmin)
      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      this.oldnews = []
      this.news = []
      await this.$profileProvider.getNewsData(this.userProfile.id, params).then(
        (result) => {
          if (result.status === 200) {
            let data_new = []
            if (!this.isAdmin) {
              data_new = result.data.items.filter((a) => a.is_active === true)
            } else {
              data_new = result.data.items
            }
            console.warn('result.data :>> ', result.data)

            data_new.forEach((element, index) => {
              this.news.push({
                index: index,
                id: element.id,
                title: element.title,
                updateDate: element.update_info.timestamp,
                userName: element.update_info.user_name,
                detail: element.detail,
                isActive:
                  element.is_active && element.is_date_active ? true : false,
                isFile: element.attach_file.url !== '' ? true : false,
                activeDate: {
                  beginDate: element.valid_from,
                  endDate: element.valid_to,
                },
                news_channel: element.news_channel,
                expand: false,
                attach_file: element.attach_file,
              })
            })
            this.perPage = result.data.pageSize
            this.pageTotal = result.data.totalRecords
            this.page = result.data.pageNo
            this.oldnews = this.news
          }
        },
        (error) => {
          console.log('getNews', error.responsed)
        }
      )
    },
    async setting() {
      let admin = this.userProfile.roles.filter(
        (a) => a.role_id === 1 || a.role_id === 2
      )
      if (admin !== undefined && admin.length > 0) {
        this.isAdmin = true
      }
    },
  },
}
