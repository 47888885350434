import { render, staticRenderFns } from "./default.vue?vue&type=template&id=641c3bce"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=641c3bce&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNavbar: require('/usr/src/nuxt-app/components/template/algolia/main-navbar.vue').default,SecondNavbar: require('/usr/src/nuxt-app/components/template/second-navbar.vue').default,Loading: require('/usr/src/nuxt-app/components/share/loading.vue').default,PreconditionFailed: require('/usr/src/nuxt-app/components/share/precondition-failed.vue').default,ResponseFailed: require('/usr/src/nuxt-app/components/share/response-failed.vue').default})
