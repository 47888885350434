
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import datePicker from '@/components/share/date-picker.vue'
import TableReportGR from '@/components/company/table-report-gr.vue'
import { exportExcelGoodsReceivedReportDocument } from '@/plugins/export-excel.js'

export default {
  layout: 'main',
  name: 'CompanyReportOrder',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    datePicker,
    TableReportGR,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      itemCount: 0,
      pageNo: 0,
      filter: {
        selected: [],
        order_id: '',
        requester: [],
        approver: [],
        department: [],
        order_date_from: '',
        order_date_to: '',
        goods_received_approval_date_from: '',
        goods_received_approval_date_to: '',
        goods_received_date_from: '',
        goods_received_date_to: '',
        goods_receved_status: [],
      },
      approverData: [],
      requesterData: [],
      departmentData: [],
      items: [],
      requesterLine: [],
      optionsApprover: [],
      optionsRequester: [],
      optionsDepartment: [],
      isMsg: false,
      res_approver: [],
      index_approve: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'userCostcenter']),
  },
  watch: {},
  mounted() {
    // this.getUserById()
    this.getRoleApproverEmail()
    this.getRoleRequestersEmail()
    this.getNameCostcenter()
    // this.onSearch()
  },
  methods: {
    async getRoleApproverEmail() {
      await this.$profileProvider
        .getRoleApproverEmailById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsApprover = []
              let data = result.data
              data.forEach((s) => {
                this.optionsApprover.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleApproverEmail', error.responsed)
          }
        )
    },
    async getRoleRequestersEmail() {
      await this.$profileProvider
        .getRoleRequestersEmailById(this.userProfile.id, false)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsRequester = []
              let data = result.data
              data.forEach((s) => {
                this.optionsRequester.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleRequestersEmail', error.responsed)
          }
        )
    },
    async getNameCostcenter() {
      await this.$profileProvider
        .getNameCostcenterData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.optionsDepartment = []
              data.forEach((e, index) => {
                this.optionsDepartment.push({
                  index: index + 1,
                  id: e.code,
                  text: e.name.th,
                  value: e.id,
                })
              })
            }
          },
          (error) => {
            console.log('getNameCostcenterData', error.responsed)
          }
        )
    },
    async getOrderReport(pageNo, isExcel) {
      this.pageNo = pageNo
      let params = await this.paramData(pageNo)

      try {
        // this.items = []
        const result = await this.$reportProvider.getGoodsReceived(params)
        if (result.status === 200) {
          let data = result.data
          console.log('daaaaaaaaaaaaaaa :>> ', data)
          this.itemCount = data.totalRecords
          data.items.forEach((a) => {
            this.res_approver = []
            a.approver.forEach((s) => {
              this.res_approver.push(s.first_name.th + ' ' + s.last_name.th)
            })
            this.items.push({
              index: +1,
              id: a.id,
              account_offline_id: a.account_offline_id,
              goods_received_id: a.goods_received_id,
              order_id: a.order_id,
              order_object_id: a.order_object_id,
              goods_received_status: a.goods_received_status,
              goods_received_date: a.goods_received_date,
              goods_received_approval_date: a.goods_received_approval_date,
              contact_name: a.contact_name_th,
              costcenter_code: a.costcenter_code,
              costcenter_name: a.costcenter_name_th,
              grand_total_amt: a.grand_total_amt,
              approveName: this.res_approver.join(','),
            })
          })
          this.isMsg = false
          console.warn('this.items :>> ', this.items)
        }
        if (this.items.length === 0) {
          this.itemCount = 0
          this.isMsg = true
        }
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async getOrderExcelReport(pageNo) {
      try {
        let params = await this.paramData(pageNo)
        const result = await this.$reportProvider.getGoodsReceived(params)
        if (result.status === 200) {
          let data = result.data
          this.itemCount = data.totalRecords
          data.items.forEach((a) => {
            this.index_approve =
              a.approver.length > this.index_approve
                ? a.approver.length
                : this.index_approve
            this.items.push({
              index_approve: this.index_approve,
              index: +1,
              //order_object_id: a.order_object_id,
              company_code: a.company_code,
              department_code: a.department_code,
              department_name_th: a.department_name_th,
              department_name_en: a.department_name_en,
              account_offline_id: a.account_offline_id,
              costcenter_code: a.costcenter_code,
              costcenter_name_th: a.costcenter_name_th,
              costcenter_name_en: a.costcenter_name_en,
              contact_emai: a.contact_emai,
              contact_name_th: a.contact_name_th,
              contact_name_en: a.contact_name_en,
              order_id: a.order_id,
              po_no: a.po_no,
              order_date: a.order_date,
              status: a.status,
              goods_received_status: a.goods_received_status,
              approver: a.approver,
              products: a.items,
            })
          })
        }
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async paramData(pageNo) {
      this.pageNo = pageNo
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('order_date_from', this.filter.order_date_from ?? '')
      params.append('order_date_to', this.filter.order_date_to ?? '')
      params.append(
        'goods_received_date_from',
        this.filter.goods_received_date_from ?? ''
      )
      params.append(
        'goods_received_date_to',
        this.filter.goods_received_date_to ?? ''
      )
      params.append(
        'goods_received_approval_date_from',
        this.filter.goods_received_approval_date_from ?? ''
      )
      params.append(
        'goods_received_approval_date_to',
        this.filter.goods_received_approval_date_to ?? ''
      )
      params.append('order_id', this.filter.order_id ?? '')

      this.filter.selected.forEach((a) => {
        params.append('goods_receved_status', a)
      })
      this.filter.approver.forEach((a) => {
        params.append('approver_email', a.value)
      })
      this.filter.department.forEach((a) => {
        params.append('costcenter_id', a.value)
      })
      this.filter.requester.forEach((a) => {
        params.append('contact_email', a.value)
      })
      params.append('pageNo', pageNo ?? 0)
      return params
    },
    // userOrderRangeSelected(event) {
    //   this.filter.date = event
    // },
    async exportExcel(isExcel) {
      this.items = []
      await this.getOrderExcelReport(1)
      exportExcelGoodsReceivedReportDocument(this.items)
      this.items = []
      await this.getOrderReport(1, false)
    },
    onAddApprover() {
      this.$nextTick(() => {
        if (this.approverData) this.filter.approver.push(this.approverData)
        this.approverData = ''

        // disabled approver option
        this.filter.approver.forEach((v) => {
          this.optionsApprover[
            this.optionsApprover.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveApprover(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsApprover[
          this.optionsApprover.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove approver
        this.filter.approver.splice(
          this.filter.approver.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onAddRequester() {
      this.$nextTick(() => {
        if (this.requesterData) this.filter.requester.push(this.requesterData)
        this.requesterData = ''

        // disabled order option
        this.filter.requester.forEach((v) => {
          this.optionsRequester[
            this.optionsRequester.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveRequester(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsRequester[
          this.optionsRequester.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove order
        this.filter.requester.splice(
          this.filter.requester.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onAddDepartment() {
      this.$nextTick(() => {
        if (this.departmentData)
          this.filter.department.push(this.departmentData)
        this.departmentData = ''

        // disabled order option
        this.filter.department.forEach((v) => {
          this.optionsDepartment[
            this.optionsDepartment.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveDepartment(id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsDepartment[
          this.optionsDepartment.findIndex((e) => e.id === id)
        ]['$isDisabled'] = false
        // remove department
        this.filter.department.splice(
          this.filter.department.findIndex((e) => e.id === id),
          1
        )
      })
    },
    async onSearch() {
      this.items = []
      console.warn('filter :>> ', this.filter)
      await this.getOrderReport(1, false)
    },
    onReset() {
      this.filter = {
        selected: [],
        order_status: [],
        // dateFrom: '',
        // dateTo: '',
        order_date_from: '',
        order_date_to: '',
        goods_received_approval_date_from: '',
        goods_received_approval_date_to: '',
        order_id: '',
        approver: [],
        department: [],
        requester: [],
        goods_received_date_from: '',
        goods_received_date_to: '',
      }
      this.isMsg = false
      this.items = []
      // this.filter.order_id = ''

      // reset option
      this.approverData = []
      this.requesterData = []
      this.departmentData = []
      this.optionsRequester.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsApprover.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsDepartment.forEach((e) => {
        e['$isDisabled'] = false
      })
    },
    dateApproveSelected(event) {
      this.filter.goods_received_approval_date_from = event.from
      this.filter.goods_received_approval_date_to = event.to
    },
    dateOrderSelected(event) {
      this.filter.order_date_from = event.from
      this.filter.order_date_to = event.to
    },
    dateGoodReceivedSelected(event) {
      this.filter.goods_received_date_from = event.from
      this.filter.goods_received_date_to = event.to
    },
  },
}
