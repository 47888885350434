
import asideComponent from '@/components/template/aside-user.vue'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageProductCreate',
  components: {
    'aside-component': asideComponent,
    Multiselect,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      createFor: 'Company',
      is_department: false,
      searchItem: [],
      fields: [
        {
          key: 'productImage',
          label: '',
          class: 'text-center fs-12 w-90 border-right',
          sortable: false,
          stickyColumn: true,
        },
        {
          key: 'productId',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'reference_code',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'productName',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'price',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 w-160',
          sortable: false,
        },

        {
          key: 'action',
          label: '',
          class: 'text-right w-160',
          sortable: false,
        },
      ],
      userFields: [
        {
          key: 'text',
          label: 'ชื่อผู้ใช้งาน',
          class: 'fs-12 w-300 border-right',
          sortable: false,
        },
        {
          key: 'email',
          label: 'อีเมล',
          thClass: '',
          tdClass: 'fs-12',
          sortable: false,
        },
      ],
      costcenterFields: [
        {
          key: 'code',
          label: this.$i18n.t(`company-products-create.costcenterID`),
          class: 'fs-12 w-300 border-right',
          sortable: false,
        },
        {
          key: 'name',
          label: this.$i18n.t('company-products-create.costcenter'),
          class: 'fs-12',
          sortable: false,
        },
      ],
      userSelect: '',
      costcenterSelect: '',
      optionsUser: [],
      optionsCostcenter: [],
      userList: [],
      costcenterList: [],
      productControl: {},
      sku_infos: [],
      product_data: {
        sku_infos: [],
        user_email_create: '',
        user_name_create: '',
      },
      is_company: false,
      is_costcenter: false,
      is_user: false,
      is_head_company: false,
      is_head_costcenter: false,
      reference_code: '',
      is_error: false,
      is_reference: false,
      is_status_show: true,
      is_Show: false,
      is_Show_Cost: false,
      is_success: false,
      skus_code: [],
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('authen', ['openId']),
  },
  watch: {
    costcenterList: function (value) {
      this.is_Show_Cost = value.length > 1 ? true : false
    },
    userList: function (value) {
      this.is_Show = value.length > 1 ? true : false
    },
  },
  mounted() {
    this.getProductSkus()
    this.getRoleRequestersEmail()
    if (this.userProfile.categories_type === 'Company') {
      this.createFor = 'Company'
      this.is_head_company = true
      this.is_company = true
      this.is_costcenter = false
    }
    if (this.userProfile.categories_type === 'Costcenter') {
      this.createFor = 'Costcenter'
      this.is_head_costcenter = true
      this.is_company = false
      this.is_costcenter = true
      this.is_user = false
      this.getNameCostcenter()
    }
  },
  created() {},
  methods: {
    onAddUser() {
      this.$nextTick(() => {
        this.userList.push(this.userSelect)
        this.userSelect = ''

        this.userList.forEach((v) => {
          this.optionsUser[
            this.optionsUser.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
        this.is_error = false
      })
    },
    onAddCostcenter() {
      this.$nextTick(() => {
        this.costcenterList.push(this.costcenterSelect)
        this.costcenterSelect = ''

        this.costcenterList.forEach((v) => {
          this.optionsCostcenter[
            this.optionsCostcenter.findIndex((e) => e.text === v.text)
          ]['$isDisabled'] = true
        })
        this.is_error = false
      })
    },
    onRemoveUser(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsUser[this.optionsUser.findIndex((e) => e.email === email)][
          '$isDisabled'
        ] = false
        // remove user
        this.userList.splice(
          this.userList.findIndex((e) => e.email === email),
          1
        )
      })
    },
    delectCostcenterData(costcenter_id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsCostcenter[
          this.optionsCostcenter.findIndex((e) => e.id === costcenter_id)
        ]['$isDisabled'] = false
        // remove user
        this.costcenterList.splice(
          this.costcenterList.findIndex((e) => e.id === costcenter_id),
          1
        )
      })
    },
    onCancel() {
      this.$router.push({
        path: this.$i18n.path('company/products/'),
      })
    },
    async getProductSkus() {
      this.skus_code = []
      this.skus_code.push(this.$route.params.id)
      this.params = {
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        costcenter_id: this.userCostcenter.id,
        skus: this.skus_code,
      }
      this.$productProvider.productSkusbulkSearch(this.params).then(
        (result) => {
          if (result.status === 201) {
            let data = result.data
            let params = new URLSearchParams()
            params.append('company_id', this.userProfile.id)
            params.append('sku_code', data.sku_code)
            this.$profileProvider.getCompaniesProductskusData(params).then(
              (result) => {
                if (result.status === 200) {
                  let com_product = result.data
                  console.log('object', com_product)
                  com_product.items.forEach((e) => {
                    this.is_reference =
                      e.companies_custom.is_use_reference_product_code
                  })
                  console.log('is_reference', this.is_reference)
                  this.searchItem = [
                    {
                      productImage: this.imageNormalShowcase(data.skus[0]),
                      productId: data.skus[0].sku_code,
                      reference_code: com_product.reference_code,
                      productName:
                        data.skus[0].product_names !== undefined
                          ? data.skus[0].product_names.find(
                              (a) => a.type === 'Online'
                            ).display_name.th
                          : '',
                      price: data.skus[0].price_exclude_vat,
                    },
                  ]
                  this.checkCompany()
                }
              },
              (error) => {
                console.log('getCompaniesProductskusData', error.responsed)
              }
            )
          } else {
            this.is_status_show = false
          }
        },
        (error) => {
          console.log('getProductSkus', error.responsed)
        }
      )
    },
    selectDataControl() {
      console.log('object', this.createFor)
      if (this.createFor === 'Costcenter') {
        this.is_costcenter = true
        this.is_user = false
        this.is_error = false
        this.getNameCostcenter()
      }
      if (this.createFor === 'User') {
        this.is_user = true
        this.is_costcenter = false
        this.getRoleRequestersEmail()
        this.is_error = false
      }
      if (this.createFor === 'Company') {
        this.is_user = false
        this.is_costcenter = false
        this.is_error = false
      }
    },
    async getNameCostcenter() {
      await this.$profileProvider
        .getNameCostcenterData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.optionsCostcenter = []
              data.forEach((e, index) => {
                this.optionsCostcenter.push({
                  index: index + 1,
                  id: e.id,
                  code: e.code,
                  name: e.name.th,
                  text: '[' + e.code + '] ' + e.name.th,
                  value: e.name.th,
                  $isDisabled: false,
                })
              })
              this.costcenterList.forEach((v) => {
                this.optionsCostcenter[
                  this.optionsCostcenter.findIndex((e) => e.text === v.text)
                ]['$isDisabled'] = true
              })
            }
          },
          (error) => {
            console.log('getNameCostcenterData', error.responsed)
          }
        )
    },
    async getRoleRequestersEmail() {
      await this.$profileProvider
        .getRoleRequestersEmailById(this.userProfile.id, false)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('getRoleRequestersEmail', data)
              this.optionsUser = []
              data.forEach((e, i) => {
                this.optionsUser.push({
                  index: i + 1,
                  id: e.user_open_id,
                  text: e.first_name.th + ' ' + e.last_name.th,
                  email: e.email,
                  $isDisabled: false,
                })
              })
              this.userList.forEach((v) => {
                this.optionsUser[
                  this.optionsUser.findIndex((e) => e.email === v.email)
                ]['$isDisabled'] = true
              })
            }
          },
          (error) => {
            console.log('getRoleRequestersEmail', error.responsed)
          }
        )
    },
    async createProductControl() {
      this.sku_infos = []
      if (this.createFor === 'Costcenter') {
        this.costcenter = []
        this.is_success = this.costcenterList.length > 0 ? true : false
        console.log('object', this.is_success)

        this.costcenterList.forEach((e) => {
          this.costcenter.push(e.id)
        })
      } else if (this.createFor === 'Company') {
        this.is_success = true
      } else {
        this.users = []
        this.is_success = this.userList.length > 0 ? true : false
        this.userList.forEach((e) => {
          this.users.push(e.id)
        })
      }

      if (this.is_success) {
        this.product_data = {
          sku_infos: [
            {
              sku_code: this.$route.params.id,
              reference_code: this.reference_code ?? '',
              control_type: this.createFor,
              control_type_values:
                this.createFor === 'Costcenter' ? this.costcenter : this.users,
            },
          ],
          user_email_create: 'System',
          user_name_create: 'Online e-Procurement',
        }
        await this.$profileProvider
          .createProductControlData(this.userProfile.id, this.product_data)
          .then(
            (result) => {
              if (result.status === 201) {
                this.onCancel()
              }
            },
            (error) => {
              console.log('createProductControlData', error.responsed)
            }
          )
      } else {
        this.is_error = true
      }
    },
    async checkCompany() {
      await this.$profileProvider.checkCompany(this.userProfile.id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('checkCompany', data)
            this.is_reference = data.is_use_reference_product_code
          }
        },
        (error) => {
          console.log('checkCompany', error.responsed)
        }
      )
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return sku.images.some(
          (q) => q.size === 'thumbnail' && q.sequence === 1
        )
          ? sku.images.find((q) => q.size === 'thumbnail' && q.sequence === 1)
              .url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
  },
}
