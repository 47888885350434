
import SelectDropdown from '@/components/share/select-dropdown.vue'
export default {
  name: 'FilterController',
  components: {
    'select-dropdown': SelectDropdown,
  },
  props: {
    sortByDefault: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      view: 'grid',
      sortBy: [
        {
          text: this.$t('filterControllerComponent.dropdownPromotion'),
          value: '0',
        },
        {
          text: this.$t('filterControllerComponent.dropdownPriceLowHigh'),
          value: '1',
        },
        {
          text: this.$t('filterControllerComponent.dropdownPriceHighLow'),
          value: '2',
        },
        {
          text: this.$t('filterControllerComponent.dropdownNewOld'),
          value: '3',
        },
        {
          text: this.$t('filterControllerComponent.dropdownOldNew'),
          value: '4',
        },
      ],
      perPage: 30,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      defaultValue: String(),
    }
  },
  watch: {
    perPage: {
      handler: function () {
        this.$emit('onChangePage', this.perPage)
      },
      deep: true,
    },
    view: {
      handler: function (val) {
        this.$emit('onChangeView', val)
      },
      deep: true,
    },
  },
  created() {
    if (this.sortByDefault !== null) this.sortBy = this.sortByDefault
    this.$nuxt.$on('onResetSortFilter', () => {
      this.defaultValue = '0'
      this.$emit('onSelectSort', { value: this.defaultValue })
    })
  },
  mounted() {
    setTimeout(() => {
      let getSort = new URLSearchParams(window.location.search).get('sort')
      let val = ''
      switch (getSort) {
        case 'promotion':
          val = '0'
          break
        case 'price-ascending':
          val = '1'
          break
        case 'price-descending':
          val = '2'
          break
        case 'new-descending':
          val = '3'
          break
        case 'old-descending':
          val = '4'
          break
        default:
          val = '0'
      }
      this.defaultValue = val
      if (getSort) this.$emit('onSelectSort', { value: val })
    })
  },
  methods: {
    onShowPanel() {
      document.getElementsByTagName('body')[0].classList.add('filter-panel')
    },
    onSort(sort) {
      let searchParams = new URLSearchParams(window.location.search)
      let val = ''
      switch (sort.value) {
        case '0':
          val = 'promotion'
          break
        case '1':
          val = 'price-ascending'
          break
        case '2':
          val = 'price-descending'
          break
        case '3':
          val = 'new-descending'
          break
        case '4':
          val = 'old-descending'
          break
      }
      searchParams.set('sort', val)
      window.history.replaceState(null, null, `?${searchParams.toString()}`)
      this.$emit('onSelectSort', sort)
    },
  },
  beforeDestroy() {
    this.$nuxt.$off('onResetSortFilter')
  },
}
