
import mainNavbar from '@/components/template/algolia/main-navbar'
import secondNavbar from '@/components/template/second-navbar'
import FooterComponent from '@/components/template/footer.vue'
import loading from '@/components/share/loading'
import modalComponent from '@/components/share/modal'
import preconditionFailed from '@/components/share/precondition-failed'
import responseFailed from '@/components/share/response-failed'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    'main-navbar': mainNavbar,
    'second-navbar': secondNavbar,
    'footer-component': FooterComponent,
    // login: login,
    loading,
    modalComponent,
    'precondition-failed': preconditionFailed,
    'response-failed': responseFailed,
    addToCart: async () => await import('@/components/modal/addToCart.vue'),
    // InAppMessageHandler: async () =>
    //   await import('@/components/braze/InAppMessageHandler.vue'),
  },
  // async fetch() {
  //   if (process.server) {
  //     if (this.isUser) await this.getCartCount()
  //     await this.getCategories()
  //   }
  // },
  data() {
    return {
      userData: {},
      categories: [],
      test: [],
      img: '',
      alt: '',
      title: '',
      loadBanner: true,
    }
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  computed: {
    ...mapState('authen', ['isAuthenticated', 'openId']),
    ...mapState('cart', ['cartId']),
    ...mapState('user', ['userProfile', 'profileType']),
    ...mapGetters('user', ['userProfile', 'userCostcenter']),
    ...mapGetters('authen', ['isGuest']),
    getAuthenticatedFlag() {
      console.log('isAuthenticated', this.isAuthenticated)
      return this.isAuthenticated
    },
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
    showBanner() {
      return (
        this.$route.name === 'index' &&
        (this.profileType === 'standard' ||
          (this.profileType === 'eprocurement' &&
            this.userProfile.is_show_banner_promotion))
      )
    },
  },
  watch: {
    img: {
      immediate: true,
      handler: function (val) {
        console.log('loadingBanner', val)
        this.loadBanner = !val.src
      },
      deep: true,
    },
  },
  async created() {
    // if (process.client) {
    if (this.isUser) {
      await this.getCartCount()
      await this.getPrintingCartsCount()
    }
    await this.getCategories()
    // window.onscroll = function () {
    //   if (
    //     document.body.scrollTop > 40 ||
    //     document.documentElement.scrollTop > 40
    //   ) {
    //     setTimeout(() => {
    //       document.getElementById('btn-scrollToTop').classList.add('show')
    //     }, 100)
    //   } else {
    //     setTimeout(() => {
    //       document.getElementById('btn-scrollToTop').classList.remove('show')
    //     }, 100)
    //   }
    // }
    // }
    /* เป็น func ที่เปิดรับการเรียกเพื่อขอข้อมูลของแคตตาล๊อค */
    this.$nuxt.$on('requestCategory', (data, level) => {
      if (this.categories.length > 0) {
        let catLevelTwo = []
        let catLevelThree = []
        if (level === 1) {
          /* ใช้ seo_name ในการค้นหา */
          let catLevelOne = this.categories.filter(
            (element) =>
              this.$utils.isObjectValid(element.meta) &&
              element.meta.seo_name[this.$i18n.locale] === data
          )
          if (!catLevelOne.length) {
            catLevelOne = this.categories.filter(
              (element) =>
                this.$utils.isObjectValid(element.meta) &&
                element.meta.seo_name[
                  this.$i18n.locale === 'th' ? 'en' : 'th'
                ] === data
            )
          }
          /* ส่งค่าของแคตนั้น และลูกของมันกลับไป ลูกคือ item ของแคต lv.2 */
          setTimeout(async () => {
            this.$nuxt.$emit('returnCategory', catLevelOne[0])
          }, 100)
        }
        /* ค้นหาแคต lv2 */
        if (level === 2) {
          /* วนเพื่อหาข้อมูลของ แคต lv 2 */
          let lvl_2 = {}
          this.categories.forEach((element) => {
            catLevelTwo = element.items.filter((item) => {
              return (
                this.$utils.isObjectValid(item.meta) &&
                item.meta.seo_name[this.$i18n.locale] === data
              )
            })
            if (!catLevelTwo.length) {
              catLevelTwo = element.items.filter((item) => {
                return (
                  this.$utils.isObjectValid(item.meta) &&
                  item.meta.seo_name[
                    this.$i18n.locale === 'th' ? 'en' : 'th'
                  ] === data
                )
              })
            }
            if (catLevelTwo.length) {
              lvl_2 = catLevelTwo[0]
              return lvl_2
            }
          })
          setTimeout(async () => {
            this.$nuxt.$emit('returnCategoryLevelTwo', lvl_2)
          }, 100)
        }

        /* ค้นหาแคต lv3 เป็นอันสุดท้าย จะไม่มีข้อลูก*/
        if (level === 3) {
          let lvl_3 = {}
          this.categories.forEach((element) => {
            element.items.forEach((elementTwo) => {
              catLevelThree = elementTwo.items.filter((item) => {
                return (
                  this.$utils.isObjectValid(item.meta) &&
                  item.meta.seo_name[this.$i18n.locale] === data
                )
              })
              if (!catLevelThree.length) {
                catLevelThree = elementTwo.items.filter((item) => {
                  return (
                    this.$utils.isObjectValid(item.meta) &&
                    item.meta.seo_name[
                      this.$i18n.locale === 'th' ? 'en' : 'th'
                    ] === data
                  )
                })
              }
              if (catLevelThree.length) {
                lvl_3 = catLevelThree[0]
                return lvl_3
              }
            })
          })
          setTimeout(async () => {
            this.$nuxt.$emit('returnCategoryLevelThree', lvl_3)
          }, 100)
        }
        // /* ใช้ seo_name ในการค้นหา */
        // const catLevelOne = this.categories.filter(
        //   (element) => element.meta.seo_name[this.$i18n.locale] === data
        // )
        // let catLevelTwo = []
        // let catLevelThree = []
        // /* ส่งค่าของแคตนั้น และลูกของมันกลับไป ลูกคือ item ของแคต lv.2 */
        // if (catLevelOne.length > 0) {
        //   this.$nuxt.$emit('returnCategory', catLevelOne[0])
        // }
        // /* ค้นหาแคต lv2 */
        // if (catLevelOne.length == 0) {
        //   /* วนเพื่อหาข้อมูลของ แคต lv 2 */
        //   this.categories.forEach((element) => {
        //     catLevelTwo = element.items.filter((item) => {
        //       return item.meta.seo_name[this.$i18n.locale] === data
        //     })
        //     if (catLevelTwo.length > 0) {
        //       this.$nuxt.$emit('returnCategoryLevelTwo', catLevelTwo[0])
        //       return
        //     }
        //   })
        // }
        // /* ค้นหาแคต lv3 เป็นอันสุดท้าย จะไม่มีข้อลูก*/
        // if (catLevelTwo.length == 0) {
        //   this.categories.forEach((element) => {
        //     element.items.forEach((elementTwo) => {
        //       catLevelThree = elementTwo.items.filter((item) => {
        //         return item.meta.seo_name[this.$i18n.locale] === data
        //       })

        //       if (catLevelThree.length > 0) {
        //         this.$nuxt.$emit('returnCategoryLevelThree', catLevelThree[0])
        //         return
        //       }
        //     })
        //   })
        // }
      } else {
        console.log('data category undifine')
      }
    })
    if (process.client) {
      this.$nuxt.$on('loadingBanner', (data) => {
        console.log('loadingBanner', data)
        this.img = data
      })
    }
  },
  async mounted() {
    if (this.isUser) await this.getUserData()
  },
  beforeDestroy() {
    this.$nuxt.$off('requestCategory')
    if (process.client) {
      this.$nuxt.$off('loadingBanner')
    }
  },
  methods: {
    ...mapActions('cart', ['setCartCount', 'setCartCountPrinting']),
    ...mapActions('user', [
      'setUserProfile',
      'setUserInfo',
      'setChangeSitePermision',
    ]),
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId, false)
        .then((result) => {
          console.log(result)
          this.userData = result.data
          if (
            (this.userData.accounts.length && this.userData.companies.length) ||
            this.userData.accounts.length > 1 ||
            this.userData.companies.length > 1
          ) {
            this.setChangeSitePermision({ payload: true })
          } else {
            if (
              this.userData.companies.length === 1 &&
              this.userData.companies[0].categories_type === 'Costcenter'
            ) {
              this.setChangeSitePermision({ payload: true })
            } else this.setChangeSitePermision({ payload: false })
          }
          let tempUserData = Object.assign({}, result.data)
          delete tempUserData['accounts']
          delete tempUserData['companies']
          // delete tempUserData['phones']
          delete tempUserData['create_info']
          delete tempUserData['update_info']
          this.setUserInfo({ payload: tempUserData })
          console.log('this.userProfile', this.userProfile)
          if (!this.userData.is_verify)
            this.$router.push({ path: this.$i18n.path('verify') })
          else {
            if (
              this.userProfile !== undefined &&
              Object.keys(this.userProfile).length
            ) {
              // case has seleced profile
              // console.log('this.profileType', this.profileType)
              if (this.profileType === 'standard') {
                let account = result.data.accounts.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('account', account)
                this.setUserProfile({
                  payload: account,
                  profileType: 'standard',
                })
                console.log('setUserProfile', {
                  payload: account,
                  profileType: 'standard',
                })
              } else if (this.profileType === 'eprocurement') {
                let company = result.data.companies.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('company', company)
                this.setUserProfile({
                  payload: company,
                  profileType: 'eprocurement',
                })
                if (
                  this.$utils.isPasswordExpire(
                    this.userData.companies[0].password_expire
                  )
                )
                  this.$router.push({
                    path: this.$i18n.path('changepassword'),
                  })
              }
            } else {
              if (
                this.userData.accounts.length ||
                this.userData.companies.length
              ) {
                if (
                  this.userData.accounts.length === 1 &&
                  this.userData.companies.length === 0
                ) {
                  // use account
                  this.setUserProfile({
                    payload: this.userData.accounts[0],
                    profileType: 'standard',
                  })
                } else if (
                  this.userData.accounts.length === 0 &&
                  this.userData.companies.length === 1
                ) {
                  //use company
                  this.setUserProfile({
                    payload: this.userData.companies[0],
                    profileType: 'eprocurement',
                  })
                  if (
                    this.$utils.isPasswordExpire(
                      this.userData.companies[0].password_expire
                    )
                  )
                    this.$router.push({
                      path: this.$i18n.path('changepassword'),
                    })
                } else {
                  //select account || company
                  this.$router.push({
                    path: this.$i18n.path('account/select-company'),
                  })
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async getCartCount() {
      let responsed = await this.$shoppingCartProvider.getCartCount(
        this.cartId,
        this.userProfile.id
      )
      if (responsed.status === 200) this.setCartCount(responsed.data)
    },
    async getPrintingCartsCount() {
      if (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      ) {
        let params = new URLSearchParams()
        params.append('company_id', this.userProfile.id)
        const key = `${this.openId}-${this.userProfile.id}`
        const result = await this.$bizCardProvider.getCartCount(key, params)
        console.log('getPrintingCartsCount', result)
        if (result.status === 200) {
          this.setCartCountPrinting(result.data.cart_total)
        } else {
          this.setCartCountPrinting(0)
        }
      }
    },
    /* api request ข้อมูลแคตตาล๊อคตาม user */
    async getCategories() {
      let params = new URLSearchParams()
      /* New */
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      setTimeout(async () => {
        const response = await this.$productProvider.getCategories(params)
        console.log('response_categories : ', response.data.categories)
        this.categories = response.data.categories
        this.$nuxt.$emit('onCategoryReady')
      }, 400)
    },
  },
}
