
export default {
  name: 'InstallmentTooltipDetail',
  props: {
    id: {
      type: String,
      default: 'tooltip-installment',
    },
    paymentdata: { type: Object, default: () => {} },
  },
  mounted() {
    console.log('paymentdata', this.paymentdata)
  },
  methods: {},
}
