
export default {
  props: {
    label: {
      type: String,
      default: 'Loading...',
    },
    show: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    loaderClass: {
      type: String,
      default: 'loader-wrapper',
    },
    overlayClass: {
      type: String,
      default: 'white-overlay',
    },
    // central event bus
    eventBus: {
      type: null,
      default: null,
    },
    eventShow: {
      type: String,
      default: 'show-full-loading',
    },
    eventHide: {
      type: String,
      default: 'hide-full-loading',
    },
  },
  data() {
    return {
      showing: false,
    }
  },
  watch: {
    show(val) {
      this.showing = val
    },
  },
  created() {
    if (process.client) {
      this.$bus.$on('changeLoadingState', (state) => {
        this.showing = state
      })
    }
  },
  mounted() {
    // If event bus, register methods.
    if (this.eventBus) {
      this.registerBusMethods()
    }
  },
  beforeDestroy() {
    this.$bus.$off('changeLoadingState')
  },
  methods: {
    showMe() {
      this.showing = true
    },
    hideMe() {
      this.showing = false
    },
    // Register eventBus methods.
    registerBusMethods() {
      this.eventBus.$on(this.eventShow, this.showMe)
      this.eventBus.$on(this.eventHide, this.hideMe)
    },
  },
}
