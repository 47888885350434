
export default {
  name: 'CheckoutWarningComponent',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    messages: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    show: function (value) {
      if (value) {
        this.$refs.checkoutWarnings.show()
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onClose() {
      this.$refs.checkoutWarnings.hide()
      this.$emit('hidden')
    },
    async onSubmit() {
      this.$emit('confirm')
      this.$refs.checkoutWarnings.hide()
    },
  },
}
