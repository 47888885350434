import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=54cf8c44"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=54cf8c44&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApproverList: require('/usr/src/nuxt-app/components/checkout/approver-list.vue').default,SummaryComponent: require('/usr/src/nuxt-app/components/checkout/summary-component.vue').default,ModalWarning: require('/usr/src/nuxt-app/components/modal/modalWarning.vue').default})
