
import NewsItemsComponent from '@/components/share/news-items.vue'

export default {
  name: 'B2B',
  layout({ store }) {
    if (
      store.state.authen.isAuthenticated &&
      Object.keys(store.state.user.userInfo).length
    ) {
      return 'main'
    } else return 'b2b'
  },
  components: {
    'news-items': NewsItemsComponent,
    // 'swiper-image': SwiperImage,
    // 'swiper-item': SwiperSlider,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'home',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  // head() {
  //   return {
  //     __dangerouslyDisableSanitizers: ['script'],
  //     script: [
  //       {
  //         hid: 'insider',
  //         type: 'text/javascript',
  //         charset: 'utf-8',
  //         innerHTML: `
  //           window.insider_object = {
  //             "page": {
  //               "type":"Intro",
  //             },
  //             "custom": {
  //               "category_name": null
  //             },
  //             "user": {
  //               "uuid": "",
  //               "gdpr_optin": true,
  //               "name": "",
  //               "surname": "",
  //               "username": "",
  //               "email": "",
  //               "phone_number": "",
  //               "language": "",
  //               "returning": true,
  //               "custom": {
  //                 "cust_level": null
  //               }
  //             }
  //           }
  //         `,
  //         async: true,
  //       },
  //       {
  //         src:
  //           this.$nuxt.context.store.getters['user/profileType'] !== null &&
  //           this.$nuxt.context.store.getters['user/profileType'] ===
  //             'eprocurement'
  //             ? '//eprocurementth.api.useinsider.com/ins.js?id=10002769'
  //             : '//eprocurementth.api.useinsider.com/ins.js?id=10006310',
  //         async: true,
  //       },
  //     ],
  //   }
  // },
  data() {
    return {
      feeds: [],
      introBanner: [],
      // brandItems: [],
      products: [
        {
          index: 1,
          catImage: require('@/assets/images/categories/electronic.jpeg'),
          catName: 'Electronics & IT',
          catUrl: '',
        },
        {
          index: 2,
          catImage: require('@/assets/images/categories/officesupplies.jpeg'),
          catName: 'Office Supplies',
          catUrl: '',
        },
        {
          index: 3,
          catImage: require('@/assets/images/categories/industrial.jpeg'),
          catName: 'Industrial',
          catUrl: '',
        },
        {
          index: 4,
          catImage: require('@/assets/images/categories/officesupplies.jpeg'),
          catName: 'Cleaning',
          catUrl: '',
        },
        {
          index: 5,
          catImage: require('@/assets/images/categories/furniture.jpeg'),
          catName: 'Furniture',
          catUrl: '',
        },
        {
          index: 6,
          catImage: require('@/assets/images/categories/gifts.jpeg'),
          catName: 'Printing & Gifts',
          catUrl: '',
        },
        {
          index: 7,
          catImage: require('@/assets/images/categories/smartlife.jpeg'),
          catName: 'Smart Lifestyle',
          catUrl: '',
        },
        {
          index: 8,
          catImage: require('@/assets/images/categories/food.jpeg'),
          catName: 'Food Service',
          catUrl: '',
        },
        {
          index: 9,
          catImage: require('@/assets/images/categories/software.jpg'),
          catName: 'Software & Services',
          catUrl: '',
        },
        {
          index: 10,
          catImage: require('@/assets/images/categories/tools.jpeg'),
          catName: 'Tools',
          catUrl: '',
        },
        {
          index: 11,
          catImage: require('@/assets/images/categories/school.jpg'),
          catName: 'School & Entertainment',
          catUrl: '',
        },
        {
          index: 12,
          catImage: require('@/assets/images/categories/healthcare.jpeg'),
          catName: 'Healthcare',
          catUrl: '',
        },
      ],
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.$_onGetInsider({
    //     page: {
    //       type: 'Intro',
    //       custom: {
    //         category_name: null,
    //       },
    //     },
    //   })
    // }, 500)
    this.getBlogs()
    this.introBanner = [
      {
        index: 1,
        src: require('@/assets/images/banners/_biz.jpg'),
        url: '',
        alt: '',
      },
    ]
    // this.brandItems = [
    //   {
    //     index: 1,
    //     src: 'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_3M.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 2,
    //     src: 'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_One.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 3,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Pentel.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 4,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Faber-Castell.jpg',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 5,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo-Quantum.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 6,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Elfen_1.jpg',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 7,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_UHU_1.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 8,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Me-Style.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 1,
    //     src: 'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_3M.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 2,
    //     src: 'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_One.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 3,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Pentel.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 4,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Faber-Castell.jpg',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 5,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo-Quantum.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 6,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Elfen_1.jpg',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 7,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_UHU_1.png',
    //     url: '',
    //     alt: '',
    //   },
    //   {
    //     index: 8,
    //     src:
    //       'https://aumento.officemate.co.th/media/aw_sbb/brand/Logo_Me-Style.png',
    //     url: '',
    //     alt: '',
    //   },
    // ]
    let youtube = document.querySelectorAll('.youtube')

    for (const element of youtube) {
      let source =
        'https://i.ytimg.com/vi/' + element.dataset.embed + '/hqdefault.jpg'

      let image = new Image()
      image.src = source
      image.width = 622
      image.height = 467
      image.alt = element.dataset.embed
      image.addEventListener('load', function () {
        return element.appendChild(image)
      })

      element.addEventListener('click', function () {
        let iframe = document.createElement('iframe')

        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('allowfullscreen', '')
        iframe.setAttribute(
          'allow',
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        )
        iframe.setAttribute(
          'src',
          'https://www.youtube.com/embed/' +
            this.dataset.embed +
            '?autoplay=1&mute=0&enablejsapi=1'
        )

        this.innerHTML = ''
        this.appendChild(iframe)
      })
    }
  },
  updated() {
    this.$_onGetInsider({
      page: {
        type: 'Intro',
        custom: {
          category_name: null,
        },
      },
    })
  },
  methods: {
    next(ref) {
      this.$refs[ref].$swiper.slideNext()
    },
    prev(ref) {
      this.$refs[ref].$swiper.slidePrev()
    },
    async getBlogs() {
      try {
        const result = await this.$productProvider.getBlogsData()
        if (result.status === 200) {
          let data = result.data
          data.forEach((a) => {
            this.feeds.push({
              imagePath: a.imagePath,
              description: a.description,
              linkUrl: a.linkUrl,
              title: a.title,
            })
          })
        }
      } catch (error) {
        console.log('getBlog', error.responsed)
      }
    },
  },
}
