
import modalWarning from '@/components/modal/modalWarning'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

export default {
  components: {
    'modal-warning': modalWarning,
    QrImageComponent: async () => await import('@/components/qr/qr-image.vue'),
    QrConsentComponent: async () =>
      await import('@/components/qr/qr-consent.vue'),
    QrSuccessComponent: async () =>
      await import('@/components/qr/qr-success.vue'),
  },
  data() {
    return {
      step: 'new',
      isExpired: false,
      qrId: '',
      polling: false,
      warningMessages: [],
      modalWarning: false,
      expirely: '',
      source: null,
    }
  },
  mounted() {},
  methods: {
    ...mapActions('authen', ['setAuthToken', 'signout']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', [
      'setUserProfile',
      'removeUserProfile',
      'setUserInfo',
    ]),

    async startLongPolling(qrData) {
      const qrId = qrData.qr_id
      this.qrId = qrId
      console.log('start Polling', qrId)
      this.polling = true
      let continue_polling_key = ''
      let vm = this
      if (qrId) {
        this.isExpired = false
        while (this.polling) {
          try {
            // Create a CancelToken source
            const CancelToken = axios.CancelToken
            console.log('source', this.source)
            this.source = CancelToken.source()
            const response =
              await this.$authenProvider.checkQrSessionStatusPollingAsync(
                qrId,
                continue_polling_key,
                this.source.token
              )
            if (response.status === 200) {
              const qrData = response.data
              continue_polling_key = qrData.continue_polling_key
              if (qrData.status === 'Consent') {
                const qrProvedToken = qrData.data.qr_proved_token
                this.polling = false
                if (qrProvedToken) {
                  vm.onSuccessful(qrProvedToken)
                }
              } else if (qrData.status === 'Waiting') {
                //polling = false
                this.step = 'waiting'
              } else if (qrData.status === 'Successful') {
                this.polling = false
                // source.cancel('Operation canceled by the user.')
              } else if (qrData.status === 'Rejected') {
                this.polling = false
                // source.cancel('Operation canceled by the user.')
                vm.onRejected()
              }

              if (this.isExpired) {
                this.polling = false
                // source.cancel('Operation canceled by the user.')
                console.log('Polling stopped because of expired QR code')
                vm.onRejected()
              }

              if (this.qrId !== qrId) {
                this.polling = false
                //  source.cancel('Operation canceled by the user.')
                vm.onRejected()
                console.log('Polling stopped because of new QR code')
              }
            } else {
              this.polling = false
              //  source.cancel('Operation canceled by the user.')
              vm.onRejected()
              console.error('Error polling API:', response)
            }
          } catch (error) {
            this.polling = false
            //   source.cancel('Operation canceled by the user.')
            vm.onRejected()
            console.error('Error polling API:', error)
          }
        }
      }
    },

    async onSuccessful(qrProvedToken) {
      // await this.verifyLoginQrCode(qrProvedToken)
      this.$emit('onSuccessful', qrProvedToken)
      // this.step = 'successful'
    },

    onRejected() {
      this.isExpired = true
      this.step = 'new'
      if (this.source) {
        this.source.cancel('Operation canceled by the user.')
      }
    },
  },
  beforeDestroy() {
    this.polling = false
    console.log('Polling stopped')
    console.log('beforeDestroy', this.source)
    if (this.source) {
      this.source.cancel('Operation canceled by the user.')
    }
  },
}
