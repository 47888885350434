
import b2bNavbar from '@/components/template/b2b-navbar'
import FooterComponent from '@/components/template/footer.vue'
import loading from '@/components/share/loading'
import modalComponent from '@/components/share/modal'

import { mapState, mapActions } from 'vuex'
export default {
  components: {
    'b2b-navbar': b2bNavbar,
    'footer-component': FooterComponent,
    loading,
    modalComponent,
  },
  data() {
    return {
      userData: {},
    }
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  computed: {
    ...mapState('authen', ['isAuthenticated']),
    ...mapState('cart', ['cartId']),
  },
  created() {
    if (process.client) {
      // window.onscroll = function () {
      //   if (
      //     document.body.scrollTop > 40 ||
      //     document.documentElement.scrollTop > 40
      //   ) {
      //     setTimeout(() => {
      //       document.getElementById('btn-scrollToTop').classList.add('show')
      //     }, 100)
      //   } else {
      //     setTimeout(() => {
      //       document.getElementById('btn-scrollToTop').classList.remove('show')
      //     }, 100)
      //   }
      // }
    }
  },
  async mounted() {
    if (this.isAuthenticated) await this.getUserData()
    if (!this.$utils.isValid(this.cartId)) {
      let genCartId = ''
      let characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        genCartId += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      this.setCartId(genCartId)
    }
  },
  methods: {
    ...mapActions('cart', ['setCartId']),
    async getUserData() {},
  },
}
