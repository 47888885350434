
export default {
  name: 'InputTextComponent',
  props: {
    label: { type: String, default: '' },
    labelColor: { type: String, default: '' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '110px' },
    maxlength: { type: String, default: '255' },
    placeholder: { type: String, default: '' },
    isEmpty: { type: Boolean, default: false },
    validateQty: { type: Boolean, default: false },
    validateText: { type: String, default: '' },
    type: { type: String, default: '' },
    icon: { type: String, default: '' },
    search: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    decimal: { type: Number, default: 2 },
    maxQty: { type: String, default: '' },
    password: { type: Boolean, default: false },
    textarea: { type: Boolean, default: false },
    rows: { type: Number, default: 4 },
    specific: { type: String, default: '' },
    disabledPassword: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    rules: { type: Object, default: Object },
    fieldName: { type: String, default: '' },
    showlength: { type: Boolean, default: false },
    dataEncrypt: { type: String, default: String },
    autoComplete: { type: Boolean, default: false },
    reference: { type: String, default: '' },
    passwordCondition: { type: Boolean, default: false },
    id: { type: String, default: '' },
    numberBorder: { type: Boolean, default: false },
    searchBtn: { type: Boolean, default: false },
    keyEnter: { type: Boolean, default: false },
  },
  data() {
    return {
      input: '',
      hidePassword: true,
      hasSpecialChar: false,
      temp: '',
      errorSpecial: '',
      isShowPasswordCondition: false,
      passwordCodition_1: false,
      passwordCodition_2: false,
      passwordCodition_3: false,
      passwordCodition_4: false,
    }
  },
  computed: {
    horizontal: function () {
      return this.vertical ? 'horizontal' : ''
    },
    addEmptyClass: function () {
      if (this.isEmpty && this.input === '') {
        return true
      } else if (this.specific !== 'success' && this.specific !== '') {
        return true
      } else {
        return false
      }
    },
    danger: function () {
      return this.specific === 'danger'
    },
    success: function () {
      return this.specific === 'success'
    },
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        this.input = this.$utils.isValid(val) ? val : ''
      },
      deep: true,
    },
    input: {
      handler: function (val) {
        if (this.type !== 'default') {
          // val = val.replace(/['\\',\\'\\,':,';','\t','\n','\r','\n']/g, '')
          val = val.replace(/[\\:;'\t\n\r]/g, '')
          switch (this.type) {
            case 'text':
              val = val.replace(/[^().a-zA-Zก-๛\s]/g, '')
              break
            case 'email':
              val = val.replace(/([^a-zA-Z0-9@._-])/g, '')
              break
            case 'number':
              val = val.replace(/[^0-9.]/g, '')
              break
            case 'qty':
              if (this.maxQty && this.maxQty < val) val = this.maxQty
              val = val.replace(/[^\d]/g, '')
              break
            // case 'decimal':
            //   val = val
            //     .replace(/[^.\d]/g, '')
            //     .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
            //   if (
            //     (val.includes('.') &&
            //       val.split('.')[1].length > this.decimal) ||
            //     (val.includes('.') && val.length === 1)
            //   ) {
            //     val = val.slice(0, -1)
            //   }
            //   break
            case 'special':
              val = val.replace(/([^a-zA-Z0-9_,-])/g, '')
              break
            case 'coupon':
              val = val.replace(/([^a-zA-Z0-9])/g, '')
              break
            case 'phone':
              val = val.replace(/[^\d]/g, '')
              break
          }
        }
        if (this.type === 'no-space') {
          val = val.replace(/\s/g, '')
        }

        if (this.passwordCondition) this.checkPasswordType()
        if (val === '') this.hidePassword = true

        this.input = val
        this.$emit('value', val)
      },
    },
  },
  mounted() {
    console.log('this.defaultValue', this.defaultValue)
    this.input = this.$utils.isValid(this.defaultValue) ? this.defaultValue : ''
    if (
      this.$utils.isValid(this.reference) &&
      this.$refs[this.reference] !== undefined
    ) {
      if (this.$utils.isValid(this.dataEncrypt))
        this.$refs[this.reference].setAttribute(
          'data-encrypt',
          this.dataEncrypt
        )
      if (!this.autoComplete)
        this.$refs[this.reference].setAttribute('autocomplete', 'new-password')
    }
  },
  methods: {
    checkSpecial(val) {
      if (this.type === 'text') {
        this.hasSpecialChar = /[^().a-zA-Zก-๛\s]/g.test(val.key)
      } else {
        this.hasSpecialChar = /[\\:;']/g.test(val.key)
      }

      this.errorSpecial =
        this.hasSpecialChar && this.type !== 'number'
          ? this.$t(`input.errorSpecial`)
          : ''
    },
    validationInput() {
      // console.log(this.$refs[this.fieldName])
      const validateResult = this.$refs[this.fieldName].validate()
      // console.log('validateResult', validateResult)
      return validateResult
    },
    checkPasswordType() {
      this.passwordCodition_1 = /[A-Z]/g.test(this.input)
      this.passwordCodition_2 = /[a-z]/g.test(this.input)
      this.passwordCodition_3 = /[0-9]/g.test(this.input)
      this.passwordCodition_4 = /^.{8,}$/.test(this.input)
    },
    onShowPasswordCondition() {
      this.isShowPasswordCondition = true
      console.log('isShowPasswordCondition', this.isShowPasswordCondition)
    },
    onEnter() {
      if (this.keyEnter) {
        this.$emit('onPressEnter')
      }
    },
  },
}
