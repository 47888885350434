
import ItemsComponent from '@/components/share/item.vue'
import ItemsAddToCartComponent from '@/components/share/item-add-to-cart.vue'
import SkuItemsAddToCartComponent from '@/components/share/sku-item-add-to-cart.vue'
export default {
  name: 'SwiperSliderComponent',
  components: {
    item: ItemsComponent,
    'item-add-to-cart': ItemsAddToCartComponent,
    'sku-item-add-to-cart': SkuItemsAddToCartComponent,
  },
  props: {
    products: { type: Array, default: () => [] },
    loop: { type: Boolean, default: false },
    hideButton: { type: Boolean, default: true },
    spaceBetween: { type: Number, default: 0 },
    slideStyle: { type: String, default: 'recommended' },
    addToCart: { type: Boolean, default: true },
    sourceModel: { type: String, default: 'product' },
    listName: { type: String, default: '' },
    isShowNormalPrice: { type: Boolean, default: true },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    width: { type: String, default: '150' },
    height: { type: String, default: '150' },
    showFlashSale: { type: Boolean, default: false },
    groupslide: { type: Boolean, default: false },
    overlayImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      ref: '',
      lazyNum: 0,
      slidesSizesGrid: 0,
      virtualSize: 0,
      isActivePrev: false,
      isActiveNext: false,
      isActiveSlide: false,
      swiperOption: {
        breakpoints: this.breakpoints(),
        // on: {
        //   resize: () => {
        // this.$refs[
        //   this.ref
        // ].$swiper.params.slidesPerView = this.responsive()
        // this.$refs[this.ref].$swiper.update()
        //     },
        //   },
        on: {
          touchMove: () => {
            this.isActiveSlide = true
            this.slidesSizesGrid =
              this.$refs[this.ref].$swiper.slidesSizesGrid[0]
            this.virtualSize = this.$refs[this.ref].$swiper.virtualSize
          },
        },
      },
      slidesPerView: 0,
    }
  },
  watch: {
    products: {
      handler: function (val) {
        if (val.length > 0) this.onSlide()
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    this.swiperOption.spaceBetween = this.spaceBetween
    this.swiperOption.loop = this.loop
  },
  mounted() {
    this.lazyNum = 7
    // this.lazyNum = this.$refs[this.ref].$swiper.params.slidesPerView

    // this.$nextTick(() => {
    //   let elemWidth =
    //     document.querySelector('#products-' + this.ref + ' > .swiper-wrapper')
    //       .clientWidth /
    //       this.responsive() +
    //     'px'
    //   let items = document.querySelectorAll(
    //     '#products-' + this.ref + ' > .swiper-wrapper > .swiper-slide'
    //   )
    //   items.forEach((element) => {
    //     element.style.width = elemWidth
    //   })
    this.onSlide()
    // })
  },
  methods: {
    breakpoints() {
      let mobile = 0
      let minMobile = 2
      let tablet = 2
      let minDesktop = 3
      let wideDesktop = 3

      switch (this.slideStyle) {
        case 'recommended':
          mobile = 1.5
          minMobile = 2.2
          tablet = 4
          minDesktop = 5
          wideDesktop = 6
          break
        case 'tab-product':
          mobile = 1.5
          minMobile = 2.2
          tablet = 3.5
          minDesktop = 4
          wideDesktop = 6
          break
        case 'tab-brands':
          mobile = 1.5
          minMobile = 1.5
          tablet = 3.5
          minDesktop = 4
          wideDesktop = 6
          break
        case 'full-width':
          mobile = 1.5
          minMobile = 2.2
          tablet = 4
          minDesktop = 5
          wideDesktop = 6
          break
        default:
          break
      }
      return {
        0: {
          slidesPerView: mobile,
          slidesPerGroup: this.groupslide ? mobile : 1,
        },
        360: {
          slidesPerView: minMobile,
          slidesPerGroup: this.groupslide ? minMobile : 1,
        },
        768: {
          slidesPerView: tablet,
          slidesPerGroup: this.groupslide ? tablet : 1,
        },
        1179: {
          slidesPerView: minDesktop,
          slidesPerGroup: this.groupslide ? minDesktop : 1,
        },
        1360: {
          slidesPerView: wideDesktop,
          slidesPerGroup: this.groupslide ? wideDesktop : 1,
        },
      }
    },
    // responsive() {
    //   let slidesPerView = 6
    //   let windowWidth = document.documentElement.clientWidth
    //   switch (this.slideStyle) {
    //     case 'recommended':
    //       if (windowWidth < 767) {
    //         slidesPerView = 2.2
    //       } else if (windowWidth > 767 && windowWidth < 1024) {
    //         slidesPerView = 4
    //       } else {
    //         slidesPerView = 7
    //       }
    //       break
    //     case 'best-seller':
    //       if (windowWidth < 767) {
    //         slidesPerView = 2.2
    //       } else if (windowWidth > 767 && windowWidth < 1024) {
    //         slidesPerView = 3
    //       } else if (windowWidth > 1024 && windowWidth < 1599) {
    //         slidesPerView = 5
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //     case 'full-width':
    //       if (windowWidth < 767) {
    //         slidesPerView = 1.5
    //       } else if (windowWidth > 767 && windowWidth < 1024) {
    //         slidesPerView = 4
    //       } else if (windowWidth > 1024 && windowWidth < 1599) {
    //         slidesPerView = 5
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //     default:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1.5
    //       } else if (windowWidth > 767 && windowWidth < 1024) {
    //         slidesPerView = 2
    //       } else {
    //         slidesPerView = 3
    //       }
    //       break
    //   }
    //   return slidesPerView
    // },
    onSlide() {
      if (this.hideButton) {
        this.isActivePrev =
          this.$refs[this.ref].$swiper.activeIndex === 0 ? false : true
        this.isActiveNext =
          this.$refs[this.ref].$swiper.activeIndex ===
            this.products.length -
              this.$refs[this.ref].$swiper.params.slidesPerView ||
          this.products.length <=
            this.$refs[this.ref].$swiper.params.slidesPerView
            ? false
            : true
      }
    },
    next() {
      this.isActiveSlide = true
      this.$refs[this.ref].$swiper.slideNext()
    },
    prev() {
      this.isActiveSlide = true
      this.$refs[this.ref].$swiper.slidePrev()
    },
  },
}
