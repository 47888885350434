
import { mapGetters } from 'vuex'
export default {
  layout: 'blank-nav',
  name: 'PaymentCallback',
  components: {},
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'purchase',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  // async fetch({ req, store }) {
  //   if (process.server) {
  //     const qs = require('querystring')
  //     var body = ''
  //     var temp = ''
  //     while ((temp = req.read())) {
  //       // console.log(temp, temp)
  //       body += temp
  //     }
  //     var payload = qs.parse(body)
  //     // this.payload = post
  //     store.dispatch('paymentResponsed', payload)
  //   }
  // },
  async fetch({ req, store }) {
    console.log('object :>> ', req)
  },
  data() {
    return {
      errorDescription: '',
      decodePayload: '',
      payload: {},
      responseStatus: '',
      loading: true,
      isStatus: false,
    }
  },
  computed: {
    ...mapGetters('order', ['display2C2PPaymentStep']),
    ...mapGetters('authen', ['openId']),
    isSuccesed() {
      return this.$utils.isValid(this.responseStatus) &&
        this.responseStatus === 'A'
        ? true
        : false
    },
    isFailed() {
      return this.$utils.isValid(this.responseStatus) &&
        this.responseStatus === 'F'
        ? true
        : false
    },
  },
  async mounted() {
    //decode payload
    this.responseStatus = ''
    console.log('this.$store.getters.payload', this.display2C2PPaymentStep)
    try {
      if (this.display2C2PPaymentStep !== undefined) {
        this.responseStatus = this.display2C2PPaymentStep.statusCode
        if (this.responseStatus === 'F') {
          this.isStatus = true
          this.errorDescription = this.display2C2PPaymentStep.respDesc
        } else {
          this.paymentInquiry2C2P()
        }
      } else {
        this.paymentInquiry2C2P()
      }

      // if (Object.keys(this.$store.getters.paymentResponed).length) {
      //   let payload = this.$store.getters.paymentResponed
      //   if (
      //     payload.paymentResponse !== undefined &&
      //     payload.paymentResponse !== ''
      //   ) {
      //     let decode1 = atob(payload.paymentResponse)
      //     let splitData = decode1.split('<payload>')
      //     let splitData2 = splitData[1].split('</payload>')
      //     let decode2 = atob(splitData2[0])
      //     // this.decodePayload = decode2
      //     let parser = new DOMParser()
      //     let xmlDoc = parser.parseFromString(decode2, 'text/xml')
      //     this.responseStatus =
      //       xmlDoc.getElementsByTagName('status')[0].childNodes[0].nodeValue
      //     if (this.responseStatus === 'F') {
      //       this.errorDescription =
      //         xmlDoc.getElementsByTagName(
      //           'failReason'
      //         )[0].childNodes[0].nodeValue
      //     }
      //   }
      // }
    } catch (err) {
      console.log(err)
    }
    this.$nextTick(() => (this.loading = false))
  },
  beforeMount() {
    // this.reloadStoreData()
  },
  beforeDestroy() {},
  methods: {
    reloadStoreData() {
      this.$store.dispatch('reloadStoreData')
    },
    redirect(action) {
      switch (action) {
        case 'complete':
          if (
            this.$route.query !== null &&
            Object.keys(this.$route.query).length &&
            this.$utils.isValid(this.$route.query.redirectUrl)
          ) {
            this.$router.push({
              path: this.$i18n.path(this.$route.query.redirectUrl),
              name:
                this.$i18n.locale === 'th'
                  ? 'account-order-id'
                  : 'lang-account-order-id',
              params: { id: this.$route.params.id },
              query: { mode: 'view' },
            })
          } else {
            this.$router.push({
              path: this.$i18n.path('checkout/complete'),
              name:
                this.$i18n.locale === 'th'
                  ? 'checkout-complete-orderId'
                  : 'lang-checkout-complete-orderId',
              params: { orderId: this.$route.params.id },
            })
          }
          break
        case 'home':
          this.$router.push({
            path: this.$i18n.path(''),
          })
          break
        case 'rePayment':
          this.$router.push({
            path: this.$i18n.path('account/order'),
            name:
              this.$i18n.locale === 'th'
                ? 'account-order-id'
                : 'lang-account-order-id',
            params: { id: this.$route.params.id },
            query: { mode: 'view' },
          })
          break
      }
    },
    async paymentInquiry2C2P() {
      console.warn('object :>> ', 55555555555)
      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('order_id', this.$route.params.id)
      await this.$orderProvider.paymentInquiry2C2P(params).then((result) => {
        if (result.status === 200) {
          console.log(result.data)
          if (result.data !== undefined && result.data !== null) {
            this.responseStatus = result.data.statusCode
            if (this.responseStatus !== 'C') {
              this.isStatus = true
            }
          }
        }
      })
    },
    // redirect(action) {
    //   let path = ''
    //   switch (action) {
    //     case 'complete':
    //       path = `${process.env.NUXT_ENV_HOST_URL}${
    //         this.$i18n.locale === 'th' ? '' : 'en/'
    //       }checkout/complete/${this.$route.params.id}`
    //       break
    //     case 'home':
    //       path = `${process.env.NUXT_ENV_HOST_URL}${
    //         this.$i18n.locale === 'th' ? '' : 'en/'
    //       }`
    //       break
    //     case 'rePayment':
    //       path = `${process.env.NUXT_ENV_HOST_URL}${
    //         this.$i18n.locale === 'th'
    //           ? `account/order/${this.$route.params.id}?mode=view`
    //           : `en/account/order/${this.$route.params.id}?mode=view`
    //       }`
    //       break
    //   }
    //   window.location.href = path
    // },
  },
}
