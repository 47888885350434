import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=3301b589&scoped=true"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=3301b589&prod&lang=css"
import style1 from "./_id.vue?vue&type=style&index=1&id=3301b589&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3301b589",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageZoom: require('/usr/src/nuxt-app/components/share/image-zoom.vue').default,Countdown: require('/usr/src/nuxt-app/components/flashsale/countdown.vue').default,InputQty: require('/usr/src/nuxt-app/components/share/input-qty.vue').default,InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default})
