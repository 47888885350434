
export default {
  name: 'CheckoutWarningComponent',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    show: function (value) {
      if (value) {
        this.$refs.checkoutWarningShipping.show()
      }
    },
  },
  mounted() {
    console.log('item-modal', this.items)
  },
  beforeDestroy() {},
  methods: {
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    onClose() {
      this.$refs.checkoutWarningShipping.hide()
      this.$emit('hidden')
    },
    async onSubmit() {
      this.$emit('confirm')
      this.$refs.checkoutWarningShipping.hide()
    },
  },
}
