
import ConfirmRemoveModal from '@/components/modal/confirmRemoveDepartment.vue'
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import TableCostcenter from '@/components/company/table-costcenter.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'CompanySetting',
  components: {
    ConfirmRemoveModal,
    'aside-component': asideComponent,
    'input-text': InputText,
    'table-costcenter': TableCostcenter,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmModal: false,
      isShowCreateDepartment: false,
      isStatus: true,
      isBanner: false,
      status_department: 'Active',
      //status_costcenter: 'Active',
      status_costcenter: '',
      filterDepartment: '',
      deptCollapse: [],
      filterResult: [],
      department: {
        id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        company_id: '',
        update_by: 'OnlineEpro',
      },
      deptSlot: [],
      data: {
        company_level: '',
      },
      info: {
        company_code: '',
        name: {
          th: '',
          en: '',
        },
        accounts_id: '',
      },
      create_department: {
        id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        status: 'Active',
        user_id: 'System',
        user_name: 'OnlineEpro',
      },
      temp_costcenter: [],
      isDept: false,
      isError: false,
      item_level_three: [],
      dept_id: '',
      is_DeptStatus: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    filterDepartment: {
      handler: function (val) {
        let result = this.deptSlot.filter(
          (element) =>
            element.deptId.toUpperCase().includes(val.toUpperCase()) ||
            element.name.th.toUpperCase().includes(val.toUpperCase()) ||
            element.name.en.toUpperCase().includes(val.toUpperCase())
        )
        result.forEach((element) => {
          element.isSlot = false
          element.isEdit = false
          element.height = ''
        })
        this.filterResult = result
      },
      deep: true,
    },
  },
  created() {
    // this.filterResult = this.deptSlot
  },
  mounted() {
    this.getCompaniesInfoById()
    this.getCompaniesLevelControl()
    this.$nextTick(() => {
      this.filterResult = this.deptSlot
    })
  },
  methods: {
    onConfirmRemoveModal(department_id, isStatus) {
      this.dept_id = department_id
      this.is_DeptStatus = isStatus
      this.showConfirmModal = true
    },
    validateDepartmentDataSubmit() {
      this.$refs.createDepartmentDataForm.validate().then((result) => {
        if (result) this.createDepartmentData()
      })
    },
    validateUpdateDepartmentSubmit(Index, department_id, isStatus) {
      this.$refs.updateDepartmentDataForm[Index].validate().then((result) => {
        if (result) {
          if (!isStatus) {
            this.onConfirmRemoveModal(department_id, isStatus)
          } else {
            this.UpdateDepartmentDataById(Index, department_id, isStatus)
          }
        }
      })
    },
    onExpandDept(index) {
      const data = this.filterResult[index] // this.deptSlot[index]
      data.isSlot = !data.isSlot
      console.log('!data.isSlot', !data.isSlot)
      this.department.name = {}
      data.isEdit = false
      if (!data.isSlot) {
        data.height = ''
      } else {
        data.height =
          document.getElementById('deptCollapse_' + index).clientHeight + 24
      }
    },
    onLoadHeight(index) {
      const data = this.filterResult[index] // this.deptSlot[index]
      this.$nextTick(() => {
        console.log(
          document.getElementById('deptCollapse_' + index).clientHeight
        )
        data.height =
          document.getElementById('deptCollapse_' + index).clientHeight + 24
      })
    },
    onEdit(index) {
      const data = this.deptSlot[index]
      data.isEdit = !this.deptSlot[index].isEdit
      this.department.name = data.name
    },
    onCancel(index) {
      this.deptSlot[index].isEdit = false
    },
    onCreateCostcenter(Id) {
      this.$router.push({
        path: this.$i18n.path('company/setting/costcenter/create'),
        query: { dept: Id },
      })
    },
    onEditCostcenter(detp_id) {
      this.$router.push({
        path: this.$i18n.path('company/setting/costcenter/edit/' + detp_id),
      })
    },
    async UpdateDepartmentDataById(index, department_id, isStatus) {
      this.department.status = isStatus ? 'Active' : 'Inactive'
      this.department.id = department_id
      await this.$profileProvider
        .UpdateDepartmentDataById(
          this.userProfile.id,
          department_id,
          this.department
        )
        .then(
          (result) => {
            if (result.status === 204) {
              this.deptSlot = []
              this.getCompaniesLevelControl()
              this.$nextTick(() => {
                this.filterResult = this.deptSlot
              })
            }
          },
          (error) => {
            console.log('UpdateDepartmentDataById', error.responsed)
          }
        )
    },
    async getCompaniesLevelControl() {
      await this.$profileProvider
        .getCompaniesLevelControlData(
          this.userProfile.id,
          this.status_department,
          this.status_costcenter
        )
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(55555555, data)
              this.data = {
                company_level: data.company_level,
              }
              if (data.company_level === 3) {
                this.item_level_three = data.data_level_three.items
                if (
                  this.item_level_three != undefined &&
                  this.item_level_three.length
                ) {
                  this.item_level_three.forEach((element) => {
                    let cost = element.costcenters.length
                      ? element.costcenters.filter((a) => a.status === 'Active')
                      : []
                    this.deptSlot.push({
                      id: element.id,
                      deptId: element.code,
                      name: {
                        th: element.name.th,
                        en: element.name.en,
                      },
                      display: {
                        th: element.name.th,
                        en: element.name.en,
                      },
                      isActive: true,
                      isStatus: element.status === 'Active' ? true : false,
                      isSlot: false,
                      isEdit: false,
                      height: '',
                      costcenter: cost.length ? cost : undefined,
                      // costcenter: element.costcenters.find(
                      //   (a) => a.code !== null
                      // ),
                    })
                  })
                }
                console.log('costcenter', this.deptSlot)
              }
            }
          },
          (error) => {
            console.log('getCompaniesLevelControl', error.responsed)
          }
        )
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.info = {
                company_code: data.company_code,
                name: data.name,
                account_id: data.accounts[0].id,
                offline_id: data.account_id_default,
              }
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
    async createDepartmentData() {
      // this.isDept = this.deptSlot.some(
      //   (a) => a.deptId !== this.create_department.code
      // )
      let dept = this.item_level_three.find(
        (a) => a.code === this.create_department.code.toUpperCase()
      )
      if (dept === undefined) {
        this.create_department.company_id = this.userProfile.id
        await this.$profileProvider
          .createDepartmentData(this.userProfile.id, this.create_department)
          .then(
            (result) => {
              if (result.status === 201) {
                this.isShowCreateDepartment = false
                this.deptSlot = []
                this.getCompaniesLevelControl()
                this.create_department.code = ''
                this.create_department.name = {
                  th: '',
                  en: '',
                }
                this.$refs.createDepartmentDataForm.reset()
                this.$nextTick(() => {
                  this.filterResult = this.deptSlot
                })
              }
            },
            (error) => {
              console.log('createDepartmentData', error.responsed)
            }
          )
      } else {
        this.isError = true
      }
    },
    departmentCancel() {
      this.isShowCreateDepartment = false
      this.create_department = {
        code: '',
        name: {
          th: '',
          en: '',
        },
      }
      this.$refs.createDepartmentDataForm.reset()
    },
  },
}
