
import asideComponent from '@/components/template/aside-user.vue'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageRoleCreate',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    // ConfirmResetModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmResetModal: false,
      departmentSelect: '',
      // departmentData: { text: '[1000] จัดซื้อ', value: '1000' },
      levels: [],
      tempLevels: [],
      approvers: [],
      users: {
        user_open_id: '',
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
      },
      departmentData: {
        text: '',
        value: '',
      },
      role_level: [],
      temp_levels: [],
      temp_approverList: [],
      approval: [],
      temp_approval: {
        approval: [],
      },
      temp_approver: [],
      is_period: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  created() {},
  mounted() {
    this.getUserById()
    this.getRoleApproverEmail()
  },
  methods: {
    validateBeforeSubmit() {
      this.$refs.editRequesterLineForm.validate().then((result) => {
        if (result) this.onSave()
      })
    },
    async getUserById() {
      await this.$profileProvider
        .getCompaniesRoleMasterData(this.$route.params.id, this.userProfile.id)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            this.users = {
              user_open_id: data.user_open_id,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
            }
            this.getRequesterLineCostcenter()
          }
        })
    },
    async getRequesterLineCostcenter() {
      await this.$profileProvider
        .getRequesterLineCostcenterDataById(this.$route.query.cost)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('object', data)
              this.departmentData = {
                text:
                  '[' + data.costcenter.code + '] ' + data.costcenter.name.th,
                value: data.costcenter.id,
              }

              data.approval.forEach((b, bindex) => {
                this.temp_approverList = []
                b.approver.forEach((e, index) => {
                  this.temp_approverList.push({
                    user_open_id: e.user_open_id,
                    index: index + 1,
                    text: e.first_name.th + ' ' + e.last_name.en,
                    email: e.email,
                    $isDisabled: false,
                    first_name: e.first_name,
                    last_name: e.last_name,
                  })
                })

                this.levels.push({
                  limit: b.credit_limit.toString(),
                  level_index: bindex + 1,
                  period: b.period.toString(),
                  approverSelect: '',
                  approverList: this.temp_approverList,
                  optionsApprover: [],
                })
              })
            }
          },
          (error) => {
            console.log('getRequesterLine', error.responsed)
          }
        )
    },
    async getRoleApproverEmail() {
      await this.$profileProvider
        .getRoleApproverEmailById(this.userProfile.id, false)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('data', data)
            this.role_level = []
            data.forEach((e, i) => {
              this.role_level.push({
                index: i + 1,
                text: e.first_name.th + ' ' + e.last_name.th,
                email: e.email,
                $isDisabled: false,
                user_open_id: e.user_open_id,
                first_name: e.first_name,
                last_name: e.last_name,
              })
            })
          }
        })
    },
    onFilterOptionApprover(index) {
      const optionApprover = JSON.parse(JSON.stringify(this.role_level))
      let levels = this.levels[index]
      this.$nextTick(() => {
        levels.approverList.forEach((v) => {
          optionApprover[optionApprover.findIndex((e) => e.email === v.email)][
            '$isDisabled'
          ] = true
        })
      })

      return optionApprover
    },
    onCreateLevels() {
      this.getRoleApproverEmail()
      this.$nextTick(() => {
        this.levels.push({
          limit: '',
          period: '',
          level_index: 0,
          approverSelect: '',
          approverList: [],
          optionsApprover: this.role_level,
        })
      })
    },
    onRemoveLevels(index) {
      this.levels.splice(index, 1)
    },
    onTemp() {
      this.levels = []
      this.getUserById()
    },
    onAddApprover(index) {
      let levels = this.levels[index]
      this.$nextTick(() => {
        levels.approverList.push(levels.approverSelect)
        levels.approverSelect = ''
      })
      console.log('levels', levels)
    },
    onRemoveApprover(index, email) {
      this.$nextTick(() => {
        // remove approver
        this.levels[index].approverList.splice(
          this.levels[index].approverList.findIndex((e) => e.email === email),
          1
        )
      })
    },
    async onSave() {
      console.log('object', this.levels)
      this.requester_lines = []

      this.is_error =
        this.levels.findIndex(
          (e, index) =>
            (index === 0 && parseInt(e.limit) === 0) ||
            (index > 0 &&
              parseInt(e.limit) <= parseInt(this.levels[index - 1].limit))
        ) >= 0
          ? false
          : true

      this.is_period = this.levels.some((e) => parseInt(e.period) === 0)
        ? false
        : true

      if (this.is_error && this.is_period) {
        this.approval = []
        this.levels.forEach((e) => {
          this.temp_approver = []
          e.approverList.forEach((a) => {
            this.temp_approver.push({
              user_open_id: a.user_open_id,
              email: a.email,
              first_name: a.first_name,
              last_name: a.last_name,
            })
          })

          this.approval.push({
            level: e.level_index,
            credit_limit: parseInt(e.limit),
            period: parseInt(e.period),
            type: 'Any',
            approver: this.temp_approver,
          })
        })
        this.temp_approval = {
          approval: this.approval,
        }

        console.log('object', this.temp_approval)

        await this.$profileProvider
          .updateApproval(this.$route.query.cost, this.temp_approval)
          .then(
            (result) => {
              if (result.status === 204) {
                this.onGoBack()
              }
            },
            (error) => {
              console.log('createRequesterLinesData', error.responsed)
            }
          )
      }
    },
    onGoBack() {
      this.$router.push({
        path: this.$i18n.path(
          'company/user/manage/setting/' + this.$route.params.id
        ),
      })
    },
  },
}
