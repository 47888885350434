
import BreadCrumb from '@/components/template/bread-crumb.vue'
import notfound from '@/components/share/notfound.vue'

export default {
  // layout({ store }) {
  //   if (store.state.authen.isAuthenticated || $route.name !== 'biz') {
  //     return 'main'
  //   } else return 'b2b'
  // },
  layout: 'main',
  name: 'ActivityPage',
  components: {
    breadcrumb: BreadCrumb,
    notfound,
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`activity/${this.$route.params.url}`),
      //   },
      // ],
    }
  },
  async asyncData({ route, app, store, $axios }) {
    let url = route.params.url
    // default
    let pageTitle = url
    let pageContent = url
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'

    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    // axios
    try {
      const responsed = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/statics/${url}/meta`
        ),
        {
          headers,
        }
      )

      if (responsed.status === 200) {
        let lang = store.state.locale
        if (app.$utils.isValid(responsed.data.title_tag[lang])) {
          pageTitle = responsed.data?.title_tag[lang]
        }
        if (app.$utils.isValid(responsed.data.description_tag[lang])) {
          pageContent = responsed.data?.description_tag[lang]
        }
        console.log('responsed', responsed.data)
      }
    } catch (e) {
      console.log('catch', e)
    }
    return {
      // name: url,
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
    }
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Home',
          href: '/',
        },
      ],
      // name: '',
      url: '',
      title: '',
      banner: null,
      detail: '',
    }
  },
  middleware(ctx) {
    let userProfile = ''
    switch (ctx.store.getters['user/profileType']) {
      case 'standard':
        userProfile = ctx.store.getters['user/userProfile'].offline_id
        break
      case 'eprocurement':
        userProfile = ctx.store.getters['user/userProfile'].company_code
        break
      default:
        break
    }
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  created() {
    this.lang = this.$store.state.locale
    // this.name = this.$route.params.name
    this.url = this.$route.params.url
    this.getBannerStaticByNameOrUrl()
  },
  methods: {
    async getBannerStaticByNameOrUrl() {
      let params = new URLSearchParams()
      // params.append('name', this.name ?? '')
      params.append('url', this.url ?? '')
      const response = await this.$bannerProvider.getBannerStaticByNameOrUrl(
        params
      )
      if (response.status === 200) {
        console.log('bannerProvider', response.data)
        this.banner = response.data
        this.detail =
          this.lang === 'en' ? this.banner.detail.en : this.banner.detail.th
        // this.breadcrumbItems.push({
        //   text: this.banner.title,
        // })
      } else {
        this.banner = {}
      }
    },
  },
  mounted() {
    this.$_onGetInsider({
      page: {
        type: 'Other',
        custom: {
          category_name: null,
        },
      },
    })
  },
}
