
import { mapGetters } from 'vuex'
import {
  NotificationEnum,
  NotificationOFMEnum,
  NotificationSenderType,
} from '@/enum/notification.enum.js'
export default {
  name: 'NotificationList',
  components: {},

  data() {
    return {
      isLoading: true,
      objNotification: new Array(),
      objNotificationAll: new Array(),
      maxNotification: 0,
      page: 1,
      hasNextPage: true,
      loadFlag: true,
      loadData: false,
      lang: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  created() {
    this.isLoading = true
  },
  beforeMount() {
    window.addEventListener('scroll', this.lazyLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoad)
  },
  updated() {
    this.$nextTick(() => {
      this.lang = this.$i18n.locale
    })
  },
  mounted() {
    this.lang = this.$i18n.locale
    this.maxNotification = 10
    this.getNotification()
    //this.getTotalMessages()
  },
  methods: {
    async getNotification() {
      this.loadFlag = false
      //this.isLoading = true
      //this.objNotification = new Array()
      const params = new URLSearchParams()
      params.append('page', this.page)
      params.append('pageSize', this.maxNotification)
      params.append('workspace_id', this.userProfile.id)
      params.append('notification_channel', 'OrderNotification')
      if (this.hasNextPage) {
        let result = await this.$productNotifyMeProvider.getNotification(params)
        console.log('Noti getNotificationApi : ', result)
        if (result.status === 200) {
          if (result.data != undefined && result.data != '') {
            this.page = this.page + 1
            this.hasNextPage = result.data.hasNextPage

            result.data.items.forEach((element, index) => {
              let payloadContent = JSON.parse(element.web_notification.content)
              let senderName =
                this.lang == 'en'
                  ? element.sender.sender_name.en === ''
                    ? element.sender.sender_name.th
                    : element.sender.sender_name.en
                  : element.sender.sender_name.th
              let content = {
                id: element._id,
                title:
                  this.lang == 'en'
                    ? payloadContent.title_en
                    : payloadContent.title_th,
                date: '',
                body:
                  this.lang == 'en'
                    ? payloadContent.content_en
                    : payloadContent.content_th,
                active:
                  element.status == NotificationEnum.UNREAD ? true : false,
                timestamp: element.timestamp,
                status: element.status,
                sender_name: senderName,
                firstCha: this.getFirstChalater(
                  element.sender.sender_type,
                  senderName,
                  element.sender.photo_url
                ),
                redirectPath:
                  element.web_notification.interact_methods.click.action_value,
                isOFMsender:
                  element.sender.sender_type ==
                  NotificationSenderType.application
                    ? true
                    : false,
                remark: payloadContent.remark,
              }

              this.objNotification.push(content)
            })
          }
        }
      }
      this.onNotification()
      console.log('noti this.objNotification : ', this.objNotification)
      this.isLoading = false
      this.loadFlag = true
    },
    async getTotalMessages() {
      const params = new URLSearchParams()
      params.append('notification_channel', 'OrderNotification')
      params.append('workspace_id', this.userProfile.id)
      let unreadItems =
        await this.$productNotifyMeProvider.getNotificationCount(params)
      if (unreadItems.status === 200) {
        this.maxNotification = unreadItems.data.total
      }
    },
    onNotification() {
      this.objNotification.forEach((element, index) => {
        this.$set(
          this.objNotification[index],
          'date',
          this.timeSince(element.timestamp)
        )
      })
    },
    getFirstChalater(sender_type, name, logo_uri) {
      if (sender_type == NotificationSenderType.application) {
        if (logo_uri == '' || logo_uri === null) {
          logo_uri = NotificationOFMEnum.LINK_CDN
        }
        return logo_uri
      } else {
        if (name !== null && name !== undefined) {
          name = name.trim()
          let first_last_name = name.split(' ')
          let firstname = this.regularVowels(first_last_name[0]).charAt(0)
          let lastname = ''
          if (first_last_name.length >= 2) {
            lastname = this.regularVowels(first_last_name[1]).charAt(0)
          }
          return firstname + lastname
        }
      }
      return ''
    },
    regularVowels(name) {
      let vowels = ['เ', 'แ', 'โ', 'ใ', 'ไ']
      for (const element of vowels) {
        name = name.replace(element, '')
      }
      return name
    },
    timeSince(dateObject) {
      let date = new Date(dateObject)
      let dataAndTime =
        ('0' + (date.getDate() + 1)).slice(-2) +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        date.getFullYear() +
        ' ' +
        date.getHours() +
        ':' +
        ('0' + (date.getMinutes() + 1)).slice(-2)
      let seconds = Math.floor((new Date() - date) / 1000)
      let interval = seconds / 31536000
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 86400
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$t(`notification.hours_ago`)
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$t(`notification.minutes_ago`)
      }
      return (
        (Math.floor(seconds) <= 0 ? 1 : Math.floor(seconds)) +
        ' ' +
        this.$t(`notification.seconds_ago`)
      )
    },
    async read(item, uri) {
      this.isLoading = true
      console.log('Noti myNotificationUpdate2 : ', uri)
      this.$productNotifyMeProvider
        .updateStatusNotification(item.id, NotificationEnum.READ)
        .then(() => {
          this.$nuxt.$emit('myNotificationUpdate')
          let redirectPath = {
            path: this.$i18n.path(`${uri}`),
          }

          this.$router.push(redirectPath)
        })

      this.isLoading = false
    },
    async deleteNoti(item) {
      this.isLoading = true
      this.$productNotifyMeProvider.deleteNotification(item.id).then(() => {
        this.$nuxt.$emit('myNotificationUpdate')
        let index = this.objNotification.indexOf(item)
        if (index !== -1) {
          this.objNotification.splice(index, 1)
        }
      })
      this.isLoading = false
    },
    async MarkReadAll() {
      this.isLoading = true
      let apiData = JSON.stringify({
        workspace_id: this.userProfile.id,
        notification_channels: ['OrderNotification'],
      })
      this.$productNotifyMeProvider.markReadAllNoti(apiData).then((result) => {
        this.$nuxt.$emit('myNotificationUpdate')
        if (result.status === 200 || result.status === 204) {
          this.objNotification.forEach((element, index) => {
            element.active = false
          })
          console.log('Noti myNotificationUpdate2 : ', this.objNotification)
        }
      })
      this.isLoading = false
    },
    resetLoadFlag() {
      this.loadFlag = true
    },
    async lazyLoad() {
      if (document.getElementById('load-items') != null) {
        if (
          document.getElementById('load-items').getBoundingClientRect().y <
          window.innerHeight - 80
        ) {
          if (this.loadFlag) {
            console.log('noti lazyLoad')
            this.getNotification()
          }
        }
      }
    },
  },
}
