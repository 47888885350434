
import asideComponent from '@/components/template/aside-user.vue'
import listOrder from '@/components/order/list-order.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'OrderHistory',
  components: {
    'aside-component': asideComponent,
    'list-order': listOrder,
  },
  data() {
    return {
      filter: '',
      selectedOrder: 'waiting',
      filterOrder: [],
      orderDocument: {
        pageNo: 1,
        pageSize: 20,
        items: [],
        totalRecords: 0,
        totalPages: 0,
        pageIndex: 1,
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
  },
  watch: {
    selectedOrder: {
      handler: function (val) {
        // if (val === 'all') {
        //   this.filterOrder = this.orderHistory
        // } else {
        //   this.filterOrder = this.orderHistory.filter(function (e) {
        //     return e.status === val
        //   })
        // }
      },
    },
    $route: {
      handler: async function (val) {
        if (
          Object.keys(this.$route.query).length &&
          this.$utils.isValid(this.$route.query.tab)
        ) {
          this.selectedOrder = this.$route.query.tab
          let orderStatus = ''
          switch (this.selectedOrder) {
            case 'waiting':
              orderStatus = 'Waiting'
              break
            case 'complete':
              orderStatus = 'Complete'
              break
            case 'inprogress':
              orderStatus = 'InProgress'
              break
            case 'shipping':
              orderStatus = 'Shipping'
              break
            case 'delivered':
              orderStatus = 'Delivered'
              break
            case 'cancel':
              orderStatus = 'Cancel'
              break
          }
          if (orderStatus !== 'Waiting') this.orderDocument.pageSize = 20
          await this.searchOrder(
            orderStatus,
            1,
            orderStatus === 'Waiting' ? 9999 : this.orderDocument.pageSize
          )
        } else await this.searchOrder('Waiting', 1, 9999)
      },
      deep: true,
    },
  },
  async mounted() {
    if (
      Object.keys(this.$route.query).length &&
      this.$utils.isValid(this.$route.query.tab)
    ) {
      this.selectedOrder = this.$route.query.tab
      let orderStatus = ''
      switch (this.selectedOrder) {
        case 'waiting':
          orderStatus = 'Waiting'
          break
        case 'complete':
          orderStatus = 'Complete'
          break
        case 'inprogress':
          orderStatus = 'InProgress'
          break
        case 'shipping':
          orderStatus = 'Shipping'
          break
        case 'delivered':
          orderStatus = 'Delivered'
          break
        case 'cancel':
          orderStatus = 'Cancel'
          break
      }
      if (orderStatus !== 'Waiting') this.orderDocument.pageSize = 20
      await this.searchOrder(
        orderStatus,
        1,
        orderStatus === 'Waiting' ? 9999 : this.orderDocument.pageSize
      )
    } else await this.searchOrder('Waiting', 1, this.orderDocument.pageSize)
    // this.filterOrder = this.orderHistory.filter(function (e) {
    //   return e.status === 'order'
    // })
  },
  methods: {
    activeLinkSideBar(tabStatus) {
      this.$router.push({
        path: this.$i18n.path('order/history'),
        name:
          this.$i18n.locale === 'th' ? 'order-history' : 'lang-order-history',
        query: { tab: tabStatus },
      })
    },
    async onFilterSearch(payload, isStackResult) {
      console.log('payload', payload)
      if (isStackResult) payload.pageNo += 1
      let orderStatus = ''
      switch (this.selectedOrder) {
        case 'waiting':
          orderStatus = 'Waiting'
          break
        case 'complete':
          orderStatus = 'Complete'
          break
        case 'inprogress':
          orderStatus = 'InProgress'
          break
        case 'shipping':
          orderStatus = 'Shipping'
          break
        case 'delivered':
          orderStatus = 'Delivered'
          break
        case 'cancel':
          orderStatus = 'Cancel'
          break
      }
      this.orderDocument.pageSize = payload.pageSize
      await this.searchOrder(
        orderStatus,
        payload.pageNo,
        payload.pageSize,
        isStackResult,
        payload.orderId,
        payload.startDate,
        payload.endDate
      )
    },
    async searchOrder(
      orderStatus,
      page,
      pageSize,
      isStackResult = false,
      orderId,
      startDate,
      endDate
    ) {
      const params = new URLSearchParams()
      params.append('order_status', orderStatus)
      params.append('profile_id', this.userProfile.id)
      params.append('user_open_id', this.openId)
      params.append('account_channel', this.profileType)
      if (this.profileType === 'eprocurement') {
        params.append('is_admin', this.isAdmin)
        // if (this.userProfile.roles !== null && this.userProfile.roles.length) {
        //   let isAdmin = this.userProfile.roles.some(
        //     (q) => q.role_id === 1 || q.role_id === 2
        //   )
        //   params.append('is_admin', isAdmin)
        // }
      }
      if (orderId !== undefined) params.append('order_id', orderId)
      if (this.$utils.isValid(startDate) && this.$utils.isValid(endDate)) {
        let order_date_begin = this.$utils.convertDatetoTicks(startDate, 'from')
        let order_date_until = this.$utils.convertDatetoTicks(endDate, 'to')
        params.append('order_date_from', order_date_begin)
        params.append('order_date_to', order_date_until)
      }
      if (orderStatus === 'Waiting') params.append('pageSize', 9999)
      else {
        if (pageSize !== undefined) params.append('pageSize', pageSize)
      }
      if (page !== undefined) params.append('page', page)
      let result = await this.$orderProvider.searchOrders(params)
      console.log(result)
      if (result.status === 200) {
        if (result.data.items.length) {
          result.data.items.forEach((item) => {
            item.isChecked = false
            item.hasApprove = false
            if (item.approval_status === 'WaitingAdmin' && this.isAdmin)
              item.hasApprove = true
            else if (item.approval_status === 'Waiting') {
              if (item.approval_information !== null) {
                let currentLevel = item.approval_information.current_level
                let approvalCurrentLevel =
                  item.approval_information.approval.find(
                    (q) => q.level === currentLevel
                  )
                if (approvalCurrentLevel !== undefined) {
                  item.hasApprove = approvalCurrentLevel.approver.some(
                    (q) =>
                      q.user_open_id === this.openId &&
                      q.approval_status !== 'Approve'
                  )
                }
              }
            }
          })

          if (isStackResult) {
            this.orderDocument.pageNo = result.data.pageNo
            this.orderDocument.pageSize = result.data.pageSize
            this.orderDocument.totalRecords = result.data.totalRecords
            this.orderDocument.totalPages = result.data.totalPages
            result.data.items.forEach((item) => {
              this.orderDocument.items.push(item)
            })
            this.$refs['list-order'].resetLoadFlag()
          } else this.orderDocument = result.data

          this.orderDocument.pageIndex =
            (result.data.pageNo - 1) * result.data.pageSize + 1
        } else {
          this.orderDocument = {
            pageNo: 1,
            pageSize: 20,
            items: [],
            totalRecords: 0,
            totalPages: 0,
            pageIndex: 1,
          }
        }
      }
    },
  },
}
