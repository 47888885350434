
import InputText from '@/components/share/input-text.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'OTPModalComponent',
  components: {
    'input-text': InputText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
    openId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reloadVerify: true,
      dataForOTP: {
        otp: '',
      },
      countDownOTP: 60,
    }
  },
  computed: {
    ...mapGetters('authen', ['accessToken']),
    labelEmail() {
      let msg = this.$t('register-new.otpModal.label2')
      return msg.replace('{email}', this.email)
    },
    // getEmail() {
    //   if (this.$utils.isValid(this.accessToken)) {
    //     return this.accessToken.email
    //   } else return ''
    // },
  },
  watch: {
    show: function (value) {
      if (value) {
        this.$refs.otpmodal.show()
      }
    },
    countDownOTP: function (val) {
      if (val > 0) {
        setTimeout(() => {
          this.countDownOTP -= 1
        }, 1000)
      } else {
        console.log('time out')
      }
    },
  },
  mounted() {
    console.log(this.$route.query.code)
    if (
      this.$utils.isValid(this.$route.query) &&
      this.$utils.isValid(this.$route.query.code)
    ) {
      // await this.verifyUser(this.$route.query.code)
      this.dataForOTP.otp = this.$route.query.code
    }
  },
  beforeDestroy() {},
  methods: {
    onClose() {
      this.$refs.otpmodal.hide()
      this.$emit('hidden')
    },
    // onConfirm() {
    //   this.$refs.otpmodal.hide()
    // },
    ...mapActions('authen', ['setAuthToken']),
    ...mapActions('cart', ['setCartId']),
    async onSubmit() {
      this.$refs.otpVerifyForm.validate().then(async (result) => {
        if (result) {
          await this.verifyUserByOTP(this.dataForOTP.otp)
        }
      })
    },
    async verifyUserByOTP(code) {
      let apiData = JSON.stringify({
        otp_code: code,
      })
      const result = await this.$profileProvider.verifyUserByOTP(apiData)
      if (result.status === 201) {
        this.$refs.otpmodal.hide()
        this.$router.push({ path: this.$i18n.path('register-complete') })
      }
    },
    async resendOTP() {
      let apiData = JSON.stringify({
        open_id: this.openId,
      })
      const result = await this.$authenProvider.requestOneTimePassword(apiData)
      if (result.status === 204) {
        this.$bvToast.toast('กรุณาตรวจสอบอีเมลของคุณ', {
          title: `Resend OTP Success`,
          variant: 'success',
          solid: true,
        })
      }
    },
  },
}
