
import { mapGetters } from 'vuex'

export default {
  name: 'TableReportCategory',
  props: {
    items: { type: Array, default: () => [] },
    detail: { type: Boolean, default: false },
    isPrinting: { type: Boolean, default: false },
  },
  data() {
    return {
      fields: [
        {
          key: 'department_id',
          label: 'รหัสควบคุม',
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'budget_control',
          label: 'ระยะเวลาควบคุม',
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'year',
          label: 'ปี',
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'budget_start',
          label: 'งบประมาณเริ่มต้น',
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'budget_current',
          label: 'งบประมาณปัจจุบัน',
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'budget_used',
          label: 'งบประมาณที่ใช้ไป',
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'budget_left',
          label: 'งบประมาณคงเหลือ',
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  created() {
    if (this.detail) {
      this.fields.push(
        {
          key: 'order_ref',
          label: 'อ้างอิงใบสั่งซื้อ',
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'process_date',
          label: 'วันที่ดำเนินการ',
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatDateTime(value)
          },
        }
      )
    } else {
      this.fields.push({
        key: 'validate',
        label: 'ตรวจสอบ',
        thClass: '',
        tdClass: 'text-center',
        sortable: false,
      })
    }
  },
  methods: {
    onValidate(id, month, year, type, ptype) {
      this.$router.push(
        !this.isPrinting
          ? {
              path: this.$i18n.path('company/report/budget/' + id),
              query: { type: type, period: month, year: year, ptype: ptype },
            }
          : {
              path: this.$i18n.path('company/report/budget-printing/' + id),
              query: { type: type, period: month, year: year, ptype: ptype },
            }
      )
    },
  },
}
