
export default {
  name: 'SelectObjectComponent',
  props: {
    label: { type: String, default: '' },
    width: { type: String, default: '100%' },
    placeholder: { type: String, default: '' },
    rules: { type: Object, default: Object },
    fieldName: { type: String, default: '' },
    // format: { type: String, default: '' },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    disabled: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    value: { type: [Object, String], default: '' },
  },
  data() {
    return {
      select: '',
      selected: '',
    }
  },
  computed: {
    isEmpty: function () {
      return this.validate && this.select === '' ? true : false
    },
    horizontal: function () {
      return this.vertical ? 'horizontal' : ''
    },
  },
  watch: {
    value: {
      handler() {
        this.selected = this.value
      },
      deep: true,
    },
  },
  methods: {
    onSelected(event) {
      if (event.target.options.selectedIndex !== -1) {
        this.$emit(
          'input',
          event.target.options[event.target.options.selectedIndex]._value
        )
        this.$emit('change')
      }
    },
  },
}
