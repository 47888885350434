
export default {
  name: 'SummaryComponent',
  props: {
    isCheckout: { type: Boolean, default: false },
    isUseBudget: { type: Boolean, default: false },
    summary: { type: Object, default: Object },
    checkoutText: { type: String, default: String },
    budget: { type: Object, default: Object },
    isEprocuerment: { type: Boolean, default: false },
  },
  computed: {
    alertVoucher() {
      if (Object.keys(this.summary).length) {
        return this.$utils.isValid(this.summary.voucher_no) &&
          this.summary.voucher_disc_amt_excvat <= 0
          ? true
          : false
      } else return false
    },
  },
}
