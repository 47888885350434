
import InputQty from '@/components/share/input-qty.vue'

export default {
  name: 'ListAccessoryComponent',
  components: {
    'input-qty': InputQty,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      compare: [],
      qty: '1',
    }
  },
  computed: {},
  mounted() {
    // console.log(this.item)
  },
  methods: {},
}
