
export default {
  name: 'QrConsentComponent',
  props: {
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isExpired: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // ทำอะไรบางอย่างเมื่อ onExpired เปลี่ยนเป็น true
          this.showRefreshButton = true // แสดงปุ่ม Refresh
        } else {
          // ทำอะไรบางอย่างเมื่อ onExpired เปลี่ยนเป็น false
          this.showRefreshButton = false // ซ่อนปุ่ม Refresh
        }
      },
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
