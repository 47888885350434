
import BreadCrumb from '@/components/template/bread-crumb.vue'
import MainCategoryList from '@/components/category/main-category-list.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'
import notfound from '@/components/share/notfound.vue'

export default {
  name: 'CategoryLevel2',
  components: {
    breadcrumb: BreadCrumb,
    'main-category-list': MainCategoryList,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    // 'swiper-image': SwiperImage,
    notfound,
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            `https://www.ofm.co.th` +
            this.$i18n.path(
              `category/${this.department_title}/${this.category_title}`
            ),
        },
      ],
      script: [
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: this.$nuxt._route.params.department, //Main Category
                item:
                  `https://www.ofm.co.th` +
                  this.$i18n.path(`category/${this.$route.params.department}`),
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: this.$nuxt._route.params.category, //Tier 2 Sub-Category
              },
            ],
          },
        },
      ],
    }
  },
  // async fetch() {
  //   if (process.server) {
  //     await this.getCategoriesFromLayout()
  //   }
  // },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  async asyncData({ route, app, store, $axios }) {
    let pageTitle = `${app.i18n.t('meta.defaultTitle')}`
    let pageContent = `${app.i18n.t('meta.description')}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'
    let catL_1 = {}
    let catL_2 = {}
    let department_title = ''
    let category_title = ''
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    const params = {
      seo_name_level2: route.params.category,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)
    // axios
    try {
      const getCategoryMeta = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/${route.params.department}/meta/`
        ),
        {
          params,
          headers,
        }
      )
      console.log('getCategoryMeta', getCategoryMeta)
      if (getCategoryMeta.status === 200) {
        const categories = getCategoryMeta.data.categories

        catL_1 = categories.find((q) => q.level === 1)
        catL_2 = categories.find((q) => q.level === 2)
        // console.log('catL_1', catL_1)

        let otherLang = store.state.locale === 'th' ? 'en' : 'th'
        // PLP 3
        // default name
        department_title = catL_1.meta.seo_name[store.state.locale]
        category_title = catL_2.meta.seo_name[store.state.locale]
        let pathName = catL_2.display_name[store.state.locale]

        if (app.$utils.isObjectValid(catL_2.meta)) {
          if (app.$utils.isObjectValid(catL_2.meta.title_tag)) {
            let titleTag = catL_2.meta.title_tag
            pageTitle = app.$utils.isValid(titleTag[store.state.locale])
              ? titleTag[store.state.locale]
              : titleTag[otherLang]
          } else {
            // default pageTitle
            pageTitle = `${pathName}`
          }

          if (app.$utils.isObjectValid(catL_2.meta.description_tag)) {
            let descriptionTag = catL_2.meta.description_tag
            pageContent =
              // app.i18n.t('meta.descShop') +
              app.$utils.isValid(descriptionTag[store.state.locale])
                ? descriptionTag[store.state.locale]
                : descriptionTag[otherLang]
          } else {
            // default pageContent
            pageContent = `${app.i18n.t('meta.descShop')}${pathName}`
          }
        }
      }
    } catch (e) {
      console.log('catch', e)
    }
    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
      cateIdLevelOne: catL_1.id,
      cateIdLevelTwo: catL_2.id,
      department_title: department_title,
      category_title: category_title,
    }
  },
  data() {
    return {
      asyncCateIdLevelOne: '',
      asyncCateIdLevelTwo: '',
      // breadcrumbItems: [
      //   {
      //     text: this.$nuxt._route.params.department,
      //     href: this.$i18n.path(`category/${this.$route.params.department}`),
      //   },
      //   {
      //     text: this.$nuxt._route.params.category,
      //     href: this.$i18n.path(
      //       `category/${this.$route.params.department}/${this.$route.params.category}`
      //     ),
      //   },
      // ],
      sortBy: [
        {
          text: this.$t('category_department_category.dropdownSortByDiscount'),
          value: '0',
        },
        {
          text: this.$t('category_department_category.dropdownPriceLowHigh'),
          value: '1',
        },
        {
          text: this.$t('category_department_category.dropdownPriceHighLow'),
          value: '2',
        },
        {
          text: this.$t('category_department_category.dropdownAZ'),
          value: '3',
        },
        {
          text: this.$t('category_department_category.dropdownZA'),
          value: '4',
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      filter: {},
      sort: '',
      view: 'grid',
      categoryName: {},
      categorySEOName: {},
      subCategory: [],
      category: [],
      updateRoute: '',
      count: 0,
      seoNameLevelTwo: '',
      seoNameLevelOne: '',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      active: '',
      cateIdLevelOne: '',
      cateIdLevelTwo: '',
      defaultCreate: '',
      enableSort: false,
      sorting: -1,
      sortField: '',
      pageTitle: '',
      pageContent: '',
      filterProductStatus: ['Inactive', 'Delete'],
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      // skeleton: true,
      skeletonPage: true,
      isNotFound: false,
      showItems: false,
      categoryContent: '',
      showContent: false,
      firstTime: true,
      showCategoryContent: false,
      overlayImages: [],
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isItems() {
      return (
        this.$utils.anyArrayObjectValid(this.attribute) ||
        this.$utils.anyArrayObjectValid(this.items)
      )
    },
    breadcrumbItems() {
      return [
        {
          text: this.categoryName[this.$store.state.locale],
          href: this.$i18n.path(`category/${this.$route.params.department}`),
        },
        {
          text: this.updateRoute,
          href: this.$i18n.path(
            `category/${this.$route.params.department}/${this.$route.params.category}`
          ),
        },
      ]
    },
    // sortedItems () {
    // 	if (this.enableSort && this.sortField !== '') {
    // 		return this.items.slice(0).sort((a, b) => a[this.sortField] < b[this.sortField] ? this.sorting : -this.sorting )
    //  	} else {
    // 		return this.items
    //   }
    // },
  },
  watch: {
    // async cateIdLevelTwo() {
    //   console.log('555')
    //   this.setTitle()
    //   await this.onAsyncDataCatId()
    //   await this.getAttribute()
    // },
  },
  created() {
    /* กำหนดค่า seo name จาก route */
    this.seoNameLevelOne = this.$nuxt._route.params.department
    // /* กำหนดค่า cate name จาก route */
    // this.categoryName = this.$nuxt._route.params.department
    /* กำหนดค่า seo name จาก route */
    this.seoNameLevelTwo = this.$nuxt._route.params.category

    this.lang = this.$store.state.locale
    /* func returnCategory เพื่อรอให้ default.vue เป็นตัวเรียกเพื่อส่งข้อมูล ข้อมูลที่ได้คือ cate lv.1 ที่มีข้อมูลใน items
    เป็นcate lv.2 ส่งเข้า func setCategory เพื่อ assign ค่า*/
    this.$nuxt.$on('returnCategory', (data) => {
      this.setCategory('two', data)
    })
    /* func returnCategoryLevelTwo เพื่อรอให้ default.vue เป็นตัวเรียกเพื่อส่งข้อมูล ข้อมูลที่ได้คือ cate lv.2 ที่มีข้อมูลใน items
    เป็นcate lv.3 ส่งเข้า func setCategory เพื่อ assign ค่า*/
    this.$nuxt.$on('returnCategoryLevelTwo', (data) => {
      this.setCategory('three', data)
    })

    this.$nuxt.$on('onCategoryReady', () => {
      this.getCategoriesFromLayout()
    })

    /* func returnCategoryLevelTwo เพื่อรอให้ default.vue เรียกในกรณีที่ มีการ refresh หน้า
    เพื่อรอให้ defaul render และเรียกข้อมูล cate เสร็จจะเรียก func นี้เพื่อทำงานต่อ*/
    // this.$nuxt.$on('render', (result) => {
    //   // console.log('render default: ', result)
    //   /* เมื่อ default.vue render เสร็จแล้ว เราจะทำการเรียก getCategoriesFromLayout เพื่อดึงค่า cate จาก layout*/
    //   this.getCategoriesFromLayout()
    // })
  },
  updated() {
    // if (!this.skeleton) {
    //   this.skeletonPage = false
    // }
    this.$_onGetInsider({
      page: {
        type: 'Category',
        custom: {
          category_name: this.categoryName[this.$store.state.locale],
        },
      },
    })
    this.$_onInsiderResult({
      taxonomy: [this.categoryName[this.$store.state.locale], this.updateRoute],
      result: [...(this.items || [])],
    })
    console.log('skeletonPage', this.skeletonPage)
  },
  async mounted() {
    /* ในกรณีที่เข้าหน้าเว็บแบบปกติไม่ได้ผ่านการ refresh default.vue จะมีข้อมูลของ cate อยู่แล้วเราจึงสามารถเรียก
    requestCategory เพื่อข้อมูลของ cate */
    // console.log('mounted category')
    /* ส่งชื่อ seo name ของ cate ไปเพื่อค้นหาข้อมูล ของcate lv หนึ่ง */
    // this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    /* ส่งชื่อ seo name ของ cate ไปเพื่อค้นหาข้อมูล ของcate lv สอง */
    // this.$nuxt.$emit('requestCategory', this.seoNameLevelTwo, 2)
    // process.client &&

    await this.onAsyncDataCatId()
    await this.getCategoriesFromLayout()

    this.setTitle()
    await this.getAttribute()
    window.addEventListener('load', this.onBoundingLoad)
    window.addEventListener('scroll', this.onBoundingLoad)
  },
  beforeDestroy() {
    this.$nuxt.$off('returnCategory')
    this.$nuxt.$off('returnCategoryLevelTwo')
    this.$nuxt.$off('onCategoryReady')
    window.removeEventListener('load', this.onBoundingLoad)
    window.removeEventListener('scroll', this.onBoundingLoad)
  },
  methods: {
    async onAsyncDataCatId() {
      this.showItems = false
      if (this.cateIdLevelOne && this.cateIdLevelTwo) {
        setTimeout(async () => {
          let url = new URL(window.location.href).searchParams.toString().length
          this.firstTime = url === 0
          if (this.firstTime) {
            await this.getProduct()
            this.showItems = true
          }
        }, 100)
      } else {
        this.isNotFound = true
      }
    },
    /* func จะถูกเรียกใช้ในกรณี มีการ refresh จะทำงานเมื่อ default.vue render เสร็จ */
    async getCategoriesFromLayout() {
      await this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
      await this.$nuxt.$emit('requestCategory', this.seoNameLevelTwo, 2)
      console.log('check')
    },
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    /* func assign ค่าที่ได้จาก layout ส่งมา */
    async setCategory(level, data) {
      if (
        this.$utils.isObjectValid(data) &&
        this.$utils.isObjectValid(data.items)
      ) {
        /* ข้อมุลของ cate 1 ที่มีข้อมูลของ lv.2 ใน items เพื่อใช้แสดงในแทบด้านบน */
        if (level === 'two') {
          this.categoryName = data.display_name
          this.categorySEOName = data.meta.seo_name
          this.category = data.items.filter((a) => a.status === 'Active')
          // this.cateIdLevelOne = data.id
          // this.cateIdLevelOne = this.asyncCateIdLevelOne
          console.log('retunrdata: two', data)
          /* ข้อมูลของ cate 2 ที่มีข้อมูล lv.3 ใน itmes เพื่อแสดงเวาลาเราคลิ๊กเลือก cate lv.2 */
        } else if (level === 'three') {
          this.updateRoute = data.display_name[this.$store.state.locale]
          this.subCategory = data.items.filter((a) => a.status === 'Active')
          console.log('retunrdata: three', data)
          this.active = data.id
          // this.cateIdLevelTwo = this.asyncCateIdLevelTwo
          // this.cateIdLevelTwo = data.id
          // await this.$productProvider.getCategoryById(data.id).then(
          //   async (result) => {
          //     if (result.status === 200) {
          //       if (this.$utils.isObjectValid(result.data.meta)) {
          //         this.pageTitle = result.data.meta.title_tag[this.$i18n.locale]
          //         this.pageContent =
          //           result.data.meta.description_tag[this.$i18n.locale]
          //       }
          //     }
          //   },
          //   (error) => {
          //     console.log('getBannerCategoryData', error.responsed)
          //   }
          // )
          // await this.getProduct()
        }
      } else {
        this.skeletonPage = false
        this.skeletonSwiper = false
        // this.isNotFound = true
      }
    },
    async setTitle() {
      await this.$productProvider.getCategoryById(this.cateIdLevelTwo).then(
        async (result) => {
          if (result.status === 200) {
            this.categoryContent = result.data.content[this.$i18n.locale]
            // let otherLang = this.$i18n.locale === 'th' ? 'en' : 'th'

            // if (this.$utils.isObjectValid(result.data.meta)) {
            //   if (this.$utils.isObjectValid(result.data.meta.title_tag)) {
            //     let titleTag = result.data.meta.title_tag
            //     this.pageTitle = this.$utils.isValid(
            //       titleTag[this.$i18n.locale]
            //     )
            //       ? titleTag[this.$i18n.locale]
            //       : titleTag[otherLang]
            //   }
            //   console.log('this.pageTitle', this.pageTitle)

            //   if (this.$utils.isObjectValid(result.data.meta.description_tag)) {
            //     let descriptionTag = result.data.meta.description_tag
            //     this.pageContent = this.$utils.isValid(
            //       descriptionTag[this.$i18n.locale]
            //     )
            //       ? descriptionTag[this.$i18n.locale]
            //       : descriptionTag[otherLang]
            //   }
            //   this.categoryContent = result.data.content[this.$i18n.locale]
            // }
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    /* เรียก api ข้อมูล product */
    async getProduct() {
      this.skeleton = true
      this.showItems = false
      this.items = []
      // console.log('get product list xxxxxxxxxxxxxxx')
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createParams()
      // console.log('get product', this.cateIdLevelOne, this.cateIdLevelTwo)
      if (this.cateIdLevelOne !== '' && this.cateIdLevelTwo !== '') {
        // console.log('getProduct')
        const response = await this.$productProvider.getProductList(params)
        if (response.status === 200) {
          // await this.getAttribute()
          this.showItems = true
          this.pageTotal = response.data.totalRecords
          this.items = response.data.items
          // get overlay
          if (this.items.length) {
            let pid = this.items.map((item) => {
              return item.skus[0].sku_code
            })
            const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
              pid
            )
            console.log('graphqlResponse', graphqlResponse)
            if (graphqlResponse) {
              this.overlayImages = graphqlResponse
            }
          }

          this.isFirstLoad = false
          console.log('items', this.items)
          if (this.isItems) {
            // ทำ seq เพื่อส่งเข้า gtm event impression
            let startIndex =
              (response.data.pageNo - 1) * response.data.pageSize + 1
            this.items.map((item, index) => {
              item.seq = index + startIndex
            })
            //gtm
            this.$gtm.push({
              event: 'impression',
              currencyCode: 'THB',
              ecommerce: {
                impressions: this.items.map((item) => {
                  return {
                    id: item.skus[0].sku_code,
                    price: this.$utils.formatPrice(
                      item.skus[0].price_include_vat,
                      2,
                      true
                    ),
                    list: 'Category',
                    position: item.seq,
                  }
                }),
              },
            })
            // gtm GA4
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'view_item_list',
              ecommerce: {
                user: this.$store.getters['user/userInfoTrack'],
                item_list_id: this.cateIdLevelTwo,
                item_list_name: this.updateRoute,
                items: this.items.map((item, index) => {
                  const sku = item.skus[0]
                  return {
                    index: index,
                    item_id: sku.sku_code,
                    item_name: sku.product_names[1]?.display_name.th,
                    item_brand: item.brand?.display_name.en,
                    price: sku.price_include_vat,
                    discount:
                      sku.retail_price.include_vat - sku.price_include_vat,
                    item_list_id: this.cateIdLevelTwo,
                    item_list_name: this.updateRoute,
                    ...this.$store.getters['user/userPromotionTrack'],
                  }
                }),
              },
            })
          }
        }
      }
      this.skeleton = false
      // console.log(this.items)
    },
    /* func เพื่อเรียก filter ด้านซ้ายมือโดยมี filter ของแบรนด์สินค้า กับคุณสมบัตรสินค้า */
    async getAttribute() {
      let params = await this.createParams()
      /* api get ข้อมูลของ แบรนด์ของสินค้าที่มี */
      console.log('getAttribute', params.toString())
      const response = await this.$productProvider.getBrand(params)
      console.log('getBrand', response)
      let attr = []
      if (response.status === 200) {
        let brandList = []
        /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })

        if (this.$utils.anyArrayObjectValid(brandList)) {
          attr.push({
            title: this.$t('filterPanelComponent.brand'),
            list: brandList,
          })
        }
      }
      /* api get ข้อมูลคุณสมบัติ ของสินค้าที่มี */
      const responseAttribute = await this.$productProvider.getAttribute(params)
      console.log('getAttribute', responseAttribute)
      if (responseAttribute.status === 200) {
        const attribute = responseAttribute.data
        console.log('attr', attribute)
        /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
        attribute.forEach((element) => {
          let attributeList = []
          if (this.$utils.anyArrayObjectValid(element.value)) {
            element.value.forEach((value, index) => {
              if (
                this.$utils.isObjectValid(value) &&
                this.$utils.isValid(value[this.lang]) &&
                !attributeList.some((q) => q.value.value === value[this.lang])
              ) {
                attributeList.push({
                  text: value[this.lang],
                  value: {
                    index: index,
                    by: element.display_name[this.lang],
                    id: element.id,
                    value: value[this.lang],
                  },
                })
              }
            })
          }

          if (this.$utils.anyArrayObjectValid(attributeList)) {
            attr.push({
              title: element.display_name[this.lang],
              list: attributeList,
            })
          }
        })
      }
      this.attribute = attr
      this.skeletonPage = false
      if (!this.attribute.length) this.showItems = true
      this.skeleton = false
    },
    /* ส่งค่ากลับจาก component filter จากการคลิ๊กเรือกของเรา */
    async emitAttribute(value) {
      console.log('this.firstTime', this.firstTime)
      this.paramAttribute = value
      setTimeout(async () => {
        if (!this.firstTime) {
          await this.getProduct()
          this.showItems = true
        }
        this.firstTime = false
        console.log('on emitAttribute', value)

        // replaceState
        // let query = new URL(window.location.href).search
        // window.history.replaceState(
        //   null,
        //   null,
        //   this.$i18n.path(
        //     `category/${this.department_title}/${this.category_title}${query}`
        //   )
        // )
      }, 100)
    },
    /* สร้าง param เพื่อใช่ในการเรียน api */
    async createParams() {
      let params = new URLSearchParams()
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      params.append('categoryId', this.cateIdLevelOne)
      params.append('categoryId', this.cateIdLevelTwo)
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          console.log('paramAttribute', data.by, data.id, data.value)
          data.by === 'brand'
            ? params.append('brands', data.value)
            : params.append('attributes', data.id + '-' + data.value)
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      if (this.$utils.anyArrayObjectValid(this.filterProductStatus)) {
        this.filterProductStatus.forEach((item) => {
          params.append('productStatus', item)
        })
      }
      console.log('params', params.toString())
      return params
    },
    async selectSort(e) {
      // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
      this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
      if (e.value === '0') this.sort = ''
      else if (e.value === '1') {
        this.sort = 'price-ascending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
      } else if (e.value === '2') {
        this.sort = 'price-descending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
      } else if (e.value === '3') this.sort = 'new-descending'
      else this.sort = 'old-ascending'

      await this.getProduct()
      this.showItems = true
    },
    async onBoundingLoad() {
      if (!this.skeletonPage) {
        let height = 0
        if (
          this.$refs['category-content'].getBoundingClientRect().top <
          window.innerHeight - height
        ) {
          this.showCategoryContent = true
        }
      }
    },
  },
}
