
import confirmModal from '../modal/confirmModal.vue'
export default {
  components: { confirmModal },
  name: 'ListAddressComponent',
  props: {
    address: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    image: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    pagetype: { type: String, default: '' },
  },
  data() {
    return {
      // orderedItems: [],
      isShowConfirmDeleteModal: false,
      selectedData: {},
    }
  },
  computed: {
    orderedItems: function () {
      return this.address.sort((a, b) => b.active - a.active)
    },
  },
  methods: {
    changeActive(index) {
      this.address[
        this.address.findIndex((e) => e.active === true)
      ].active = false
      this.address[index].active = true
    },
    toEditShipping() {
      this.$i18n.path('address/edit/' + this.data.shippingid)
    },
    async updateShippingStatusData(accountid, shippingid) {
      let status = JSON.stringify('Delete')
      await this.$profileProvider
        .updateShippingStatusDataById(accountid, shippingid, status)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            this.$emit('onUpdateShippingStatus')
          },
          (error) => {
            console.log('updateShippingStatusData', error.responsed)
          }
        )
    },
    async updateShippingDefaultData(accountid, shippingid) {
      let isdefault = JSON.stringify(true)
      await this.$profileProvider
        .updateShippingDefaultDataById(accountid, shippingid, isdefault)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            console.log('updateShippingDefaultData', result)
          },
          (error) => {
            console.log('updateShippingDefaultData', error.responsed)
          }
        )
    },
    async updateInvoiceStatusData(accountid, invoiceid) {
      let status = JSON.stringify('Delete')
      await this.$profileProvider
        .updateInvoiceStatusDataById(accountid, invoiceid, status)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            this.$emit('onUpdateInvoiceStatus')
          },
          (error) => {
            console.log('updateInvoiceStatusData', error.responsed)
          }
        )
    },
    async updateInvoiceDefaultData(accountid, invoiceid) {
      let isdefault = JSON.stringify(true)
      await this.$profileProvider
        .updateInvoiceDefaultDataById(accountid, invoiceid, isdefault)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {},
          (error) => {
            console.log('updateInvoiceDefaultData', error.responsed)
          }
        )
    },
    onShowConfirmDeleteModal(value) {
      Object.assign(this.selectedData, value)
      this.isShowConfirmDeleteModal = true
    },
    onConfirmDelete() {
      if (this.pagetype === 'invoice') {
        const { accountid, invoiceid } = this.selectedData
        this.updateInvoiceStatusData(accountid, invoiceid)
      } else {
        const { accountid, shippingid } = this.selectedData
        this.updateShippingStatusData(accountid, shippingid)
      }
    },
    onCancelDelete() {
      this.isShowConfirmDeleteModal = false
      this.selectedData = {}
    },
  },
}
