
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'
// import tinymce from '@tinymce/tinymce-vue'

export default {
  layout: 'main',
  name: 'NewsEdit',
  components: {
    'aside-component': asideComponent,
    // CKEditor,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    tinymce: () => import('@tinymce/tinymce-vue'),
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      tinymce: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | styleselect | fontsizeselect | \
           bold italic underline backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent |  \
           link image | \
           removeformat | help',
        height: 200,
        branding: false,
        // toolbar1:
        //   'styleselect fontselect fontsizeselect bold italic strikethrough underline subscript superscript forecolor backcolor link',
        // toolbar2:
        //   'alignleft aligncenter alignright alignjustify bullist numlist outdent indent blockquote undo redo',
        // options: {
        //   height: 200,
        //   menubar: false,
        //   branding: false,
        // },
      },
      newsData: {
        id: '',
        title: '',
        updateDate: '',
        detail: '',
        startDate: '',
        endDate: '',
        isActive: true,
        attach_file: {
          name: '',
          url: '',
        },
        attach_file_display: '',
        company_id: '',
        valid_from: '',
        valid_to: '',
      },
      defaultDate: [],
      error: '',
      isAdmin: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },

  created() {},
  mounted() {
    this.GetData()
  },
  methods: {
    async selected({ target: { files } }) {
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              // this.importFile(files[0])
              await this.onUploadFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async onUploadFile(file) {
      this.error = ''
      this.$refs.tempEditNews.validate().then((result) => {
        if (result) {
          this.newsData.attach_file_display = file.name.toLowerCase()
          let formData = new FormData()
          formData.append('file', file)
          formData.append('prefix_path', 'personal-document')
          formData.append('content_name', 'news')
          formData.append('is_private', true)

          this.$profileProvider.uploadContentImage(formData).then(
            (result) => {
              if (result.status === 201) {
                console.log('uploadContentImage :>> ', result.data)
                if (result.data.url !== null) {
                  this.newsData.attach_file = {
                    name: this.newsData.attach_file_display,
                    url: result.data.url,
                  }
                } else {
                  this.error = 'รูปแบบชื่อไฟล์ไม่ถูกต้องค่ะ'
                  this.newsData.attach_file = {
                    name: '',
                    url: '',
                  }
                }
              }
            },
            (error) => {
              console.log('onUploadFile', error.responsed)
            }
          )
        }
      })
    },
    onEdit() {
      this.$refs.tempEditNews.validate().then((result) => {
        if (result) {
          this.updateContent()
        } else {
          console.log('tempEditNews :>> ', result)
        }
      })
    },
    async updateContent() {
      if (this.error === '') {
        this.newsData.company_id = this.userProfile.id
        this.newsData.is_active = this.newsData.isActive
        this.newsData.valid_from = this.newsData.startDate
        this.newsData.valid_to = this.newsData.endDate
        await this.$profileProvider
          .updateContentImage(this.newsData.id, this.newsData)
          .then(
            (result) => {
              if (result.status === 204) {
                this.onCancel()
              }
            },
            (error) => {
              console.log('onGetData', error.responsed)
            }
          )
      }
    },
    onCancel() {
      this.$router.push({ path: this.$i18n.path('company/news') })
    },
    dateRangeSelected(event) {
      console.warn('event', event)
      this.newsData.startDate = event.from
      this.newsData.endDate = event.to
    },
    async GetData() {
      await this.setting()
      let params = new URLSearchParams()
      params.append('news_id', this.$route.params.id)
      params.append('is_admin', this.isAdmin)
      params.append('is_active', false)
      params.append('page', 1)
      params.append('pageSize', 20)
      await this.$profileProvider.getNewsData(this.userProfile.id, params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.items.forEach((element) => {
              this.newsData = {
                id: element.id,
                title: element.title,
                updateDate: element.update_info.timestamp,
                detail: element.detail,
                startDate: element.valid_from,
                endDate: element.valid_to,
                isActive: element.is_active,
                attach_file: element.attach_file,
                attach_file_display: element.attach_file.url,
                news_channel: element.news_channel,
              }
              this.defaultDate.push(
                new Date(element.valid_from),
                new Date(element.valid_to)
              )
              console.log('newsData :>> ', this.newsData)
            })
          }
        },
        (error) => {
          console.log('onGetData', error.responsed)
        }
      )
    },
    async setting() {
      let admin = this.userProfile.roles.filter(
        (a) => a.role_id === 1 || a.role_id === 2
      )
      if (admin !== undefined && admin.length > 0) {
        this.isAdmin = true
      }
    },
  },
}
