import { render, staticRenderFns } from "./addonModal.vue?vue&type=template&id=610c4752"
import script from "./addonModal.vue?vue&type=script&lang=js"
export * from "./addonModal.vue?vue&type=script&lang=js"
import style0 from "./addonModal.vue?vue&type=style&index=0&id=610c4752&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ItemAddToCart: require('/usr/src/nuxt-app/components/share/item-add-to-cart.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default})
