
import { mapGetters } from 'vuex'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'CardProductComponent',
  props: {
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    listName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeItem: 0,
      activeClass: 0,
      isShowItem: true,
      lang: '',
      isAlgolia: {
        addCart: false,
      },
      // imageTemp:
      //   'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
    }
  },
  computed: {
    ...mapGetters('authen', ['openId', 'isGuest', 'guestId']),
    showItem() {
      return this.items[this.activeItem]
    },
    isDiscount() {
      return (
        this.showItem.is_promotion ||
        this.showItem.is_fixprice ||
        this.showItem.is_online_only ||
        this.showItem.is_flash_sale
      )
    },
  },
  watch: {
    items: {
      handler: function (val) {
        if (val.length > 0) {
          val.forEach((item) => {
            this.$utils.onPreload(
              this.$utils.genImgSrcSet(
                this.$utils.imageTimeStamp(
                  item.productImg,
                  item?.update_info?.timestamp,
                  true
                ),
                [
                  { w: 51, h: 51, q: 90, ws: '51w' },
                  { w: 68, h: 68, q: 90, ws: '68w' },
                  { w: 34, h: 34, q: 90, ws: '34w' },
                  { w: 35, h: 35, q: 90, ws: '35w' },
                  { w: 40, h: 40, q: 90, ws: '40w' },
                  { w: 114, h: 114, q: 90, ws: '114w' },
                ]
              ),
              '(max-width: 360px) 51px, (max-width: 412px) 68px, (max-width: 768px) 35px,(max-width: 1024px) 35px,(max-width: 1280px) 35px,(max-width: 1920px) 34px'
            )
          })
        }
      },
      deep: true,
    },
    showItem: {
      handler: function (val) {
        if (this.$utils.isValid(val)) {
          this.$utils.onPreload(
            this.$utils.genImgSrcSet(
              this.$utils.imageTimeStamp(
                val.productImg,
                val?.update_info?.timestamp,
                true
              ),
              [
                { w: 125, h: 125, q: 90, ws: '125w' },
                { w: 34, h: 34, q: 90, ws: '34w' },
                { w: 40, h: 40, q: 90, ws: '40w' },
                { w: 85, h: 85, q: 90, ws: '85w' },
                { w: 150, h: 150, q: 90, ws: '150w' },
              ]
            ),
            '(max-width: 360px) 125px, (max-width: 412px) 125px, (max-width: 768px) 85px, (max-width: 1024px) 34px, (max-width: 1280px) 85px, (max-width: 1920px) 85px'
          )
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.lang = this.$store.state.locale
  },
  methods: {
    onProductDetail(item) {
      if (
        this.$utils.isObjectValid(item) &&
        this.$utils.isValid(this.listName)
      ) {
        //gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'productClicked',
          currencyCode: 'THB',
          ecommerce: {
            click: {
              actionField: { list: this.listName },
              products: [
                {
                  id: item.productId,
                  price: this.$utils.formatPrice(
                    item.price_include_vat,
                    2,
                    true
                  ),
                  position: item.seq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const item_list_id =
          (item.is_flash_sale && 'flash_sale') ||
          (item.is_promotion && 'promotions') ||
          ''
        const item_list_name =
          (item.is_flash_sale && 'flash sale') ||
          (item.is_promotion && 'Promotions') ||
          ''
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_item',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            item_list_id,
            item_list_name,
            items: [
              {
                item_list_id,
                item_list_name,
                item_id: item.productId,
                item_name: item.productName.th,
                item_brand: item.brand?.display_name.en,
                price: item.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }

      if (!this.isAlgolia.addCart) {
        this.$eventInsightsAlgolia.sendEvent(
          TriggerInsightsAlgoliaEnum.NONSEARCHPLPADDCART,
          this.isGuest ? this.guestId : this.openId,
          '',
          [item.productId]
        )
        this.isAlgolia.addCart = true
      }

      // let skuSEOName = item.meta.seo_name[this.$i18n.locale]
      // this.$nextTick(() => {
      //   this.$router.push({
      //     path: this.$i18n.path(`product/${skuSEOName}-p.${item.productId}`),
      //   })
      // })
    },
    onActiveItem(index, item) {
      this.isShowItem = false
      this.activeClass = index
      let _this = this
      setTimeout(function () {
        _this.isShowItem = true
        _this.activeItem = index
      }, 300)
    },
    imgError($event) {
      console.log($event)
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
  },
}
