
export default {
  name: 'InputQtyComponent',
  props: {
    fixQty: { type: Boolean, default: false },
    alertLimitQty: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    maxlength: { type: String, default: '' },
  },
  data() {
    return {
      input: '',
      newValue: '',
      oldValue: '',
    }
  },
  computed: {
    addEmptyClass: function () {
      return this.isEmpty && this.input === '' ? true : false
    },
  },
  watch: {
    input: {
      handler: function (val) {
        let tempVal = String(val)
        tempVal = tempVal
          .replace(/['\\',\\'\\,':',';','\t','\n','\r','\n']/g, '')
          .replace(/[^\d]/g, '')
        this.input = tempVal
        if (parseInt(tempVal) <= 0) {
          tempVal = String(1)
          this.input = 1
        }
        this.newValue = tempVal
      },
    },
    newValue: {
      handler: function (val, oldValue) {
        this.oldValue = String(oldValue)
        this.$emit('value', this.newValue)
      },
    },
    defaultValue: {
      handler: function (val) {
        if (val && parseInt(val) !== parseInt(this.input)) this.input = val
      },
    },
  },
  created() {
    if (this.defaultValue) this.input = this.defaultValue
  },
  methods: {
    onChangeQty() {
      if (
        this.oldValue !== '' &&
        parseInt(this.input) !== parseInt(this.oldValue)
      ) {
        this.oldValue = this.input
      }
    },
  },
}
