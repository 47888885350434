
export default {
  layout: 'blank-nav',
  name: 'RegisterComplete',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
}
