
import BreadCrumb from '@/components/template/bread-crumb.vue'
// import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import { /* mapActions, mapState,*/ mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'
export default {
  name: 'addondeal',
  components: {
    breadcrumb: BreadCrumb,
    // 'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    // 'filter-controller': FilterController,
    'product-result': ProductResult,
    'skeleton-result': SekletonResult,
  },
  data() {
    return {
      breadcrumbItems: [
        // {
        //   text: this.$nuxt._route.params.department.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department,
        // },
        // {
        //   text: this.$nuxt._route.params.category.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department +
        //     '/' +
        //     this.$nuxt._route.params.category,
        // },
        // {
        //   text: this.$nuxt._route.params.subcategory.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department +
        //     '/' +
        //     this.$nuxt._route.params.category +
        //     '/' +
        //     this.$nuxt._route.params.subcategory,
        // },
      ],
      sku: {},
      skuName: '',
      itemsRelated: [],
      lang: '',
      sortBy: [
        {
          text: this.$t('similar_id.dropdownSortByDefault'),
          value: '0',
        },
        {
          text: this.$t('similar_id.dropdownSortByDiscount'),
          value: '1',
        },
        {
          text: this.$t('similar_id.dropdownPriceLowHigh'),
          value: '2',
        },
        {
          text: this.$t('similar_id.dropdownPriceHighLow'),
          value: '3',
        },
        {
          text: this.$t('similar_id.dropdownAZ'),
          value: '4',
        },
        {
          text: this.$t('similar_id.dropdownZA'),
          value: '5',
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      filter: {},
      sort: 'promotion-descending',
      view: 'grid',
      loading: false,
      imageTemp:
        'https://b2s-static-cdn0.b2s.co.th/images/image-product-default-thumbnail.png',
      // IsInactive: false,
      // IsDelete: false,
      productStatusNotReady: ['not_ready', 'cancel'],
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      isGetProduct: false,
      isNotFound: false,
      promotionId: '',
      itemsPromotionDiscount: [],
      conditionPromotions: [],
      overlayImages: [],
      overlayImagesItemsRecent: [],
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
    ]),
    isItemsDiscount() {
      return (
        this.itemsPromotionDiscount !== undefined &&
        this.itemsPromotionDiscount !== null &&
        this.itemsPromotionDiscount.length > 0
      )
    },
    imageNormalShowcase() {
      if (this.sku.images != null && this.sku.images.length) {
        return this.sku.images.some((q) => q.size === 'thumbnail')
          ? this.sku.images
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((q) => q.size === 'thumbnail').url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
    isDiscount() {
      return (
        this.sku.is_promotion ||
        this.sku.is_fixprice ||
        this.sku.is_online_only ||
        this.sku.is_flash_sale
      )
    },
    checkProductStatusReady() {
      return !this.productStatusNotReady.includes(this.sku.display_status)
    },
  },
  watch: {
    // perPage: {
    //   handler: function () {
    //     console.log(this.perPage)
    //     this.getProduct()
    //   },
    //   deep: true,
    // },
  },
  created() {
    if (!this.$route.params.id)
      this.$router.push({
        path: this.$i18n.path('_'),
      })
    // this.categoryId = this.$nuxt._route.params.subcategory.split('-')[0]
    this.lang = this.$store.state.locale
    // let paths = this.$nuxt._route.params.id.split('.')
    // this.skuCode =
    //   this.$utils.anyArrayObjectValid(paths) &&
    //   this.$utils.isValid(paths[paths.length - 1])
    //     ? paths[paths.length - 1].toUpperCase()
    //     : ''
    this.promotionId = this.$route.params.id
    console.log('promotionId', this.promotionId)
    // this.getProductDetail()
    //this.skuCode = this.$route.params.id
    this.getPromotionDiscountSeeAll()
    this.getPromotionDiscountpdp()
  },
  mounted() {
    // this.getProduct()
  },
  methods: {
    async createParams() {
      let params = new URLSearchParams()
      console.log('promotionId', this.promotionId)
      params.append('accountChannel', this.profileType)
      params.append('promotion_id', this.promotionId)
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)

      // if (
      //   this.profileType === 'eprocurement' &&
      //   this.$utils.isObjectValid(this.userProfile)
      // ) {
      //   // if (this.$utils.isValid(this.userProfile.id)) {
      //   //   params.append('companyId', this.userProfile.id)
      //   // }
      //   if (this.$utils.isValid(this.userProfile.categories_type)) {
      //     params.append('userCategoryType', this.userProfile.categories_type)
      //   }
      //   if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
      //     params.append('costcenterId', this.userCostcenter.id)
      //   }
      // } else
      if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }
      return params
    },

    // async getPromotionDiscount() {
    //   let params = await this.createParams()
    //   params.append('pageSize', this.perPage)
    //   params.append('sort', this.sort)
    //   params.append('page', this.page)
    //   //params.append('pageSize', this.perPage)
    //   console.log('params', params)
    //   let responsed = await this.$productProvider.getPromotionDiscount(
    //     params
    //   )
    //   console.log('PromotionDiscount', responsed)
    //   if (responsed.status === 200) {
    //     this.PromotionDiscount = responsed.data
    //   }
    // },
    // async getProductRelated() {
    //   this.isGetProduct = false
    //   let params = await this.createParams()
    //   params.append('pageSize', this.perPage)
    //   params.append('sort', this.sort)
    //   params.append('type', 'Related')
    //   params.append('page', this.page)
    //   //params.append('pageSize', this.perPage)
    //   params.append('isFree', false)

    //   console.log('params', params)
    //   await this.$productProvider
    //     .getProductRelatedData(params)
    //     .then((result) => {
    //       if (result.status === 200) {
    //         let data = result.data
    //         this.itemsRelated = data.items ?? undefined
    //         this.pageTotal = result.data.totalRecords
    //         this.loading = true
    //       }
    //     })
    //   this.isGetProduct = true
    // },
    // async createParams() {
    //   let params = new URLSearchParams()
    //   params.append('skuCode', this.skuCode)
    //   params.append('accountId', this.userProfile.id)
    //   params.append('accountChannel', this.profileType)
    //    params.append(
    //     'categoryType',
    //     this.userProfile.categories_type ?? 'Standard'
    //   )
    //   params.append('pageSize', this.perPage)
    //   params.append('sort', this.sort)
    //   params.append('type', 'Related')
    //   params.append('page', this.page)
    //   params.append('pageSize', this.perPage)

    //   params.append('isFree', false)
    //   return params
    // },
    async onPagination(page) {
      console.log(page)
      if (page != this.page) {
        this.page = page
        this.loading = false
        await this.getPromotionDiscountSeeAll()
      }
    },
    async getPromotionDiscountpdp() {
      const params = new URLSearchParams()
      params.append('promotion_id', this.promotionId)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id)
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      let responsed = await this.$shoppingCartProvider.getPromotionDiscountpdp(
        params
      )
      console.log('PromotionDiscount', responsed)
      if (responsed.status === 200) {
        this.conditionPromotions = responsed.data.conditions
      } else this.itemsPromotionDiscount = []

      console.log('itemsPromotionDiscount', this.itemsPromotionDiscount)

      if (responsed.status === 404) {
        this.dataSku.is_promotion_discount = false
      }
    },
    async getPromotionDiscountSeeAll() {
      console.log('promotionId', this.promotionId)
      const params = new URLSearchParams()
      params.append('promotion_id', this.promotionId)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id)
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      let responsed = await this.$productProvider.getPromotionDiscountSeeAll(
        params
      )
      console.log('PromotionDiscount', responsed)
      if (responsed.status === 200) {
        // this.itemsPromotionDiscount = []
        // var resultProductDiscount = responsed.data.items.filter(
        //   (q) => q.id !== this.dataSku.sku_code
        // )
        this.pageTotal = responsed.data.totalRecords
        this.itemsPromotionDiscount = responsed.data.items

        // get overlay
        if (this.itemsPromotionDiscount.length) {
          let pid = this.itemsPromotionDiscount.map((item) => {
            return item.skus[0].sku_code
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          console.log('graphqlResponse', graphqlResponse)
          if (graphqlResponse) {
            this.overlayImages = graphqlResponse
          }
        }

        // responsed.data.items.forEach((item, index) => {
        //   console.log('resultProductDiscount', item)
        //   item.skus.forEach((sku) => {
        //     console.log('sku', sku)
        //     sku.product_name = sku.product_names.find(
        //       (q) => q.type.toLowerCase() === 'online'
        //     ).display_name
        //     this.itemsPromotionDiscount.push(sku)
        //   })
        // })
      } else this.itemsPromotionDiscount = []

      this.loading = true
      this.isGetProduct = true
      console.log('itemsPromotionDiscount', this.itemsPromotionDiscount)
    },
  },
}
