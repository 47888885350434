import { render, staticRenderFns } from "./_keyword.vue?vue&type=template&id=4cf093eb&scoped=true"
import script from "./_keyword.vue?vue&type=script&lang=js"
export * from "./_keyword.vue?vue&type=script&lang=js"
import style0 from "./_keyword.vue?vue&type=style&index=0&id=4cf093eb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf093eb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notfound: require('/usr/src/nuxt-app/components/share/notfound.vue').default})
