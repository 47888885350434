
import asideComponent from '@/components/template/aside-user.vue'
// import InputText from '@/components/share/input-text.vue'
import TableManageUser from '@/components/company/table-manage-user.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageRole',
  components: {
    'aside-component': asideComponent,
    // 'input-text': InputText,
    TableManageUser,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      filter: '',
      filterResult: [],
      userItems: [],
      phone: {
        extension: '',
        type: 'Phone',
        value: '',
      },
      mobile: {
        type: 'Mobile',
        value: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  mounted() {
    this.getAccountUsers()
  },
  created() {},
  methods: {
    async getAccountUsers() {
      await this.$profileProvider.getAccountUsersAll(this.userProfile.id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('55555', data)
            data.items.forEach((e, index) => {
              this.userItems.push({
                index: index + 1,
                user_open_id: e.user_open_id,
                email: e.email,
                name: e.first_name,
                last_name: e.last_name,
                mobile:
                  e.phones !== undefined
                    ? e.phones.find((a) => a.type === 'Mobile') ?? this.mobile
                    : '',
                phone:
                  e.phones !== undefined
                    ? e.phones.find((a) => a.type === 'Phone') ?? this.phone
                    : '',
                status: e.is_user_active,
                id: e.id,
              })
            })
          }
        },
        (error) => {
          console.log('getAccountUsersAll', error.responsed)
        }
      )
    },
  },
}
