
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTotal',
  components: {
    Multiselect,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    HorizontalBarChart: async () =>
      await import('@/components/dashboard/chartjs/horizontalBar.vue'),
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    costcenterOptions: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      argumentField: 'userOrder',
      //   dataSourceSumSort: [],
      dataSource: [],
      dataSeries: [],
      selectedLegend: [],
      filter: {
        date: '',
        selectTop: { name: this.$t(`company-dashboard.top-10`), value: 10 },
      },
      selectTopOptions: [
        { name: this.$t(`company-dashboard.top-10`), value: 10 },
        { name: this.$t(`company-dashboard.top-20`), value: 20 },
      ],
      costcenter_code: [],
      date_from: '',
      date_to: '',
      select_top: 10,
      dataSourceSum: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    // dataSourceSum() {
    //   // sum data to summary
    //   var source = this.dataSource.reduce((source, value) => {
    //     for (const [sourceName, sourceCount] of Object.entries(value)) {
    //       if (!source[sourceName]) {
    //         source[sourceName] = 0
    //       }
    //       source[sourceName] += sourceCount
    //     }
    //     delete source[this.argumentField]
    //     // delete source['color']
    //     return source
    //   }, {})
    //   return source
    // },
  },
  watch: {
    dataSource: {
      handler: function (val) {
        this.getSummaryOrderSatatus()
      },
      deep: true,
    },
  },
  async created() {},
  mounted() {
    this.getSummaryOrderStatusAll()
  },
  methods: {
    userOrderRangeSelected(event) {
      this.filter.date = event
    },
    onFilter() {
      console.log('cost', this.filter)
      this.dataSource = []
      this.dataSeries = []
      this.dataSourceSum = []
      this.costcenter_code = []
      this.date_from = ''
      this.date_to = ''
      if (
        this.filter.costcenter !== undefined &&
        this.filter.costcenter.length > 0
      ) {
        this.filter.costcenter.forEach((a) => {
          this.costcenter_code.push(a.value)
        })
      }

      this.date_from = this.filter.date.from ?? ''
      this.date_to = this.filter.date.to ?? ''
      this.select_top = this.filter.selectTop.value
      this.getSummaryOrderStatusAll()
    },
    async getSummaryOrderSatatus() {
      this.dataSeries = []
      let params = this.mapURLParams()
      await this.$reportProvider.getSummaryOrderSatatusData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            let _dataSeries = []
            if (Array.isArray(data)) {
              data.forEach((a, index) => {
                _dataSeries.push({
                  // index: index + 1,
                  index: index + 1,
                  name: a.order_status,
                  label: a.order_status,
                })
              })

              _dataSeries.forEach((element) => {
                element.value = this.dataSourceSum[element.label] || 0
                if (element.label === 'Waiting for approval') {
                  element.color = '#ff4f72'
                } else if (element.label === 'Waiting for revised') {
                  element.color = '#01a0df'
                } else if (element.label === 'Approved') {
                  element.color = '#007ede'
                } else {
                  element.color = ''
                }
              })
              this.dataSeries = _dataSeries
              this.selectedLegend = [
                'Waiting for approval',
                'Waiting for revised',
                'Waiting for payment',
              ]
            }
          }
        },
        (error) => {
          console.log('getSummaryOrderSatatus', error.responsed)
        }
      )
    },
    async getSummaryOrderStatusAll() {
      let params = this.mapURLParams()
      this.dataSource = []
      await this.$reportProvider.getSummaryOrderStatusAllData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            let _dataSource = []
            if (Array.isArray(data)) {
              data.forEach((a) => {
                let object = {}
                let key = ''
                key = 'label'
                object[key] = a.costcenter_code
                let result = a.order
                  .filter((e) => e.costcenter_code === a.costcenter_code)
                  .sort(function (a, b) {
                    return a.quantity - b.quantity
                  })
                result.forEach((b) => {
                  let order_status =
                    b.order_status === 'ReadyToShip'
                      ? 'Ready to ship'
                      : b.order_status
                  key = order_status
                  let quantity = parseInt(b.quantity.toFixed(2))
                  object[key] = quantity
                })
                _dataSource.push(object)
              })

              this.dataSourceSum = _dataSource.reduce((source, value) => {
                for (const [sourceName, sourceCount] of Object.entries(value)) {
                  if (!source[sourceName]) {
                    source[sourceName] = 0
                  }
                  source[sourceName] += sourceCount
                }
                delete source[this.argumentField]
                delete source['color']
                return source
              }, {})

              this.dataSource = _dataSource
              console.log('source', this.dataSource)
            }
          }
        },
        (error) => {
          console.log('getSummaryOrderStatusAll', error.responsed)
        }
      )
    },
    mapURLParams() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      if (
        this.costcenter_code !== undefined &&
        this.costcenter_code.length > 0
      ) {
        this.costcenter_code.forEach((s) => {
          params.append('code', s)
        })
      }
      if (this.date_from !== '' && this.date_to !== '') {
        params.append('date_from', this.date_from)
        params.append('date_to', this.date_to)
      }
      params.append('filter', this.select_top)
      return params
    },
  },
}
