
export default {
  name: 'SelectBasicComponent',
  props: {
    label: { type: String, default: '' },
    width: { type: String, default: '100%' },
    placeholder: { type: String, default: '' },
    validate: { type: Boolean, default: false },
    // format: { type: String, default: '' },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    disabled: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    vertical: { type: Boolean, default: false },
  },
  data() {
    return {
      select: '',
    }
  },
  computed: {
    isEmpty: function () {
      if (this.validate && this.select === '') {
        return true
      } else {
        return false
      }
    },
    horizontal: function () {
      return this.vertical ? 'horizontal' : ''
    },
  },
  watch: {
    select: {
      handler: function () {
        this.$emit('selected', this.select)
      },
    },
    defaultValue: {
      handler: function () {
        this.items.forEach((element) => {
          if (element.value === this.defaultValue) {
            this.select = this.defaultValue
          }
        })
      },
    },
  },
  mounted() {
    if (this.defaultValue !== '') {
      this.items.forEach((element) => {
        if (element.value === this.defaultValue) {
          this.select = this.defaultValue
        }
      })
    }
  },
}
