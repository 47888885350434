import { render, staticRenderFns } from "./index_backup.vue?vue&type=template&id=2d37d53e&scoped=true"
import script from "./index_backup.vue?vue&type=script&lang=js"
export * from "./index_backup.vue?vue&type=script&lang=js"
import style0 from "./index_backup.vue?vue&type=style&index=0&id=2d37d53e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d37d53e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperImage: require('/usr/src/nuxt-app/components/share/swiper-image.vue').default})
