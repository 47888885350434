import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=7284f931"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductResult: require('/usr/src/nuxt-app/components/skeleton/product-result.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default})
