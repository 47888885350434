
import FooterComponent from '@/components/template/footer.vue'
import preconditionFailed from '@/components/share/precondition-failed'
import responseFailed from '@/components/share/response-failed'
import loading from '@/components/share/loading'
import modalComponent from '@/components/share/modal'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    'footer-component': FooterComponent,
    'precondition-failed': preconditionFailed,
    'response-failed': responseFailed,
    loading,
    modalComponent,
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['profileType']),
    ...mapGetters('cart', ['cartCount']),
    isEprocurementUser() {
      return this.$utils.isValid(this.profileType) &&
        this.profileType === 'eprocurement'
        ? true
        : false
    },
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    check() {
      if (this.$route.name !== 'account-select-company')
        this.$router.push({ path: this.$i18n.path('') })
    },
    onChangeLanguage(language) {
      // this.lang = language
      this.$router.push(
        `${
          language === 'th'
            ? this.$route.fullPath.replace(/^\/[^\/]+/, '')
            : this.$route.fullPath.includes('/en')
            ? this.$route.fullPath
            : `/en${this.$route.fullPath}`
        }`
      )
    },
    redirectHomePage() {
      if (this.$route.name === 'verify') {
        this.signout()
        this.removeUserProfile()
      }
      // else this.$router.push(this.$i18n.path(''))
    },
  },
}
