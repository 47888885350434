
import { mapActions, mapGetters, mapState } from 'vuex'
// import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'
export default {
  name: 'ItemComponent',
  props: {
    item: { type: Object, default: () => {} },
    listName: { type: String, default: '' },
    isShowNormalPrice: { type: Boolean, default: true },
    queryIdEventAlgolia: { type: String, default: '' },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    position: { type: String, default: null },
    width: { type: String, default: '150' },
    height: { type: String, default: '150' },
    showFlashSale: { type: Boolean, default: false },
    slideStyle: { type: String, default: '' },
    gtmItemListId: { type: String, default: '' },
    gtmItemListName: { type: String, default: '' },
    overlayImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
    }
  },
  mounted() {
    if (this.slideStyle === 'recommended') {
      this.$utils.onPreload(
        this.$utils.genImgSrcSet(
          this.$utils.imageTimeStamp(
            this.imageShowcase,
            this.item?.update_info?.timestamp,
            true
          ),
          [
            { w: 115, h: 115, q: 90, ws: '115w' },
            { w: 117, h: 117, q: 90, ws: '117w' },
            { w: 150, h: 150, q: 90, ws: '150w' },
          ]
        ),
        '(max-width: 360px) 117px, (max-width: 412px) 150px, (max-width: 768px) 115px, (max-width: 1024px) 115px 150px'
      )
    } else {
      this.$utils.onPreload(
        this.$utils.genImgSrcSet(
          this.$utils.imageTimeStamp(
            this.imageShowcase,
            this.item?.update_info?.timestamp,
            true
          ),
          [
            { w: 115, h: 115, q: 90, ws: '115w' },
            { w: 125, h: 125, q: 90, ws: '125w' },
            { w: 140, h: 140, q: 90, ws: '140w' },
            { w: 150, h: 150, q: 90, ws: '150w' },
          ]
        ),
        '(max-width: 360px) 125px, (max-width: 412px) 125px, (max-width: 768px) 85px,(max-width: 1920px) 140px'
      )
    }
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    ...mapState('cart', ['cartId']),
    isMyGuest() {
      return this.isAuthenticated && this.isGuest
    },
    isItem() {
      return this.$utils.isObjectValid(this.item)
    },
    productName() {
      return this.$utils.isObjectValid(this.item.productName)
        ? this.item.productName[this.$i18n.locale]
        : ''
    },
    isDiscount() {
      return this.item.isPromotion || this.item.isFixprice
    },
    isOnlineOnly() {
      return this.item.is_online_only || this.item.is_flash_sale
    },
    isDiscountRate() {
      return (
        this.$utils.isObjectValid(this.item) &&
        this.$utils.anyArrayObjectValid(this.item.promotion_prices) &&
        this.$utils.isObjectValid(this.item.display_discount_rate) &&
        this.item.display_discount_rate > 0 &&
        (this.item.is_promotion ||
          this.item.is_online_only ||
          this.item.is_flash_sale)
      )
    },
    imageShowcase() {
      let timeStamp = this.$utils.isObjectKeyValid(
        this.item.update_info,
        'timestamp'
      )
        ? /*? '?v=' + this.$utils.getDateString(this.item.update_info.timestamp)
        : ''*/
          ''
        : ''

      return this.$utils.isValid(this.item.productImg)
        ? this.item.productImg + timeStamp
        : this.imageTemp
    },
    isOverlay() {
      let sku_code = this.item.productId || this.item.sku_code
      if (this.overlayImages.length && sku_code) {
        const selectedProduct = this.overlayImages.filter(
          (product) => product.sku_code === sku_code
        )
        return selectedProduct[0]
      }
    },
    isFlashSale() {
      console.log('showFlashSale', this.showFlashSale)
      return this.item.is_flash_sale
    },
    isHotFlashSale() {
      return this.item.is_hot_flash_sale
    },
    isPromotionDiscount() {
      return this.item.is_promotion_discount
    },
    isInstallmentBySku() {
      return this.item?.is_installment_sku
    },
  },
  methods: {
    ...mapActions('cart', ['addCart', 'setCartCount']),
    async onAdd(item) {
      console.log(item)
      item.qty = 1

      // const responsed = await this.$shoppingCartProvider.addCart(
      //   this.cartId,
      //   item
      // )
      // if (responsed.status === 201) {
      //   const cartResponsed = await this.$shoppingCartProvider.getCartCount(
      //     this.cartId
      //   )
      //   if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
      // }

      // this.addCart(item)
      // this.$router.push({
      //   path: '/products/detail',
      //   query: { item: item },
      // })
    },
    onRoute(item, productId) {
      if (this.$utils.isValid(this.listName)) {
        //gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'productClicked',
          currencyCode: 'THB',
          ecommerce: {
            click: {
              actionField: { list: this.listName },
              products: [
                {
                  id: this.item.productId,
                  price: this.$utils.formatPrice(
                    this.item.price_include_vat,
                    2,
                    true
                  ),
                  position: this.item.seq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const item_list_id =
          (this.item.gtmItemListId && this.item.gtmItemListId) ||
          (this.item.is_flash_sale && 'flash_sale') ||
          (this.item.is_promotion && 'promotions') ||
          ''
        const item_list_name =
          (this.item.gtmItemListName && this.item.gtmItemListName) ||
          (this.item.is_flash_sale && 'flash sale') ||
          (this.item.is_promotion && 'Promotions') ||
          ''
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_item',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            item_list_id,
            item_list_name,
            items: [
              {
                item_list_id,
                item_list_name,
                item_id: this.item.productId,
                item_name: this.item.productName.th,
                item_brand: this.item.brand?.display_name.en,
                price: this.item.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }

      if (this.$utils.isValid(this.triggerEventAlgolia)) {
        this.$eventInsightsAlgolia.sendEvent(
          this.triggerEventAlgolia,
          this.isMyGuest ? this.guestId : this.openId,
          this.queryIdEventAlgolia,
          [this.item.productId],
          [this.positionOnPage],
          this.indexEventAlgolia
        )
      }

      // let skuSEOName = this.$utils.isObjectValid(item.seoMeta)
      //   ? item.seoMeta[this.$i18n.locale]
      //   : item.meta.seo_name[this.$i18n.locale]

      // this.$nextTick(() => {
      //   this.$router.push({
      //     path: this.$i18n.path(`product/${skuSEOName}-p.${productId}`),
      //     query: { queryIdEventAlgolia: this.queryIdEventAlgolia },
      //   })
      // })
      // this.$router.push({
      //   path: '/product/' + path + '-p.' + productId,
      // })
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
  },
}
