
export default {
  name: 'SliderBannersComponent',
  props: {
    images: { type: Array, default: () => [] },
    interval: { type: Number, default: 5000 },
    bannerCreative: { type: String, default: '' },
  },
  data() {
    return {
      lazyNum: 0,
      isActiveSlide: false,
      swiperOption: {
        // effect: 'fade',
        speed: 1000,
        loop: this.images.length > 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchMove: () => {
            this.isActiveSlide = true
          },
        },
      },
      loop: false,
      userAgent: false,
    }
  },
  // created() {
  //   this.userAgent = navigator.userAgent.includes('Chrome-Lighthouse')
  // },
  mounted() {
    // if (process.client) {
    // const userAgent = navigator.userAgent.includes('Chrome-Lighthouse')
    if (!this.userAgent) {
      // this.loop = this.images.length > 1
      this.swiperOption.autoplay =
        this.images.length > 1 ? { delay: this.interval } : false

      this.$refs['swiper-hero'].$swiper.autoplay.start()
    }
    // }

    if (this.images.length) {
      this.images.forEach((image) => {
        this.$utils.onPreload(
          this.$utils.genImgSrcSet(
            this.$utils.imageTimeStamp(image?.path, image?.update_info, false),
            [
              { w: 328, h: 99, q: 90, ws: '328w' },
              { w: 380, h: 114, q: 90, ws: '380w' },
              { w: 736, h: 222, q: 90, ws: '736w' },
              { w: 992, h: 299, q: 90, ws: '992w' },
              { w: 1172, h: 353, q: 90, ws: '1172w' },
              { w: 1044, h: 314, q: 90, ws: '1044w' },
            ]
          ),
          '(max-width: 360px) 328px, (max-width: 412px) 380px, (max-width: 768px) 736px, (max-width: 1024px) 992px, (max-width: 1280px) 1044px'
        )
      })
    }
  },
  methods: {
    onSlide() {
      if (this.lazyNum <= this.images.length) this.lazyNum++
    },
    onSwiperReady() {
      this.$refs['swiper-hero'].$swiper.$el[0].children[0].onclick =
        this.onClickPerSlide
    },
    onClickPerSlide(event) {
      let banner = JSON.parse(event.target.dataset.banner)
      if (banner !== undefined) {
        if (this.$utils.isValid(this.bannerCreative)) {
          //gtm promoClicked
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'promoClicked',
            ecommerce: {
              promoClick: {
                promotions: [
                  {
                    id: banner.path,
                    name: `HP|${banner.gtmSeq}|${
                      banner.title
                    }|${this.$utils.formatDateGTM(
                      banner.active_from
                    )}_${this.$utils.formatDateGTM(banner.active_to)}`,
                    creative: this.bannerCreative,
                    position: banner.gtmSeq,
                  },
                ],
              },
            },
          })

          // gtm GA4
          const promotion = {
            creative_name: banner.title,
            creative_slot: `${banner.title}_${banner.gtmSeq}`,
            promotion_id: banner.path,
            promotion_name: `HP|${banner.gtmSeq}|${
              banner.title
            }|${this.$utils.formatDateGTM(
              banner.active_from
            )}_${this.$utils.formatDateGTM(banner.active_to)}`,
          }
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'select_promotion',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              ...promotion,
            },
          })
          this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
        }
        if (banner !== undefined && this.$utils.isValid(banner.url)) {
          console.log('banner')
          this.$nextTick(() => {
            window.location.href = banner.url
          })
        }
      }
    },
  },
}
