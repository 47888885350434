
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTotal',
  components: {
    Multiselect,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    BarChart: async () =>
      await import('@/components/dashboard/chartjs/bar.vue'),
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    costcenterOptions: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      argumentField: 'category',
      dataSource: [],
      dataSeries: [],
      chartColor: [
        // '#50C1EE',
        // '#48B9E6',
        // '#40B1DE',
        // '#38A9D6',
        // '#30A1CE',
        // '#2899C6',
        // '#2091BE',
        // '#1889B6',
        // '#1081AE',
        // '#0879A6',
        // '#00719E',
        // '#006996',
        // '#00618E',
        // '#005986',
        // '#00517E',
        // '#004976',
        // '#00416E',
        // '#003966',
        // '#00315E',
        // '#002956',
      ],
      filter: {
        date: '',
        costcenter: [],
        selectTop: { name: this.$t(`company-dashboard.top-10`), value: 10 },
      },
      selectTopOptions: [
        { name: this.$t(`company-dashboard.top-10`), value: 10 },
        { name: this.$t(`company-dashboard.top-20`), value: 20 },
      ],
      legendCallback: [],
      select_top: 10,
      date_from: '',
      date_to: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  mounted() {
    this.getsummaryCategories()
  },
  methods: {
    generateChartColor(length) {
      this.chartColor = []
      function hex(c) {
        let s = '0123456789abcdef'
        let i = parseInt(c)
        if (i == 0 || isNaN(c)) return '00'
        i = Math.round(Math.min(Math.max(0, i), 255))
        return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16)
      }

      function convertToHex(rgb) {
        return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2])
      }

      function trim(s) {
        return s.charAt(0) == '#' ? s.substring(1, 7) : s
      }

      function convertToRGB(hex) {
        let color = []
        color[0] = parseInt(trim(hex).substring(0, 2), 16)
        color[1] = parseInt(trim(hex).substring(2, 4), 16)
        color[2] = parseInt(trim(hex).substring(4, 6), 16)
        return color
      }

      function generateColor(colorStart, colorEnd, colorCount) {
        let start = convertToRGB(colorStart)

        let end = convertToRGB(colorEnd)

        let len = colorCount

        let alpha = 0.0

        let saida = []
        let n = 0
        while (n < len) {
          let c = []
          alpha += 1.0 / len

          c[0] = start[0] * alpha + (1 - alpha) * end[0]
          c[1] = start[1] * alpha + (1 - alpha) * end[1]
          c[2] = start[2] * alpha + (1 - alpha) * end[2]

          saida.push(convertToHex(c))
          n++
        }

        return saida
      }

      generateColor('#50C1EE', '#002956', length).forEach((color) => {
        this.chartColor.push('#' + color)
      })
    },
    categoryRangeSelected(event) {
      this.filter.date = event
    },
    async onFilter() {
      console.log('cost', this.filter)
      this.costcenter_code = []
      this.legendCallback = []
      this.date_from = ''
      this.date_to = ''
      if (
        this.filter.costcenter !== undefined &&
        this.filter.costcenter.length > 0
      ) {
        this.filter.costcenter.forEach((a) => {
          this.costcenter_code.push(a.value)
        })
      }

      this.date_from = this.filter.date.from ?? ''
      this.date_to = this.filter.date.to ?? ''
      this.select_top = this.filter.selectTop.value
      this.getsummaryCategories()
    },
    setLegend(callback) {
      this.legendCallback = callback
      console.log(this.legendCallback)
    },
    async getsummaryCategories() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      if (
        this.costcenter_code !== undefined &&
        this.costcenter_code.length > 0
      ) {
        this.costcenter_code.forEach((s) => {
          params.append('code', s)
        })
      }
      if (this.date_from !== '' && this.date_to !== '') {
        params.append('date_from', this.date_from)
        params.append('date_to', this.date_to)
      }
      params.append('filter', this.select_top)
      await this.$reportProvider.getsummaryCategoriesData(params).then(
        (result) => {
          if (result.status === 200) {
            this.dataSource = []
            this.dataSeries = []
            let data = result.data
            data.costcenters.forEach((a, index) => {
              this.dataSeries.push({
                index: index + 1,
                label: a,
                name: a,
              })
            })
            data.categories.forEach((b, index) => {
              let object = {}
              let key = ''
              key = 'label'
              object[key] = b.category_name
              let result = b.costcenters
                .filter((e) => e.category_name === b.category_name)
                .sort(function (a, b) {
                  return a.total_amt - b.total_amt
                })
              result.forEach((c) => {
                key = c.costcenter_code
                object[key] = c.total_amt
              })
              this.dataSource.push(object)
            })
            // generate shade color by dataSeries.length
            this.generateChartColor(this.dataSeries.length)

            this.dataSeries.forEach((element, index) => {
              element.color = this.chartColor[index]
              //  this.dataSeries.length > 10 ? index : index + 1
            })
            // }
          }
        },
        (error) => {
          console.log('getsummaryCategories', error.responsed)
        }
      )
    },
  },
}
