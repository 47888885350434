
import asideComponent from '@/components/template/aside-user.vue'
import listAddress from '@/components/share/list-address.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'AccountAddress',
  components: {
    'aside-component': asideComponent,
    'list-address': listAddress,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      shipAddress: [],
      accountid: '',
      pagetype: 'shipping',
      isShow: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  mounted() {
    this.getAllShippingById()
  },
  methods: {
    async getAllShippingById() {
      await this.$profileProvider
        .getAllShippingDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data.items
              this.shipAddress = data
              this.accountid = this.userProfile.id
              this.isShow = this.shipAddress.length > 0 ? data[0].isShow : false
            }
          },
          (error) => {
            console.log('getAllShippingById', error.responsed)
          }
        )
    },
  },
}
