
export default {
  name: 'SwiperSliderComponent',
  props: {
    images: { type: Array, default: () => [] },
    loop: { type: Boolean, default: false },
    hideButton: { type: Boolean, default: true },
    banner: { type: Boolean, default: false },
    // selector: { type: Boolean, default: false },
    spaceBetween: { type: Number, default: 0 },
    count: { type: Number, default: 1 },
    bannerCreative: { type: String, default: '' },
    imageType: { type: String, default: '' },
    imageField: { type: String, default: '' },
    prefixBanner: {
      type: String,
      default: 'HP',
    },
    autoPlay: { type: Number, default: 0 },
    speed: { type: Number, default: 800 },
    position: { type: String, default: '' },
    height: { type: String, default: 'auto' },
    width: { type: String, default: 'auto' },
    groupslide: { type: Boolean, default: false },
    slideStyle: { type: String, default: '' },
    showPagination: { type: Boolean, default: false },
  },
  data() {
    return {
      ref: '',
      isActivePrev: false,
      isActiveNext: false,
      swiperOption: {
        // slidesPerView: this.count,
        // loop: this.loop,
        // slidesPerGroup: this.groupslide ? this.count : 1,
        lazy: true,
        breakpoints: this.breakpoints(),
        // loopFillGroupWithBlank: true,
        speed: this.speed,
        // pagination: {
        //   el: '#item-pagination-' + this.ref,
        //   clickable: true,
        // },
      },
      sizes: '',
      // height: '385px',
    }
  },
  watch: {
    images: {
      handler: function (val) {
        if (val.length > 0) {
          this.onSlide()
          val.forEach((image) => {
            if (this.slideStyle == 'categoryBanner1') {
              this.$utils.onPreload(
                this.$utils.genImgSrcSet(
                  this.$utils.imageTimeStamp(
                    image.src,
                    image?.update_info?.timestamp || this.update_info,
                    true
                  ),
                  [
                    { w: 328, h: 52, q: 90, ws: '328w' },
                    { w: 380, h: 60, q: 90, ws: '380w' },
                    { w: 736, h: 117, q: 90, ws: '736w' },
                    { w: 992, h: 157, q: 90, ws: '992w' },
                    { w: 1368, h: 215, q: 90, ws: '1368w' },
                  ]
                ),
                '(max-width: 360px) 328px, (max-width: 412px) 380px, (max-width: 768px) 736px, (max-width: 1024px) 992px,(max-width: 1280px) 1368px'
              )
            } else if (this.slideStyle == 'categoryBanner2') {
              this.$utils.onPreload(
                this.$utils.genImgSrcSet(
                  this.$utils.imageTimeStamp(
                    image.src,
                    image?.update_info?.timestamp || this.update_info,
                    true
                  ),
                  [
                    { w: 284, h: 93, q: 90, ws: '284w' },
                    { w: 329, h: 108, q: 90, ws: '329w' },
                    { w: 364, h: 120, q: 90, ws: '364w' },
                    { w: 451, h: 148, q: 90, ws: '451w' },
                    { w: 492, h: 162, q: 90, ws: '492w' },
                  ]
                ),
                '(max-width: 360px) 328px, (max-width: 412px) 380px, (max-width: 768px) 736px, (max-width: 1024px) 492px, 451px, (max-width: 1280px) 451px'
              )
            } else {
              this.$utils.onPreload(
                this.$utils.genImgSrcSet(
                  this.$utils.imageTimeStamp(
                    image.src,
                    image?.update_info,
                    true
                  ),
                  [
                    { w: 208, h: 164, q: 90, ws: '208w' },
                    { w: 219, h: 173, q: 90, ws: '219w' },
                    { w: 233, h: 184, q: 90, ws: '233w' },
                    { w: 245, h: 193, q: 90, ws: '245w' },
                    { w: 253, h: 200, q: 90, ws: '253w' },
                    { w: 331, h: 261, q: 90, ws: '331w' },
                    { w: 405, h: 320, q: 90, ws: '405w' },
                  ]
                ),
                '(max-width: 360px) 219px, (max-width: 412px) 253px, (max-width: 768px) 245px, (max-width: 1024px) 331px,(max-width: 1280px) 208px, 233px'
              )
            }
          })
        }
        // this.$refs[this.ref].$swiper.params.slidesPerView = this.responsive()
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    if (this.autoPlay > 0) {
      this.swiperOption.autoplay = { delay: this.autoPlay }
      // this.swiperOption.speed = this.speed
    }
    this.swiperOption.spaceBetween = this.spaceBetween
    this.swiperOption.pagination = {
      el: '#item-pagination-' + this.ref,
      clickable: true,
    }
    // this.swiperOption.loop = this.loop
  },
  // computed: {
  //   imagesLoop() {
  //     let images = []
  //     // LMC
  //     let loop = 0
  //     let min =
  //       this.count > this.images.length ? this.count : this.images.length

  //     while (true) {
  //       if (min % this.count == 0 && min % this.images.length == 0) {
  //         loop = min / this.images.length
  //         break
  //       }
  //       min++
  //     }

  //     // duplicate items while loop
  //     if (this.loop && this.groupslide && loop > 1) {
  //       for (let i = 0; i < loop; i++) {
  //         images = images.concat(this.images)
  //       }
  //     } else {
  //       images = images.concat(this.images)
  //     }
  //     console.log('images', images)
  //     return images
  //   },
  // },
  mounted() {
    this.onSlide()
    if (this.position === 'promotion')
      this.sizes = 'xs:204 sm:405 md:255px lg:330px xl:223px'
  },
  methods: {
    onSwiperReady() {
      this.$refs[this.ref].$swiper.$el[0].children[0].onclick =
        this.onClickPerSlide
    },
    onClickPerSlide(event) {
      const image = JSON.parse(event.target.dataset.image)
      if (image !== undefined) {
        if (image.brandName) {
          // this.$router.push({
          //   path: this.$i18n.path(`brand/${image.brandName}`),
          // })
        } else {
          if (
            this.$utils.isValid(this.imageField) &&
            this.$utils.isValid(this.imageType) &&
            this.imageType === 'searchBrand'
          ) {
            this.$router.push({
              path: this.$i18n.path(`brand/${image[this.imageField]}`),
            })
          } else {
            if (
              this.$utils.isValid(this.bannerCreative) &&
              this.$utils.isValid(this.imageType) &&
              this.imageType === 'banner'
            ) {
              let title = image.title_tag?.[this.$i18n.locale] || image.title

              // console.log('image.title', title)
              //gtm promoClicked
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'promoClicked',
                ecommerce: {
                  promoClick: {
                    promotions: [
                      {
                        id: image.src,
                        name: `${this.prefixBanner}|${
                          image.gtmSeq
                        }|${title}|${this.$utils.formatDateGTM(
                          image.active_from
                        )}_${this.$utils.formatDateGTM(image.active_to)}`,
                        creative: this.bannerCreative,
                        position: image.gtmSeq,
                      },
                    ],
                  },
                },
              })

              //gtm GA4
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'select_promotion',
                ecommerce: {
                  creative_name: this.bannerCreative,
                  creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
                  promotion_id: image.src,
                  promotion_name: `${this.prefixBanner}|${
                    image.gtmSeq
                  }|${title}|${this.$utils.formatDateGTM(
                    image.active_from
                  )}_${this.$utils.formatDateGTM(image.active_to)}`,
                },
              })

              // gtm GA4
              const promotion = {
                creative_name: this.bannerCreative,
                creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
                promotion_id: image.src,
                promotion_name: `${this.prefixBanner}|${
                  image.gtmSeq
                }|${title}|${this.$utils.formatDateGTM(
                  image.active_from
                )}_${this.$utils.formatDateGTM(image.active_to)}`,
              }
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                user: this.$store.getters['user/userInfoTrack'],
                ...promotion,
              })
              this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
            }

            // if (this.$utils.isValid(image.url)) {
            //   this.$nextTick(() => {
            //     window.open(image.url, '_blank')
            //   })
            // }
          }
        }
      }
    },
    breakpoints() {
      console.log('img', this.images)
      let mobile = 0
      let tablet = 0
      let desktop = 0

      switch (this.count) {
        case 7:
          mobile = 2.4
          tablet = 5
          desktop = 7
          break
        case 6:
          mobile = this.imageType === 'banner' ? 1.5 : 1
          tablet = 3
          desktop = 6
          break
        case 3:
          mobile = 1.15
          tablet = 2
          desktop = 3
          break
        case 1:
          mobile = 1
          tablet = 1
          desktop = 1
          break
        default:
          mobile = 1
          tablet = 3
          desktop = 6
          break
      }

      return {
        0: {
          slidesPerView: mobile,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: tablet,
          slidesPerGroup: this.groupslide ? tablet : 1,
          loop: this.loop && this.images.length >= tablet,
        },
        1179: {
          slidesPerView: desktop,
          slidesPerGroup: this.groupslide ? desktop : 1,
          loop: this.loop && this.images.length >= desktop,
        },
      }
    },
    // responsive() {
    //   let slidesPerView = this.count
    //   let windowWidth = document.documentElement.clientWidth
    //   switch (this.count) {
    //     case 7:
    //       if (windowWidth < 767) {
    //         slidesPerView = 3
    //       } else if (windowWidth > 767 && windowWidth < 1280) {
    //         slidesPerView = 5
    //       } else {
    //         slidesPerView = 7
    //       }
    //       break
    //     case 6:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1280) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //     case 5:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 5
    //       }
    //       break
    //     case 4:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 2
    //       } else {
    //         slidesPerView = 4
    //       }
    //       break
    //     case 3:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 2
    //       } else {
    //         slidesPerView = 3
    //       }
    //       break
    //     case 2:
    //       slidesPerView = 2
    //       break
    //     case 1:
    //       slidesPerView = 1
    //       break
    //     default:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //   }
    //   return slidesPerView
    // },
    onSlide() {
      if (this.hideButton) {
        this.isActivePrev =
          this.$refs[this.ref].$swiper.activeIndex === 0 && !this.loop
            ? false
            : true
        this.isActiveNext =
          this.$refs[this.ref].$swiper.activeIndex ===
            this.images.length -
              this.$refs[this.ref].$swiper.params.slidesPerView && !this.loop
            ? false
            : true
      }
    },
    next() {
      this.$refs[this.ref].$swiper.slideNext()
    },
    prev() {
      this.$refs[this.ref].$swiper.slidePrev()
    },
    // onClickImage(image) {
    //   if (image !== undefined) {
    //     if (image.brandName) {
    //       this.$router.push({
    //         path: this.$i18n.path(`brand/${image.brandName}`),
    //       })
    //     } else {
    //       if (
    //         this.$utils.isValid(this.imageField) &&
    //         this.$utils.isValid(this.imageType) &&
    //         this.imageType === 'searchBrand'
    //       ) {
    //         this.$router.push({
    //           path: this.$i18n.path(`brand/${image[this.imageField]}`),
    //         })
    //       } else {
    //         if (
    //           this.$utils.isValid(this.bannerCreative) &&
    //           this.$utils.isValid(this.imageType) &&
    //           this.imageType === 'banner'
    //         ) {
    //           //gtm promoClicked
    //           this.$gtm.push({ ecommerce: null })
    //           this.$gtm.push({
    //             event: 'promoClicked',
    //             ecommerce: {
    //               promoClick: {
    //                 promotions: [
    //                   {
    //                     id: image.src,
    //                     name: `${this.prefixBanner}|${image.gtmSeq}|${
    //                       image.title
    //                     }|${this.$utils.formatDateGTM(
    //                       image.active_from
    //                     )}_${this.$utils.formatDateGTM(image.active_to)}`,
    //                     creative: this.bannerCreative,
    //                     position: image.gtmSeq,
    //                   },
    //                 ],
    //               },
    //             },
    //           })

    //           // gtm GA4
    //           const promotion = {
    //             creative_name: this.bannerCreative,
    //             creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
    //             promotion_id: image.src,
    //             promotion_name: `${this.prefixBanner}|${image.gtmSeq}|${
    //               image.title
    //             }|${this.$utils.formatDateGTM(
    //               image.active_from
    //             )}_${this.$utils.formatDateGTM(image.active_to)}`,
    //           }
    //           this.$gtm.push({ ecommerce: null })
    //           this.$gtm.push({
    //             user: this.$store.getters['user/userInfoTrack'],
    //             ...promotion,
    //           })
    //           this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
    //         }

    //         if (this.$utils.isValid(image.url)) {
    //           this.$nextTick(() => {
    //             window.open(image.url, '_blank')
    //           })
    //         }
    //       }
    //     }
    //   }
    // },
  },
}
