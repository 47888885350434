
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import SelectObject from '@/components/share/select-object.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'AccountAddressEdit',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    'select-object': SelectObject,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isDefault: false,
      isPhone: false,
      address: {
        contact: '',
        phone: '',
        mobile: '',
        extension: '',
        address_1: '',
        address_2: '',
        postcode: '',
        province: '',
        district: '',
        sub_district: '',
        remark: '',
        is_default: false,
        phonetype: '',
        system: 'Online e-Procurement',
        is_deliveryfee: '',
      },
      district: [
        {
          text: this.$i18n.t(`account-address-create.select`),
          value: '',
        },
      ],
      sub_district: [
        {
          text: this.$i18n.t(`account-address-create.select`),
          value: '',
        },
      ],
      province: [
        {
          text: this.$i18n.t(`account-address-create.select`),
          value: '',
        },
      ],
      zoneList: [],
      tempProvinceGroup: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    requiredIsPhone() {
      return this.address.phone === '' && this.address.mobile === ''
    },
  },
  watch: {
    'address.district': function (val) {
      console.log('val', val)
      let filterZone = this.zoneList.filter((q) => q.district.th === val.th)
      this.sub_district = [
        {
          text: this.$i18n.t(`account-address-create.select`),
          value: '',
        },
      ]
      if (filterZone != undefined && filterZone.length) {
        filterZone.forEach((item) => {
          this.sub_district.push({
            is_deliveryfee: item?.is_deliveryfee,
            text: item.sub_district.th,
            value: item.sub_district,
          })
        })
        this.address.sub_district = this.sub_district[1].value
      }
    },
    'address.phone': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'address.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
    'address.sub_district': function (val) {
      this.address.is_deliveryfee = this.sub_district.find(
        (a) => a.text === val.th
      )?.is_deliveryfee
    },
    'address.province': {
      handler: function (val) {
        if (val.en) {
          this.tempProvinceGroup[
            this.tempProvinceGroup.findIndex((e) => e.province.en === val.en)
          ].items.forEach((item) => {
            if (
              this.district.find((q) => q.value.en === item.district.en) ===
              undefined
            ) {
              this.district.push({
                text: item.district.th,
                value: item.district,
              })

              this.address.district = this.district[1].value
            }
          })
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    validateBeforeSubmit() {
      this.$refs.addShippingForm.validate().then((result) => {
        console.log('result', result)
        if (result) {
          if (this.address.phone === '' && this.address.extension !== '') {
            this.isPhone = true
          } else {
            this.createShipping()
            this.isPhone = false
          }
        }
      })
    },
    async searchZone(event) {
      if (event.length >= 5) {
        const params = new URLSearchParams()
        params.append('keyword', event)
        this.district = [
          {
            text: this.$i18n.t(`account-address-create.select`),
            value: '',
          },
        ]
        await this.$logisticProvider
          .getZone(params)
          .then((result) => {
            this.zoneList = result.data
            if (result.data.length) {
              // groups
              this.province = [
                {
                  text: this.$i18n.t(`account-address-edit-id.select`),
                  value: '',
                },
              ]
              let value = result.data
              let groups = []
              value = value.reduce((h, value) => {
                return Object.assign(h, {
                  [value['province']['en']]: (
                    h[value['province']['en']] || []
                  ).concat(value),
                })
              }, {})
              console.log('value', value)
              Object.keys(value).forEach((element) => {
                groups.push({
                  province: value[element][0].province,
                  items: value[element],
                })
              })
              this.tempProvinceGroup = groups

              this.tempProvinceGroup.forEach((e) => {
                this.province.push({
                  text: e.province.th,
                  value: e.province,
                })
              })

              this.address.province = groups[0].province
            }
            let _district = this.district.find(
              (q) => q.text === this.address.district.th
            )
            if (_district === undefined) {
              this.cleardata()
            }
          })
          .catch((err) => {
            console.log('searchZone', err)
          })
          .finally(() => {
            console.log('finally!!')
          })
      } else {
        this.address.sub_district = ''
        this.address.district = ''
        this.address.province = ''
        this.sub_district = [
          {
            text: this.$i18n.t(`account-address-create.select`),
            value: '',
          },
        ]
        this.district = [
          {
            text: this.$i18n.t(`account-address-create.select`),
            value: '',
          },
        ]
        this.province = [
          {
            text: this.$i18n.t(`account-address-create.select`),
            value: '',
          },
        ]
      }
    },
    async createShipping() {
      console.log('extension', this.address)
      await this.$profileProvider
        .createShippingData(this.userProfile.id, this.address)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 201) {
              this.toAllShipping()
            }
          },
          (error) => {
            console.log('createShipping', error.responsed)
          }
        )
    },
    toAllShipping() {
      this.$router.push({ path: this.$i18n.path('account/delivery') })
    },
    cleardata() {
      this.address.sub_district = ''
      this.address.district = ''
      this.sub_district = [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ]
    },
  },
}
