
export default {
  name: 'ModalCardPreview',
  props: {
    objData: { type: Object, default: () => {} },
  },
  data() {
    return {
      title: '',
      // printing: {},
    }
  },
  watch: {
    objData: {
      handler: function (val) {
        if (Object.keys(val).length) {
          this.title =
            this.$t('bizcard.printing-name') +
            ' : ' +
            val.template_name[this.$i18n.locale]
          console.log('printing-modal', val)
          this.$refs['printing-modal'].show()
        }
      },
      deep: true,
    },
  },
  // async mounted() {
  // this.$nuxt.$on('open-printing-modal', (data) => {
  //   this.$refs['printing-modal'].show()
  //   this.getData(data)
  // })
  // },
  // beforeDestroy() {
  //   this.$nuxt.$off('open-printing-modal', () => {
  //     // this.$refs['printing-modal'].hide()
  //     // this.printing = {}
  //   })
  // },
  methods: {
    onClose() {
      this.$emit('onResetPreview')
      this.$nextTick(() => {
        this.$refs['printing-modal'].hide()
      })
    },
  },
}
