
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTotal',
  components: {
    Multiselect,
  },
  props: {},
  data() {
    return {
      filter: {
        costcenter: [],
        selectTop: { name: this.$t(`company-dashboard.top-10`), value: 10 },
      },
      selectTopOptions: [
        { name: this.$t(`company-dashboard.top-10`), value: 10 },
        { name: this.$t(`company-dashboard.top-20`), value: 20 },
      ],
      dataSource: [],
      control_level: '',
      select_top: 10,
      costcenter_code: [],
      costcenterOptions: [],
      isNoDetail: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  created() {},
  mounted() {
    this.getBudgetControl()
  },
  methods: {
    // async getData() {
    //   // this.dataSource = [
    //   //   {
    //   //     index: 1,
    //   //     name: 'บริษัท โซลูชั่น คอนเซาท์ จำกัด ',
    //   //     budget: {
    //   //       budget_amt: 45000,
    //   //       remain_amt: 15000,
    //   //       used_amt: 30000,
    //   //     },
    //   //   },
    //   //   {
    //   //     index: 1,
    //   //     name: 'บริษัท โซลูชั่น คอนเซาท์ จำกัด ',
    //   //     budget: {
    //   //       budget_amt: 45000,
    //   //       remain_amt: 20000,
    //   //       used_amt: 25000,
    //   //     },
    //   //   },
    //   //   {
    //   //     index: 1,
    //   //     name: 'บริษัท โซลูชั่น คอนเซาท์ จำกัด ',
    //   //     budget: {
    //   //       budget_amt: 45000,
    //   //       remain_amt: 13000,
    //   //       used_amt: 32000,
    //   //     },
    //   //   },
    //   // ]
    // },
    onFilter() {
      console.log('cost', this.filter)
      this.dataSource = []
      this.select_top = []
      this.costcenter_code = []
      if (
        this.filter.costcenter !== undefined &&
        this.filter.costcenter.length > 0
      ) {
        this.filter.costcenter.forEach((a) => {
          this.costcenter_code.push(a.value)
        })
      }
      this.select_top = this.filter.selectTop.value
      this.getBudgetControl()
      // this.getData()
    },
    async getBudgetControl() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      if (
        this.costcenter_code !== undefined &&
        this.costcenter_code.length > 0
      ) {
        this.costcenter_code.forEach((s) => {
          params.append('code', s)
        })
      }
      params.append('filter', this.select_top)
      await this.$reportProvider.getBudgetControlData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('99999999999 :>> ', data)

            if (data.budgetControls !== null) {
              data.budgetControls.forEach((a) => {
                this.control_level = a.control_level
                this.dataSource.push({
                  name:
                    a.control_level === 'Company'
                      ? a.name[this.$i18n.locale]
                      : '[' + a.code + ']' + a.name[this.$i18n.locale],
                  budget: a.periods[0],
                })
              })
              this.costcenterOptions = data.costCenters
              console.log('object :>> ', this.costcenterOptions)
              console.log('dataSource :>> ', this.dataSource)

              if (this.dataSource.length) {
                let checkFilter = this.dataSource.find(
                  (o) => o.budget !== undefined
                )
                if (checkFilter === undefined) {
                  this.isNoDetail = true
                } else this.isNoDetail = false
                console.log('checkFilter :>> ', checkFilter)
              }
            }
          }
        },
        (error) => {
          console.log('getBudgetControl', error.responsed)
        }
      )
    },
  },
}
