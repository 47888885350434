
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData', 'options'],
  watch: {
    chartData: function (value) {
      console.log(value)
      this.update()
    },
  },
  mounted() {
    this.update()
  },
  methods: {
    update() {
      // this.$data._chart.update()
      this.addPlugin({
        id: 'my-plugin',
        // beforeDraw: this.plugin,
      })
      this.renderChart(this.chartData, this.options)
    },
  },
}
