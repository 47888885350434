
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import TableReportBudgetLog from '@/components/company/table-report-budget-log.vue'
// import { mapGetters } from 'vuex'
import { exportExcelBudgetLogsReportDocument } from '@/plugins/export-excel.js'

export default {
  layout: 'main',
  name: 'CompanyReportBudgetLog',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    TableReportBudgetLog,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      costcenter: [],
    }
  },
  mounted() {
    this.getBudgetLogsReport()
  },
  methods: {
    async getBudgetLogsReport() {
      let params = new URLSearchParams()
      params.append('budget_id', this.$route.params.id)
      params.append('period_id', this.$route.query.period)
      params.append('control_level', this.$route.query.type)
      params.append('year', this.$route.query.year)
      await this.$reportProvider.getBudgetLogsReportData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((element) => {
              this.costcenter.push({
                department_id: element.costcenter_code,
                budget_control: this.getBudgetControl(
                  this.$route.query.ptype,
                  element.period_id
                ),
                year: element.year,
                budget_start: element.old_remain_amt,
                budget_current: element.increase_amt,
                budget_used: element.decrease_amt,
                budget_left: element.new_remain_amt,
                order_ref: element.order_id,
                process_date: element.create_info.timestamp,
              })
            })
          }
        },
        (error) => {
          console.log('getBudgetLogsReport', error.responsed)
        }
      )
    },
    getBudgetControl(type, id) {
      let budget = []
      switch (type) {
        case 'Month':
          const monthNames = [
            'January',
            'Febuary',
            'March',
            'April',
            'May',
            'June',
            'July',
            'Augest',
            'September',
            'October',
            'November',
            'Decumber',
          ]
          budget = monthNames[id - 1]
          break
        case 'Haft':
          budget = id < 2 ? 'ครึ่งปีแรก' : 'ครึ่งปีหลัง'
          break
        case 'Quarter':
          budget =
            id === 1
              ? 'ไตรมาส 1'
              : id === 2
              ? 'ไตรมาส 2'
              : id === 3
              ? 'ไตรมาส 3'
              : id === 4
              ? 'ไตรมาส 4'
              : ''
          break
        case 'Year':
          budget = 'ปี'
          break
      }
      return budget
    },
    async exportExcel() {
      exportExcelBudgetLogsReportDocument(this.costcenter)
    },
  },
}
