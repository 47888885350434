
export default {
  name: 'InputQtyComponent',
  props: {
    type: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    maxQty: { type: Number, default: 0 },
    minQty: { type: Number, default: 0 },
    click: { type: Boolean, default: true },
    alertLimitQty: { type: Boolean, default: false },
    qtyWarning: { type: Array, default: () => [] },
    qtyLength: { type: Number, default: 1 },
    fixQty: { type: Boolean, default: false },
    stockAvar: { type: Number, default: 0 },
    isUseDebouce: { type: Boolean, default: false },
  },
  data() {
    return {
      input: '',
      newValue: '',
      oldValue: '',
      timeout: null,
    }
  },
  computed: {
    addEmptyClass: function () {
      return this.isEmpty && this.input === '' ? true : false
    },
    debouceInput: {
      get() {
        return this.input
      },
      set(val) {
        let tempVal = String(val)
        tempVal = tempVal
          .replace(/['\\',\\'\\,':',';','\t','\n','\r','\n']/g, '')
          .replace(/[^\d]/g, '')
        // this.input = tempVal
        console.log('tempVal', tempVal)
        if (tempVal !== '') {
          tempVal = isNaN(tempVal) ? 1 : tempVal
          tempVal = parseInt(tempVal)
          tempVal = tempVal <= 0 ? 1 : tempVal
          tempVal =
            this.maxQty > 0 && this.maxQty < tempVal ? this.maxQty : tempVal
          tempVal = tempVal > this.stockAvar ? this.stockAvar : tempVal
          this.input = null
          this.input = tempVal
          if (this.isUseDebouce) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
              this.newValue = tempVal
            }, 600)
          } else {
            this.newValue = tempVal
          }
        } else {
          this.input = null
          this.input = ''
          this.newValue = ''
        }
      },
    },
  },
  watch: {
    // input: {
    //   handler: function (val, oldValue) {
    //     // console.log('val: ', val, 'oldValue: ', oldValue)
    //     if (this.maxQty > 0 && this.maxQty < parseInt(val)) val = this.maxQty
    //     // console.log('val', val)
    //     let tempVal = String(val)
    //     tempVal = tempVal
    //       .replace(/['\\',\\'\\,':',';','\t','\n','\r','\n']/g, '')
    //       .replace(/[^0-9]/g, '')
    //     // console.log('tempVal', tempVal)
    //     this.input = tempVal
    //     if (parseInt(tempVal) <= 0) {
    //       tempVal = String(1)
    //       this.input = 1
    //     }
    //     this.newValue = tempVal
    //     // if (this.maxQty > 0 && this.maxQty < val) val = this.maxQty
    //     // val = String(val)
    //     // this.input = val
    //     //   .replace(/['\\',\\'\\,':',';','\t','\n','\r','\n']/g, '')
    //     //   .replace(/[^0-9]/g, '')
    //     // if (this.input <= '0') this.input = String(1)
    //     // if (this.input === '0') {
    //     //   console.log('remove item ?')
    //     // }
    //     // this.$emit('value', this.input)
    //   },
    // },
    newValue: {
      handler: async function (val, oldValue) {
        this.oldValue = oldValue
        await this.$emit('value', val)
        this.onChangeQty()
      },
    },
    // disabled: {
    //   handler: function (val) {
    //     if (val) this.input = ''
    //   },
    // },
    defaultValue: {
      handler: function (val) {
        if (val && parseInt(val) !== parseInt(this.input)) {
          this.input = parseInt(val)
          this.oldValue = parseInt(val)
        }
      },
    },
  },
  created() {
    if (this.defaultValue) {
      this.input = parseInt(this.defaultValue)
      this.oldValue = parseInt(this.defaultValue)
    }
  },
  methods: {
    async onClickChangeQty(action) {
      this.oldValue = this.input
      if (action === 'increase') {
        this.input = parseInt(this.input) + this.qtyLength
      } else {
        this.input =
          parseInt(this.input) > this.minQty
            ? parseInt(this.input) - this.qtyLength
            : (this.input = this.minQty)
      }
      this.newValue = this.input
      // await this.$emit('value', this.input)
      // this.onChangeQty()
    },
    onChangeQty(event) {
      if (event === 'onBlur' && this.input === '') this.debouceInput = 1
      else {
        if (parseInt(this.input) !== parseInt(this.oldValue)) {
          if (this.input !== '') {
            this.$emit('changeQty', this.input)
            this.oldValue = this.input
          }
        }
      }
    },
  },
}
