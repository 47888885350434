
export default {
  name: 'QrSuccessComponent',
  props: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
