
import asideComponent from '@/components/template/aside-user.vue'
import InputQty from '@/components/share/input-qty-special.vue'
import InputText from '@/components/share/input-text.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: 'main',
  name: 'CompanyInformation',
  components: {
    'aside-component': asideComponent,
    'input-qty': InputQty,
    'input-text': InputText,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isEdit: false,
      companies: {
        company_code: '',
        name: {
          th: '',
          en: '',
        },
        company_logo_url: '',
        discount_rate: '',
        company_level: '',
        price_type: '',
        categories_type: '',
        password_period: '',
        default_expiration_period: '',
        is_use_budget_control: false,
        is_site_active: false,
        is_show_banner_promotion: false,
        accounts: {
          id: '',
          is_default: true,
        },
        document_setting_desc: '',
        control_level_desc: '',
        period_type_desc: '',
        categories_type_desc: '',
        url: '',
      },
      image: {
        url: '',
        cdn_url: '',
      },
      iserror: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  mounted() {
    this.getCompaniesById()
  },
  methods: {
    ...mapActions('user', [
      'setUserProfileISBanner',
      'setCompanyLogo',
      'setCompanyName',
    ]),
    browseFile() {
      this.$refs['browse-file'].click()
    },
    async selected({ target: { files } }) {
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              console.log('valid')
              console.log('file', files[0])
              // this.importFile(files[0])
              await this.uploadFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async uploadFile(file) {
      this.iserror = false
      // let newname = file.name.toLowerCase()
      let formData = new FormData()
      formData.append('id', this.userProfile.id)
      formData.append('file', file)
      formData.append('prefix_path', 'personal-document')
      formData.append('content_name', 'logo')
      formData.append('is_private', false)

      await this.$profileProvider.uploadContentImage(formData).then(
        (result) => {
          console.log(result)
          if (result.status === 201) {
            console.log('url', result.data)
            if (result.data.url !== null) {
              this.image.url = result.data.original_url
              this.image.cdn_url = result.data.url
              console.warn('image :>> ', this.image)
              this.UpdateCompaniesImageUrl()
            } else {
              this.iserror = true
            }
          }
        },
        (error) => {
          console.log('uploadFile', error.responsed)
        }
      )
    },
    validateCompaniesInfoSubmit() {
      this.$refs.editCompaniesInfoForm.validate().then((result) => {
        if (result) this.updateCompaniesInfo()
      })
    },
    validateCompaniesSettingSubmit() {
      this.$refs.editCompaniesSettingForm.validate().then((result) => {
        if (result) this.updateCompaniesSetting()
      })
    },
    async getCompaniesById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.companies = result.data
            }
          },
          (error) => {
            console.log('getCompaniesById', error.responsed)
          }
        )
    },
    async updateCompaniesInfo() {
      await this.$profileProvider
        .updateCompaniesInfoDataById(this.userProfile.id, this.companies)
        .then(
          (result) => {
            if (result.status === 204) {
              this.getCompaniesById()
              this.isEdit = false
              this.setCompanyName(this.companies.name)
            }
          },
          (error) => {
            console.log('updateCompaniesInfo', error.responsed)
          }
        )
    },
    async updateCompaniesSetting() {
      await this.$profileProvider
        .updateCompaniesSettingDataById(this.userProfile.id, this.companies)
        .then(
          (result) => {
            if (result.status === 204) {
              this.getCompaniesById()
              this.setUserProfileISBanner(
                this.companies.is_show_banner_promotion
              )
            }
          },
          (error) => {
            console.log('updateCompaniesSetting', error.responsed)
          }
        )
    },
    async UpdateCompaniesImageUrl() {
      await this.$profileProvider
        .UpdateCompaniesImageUrlData(this.userProfile.id, this.image)
        .then(
          (result) => {
            if (result.status === 204) {
              console.warn('this.image :>> ', this.image)
              this.getCompaniesById()
              this.setCompanyLogo(this.image.cdn_url)
            }
          },
          (error) => {
            console.log('UpdateCompaniesImageUrl', error.responsed)
          }
        )
    },
  },
}
