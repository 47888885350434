
import asideComponent from '@/components/template/aside-user.vue'
import Multiselect from 'vue-multiselect'
import notfound from '@/components/share/notfound.vue'

import { mapGetters, mapActions } from 'vuex'
// import PrintingSummary from '@/components/printing/summary.vue'
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'

export default {
  layout: 'main',
  name: 'PrintingCreate',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    notfound,
    // PrintingSummary,
  },
  data() {
    return {
      isSkeleton: true,
      sampleLoading: true,
      isNotFound: false,
      printing: {
        // name: 'ทดสอบ',
        // thumbnail: 'template_front.png',
        // thumbnailBack: 'template_back.png',
        // layout: 'horizontal',
        // is_use_back_side: true,
        // size: {
        //   height: 275,
        //   width: 450,
        // },
        // text_object: [
        //   {
        //     sequence: 1,
        //     isEdit: false,
        //     is_front_page: true,
        //     field_name: 'ชื่อ นามสกุล',
        //     default_text: 'Nuttapong Kornlerdwanich',
        //     style: {
        //       size: 16,
        //       position_left: 30,
        //       position_top: 150,
        //       position_right: 0,
        //       align: 'left',
        //       color: '#000000',
        //       family: {
        //         text: 'Kanit',
        //         value: 'Kanit',
        //       },
        //       bold: false,
        //       underline: false,
        //       italic: false,
        //     },
        //   },
        //   {
        //     sequence: 2,
        //     isEdit: false,
        //     is_front_page: true,
        //     field_name: 'ตำแหน่ง',
        //     default_text: 'UI Frontend Developer',
        //     style: {
        //       size: 12,
        //       position_left: 30,
        //       position_top: 180,
        //       position_right: 0,
        //       align: 'left',
        //       color: '#000000',
        //       family: {
        //         text: 'Kanit',
        //         value: 'Kanit',
        //       },
        //       bold: false,
        //       underline: false,
        //       italic: false,
        //     },
        //   },
        //   {
        //     sequence: 3,
        //     isEdit: false,
        //     is_front_page: false,
        //     field_name: 'Text',
        //     default_text: 'COMPANY',
        //     style: {
        //       size: 20,
        //       position_left: 0,
        //       position_top: 180,
        //       position_right: 0,
        //       align: 'center',
        //       color: '#ffffff',
        //       family: {
        //         text: 'Kanit',
        //         value: 'Kanit',
        //       },
        //       bold: false,
        //       underline: false,
        //       italic: false,
        //     },
        //   },
        // ],
      },
      edit: {
        frontDetail: [],
        backDetail: [],
      },
      frontInner: {
        height: '',
        width: '',
      },
      backInner: {
        height: '',
        width: '',
      },
      option_paper: [
        // {
        //   text: '[P000001] นามบัตรอาร์ตการ์ด 250g. 1P	 ',
        //   value: 'P000001',
        // },
      ],
      // optionCorner: [
      //   {
      //     title: 'Space Pirate',
      //     desc: 'More space battles!',
      //     img: '',
      //   },
      //   {
      //     title: 'Merchant',
      //     desc: 'PROFIT!',
      //     img: '',
      //   },
      //   {
      //     title: 'Explorer',
      //     desc: 'Discovering new species!',
      //     img: '',
      //   },
      //   {
      //     title: 'Miner',
      //     desc: 'We need to go deeper!',
      //     img: '',
      //   },
      // ],
      // optionLamination: [],
      detail: {
        paper: '',
        // cornner: '',
        // lamination: '',
        quantity: 1,
        display_quantity: 100,
      },
      companies: {},
      tempData: {},
      optionsQty: [
        { text: 100, value: 1 },
        { text: 200, value: 2 },
        { text: 300, value: 3 },
        { text: 400, value: 4 },
        { text: 500, value: 5 },
        { text: 600, value: 6 },
        { text: 700, value: 7 },
        { text: 800, value: 8 },
        { text: 900, value: 9 },
      ],
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType']),
    // ...mapActions('cart', ['setCartCountPrinting']),
    isUsePrintingMenu() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 3).length > 0 &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
  },
  created() {
    // split detail front - back
    // this.printing.frontDetail = this.printing.text_object.filter(
    //   (e) => e.is_front_page === true
    // )
    // this.printing.backDetail = this.printing.text_object.filter(
    //   (e) => e.is_front_page === false
    // )
    // set edit default
  },
  async mounted() {
    // this.onGetEdit()
    console.log('this.edit', this.edit)
    console.log('this.$route.params.id', this.$route.params.id)
    await this.getCompaniesById()
  },
  methods: {
    async getCompaniesById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.companies = result.data
              this.onGetDetail()
              this.onGetPaperPrice()
            }
          },
          (error) => {
            console.log('getCompaniesById', error.responsed)
          }
        )
    },
    async onGetDetail() {
      let response = await this.$bizCardProvider.getCompaniesTemplatesById(
        this.companies.id,
        this.$route.params.id
      )
      if (response.status == 200) {
        console.log(response.data)
        let printObj = response.data
        printObj.text_object.forEach((e) => {
          e.isEdit = false
          e.isWarning = false
        })
        this.tempData = JSON.parse(JSON.stringify(printObj))
        this.printing = JSON.parse(JSON.stringify(printObj))
        this.printing.template_id = this.$route.params.id
        this.edit = JSON.parse(JSON.stringify(printObj))
        console.log('this.printing', this.printing)
        console.log('this.edit', this.edit)
        this.isSkeleton = false
        // this.templateTitle = 'ชื่อนามบัตร : ' + this.detail.template_name.th
        // this.frontInner.height = this.$refs['front'].clientHeight
        // this.frontInner.width = this.$refs['front'].clientWidth
        // if (this.printing.is_use_back_side) {
        //   this.backInner.height = this.$refs['back'].clientHeight
        //   this.backInner.width = this.$refs['back'].clientWidth
        // }
      } else {
        this.isSkeleton = false
        this.isNotFound = true
      }
    },
    async onGetPaperPrice() {
      this.option_paper = []
      let params = new URLSearchParams()
      // if (this.input.company_code !== "")
      params.append('company_code', this.companies.company_code.toUpperCase())
      let response = await this.$bizCardProvider.getPrintingPrices(params)
      console.log('onGetPaperPrice', response)
      if (response.status === 200) {
        response.data.items.forEach((e) => {
          this.option_paper.push({
            text: {
              th: e.product_name.th,
              en: e.product_name.en,
            },
            value: e._id,
          })
        })
      }
    },
    onResetData() {
      this.printing = JSON.parse(JSON.stringify(this.tempData))
      this.edit = JSON.parse(JSON.stringify(this.tempData))
    },
    onSelectEdit(page, seq) {
      console.log('page-seq', page, seq)
    },
    generateStyle(obj) {
      let style = ''
      if (obj.size) style += 'font-size:' + obj.size + 'px;'
      if (obj.position_left >= 0) style += 'left:' + obj.position_left + 'px;'
      if (obj.position_top >= 0) style += 'top:' + obj.position_top + 'px;'
      if (obj.position_right >= 0)
        style += 'right:' + obj.position_right + 'px;'
      if (obj.color) style += 'color:' + obj.color + ';'
      if (obj.family) style += 'font-family:' + obj.family + ';'
      if (obj.bold) style += 'font-weight:bold;'
      if (obj.underline) style += 'text-decoration:underline;'
      if (obj.italic) style += 'font-style: italic;'

      return style
    },
    customLabel({ title, desc }) {
      return `${title} – ${desc}`
    },
    // onGetEdit() {
    //   const data = JSON.parse(JSON.stringify(this.printing))
    //   this.edit = data
    // },
    onCancelInput(page, sequence) {
      const index = this.printing.text_object.findIndex(
        (e) => e.sequence === sequence
      )

      this.edit.text_object[index].default_text = JSON.parse(
        JSON.stringify(this.printing.text_object[index].default_text)
      )
      this.printing.text_object[index].isEdit = false
    },
    onChangeInput(page, sequence) {
      console.log('text_object', this.printing.text_object)
      console.log('text_object', this.edit.text_object)
      const index = this.printing.text_object.findIndex(
        (e) => e.sequence === sequence
      )

      this.printing.text_object[index].default_text = JSON.parse(
        JSON.stringify(this.edit.text_object[index].default_text)
      )
      this.printing.text_object[index].isEdit = false
    },
    // async onPdf(num) {
    //   this.printing.text_object.map((key) => {
    //     key.isEdit = false
    //   })
    //   // this.printing.backDetail.map((key) => {
    //   //   key.isEdit = false
    //   // })
    //   this.sampleLoading = true
    //   await this.$refs['print-modal'].show()

    //   let n = 0
    //   const el = document.getElementById('capture')
    //   const sample = document.querySelectorAll('.print-sample')[0]
    //   const this_ = this
    //   while (n < num) {
    //     html2canvas(el, {
    //       // dpi: 300,
    //       // scale: 3,
    //       letterRendering: 1,
    //       allowTaint: true,
    //     }).then((canvas) => {
    //       // console.log('canvas', canvas)
    //       sample.appendChild(canvas)

    //       // const anchorTag = document.createElement('a')
    //       // document.body.appendChild(anchorTag)
    //       // anchorTag.download = 'filename.png'
    //       // anchorTag.href = canvas.toDataURL()
    //       // anchorTag.target = '_blank'
    //       // anchorTag.click()

    //       // const imgData = canvas.toDataURL('image/png')
    //       // const doc = new jsPDF('p', 'mm')
    //       // doc.addImage(imgData, 'PNG', 10, 10)
    //       // doc.save('sample-file.pdf')
    //       if (sample.childNodes.length === num) {
    //         this_.sampleLoading = false
    //         console.log('complete', sample.childNodes.length)
    //       }
    //     })

    //     n++
    //   }
    // },
    // async onSample(num) {
    //   this.sampleLoading = true
    //   await this.$refs['print-modal'].show()
    //   this.sampleLoading = false
    // },
    // onPrint() {
    //   // const el = document.getElementById('capture')
    //   const el = document.querySelectorAll('.print-sample')[0]

    //   html2canvas(el, {
    //     // dpi: 300,
    //     // scale: 3,
    //     letterRendering: 1,
    //     allowTaint: true,
    //   }).then((canvas) => {
    //     const anchorTag = document.createElement('a')
    //     document.body.appendChild(anchorTag)
    //     anchorTag.download = 'filename.png'
    //     anchorTag.href = canvas.toDataURL()
    //     anchorTag.target = '_blank'
    //     anchorTag.click()

    //     // const imgData = canvas.toDataURL('image/png')
    //     // const doc = new jsPDF('p', 'mm')
    //     // doc.addImage(imgData, 'PNG', 10, 10)
    //     // doc.save('sample-file.pdf')
    //   })
    // },
    onValidate() {
      this.$refs.addToCard.validate().then(async (result) => {
        if (result) {
          console.log('onSave')
          if (this.printing.text_object.some((q) => q.isEdit)) {
            this.$bvModal
              .msgBoxOk(this.$t('bizcard.modal.warning'), {
                size: 'sm',
                buttonSize: 'sm',
                hideHeader: true,
                contentClass: 'pt-4 text-center fs-14',
                okVariant: 'cancel px-3',
                okTitle: this.$t('bizcard.create-modal.cancel-btn'),
                footerClass: 'pt-2 border-top-0 justify-content-center',
                centered: true,
              })
              .then((value) => {
                this.printing.text_object.forEach((item) => {
                  if (item.isEdit) {
                    item.isWarning = true
                  }
                })
              })
          } else {
            await this.addShoppingCarts()
          }
        }
      })
    },
    async addShoppingCarts() {
      let payload = { ...this.printing, ...this.detail }
      // mapgetter
      payload.user_open_id = this.openId
      payload.account_id = this.userProfile.id
      payload.account_channel = this.profileType

      payload.paper_id = payload.paper.value
      payload.paper_name_th = payload.paper.text.th
      payload.paper_name_en = payload.paper.text.en
      // payload.quantity = payload.display_quantity / 100
      console.log('payload', payload)

      let response = await this.$bizCardProvider.addShoppingCarts(payload)
      if (response.status === 201) {
        // set cart count
        let params = new URLSearchParams()
        params.append('company_id', this.userProfile.id)
        const key = `${this.openId}-${this.userProfile.id}`
        const result = await this.$bizCardProvider.getCartCount(key, params)
        console.log('getPrintingCartsCount', result)
        if (result.status === 200) {
          this.$store.dispatch(
            'cart/setCartCountPrinting',
            result.data.cart_total
          )
        }

        // return modal
        this.$bvModal
          .msgBoxOk(this.$t('bizcard.modal.success'), {
            size: 'sm',
            buttonSize: 'sm',
            hideHeader: true,
            contentClass: 'pt-4 text-center fs-16',
            okVariant: 'on px-3',
            okTitle: this.$t('bizcard.modal.back'),
            footerClass: 'pt-2 border-top-0 justify-content-center',
            centered: true,
          })
          .then((value) => {
            this.$router.push({
              path: this.$i18n.path('company/printing/cart'),
            })
          })
      }
    },
    onCancelCreate() {
      this.$router.push({
        path: this.$i18n.path('company/printing/template'),
      })
    },
  },
}
