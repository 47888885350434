
export default {
  name: 'Consent',
  data() {
    return {
      consentStatus: false,
    }
  },
  watch: {
    consentStatus: {
      async handler(val) {
        this.$emit('consentStatus', val)
        console.log('consentxxx', val)
      },
    },
  },
  //   methods: {
  //     onSelected() {
  //       this.$emit('consentStatus', this.consentStatus)
  //     },
  //   },
}
