
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: 'main',
  name: 'AccountDetail',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    // 'b-table': bTable,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isEdit: false,
      isChangePassword: false,
      isChangePasswordComplete: false,
      isChangePasswordFail: false,
      reloadPassword: true,
      dataForNewPassword: {
        oldPassword: '',
        password: '',
        rePassword: '',
      },
      isName: false,
      isSurname: false,
      isNameEN: false,
      isSurnameEN: false,
      isPhone: false,
      isMobile: false,
      isExtension: false,
      phone: {
        type: 'Phone',
        value: '',
        extension: '',
      },
      mobile: {
        type: 'Mobile',
        value: '',
        extension: '',
      },
      account: {
        offlineId: '',
        firstName: {
          th: '',
          en: '',
        },
        lastName: {
          th: '',
          en: '',
        },
        type: '',
        email: '',
        profileImage: '',
        // phones: '',
        phones: [],
        lastUse: '',
        t1cCardNo: '',
        companyId: '',
        companyName: {
          th: '',
          en: '',
        },
        isType: true,
        default_lang: '',
        last_active: '',
        phone: {
          type: 'Phone',
          value: '',
          extension: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
          extension: '',
        },
        ref_affiliate_code: '',
        segmentType: '',
      },
      edit: {
        isThe1Card: true,
        firstName: {
          th: '',
          en: '',
        },
        lastName: {
          th: '',
          en: '',
        },
        user_open_id: '',
        t1cCardNo: '',
        phone: {
          type: 'Phone',
          value: '',
          extension: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
          extension: '',
        },
        companyName: {
          th: '',
          en: '',
        },
        default_language: '',
        is_user_active: false,
        ref_affiliate_code: '',
      },
      fields: [
        {
          key: 'account_id',
          class: 'text-center',
          label: this.$i18n.t(`account-id.customerId`),
          thStyle: 'min-width: 110px',
          sortable: true,
        },
        {
          key: 'code',
          label: this.$i18n.t(`account-id.deptId`),
          class: 'text-center',
          thStyle: 'min-width: 160px',
          sortable: true,
        },
        {
          key: 'cost_center_name.th',
          label: this.$i18n.t(`account-id.deptName`),
          class: 'text-center',
          thStyle: 'min-width: 160px',
          sortable: true,
        },
        {
          key: 'role_name.th',
          label: this.$i18n.t(`account-id.role`),
          class: 'text-center',
          thStyle: 'min-width: 140px',
          sortable: true,
        },
        {
          key: 'last_active',
          label: this.$i18n.t(`account-id.startDate`),
          class: 'text-center',
          thStyle: 'min-width: 165px',
          sortable: true,
        },
      ],
      items: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      tempPhones: [
        {
          type: 'Phone',
          value: '',
          extension: '',
        },
        {
          type: 'Mobile',
          value: '',
          extension: '',
        },
      ],
      default_language: '',
      iserror: false,
      isThe1Flag: false,
      msgThe1: '',
    }
  },

  computed: {
    ...mapGetters('authen', ['openId', 'accessToken']),
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('user', ['userProfile', 'profileType']),
    confirmation() {
      return this.dataForNewPassword.password !==
        this.dataForNewPassword.rePassword
        ? true
        : false
    },
    testMinMAx(val) {
      console.log('testMinMAx : ', val)
      return true
    },
    requiredIsPhone() {
      return this.edit.phone.value === '' && this.edit.mobile.value === ''
    },
  },
  watch: {
    'edit.phone.value': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'edit.phone.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
    'edit.t1cCardNo': function (val) {
      console.log('edit.t1cCardNo', val)
      if (val != '') {
        if (val.length < 10) {
          this.isThe1Flag = true
          this.msgThe1 = 'เลข The1 card ต้องมีความยาวอย่างน้อย 10 ตัวอักษร'
        } else {
          this.msgThe1 = ''
        }
      } else {
        this.isThe1Flag = false
      }
    },
  },
  mounted() {
    if (this.profileType === 'standard') {
      this.getAccountById()
    } else if (this.profileType === 'eprocurement') {
      this.getUserCompanyRequesterlineById()
    }
  },
  methods: {
    ...mapActions('user', ['setUserProfileImage', 'setUserInfoName']),
    onChangeThe1({ target: { e } }) {
      console.log(e)
    },
    validateupdateUserProfileSubmit() {
      this.$refs.profileForm.validate().then((result) => {
        if (result) {
          if (
            this.edit.phone.value === '' &&
            this.edit.phone.extension !== ''
          ) {
            this.isPhone = true
          } else {
            this.updateUserProfile()
            this.isPhone = false
          }
        }
      })
    },
    validateupdateUserCompaniesSubmit() {
      this.$refs.profileForm.validate().then((result) => {
        if (result) {
          if (
            this.edit.phone.value === '' &&
            this.edit.phone.extension !== ''
          ) {
            this.isPhone = true
          } else {
            this.updateUserCompanies()
            this.isPhone = false
          }
        }
      })
    },
    onChangeLanguage(lang) {
      this.default_language = lang.toLowerCase()
    },
    browseFile() {
      this.$refs['browse-file'].click()
    },
    async selected({ target: { files } }) {
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              console.log('valid')
              console.log('file', files[0])
              // this.importFile(files[0])
              await this.uploadFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async uploadFile(file) {
      this.iserror = false
      // let newname = file.name.toLowerCase()
      let formData = new FormData()
      // formData.append('bucket', 'dev-epro')
      // formData.append('path', 'personal-document/profile/' + this.openId)
      // formData.append('key', newname)
      // formData.append('file', file)
      // formData.append('is_upsert', 'true')
      // formData.append('content_description', 'upload file')
      // const config = {
      //   headers: { 'content-type': 'multipart/form-data' },
      // }

      formData.append('id', this.openId)
      formData.append('file', file)
      formData.append('prefix_path', 'personal-document')
      formData.append('content_name', 'profile')
      formData.append('is_spacial', true)
      await this.$profileProvider.uploadContentImage(formData).then(
        (result) => {
          console.log(result)
          if (result.status === 201) {
            console.log('url', result.data.url)
            if (result.data.url !== null) {
              let url = JSON.stringify(result.data.url)
              this.UpdateProfileImageUrl(result.data.url)
              this.setUserProfileImage(result.data.url)
            } else {
              this.iserror = true
            }
          }
        },
        (error) => {
          console.log('uploadFile', error.responsed)
        }
      )
    },
    countDownChanged(dismissCountDown) {
      this.isChangePasswordComplete = dismissCountDown
    },
    countDownChangedFail(dismissCountDown) {
      this.isChangePasswordFail = dismissCountDown
    },
    showCompleteBox() {
      this.isChangePasswordComplete = this.dismissSecs
    },
    showFailBox() {
      this.isChangePasswordFail = this.dismissSecs
    },
    resetForm() {
      this.dataForNewPassword.oldPassword = ''
      this.dataForNewPassword.password = ''
      this.dataForNewPassword.rePassword = ''
      this.reloadPassword = false
      this.$nextTick(() => {
        this.reloadPassword = true
      })
      this.isChangePassword = false
    },
    async onSubmitPassword() {
      this.$refs.passwordVerifyForm.validate().then(async (result) => {
        if (result) {
          // await console.log(this.dataForNewPassword)
          await this.setNewPassword()
        }
      })
    },
    async getAccountById() {
      await this.$profileProvider
        .getAccountMasterById(this.userProfile.id)
        .then(
          (result) => {
            console.log('result', result)
            if (result.status === 200) {
              let data = result.data
              console.log('getAccountMasterById', data)
              //this.edit.t1cCardNo = data.t1cCardNo
              console.log('data.segment', data.segment)
              this.account = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                offlineId: data.offlineId,
                phones: data.phones.sort().reverse(),
                t1cCardNo: data.t1cCardNo,
                type: 'account',
                isType:
                  data.payment.code === 'Cash' && data.type === 'Personal'
                    ? true
                    : false,
                profileImage: data.profile_image_url,
                default_language: data.default_language.toUpperCase(),
                last_active: data.last_active,
                mobile:
                  data.phones.find((a) => a.type === 'Mobile') ?? this.mobile,
                phone:
                  data.phones.find((a) => a.type === 'Phone') ?? this.phone,
                ref_affiliate_code: data.ref_affiliate_code,
                segmentType: data.segment,
              }
              if (data.phones === null) {
                this.account.phones = this.tempPhones
              }
              if (this.account.phones.length == 1) {
                this.account.phones.push({
                  type: this.account.phones.find(
                    (a) => a.type === 'Phone' && a.value !== ''
                  )
                    ? 'Mobile'
                    : 'Phone',
                  value: '',
                  extension: '',
                })
              }
              this.setUserInfoName(this.account)
              console.log('phones', this.account)
            }
          },
          (error) => {
            console.log('getAccountById', error.responsed)
          }
        )
    },
    showToast(message) {
      const id = `error-toast-${this.toast++}`

      const Msg = this.$createElement('div', { class: ['mr-auto'] }, [message])
      const CloseToast = this.$createElement('b-icon-x', {
        class: ['ml-auto'],
        on: { click: () => this.$bvToast.hide(id) },
      })

      this.$bvToast.toast([Msg, CloseToast], {
        id: id,
        toaster: 'b-toaster-top-right error-toast',
        noCloseButton: true,
      })
    },
    async updateUserProfile() {
      console.log('xxxxxxxxxxxx', this.edit.t1cCardNo)
      this.edit.user_open_id = this.openId
      this.edit.default_language =
        this.default_language === undefined || this.default_language === ''
          ? this.account.default_language.toLowerCase()
          : this.default_language.toLowerCase()

      await this.$profileProvider
        .updateUserProfileMaster(this.userProfile.id, this.edit)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            console.log(result)
            if (result.status === 204) {
              this.getAccountById()
              this.isEdit = false
            } else {
              if (this.$utils.isObjectValid(result.data.message)) {
                this.showToast(result.data.message[this.$i18n.locale])
              }
            }
          },
          (error) => {
            console.log('updateUserProfile', error.responsed)
          }
        )
    },
    async updateUserCompanies() {
      this.edit.is_user_active = true
      this.edit.default_language =
        this.default_language === undefined || this.default_language === ''
          ? this.account.default_language.toLowerCase()
          : this.default_language.toLowerCase()

      await this.$profileProvider
        .updateMeCompaniesMaster(this.openId, this.userProfile.id, this.edit)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 204) {
              this.getUserCompanyRequesterlineById()
              this.isEdit = false
            }
          },
          (error) => {
            console.log('updateUserCompanies', error.responsed)
          }
        )
    },
    async getUserCompanyRequesterlineById() {
      await this.$profileProvider
        .getUserCompanyRequesterlineDataById(this.openId, this.userProfile.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('account', data)
              this.account = {
                companyId: data.company_id,
                companyName: data.company_name,
                firstName: data.first_name,
                lastName: data.last_name,
                email: data.email,
                profileImage: data.profile_image,
                phones: data.phones,
                default_language: data.default_language?.toUpperCase(),
                last_active: data.last_active,
                mobile:
                  data.phones.find((a) => a.type === 'Mobile') ?? this.mobile,
                phone:
                  data.phones.find((a) => a.type === 'Phone') ?? this.phone,
                segmentType: data.segment,
              }
              console.log('this.account :>> ', this.account)
              if (data.phones === null) {
                this.account.phones = this.tempPhones
              }
              if (this.account.phones.length == 1) {
                this.account.phones.push({
                  type: this.account.phones.find(
                    (a) => a.type === 'Phone' && a.value !== ''
                  )
                    ? 'Mobile'
                    : 'Phone',
                  value: '',
                  extension: '',
                })
              }
              this.items = data.roles
              console.log(data)
              this.setUserInfoName(this.account)
              // , { img: this.account.profileImage , name: this.account.profileImage}
            }
          },
          (error) => {
            console.log('getUserCompanyRequesterlineById', error.responsed)
          }
        )
    },
    async setNewPassword() {
      const oldPassword = this.$utils.cryptoJS(
        this.dataForNewPassword.oldPassword
      )

      const password = this.$utils.cryptoJS(this.dataForNewPassword.password)
      let apiData = JSON.stringify({
        open_id: this.openId,
        old_password: oldPassword,
        new_password: password,
      })
      //console.log('oldPassword : ', apiData)
      const result = await this.$profileProvider.updatePassword(apiData)
      if (result.status === 204 || result.status === 201) {
        this.isChangePasswordFail = false
        this.isChangePasswordComplete = true
        this.resetForm()
        this.showCompleteBox()
      } else {
        this.isChangePasswordFail = true
        this.isChangePasswordComplete = false
        this.showFailBox()
      }
    },
    onRoute() {
      this.$router.push({
        path: this.$i18n.path('account'),
      })
    },
    async UpdateProfileImageUrl(url) {
      await this.$profileProvider
        .UpdateProfileImageUrlData(this.openId, {
          Base64Url: btoa(unescape(encodeURIComponent(url))),
        })
        .then(
          (result) => {
            if (result.status === 204) {
              console.log('6666', this.profileType)
              if (this.profileType !== 'eprocurement') {
                this.getAccountById()
                console.log('5555')
              } else {
                this.getUserCompanyRequesterlineById()
              }
            }
          },
          (error) => {
            console.log('UpdateProfileImageUrl', error.responsed)
          }
        )
    },
    onResetData() {
      this.edit.mobile.value = this.account.mobile.value
      this.edit.phone.value = this.account.phone.value
      this.edit.phone.extension = this.phone.extension
    },
  },
}
