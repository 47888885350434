
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller-algolia.vue'
import SwiperItem from '@/components/share/swiper-item.vue'
import ProductResult from '@/components/template/product-result.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'

export default {
  name: 'SearchKeyword',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    'swiper-item': SwiperItem,
    'skeleton-result': SekletonResult,
  },
  head() {
    return {
      // title: this.pageTitle,
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: this.pageContent,
      //   },
      //   {
      //     hid: 'og:type',
      //     property: 'og:type',
      //     content: 'website',
      //   },
      //   {
      //     hid: 'og:title',
      //     property: 'og:title',
      //     content: this.pageTitle,
      //   },
      //   {
      //     hid: 'og:description',
      //     property: 'og:description',
      //     content: this.pageContent,
      //   },
      //   {
      //     hid: 'og:image',
      //     property: 'og:image',
      //     content: this.ogImage,
      //   },
      // ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`search/${this.$route.params.keyword}`),
      //   },
      // ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'searchresults',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('search_keyword.breadcrumbItemsText'),
          href: '/',
        },
        {
          text: this.$nuxt._route.params.keyword,
          href: '',
        },
      ],
      sortBy: [
        {
          text: this.$t('search_keyword.dropdownBestSeller'),
          value: '0',
        },
        {
          text: this.$t('search_keyword.dropdownPromotion'),
          value: '1',
        },
        {
          text: this.$t('search_keyword.dropdownNewArrival'),
          value: '2',
        },
        {
          text: this.$t('search_keyword.dropdownRating'),
          value: '3',
        },
        {
          text: this.$t('search_keyword.dropdownPriceHighLow'),
          value: '4',
        },
        {
          text: this.$t('search_keyword.dropdownPriceLowHigh'),
          value: '5',
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: null,
      lang: '',
      attributes: [],
      priceDefault: [200, 9000],
      paramAttribute: { attribute: [], price: [] },
      itemsRecentlyViewedProducts: [],
      isNotFound: false,
      images: [],
      isGetProduct: false,
      queryIdEventAlgolia: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.SEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeletonPage: true,
      showItems: false,
      firstTime: true,
      overlayImages: [],
      overlayImagesItemsRecent: [],
      shopInfo: [],
      sharkUrl: process.env.NUXT_ENV_SHOP_IN_SHOP_ASSETS,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId', 'isAuthenticated', 'isGuest']),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
    headerTitleAny() {
      return (
        this.$route.params.keyword !== null &&
        this.items !== null &&
        this.items.length > 0
      )
    },
    headerTitleNotfound() {
      return (
        this.$route.params.keyword !== null &&
        this.items !== null &&
        this.items.length === 0
      )
    },
    isEprocurementStandard() {
      return this.profileType === 'eprocurement' &&
        this.userProfile.categories_type !== 'Standard'
        ? true
        : false
    },
  },
  watch: {},
  created() {
    this.lang = this.$store.state.locale
    // console.log('first')
  },
  updated() {
    this.skeletonPage = false
    this.$_onGetInsider({
      page: {
        type: 'Search',
        custom: {
          category_name: null,
        },
      },
    })
    this.$_onInsiderResult({
      result: [...(this.items || [])],
      lists: [...(this.itemsRecentlyViewedProducts || [])],
    })
  },
  async mounted() {
    this.sort = 'products'
    // await this.getProduct()
    this.showItems = false
    let url = new URL(window.location.href).searchParams.toString().length
    this.firstTime = url === 0

    // if (this.firstTime) {
    await this.getProduct()
    // this.showItems = true
    // }
    this.getฺBannerHome()
    // this.attributes = await Object.assign([], this.attribute)
    this.$nextTick(() => {
      if (this.$utils.anyArrayObjectValid(this.attribute)) {
        this.attributes = Object.assign([], this.attribute)
      } else {
        this.showItems = true
      }
    })
    console.log('this.attributes', this.attributes)
    console.log('check_attribute', this.attribute)
    if (this.attributes.length <= 0 && this.items <= 0) this.isNotFound = true
    if (this.isUser && this.items <= 0) this.getUserProductHistory()
  },
  methods: {
    async onPagination(page) {
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    /* func assign ข้อมูลหลังจากเรียกไดรับ returnจาก default.vue */
    /* func เรียก api เพื่อข้อมูลแสดงข้อมูลสิ้นค้า  */
    async getProduct() {
      this.isGetProduct = false
      this.showItems = false
      this.items = []
      let attr = []
      let bodyBrands = []
      let bodyCategoriesLevel1s = []
      let bodyAttributes = []
      let params = await this.createParams()
      console.log('param', params.toString())
      if (this.$utils.anyArrayObjectValid(this.paramAttribute.attribute)) {
        this.paramAttribute.attribute.forEach((data) => {
          console.log('brand', data)

          data.by === 'brand'
            ? bodyBrands.push(data.value)
            : data.by === 'category'
            ? bodyCategoriesLevel1s.push(data.value)
            : bodyAttributes.push(data.id + '-' + data.value)
        })
      }
      let apiData = JSON.stringify({
        filterBrands: bodyBrands,
        filterCategoriesLevel1s: bodyCategoriesLevel1s,
        filterAttributes: bodyAttributes,
      })
      console.log('apiData', apiData)
      const response = await this.$productProvider.productsSearchEngine(
        params,
        apiData
      )
      // console.log('params', params.toString())
      // console.log('apiData', apiData)

      if (response.status === 201) {
        console.log('response', response)
        // await this.getAttribute()
        this.showItems = this.firstTime
        this.pageTotal = response.data.items.totalRecords
        console.log('response.data.items.items', response.data.items)
        // this.items =
        //   response.data.items.items === null ? [] : response.data.items.items
        if (
          this.$utils.isObjectValid(response.data.items) &&
          this.$utils.anyArrayObjectValid(response.data.items.items)
        ) {
          //  || !isFirst
          this.isNotFound = false
          this.items = this.$utils.anyArrayObjectValid(
            response.data.items.items
          )
            ? response.data.items.items
            : []
          // get overlay
          if (this.items.length) {
            let pid = this.items.map((item) => {
              return item.skus[0].sku_code
            })
            const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
              pid
            )
            console.log('graphqlResponse', graphqlResponse)
            if (graphqlResponse) {
              this.overlayImages = graphqlResponse
            }
          }
        }
        //  else {
        //   this.items = []
        //   this.isNotFound = true
        // }

        if (this.$utils.anyArrayObjectValid(this.items)) {
          // ทำ seq เพื่อส่งเข้า gtm event impression

          let startIndex =
            response.data.items.pageNo * response.data.items.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })
          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.items.map((item) => {
                return {
                  id: this.$utils.anyArrayObjectValid(item.skus)
                    ? item.skus[0].sku_code
                    : '',
                  price: this.$utils.formatPrice(
                    this.$utils.anyArrayObjectValid(item.skus)
                      ? item.skus[0].price_include_vat
                      : 0,
                    2,
                    true
                  ),
                  list: 'Search Results',
                  position: item.seq,
                }
              }),
            },
          })
        }

        let facets = response.data.facets
        // console.log('facets', facets)
        if (facets != null && facets.length > 0) {
          //  && isFirst
          // this.attributes = []
          /* categories_level1 */
          let categories_level1 = facets.find(
            (q) => q.display_name === 'categories_level1.display_name'
          )

          if (categories_level1 != null) {
            let categories_level1_list = []

            for (const [index, element] of categories_level1.values.entries()) {
              console.log('element', element)
              categories_level1_list.push({
                text: element.key[this.lang],
                value: { by: 'category', index: index, value: element.id },
                // value: {
                //   // by: 'categories_level1',
                //   by: 'category',
                //   value: `${element.key['th']}__${element.key['en']}`,
                //   id: element.id,
                //   index: index,
                // },
                // value: { by: 'categories_level1', value: categories_level1.values[i].value },
              })
            }

            attr.push({
              title: this.$t('brand_keyword.labelTitleCategoryAttribute'),
              list: categories_level1_list,
            })
          }
          /* brand */
          let brand = facets.find(
            (q) => q.display_name === 'brand.display_name'
          )

          if (brand != null) {
            // console.log('brand', brand)
            let brandList = []

            for (const element of brand.values) {
              brandList.push({
                text: element.key[this.lang],
                value: {
                  by: 'brand',
                  // value: `${element.key['th']}__${element.key['en']}`,
                  value: element.id,
                },
                // value: { by: 'brand', value: brand.values[i].value },
              })
            }

            attr.push({
              title: this.$t('search_keyword.labelTitleBrandAttribute'),
              list: brandList,
            })
          }
          /* attributes */
          let attributes = facets.find(
            (q) => q.display_name === 'attributes.display_name'
          )
          // console.log('attributes', attributes)
          if (attributes != null) {
            for (const element of attributes.values) {
              let attributeList = []
              if (this.$utils.anyArrayObjectValid(element.value)) {
                element.value.forEach((value, index) => {
                  if (
                    this.$utils.isObjectValid(value) &&
                    this.$utils.isValid(value[this.lang]) &&
                    !attributeList.some(
                      (q) => q.value.value === value[this.lang]
                    )
                  ) {
                    attributeList.push({
                      text: value[this.lang],
                      value: {
                        index: index,
                        by: element.display_name[this.lang],
                        id: element.id,
                        value: value[this.lang],
                      },
                    })
                  }
                })
              }
              if (this.$utils.anyArrayObjectValid(attributeList)) {
                attr.push({
                  title: element.display_name[this.lang],
                  list: attributeList,
                })
              }
            }
          }
          // /* attributes */
          // let attributes = facets.find((q) => q.display_name === 'attributes')
          // let facetItem = {}
          // if (attributes != null) {
          //   for (const [index, element] of attributes.values.entries()) {
          //     if (
          //       !this.$utils.isObjectValid(element) ||
          //       !this.$utils.isObjectValid(element.key[this.lang])
          //     ) {
          //       continue
          //     }

          //     facetItem = {
          //       th: element.key.th.split('__'),
          //       en: element.key.en.split('__'),
          //     }
          //     if (
          //       this.$utils.anyArrayObjectValid(facetItem.th) &&
          //       this.$utils.anyArrayObjectValid(facetItem.en)
          //     ) {
          //       if (
          //         !this.$utils.isValid(facetItem[this.lang][0]) ||
          //         !this.$utils.isValid(facetItem[this.lang][1])
          //       ) {
          //         continue
          //       }
          //       // console.log('facetItem2', facetItem)
          //       if (
          //         this.$utils.anyArrayObjectValid(attr) &&
          //         attr.some((q) => q.title === facetItem[this.lang][0])
          //       ) {
          //         let facetItemValue = attr.find(
          //           (q) => q.title === facetItem[this.lang][0]
          //         )
          //         console.log('facetItem2', facetItemValue)
          //         facetItemValue.list.push({
          //           text: facetItem[this.lang][1],
          //           value: {
          //             by: 'attributes',
          //             value: `${facetItem['th'][0]}__${facetItem['th'][1]}___${facetItem['en'][0]}__${facetItem['en'][1]}`,
          //           },
          //         })
          //       } else {
          //         attr.push({
          //           title: facetItem[this.lang][0],
          //           list: [
          //             {
          //               text: facetItem[this.lang][1],
          //               value: {
          //                 index: index,
          //                 id: '',
          //                 by: 'attributes',
          //                 value: `${facetItem['th'][0]}__${facetItem['th'][1]}___${facetItem['en'][0]}__${facetItem['en'][1]}`,
          //               },
          //             },
          //           ],
          //         })
          //       }
          //     }
          //   }
          // }

          /* price */
          let price = facets.find(
            (q) => q.display_name === 'price_default.include_vat'
          )
          if (price != null) {
            // console.log(price)
            this.priceDefault = price.values[0].value
          }
        }

        if (this.$utils.isValid(response.data.query_id)) {
          this.queryIdEventAlgolia = response.data.query_id
          // console.log('this.queryIdEventAlgolia', this.queryIdEventAlgolia)
        }
        let shop = response.data.shops
        console.log('shops', shop)
        if (shop != null) {
          this.shopInfo = shop
        }
      }
      // if (this.items != null) {
      //   this.items = this.items.reduce((result, item) => {
      //     let rs = item.skus.find((f) => f.is_free)
      //     if (rs) {
      //       return result
      //     } else {
      //       return [...result, item]
      //     }
      //   }, [])
      // }
      console.log('pageTotal', response)
      this.attribute = attr
      this.isGetProduct = true
      this.skeletonPage = false
      // if (this.attribute.length <= 0) {
      //   this.isNotFound = true
      // }
    },
    // async getAttribute() {
    //   // console.log('getAttribute')
    //   let params = await this.createParams()
    //   /* api get ข้อมูลของ แบรนด์ของสินค้าที่มี */
    //   const response = await this.$productProvider.getBrand(params)
    //   this.attributes = []
    //   if (response.status === 200) {
    //     let brandList = []
    //     /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
    //     response.data.forEach((element) => {
    //       brandList.push({
    //         text: element.display_name[this.lang],
    //         value: { by: 'brand', value: element.id },
    //       })
    //     })

    //     this.attributes.push({
    //       title: 'Brand',
    //       list: brandList,
    //     })
    //   }
    //   /* api get ข้อมูลคุณสมบัติ ของสินค้าที่มี */
    //   const responseAttribute = await this.$productProvider.getAttribute(params)
    //   if (responseAttribute.status === 200) {
    //     const attribute = responseAttribute.data
    //     // console.log('attr', attribute)
    //     /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
    //     attribute.forEach((element) => {
    //       let attributeList = []
    //       element.value.forEach((value) => {
    //         attributeList.push({
    //           text: value[this.lang],
    //           value: {
    //             by: element.display_name[this.lang],
    //             id: element.id,
    //             value: value[this.lang],
    //           },
    //         })
    //       })
    //       this.attributes.push({
    //         title: element.display_name[this.lang],
    //         list: attributeList,
    //       })
    //     })
    //   }
    // },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('emitAttribute', this.firstTime)
      // await this.getProduct()
      // setTimeout(async () => {
      if (!this.firstTime) {
        await this.getProduct()
        this.showItems = true
      }
      this.firstTime = false
      // }, 100)
    },
    /* สร้าง params สำหรับเรียก api get product */
    async createParams() {
      // console.log('search page', this.page)
      let params = new URLSearchParams()
      if (
        this.$route.params.keyword !== undefined &&
        this.$route.params.keyword !== null &&
        this.$route.params.keyword !== ''
      ) {
        params.append('keyword', this.$route.params.keyword)
      }

      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)
      /* profileType จะเป็น standard กับ eprocurement */
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      /* category type exp. costcenter, company */
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      // params.append('page', this.page)
      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      // console.log(this.perPage)
      params.append('sort', this.sort)
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          if (data.by === 'brand') {
            params.append('filterBrands', data.value)
          } else if (data.by === 'category') {
            params.append('filterCategoriesLevel1s', data.value)
          } else {
            params.append('filterAttributes', data.id + '-' + data.value)
          }
        })
      }
      if (this.paramAttribute.price.length !== 0) {
        if (this.paramAttribute.price[0] !== 0)
          params.append('min', this.paramAttribute.price[0])
        if (this.paramAttribute.price[1] !== 0)
          params.append('max', this.paramAttribute.price[1])
      }
      params.append('isFree', false)
      return params
    },
    async selectSort(e) {
      this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
      if (e.value === '0') {
        this.sort = 'products'
      } else if (e.value === '1') {
        this.sort = 'product-promotion'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPROPROMOTION
      } else if (e.value === '2') {
        this.sort = 'product-new-arrival'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTNEWARRIVAL
      } else if (e.value === '3') {
        this.sort = 'product-rating'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTRATING
      } else if (e.value === '4') {
        this.sort = 'price-descending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
      } else if (e.value === '5') {
        this.sort = 'price-ascending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
      }
      console.log('search xxxxxxxxxxxxxx', e.value, this.sort)
      await this.getProduct()
      this.showItems = true
    },
    async getฺBannerHome() {
      let params = this.createProfileParams()
      await this.$profileProvider.getฺBannerHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.length > 0) {
              data.sort(function (a, b) {
                return a.sequence - b.sequence
              })
              data.forEach((e, index) => {
                this.images.push({
                  alt: e.sequence,
                  url: e.path_url,
                  path: e.image.url,
                  asset: false,
                  title: e.title,
                  gtmSeq: index + 1,
                  active_from: e.active_from,
                  active_to: e.active_to,
                })
              })
              // console.log('this.images', this.images)
              if (this.images.length) {
                this.$gtm.push({ ecommerce: null })
                this.$gtm.push({
                  event: 'promoViewed',
                  ecommerce: {
                    promoView: {
                      promotions: this.images.map((item) => {
                        return {
                          id: item.path,
                          name: `Search|${item.gtmSeq}|${
                            item.title
                          }|${this.$utils.formatDateGTM(
                            item.active_from
                          )}_${this.$utils.formatDateGTM(item.active_to)}`,
                          creative: 'Search_result',
                          position: item.gtmSeq,
                        }
                      }),
                    },
                  },
                })
              }
            }
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
    },
    createProfileParams() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)
      params.append('page_type', 'search')
      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type ?? '')
      }
      return params
    },
    onClickBanner(banner) {
      if (banner !== undefined) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoClicked',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  id: banner.path,
                  name: `Search|${banner.gtmSeq}|${
                    banner.title
                  }|${this.$utils.formatDateGTM(
                    banner.active_from
                  )}_${this.$utils.formatDateGTM(banner.active_to)}`,
                  creative: 'Search_result',
                  position: banner.gtmSeq,
                },
              ],
            },
          },
        })
        if (this.$utils.isValid(banner.url)) {
          this.$nextTick(() => {
            window.location.href = banner.url
          })
        }
      }
    },
    async getUserProductHistory() {
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )
      // console.log('user product history', result.data)
      this.itemsRecentlyViewedProducts = []
      if (result.status === 200 && result.data.length) {
        console.log('result.data', result.data)
        result.data.forEach((sku, index) => {
          if (this.$utils.isObjectValid(sku)) {
            this.itemsRecentlyViewedProducts.push({
              productId: sku.sku_code,
              productName: sku.product_names.find(
                (b) => b.type.toLowerCase() === 'online'
              ).display_name,

              // productImg: sku.images.find(
              //   (c) => c.size === 'thumbnail' && c.sequence === 1
              // ).url,
              productImg: this.imageNormalShowcase(sku),
              price_include_vat: sku.price_include_vat,
              retail_price: sku.retail_price.include_vat,
              is_promotion: sku.is_promotion,
              is_fixprice: sku.is_fixprice,
              is_hot_flash_sale: sku.is_hot_flash_sale,
              is_flash_sale: sku.is_flash_sale,
              is_online_only: sku.is_online_only,
              meta: sku.meta,
              seoMeta: sku.meta.seo_name,
              seq: index + 1,
              display_ratings: sku.display_ratings,
              display_ratings_count: sku.display_ratings_count,
              display_discount_rate: sku.display_discount_rate,
              promotion_prices: sku.promotion_prices,
              is_promotion_discount: sku.is_promotion_discount,
              update_info: sku.update_info,
              brand: sku.brand,
            })
          }
        })
        // overlay promotion
        if (this.itemsRecentlyViewedProducts.length) {
          let pid = this.itemsRecentlyViewedProducts.map((item) => {
            return item.productId
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesItemsRecent = graphqlResponse
          }
        }
        // gtm
        if (this.$utils.anyArrayObjectValid(this.itemsRecentlyViewedProducts)) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.itemsRecentlyViewedProducts.map((item) => {
                return {
                  id: item.sku_code,
                  price: this.$utils.formatPrice(
                    item.price_include_vat,
                    2,
                    true
                  ),
                  list: 'Recently Viewed Products',
                  position: item.seq,
                }
              }),
            },
          })
        }
      }
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return (
          sku.images.some((q) => q.size === 'thumbnail')
            ? sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'thumbnail').url
            : this.imageTemp
        ).replace('/thumbnail', '/original')
      } else {
        return this.imageTemp.replace('/thumbnail', '/original')
      }
    },

    replaceDisplaySearch(index) {
      let msg = this.$t('search_keyword.labelNotMatchEprocurement')
      return msg.split('{keyword}')[index]
    },
    onUpdateQuery(query) {
      console.log('onUpdateQuery', query)
      this.$router.push({
        path: `/search-shop/${this.keyword}`,
        query: query,
      })
    },
  },
}
