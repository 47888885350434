
export default {
  data() {
    return {
      modalShow: false,
      message: '',
      exceptList: ['duplicate user'],
    }
  },
  created() {
    if (process.client) {
      this.$bus.$on('preconditionFailed', (error) => {
        console.log('preconditionFailed', error, this.$i18n.locale)

        if (
          this.$utils.isObjectValid(error) &&
          this.$utils.isObjectValid(error.data) &&
          (this.$utils.isObjectKeyValid(error.data, 'message') ||
            this.$utils.isObjectKeyValid(error.data, 'Message'))
        ) {
          let errorMessage = this.$utils.isObjectKeyValid(error.data, 'message')
            ? error.data.message
            : error.data.Message
          if (!this.exceptList.includes(errorMessage)) {
            if (this.$utils.isObjectKeyValid(errorMessage, this.$i18n.locale))
              this.message = errorMessage[this.$i18n.locale]
            else this.message = errorMessage

            this.modalShow = true
          }
        } else {
          // this.message = 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่'
          // this.modalShow = true
        }
      })
    }
  },
  beforeDestroy() {
    if (process.client) {
      this.$bus.$off('preconditionFailed')
    }
  },
}
