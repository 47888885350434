
import { HorizontalBar } from 'vue-chartjs'
// ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: HorizontalBar,
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    selectedLegend: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
    scaleLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  watch: {
    series: {
      handler: function (val) {
        if (val.length) {
          this.$nextTick(() => {
            this.renderChart(this.generateChart(), {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  color: '#ffffff',
                  textAlign: 'center',
                  font: {
                    size: 12,
                    family: 'Kanit',
                  },
                },
              },
              datalabels: {
                display: false,
              },
              legend: {
                display: false,
              },
              legendCallback: function (chart) {
                return chart.data.datasets
              },
              tooltips: {
                enabled: false,
                mode: 'index',
                // position: 'nearest',
                custom: this.customTooltips,
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                    gridLines: {
                      display: true,
                    },
                    fill: true,
                    ticks: {
                      maxTicksLimit: 4,
                      fontFamily: 'Kanit',
                      fontStyle: 'normal',
                      fontSize: 11,
                      fontColor: '#999999',
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    fill: true,
                    ticks: {
                      // maxTicksLimit: 20,
                      fontFamily: 'Kanit',
                      fontStyle: 'normal',
                      fontSize: 11,
                      fontColor: '#999999',
                    },
                    scaleLabel: {
                      display: this.scaleLabel ? true : false,
                      labelString: this.scaleLabel,
                      fontFamily: 'Kanit',
                      fontStyle: 'normal',
                      fontSize: 14,
                      fontColor: '#999999',
                    },
                  },
                ],
              },
            })
            const legendCallback = this.$data._chart.generateLegend()
            this.$emit('generated', legendCallback)
          })
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    customTooltips(tooltip) {
      // Tooltip Element
      let tooltipEl = document.getElementById(this.tooltip)
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }
      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      let innerHTML = ''
      tooltip.body.forEach((element, index) => {
        let selector = ''
        innerHTML +=
          '<div class="chart-tooltip-item ' +
          selector +
          '">' +
          '<span class="mr-1" style="display:inline-block;width:8px;height:8px;background-color:' +
          tooltip.labelColors[index].backgroundColor +
          '"></span>' +
          element.lines +
          '</div>'
      })

      tooltipEl.innerHTML =
        '<div class="fw-bold">' + tooltip.title + '</div>' + innerHTML
      tooltipEl.style.opacity = 1
      tooltipEl.style.top = tooltip.caretY - tooltip.height - 20 + 'px' // done
      // tooltipEl.style.left = tooltip.caretX + tooltip.width / 2 - 15 + 'px'
      // find center
      if (tooltip.dataPoints.length > 0) {
        let dataWidth = []
        tooltip.dataPoints.forEach((e) => {
          if (e.x > 0) dataWidth.push(e.x)
        })
      }
      tooltipEl.style.left =
        Math.max(...dataWidth) / 2 + tooltip.width / 2 + 'px'
    },
    generateChart() {
      const labels = []
      this.sources.forEach((source) => {
        labels.push(source.label)
      })

      const datasets = []
      this.series.forEach((serie, index) => {
        if (serie.color !== '') {
          datasets.push({
            label: serie.name,
            backgroundColor: serie.color,
            data: this.setData(index),
            hoverBackgroundColor: serie.color,
          })
        }
      })
      return {
        labels: labels,
        datasets: datasets,
      }
    },
    setData(index) {
      let arrData = []
      this.sources.forEach((source) => {
        arrData.push(source[this.series[index].label] ?? '')
      })
      return arrData
    },
  },
}
