
import { mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  name: 'ModalPopUpComponent',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isExisting: {
      type: String,
      default: 'disabled',
    },
    userProfile: {
      type: Object,
      default: Object,
    },
    popup: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      swiperOption: {},
      page: 1,
      isDisabled: false,
      modalShow: false,
    }
  },
  watch: {
    show: function (value) {
      if (value) {
        document.getElementsByTagName('body')[0].classList.add('modal-overflow')

        if (Cookie.get('popupImage') === undefined) {
          if (this.popup?.image?.url) {
            // this.$refs['popup-image'].show()
            this.modalShow = true
            Cookie.set('popupImage', this.isExisting, {
              expires: 1,
              secure: true,
            })
          }
        }
      }
    },
    popup: function (value) {
      if (value?.image?.url) {
        if (Cookie.get('popupImage') === 'existing')
          // this.$refs['popup-image'].show()
          this.modalShow = true
      }
    },
  },
  created() {
    if (process.client) {
      window.addEventListener('beforeunload', this.handleBeforeUnload)
      if (Cookie.get('popupImage') === 'existing') {
        this.isDisabled = true
      }
    }
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('beforeunload', this.handleBeforeUnload())
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    onClose() {
      this.handleBeforeUnload()
      // this.$refs['popup-image'].hide()
      this.modalShow = false
      setTimeout(() => {
        document
          .getElementsByTagName('body')[0]
          .classList.remove('modal-overflow')
      }, 500)
    },
    handleBeforeUnload(event) {
      if (this.isDisabled) {
        Cookie.set('popupImage', 'disabled', {
          expires: 1,
          secure: true,
        })
      }
    },
  },
}
