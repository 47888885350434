
export default {
  name: 'NotFoundComponent',
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
}
