
export default {
  data() {
    return {
      modalShow: false,
      isWarning: false,
      message: {},
    }
  },
  created() {
    if (process.client) {
      this.$nuxt.$on('addToCart', (data) => {
        this.isWarning = data?.isWarning
        this.message = data?.message
        this.modalShow = true

        if (!this.isWarning)
          setTimeout(() => {
            this.modalShow = false
          }, 2000)
      })
    }
  },
  beforeDestroy() {
    if (process.client) {
      this.$nuxt.$off('addToCart')
    }
  },
}
