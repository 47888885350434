
// import ilbs
import 'vue-search-select/dist/VueSearchSelect.css'
import { BasicSelect } from 'vue-search-select'

export default {
  name: 'SearchSelectComponent',
  components: {
    'basic-select': BasicSelect,
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    defaultValue: {
      type: Object,
      default: Object,
    },
    placeholder: {
      type: String,
      default: 'กรุณาเลือก',
    },
    // noSelected: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      // options: [],
      item: {
        value: null,
        text: null,
      },
    }
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        if (val.value && val.text) {
          this.item = val
          // this.$emit('selected', { id: value.value, value: value.text })
        }
      },
      deep: true,
    },
  },
  // computed: {
  //   newOption() {
  //     return
  //   }
  // },
  mounted() {
    if (Object.keys(this.defaultValue).length) {
      if (this.defaultValue.value && this.defaultValue.text) {
        this.item = this.defaultValue
      }
    }
    document.querySelectorAll('.no-selected .text ').forEach((e) => {
      e.innerHTML = ''
    })
    document.querySelectorAll('.no-selected .search ').forEach((e) => {
      e.placeholder = 'ค้นหา'
    })
  },
  methods: {
    onSelect(item) {
      item.value =
        item.value === null || item.value === undefined ? '' : item.value
      item.text = item.text === null || item.text === undefined ? '' : item.text
      item.account_id =
        item.account_id === null || item.account_id === undefined
          ? ''
          : item.account_id
      item.custom =
        item.custom === null || item.custom === undefined ? '' : item.custom

      this.item = item
      this.$emit('selected', {
        id: item.account_id,
        value: item.value,
        text: item.text,
        custom: item.custom,
      })
    },
  },
}
