
export default {
  name: 'SwiperSliderComponent',
  components: {
    couponItem: async () =>
      await import('@/components/suggest-coupon/couponItemShop.vue'),
    modalCouponDetail: async () =>
      await import('@/components/suggest-coupon/modalCouponDetail.vue'),
  },
  props: {
    coupons: { type: Array, default: () => [] },
    loop: { type: Boolean, default: false },
    hideButton: { type: Boolean, default: true },
    spaceBetween: { type: Number, default: 15 },
    slideStyle: { type: String, default: 'recommended' },
    groupslide: { type: Boolean, default: false },
    seeAll: { type: String, default: '' },
    title: {
      type: String,
      default() {
        return this.$t('suggestCoupon.text-discount-coupon')
      },
    },
    showPagination: { type: Boolean, default: false },
  },
  data() {
    return {
      ref: '',
      lazyNum: 0,
      slidesSizesGrid: 0,
      virtualSize: 0,
      isActivePrev: false,
      isActiveNext: false,
      isActiveSlide: false,
      swiperOption: {
        // pagination: {
        //   el: '#coupon-pagination-' + this.ref,
        //   clickable: true,
        // },
        breakpoints: this.breakpoints(),
        on: {
          touchMove: () => {
            this.isActiveSlide = true
            this.slidesSizesGrid =
              this.$refs[this.ref].$swiper.slidesSizesGrid[0]
            this.virtualSize = this.$refs[this.ref].$swiper.virtualSize
          },
        },
      },
      slidesPerView: 0,
      //   coupon
      coupon_code: '',
      couponDetail: '',
      promotionNo: '',
      product_include_flag: '',
      isShowCouponDetail: false,
    }
  },
  watch: {
    coupons: {
      handler: function (val) {
        if (val.length > 0) this.onSlide()
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    this.swiperOption.spaceBetween = this.spaceBetween
    this.swiperOption.loop = this.loop
    this.swiperOption.pagination = {
      el: '#coupon-pagination-' + this.ref,
      clickable: true,
    }
  },
  mounted() {
    this.lazyNum = 7
    this.onSlide()
  },
  methods: {
    onShowPromotionDetail(obj) {
      console.log('promotion_detail_html', obj)
      this.coupon_code = obj?.coupon_code
      this.couponDetail = obj?.promotion_detail_html
      this.promotionNo = obj?.promotion_no
      this.product_include_flag = obj?.product_include_flag
      if (this.couponDetail) this.isShowCouponDetail = true
    },
    breakpoints() {
      let mobile = 0
      let minMobile = 2
      let tablet = 2
      let minDesktop = 3
      let wideDesktop = 3

      switch (this.slideStyle) {
        case 'recommended':
          mobile = 1
          minMobile = 1.3
          tablet = 2
          minDesktop = 3
          wideDesktop = 4
          break
        case 'pdp':
          mobile = 1
          minMobile = 1.3
          tablet = 2
          minDesktop = 1.5
          wideDesktop = 1.5
          break

        default:
          break
      }
      return {
        0: {
          slidesPerView: mobile,
          slidesPerGroup: this.groupslide ? mobile : 1,
        },
        468: {
          slidesPerView: minMobile,
          slidesPerGroup: this.groupslide ? minMobile : 1,
        },
        768: {
          slidesPerView: tablet,
          slidesPerGroup: this.groupslide ? tablet : 1,
        },
        1179: {
          slidesPerView: minDesktop,
          slidesPerGroup: this.groupslide ? minDesktop : 1,
        },
        1360: {
          slidesPerView: wideDesktop,
          slidesPerGroup: this.groupslide ? wideDesktop : 1,
        },
      }
    },
    onSlide() {
      if (this.hideButton) {
        this.isActivePrev =
          this.$refs[this.ref].$swiper.activeIndex === 0 ? false : true
        this.isActiveNext =
          this.$refs[this.ref].$swiper.activeIndex ===
            this.coupons.length -
              this.$refs[this.ref].$swiper.params.slidesPerView ||
          this.coupons.length <=
            this.$refs[this.ref].$swiper.params.slidesPerView
            ? false
            : true
      }
    },
    next() {
      this.isActiveSlide = true
      this.$refs[this.ref].$swiper.slideNext()
    },
    prev() {
      this.isActiveSlide = true
      this.$refs[this.ref].$swiper.slidePrev()
    },
  },
}
