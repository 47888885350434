
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import SelectObject from '@/components/share/select-object.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'AccountAddressEdit',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    'select-object': SelectObject,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isDefault: false,
      isPhone: false,
      address: {
        contact: '',
        mobile: '',
        phone: '',
        extention: '',
        address_1: '',
        address_2: '',
        postcode: '',
        province: '',
        district: '',
        sub_district: '',
        remark: '',
        is_default: '',
        phonetype: '',
        old_phone: '',
        old_extension: '',
        is_deliveryfee: '',
      },
      district: [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ],
      sub_district: [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ],
      province: [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ],
      zoneList: [],
      tempProvinceGroup: [],
      isEdit: false,
      isEditSubDistrict: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    requiredIsPhone() {
      return this.address.phone === '' && this.address.mobile === ''
    },
  },
  watch: {
    'address.district': function (val) {
      let filterZone = this.zoneList.filter((q) => q.district.th === val.th)
      this.sub_district = [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ]
      if (filterZone != undefined && filterZone.length) {
        filterZone.forEach((item) => {
          this.sub_district.push({
            is_deliveryfee: item?.is_deliveryfee,
            text: item.sub_district.th,
            value: item.sub_district,
          })
        })
        // remove get temp
        if (!this.isEditSubDistrict) {
          this.address.sub_district = this.sub_district[1].value
        } else {
          this.isEditSubDistrict = false
        }
      }
    },
    'address.phone': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'address.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
    'address.sub_district': function (val) {
      this.address.is_deliveryfee = this.sub_district.find(
        (a) => a.text === val.th
      )?.is_deliveryfee
    },
    'address.province': {
      handler: function (val) {
        if (!this.isEdit) {
          let index = this.tempProvinceGroup.findIndex(
            (e) => e.province.en === val.en
          )
          if (index !== -1) {
            this.tempProvinceGroup[index].items.forEach((item) => {
              if (
                this.district.find((q) => q.value.th === item.district.th) ===
                undefined
              ) {
                this.district.push({
                  text: item.district.th,
                  value: item.district,
                })

                this.address.district = this.district[1].value

                console.log('tempEditData', this.address.district)
              }
            })
          }
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getShippingById()
  },
  methods: {
    validateSubmit() {
      this.$refs.editShippingForm.validate().then((result) => {
        if (result) {
          if (this.address.phone === '' && this.address.extension !== '') {
            this.isPhone = true
          } else {
            this.updateShippingDataById()
            this.isPhone = false
          }
        }
      })
    },
    async searchZone(event) {
      if (event.length >= 5) {
        const params = new URLSearchParams()
        params.append('keyword', event)
        this.district = [
          {
            text: this.$i18n.t(`account-address-edit-id.select`),
            value: '',
          },
        ]
        await this.$logisticProvider
          .getZone(params)
          .then((result) => {
            console.log(result.data)
            this.zoneList = result.data
            if (result.data.length) {
              // get groups
              this.province = [
                {
                  text: this.$i18n.t(`account-address-edit-id.select`),
                  value: '',
                },
              ]
              let value = result.data
              let groups = []
              value = value.reduce((h, value) => {
                return Object.assign(h, {
                  [value['province']['en']]: (
                    h[value['province']['en']] || []
                  ).concat(value),
                })
              }, {})
              Object.keys(value).forEach((element) => {
                groups.push({
                  province: value[element][0].province,
                  items: value[element],
                })
              })
              this.tempProvinceGroup = groups

              this.tempProvinceGroup.forEach((e) => {
                this.province.push({
                  text: e.province.th,
                  value: e.province,
                })
              })

              // get district
              let index = this.tempProvinceGroup.findIndex(
                (e) => e.province.en === this.address.province.en
              )
              if (index !== -1) {
                this.tempProvinceGroup[index].items.forEach((item) => {
                  if (
                    this.district.find(
                      (q) => q.value.th === item.district.th
                    ) === undefined
                  ) {
                    this.district.push({
                      text: item.district.th,
                      value: item.district,
                    })
                  }
                })
              }
              // remove get temp
              if (!this.isEdit) {
                this.address.province = groups[0].province
                this.address.district = this.district[1].value
              } else {
                this.isEdit = false
              }

              let _district = this.district.find(
                (a) => a.text === this.address.district.th
              )
              if (_district === undefined) {
                this.cleardata()
              } else {
                //## has value from loading page
                if (Object.keys(this.address.district).length) {
                  let temp = result.data.find(
                    (q) => q.district.th === this.address.district.th
                  )
                  this.address.district = temp.district
                }
                if (Object.keys(this.address.sub_district).length) {
                  let temp = result.data.find(
                    (q) => q.sub_district.th === this.address.sub_district.th
                  )
                  this.address.sub_district = temp.sub_district
                }
              }
            }
          })
          .catch((err) => {
            console.log('searchZone', err)
          })
          .finally(() => {
            console.log('finally!!')
          })
      } else {
        this.address.sub_district = ''
        this.address.district = ''
        this.address.province = ''
        this.sub_district = [
          {
            text: this.$i18n.t(`account-address-edit-id.select`),
            value: '',
          },
        ]
        this.district = [
          {
            text: this.$i18n.t(`account-address-edit-id.select`),
            value: '',
          },
        ]
        this.province = [
          {
            text: this.$i18n.t(`account-address-edit-id.select`),
            value: '',
          },
        ]
      }
    },
    async getShippingById() {
      await this.$profileProvider
        .getShippingDataById(this.userProfile.id, this.$route.params.id)
        .then(
          async (result) => {
            if (result.status === 200) {
              let data = result.data
              // await this.searchZone(data.zipcode)
              let phone = data.phones.find((a) => a.type === 'Phone')
              let mobile = data.phones.find((a) => a.type === 'Mobile')
              this.address = {
                zipcode: data.zipcode,
                contact: data.contact,
                address_1: data.address_1,
                address_2: data.address_2,
                province: data.province,
                district: data.district,
                sub_district: data.subdistrict,
                remark: data.remark,
                is_default: data.is_default,
                phone: phone?.value ?? '',
                extension: phone?.extension ?? '',
                mobile: mobile?.value ?? '',
              }
              this.isDefault = this.address.is_default
              this.isEdit = true
              this.isEditSubDistrict = true
            }
          },
          (error) => {
            console.log('getShippingById', error.responsed)
          }
        )
    },
    async updateShippingDataById() {
      await this.$profileProvider
        .updateShippingData(
          this.userProfile.id,
          this.$route.params.id,
          this.address
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 204) {
              this.toAllShipping()
            }
          },
          (error) => {
            console.log('getShippingById', error.responsed)
          }
        )
    },
    toAllShipping() {
      this.$router.push({ path: this.$i18n.path('account/delivery') })
    },
    cleardata() {
      this.address.sub_district = ''
      this.address.district = ''
      this.sub_district = [
        {
          text: this.$i18n.t(`account-address-edit-id.select`),
          value: '',
        },
      ]
    },
  },
}
