
export default {
  name: 'SpecialDealComponent',
  props: {
    updateInfo: {
      type: String,
      default: '',
    },
    urlParameter: {
      type: String,
      default: '',
    },
    bannerSpecialDeal: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: Array,
    },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    // overlayImagesSwiper: { type: Array, default: () => [] },
  },
  watch: {
    bannerSpecialDeal: {
      immediate: true,
      handler: function (val) {
        console.log('bannerSpecialDeal', val)
        if (val.length) {
          this.imgTitle = val.filter((image) => image.sequence === 0)
          this.imgBanner = val.filter((image) => image.sequence !== 0)
        }
        console.log('bannerSpecialDeal imgTitle', this.imgTitle)
        console.log('bannerSpecialDeal imgBanner', this.imgBanner)
      },
      deep: true,
    },
    items: {
      handler: async function (val) {
        if (val.length) {
          let pid = this.items.map((item) => {
            return item.productId
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesSwiper = graphqlResponse
          }
          console.log('overlayImagesSwiper', this.overlayImagesSwiper)
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      imgTitle: {},
      imgBanner: {},
      overlayImagesSwiper: [],
    }
  },
}
