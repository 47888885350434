
import OrderItems from '@/components/order/order-items.vue'
import ApproverList from '@/components/checkout/approver-list-gr.vue'
import ListOrderProcess from '@/components/order/list-process.vue'
import SummaryComponent from '@/components/checkout/summary-gr-component.vue'
import ApproveModal from '@/components/modal/confirmApproveOrder.vue'
import ModalWarning from '@/components/modal/modalWarning.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'blank-nav',
  name: 'ApproveGR',
  components: {
    // 'aside-component': asideComponent,
    'order-item': OrderItems,
    ApproverList,
    ListOrderProcess,
    'summary-component': SummaryComponent,
    ApproveModal,
    ModalWarning,
  },
  data() {
    return {
      modalTitle: '',
      showApproveModal: false,
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      productGroups: [],
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        period: {
          used_amt: 0,
          budget_amt: 0,
          remain_amt: 0,
          original_amt: 0,
        },
        sum_order_total_amt: 0,
        control_level: '',
        period_type: '',
        variant: 'primary',
      },
      items: [],
      approval: [],
      approverLevel: 1,
      activityLogs: [],
      isGR: false,
      warningMessages: [],
      modalWarning: false,
      approverAction: '',
      isCartHeader: false,
      is_verify: true,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
          ? true
          : false
      else return false
    },
    hasApprovePermission() {
      return this.orderDocument?.approver?.some(
        (a) =>
          a.user_open_id === this.$route.query.user_open_id &&
          a.approval_status === 'Waiting'
      )
    },
  },
  watch: {},
  async mounted() {
    console.log(this.$route.params.id)
    if (!this.$utils.isValid(this.$route.params.id)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบเลขออเดอร์', en: 'order id not found' },
      })
      this.modalWarning = true
    } else if (!this.$utils.isValid(this.$route.query.verify_key)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบ verify key', en: 'verify key not found' },
      })
      this.modalWarning = true
    } else if (!this.$utils.isValid(this.$route.query.user_open_id)) {
      this.warningMessages.push({
        key: '',
        message: { th: 'ไม่พบ user id', en: 'user id not found' },
      })
      this.modalWarning = true
    } else {
      await this.getOrderDocument()
    }
  },
  methods: {
    onApproverAction(action) {
      this.approverAction = action
      this.showApproveModal = true
    },
    displayApproveBadge(status, approval) {
      return status === approval.level_status ? true : false
    },
    async getOrderDocument() {
      let params = new URLSearchParams()
      params.append('verify_key', this.$route.query.verify_key)
      params.append('is_verify', this.is_verify)
      params.append('user_open_id', this.$route.query.user_open_id)
      await this.$orderProvider
        .getOrderDocumentGRFromEmail(this.$route.params.id, params)
        .then((result) => {
          if (result.status === 200) {
            this.orderDocument = result.data
            console.warn('object :>> ', result.data)
            this.getOrderActivityGRLogs()
            this.isCartHeader = true
            this.summary = {
              net_amt:
                this.orderDocument.net_amt ||
                this.orderDocument?.summary?.net_amt,
              net_deliveryfee_excvat:
                this.orderDocument.net_deliveryfee_excvat ||
                this.orderDocument?.summary?.net_deliveryfee_excvat,
              nonvat_prod_net_amt:
                this.orderDocument.nonvat_prod_net_amt ||
                this.orderDocument?.summary?.nonvat_prod_net_amt,
              vat_amt:
                this.orderDocument.vat_amt ||
                this.orderDocument?.summary?.vat_amt,
              vat_prod_net_amt:
                this.orderDocument.vat_prod_net_amt ||
                this.orderDocument?.summary?.vat_prod_net_amt,
              voucher_disc_amt_excvat:
                this.orderDocument.voucher_disc_amt_excvat ||
                this.orderDocument?.summary?.voucher_disc_amt_excvat,
              voucher_no: this.orderDocument.voucher_no,
              payment_disc_amt_excvat:
                this.orderDocument.payment_disc_amt_excvat ||
                this.orderDocument?.summary?.payment_disc_amt_excvat,
              payment_disc_code:
                this.orderDocument.payment_disc_code ||
                this.orderDocument?.summary?.payment_disc_code,
              is_use_coupon: this.orderDocument.is_use_coupon,
              used_voucher_type:
                this.orderDocument.voucher_type ||
                this.orderDocument?.summary?.voucher_type, // used_voucher_type
            }

            this.productGroups = []
            this.productGroups.push({
              items: this.orderDocument.items,
            })
            this.productGroups.map((group) => {
              group.premiums = group.items.filter((q) => q.is_free)
              group.items = group.items.filter((q) => !q.is_free)
              group.items.map((item) => {
                item.display_price_exclude_vat = item.price_disc_excvat
                item.product_name = {
                  th: item.product_name_th,
                  en: item.product_name_en,
                }
              })
              if (group.premiums.length) {
                group.premiums.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
              }
            })
          }
        })
    },
    async getOrderActivityGRLogs() {
      await this.$orderProvider
        .getOrderActivityGRLogs(this.$route.params.id)
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    onConfirmApprove(payload) {
      console.log(payload)
      this.approveOrder(payload.remark)
    },
    async approveOrder(remark) {
      let params = {
        verify_key: this.$route.query.verify_key,
        user_open_id: this.$route.query.user_open_id,
        remark: remark,
        goods_received_id: this.orderDocument.id,
        is_verify: this.is_verify,
      }
      const result = await this.$orderProvider.updateGoodsReceivedApproveOrder(
        params
      )
      if (result.status === 204) {
        this.getOrderDocument()
      }
    },
    orderDocumentStatus() {
      switch (this.orderDocument.goods_received_status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
  },
}
