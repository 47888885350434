
import { mapGetters } from 'vuex'
export default {
  name: 'CartEmptyItems',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isFamilarItems: { type: Boolean, default: true },
  },
  data() {
    console.log('cart-item : ', this.items)
    return {
      groups: [],
      selectedShipping: {
        option: [],
      },
      selectedItems: [],
      checkoutItem: [],
      is_selected_all: false,
      isSelectedAllEmpty: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  watch: {},
  created() {},
  methods: {
    displayProductImage(productImageList) {
      let img = productImageList.images
      let isImgErr = false

      if (img.length) {
        const imgCheck = new Image()
        imgCheck.onerror = function () {
          isImgErr = true
        }
        imgCheck.src = img.find((q) => q.size === 'thumbnail').url
      }

      let errImg =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      let timeStamp = this.$utils.isObjectKeyValid(
        productImageList.update_info,
        'timestamp'
      )
        ? // ? '?v=' +
          //   this.$utils.getDateString(productImageList.update_info.timestamp)
          // : ''
          ''
        : ''

      if (img.length && !isImgErr) {
        return img.some((q) => q.size === 'thumbnail')
          ? img.find((q) => q.size === 'thumbnail').url + timeStamp
          : errImg
      } else {
        return errImg
      }
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    // onRoute(product) {
    //   this.$nextTick(() => {
    //     this.$router.push({
    //       path: this.$i18n.path(
    //         `product/${product.meta.seo_name[this.$store.state.locale]}-p.${
    //           product.sku_code
    //         }`
    //       ),
    //       query: { queryIdEventAlgolia: '' },
    //     })
    //   })
    // },
  },
}
