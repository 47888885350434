
import { mapGetters } from 'vuex'

export default {
  name: 'DiscountCouponPage',
  // layout: 'SharedLayout',
  components: {
    couponItem: async () =>
      await import('@/components/suggest-coupon/couponItemShop.vue'),
    modalCouponDetail: async () =>
      await import('@/components/suggest-coupon/modalCouponDetail.vue'),
  },
  async asyncData({ store }) {
    const theme = 'shop-type-a'
    return { theme }
  },
  data() {
    return {
      isSkeletonPage: true,
      coupon_banners: [],
      isLoading: true,
      activeTab: '',
      showTab: ['Brand', 'Seller', 'Platform', 'NewCustomer'],
      coupons: [],
      page: 1,
      per_page: 20,
      page_total: 0,
      imgWarningMessageList: '',
      warningMessageList: '',
      warningMessageListDesc: '',
      // coupon detail
      coupon_code: '',
      couponDetail: '',
      promotionNo: '',
      product_include_flag: '',
      isShowCouponDetail: false,
    }
  },

  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.handleRouteQueryChange(newQuery)
      },
    },
  },
  created() {
    this.getฺCouponBanners()
  },
  methods: {
    async getฺCouponBanners() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType || 'Standard')
      if (this.userProfile.segment)
        params.append('segment', this.userProfile.segment)
      params.append('page_type', 'coupon')
      params.append('status', 'active')

      await this.$profileProvider.getฺBannerHomeData(params).then(
        async (result) => {
          if (result.status === 200) {
            const data = result.data
            console.log('getฺCouponBanners', data)
            data
              .filter((a) => a.page_type === 'coupon')
              .forEach((e) => {
                this.coupon_banners.push({
                  src: e.image.url,
                  src_mobile: e?.image_mobile?.url,
                  url: e.path_url,
                  title: e.title_tag?.[this.$i18n.locale] || e.title,
                  alt: e.alt_tag?.[this.$i18n.locale] || e.title,
                  gtmSeq: e.sequence,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info.timestamp,
                  banner_name: e.banner_name,
                })
              })
            // this.ofm_mall_banner
            this.coupon_banners = this.coupon_banners.sort(
              (a, b) => a.gtmSeq - b.gtmSeq
            )
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
      this.isSkeletonPage = false
    },
    async handleRouteQueryChange({ coupon }) {
      console.log(555)
      const isValidCoupon = coupon && this.showTab.includes(coupon)
      this.activeTab = isValidCoupon ? coupon : this.showTab[0]

      if (!isValidCoupon && coupon) {
        this.$router.replace({ path: this.$route.path, query: {} })
      }

      // if (isValidCoupon) {
      await this.onChangeTab(this.activeTab)
      // }
    },
    async onChangeTab(tab) {
      this.isLoading = true
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile?.id || '')
      params.append(
        'user_category_type',
        this.userProfile?.categories_type || 'Standard'
      )
      params.append('coupon_types', tab)
      params.append('page', this.$route.query.page || 1)
      params.append('page_size', this.per_page)
      await this.$productProvider.getPromotionCoupons(params).then(
        (responsed) => {
          if (responsed.data?.items?.length) {
            this.coupons = [...responsed.data.items]
            this.page_total = responsed.data.total_records
          } else if (responsed.data?.code) {
            this.imgWarningMessageList =
              responsed.data?.image_url ||
              'https://ofm-cdn0.ofm.co.th/images/logo/ic_coupon_not_available.png'
            this.warningMessageList = responsed.data?.message
              ? responsed.data.message[this.$i18n.locale]
              : this.$t(`suggestCoupon.default-title-warning`)
            this.warningMessageListDesc = responsed.data?.message_description
              ? responsed.data.message_description[this.$i18n.locale]
              : this.$t(`suggestCoupon.default-desc-warning`)
          }
        },
        (error) => {
          console.log('getPromotionCoupons', error.responsed)
        }
      )
      this.isLoading = false
      console.log('getPromotionCoupons', this.isLoading)
    },
    async onPagination(page) {
      this.page = page
      this.$router.push({
        query: { ...this.$route.query, page: this.page },
      })
    },
    onShowPromotionDetail(obj) {
      this.coupon_code = obj?.coupon_code
      this.couponDetail = obj?.promotion_detail_html
      this.promotionNo = obj?.promotion_no
      this.product_include_flag = obj?.product_include_flag
      if (this.couponDetail) this.isShowCouponDetail = true
    },
  },
}
