
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    selectedLegend: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  watch: {
    series: {
      handler: function (val) {
        if (val.length) {
          this.$nextTick(() => {
            this.renderChart(this.generateChart(), {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: false,
                },
              },
              legend: {
                display: false,
              },
              legendCallback: function (chart) {
                return chart.data.datasets
              },
              tooltips: {
                enabled: false,
                mode: 'index',
                position: 'nearest',
                custom: this.customTooltips,
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    // categoryPercentage: 0.5,
                    // barPercentage: 1,
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontFamily: 'Kanit',
                      fontStyle: 'normal',
                      fontSize: 11,
                      fontColor: '#999999',
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: true,
                    },
                    ticks: {
                      maxTicksLimit: 10,
                      fontFamily: 'Kanit',
                      fontStyle: 'normal',
                      fontSize: 11,
                      fontColor: '#999999',
                    },
                  },
                ],
              },
            })
            const legendCallback = this.$data._chart.generateLegend()
            this.$emit('generated', legendCallback)
          })
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async customTooltips(tooltip) {
      // Tooltip Element
      let tooltipEl = document.getElementById(this.tooltip)
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }
      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      let innerHTML = ''
      let sum = 0
      tooltip.body.forEach((element, index) => {
        let selector = ''
        let label = element.lines.toString().split(':')
        sum = sum + Number((label[1] / 1).toFixed(2))
        if (index % 20 == 0) innerHTML += '<div class="chart-column">'
        innerHTML +=
          '<div class="chart-tooltip-item ' +
          selector +
          '">' +
          '<span class="mr-1" style="display:inline-block;width:8px;height:8px;background-color:' +
          tooltip.labelColors[index].backgroundColor +
          '"></span>' +
          label[0] +
          ': ' +
          this.$utils.formatPrice(label[1], 2) +
          '</div>'
        if (index % 20 == 19) innerHTML += '</div>'
      })

      tooltipEl.innerHTML =
        '<div class="fw-bold">' +
        tooltip.title +
        '</div>' +
        '<div class="chart-row">' +
        innerHTML +
        '</div>' +
        '<div class="chart-sum">' +
        this.$t(`company-dashboard.tabOrderAmount.sum`) +
        ' : ' +
        this.$utils.formatPrice(sum, 2) +
        '</div>'

      tooltipEl.style.opacity = 1
      tooltipEl.style.left = tooltip.caretX + 15 + 'px'
      tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + 'px'
    },
    generateChart() {
      const labels = []
      this.sources.forEach((source) => {
        labels.push(source.label)
      })

      const datasets = []
      this.series.forEach((serie, index) => {
        if (serie.color !== '') {
          datasets.push({
            barPercentage: 1,
            categoryPercentage: 0.5,
            minBarLength: 2,
            label: serie.name,
            backgroundColor: serie.color,
            data: this.setData(index),
            hoverBackgroundColor: serie.color,
          })
        }
      })
      return {
        labels: labels,
        datasets: datasets,
      }
    },
    setData(index) {
      let arrData = []
      this.sources.forEach((source) => {
        arrData.push(source[this.series[index].label] ?? 0)
      })
      return arrData
    },
  },
}
