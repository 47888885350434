
import asideComponent from '@/components/template/aside-user.vue'
// import CatalogItems from '@/components/catalog/catalog-items.vue'
import InputText from '@/components/share/input-text.vue'
import SkeletonCatalog from '@/components/skeleton/catalog-items.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import ConfirmModal from '@/components/modal/confirmModal.vue'
export default {
  layout: 'main',
  name: 'AccountFavorite',
  components: {
    'aside-component': asideComponent,
    CatalogItems: async () =>
      await import('@/components/catalog/catalog-items.vue'),
    'input-text': InputText,
    SkeletonCatalog,
    ConfirmModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  props: {},
  data() {
    return {
      selectedCatalog: '',
      isCatalog: false,
      catalog_id: '',
      catalog: {
        user_open_id: '',
        account_channel: '',
        company_id: '',
        account_id: '',
        name: '',
        is_default: false,
      },
      catalogs: [],
      temp_catalog: { catId: '', catName: '', items: [] },
      getCatalog: {
        catId: '',
        catName: '',
        items: [],
      },
      companyid: '',
      useropenid: '',
      costcenterid: '',
      default: {
        is_default: true,
      },
      setting: {
        useropenid: '',
        companyid: '',
        account_id: '',
      },
      items: [],
      noItems: [],
      isclear: false,
      skeletonCatalogs: true,
      skeletonProducts: true,
      isShowDeleteModal: false,
      selectedData: {},
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'userCostcenter',
      'profileType',
    ]),
  },
  created() {
    if (this.profileType === 'eprocurement') {
      this.companyid = this.userProfile.id
      this.useropenid = this.openId
      this.costcenterid = Object.keys(this.userCostcenter).length
        ? this.userCostcenter.id
        : ''
      console.log(5555555, this.userCostcenter.id)
    }
    if (this.profileType === 'standard') {
      this.useropenid = this.openId
      this.account_id = this.userProfile.id
    }
  },
  async mounted() {
    this.getCatalogsById()
  },
  methods: {
    async onRemove(catalogid) {
      await this.$productProvider
        .deleteCatalogById(catalogid)
        .then((result) => {
          console.log('status', result.status)
          if (result.status === 200) {
            this.getCatalogsById()
          }
        })
        .catch((error) => {
          console.log('deleteCatalogs', error.responsed)
        })
    },
    async onSetDefault(catalogid) {
      await this.$productProvider
        .updateCatalogDefaultById(
          catalogid,
          this.companyid,
          this.openId,
          this.default
        )
        .then((result) => {
          if (result.status === 204) {
            this.getCatalogsById()
          }
        })
        .catch((error) => {
          console.log('onSetDefault', error.responsed)
        })
    },

    async getCatalogsById() {
      this.skeletonCatalogs = true
      this.catalogs = []
      let params = new URLSearchParams()
      params.append('company_id', this.companyid)
      params.append('user_open_id', this.useropenid)
      params.append('account_channel', this.profileType)
      await this.$productProvider.getCatalogsDataById(params, false).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((e, index) => {
              this.catalogs.push({
                index: index,
                is_default: e.is_default,
                catId: e.id,
                catName: e.name,
              })
            })
            console.log('catalog', this.catalogs)
            if (this.catalogs.length > 0) {
              let catalog_id = this.catalogs[0].catId
              this.getProductCatalogs(catalog_id)
            } else {
              this.getCatalog = {}
              this.noItems = []
              this.skeletonProducts = false
            }
          } else {
            this.skeletonProducts = false
          }
        },
        (error) => {
          console.log('getCatalogsById', error.responsed)
          this.skeletonProducts = false
        }
      )
      this.skeletonCatalogs = false
    },
    async createCatalog() {
      if (this.catalog.name === '') {
        this.isCatalog = true
      } else {
        this.catalog = {
          user_open_id: this.useropenid,
          account_channel: this.profileType,
          company_id: this.companyid,
          account_id:
            this.profileType === 'standard' ? this.userProfile.id : null,
          name: this.catalog.name,
        }
        await this.$productProvider.createCatalogData(this.catalog).then(
          (result) => {
            if (result.status === 201) {
              this.catalog.name = ''
              this.getCatalogsById()
            }
          },
          (error) => {
            console.log('createCatalog', error.responsed)
          }
        )
      }
    },
    async getProductCatalogs(catalog_id) {
      this.skeletonProducts = true
      this.catalog_id = catalog_id
      let params = new URLSearchParams()
      params.append('user_open_id', this.useropenid)
      params.append('profile_id', this.userProfile.id)
      params.append('account_channel', this.profileType)
      params.append('costcenter_id', this.costcenterid)
      await this.$productProvider
        .getProductCatalogsDataById(catalog_id, params)
        .then(
          (result) => {
            this.selectedCatalog = catalog_id
            this.getCatalog = {}
            this.noItems = []
            if (result.status === 200) {
              let data = result.data

              if (data.items !== null) {
                this.noItems = data.items.filter(
                  (q) =>
                    q.is_wrong_condition === true || q.warningTags.length > 0
                )
                if (this.noItems.length) {
                  this.noItems.map((item) => {
                    item.product_name = item.product_names.find(
                      (q) => q.type === 'Online'
                    ).display_name
                  })
                }
                console.log('getProductCatalogsDataById', this.noItems)
              }
              console.log('this.noItems', this.noItems)
              this.getCatalog = {
                allitems: data.items === null ? undefined : data.items,
                items:
                  data.items === null || data.items === undefined
                    ? undefined
                    : data.items.filter((a) => a.warningTags.length === 0) ??
                      undefined,
                catId:
                  this.catalogs[
                    this.catalogs.findIndex((e) => e.catId === catalog_id)
                  ].catId,
                catName:
                  this.catalogs[
                    this.catalogs.findIndex((e) => e.catId === catalog_id)
                  ].catName,
              }
              if (
                this.getCatalog.items !== null &&
                this.getCatalog.items !== undefined
              ) {
                this.getCatalog.items.forEach((e, index) => {
                  Vue.set(e, 'isChecked', false)
                })
                this.temp_catalog = JSON.parse(JSON.stringify(this.getCatalog))
              }
            }
          },
          (error) => {
            console.log('getProductCatalogsDataById', error.responsed)
          }
        )
      this.skeletonProducts = false
    },
    async onchangecat(check) {
      this.isclear = check
    },
    onConfirmDeleteModal(value) {
      console.log('open delete modal')
      Object.assign(this.selectedData, value)
      this.isShowDeleteModal = true
    },
    onDeleteSubmit() {
      const { catId } = this.selectedData
      console.log('on submit data :====> ', this.selectedData)
      this.onRemove(catId)
    },
    onCloseConfirmDeleteModal() {
      console.log('close modal')
      this.isShowDeleteModal = false
      this.selectedData = {}
    },
  },
}
