
import jwtDecode from 'jwt-decode'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
    }
  },
  mounted() {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async onSubmit() {
      this.$refs.loginForm.validate().then((result) => {
        console.log(result)
        if (result) this.login()
      })
    },
    async login() {
      let apiData = JSON.stringify({
        username: this.input.username,
        password: this.$utils.cryptoJS(this.input.password),
        audienceId: process.env.NUXT_ENV_AUDIENCE_ID,
        applicationType: 'AD',
      })
      const response = await this.$authenProvider.signIn(apiData)
      if (response.status === 200) {
        response.data.type = 'AD'
        // console.log('response.data.', response.data)

        let objUser = jwtDecode(response.data.accessToken)
        response.data.user = {
          name: objUser.fullname,
          email: objUser.email,
          photo: '',
        }

        this.$store.dispatch('authen/setAuthToken', response.data)
        Cookie.set('refreshToken', response.data.refreshToken, { expires: 1 })
        Cookie.set('accessToken', response.data.accessToken, { expires: 1 })
        Cookie.set('user', JSON.stringify(response.data.user), { expires: 1 })

        if (
          Object.keys(this.$route.query).length &&
          this.$route.query.redirect
        ) {
          this.$router.push(this.$route.query.redirect)
        } else if (this.$store.getters.routeHistory !== '') {
          this.$router.push(this.$store.getters.routeHistory)
        } else this.$router.push('/')
      } else {
        console.log('signIn', response)
      }
    },
  },
}
