
export default {
  name: 'SwiperSliderComponent',
  props: {
    loop: { type: Boolean, default: false },
    hideButton: { type: Boolean, default: true },
    banner: { type: Boolean, default: false },
    spaceBetween: { type: Number, default: 0 },
    count: { type: Number, default: 1 },
    bannerCreative: { type: String, default: '' },
    imageType: { type: String, default: '' },
    imageField: { type: String, default: '' },
    prefixBanner: {
      type: String,
      default: 'HP',
    },
    autoPlay: { type: Number, default: 0 },
    speed: { type: Number, default: 800 },
    position: { type: String, default: '' },
    height: { type: String, default: 'auto' },
    width: { type: String, default: 'auto' },
    groupslide: { type: Boolean, default: false },
    slideStyle: { type: String, default: '' },
    //
    shopComponent: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      ref: '',
      isActivePrev: false,
      isActiveNext: false,
      swiperOption: {
        lazy: true,
        breakpoints: this.breakpoints(),
        speed: this.speed,
      },
      sizes: '',
      assetEndpoint: process.env.NUXT_ENV_SHOP_IN_SHOP_ASSETS,
    }
  },
  watch: {
    images: {
      handler: function (val) {
        if (val.length > 0) {
          this.onSlide()
          val.forEach((image) => {})
        }
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    if (this.autoPlay > 0) {
      this.swiperOption.autoplay = { delay: this.autoPlay }
    }
    this.swiperOption.spaceBetween = this.spaceBetween
  },
  computed: {
    images() {
      const file = this.shopComponent?.components_images
      console.log('file', this.shopComponent)
      return file || []
    },
  },
  mounted() {
    this.onSlide()
    if (this.position === 'promotion')
      this.sizes = 'xs:204 sm:405 md:255px lg:330px xl:223px'
  },
  methods: {
    onSwiperReady() {
      this.$refs[this.ref].$swiper.$el[0].children[0].onclick =
        this.onClickPerSlide
    },
    onClickPerSlide(event) {
      const image = JSON.parse(event.target.dataset.image)
      if (image !== undefined) {
        if (image.brandName) {
        } else {
          if (
            this.$utils.isValid(this.imageField) &&
            this.$utils.isValid(this.imageType) &&
            this.imageType === 'searchBrand'
          ) {
            this.$router.push({
              path: this.$i18n.path(`brand/${image[this.imageField]}`),
            })
          } else {
            if (
              this.$utils.isValid(this.bannerCreative) &&
              this.$utils.isValid(this.imageType) &&
              this.imageType === 'banner'
            ) {
              let title = image.title_tag?.[this.$i18n.locale] || image.title

              // //gtm promoClicked
              // this.$gtm.push({ ecommerce: null })
              // this.$gtm.push({
              //   event: 'promoClicked',
              //   ecommerce: {
              //     promoClick: {
              //       promotions: [
              //         {
              //           id: image.src,
              //           name: `${this.prefixBanner}|${
              //             image.gtmSeq
              //           }|${title}|${this.$utils.formatDateGTM(
              //             image.active_from
              //           )}_${this.$utils.formatDateGTM(image.active_to)}`,
              //           creative: this.bannerCreative,
              //           position: image.gtmSeq,
              //         },
              //       ],
              //     },
              //   },
              // })

              // //gtm GA4
              // this.$gtm.push({ ecommerce: null })
              // this.$gtm.push({
              //   event: 'select_promotion',
              //   ecommerce: {
              //     creative_name: this.bannerCreative,
              //     creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
              //     promotion_id: image.src,
              //     promotion_name: `${this.prefixBanner}|${
              //       image.gtmSeq
              //     }|${title}|${this.$utils.formatDateGTM(
              //       image.active_from
              //     )}_${this.$utils.formatDateGTM(image.active_to)}`,
              //   },
              // })

              // // gtm GA4
              // const promotion = {
              //   creative_name: this.bannerCreative,
              //   creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
              //   promotion_id: image.src,
              //   promotion_name: `${this.prefixBanner}|${
              //     image.gtmSeq
              //   }|${title}|${this.$utils.formatDateGTM(
              //     image.active_from
              //   )}_${this.$utils.formatDateGTM(image.active_to)}`,
              // }
              // this.$gtm.push({ ecommerce: null })
              // this.$gtm.push({
              //   user: this.$store.getters['user/userInfoTrack'],
              //   ...promotion,
              // })
              // this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
            }
          }
        }
      }
    },
    breakpoints() {
      console.log('img', this.images)
      let mobile = 0
      let tablet = 0
      let desktop = 0

      switch (this.count) {
        case 7:
          mobile = 2.4
          tablet = 5
          desktop = 7
          break
        case 6:
          mobile = this.imageType === 'banner' ? 1.5 : 1
          tablet = 3
          desktop = 6
          break
        case 3:
          mobile = 1.15
          tablet = 2
          desktop = 3
          break
        case 1:
          mobile = 1
          tablet = 1
          desktop = 1
          break
        default:
          mobile = 1
          tablet = 3
          desktop = 6
          break
      }

      return {
        0: {
          slidesPerView: mobile,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: tablet,
          slidesPerGroup: this.groupslide ? tablet : 1,
          loop: this.loop && this.images.length >= tablet,
        },
        1179: {
          slidesPerView: desktop,
          slidesPerGroup: this.groupslide ? desktop : 1,
          loop: this.loop && this.images.length >= desktop,
        },
      }
    },
    onSlide() {
      if (this.hideButton) {
        this.isActivePrev =
          this.$refs[this.ref].$swiper.activeIndex === 0 && !this.loop
            ? false
            : true
        this.isActiveNext =
          this.$refs[this.ref].$swiper.activeIndex ===
            this.images.length -
              this.$refs[this.ref].$swiper.params.slidesPerView && !this.loop
            ? false
            : true
      }
    },
    next() {
      this.$refs[this.ref].$swiper.slideNext()
    },
    prev() {
      this.$refs[this.ref].$swiper.slidePrev()
    },
  },
}
