
import InputQty from '@/components/share/input-qty.vue'

export default {
  name: 'CompareProductComponent',
  components: {
    'input-qty': InputQty,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
  watch: {},
}
