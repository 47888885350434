
import ConfirmRemoveModal from '@/components/modal/confirmRemoveApprover.vue'
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'RoleUser',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    ConfirmRemoveModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmModal: false,
      isEditUser: false,
      isEditRole: false,
      isActive: true,
      isPhone: false,
      isName: '',
      isLast: '',
      is_role_id: false,
      is_checked: false,
      errormessage: '',
      is_role_admin: false,
      user: {
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: '',
          value: '',
        },
        mobile: {
          type: '',
          value: '',
        },
        is_user_active: false,
        is_printing: false,
        is_level_one: false,
        role_user_open_id: '',
        role_email: '',
        role_name: {
          th: '',
          en: '',
        },
        is_old_user: false,
        is_admin: false,
        default_language: '',
      },
      edit: {
        firstName: {
          th: '',
          en: '',
        },
        lastName: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: false,
        default_language: '',
      },
      roles: [],
      editRoles: [],
      onSelectCheckbox: {
        val: '',
        index: '',
      },
      rolesEdit: [],
      roleAdmin: [],
      isError: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    requiredIsPhone() {
      return this.edit.phone.value === '' && this.edit.mobile.value === ''
    },
  },
  watch: {
    isEditRole: {
      handler: function (val) {
        if (val) {
          this.roles.forEach((element) => {
            this.editRoles.push({
              id: '',
              role_id: element.index,
              name: element.name,
              isActive: element.isActive,
            })
          })
        } else {
          this.editRoles = []
        }
        this.errormessage = ''
      },
    },
    onSelectCheckbox: {
      handler: function (data) {
        console.log('roles', this.roles)
        this.editRoles
          .filter((a) => a.index === data.index && a.isActive === data.val)
          .map((b) => (b.isActive = data.val))
      },
    },
    'edit.phone.value': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'edit.phone.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
  },
  mounted() {
    this.getCompaniesRoleMaster()
  },
  methods: {
    onConfirmRemoveModal() {
      this.showConfirmModal = true
      console.log('delectId')
    },
    validateCompaniesSettingSubmit() {
      this.$refs.editCompaniesSettingForm.validate().then((result) => {
        if (result) {
          if (
            this.edit.phone.value === '' &&
            this.edit.phone.extension !== ''
          ) {
            this.isPhone = true
          } else {
            if (!this.user.is_user_active && this.user.is_admin) {
              this.is_role_admin = true
            } else if (!this.user.is_user_active && !this.user.is_admin) {
              this.onConfirmRemoveModal()
            } else {
              this.updateUserCompanies()
            }
            this.isPhone = false
          }
        }
      })
    },
    async getCompaniesRoleMaster() {
      await this.$profileProvider
        .getCompaniesRoleMasterData(this.$route.params.id, this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('result', data)
              this.user = {
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone !== null ? data.phone : this.user.phone,
                mobile: data.mobile !== null ? data.mobile : this.user.mobile,
                default_language: data.default_language.toUpperCase(),
                is_user_active: data.is_user_active,
                is_old_user: data.is_user_active,
                is_printing: data.is_use_product_printing,
                is_level_one: data.is_level_one,
                is_admin: data.is_role_admin,
                role_user_open_id:
                  data.role_user_open_id !== null ? data.role_user_open_id : '',
                role_email: data.role_email !== null ? data.role_email : '',
                role_name:
                  data.role_first_name !== null && data.role_last_name !== null
                    ? {
                        th:
                          data.role_first_name.th +
                          ' ' +
                          data.role_last_name.th,
                        en:
                          data.role_first_name.en +
                          ' ' +
                          data.role_last_name.en,
                      }
                    : {},
              }
              data.roles.forEach((e) => {
                if (
                  (this.user.is_level_one && e.role_id !== 2) ||
                  !this.user.is_level_one
                ) {
                  this.roles.push({
                    index: e.role_id,
                    name: e.name,
                    isActive: e.is_active,
                  })
                }
              })

              if (!this.user.is_printing) {
                this.roles = this.roles.filter((a) => a.index !== 5)
              }
            }
          },
          (error) => {
            console.log('getCompaniesRoleMaster', error.responsed)
          }
        )
    },
    async updateUserCompanies() {
      this.edit.is_user_active = this.user.is_user_active
      this.edit.default_language = this.user.default_language.toUpperCase()
      await this.$profileProvider
        .updateUserCompaniesMaster(
          this.$route.params.id,
          this.userProfile.id,
          this.edit
        )
        .then(
          (result) => {
            if (result.status === 204) {
              this.isEditUser = false
              this.roles = []
              this.getCompaniesRoleMaster()
            }
          },
          (error) => {
            console.log('updateUserCompanies', error.responsed)
          }
        )
    },
    async updateUserCompaniesRole() {
      this.rolesEdit = this.editRoles.filter((a) => a.isActive === true)
      console.log('roles', this.roles)
      if (
        this.roles.find((a) => a.index === 2) &&
        this.rolesEdit.length === 0
      ) {
        this.errormessage = 'กรุณากำหนดสิทธิการใช้งานด้วยค่ะ'
        this.isError = true
      } else {
        await this.$profileProvider
          .updateUserCompaniesRoleById(
            this.$route.params.id,
            this.userProfile.id,
            this.rolesEdit
          )
          .then(
            (result) => {
              if (result.status === 204) {
                this.roles = []
                this.getCompaniesRoleMaster()
                this.isEditRole = false
                this.isError = false
              }
            },
            (error) => {
              console.log('updateUserCompaniesRole', error.responsed)
            }
          )
        console.log('rolesEdit', this.rolesEdit)
      }
    },
    async updateUserCompaniesRoleAdmin() {
      this.roleAdmin.push(
        {
          user_open_id: this.$route.params.id,
          role_id: 2,
        },
        {
          user_open_id: this.user.role_user_open_id,
          role_id: 1,
        }
      )
      console.log(this.roleAdmin)

      await this.$profileProvider
        .updateUserCompaniesRoleAdminById(this.userProfile.id, this.roleAdmin)
        .then(
          (result) => {
            if (result.status === 204) {
              this.roles = []
              this.getCompaniesRoleMaster()
            }
          },
          (error) => {
            console.log('updateUserRoleAdmin', error.responsed)
          }
        )
    },
    onUpdateRole() {
      this.isEditRole = false
      this.roles = this.editRoles
    },
    onChangeAdmin() {},
    oncancel() {
      this.isEditUser = false
      if (this.is_role_admin && !this.user.is_user_active) {
        this.is_role_admin = false
      }
      this.user.is_user_active = this.user.is_old_user
      this.isPhone = false
    },
    onChangeLanguage(lang) {
      this.user.default_language = lang
    },
  },
}
