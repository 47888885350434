
import asideComponent from '@/components/template/aside-user.vue'
// import InputText from '@/components/share/input-text.vue'
import TableCostcenter from '@/components/company/table-costcenter.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'CompanySetting',
  components: {
    'aside-component': asideComponent,
    // 'input-text': InputText,
    'table-costcenter': TableCostcenter,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isCreateCostcenter: true,
      isShowCreateDepartment: false,
      isStatus: true,
      isBanner: false,
      status_department: '',
      status_costcenter: 'Active',
      filterDepartment: '',
      deptCollapse: [],

      info: {
        company_code: '',
        name: {
          th: '',
          en: '',
        },
        accounts_id: '',
      },
      filterResult: [],
      costcenter: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    filterDepartment: {
      handler: function (val) {
        let result = this.deptSlot.filter(
          (element) =>
            element.deptId.toUpperCase().includes(val.toUpperCase()) ||
            element.nameTH.toUpperCase().includes(val.toUpperCase()) ||
            element.nameEN.toUpperCase().includes(val.toUpperCase())
        )
        result.forEach((element) => {
          element.isSlot = false
          element.isEdit = false
          element.height = ''
        })
        this.filterResult = result
      },
      deep: true,
    },
  },
  mounted() {
    this.getCompaniesInfoById()
    this.getCompaniesLevelControl()
  },
  created() {
    this.filterResult = this.deptSlot
  },
  methods: {
    onCreateCostcenter(deptId) {
      this.$router.push({
        path: '/company/setting/costcenter/' + deptId,
      })
    },
    async getCompaniesLevelControl() {
      await this.$profileProvider
        .getCompaniesLevelControlData(
          this.userProfile.id,
          this.status_department,
          this.status_costcenter
        )
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('resultdata', result.data)
              this.data = {
                company_level: data.company_level,
              }
              if (data.company_level === 2) {
                let item_level_two = data.data_level_two.items
                console.log('item_level_two', item_level_two)
                if (item_level_two != undefined && item_level_two.length) {
                  item_level_two.forEach((element) => {
                    this.costcenter.push({
                      id: element.id,
                      code: element.code,
                      name: {
                        th: element.name.th,
                        en: element.name.en,
                      },
                      status: element.status === 'Active' ? true : false,
                    })
                  })
                }
              }
            }
          },
          (error) => {
            console.log('getCompaniesLevelControl', error.responsed)
          }
        )
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.info = {
                company_code: data.company_code,
                name: data.name,
                account_id: data.accounts[0].id,
                offline_id: data.account_id_default,
              }
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
  },
}
