
// import CompanySetting2Level from '../../pages/company/setting/create2level.vue'
export default {
  name: 'MainCategoryList',
  components: {},
  props: {
    condition: { type: Object, default: Object },
    category: { type: Array, default: Array },
    categoryName: { type: Object, default: Object },
    categorySEOName: { type: Object, default: Object },
    subCategory: { type: Array, default: () => [] },
    active: { type: String, default: '' },
    isSubcat: { type: Boolean, default: false },
  },
  // async fetch() {
  //   if (process.server) {
  //     this.onSlide()
  //     if (this.subCategory.length > 0) {
  //       this.onSlideSubcat()
  //     }
  //     // console.log('test', this.categories)
  //     this.responsive()
  //   }
  // },
  data() {
    return {
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      activeSeeAll: false,
      activeCategory: String,
      selectedCategory: null,
      categoryLv: [],
      // ref: '',
      isActivePrev: false,
      isActiveNext: false,
      isActiveSubcatPrev: false,
      isActiveSubcatNext: false,
      activeNextCount: 0,
      swiperOption: {
        slidesPerGroup: 3,
        on: {
          resize: () => {
            this.$refs['swiper-category'].$swiper.params.slidesPerView =
              this.responsive()
            this.$refs['swiper-category'].$swiper.update()
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOptionSubcat: {
        slidesPerGroup: 3,
        on: {
          resize: () => {
            this.$refs['swiper-sub-category'].$swiper.params.slidesPerView =
              this.responsiveSubcat()
            this.$refs['swiper-sub-category'].$swiper.update()
          },
        },
        pagination: {
          el: '.swiper-subcat-pagination',
          clickable: true,
        },
      },
      selectedCategoryName: '',
    }
  },
  computed: {
    categories() {
      return this.category.filter(
        (element) => element.id !== this.activeCategory
      )
    },
  },
  watch: {
    category: {
      handler: function (val) {
        this.selectedCategory = val.find(
          (element) => element.id === this.activeCategory
        )
      },
      deep: true,
    },
    categories: {
      handler: function () {
        this.$refs['swiper-category'].$swiper.params.slidesPerView =
          this.responsive()
      },
      deep: true,
    },
    subCategory: {
      handler: function () {
        this.$refs['swiper-sub-category'].$swiper.params.slidesPerView =
          this.responsiveSubcat()
      },
      deep: true,
    },
    active: {
      handler: function (val) {
        this.activeCategory = val
        this.selectedCategory = this.category.find(
          (element) => element.id === this.activeCategory
        )
      },
    },
    selectedCategory: {
      handler: function (val) {
        if (val)
          this.selectedCategoryName = val.display_name[this.$i18n.locale]
            .split('/')
            .join(' ')
        console.log('selectedCategory', val)
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    this.swiperOption.spaceBetween = 0
    this.swiperOption.loop = false
    this.swiperOptionSubcat.spaceBetween = 0
    this.swiperOptionSubcat.loop = false
    this.activeCategory = this.active
  },
  mounted() {
    this.init()
    this.onSlide()
    if (this.subCategory.length > 0) {
      this.onSlideSubcat()
    }
    this.responsive()
  },
  methods: {
    init() {
      this.activeCategory = this.active
      this.selectedCategory = this.category.find(
        (element) => element.id === this.activeCategory
      )
      if (this.$utils.isObjectValid(this.categories)) {
        this.$refs['swiper-category'].$swiper.params.slidesPerView =
          this.responsive()
      }

      if (this.$utils.isObjectValid(this.subCategory)) {
        this.$refs['swiper-sub-category'].$swiper.params.slidesPerView =
          this.responsiveSubcat()
      }
    },
    isGotoCategoryLevel2(subCategoryLevel2) {
      return (
        this.$utils.isObjectValid(this.selectedCategory) &&
        this.$utils.isObjectValid(this.selectedCategory.meta) &&
        this.$utils.isObjectValid(subCategoryLevel2) &&
        this.$utils.isObjectValid(subCategoryLevel2.meta)
      )
    },
    gotoCategoryLevel2(subCategoryLevel2) {
      this.$router.push({
        path: this.$i18n.path(
          `category/${this.categorySEOName[this.$i18n.locale]}/${
            this.selectedCategory.meta.seo_name[this.$i18n.locale]
          }/${subCategoryLevel2.meta.seo_name[this.$i18n.locale]}`
        ),
      })
    },
    gotoDepartment() {
      this.$router.push({
        path: this.$i18n.path(
          `category/${this.categorySEOName[this.$i18n.locale]}`
        ),
      })
    },
    displayNameCategory(displayName) {
      return this.$utils.isObjectValid(displayName)
        ? displayName[this.$i18n.locale].split('/').join(' ')
        : ''
    },
    onCloseSeeAll() {
      this.activeSeeAll = false
    },
    responsive() {
      let slidesPerView = 0
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth < 767) {
        slidesPerView = 3
      } else if (windowWidth > 767 && windowWidth < 1199) {
        slidesPerView = 4
      } else {
        slidesPerView = 9
      }
      this.activeNextCount = slidesPerView
      return slidesPerView
    },
    responsiveSubcat() {
      let slidesSubcatPerView = 0
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth < 767) {
        slidesSubcatPerView = 3
        this.swiperOptionSubcat.slidesPerGroup = 6
      } else if (windowWidth > 767 && windowWidth < 1199) {
        slidesSubcatPerView = 8
        this.swiperOptionSubcat.slidesPerGroup = 9
      } else {
        slidesSubcatPerView = 12
        this.swiperOptionSubcat.slidesPerGroup = 12
      }
      if (this.subCategory.length < slidesSubcatPerView) {
        this.isActiveSubcatNext = false
      }
      return slidesSubcatPerView
    },
    onSlide() {
      this.isActivePrev =
        this.$refs['swiper-category'].$swiper.activeIndex === 0 ? false : true
      this.isActiveNext =
        this.$refs['swiper-category'].$swiper.activeIndex ===
        this.categories.length - this.responsive()
          ? false
          : true
    },

    next() {
      this.$refs['swiper-category'].$swiper.slideNext()
    },
    prev() {
      this.$refs['swiper-category'].$swiper.slidePrev()
    },
    async onSlideSubcat() {
      this.isActiveSubcatPrev =
        this.$refs['swiper-sub-category'].$swiper.activeIndex === 0
          ? false
          : true
      this.isActiveSubcatNext =
        this.$refs['swiper-sub-category'].$swiper.activeIndex ===
        this.subCategory.length - this.responsiveSubcat()
          ? false
          : true
    },
    nextSubcat() {
      this.$refs['swiper-sub-category'].$swiper.slideNext()
    },
    prevSubcat() {
      this.$refs['swiper-sub-category'].$swiper.slidePrev()
    },
    // onActive(item) {
    //   if (
    //     this.$utils.isObjectValid(item) &&
    //     this.$utils.isObjectValid(item.meta)
    //   ) {
    //     this.$router.push({
    //       path: this.$i18n.path(
    //         `category/${this.categorySEOName[this.$i18n.locale]}/${
    //           item.meta.seo_name[this.$i18n.locale]
    //         }`
    //       ),
    //     })
    //   }
    // },
    // onCategory(id) {
    //   this.$router.push({
    //     path: this.$nuxt._route.params.department + '/' + id,
    //   })
    // },
    onSubCategory(index) {
      this.active = index
    },
    onImageCategory(sku) {
      console.warn('sku', sku)
      if (sku.images !== undefined && sku.images.length > 0) {
        return sku.images.some((q) => q.size === 'thumbnail')
          ? sku.images.find((q) => q.size === 'thumbnail').url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
  },
}
