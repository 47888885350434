
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import TableReportBudget from '@/components/company/table-report-budget.vue'
import { mapGetters } from 'vuex'
import { exportExcelBudgetReportDocument } from '@/plugins/export-excel.js'

export default {
  layout: 'main',
  name: 'CompanyReportBudget',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    TableReportBudget,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmModal: false,
      isShowCreateDepartment: false,
      isStatus: true,
      isBanner: false,
      status_department: 'Active',
      //status_costcenter: 'Active',
      status_costcenter: '',
      filterDepartment: '',
      deptCollapse: [],
      filterResult: [],
      department: {
        id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        company_id: '',
        update_by: 'OnlineEpro',
      },
      deptSlot: [],
      data: {
        company_level: '',
      },
      info: {
        company_code: '',
        name: {
          th: '',
          en: '',
        },
        accounts_id: '',
      },
      isDept: false,
      isError: false,
      item_level_three: [],
      dept_id: '',
      costcenter: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    filterDepartment: {
      handler: function (val) {
        let result = this.deptSlot.filter(
          (element) =>
            element.deptId.toUpperCase().includes(val.toUpperCase()) ||
            element.name.th.toUpperCase().includes(val.toUpperCase()) ||
            element.name.en.toUpperCase().includes(val.toUpperCase())
        )
        result.forEach((element) => {
          element.isSlot = false
          element.height = ''
        })
        this.filterResult = result
      },
      deep: true,
    },
  },
  created() {
    // this.filterResult = this.deptSlot
  },
  mounted() {
    this.getCompaniesInfoById()
    this.getBudgetReport()
    this.$nextTick(() => {
      this.filterResult = this.deptSlot
    })
  },
  methods: {
    onExpandDept(index) {
      const data = this.filterResult[index] // this.deptSlot[index]
      data.isSlot = !data.isSlot
      console.log('!data.isSlot', !data.isSlot)
      this.department.name = {}
      if (!data.isSlot) {
        data.height = ''
      } else {
        data.height =
          document.getElementById('deptCollapse_' + index).clientHeight + 6
      }
      console.log(index)
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(data)
              this.info = {
                company_code: data.company_code,
                name: data.name,
                company_level: data.company_level,
                budget_level: data.budget.control_level,
                budget_control: data.budget.period_type,
              }
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
    async getBudgetReport() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      await this.$reportProvider.getBudgetReportData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((a, index) => {
              this.costcenter = []
              a.periods.forEach((e) => {
                this.costcenter.push({
                  budget_id: a.id,
                  month: e.period_id,
                  department_id:
                    a.control_level === 'Department'
                      ? a.dept_code
                      : a.costcenter_code,
                  budget_control: this.getBudgetControl(
                    a.period_type,
                    e.period_id
                  ),
                  control_level: a.control_level,
                  year: a.year,
                  budget_start: e.original_amt,
                  budget_current: e.budget_amt,
                  budget_used: e.used_amt,
                  budget_left: e.remain_amt,
                  period_type: a.period_type,
                })
              })

              this.deptSlot.push({
                id: index + 1,
                deptId:
                  a.control_level === 'Department'
                    ? a.dept_code
                    : a.costcenter_code,
                name:
                  a.control_level === 'Department'
                    ? a.dept_name
                    : a.costcenter_name,
                display:
                  a.control_level === 'Department'
                    ? a.dept_name
                    : a.costcenter_name,
                isActive: true,
                isStatus: true,
                isSlot: false,
                height: '',
                control_level: a.control_level,
                period_type: a.period_type,
                costcenter: this.costcenter,
              })
            })
            console.warn('this.deptSlot :>> ', this.deptSlot)
          }
        },
        (error) => {
          console.log('getBudgetReport', error.responsed)
        }
      )
    },
    getBudgetControl(type, id) {
      let budget = []
      switch (type) {
        case 'Month':
          const monthNames = [
            'January',
            'Febuary',
            'March',
            'April',
            'May',
            'June',
            'July',
            'Augest',
            'September',
            'October',
            'November',
            'Decumber',
          ]
          budget = monthNames[id - 1]
          break
        case 'Haft':
          budget = id < 2 ? 'ครึ่งปีแรก' : 'ครึ่งปีหลัง'
          break
        case 'Quarter':
          budget =
            id === 1
              ? 'ไตรมาส 1'
              : id === 2
              ? 'ไตรมาส 2'
              : id === 3
              ? 'ไตรมาส 3'
              : id === 4
              ? 'ไตรมาส 4'
              : ''
          break
        case 'Year':
          budget = 'ปี'
          break
      }
      return budget
    },
    async exportExcel() {
      exportExcelBudgetReportDocument(this.deptSlot)
    },
  },
}
