
import DonutChartsComponent from '@/components/share/donut-charts'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'AppreverB2B',
  components: {
    // 'select-basic': SelectBasic,
    'donut-chart': DonutChartsComponent,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      total: 0,
      adminReject: 0,
      progress: 0,
      waiting: 0,
      companyMaster: {
        name: '',
        logo_url: '',
      },
      account: {
        companyId: '',
        companyName: {
          th: '',
          en: '',
        },
        firstName: '',
        lastName: '',
        profileImage: '',
        last_active: '',
      },
      companyItems: [
        {
          text: 'เลือกหน่วยงาน',
          value: '',
        },
      ],
      budget: {
        value: 950000,
        max: 1000000,
        variant: 'success',
      },
      selectedMonth: '',
      sortByMonth: [
        {
          text: this.$t('month.01'),
          value: '01',
        },
        {
          text: this.$t('month.02'),
          value: '02',
        },
        {
          text: this.$t('month.03'),
          value: '03',
        },
        {
          text: this.$t('month.04'),
          value: '04',
        },
        {
          text: this.$t('month.05'),
          value: '05',
        },
        {
          text: this.$t('month.06'),
          value: '06',
        },
        {
          text: this.$t('month.07'),
          value: '07',
        },
        {
          text: this.$t('month.08'),
          value: '08',
        },
        {
          text: this.$t('month.09'),
          value: '09',
        },
        {
          text: this.$t('month.10'),
          value: '10',
        },
        {
          text: this.$t('month.11'),
          value: '11',
        },
        {
          text: this.$t('month.12'),
          value: '12',
        },
      ],
      chartData: {
        datasets: [{ data: [] }],
        // labels: [
        //   'รออนุมัติ',
        //   'รอแก้ไข',
        //   'รออนุมัติจากผู้ดูแลระบบ',
        //   'กำลังจัดส่ง',
        //   'จัดส่งสำเร็จ',
        //   'ยกเลิกคำสั่งซื้อ',
        // ],
        // datasets: [
        //   {
        //     backgroundColor: [
        //       '#ff495d',
        //       '#54d2d2',
        //       '#0171cd',
        //       '#01a0df',
        //       '#00996b',
        //       '#ebebeb',
        //     ],
        //     data: [],
        //   },
        // ],
      },
      options: {
        cutoutPercentage: 80,
        tooltips: {
          mode: 'index',
          bodySpacing: 2,
          bodyFontFamily: 'Kanit',
          bodyFontSize: 12,
          padding: 10,
        },
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      news: [],
      slide: 0,
    }
  },
  created() {
    this.selectedMonth = (new Date().getMonth() + 1).toString()
  },
  computed: {
    ...mapGetters('authen', ['openId', 'accessToken']),
    ...mapGetters('user', ['userProfile', 'userInfo']),
  },
  watch: {
    slide: function (value) {
      console.log(value)
    },
  },
  mounted() {
    this.getCompaniesById()
    this.getOrderUserDashboard()
    this.getNewsDashboard()
    this.getUserCompanyRequesterlineById()
  },
  methods: {
    onSelectSort(data) {
      console.warn('object :>> ', data)
      this.selectedMonth = data.value
      this.getOrderUserDashboard()
    },
    prev() {
      this.$refs.news.prev()
    },
    next() {
      this.$refs.news.next()
    },
    filterChart() {
      // this.chartData = {
      //   labels: ['รอการพิจารณาอนุมัติ', 'รอการแก้ไข', 'อนุมัติเบื้องต้น'],
      //   datasets: [
      //     {
      //       backgroundColor: ['#ff495d', '#d2b2ed', '#0171cd'],
      //       data: [40, 20, 40],
      //     },
      //   ],
      // }
    },
    async getNewsDashboard() {
      let params = new URLSearchParams()
      params.append('page', 1)
      params.append('pageSize', 20)
      await this.$profileProvider
        .getNewsDashboardData(this.userProfile.id, params)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              if (data.items !== null) {
                data.items.forEach((element) => {
                  this.news.push({
                    title: element.title,
                    createOn: this.dateDiff(element.update_info.timestamp),
                    first_name: element.first_name[0],
                    last_name: element.last_name[0],
                  })
                })
              }
            }
          },
          (error) => {
            console.log('getNews', error.responsed)
          }
        )
    },
    async getOrderUserDashboard() {
      let params = this.MapParams()
      await this.$reportProvider.getOrderUserDashboardData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.order_users !== null) {
              this.chartData = {}
              this.total = data.total
              let _chartData = []
              let order = data.order_users.filter((a) => a.status !== 7)
              for (let i = 1; i < 7; i++) {
                let result = order.find((a) => a.status === i)
                result !== undefined
                  ? _chartData.push(result.percent)
                  : _chartData.push(0)

                this.adminReject =
                  i === 2 && result !== undefined ? result.total : 0

                this.waiting =
                  i === 1 && result !== undefined ? result.total : 0
              }
              this.chartData = {
                labels: [
                  'รอดำเนินการ',
                  'กำลังดำเนินการ',
                  'เตรียมจัดส่ง',
                  'กำลังจัดส่ง',
                  'จัดส่งสำเร็จ',
                  'เสร็จสมบูรณ์',
                ],
                datasets: [
                  {
                    backgroundColor: [
                      '#ff495d',
                      '#54d2d2',
                      '#0171cd',
                      '#01a0df',
                      '#00996b',
                      '#ebebeb',
                    ],
                    data: _chartData,
                  },
                ],
              }

              let result = data.order_users.filter((a) => a.status === 7)
              this.progress = result !== undefined ? result[0]?.total : 0
              this.getOrderUserOnlyDashboard()
            } else {
              this.total = 0
              this.progress = 0
              this.waiting = 0
              this.adminReject = 0
              this.chartData = { datasets: [{ data: [0, 0, 0, 0, 0, 0] }] }
            }
          }
        },
        (error) => {
          console.log('getOrderUserDashboard', error.responsed)
        }
      )
    },
    dateDiff(update_date) {
      let now = new Date().toISOString()
      let date2 = new Date(now)
      let date1 = new Date(update_date)
      let hours = Math.round((date2 - date1) / 36e5)
      let result = hours + ' hours ago by '
      console.log('object :>> ', result)
      return result
    },
    async getCompaniesById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.companyMaster = {
                logo_url: data.company_logo_url,
                name: data.name.th,
              }
            }
          },
          (error) => {
            console.log('getCompaniesById', error.responsed)
          }
        )
    },
    async getUserCompanyRequesterlineById() {
      await this.$profileProvider
        .getUserCompanyRequesterlineDataById(this.openId, this.userProfile.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.account = {
                companyId: data.company_id,
                companyName: data.company_name,
                firstName: data.first_name,
                lastName: data.last_name,
                profileImage: data.profile_image,
                last_active: data.last_active,
              }
            }
          },
          (error) => {
            console.log('getUserCompanyRequesterlineById', error.responsed)
          }
        )
    },
    MapParams() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('user_open_id', this.openId)
      params.append('month', this.selectedMonth)
      return params
    },
    async getOrderUserOnlyDashboard() {
      let params = this.MapParams()
      await this.$reportProvider.getOrderUserOnlyDashboardData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            this.adminReject =
              data.find((a) => a.status === 'Reject')?.total ?? 0
            this.progress =
              data.find((a) => a.status === 'InProgress')?.total ?? 0
            this.waiting = data.find((a) => a.status === 'Waiting')?.total ?? 0
          }
        },
        (error) => {
          console.log('getOrderUserOnlyDashboard', error.responsed)
        }
      )
    },
  },
}
