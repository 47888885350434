
import { mapGetters } from 'vuex'

export default {
  name: 'TablereportGR',
  props: {
    items: { type: Array, default: () => [] },
    itemCount: { type: Number, default: () => 0 },
    page: { type: Number, default: () => 0 },
  },
  data() {
    return {
      search: '',
      page_number: '',
      // itemCount: 100,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'index',
          label: this.$t(`company-report-gr.labelNo`),
          class: 'text-center w-70',
          sortable: false,
        },
        {
          key: 'account_offline_id',
          label: this.$t(`company-report-gr.labelCustomerID`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'goods_received_id',
          label: this.$t(`company-report-gr.labelGRID`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },

        {
          key: 'order_id',
          label: this.$t(`company-report-gr.labelPONumber`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'goods_received_status',
          label: this.$t(`company-report-gr.labelStatus`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'goods_received_date',
          label: this.$t(`company-report-gr.labelPODate`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'goods_received_approval_date',
          label: this.$t(`company-report-gr.labelApprovalDate`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
          formatter: (value, key, item) => {
            if (value === '0001-01-01T00:00:00Z') {
              return ''
            }
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'contact_name',
          label: this.$t(`company-report-gr.labelPurchaser`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'approveName',
          label: this.$t(`company-report-gr.labelApprover`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'costcenter_code',
          label: this.$t(`company-report-gr.labelDivisionID`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'costcenter_name',
          label: this.$t(`company-report-gr.labelDivisionName`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'grand_total_amt',
          label: this.$t(`company-report-gr.labelTotalTax`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.items.length / this.perPage)) {
        last = this.items.length
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.items.length === 0) {
        first = 0
        last = 0
      } else if (this.items.length < this.perPage) {
        last = this.items.length
      }
      return first + '-' + last
    },
  },
  watch: {
    perPage: {
      handler: function () {
        this.currentPage = 1
        this.$emit('onGetHeight')
      },
      deep: true,
    },
    currentPage: {
      handler: function () {
        this.$emit('onGetHeight')
      },
      deep: true,
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
    },
    // onPreview() {
    //   // this.$nextTick(() => {
    //   if (this.currentPage <= Math.ceil(this.itemCount / this.perPage)) {
    //     this.currentPage++
    //     console.log('onPreview :>> ', this.currentPage)
    //     this.$emit('getOrderReport', this.currentPage, false, false)
    //   }
    //   // })
    // },
    // onLast() {
    //   // this.$nextTick(() => {
    //   if (this.currentPage > 1) {
    //     this.currentPage--
    //     this.$emit('getOrderReport', this.currentPage, false, true)
    //   }
    //   //})
    // },
    grDetail(id) {
      let gr_id = this.items.find((a) => a.goods_received_id === id)?.id
      return this.$i18n.path(`company/gr/${gr_id}?mode=view`)
    },
    orderDetail(id) {
      let order_id = this.items.find((a) => a.order_id === id)?.order_object_id
      return this.$i18n.path(`account/order/${order_id}?mode=view`)
    },
  },
}
