
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import SekletonResult from '@/components/skeleton/product-result.vue'
import notfound from '@/components/share/notfound.vue'

import { /* mapActions, */ mapState, mapGetters } from 'vuex'

import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'SeeAllByCategoryPage',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    // 'swiper-image': SwiperImage,
    notfound,
  },
  head() {
    return {
      // title: this.namepage,
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: this.pageContent,
      //   },
      // ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(
      //         `widget/${this.$nuxt._route.params.category}/${this.$route.params.id}`
      //       ),
      //   },
      // ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      namepage: '',
      // breadcrumbItems: [
      //   {
      //     text: 'Home',
      //     href: '/',
      //   },
      //   {
      //     text: this.namepage,
      //     href: this.$i18n.path(`widget/${this.$nuxt._route.params.category}`),
      //   },
      //   {
      //     text: this.$nuxt._route.params.id,
      //     href: this.$i18n.path(
      //       `widget/${this.$nuxt._route.params.category}/${this.$route.params.id}`
      //     ),
      //   },
      // ],
      sortBy: [
        {
          text: 'Default...',
          value: '0',
        },
        {
          text: 'PRICE LOW > HIGH',
          value: '1',
        },
        {
          text: 'PRICE HIGH > LOW',
          value: '2',
        },
        {
          text: 'A > Z',
          value: '3',
        },
        {
          text: 'Z > A',
          value: '4',
        },
      ],
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      totalCount: '',
      display: '',
      categoryId: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeleton: true,
      skeletonPage: true,
      showItems: false,
      isNotFound: false,
      overlayImages: [],
    }
  },
  created() {
    this.seoNameLevelOne = this.$nuxt._route.params.id
    this.$nuxt.$on('returnCategory', (data) => {
      console.log('sendddddddddddddddddddddddddddddd')
      this.setCategory(data)
    })
    this.$nuxt.$on('onCategoryReady', () => {
      console.log('is readyyyyyyyyyyyyy')
      this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    })
  },

  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    breadcrumbItems() {
      return [
        {
          text: this.$t(`widgetPage.home`),
          href: '/',
        },
        {
          text: this.namepage,
          href: this.$i18n.path(`widget/${this.$nuxt._route.params.category}`),
        },
        {
          text: this.$nuxt._route.params.id,
          href: this.$i18n.path(
            `widget/${this.$nuxt._route.params.category}/${this.$route.params.id}`
          ),
        },
      ]
    },
    generateListName() {
      let name = ''
      switch (this.$route.params.category) {
        case 'new-arrival':
          name = 'New Arrivals'
          break
        case 'featured-product':
          name = 'Featured Productss'
          break
        case 'promotion':
          name = 'Promotion'
          break
        default:
          name = 'Best Sellers'
          break
      }
      return name
    },
  },
  watch: {
    // perPage: {
    //   handler: function () {
    //     console.log(this.perPage)
    //     await this.getProduct()
    //   },
    //   deep: true,
    // },
    async categoryId() {
      await this.getAttributeProductHomePage()
      // Bam Note: function selectSort มีสองอันชื่อเหมือนกันเลย เลือกใช้แค่อันใดอันหนึ่งครับ
      // await this.getProduct()
      // this.attribute = await Object.assign([], [...new Set(this.attribute)])
      console.log('this.attribute', this.attribute)
    },
  },
  updated() {
    if (!this.skeleton) {
      this.skeletonPage = false
    }
    this.$_onGetInsider({
      page: {
        type: 'Other',
        custom: {
          category_name: null,
        },
      },
    })
    this.$_onInsiderResult({
      result: [...(this.items || [])],
    })
  },
  mounted() {
    console.log('params', this.$route.params.category)
    this.selectName()
    this.lang = this.$store.state.locale
    if (this.categoryId === '') {
      this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    }
  },
  beforeDestroy() {
    // $off method will turn off the event listner
    this.$nuxt.$off('returnCategory')
    this.$nuxt.$off('onCategoryReady')
  },
  methods: {
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    // async emitAttribute(value) {
    //   this.paramAttribute = value
    // },
    async selectSort(event) {
      this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
      if (event.value === '0') this.sort = ''
      else if (event.value === '1') {
        this.sort = 'price-ascending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
      } else if (event.value === '2') {
        this.sort = 'price-descending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
      } else if (event.value === '3') {
        this.sort = 'new-descending-' + this.lang
      } else this.sort = 'old-ascending-' + this.lang
      await this.getProduct()
      this.showItems = true
    },
    async getProduct() {
      console.log('start getproduct')
      this.skeleton = true
      this.showItems = false
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createBrandAndAttributeParams()
      console.log('createBrandAndAttributeParams', params.toString())
      let response = ''
      // const response =
      //   await this.$productProvider.getWidgetHomePageProductsSeeAll(params)
      if (this.$route.params.category === 'recommend-for-you') {
        response = await this.$productProvider.getRecommendProductsSeeAll(
          params
        )
      } else {
        response = await this.$productProvider.getWidgetHomePageProductsSeeAll(
          params
        )
      }
      console.log('getWidgetHomePageProductsSeeAll', response)
      if (response.status === 200) {
        this.pageTotal = response.data.totalRecords
        this.items = response.data.items
        // get overlay
        if (this.items.length) {
          let pid = this.items.map((item) => {
            return item.skus[0].sku_code
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          console.log('graphqlResponse', graphqlResponse)
          if (graphqlResponse) {
            this.overlayImages = graphqlResponse
          }
        }

        if (this.items !== null && this.items.length) {
          // ทำ seq เพื่อส่งเข้า gtm event impression
          let startIndex =
            (response.data.pageNo - 1) * response.data.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })
          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.items.map((item) => {
                return {
                  id: item.skus[0].sku_code,
                  price: this.$utils.formatPrice(
                    item.skus[0].price_include_vat,
                    2,
                    true
                  ),
                  list:
                    this.$route.params.category === 'new-arrival'
                      ? 'New Arrivals '
                      : this.$route.params.category === 'featured-product'
                      ? 'Featured Products'
                      : this.$route.params.category === 'promotion'
                      ? 'Promotion'
                      : 'Best Sellers',
                  position: item.seq,
                }
              }),
            },
          })
        }
        console.log('this.items', this.items)
      }
      this.skeleton = false
    },
    async getAttributeProductHomePage() {
      if (this.categoryId) {
        this.skeleton = true
        let attr = []
        console.log('getAttributeProductHomePage')
        let params = await this.createParams()
        console.log('param', params.toString())
        // const response = await this.$productProvider.getwidgetBrandsData(params)
        var response = ''
        if (this.$route.params.category === 'recommend-for-you') {
          response = await this.$productProvider.getRecommendBrandsData(params)
        } else {
          response = await this.$productProvider.getwidgetBrandsData(params)
        }

        console.log('getwidgetBrandsData', response)

        if (response.status === 200) {
          let brandList = []
          console.log(response.data)
          response.data.forEach((element) => {
            brandList.push({
              text: element.display_name[this.lang],
              value: { by: 'brand', value: element.id },
            })
          })

          attr.push({
            title: this.$t('filterPanelComponent.brand'),
            list: brandList,
          })

          // get attribute
          var responseAttribute = ''
          if (this.$route.params.category === 'recommend-for-you') {
            responseAttribute =
              await this.$productProvider.getRecommendAttributeProductHomeData(
                params
              )
          } else {
            responseAttribute =
              await this.$productProvider.getAttributeProductHomeData(params)
          }

          // const responseAttribute =
          //   await this.$productProvider.getAttributeProductHomeData(params)
          if (responseAttribute.status === 200) {
            const attribute = responseAttribute.data
            // console.log('attr', attribute)
            /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
            attribute.forEach((element) => {
              let attributeList = []
              if (this.$utils.anyArrayObjectValid(element.value)) {
                element.value.forEach((value, index) => {
                  if (
                    this.$utils.isObjectValid(value) &&
                    this.$utils.isValid(value[this.lang]) &&
                    !attributeList.some(
                      (q) => q.value.value === value[this.lang]
                    )
                  ) {
                    attributeList.push({
                      text: value[this.lang],
                      value: {
                        index: index,
                        by: element.display_name[this.lang],
                        id: element.id,
                        value: value[this.lang],
                      },
                    })
                  }
                })
              }
              if (this.$utils.anyArrayObjectValid(attributeList)) {
                attr.push({
                  title: element.display_name[this.lang],
                  list: attributeList,
                })
              }
            })
          }
        } else {
          this.showItems = true
        }
        this.attribute = attr
        this.skeleton = false
      } else {
        this.isNotFound = true
        this.skeletonPage = false
      }
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('this.paramAttribute-', this.paramAttribute)
      await this.getProduct()
      this.showItems = true
    },
    async createParams() {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      if (this.$route.params.category === 'new-arrival') {
        params.append('type', 'NewArrivals')
        this.display = 'สินค้าใหม่'
      }
      if (this.$route.params.category === 'best-seller') {
        params.append('type', 'BestSeller')
        this.display = 'สินค้าขายดี'
      }
      if (this.$route.params.category === 'featured-product') {
        params.append('type', 'FeaturedProducts')
        this.display = 'สินค้ายอดนิยม'
      }
      if (this.$route.params.category === 'recommend-for-you') {
        params.append('type', 'Recommended')
        params.append('userOpenId', this.openId)
        this.display = 'Recommended'
      }
      if (this.$route.params.category === 'promotion') {
        params.append('type', 'Promotion')
        this.display = 'สินค้าโปรโมชั่น'
      }
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      if (this.$route.params.category === 'recommend-for-you') {
        params.append('categories', this.categoryId)
      } else {
        params.append('categoryId', this.categoryId)
      }

      params.append('isFree', false)
      console.log('router', this.$route)

      return params
    },
    // selectSort(e) {
    //   // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    //   if (e.value === '0') this.sort = ''
    //   else if (e.value === '1') this.sort = 'price-ascending'
    //   else if (e.value === '2') this.sort = 'price-descending'
    //   else if (e.value === '3') this.sort = 'new-descending'
    //   else this.sort = 'old-ascending'
    //   this.getProduct()
    // },
    async createBrandAndAttributeParams() {
      let params = new URLSearchParams()
      console.log('categoryId', this.categoryId)
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      // params.append('categoryId', this.categoryId)
      if (this.$route.params.category === 'recommend-for-you') {
        params.append('categories', this.categoryId)
      } else {
        params.append('categoryId', this.categoryId)
      }
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      if (this.$route.params.category === 'new-arrival') {
        params.append('type', 'NewArrivals')
      }
      if (this.$route.params.category === 'best-seller') {
        params.append('type', 'BestSeller')
      }
      if (this.$route.params.category === 'featured-product') {
        params.append('type', 'FeaturedProducts')
      }
      if (this.$route.params.category === 'recommend-for-you') {
        params.append('type', 'Recommended')
        params.append('userOpenId', this.openId)
      }
      if (this.$route.params.category === 'promotion') {
        params.append('type', 'Promotion')
      }
      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          data.by === 'brand'
            ? params.append('brands', data.value)
            : params.append('attributes', data.id + '-' + data.value)
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      console.log('this.paramAttribute', this.paramAttribute)
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      return params
    },
    selectName() {
      switch (this.$route.params.category) {
        case 'new-arrival':
          this.namepage = this.$t(`widgetPage.new_arrival`)
          break
        case 'best-seller':
          this.namepage = this.$t(`widgetPage.best_sellers`)
          break
        case 'featured-product':
          this.namepage = this.$t(`widgetPage.trending_products`)
          break
        case 'recommend-for-you':
          this.namepage = this.$t(`widgetPage.recommed_products`)
          break
        case 'promotion':
          this.namepage = this.$t(`index.labelPromotion`)
          break
        default:
          this.namepage = this.$route.params.category
          break
      }
    },
    async setCategory(data) {
      console.log('setCategory')
      console.warn('setCategory :>> ', data)
      if (this.$utils.isObjectValid(data)) {
        if (this.$utils.isObjectValid(data.items)) {
          this.category = data.items.filter((a) => a.status === 'Active')
          this.categoryId = data.id
          this.breadcrumbItems[2].text = data.display_name[this.$i18n.locale]
          console.log('category name', data.display_name[this.$i18n.locale])
        }
      } else {
        // console.log('notfound')
        // this.skeletonPage = false
        // this.skeletonItems = false
        this.isNotFound = true
      }
    },
  },
}
