
export default {
  name: 'BreadCrumbComponent',
  props: {
    items: { type: Array, default: () => [] },
    noBorder: { type: Boolean, default: false },
    updateRount: { type: String, default: '' },
  },
  data() {
    return {
      rounteHistory: this.items,
    }
  },
  watch: {
    updateRount: {
      handler: function () {
        this.rounteHistory = []
        this.rounteHistory = this.$props.items
      },
      deep: true,
    },
  },
  methods: {
    // redirect(url) {
    //   // **Bam Note: เปลี่ยนมา redirect ด้วย $router.push() แทนการ href ใน tag <a> เพราะจะทำให้ทุกครั้งที่กดวิ่งไป request ที่ server ใหม่ทุกครั้ง
    //   this.$router.push(url)
    // },
  },
}
