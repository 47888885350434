
import asideComponent from '@/components/template/aside-user.vue'
import TableManageRole from '@/components/company/table-manage-role.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageRole',
  components: {
    'aside-component': asideComponent,
    // 'input-text': InputText,
    TableManageRole,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      filter: '',
      filterResult: [],
      roleItems: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  mounted() {
    this.getCompaniesUserRolesById()
  },
  created() {},
  methods: {
    onCreateCostcenter(deptId) {
      this.$router.push({
        path: this.$i18n.path('company/setting/costcenter/' + deptId),
      })
    },
    async getCompaniesUserRolesById() {
      await this.$profileProvider
        .getCompaniesUserRolesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('55555', data)
              data.forEach((element, index) => {
                this.roleItems.push({
                  index: index++,
                  user_open_id: element.user_open_id,
                  email: element.email,
                  is_verify: element.is_verify,
                  status: element.status,
                  roles: element.roles,
                })
              })
            }
          },
          (error) => {
            console.log('getCompaniesUserRolesInfoById', error.responsed)
          }
        )
    },
  },
}
