
import ItemsComponent from '@/components/share/item.vue'
export default {
  name: 'ProductsSliderComponent',
  components: {
    item: ItemsComponent,
  },
  props: {
    products: { type: Array, default: () => [] },
    setTime: { type: Number, default: 0 },
    slidestyle: { type: String, default: 'product' },
    open: { type: Boolean, default: false },
    height: { type: String, default: '385px' },
  },
  data() {
    return {
      id: '',
      load: false,
    }
  },
  watch: {
    products: {
      handler: function (val) {
        if (val.length > 0) {
          this.load = false
          this.id = Math.random().toString(36).substring(7)
          const _this = this
          setTimeout(() => {
            _this.GenerateSlide()
            document.getElementById(this.id).style.opacity = '1'
          }, 100)
          window.addEventListener('resize', function () {
            _this.GenerateSlide()
          })
          this.$nextTick(() => {
            document
              .getElementById('prev_' + this.id)
              .classList.remove('active')
            document
              .getElementById('prev_' + this.id)
              .classList.remove('active')
          })
        }
      },
    },
  },
  mounted() {},
  methods: {
    GenerateSlide() {
      let calLength = 0
      let rollBack = 0
      let windowWidth = document.documentElement.clientWidth
      // document.querySelectorAll('.product-wrapper').forEach((el) => {
      let el = document.getElementById(this.id)
      let slidesLength = document
        .getElementById(this.id)
        .getElementsByClassName('product-slide')[0]
        .getElementsByClassName('product-item').length
      switch (el.getAttribute('slide-style')) {
        case 'recommended':
          if (windowWidth < 767) {
            rollBack = 1
            calLength = slidesLength - rollBack
          } else if (windowWidth > 767 && windowWidth < 1199) {
            rollBack = 4
            calLength = slidesLength - rollBack
          } else {
            rollBack = 7
            calLength = slidesLength - rollBack
          }
          break
        case 'categories':
          if (windowWidth < 767) {
            rollBack = 1
            calLength = slidesLength - rollBack
          } else if (windowWidth > 767 && windowWidth < 1199) {
            rollBack = 3
            calLength = slidesLength - rollBack
          } else {
            rollBack = 6
            calLength = slidesLength - rollBack
          }
          break
        case 'product':
          if (windowWidth < 767) {
            rollBack = 2
            calLength = slidesLength - rollBack
          } else if (windowWidth > 767 && windowWidth < 1199) {
            rollBack = 3
            calLength = slidesLength - rollBack
          } else {
            rollBack = 4
            calLength = slidesLength - rollBack
          }
          break
        default:
          if (windowWidth < 767) {
            rollBack = 1
            calLength = slidesLength - rollBack
          } else if (windowWidth > 767 && windowWidth < 1199) {
            rollBack = 2
            calLength = slidesLength - rollBack
          } else {
            rollBack = 3
            calLength = slidesLength - rollBack
          }
          break
      }

      el.querySelectorAll('.product-item').forEach((obj, objIndex) => {
        obj.style.width = 100 / rollBack + '%'
        obj.style.left = obj.clientWidth * objIndex + 'px'
      })
      let itemWidth =
        document
          .getElementById(this.id)
          .getElementsByClassName('product-slide')[0].clientWidth / rollBack
      if (calLength > 0) {
        this.slide(itemWidth, calLength, rollBack)
      } else {
        this.slide(itemWidth, calLength, slidesLength)
      }
      // })
    },
    slide(itemwidth, calLength, rollBack) {
      setTimeout(() => {
        _this.load = true
      }, 100)
      let _this = this
      let items = document
        .getElementById(this.id)
        .getElementsByClassName('product-slide')[0]
      let posX1 = 0
      let posX2 = 0
      let posInitial
      let posFinal
      let threshold = 100
      let slides = items.getElementsByClassName('product-item')
      let slidesLength = slides.length
      let slideSize =
        items.getElementsByClassName('product-item')[0].offsetWidth
      let firstSlide = slides[0]
      //   let lastSlide = slides[slidesLength - 1]
      let index = 0
      //   let allowShift = true
      // reset on resize
      items.style.left = '0px'

      // Mouse
      items.onmousedown = dragStart

      // Touch
      items.addEventListener(
        'touchstart',
        function (event) {
          dragStart(event)
          event.preventDefault()
        },
        { passive: false }
      )

      items.addEventListener(
        'touchend',
        function (event) {
          dragEnd(event)
          event.preventDefault()
        },
        { passive: false }
      )

      items.addEventListener(
        'touchmove',
        function (event) {
          dragAction(event)
          event.preventDefault()
        },
        { passive: false }
      )

      // Click
      document
        .getElementById('prev_' + this.id)
        .addEventListener('click', function () {
          shiftSlide(-1, 'click')
        })
      document
        .getElementById('next_' + this.id)
        .addEventListener('click', function () {
          shiftSlide(1, 'click')
        })

      // Transition
      items.addEventListener('transitionend', checkIndex)

      checkIndex()

      function dragStart(e) {
        e = e || window.event
        // e.preventDefault() // Lock scroll
        posInitial = items.offsetLeft
        if (e.type === 'touchstart') {
          posX1 = e.touches[0].clientX
        } else {
          posX1 = e.clientX
          document.onmouseup = dragEnd
          document.onmousemove = dragAction
        }
      }

      function dragAction(e) {
        e = e || window.event
        items.classList.add('deactive')

        if (e.type === 'touchmove') {
          posX2 = posX1 - e.touches[0].clientX
          posX1 = e.touches[0].clientX
        } else {
          posX2 = posX1 - e.clientX
          posX1 = e.clientX
        }
        items.style.left = items.offsetLeft - posX2 + 'px'
      }

      function dragEnd() {
        posFinal = items.offsetLeft
        if (posFinal - posInitial < -threshold) {
          shiftSlide(1, 'drag')
        } else if (posFinal - posInitial > threshold) {
          shiftSlide(-1, 'drag')
        } else {
          items.style.left = posInitial + 'px'
        }

        document.onmouseup = null
        document.onmousemove = null
        items.classList.remove('deactive')
      }

      function shiftSlide(dir, action) {
        items.classList.add('shifting')
        if (action === 'drag') {
          if (dir === 1) {
            if (index < slidesLength - rollBack) {
              items.style.left = posInitial - slideSize + 'px'
              index++
            } else {
              items.style.left =
                '-' + itemwidth * (slidesLength - rollBack) + 'px'
            }
          } else if (dir === -1) {
            if (firstSlide.getBoundingClientRect().left < 0) {
              items.style.left = posInitial + slideSize + 'px'
              index--
            } else {
              index = 0
              items.style.left = '0px'
            }
          }
        } else {
          posInitial = items.offsetLeft
          if (dir === 1) {
            if (index < slidesLength - rollBack) {
              items.style.left = posInitial - slideSize + 'px'
              index++
            } else {
              items.classList.remove('shifting')
              items.style.left =
                '-' + itemwidth * (slidesLength - rollBack) + 'px'
              index = slidesLength - rollBack
              document
                .getElementById('next_' + _this.id)
                .classList.remove('active')
            }
          } else if (dir === -1) {
            if (index > 0) {
              items.style.left = posInitial + slideSize + 'px'
              index--
            } else {
              items.classList.remove('shifting')
              items.style.left = '0px'
              index = 0
              document
                .getElementById('prev_' + _this.id)
                .classList.remove('active')
            }
          }
        }
      }

      function checkIndex() {
        items.classList.remove('shifting')
        if (index === -1) {
          items.style.left = -(slidesLength * slideSize) + 'px'
          index = slidesLength - 1
        }

        if (index === slidesLength) {
          items.style.left = -(1 * slideSize) + 'px'
          index = 0
        }
        if (calLength > 0) {
          if (index === 0) {
            document
              .getElementById('prev_' + _this.id)
              .classList.remove('active')
          } else {
            document.getElementById('prev_' + _this.id).classList.add('active')
          }
          if (index - calLength === 0) {
            document
              .getElementById('next_' + _this.id)
              .classList.remove('active')
          } else {
            document.getElementById('next_' + _this.id).classList.add('active')
          }
        }
      }
    },
  },
}
