
// import ModalAddon from '@/components/modal/addonModal.vue'
// import modalShippingItems from '@/components/delivery-charge/modalShippingItems.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'OrderItems',
  components: {
    ModalAddon: async () => await import('@/components/modal/addonModal.vue'),
    modalShippingItems: async () =>
      await import('@/components/delivery-charge/modalShippingItems.vue'),
  },
  props: {
    productGroups: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    mode: {
      type: String,
      default: 'view',
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
    isReserveStock: {
      type: Boolean,
      default: false,
    },
    isCartHeader: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    promotion_discounts: {
      type: Array,
      default: Array,
    },
    orderDocument: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      groups: [],
      trackingStatusList: [
        {
          index: 1,
          value: 'InProgress',
        },
        {
          index: 2,
          value: 'ReadyToShip',
        },
        {
          index: 3,
          value: 'Shipping',
        },
        {
          index: 4,
          value: 'Delivered',
        },
        {
          index: 5,
          value: 'Complete',
        },
      ],
      showAddonModal: false,
      addonId: '',
      showShippingModal: false,
      shippingObj: {},
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    editMode() {
      return this.mode === 'edit' ? true : false
    },
    hasAddItemPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        if (
          this.orderDocument.approval_status === 'Reject' ||
          this.orderDocument.approval_status === 'AdminReject'
        ) {
          return this.openId === this.orderDocument.contact_id ? true : false
        } else return false
      } else return false
    },
  },
  watch: {
    productGroups: {
      handler: function () {
        this.groups = this.productGroups
      },
      deep: true,
    },
  },
  mounted() {
    this.groups = this.productGroups
  },
  methods: {
    onFilterWarning(group, key) {
      return group?.warning_messages?.filter((msg) => msg.key === key)[0]
    },
    warningMinimumQty(minimum_quantity) {
      let msg = this.$t('orderItemsComponent.warningMinimumQty')
      return msg.replace(
        '{product.minimum_quantity}',
        minimum_quantity.toString()
      )
    },
    warningMaximumQty(maximum_quantity) {
      let msg = this.$t('orderItemsComponent.warningMaximumQty')
      return msg.replace(
        '{product.maximum_quantity}',
        maximum_quantity.toString()
      )
    },
    stockAvailable(product) {
      let num = this.isReserveStock ? product.reserve_quantity : 0
      return product.is_available_stock
        ? product.onhand - (product.reserve - num)
        : 9999
    },
    changeQty(product) {
      // if (product.quantity != product.reserve_quantity) {
      this.$emit('changeQty', product)
      // }
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    checkActiveDeliveryTrackingStatus(checkIndex, currentStatus) {
      let trackingStatus = this.trackingStatusList.find(
        (q) => q.value === currentStatus
      )
      return trackingStatus !== undefined && trackingStatus.index >= checkIndex
        ? true
        : false
    },
    viewTracking(trackingNo) {
      let url = `${process.env.NUXT_ENV_ORDER_TRACKING_URL}${trackingNo}`
      window.open(url, '_blank')
    },
    calculatePromotionQuantityDiscount(
      originalPrice,
      discountPrice,
      campaignType,
      discountType,
      discountAmt
    ) {
      return campaignType === 'FixedPrice'
        ? `${this.$t('cart.cartItem.labelSave')} ${Math.round(
            ((originalPrice - discountPrice) / originalPrice) * 100
          )}%`
        : discountType === 'Baht'
        ? `${this.$t('cart.cartItem.lableQuantityDiscount')} ฿ ${discountAmt}`
        : `${this.$t('cart.cartItem.lableQuantityDiscount')} ${discountAmt}%`
    },
    highlightActiveTier(activeId, pro) {
      return pro.id === activeId ? true : false
    },
    mergeNewGroup(data) {
      // const items = data.items
      // const promotion_discounts = this.promotion_discounts

      // const withoutAddOn = items.filter(
      //   (item) =>
      //     !promotion_discounts.some((addon) =>
      //       addon.items.some(({ sku_code }) => sku_code === item.sku_code)
      //     )
      // )

      // const result = promotion_discounts.map((addon) => {
      //   const matchedItems = items.filter((item) =>
      //     addon.items.some(({ sku_code }) => sku_code === item.sku_code)
      //   )
      //   return {
      //     promotion_discounts: addon,
      //     items: matchedItems,
      //   }
      // })

      // const mergedResult = [
      //   ...result.filter((item) => item.items.length > 0),
      //   { promotion_discounts: '', items: withoutAddOn },
      // ]

      // const mergedOrders = mergedResult.sort((a, b) => {
      //   const promotionA = a.promotion_discounts.id || ''
      //   const promotionB = b.promotion_discounts.id || ''
      //   return promotionA.localeCompare(promotionB)
      // })
      // console.log('mergedOrders', mergedOrders)
      // return mergedOrders

      // dcc
      console.log('mergeNewGroup', data)
      const items = [...data.items]
      const premiums = [...data.premiums]
      const promotion_discounts = data?.promotion_discounts
        ? [...data.promotion_discounts]
        : []
      const result = promotion_discounts
        ? promotion_discounts.map((addon) => {
            const addonItems = Array.isArray(addon.items) ? addon.items : []
            return {
              promotion_discounts: addon,
              items: addonItems,
              premiums: [...data.premiums],
            }
          })
        : []
      console.log('promotion_discounts', promotion_discounts)
      const mergedResult = [
        ...result.filter((item) => item.items.length > 0),
        { promotion_discounts: '', items: items, premiums: premiums },
      ]

      const mergedOrders = mergedResult.sort((a, b) => {
        const promotionA = a.promotion_discounts.id || ''
        const promotionB = b.promotion_discounts.id || ''
        return promotionA.localeCompare(promotionB)
      })
      console.log('mergedOrders', mergedOrders)

      return mergedOrders
    },
    async onModalAddon(addonId) {
      this.showAddonModal = true
      this.addonId = addonId
      console.log('addonId', addonId)
    },
    // async onAddItemsAddon(items) {
    //   console.log('onAddItemsAddon', items)
    // },
    onMap(e) {
      const mapItems = this.mergeNewGroup(e)
        .map((orderObj) => orderObj.items)
        .flat()
      console.log('onMap', mapItems)

      return {
        ...e,
        items: [...mapItems],
      }
    },
  },
}
