
export default {
  data() {
    return {}
  },
  mounted() {
    console.log(process.env.NODE_ENV)
    // console.log(process.env.NUXT_ENV_BASE_URL)
    // console.log(process.env.NUXT_ENV_FILE)
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => this.$nuxt.$loading.finish(), 500)
    })
  },
  methods: {},
}
