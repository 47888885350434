
import asideComponent from '@/components/template/aside-user.vue'
import Multiselect from 'vue-multiselect'
import notfound from '@/components/share/notfound.vue'
// import bizCardProvider from '@/plugins/service/biz-card-provider'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'PrintingTemplate',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    notfound,
  },
  data() {
    return {
      companies: {},
      isShowResult: false,
      templateStyle: 0,
      options_category: [
        { text: this.$t('bizcard.options.all'), value: '' },
        // {
        //   text: 'เทมเพลททั้งหมด',
        //   value: 'all',
        // },
        // {
        //   text: 'รูปแบบแนวนอน',
        //   value: 'landscape',
        // },
        // {
        //   text: 'รูปแบบแนวตั้ง',
        //   value: 'vertical',
        // },
      ],
      template: {
        pageNo: 1,
        pageSize: 20,
        items: [],
        totalRecords: 0,
        totalPages: 0,
        pageIndex: 1,
      },
      items: [
        // {
        //   index: 1,
        //   templateId: 'test001',
        //   templateName: 'Template A',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: 'template_back.png',
        //   type: 'landscape',
        //   is_use_back_side: true,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 2,
        //   templateId: 'test002',
        //   templateName: 'Template B',
        //   thumbnail: 'template_front_v.png',
        //   thumbnailBack: 'template_back_v.png',
        //   type: 'vertical',
        //   is_use_back_side: true,
        //   showBack: false,
        //   style: 'vertical',
        //   dimension: {
        //     width: 5.5,
        //     height: 9,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 3,
        //   templateId: 'test003',
        //   templateName: 'Template C',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 4,
        //   templateId: 'test004',
        //   templateName: 'Template D',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: 'template_back.png',
        //   type: 'landscape',
        //   is_use_back_side: true,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 5,
        //   templateId: 'test005',
        //   templateName: 'Template E',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 6,
        //   templateId: 'test006',
        //   templateName: 'Template f',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 7,
        //   templateId: 'test007',
        //   templateName: 'Template g',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 8,
        //   templateId: 'test008',
        //   templateName: 'Template H',
        //   thumbnail: 'template_front_v.png',
        //   thumbnailBack: '',
        //   type: 'vertical',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'vertical',
        //   dimension: {
        //     width: 5.5,
        //     height: 9,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 9,
        //   templateId: 'test009',
        //   templateName: 'Template I',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 10,
        //   templateId: 'test010',
        //   templateName: 'Template J',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 11,
        //   templateId: 'test011',
        //   templateName: 'Template K',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
        // {
        //   index: 12,
        //   templateId: 'test012',
        //   templateName: 'Template L',
        //   thumbnail: 'template_front.png',
        //   thumbnailBack: '',
        //   type: 'landscape',
        //   is_use_back_side: false,
        //   showBack: false,
        //   style: 'landscape',
        //   dimension: {
        //     width: 9,
        //     height: 5.5,
        //     unit: {
        //       th: 'ซม.',
        //       en: 'cm',
        //     },
        //   },
        // },
      ],
      filter: '',
      totalRows: 1,
      currentPage: 1,
      totalPage: 0,
      perPage: 10,
      selectedOrders: [],
      flip: [],
      pageTo: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    isUsePrintingMenu() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 3).length > 0 &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
    //   pageTo() {
    //     let first = ''
    //     let last = ''
    //     let page = this.onFilter().length
    //     first = (this.currentPage - 1) * this.perPage + 1
    //     if (this.currentPage === Math.ceil(page / this.perPage)) {
    //       last = page
    //     } else {
    //       last = this.currentPage * this.perPage
    //     }
    //     if (page === 0) {
    //       first = 0
    //       last = 0
    //     } else if (page < this.perPage) {
    //       last = page
    //     }
    //     return first + '-' + last
    //   },
  },
  watch: {
    perPage: {
      handler: async function () {
        await this.onGetTemplate()
        this.currentPage = 1
        this.flip = []
      },
      deep: true,
    },
    filter: {
      handler: async function () {
        await this.onGetTemplate()
        this.currentPage = 1
        this.flip = []
      },
      deep: true,
    },
    currentPage: {
      handler: async function () {
        await this.onGetTemplate()
        this.flip = []
      },
      deep: true,
    },
  },
  async mounted() {
    let _this = this
    window.addEventListener('resize', function () {
      _this.onGetHeight()
    })
    await this.getCompaniesById()
    // await this.onGetCaterogy()
  },
  methods: {
    async getCompaniesById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.companies = result.data
              this.onGetCaterogy()
            }
          },
          (error) => {
            console.log('getCompaniesById', error.responsed)
          }
        )
    },
    async onGetCaterogy() {
      this.options_category = [
        { text: this.$t('bizcard.options.all'), value: '' },
      ]
      let params = new URLSearchParams()
      // if (this.input.company_code !== "")
      params.append('company_code', this.companies.company_code.toUpperCase())
      params.append('status', 'Active')

      let response = await this.$bizCardProvider.getCategories(params)
      console.log('onGetCaterogy', response)
      if (response.status === 200) {
        response.data.items.forEach((e) => {
          this.options_category.push({
            text: e.category_name[this.$i18n.locale],
            value: e.category_id,
          })
        })
        this.onGetTemplate()
      }
    },
    onGetHeight() {
      let height = []
      if (document.querySelectorAll('.template-thumbnail.landscape').length) {
        document
          .querySelectorAll('.template-thumbnail.landscape')
          .forEach((e) => {
            height.push(e.clientHeight)
          })
      }
      this.$nextTick(() => {
        this.templateStyle = height.length
          ? 'height:' + Math.min(...height) + 'px;' + 'width:auto;'
          : 'padding: 0 33%;'
      })
    },
    // onFilter() {
    //   let filter = this.items
    //   if (this.filter !== null && this.filter !== '') {
    //     if (this.filter.value !== 'all') {
    //       filter = filter.filter((e) => e.type.includes(this.filter.value))
    //     }
    //   }
    //   this.totalPage = filter.length
    //   return filter
    // },
    async onGetTemplate() {
      console.log('this.filter', this.filter)
      if (this.companies.company_code) {
        let params = new URLSearchParams()
        params.append('pageSize', this.perPage)
        params.append('pageNo', this.currentPage)
        params.append('company_code', this.companies.company_code.toUpperCase())
        params.append('is_text_object', true)
        // console.log('filter', this.filter.value)
        if (this.filter) {
          params.append('category_id', this.filter.value)
        }
        let response = await this.$bizCardProvider.getCompaniesTemplates(params)
        console.log('itemsPagination', response)
        if (response.status === 200) {
          this.template = response.data
          // this.items = response.data.items
        }
      }
      this.isShowResult = true
    },
    onFlip(val, template_id) {
      let check = this.flip.findIndex((e) => e === template_id)

      if (val === 'front') {
        if (check !== -1) this.flip.splice(check, 1)
      } else {
        if (check === -1) this.flip.push(template_id)
      }
      console.log(this.flip)
    },
    gotoPage(templateId) {
      this.$router.push({
        path: this.$i18n.path('company/printing/template/create/' + templateId),
      })
    },
  },
}
