
export default {
  name: 'StoreLocation',
  data() {
    return {
      cdn_line_add_friend:
        'https://ofm-cdn0.ofm.co.th/images/logo/ic_line_addfriends.png',
      isShowDefault: true,
      locationsInput: '',
      activeStore: {},
      urlLogoOfm: 'https://ofm-cdn0.ofm.co.th/images/logo/ofm-logo.png',
      openInfoWindow: true,
      locationsFilter: [],
      locationTemp: [],
      locations: [],
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      fields: [],
      service: [],
      isSelectLocation: false,
      mapZoom: 8,
      lattitude: null,
      longitude: null,
    }
  },
  async created() {
    await this.onGetService()
    await this.onGetStoreLocation()
  },
  // async mounted() {
  //   await this.onGetService()
  //   await this.onGetStoreLocation()
  // },
  watch: {
    service: {
      handler: function (val) {
        console.log('service', val)
        this.locationsFilter = []
        this.storeFilter(val)
      },
      deep: true,
    },
  },
  computed: {
    isNotEmptyObject() {
      console.log(
        'datadatadatadatadatadatadatadata',
        this.$utils.isObjectValid(this.activeStore) &&
          Object.keys(this.activeStore).length !== 0
      )
      return (
        this.$utils.isObjectValid(this.activeStore) &&
        Object.keys(this.activeStore).length !== 0
      )
    },
    isLocationsFilter() {
      console.log(
        'datadatadatadatadatadatadatadata',
        this.$utils.anyArrayObjectValid(this.locationsFilter)
      )
      return this.$utils.anyArrayObjectValid(this.locationsFilter)
    },
  },
  methods: {
    storeFilter(service) {
      this.locationsFilter = []
      let selectService = service.filter((w) => w.checked)
      if (selectService.length > 0) {
        selectService.forEach((s) => {
          this.locationTemp.forEach((ss) => {
            console.log('ssssssssssssssssss', ss)
            let chService = ss.services.find(
              (f) => f.id == s.value && f.status == 'Active'
            )
            if (chService) {
              let store = ss
              let chStore = this.locationsFilter.filter((f) => f.id == ss.id)
              if (chStore.length == 0) {
                this.locationsFilter.push(store)
              }
            }
          })
        })
      } else {
        this.locationsFilter = this.locationTemp
      }
      console.log('locationsFilter', this.locationsFilter)
    },
    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return true
    },
    closeGmap() {
      this.openInfoWindow = true
      this.mapZoom = 8
      this.activeStore = []
    },
    async onSearch() {
      this.mapZoom = 8
      this.activeStore = []
      await this.onGetStoreLocation()
    },
    async onGetService() {
      let params = new URLSearchParams()
      params.append('isExportExcel', true)
      params.append('pageSize', 0)
      params.append('page', 1)
      params.append('status', 'Active')
      let result = await this.$productProvider.getServiceDetail(params)
      if (result.status === 200) {
        this.service = []
        let tableFields = [
          {
            key: 'name',
            label: this.$t(`store_location.table.head_1`),
            class: 'text-left w-70',
            sortable: false,
          },
          {
            key: 'province',
            label: this.$t(`store_location.table.head_2`),
            class: 'text-left w-90',
            sortable: false,
          },
          {
            key: 'line',
            label: this.$t(`store_location.table.head_3`),
            class: 'text-center w-90',
            sortable: false,
          },
          {
            key: 'phone',
            label: this.$t(`store_location.table.head_4`),
            class: 'text-left w-90',
            sortable: false,
          },
          {
            key: 'businessHours',
            label: this.$t(`store_location.table.head_5`),
            class: 'text-left w-90',
            sortable: false,
          },
        ]
        this.service_Temp = result.data.items
        result.data.items.forEach((item) => {
          let data = {
            text: item.name,
            value: item.id,
            type: item.type,
            checked: false,
          }
          this.service.push(data)

          let serviceField = {
            key: item.id,
            label:
              item.image.url == '' || item.image.url == null
                ? item.name[this.$i18n.locale]
                : item.image.url,
            class: 'text-center w-90',
            sortable: false,
          }
          tableFields.push(serviceField)
        })
        this.fields = Object.assign([], tableFields)
        console.log('fields', this.fields)
      }
    },
    async onGetStoreLocation() {
      // Vue.set(e, 'isChecked', false)
      this.locationTemp = []
      /* กำหนด params สำหรับดึง product detail */
      let params = new URLSearchParams()
      params.append('isExportExcel', true)
      params.append('pageSize', 0)
      params.append('page', 1)
      params.append('status', 'Active')

      if (this.locationsInput !== '') {
        params.append('keyword', this.locationsInput)
      }
      if (this.lattitude != null && this.lattitude + '' != '') {
        params.append('lattitude', this.lattitude)
      }
      if (this.longitude != null && this.longitude + '' != '') {
        params.append('longitude', this.longitude)
      }
      /* เรียก api */
      await this.$productProvider
        .getStoreLocationDetail(params)
        .then(
          (result) => {
            console.log('this.service StoreLocationDetail', result.data)
            if (result.status === 200) {
              this.locationTemp = result.data.items
              this.storeFilter(this.service)
              if (this.isSelectLocation) {
                if (this.isLocationsFilter) {
                  this.activeStore = this.locationsFilter[0]
                }
              }
            }
          },
          (error) => {
            console.log('StoreLocationDetail', error.responsed)
          }
        )
        .catch((err) => {
          console.log('err', err)
        })
    },
    async onCheckLocation() {
      console.log('this location')
      this.mapZoom = 15
      this.isSelectLocation = true
      window.navigator.geolocation.getCurrentPosition((Position) => {
        console.log('this location', Position.coords)
        this.lattitude = Position.coords.latitude
        this.longitude = Position.coords.longitude
        this.onGetStoreLocation()
      })
    },
    scrollToTop() {
      function preventScroll(e) {
        e.preventDefault()
        e.stopPropagation()

        return false
      }
      window.addEventListener('wheel', preventScroll, {
        passive: false,
      })

      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
          window.removeEventListener('wheel', preventScroll, {
            passive: false,
          })
        }, 500)
        // const c = document.documentElement.scrollTop || document.body.scrollTop
        // if (c > 60) {
        //   window.requestAnimationFrame(scrollToTop)
        //   window.scrollTo(0, c - c / 6)
        // } else {
        //   window.removeEventListener('wheel', preventScroll, {
        //     passive: false,
        //   })
        // }
      }
      scrollToTop()
    },
    schedulesFilter(schedules) {
      let temp_schedule = []
      schedules.forEach((e) => {
        if (temp_schedule.length > 0) {
          //
          let last_temp = temp_schedule[temp_schedule.length - 1]
          if (last_temp.status != e.status) {
            const temp = {
              day: e.day[this.$i18n.locale],
              time_open: e.time_open,
              time_close: e.time_close,
              status: e.status,
            }
            temp_schedule.push(temp)
            console.log(
              'onMappingItems diff status temp_schedule1 : ',
              temp_schedule
            )
          } else {
            if (e.status) {
              if (
                last_temp.time_open == e.time_open &&
                last_temp.time_close == e.time_close
              ) {
                //
                last_temp.day =
                  last_temp.day.split('-')[0] + '-' + e.day[this.$i18n.locale]
                last_temp.status = e.status
                console.log('onMappingItems open1111 : ', last_temp)
              } else {
                //
                const temp = {
                  day: e.day[this.$i18n.locale],
                  time_open: e.time_open,
                  time_close: e.time_close,
                  status: e.status,
                }
                temp_schedule.push(temp)
                console.log('onMappingItems temp_schedule1 : ', temp_schedule)
              }
            } else {
              last_temp.day =
                last_temp.day.split('-')[0] + '-' + e.day[this.$i18n.locale]
              last_temp.status = e.status
              console.log('onMappingItems open == close : ', temp_schedule)
            }
          }
        } else {
          //
          const temp = {
            day: e.day[this.$i18n.locale],
            time_open: e.time_open,
            time_close: e.time_close,
            status: e.status,
          }
          temp_schedule.push(temp)
        }
      })
      return temp_schedule
    },
    gotoLineOA(lineOA) {
      window.open(`${lineOA}`, '_blank')
    },
    gotoGoogleMap(lattitude, longitude) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lattitude},${longitude}`,
        '_blank'
      )
    },
    onSeleteStore(store) {
      this.mapZoom = 15
      this.activeStore = store
      this.scrollToTop()
      this.openInfoWindow = true
    },
    // isNotEmtryObject(data) {
    //   if (Object.keys(data).length === 0) {
    //     return false
    //   } else return true
    // },
  },
}
