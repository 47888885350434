
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'RoleUser',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isError: false,
      duplicateEmailFlag: false,
      isEditUser: false,
      isEditRole: false,
      isActive: true,
      isName: '',
      isLast: '',
      is_role_id: false,
      is_checked: false,
      errormessage: '',
      user: {
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        default_language: 'TH',
      },
      edit: {
        head_user_open_id: '',
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: false,
        roles: {
          id: '',
          role_id: '',
          name: {
            th: '',
            en: '',
          },
        },
        default_language: 'TH',
      },
      roles: [],
      editRoles: [],
      onSelectCheckbox: {
        val: '',
        index: '',
      },
      rolesEdit: [],
      roleAdmin: [],
      error_messange: '',
      isenable: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    requiredIsPhone() {
      return this.edit.phone.value === '' && this.edit.mobile.value === ''
    },
  },
  watch: {
    onSelectCheckbox: {
      handler: function (data) {
        this.roles.forEach((element) => {
          element.isActive =
            data.index === element.index ? data.val : element.isActive
        })
      },
    },
  },
  mounted() {
    this.getCompaniesRolesMaster()
  },
  methods: {
    validateCompaniesSettingSubmit() {
      this.$refs.createCompaniesSettingForm.validate().then((result) => {
        if (this.roles.find((a) => a.isActive)) {
          if (result) this.createCompaniesUser()
        } else {
          this.isError = true
        }
      })
    },
    async checkEmail() {
      this.error_messange = ''
      this.isenable = false
      this.user = {
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: false,
        default_language: 'TH',
      }
      let params = new URLSearchParams()
      params.append('email', this.edit.email)
      params.append('useropenid', this.openId)
      params.append('account_channel', this.profileType)

      if (this.edit.email.includes('@')) {
        const validateResult = this.$refs['อีเมล'].validationInput()
        console.log('validateResult', validateResult)

        if (validateResult) {
          const responsed = await this.$profileProvider.checkCompanyEmail(
            this.userProfile.id,
            params
          )
          if (responsed.status === 200) {
            console.log('responsed', responsed.data)
            let data = responsed.data
            if (!data.isuser) {
              this.duplicateEmailFlag = true
              this.error_messange = data.messange
            } else {
              this.isenable = true
              this.user = {
                first_name: data.first_name,
                last_name: data.last_name,
                phone:
                  data.phone !== null
                    ? data.phone.find((a) => a.type === 'Phone')
                    : undefined,
                mobile:
                  data.phone !== null
                    ? data.phone.find((a) => a.type === 'Mobile')
                    : undefined,
                is_user_active: true,
                default_language: data.default_language.toUpperCase(),
              }

              console.log('this.user', this.user)
            }
          }
          if (responsed.status === 404) {
            this.duplicateEmailFlag = false
            this.error_messange = this.$i18n.t(
              `company-role-user-create.errorMessage`
            )
          }
        }
      }
    },
    async getCompaniesRolesMaster() {
      await this.$profileProvider
        .getCompaniesRolesMasterAll(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('data', data)
              let role_data = data.is_use_product_printing
                ? data.roles
                : data.roles.filter((a) => a.role_id !== 5)
              role_data.forEach((e) => {
                this.roles.push({
                  index: e.role_id,
                  role_id: e.role_id,
                  name: e.name,
                  isActive: false,
                })
              })
              this.edit.is_user_active = true
            }
          },
          (error) => {
            console.log('getCompaniesRolesMaster', error.responsed)
          }
        )
    },
    async createCompaniesUser() {
      this.edit.roles = this.roles.filter((a) => a.isActive === true)
      this.edit.head_user_open_id = this.openId
      this.edit.default_language = this.user.default_language.toLowerCase()
      console.log(this.edit)
      await this.$profileProvider
        .createCompaniesUserData(this.userProfile.id, this.edit)
        .then(
          (result) => {
            if (result.status == 201) {
              this.onCancel()
            }
          },
          (error) => {
            console.log('createCompaniesUser', error.responsed)
          }
        )
    },
    onCancel() {
      this.$router.push({
        path: this.$i18n.path(`company/user/manage`),
      })
    },
    onChangeLanguage(lang) {
      this.user.default_language = lang
    },
  },
}
