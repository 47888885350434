
export default {
  name: 'SelectDropdownComponent',
  props: {
    width: { type: String, default: '100%' },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    disabled: { type: Boolean, default: false },
    showSelected: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
  },
  data() {
    return {
      select: {},
    }
  },
  computed: {
    list: function () {
      return this.showSelected
        ? this.items
        : this.items.filter((i) => i.value !== this.select.value)
    },
  },
  watch: {
    //**Bam Note: Comment watch select ออก เพราะทำให้เวลาโหลด component ครั้งแรก emit event ออกไปเสมอทั้งๆที่ user ยังไม่ได้เลือก
    // เปลี่ยนวิธีการคือให้จับ event จากตอน user click เลือก drop down แล้วค่อย emit event selected ออกไปแทน
    // select: {
    //   handler: function () {
    //     this.$emit('selected', this.select)
    //   },
    // },
  },
  created() {
    if (this.defaultValue !== '') {
      this.items.forEach((element) => {
        if (element.value === this.defaultValue) {
          this.select = {
            value: element.value,
            text: element.text,
          }
        }
      })
    }
  },
  methods: {
    onSelected(item) {
      this.select = item
      this.$emit('selected', this.select)
    },
  },
}
