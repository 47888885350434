
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import TableReportCategory from '@/components/company/table-report-category.vue'
import { exportExcelCategoriesReportDocument } from '@/plugins/export-excel.js'

export default {
  layout: 'main',
  name: 'CompanyReportCategory',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    TableReportCategory,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      filter: {
        category: [],
        selected: [],
        order_status: [],
        requester_date_from: '',
        requester_date_to: '',
        approve_date_from: '',
        approve_date_to: '',
        order_id: '',
        approver: [],
        costcenter: [],
        department: [],
        order: [],
      },
      categoryData: [],
      optionsCategory: [],

      approverData: [],
      orderData: [],
      costcenterData: [],
      departmentData: [],

      optionsApprover: [],
      optionsOrder: [],
      optionsCostcenter: [],
      optionsDepartment: [],
      // optionsCategory: [
      //   {
      //     index: 1,
      //     id: '001',
      //     text: 'แคนทีนและผลิตภัณฑ์ทำความสะอาด',
      //   },
      //   {
      //     index: 2,
      //     id: '002',
      //     text: 'เครื่องปริ้น คอมพิวเตอร์และ IT',
      //   },
      //   {
      //     index: 3,
      //     id: '003',
      //     text: 'อุปกรณ์สำนักงานอิเล็กทรอนิกส์',
      //   },
      // ],
      itemCount: 0,
      result: [],
      isMsg: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'userCostcenter']),
  },
  created() {},
  mounted() {
    // this.getUserById()
    this.getCategoriesMaster()
    this.getRoleApproverEmail()
    this.getRoleRequestersEmail()
    this.getNameCostcenter()
    this.getNameDepartment()
    // this.onSearch()
  },
  methods: {
    // async getUserById() {
    //   await this.$profileProvider
    //     .getUserDataById(this.$route.params.id)
    //     .then((result) => {
    //       if (result.status === 200) {
    //         let data = result.data
    //         this.users = {
    //           user_open_id: data.user_open_id,
    //           email: data.email,
    //           first_name: data.first_name,
    //           last_name: data.last_name,
    //         }
    //       }
    //     })
    // },
    // userOrderRangeSelected(event) {
    //   this.filter.date = event
    // },
    onAddCategory() {
      this.$nextTick(() => {
        if (this.categoryData) this.filter.category.push(this.categoryData)
        this.categoryData = ''

        // disabled category option
        this.filter.category.forEach((v) => {
          this.optionsCategory[
            this.optionsCategory.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveCategory(id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsCategory[
          this.optionsCategory.findIndex((e) => e.id === id)
        ]['$isDisabled'] = false
        // remove category
        this.filter.category.splice(
          this.filter.category.findIndex((e) => e.id === id),
          1
        )
      })
    },
    async onSearch() {
      this.result = []
      console.warn('filter :>> ', this.filter)
      let keys = Object.keys(this.filter)
      let validateInputCount = 0

      Object.keys(this.filter).forEach((fieldName) => {
        // console.log('fieldName', typeof this.filter[fieldName])
        if (typeof this.filter[fieldName] === 'object') {
          if (Object.keys(this.filter[fieldName]).length === 0) {
            // console.log(fieldName)
            validateInputCount++
          }
        }
        if (
          this.filter[fieldName] === '' ||
          this.filter[fieldName] === undefined
        ) {
          validateInputCount++
        }
      })
      // console.log(validateInputCount + '===' + keys.length)

      if (validateInputCount === keys.length) {
        this.$bvToast.toast('กรุณาระบุเงื่อนไขการค้นหา', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        })
        return
      }
      await this.getCateriesReport()
    },
    // onSearch() {
    //   console.warn('object :>> ', this.filter)
    //   this.result = []
    //   this.getCateriesReport()
    // },
    onReset() {
      this.filter = {
        // orderDate: '',
        // // multiselect
        // category: [],
        // selected: [],
        // order_status: [],
        // requester_date_from: '',
        // requester_date_to: '',
        // approve_date_from: '',
        // approve_date_to: '',
        // order_id: '',
        // approver: [],
        // costcenter: [],
        // order: [],
        category: [],
        selected: [],
        order_status: [],
        requester_date_from: '',
        requester_date_to: '',
        approve_date_from: '',
        approve_date_to: '',
        order_id: '',
        approver: [],
        costcenter: [],
        department: [],
        order: [],
      }
      // reset option
      this.approverData = []
      this.orderData = []
      this.costcenterData = []
      this.categoryData = []
      this.departmentData = []
      this.optionsOrder.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsApprover.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsCostcenter.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsDepartment.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsCategory.forEach((e) => {
        e['$isDisabled'] = false
      })
    },
    async getCategoriesMaster() {
      await this.$reportProvider.getCategoriesMasterData().then((result) => {
        if (result.status === 200) {
          let data = result.data
          data.forEach((a) => {
            this.optionsCategory.push({
              id: a.id,
              text: a.display_name[this.$i18n.locale],
            })
          })
        }
      })
    },
    // dateSelected(event) {
    //   this.filter.date_from = event.from
    //   this.filter.date_to = event.to
    // },
    async getCateriesReport(pageNo, isExcel) {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('order_id', this.filter.order_id)
      // params.append('date_from', this.filter.date_from ?? '')
      // params.append('date_to', this.filter.date_to ?? '')
      params.append('date_from', this.filter.requester_date_from ?? '')
      params.append('date_to', this.filter.requester_date_to ?? '')
      params.append('approve_date_from', this.filter.approve_date_from ?? '')
      params.append('approve_date_to', this.filter.approve_date_to ?? '')
      this.filter.selected.forEach((a) => {
        params.append('order_status', a)
      })

      this.filter.department.forEach((a) => {
        params.append('department_id', a.value)
      })
      this.filter.costcenter.forEach((a) => {
        params.append('coscenter_id', a.value)
      })
      this.filter.approver.forEach((a) => {
        params.append('approver_id', a.value)
      })
      this.filter.order.forEach((a) => {
        params.append('requester_id', a.value)
      })
      // ฝ่าย

      params.append('pageNo', pageNo ?? 0)
      params.append('is_excel', isExcel ?? false)
      this.filter.category.forEach((a) => {
        params.append('category_id', a.id)
      })
      await this.$reportProvider
        .getCateriesReportData(params)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.length > 0) {
              data.forEach((a) => {
                this.itemCount = a.total_row
                this.result.push({
                  index: a.row,
                  id: a.id,
                  company_code: a.company_code,
                  account_offline_id: a.account_offline_id,
                  costcenter_code: a.costcenter_code,
                  costcenter_name_th: a.costcenter_name_th,
                  display_name_th: a.display_name_th,
                  display_name_en: a.display_name_en,
                  department_code: a.department_code,
                  department_name_th: a.department_name_th,
                  contact_email: a.contact_email,
                  contact_name_th: a.contact_name_th,
                  // email: a.email,
                  // approver_name: a.approver_name,
                  order_id: a.order_id,
                  order_date: a.order_date,
                  order_status: a.order_status,
                  reference_document_no: a.reference_document_no,
                  approval_date: a.approval_date,
                  sku_code: a.sku_code,
                  product_name_th: a.product_name_th,
                  product_name_en: a.product_name_en,
                  sale_unit_th: a.sale_unit_th,
                  sale_unit_en: a.sale_unit_en,
                  quantity: a.quantity,
                  price_excvat: a.price_excvat,
                  price_disc_excvat: a.price_disc_excvat,
                  total_amt_excvat: a.total_amt_excvat,
                  reference_invoice: a.reference_invoice,
                })
                this.isMsg = false
              })
            } else {
              this.itemCount = 0
              this.isMsg = true
            }
          }
        })
    },
    async exportExcel(isExcel) {
      this.result = []
      await this.getCateriesReport(0, isExcel)
      exportExcelCategoriesReportDocument(
        this.result,
        this.userProfile.company_level
      )
    },
    // update
    onAddApprover() {
      this.$nextTick(() => {
        if (this.approverData) this.filter.approver.push(this.approverData)
        this.approverData = ''

        // disabled approver option
        this.filter.approver.forEach((v) => {
          this.optionsApprover[
            this.optionsApprover.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveApprover(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsApprover[
          this.optionsApprover.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove approver
        this.filter.approver.splice(
          this.filter.approver.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onAddOrder() {
      this.$nextTick(() => {
        if (this.orderData) this.filter.order.push(this.orderData)
        this.orderData = ''

        // disabled order option
        this.filter.order.forEach((v) => {
          this.optionsOrder[
            this.optionsOrder.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveOrder(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsOrder[
          this.optionsOrder.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove order
        this.filter.order.splice(
          this.filter.order.findIndex((e) => e.email === email),
          1
        )
      })
    },

    onAddDepartment() {
      this.$nextTick(() => {
        if (this.departmentData)
          this.filter.department.push(this.departmentData)
        this.departmentData = ''

        // disabled order option
        this.filter.costcenter.forEach((v) => {
          this.optionsDepartment[
            this.optionsDepartment.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveDepartment(id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsDepartment[
          this.optionsDepartment.findIndex((e) => e.id === id)
        ]['$isDisabled'] = false
        // remove costcenter
        this.filter.department.splice(
          this.filter.department.findIndex((e) => e.id === id),
          1
        )
      })
    },

    onAddCostcenter() {
      this.$nextTick(() => {
        if (this.costcenterData)
          this.filter.costcenter.push(this.costcenterData)
        this.costcenterData = ''

        // disabled order option
        this.filter.costcenter.forEach((v) => {
          this.optionsCostcenter[
            this.optionsCostcenter.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveCostcenter(id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsCostcenter[
          this.optionsCostcenter.findIndex((e) => e.id === id)
        ]['$isDisabled'] = false
        // remove costcenter
        this.filter.costcenter.splice(
          this.filter.costcenter.findIndex((e) => e.id === id),
          1
        )
      })
    },

    async getRoleApproverEmail() {
      await this.$profileProvider
        .getRoleApproverEmailById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsApprover = []
              let data = result.data
              data.forEach((s) => {
                this.optionsApprover.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleApproverEmail', error.responsed)
          }
        )
    },
    async getRoleRequestersEmail() {
      await this.$profileProvider
        .getRoleRequestersEmailById(this.userProfile.id, false)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsOrder = []
              let data = result.data
              data.forEach((s) => {
                this.optionsOrder.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleRequestersEmail', error.responsed)
          }
        )
    },

    async getNameDepartment() {
      await this.$profileProvider
        .getNameDepartmentData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.optionsDepartment = []
              data.forEach((e, index) => {
                this.optionsDepartment.push({
                  // index: index + 1,
                  // id: e.id,
                  // code: e.code,
                  // value: e.id,
                  index: index + 1,
                  id: e.code,
                  text: e.name.th,
                  // text: e.name.th,
                  value: e.id,
                })
              })
            }
          },
          (error) => {
            console.log('getNameDepartmentData', error.responsed)
          }
        )
    },

    async getNameCostcenter() {
      await this.$profileProvider
        .getNameCostcenterData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.optionsCostcenter = []
              data.forEach((e, index) => {
                this.optionsCostcenter.push({
                  index: index + 1,
                  id: e.code,
                  text: e.name.th,
                  value: e.id,
                })
              })
            }
          },
          (error) => {
            console.log('getNameCostcenterData', error.responsed)
          }
        )
    },
    dateApproveSelected(event) {
      this.filter.approve_date_from = event.from
      this.filter.approve_date_to = event.to
    },
    dateRequesterSelected(event) {
      this.filter.requester_date_from = event.from
      this.filter.requester_date_to = event.to
    },
  },
}
