import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b0bd76de"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperImage: require('/usr/src/nuxt-app/components/share/swiper-image.vue').default,MainCategoryList: require('/usr/src/nuxt-app/components/category/main-category-list.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default,Notfound: require('/usr/src/nuxt-app/components/share/notfound.vue').default})
