
import ConfirmRemoveModal from '@/components/modal/confirmRemoveApprover.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'RoleOrderList',
  components: {
    ConfirmRemoveModal,
  },
  // props: {
  //   roles: { type: Array, default: () => [] },
  // },
  data() {
    return {
      showConfirmModal: false,
      dataId: '',
      roleTest: [],
      roles: [],
      isActive: 1,
      onClickActive: {
        val: '',
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    onClickActive: {
      handler: function (data) {
        let _roles = this.roles
        if (_roles[data.index] !== undefined) {
          _roles[data.index].isActive = data.val
        }
        this.roles = []
        this.roles = _roles
      },
    },
  },
  mounted() {
    this.getRequesterLine()
  },
  methods: {
    onTest(id) {
      console.log('onTest', id)
    },
    onEdit(costcer_id) {
      this.$router.push({
        path: this.$i18n.path(
          'company/user/manage/setting/edit/' +
            this.$route.params.id +
            '?cost=' +
            costcer_id
        ),
      })
    },
    onConfirmRemoveModal(id) {
      this.dataId = id
      this.showConfirmModal = true
      console.log('delectId', id)
    },
    async onRemove(id) {
      await this.$profileProvider.deleteRequesterLineById(id).then(
        (result) => {
          if (result.status === 200) {
            this.getRequesterLine()
            window.location.reload(true)
          }
        },
        (error) => {
          console.log('onRemove', error.responsed)
        }
      )
    },
    async getRequesterLine() {
      await this.$profileProvider
        .getRequesterLineDataById(this.userProfile.id, this.$route.params.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('data', data)
              if (result.data.length > 0) {
                this.roles = data
                this.roles.forEach((e) => {
                  e.isActive = 1
                })
              }

              if (result.data.length === 0) {
                this.isHasRow = false
              }
            }
          },
          (error) => {
            console.log('getRequesterLine', error.responsed)
          }
        )
    },
  },
}
