
export default {
  name: 'ProductNotifyModalComponent',
  components: {
    // 'input-text': InputText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sku: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: Object,
    },
    productname: {
      type: Object,
      default: () => {},
    },
    // item: {
    //   type: Object,
    //   default: Object,
    // },
    brand: {
      type: String,
      default: '',
    },
    categories: {
      type: Array,
      default: Array,
    },
    price: {
      type: Number,
      default: 0,
    },
    discount: {
      type: Number,
      default: 0,
    },
    isFlashSale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reloadVerify: true,
      dataForNotify: {
        user_open_id: '',
        //fullname: '',
        name: {
          th: '',
          en: '',
        },
        email: '',
        phones: [],
        qty: '',
        sku_code: '',
        first_name: {},
        last_name: {},
      },
    }
  },
  computed: {
    // ...mapGetters('authen', ['accessToken']),
  },
  watch: {
    show: function (value) {
      if (value) {
        if (this.user.id) {
          console.log('this.user', this.user)
          this.dataForNotify.user_open_id = this.user.user_open_id
          this.dataForNotify.fullname = `${
            this.user.first_name[this.$i18n.locale]
          } ${this.user.last_name[this.$i18n.locale]}`
          this.dataForNotify.name.th = `${this.user.first_name.th} ${this.user.last_name.th}`
          this.dataForNotify.name.en = `${this.user.first_name.en} ${this.user.last_name.en}`
          this.dataForNotify.email = this.user.email
          this.dataForNotify.phones = this.user.phones
          this.dataForNotify.qty = this.user.qty
          this.dataForNotify.sku_code = this.sku
          this.dataForNotify.product_name = this.productname
        }
        this.$refs.productNotifyModal.show()
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onClose() {
      this.$refs.productNotifyModal.hide()
      this.$emit('hidden')
    },
    async onSubmit() {
      console.log('NotifyModal', this.item)
      this.$refs.productNotifyModal.hide()
      // this.$emit('onSubmit', this.dataForNotify)
      const gtmItem = [
        {
          item_id: this.sku,
          item_fs: this.isFlashSale,
          item_name: this.productname?.th,
          item_brand: this.brand,
          item_category: this.categories?.[2] ?? '',
          item_category2: this.categories?.[1] ?? '',
          item_category3: this.categories?.[0] ?? '',
          quantity: this.dataForNotify.qty,
          price: this.price,
          discount: this.discount,
          // ...this.$store.getters['user/userPromotionTrack'],
        },
      ]

      this.$gtm.push({
        event: 'notify_stock',
        ecommerce: {
          user: this.$store.getters['user/userInfoTrack'],
          currency: 'THB',
          items: [...gtmItem],
        },
      })

      console.log('NotifyModal', gtmItem)
    },
  },
}
