
import InputText from '@/components/share/input-text.vue'

export default {
  layout: 'blank-nav',
  name: 'ApplyNow',
  components: {
    'input-text': InputText,
  },
  data() {
    return {
      isComplete: false,
      showOTPModal: false,
      reloadResgiter: true,
      duplicateEmailFlag: false,
      dataForRegister: {
        company: '',
        contact: '',
        position: '',
        mobile: '',
        email: '',
      },
      verify: 0,
      hasVerify: false,
      openId: '',
    }
  },
  computed: {},
  watch: {
    // verify: {
    //   handler: function (val) {
    //     if (val < 99) {
    //       this.verify = 0
    //     }
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onGetVerify(range) {
      if (range == 100) {
        this.hasVerify = true
      } else {
        for (let i = range; i >= 0; i--) {
          setTimeout(() => (this.verify = i), 10)
        }
        this.hasVerify = false
      }
    },
    async checkEmail() {
      console.log('before validate email')
      if (this.dataForRegister.email.includes('@')) {
        const validateResult = this.$refs['อีเมล'].validationInput()
        console.log('validateResult', validateResult)
        if (validateResult) {
          const responsed = await this.$profileProvider.checkEmail(
            this.dataForRegister.email
          )
          console.log('responsed', responsed)
          if (responsed.status === 200) this.duplicateEmailFlag = true
          else if (responsed.status === 404) this.duplicateEmailFlag = false
        }
      }
    },
    validateBeforeSubmit() {
      this.$refs.registerForm.validate().then((result) => {
        if (result) {
          this.register()
        }
      })
    },
    async register() {
      const password = this.$utils.cryptoJS(this.dataForRegister.password)
      let apiData = Object.assign({}, this.dataForRegister)
      apiData.password = password
      console.log('registerBody', apiData)
      const responsed = await this.$profileProvider.register(apiData)
      console.log('responsed: ', responsed)
      if (responsed.status === 201) {
        //
      }
      if (responsed.status === 409) {
        //
      }
    },
  },
}
