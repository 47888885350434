
import asideComponent from '@/components/template/aside-user.vue'
import RoleOrderList from '@/components/role/role-order-list.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageRoleSetting',
  components: {
    'aside-component': asideComponent,
    RoleOrderList,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isHasRow: true,
      users: {
        user_open_id: '',
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
      },
      roles: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    filterDepartment: {
      handler: function () {},
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getUserById()
  },
  methods: {
    onCreateApprover() {
      this.$router.push({
        path: this.$i18n.path(
          'company/user/manage/setting/create/' + this.$route.params.id
        ),
      })
    },
    async getUserById() {
      await this.$profileProvider
        .getCompaniesRoleMasterData(this.$route.params.id, this.userProfile.id)
        .then((result) => {
          if (result.status === 200) {
            let data = result.data
            this.users = {
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
            }
            this.getRequesterLine()
          }
        })
    },
    async getRequesterLine() {
      await this.$profileProvider
        .getRequesterLineDataById(this.userProfile.id, this.$route.params.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('data', data)
              if (result.data.length > 0) {
                this.roles = data
                this.roles.forEach((e) => {
                  e.isActive = 1
                })
              }

              if (result.data.length === 0) {
                this.isHasRow = false
              }
            }
          },
          (error) => {
            console.log('getRequesterLine', error.responsed)
          }
        )
    },
  },
}
