
import SwiperImage from '@/components/share/swiper-image.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'ByBrands',
  components: {
    SwiperImage,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      bannerBrands: [],
      brandsItems: [],
      alphabets: [],
      isAlphabet: true,
      alphabetOptions: {
        navigation: {
          nextEl: '#alphabet-next',
          prevEl: '#alphabet-prev',
        },
        slidesPerView: 6,
        slidesPerGroup: 6,
        observeParents: true,
        on: {
          resize: () => {
            this.$nextTick(() => {
              this.$refs['alphabet-swiper'].$swiper.update()
            })
          },
        },
        breakpoints: {
          768: {
            slidesPerView: 12,
            slidesPerGroup: 12,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 18,
            slidesPerGroup: 18,
            spaceBetween: 0,
          },
          1280: {
            slidesPerView: 24,
            slidesPerGroup: 24,
            spaceBetween: 0,
          },
        },
      },
      activeRef: 0,
      fixedPosition: false,
      brandHomeImage: [],
      brandItems: [],
      defaultImageBrand:
        'https://ofm-cdn0.ofm.co.th/images/default-brand-logo.png',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
  },
  watch: {},
  created() {},
  mounted() {
    this.onLoadBrands()
    this.getBrandsAll()
    this.getBrandsHome()
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // onSearchBrand(name) {
    //   if (this.$utils.isValid(name))
    //     this.$router.push({ path: this.$i18n.path(`brand/${name}`) })
    // },
    handleScroll() {
      if (
        window.scrollY >=
        document.getElementById('alphabet-bar').getBoundingClientRect().top + 60
      ) {
        this.fixedPosition = true
      } else {
        this.fixedPosition = false
        console.log('remove')
      }
      this.alphabets.forEach((element, index) => {
        if (
          document.getElementById('alphabet-' + index) !== null &&
          window.scrollY >=
            document.getElementById('alphabet-' + index).offsetTop
        ) {
          this.activeRef = index
        }
      })
    },
    scrollToRef(refName) {
      if (document.getElementById(refName) !== null) {
        let top = document.getElementById(refName).offsetTop + 210
        window.scroll({
          behavior: 'smooth',
          top: top,
        })
      }
    },
    onLoadBrands() {},
    async getBrandsHome() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type)
      }
      params.append('page_type', 'home')
      await this.$profileProvider.getBrandsHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('object', data)
            let brandHome = data.brand_homes.sort(function (a, b) {
              return a.sequence - b.sequence
            })
            brandHome.forEach((c) => {
              let banner = c.banner_images[0]
              let src = banner?.image?.url || ''
              // this.$utils.isObjectValid(c) &&
              // this.$utils.isObjectValid(c.image) &&
              // this.$utils.isValid(c.image.url)
              //   ? c.image.url
              //   : ''
              this.brandsItems.push({
                id: c.brand_id,
                src: src,
                sequence: c.sequence,
                subTitle: 'By Brand',
                title: banner?.title_tag?.[this.$i18n.locale], // new
                alt: banner?.alt_tag?.[this.$i18n.locale], // new
                path_url: banner?.path_url,
              })
            })

            data.brand_images
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .forEach((a, index) => {
                let src =
                  // this.$utils.anyArrayObjectValid(a) &&
                  this.$utils.isObjectValid(a.images[0]) &&
                  this.$utils.isValid(a.images[0].url)
                    ? a.images[0].url
                    : this.defaultImageBrand
                let path_url =
                  // this.$utils.anyArrayObjectValid(a) &&
                  this.$utils.isValid(a.path_url) ? a.path_url : ''
                // brandHome[0].image.url,
                this.bannerBrands.push({
                  sequence: a.sequence,
                  // src: src,
                  brandID: a.id,
                  brandName: a.display_name.th,
                  index: index + 1,
                  src: this.$utils.imageTimeStamp(
                    src,
                    this.$utils.isObjectKeyValid(a.update_info, 'timestamp')
                      ? a.update_info.timestamp
                      : ''
                  ),
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.title_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  url: path_url,
                })
              })
            console.log('brandItems', this.brandItems)
          }
        },
        (error) => {
          console.log('getBrandsHome', error.responsed)
        }
      )
    },
    async getBrandsAll() {
      await this.$profileProvider.getBrandAllData().then(
        (result) => {
          if (result.status === 200) {
            this.alphabets = result.data
            // result.data.forEach((a, i) => {
            //   this.alphabets.push({
            //     index: i + 1,
            //     title: a.title,
            //     brands: a.brand_names,
            //   })
            // })
            console.log('alphabets1', result.data)
          }
        },
        (error) => {
          console.log('getBrandsAll', error.responsed)
        }
      )
    },
  },
}
