
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  mounted() {
    let refreshToken = Cookie.get('refreshToken')
    let accessToken = Cookie.get('accessToken')
    let info = Cookie.get('info')

    let user = {}
    if (info) {
      let json_info = JSON.parse(info)
      user = {
        name: json_info.name,
        email: json_info.email,
        photo: json_info.photo,
      }
      Cookie.remove('info')
    }

    if (refreshToken && accessToken) {
      this.$store.dispatch('authen/setAuthToken', {
        refreshToken,
        accessToken,
        user,
        type: 'OAUTH',
      })
      Cookie.remove('refreshToken')
      Cookie.remove('accessToken')

      Cookie.set('refreshToken', refreshToken, { expires: 1 })
      Cookie.set('accessToken', accessToken, { expires: 1 })
      Cookie.set('user', JSON.stringify(user), { expires: 1 })
    }

    if (Object.keys(this.$route.query).length && this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect)
    } else if (this.$store.getters.routeHistory !== '') {
      this.$router.push(this.$store.getters.routeHistory)
    } else this.$router.push('/')
  },
}
