
export default {
  name: 'QrImageComponent',
  props: {
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isExpired: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // ทำอะไรบางอย่างเมื่อ onExpired เปลี่ยนเป็น true
          this.showRefreshButton = true // แสดงปุ่ม Refresh
        } else {
          // ทำอะไรบางอย่างเมื่อ onExpired เปลี่ยนเป็น false
          this.showRefreshButton = false // ซ่อนปุ่ม Refresh
        }
      },
    },
  },

  data() {
    return {
      // qrCodeUrl: null,
      isLoading: true,
      scanSuccess: false,
      confirmed: false,
      qrData: {
        qr_id: '',
        qr_img_base64: '',
        expiry: '',
      },
      showRefreshButton: false,
      latitude: 0,
      longitude: 0,
      qrCodeUrl: '',
      timeout: null,
    }
  },
  mounted() {
    this.onCheckLocation()
  },
  methods: {
    async createQr() {
      let body = {
        type: 'Login',
        pixels_per_module: '10',
        geolocation: {
          latitude: this.latitude,
          longitude: this.longitude,
        },
      }
      const responsed = await this.$authenProvider.createQrCode(body)
      if (responsed.status === 201) {
        this.isLoading = false
        this.qrData = responsed.data
        console.log('qrData', this.qrData)
        this.$emit('onPolling', this.qrData)
        this.qrCodeUrl = this.qrData.qr_img_base64
        this.expiry = this.qrData.expiry
        this.showRefreshButton = false // ซ่อนปุ่มเมื่อได้รับ QR code ใหม่
        if (this.timeout) {
          clearTimeout(this.timeout) // ยกเลิก timeout ที่เก่า
        }
        this.setTimeoutForRefresh() // เริ่มจับเวลา timeout ใหม่
      } else {
        this.showRefreshButton = true
      }
    },

    async onCheckLocation() {
      console.log('this location')
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('this location', position.coords)
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
          this.createQr()
        },
        (error) => {
          console.error('Error getting location:', error)
          // Set default values to 0 if there's an error
          this.latitude = 0
          this.longitude = 0
          this.createQr() // ยังคงสร้าง QR code ต่อไป
        }
      )
    },

    refreshQRCode() {
      this.showRefreshButton = false
      this.createQr()
    },

    setTimeoutForRefresh() {
      // this.expiry = Date.now() + 5000
      const currentTime = Date.now()
      const expiryTime = this.expiry
      const isExpired = currentTime > expiryTime

      this.showRefreshButton = isExpired // แสดงปุ่มทันทีถ้าหมดอายุ

      if (!isExpired) {
        // ถ้ายังไม่หมดอายุ ให้ตั้ง timeout
        const remainingTime = expiryTime - currentTime
        this.timeout = setTimeout(() => {
          this.showRefreshButton = true // แสดงปุ่มเมื่อหมดอายุ
          this.$emit('onExpired')
        }, remainingTime)
      }
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
  },
}
