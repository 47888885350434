
export default {
  name: 'FilterPanelPrice',
  props: {
    currentRefinement: null,
    range: { min: 0, max: 10000 },
    canRefine: { type: Boolean, default: false },
    refine: null,
  },
  data() {
    return {}
  },
  watch: {},
  created() {
    console.log('created', this.currentRefinement, this.refine)
  },
  methods: {
    toValue(value, range) {
      return [
        value.min !== undefined ? value.min : range.min,
        value.max !== undefined ? value.max : range.max,
      ]
    },
  },
}
