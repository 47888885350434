
export default {
  name: 'StripLoComponent',
  props: {
    obj: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    img: function () {
      return {
        src: this.obj.image?.url,
        url: this.obj.path_url,
        title: this.obj.title_tag?.[this.$i18n.locale] || this.obj.title,
        alt: this.obj.alt_tag?.[this.$i18n.locale] || this.obj.title,
        gtmSeq: this.obj.sequence,
        active_from: this.obj.active_from,
        active_to: this.obj.active_to,
        update_info: this.obj.update_info?.timestamp,
        banner_name: this.obj.banner_name,
      }
    },
  },
  methods: {
    // onClickBanner(banner, bannerCreative) {
    //   if (banner !== undefined) {
    //     if (this.$utils.isValid(bannerCreative)) {
    //       //gtm promoClicked
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'promoClicked',
    //         ecommerce: {
    //           promoClick: {
    //             promotions: [
    //               {
    //                 id: banner.src,
    //                 name: `HP|${banner.gtmSeq}|${
    //                   banner.title
    //                 }|${this.$utils.formatDateGTM(
    //                   banner.active_from
    //                 )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //                 creative: bannerCreative,
    //                 position: banner.gtmSeq,
    //               },
    //             ],
    //           },
    //         },
    //       })
    //       // gtm GA4
    //       const promotion = {
    //         creative_name: banner.title,
    //         creative_slot: `${banner.title}_${banner.gtmSeq}`,
    //         promotion_id: banner.src,
    //         promotion_name: `HP|${banner.gtmSeq}|${
    //           banner.alt
    //         }|${this.$utils.formatDateGTM(
    //           banner.active_from
    //         )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //       }
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'select_promotion',
    //         ecommerce: {
    //           user: this.$store.getters['user/userInfoTrack'],
    //           ...promotion,
    //         },
    //       })
    //       this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
    //     }
    //   }
    // },
  },
}
