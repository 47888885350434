
import { mapGetters } from 'vuex'

export default {
  name: 'TableCostcenter',
  props: {
    items: { type: Array, default: () => [] },
    filter: { type: String, default: '' },
  },
  data() {
    return {
      itemCount: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'status',
          label: '',
          class: 'text-center fs-12 w-90 border-right',
          sortable: false,
        },
        {
          key: 'username',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'name',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'mobile',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14',
          sortable: false,
        },
        {
          key: 'phone',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14',
          sortable: false,
        },

        {
          key: 'action',
          label: '',
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.items.length / this.perPage)) {
        last = this.items.length
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.items.length === 0) {
        first = 0
        last = 0
      } else if (this.items.length < this.perPage) {
        last = this.items.length
      }
      return first + '-' + last
    },
  },
  watch: {},
  mounted() {
    // this.$nextTick(() => {
    //   this.itemCount = this.items.length
    // })
  },
  methods: {
    // onEdit(id) {
    //   this.$router.push({
    //     path: '/user/' + id,
    //   })
    // },
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
    },
    // onEditRole(id) {
    //   this.$router.push({
    //     path: '../role/manage/setting/' + id,
    //   })
    // },
  },
}
