
import { mapGetters } from 'vuex'

export default {
  name: 'SuggestCouponComponent',
  props: {},
  data() {
    return {
      coupon_code: '',
      promotion_no: '',
      product_include_flag: false,
      couponDetail: '',
    }
  },
  created() {
    if (process.client) {
      this.$nuxt.$on('modalCouponDetail', (data) => {
        this.$nextTick(() => {
          this.coupon_code = data?.coupon_code
          this.couponDetail = data?.promotion_detail_html
          this.promotionNo = data?.promotion_no
          this.product_include_flag = data?.product_include_flag

          this.$refs['modal-coupon-detail'].show()
          console.log('modalCouponDetail', data)
        })
      })
    }
  },
  watch: {},
  beforeDestroy() {
    this.$nuxt.$off('modalCouponDetail')
  },
  computed: {},
  mounted() {},
  methods: {
    onClose() {
      this.$refs['modal-coupon-detail'].hide()
    },
    onCloseDetail() {
      this.$refs['modal-coupon-detail'].hide()
      setTimeout(() => {
        this.couponDetail = ''
        this.promotionNo = ''
      }, 1000)
    },
  },
}
