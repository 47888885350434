
import { AisClearRefinements } from 'vue-instantsearch'
export default {
  name: 'FilterPanelBrand',
  components: {
    AisClearRefinements,
  },
  props: {
    brands: {
      type: Array,
      default: () => {
        return []
      },
    },
    refine: null,
    createURL: null,
  },
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {},
}
