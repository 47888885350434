
export default {
  layout: 'main',
  name: 'Rewards',
  head() {
    return {
      //   title: this.pageTitle,
      //   meta: [
      //     {
      //       hid: 'description',
      //       name: 'description',
      //       content: this.pageContent,
      //     },
      //     {
      //       hid: 'og:type',
      //       property: 'og:type',
      //       content: 'website',
      //     },
      //     {
      //       hid: 'og:title',
      //       property: 'og:title',
      //       content: this.pageTitle,
      //     },
      //     {
      //       hid: 'og:description',
      //       property: 'og:description',
      //       content: this.pageContent,
      //     },
      //     {
      //       hid: 'og:image',
      //       property: 'og:image',
      //       content: this.ogImage,
      //     },
      //   ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`activity/${this.$route.params.url}`),
      //   },
      // ],
    }
  },
  data() {
    return {
      //   breadcrumbItems: [
      //     {
      //       text: 'Home',
      //       href: '/',
      //     },
      //   ],
      //   name: '',
      //   url: '',
      //   title: '',
      //   banner: null,
      //   detail: '',
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  mounted() {
    this.$_onGetInsider({
      page: {
        type: 'Other',
        custom: {
          category_name: null,
        },
      },
    })
  },
}
