
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTotal',
  components: {
    Multiselect,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    BarChart: async () =>
      await import('@/components/dashboard/chartjs/bar.vue'),
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    costcenterOptions: {
      type: Array,
      default: Array,
    },
    // costcenters: { type: Array, default: () => [] },
    // costcenter_all: { type: Array, default: () => [] },
  },
  data() {
    return {
      argumentField: 'label',
      //   dataSourceSumSort: [],
      dataSource: [],
      dataSeries: [],
      chartColor: [
        // '#50C1EE',
        // '#48B9E6',
        // '#40B1DE',
        // '#38A9D6',
        // '#30A1CE',
        // '#2899C6',
        // '#2091BE',
        // '#1889B6',
        // '#1081AE',
        // '#0879A6',
        // '#00719E',
        // '#006996',
        // '#00618E',
        // '#005986',
        // '#00517E',
        // '#004976',
        // '#00416E',
        // '#003966',
        // '#00315E',
        // '#002956',
      ],
      filter: {
        date: '',
        selectTop: { name: this.$t(`company-dashboard.top-10`), value: 10 },
      },
      selectTopOptions: [
        { name: this.$t(`company-dashboard.top-10`), value: 10 },
        { name: this.$t(`company-dashboard.top-20`), value: 20 },
      ],
      sortSummary: '',
      legendCallback: [],
      costcenter_code: [],
      select_top: 10,
      date_from: '',
      date_to: '',
      dataSourceSum: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    // dataSourceSum() {
    //   // sum data to summary
    //   var source = this.dataSource.reduce((source, value) => {
    //     for (const [sourceName, sourceCount] of Object.entries(value)) {
    //       if (!source[sourceName]) {
    //         source[sourceName] = 0
    //       }
    //       source[sourceName] += sourceCount
    //     }
    //     delete source[this.argumentField]
    //     delete source['color']
    //     return source
    //   }, {})
    //   return source
    // },
    maxValue() {
      return Math.max(...Object.values(this.dataSourceSum))
    },
  },
  watch: {
    // dataSource: {
    //   handler: function (val) {
    //     this.getSummaryCostCenter()
    //   },
    //   deep: true,
    // },
  },
  async created() {
    // await this.getData()
    this.sort('')
  },
  mounted() {
    this.getSummaryCostCenter()
    // this.getSummaryCostCenterAll()
  },
  methods: {
    generateChartColor(length) {
      this.chartColor = []
      function hex(c) {
        let s = '0123456789abcdef'
        let i = parseInt(c)
        if (i == 0 || isNaN(c)) return '00'
        i = Math.round(Math.min(Math.max(0, i), 255))
        return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16)
      }

      function convertToHex(rgb) {
        return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2])
      }

      function trim(s) {
        return s.charAt(0) == '#' ? s.substring(1, 7) : s
      }

      function convertToRGB(hex) {
        let color = []
        color[0] = parseInt(trim(hex).substring(0, 2), 16)
        color[1] = parseInt(trim(hex).substring(2, 4), 16)
        color[2] = parseInt(trim(hex).substring(4, 6), 16)
        return color
      }

      function generateColor(colorStart, colorEnd, colorCount) {
        let start = convertToRGB(colorStart)

        let end = convertToRGB(colorEnd)

        let len = colorCount

        let alpha = 0.0

        let saida = []
        let n = 0
        while (n < len) {
          let c = []
          alpha += 1.0 / len

          c[0] = start[0] * alpha + (1 - alpha) * end[0]
          c[1] = start[1] * alpha + (1 - alpha) * end[1]
          c[2] = start[2] * alpha + (1 - alpha) * end[2]

          saida.push(convertToHex(c))
          n++
        }

        return saida
      }

      generateColor('#50C1EE', '#002956', length).forEach((color) => {
        this.chartColor.push('#' + color)
      })
    },
    sort(orderBy) {
      this.sortSummary = orderBy
      //   this.dataSourceSumSort = Object.assign([], this.dataSeries)
      this.dataSeries = this.dataSeries.sort(function (a, b) {
        let sort = ''
        switch (orderBy) {
          case 'min':
            sort = a.value - b.value
            break
          case 'max':
            sort = b.value - a.value
            break
          default:
            sort = a.index - b.index
            break
        }
        return sort
      })
    },
    setLegend(callback) {
      this.legendCallback = callback
      console.log(this.legendCallback)
    },
    monthRangeSelected(event) {
      this.filter.date = event
    },
    async onFilter() {
      console.log('cost', this.filter)
      this.dataSource = []
      this.dataSeries = []
      this.dataSourceSum = []
      this.legendCallback = []
      this.costcenter_code = []
      this.date_from = ''
      this.date_to = ''
      if (
        this.filter.costcenter !== undefined &&
        this.filter.costcenter.length > 0
      ) {
        this.filter.costcenter.forEach((a) => {
          this.costcenter_code.push(a.value)
        })
      }

      this.date_from = this.filter.date.from ?? ''
      this.date_to = this.filter.date.to ?? ''
      this.select_top = this.filter.selectTop.value

      if (
        this.costcenter_code.length === 0 &&
        this.date_from === '' &&
        this.date_to === ''
      ) {
        this.getSummaryCostCenter()
      } else {
        this.getSummaryCostCenterAll()
      }
    },
    async getSummaryCostCenter() {
      let params = this.mapURLParams()
      this.costcenter_code = []
      await this.$reportProvider.getSummaryCostCenterById(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((a, index) => {
              this.costcenter_code.push(a.costcenter_id)
            })
            this.getSummaryCostCenterAll()
          }
        },
        (error) => {
          console.log('getSummaryCostCenterById', error.responsed)
        }
      )
    },
    async getSummaryCostCenterAll() {
      let params = this.mapURLParams()
      let _dataSource = []
      this.dataSource = []
      await this.$reportProvider.getSummaryCostCenterAllById(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            // console.warn('111111111111111 :>> ', data)

            if (Array.isArray(data)) {
              data.forEach((a) => {
                let object = {}
                let key = ''
                key = 'label'
                object[key] = this.mapMonth(a.month.toString())
                let result = a.costCenters
                  .filter((e) => e.month === a.month)
                  .sort(function (a, b) {
                    return a.total_amt - b.total_amt
                  })
                result.forEach((b) => {
                  key = b.costcenter_code
                  object[key] = b.total_amt
                })
                _dataSource.push(object)
              })
              this.dataSource = _dataSource
              // console.warn('99999999999999999 :>> ', this.dataSource)

              this.dataSourceSum = _dataSource.reduce((source, value) => {
                for (const [sourceName, sourceCount] of Object.entries(value)) {
                  if (!source[sourceName]) {
                    source[sourceName] = 0
                  }
                  source[sourceName] += sourceCount
                }
                delete source[this.argumentField]
                delete source['color']
                return source
              }, {})
            }
            let _dataSeries = []
            this.$reportProvider.getSummaryCostCenterById(params).then(
              (result) => {
                if (result.status === 200) {
                  let data = result.data
                  data.forEach((a, index) => {
                    _dataSeries.push({
                      index: index + 1,
                      label: a.costcenter_code,
                      name: a.costcenter_code,
                    })
                  })
                  console.log('check', this.dataSourceSum)
                  _dataSeries.forEach((element) => {
                    element.value = this.dataSourceSum[element.label] || 0
                  })
                  _dataSeries = _dataSeries.sort(function (a, b) {
                    return a.value - b.value
                  })
                  // _dataSeries.forEach((element, index) => {
                  //   element.color = this.chartColor[
                  //     _dataSeries.length > 10 ? index : index + 1
                  //   ]
                  // })
                  _dataSeries = _dataSeries.sort(function (a, b) {
                    return a.index - b.index
                  })
                  this.generateChartColor(_dataSeries.length)
                  _dataSeries.forEach((element, index) => {
                    element.color = this.chartColor[index]
                  })

                  this.dataSeries = _dataSeries
                  // }
                }
              },
              (error) => {
                console.log('getSummaryCostCenterById', error.responsed)
              }
            )
          }
        },
        (error) => {
          console.log('getSummaryCostCenterAllById', error.responsed)
        }
      )
    },
    mapURLParams() {
      console.warn('object :>> ', this.costcenter_code)
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      if (
        this.costcenter_code !== undefined &&
        this.costcenter_code.length > 0
      ) {
        this.costcenter_code.forEach((s) => {
          console.log('object :>> ', s)
          params.append('code', s)
        })
      }
      if (this.date_from !== '' && this.date_to !== '') {
        params.append('date_from', this.date_from)
        params.append('date_to', this.date_to)
      }
      params.append('filter', this.select_top)
      return params
    },
    mapMonth(month) {
      let month1 = Intl.DateTimeFormat('en', { month: 'long' }).format(
        new Date(month)
      )
      return month1
    },
  },
}
