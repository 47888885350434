
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
// import datePicker from '@/components/share/date-picker.vue'
import TableReportOrder from '@/components/company/table-report-order.vue'
import { exportExcelOrderPrintingReportDocument } from '@/plugins/export-excel.js'
import notfound from '@/components/share/notfound.vue'

export default {
  layout: 'main',
  name: 'CompanyReportOrder',
  components: {
    'aside-component': asideComponent,
    Multiselect,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    TableReportOrder,
    notfound,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      itemCount: 0,
      filter: {
        selected: [],
        order_status: [],
        order_id: '',
        order: [],
        approver: [],
        department: [],
        approve_date_from: '',
        approve_date_to: '',
        requester_date_from: '',
        requester_date_to: '',
      },
      approverData: [],
      // optionsApprover: [
      //   {
      //     index: 1,
      //     email: 'approver_mitsu_0155@ofm.co.th',
      //     text: '(คุณเอพพรูบมิตซูศูนย์หนึ่ง มิตซูศูนย์หนึ่ง)',
      //   },
      //   {
      //     index: 2,
      //     email: 'approver_mitsu_02@ofm.co.th',
      //     text: '(คุณเอพพรูบมิตซูศูนย์สอง มิตซูศูนย์สอง)',
      //   },
      // ],
      orderData: [],
      // optionsOrder: [
      //   {
      //     index: 1,
      //     email: 'approver_mitsu_01@ofm.co.th',
      //     text: '(คุณเอพพรูบมิตซูศูนย์หนึ่ง มิตซูศูนย์หนึ่ง)',
      //   },
      //   {
      //     index: 2,
      //     email: 'approver_mitsu_02@ofm.co.th',
      //     text: '(คุณเอพพรูบมิตซูศูนย์สอง มิตซูศูนย์สอง)',
      //   },
      // ],
      departmentData: [],
      // optionsDepartment: [
      //   {
      //     index: 1,
      //     id: 'SALE',
      //     text: 'แผนกขาย',
      //   },
      //   {
      //     index: 2,
      //     id: 'ACCOUNT',
      //     text: 'แผนกบัญชี',
      //   },
      // ],
      itemsExportExcel: [],
      items: [],
      requesterLine: [],
      optionsApprover: [],
      optionsOrder: [],
      optionsDepartment: [],
      isMsg: false,
      is_goods_receive_use_approval: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'userCostcenter', 'profileType']),
    isUsePrintingMenuReport() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 1 || a.role_id === 2)
          .length > 0
      )
    },
  },
  watch: {},
  mounted() {
    // this.getUserById()
    this.getRoleApproverEmail()
    this.getRoleRequestersEmail()
    this.getNameCostcenter()
    this.getCompaniesInfo()
    // this.onSearch()
  },
  methods: {
    async getCompaniesInfo() {
      const res = await this.$profileProvider.getCompaniesDataById(
        this.userProfile.id
      )
      if (res.status === 200) {
        let data = res.data
        if (data.is_goods_receive_use_approval) {
          this.is_goods_receive_use_approval = true
        } else {
          this.is_goods_receive_use_approval = false
        }
      }
    },
    async getRoleApproverEmail() {
      await this.$profileProvider
        .getRoleApproverEmailById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsApprover = []
              let data = result.data
              data.forEach((s) => {
                this.optionsApprover.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleApproverEmail', error.responsed)
          }
        )
    },
    async getRoleRequestersEmail() {
      await this.$profileProvider
        .getRoleRequestersEmailById(this.userProfile.id, false)
        .then(
          (result) => {
            if (result.status === 200) {
              this.optionsOrder = []
              let data = result.data
              data.forEach((s) => {
                this.optionsOrder.push({
                  value: s.user_open_id,
                  email: s.email,
                  text: '(' + s.first_name.th + ' ' + s.last_name.th + ')',
                })
              })
            }
          },
          (error) => {
            console.log('getRoleRequestersEmail', error.responsed)
          }
        )
    },
    async getNameCostcenter() {
      await this.$profileProvider
        .getNameCostcenterData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              this.optionsDepartment = []
              data.forEach((e, index) => {
                this.optionsDepartment.push({
                  index: index + 1,
                  id: e.code,
                  text: e.name.th,
                  value: e.id,
                })
              })
            }
          },
          (error) => {
            console.log('getNameCostcenterData', error.responsed)
          }
        )
    },
    async getOrderReport(pageNo, isExcel) {
      this.filter.order_status = []
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('order_id', this.filter.order_id)
      params.append('pageNo', pageNo ?? 0)
      params.append('approve_date_from', this.filter.approve_date_from ?? '')
      params.append('approve_date_to', this.filter.approve_date_to ?? '')
      params.append('is_excel', isExcel ?? false)
      params.append(
        'requester_date_from',
        this.filter.requester_date_from ?? ''
      )
      params.append('requester_date_to', this.filter.requester_date_to ?? '')
      params.append('pageNo', pageNo ?? 0)
      this.filter.selected.forEach((a) => {
        params.append('order_status', a)
      })
      this.filter.department.forEach((a) => {
        params.append('coscenter_id', a.value)
      })
      this.filter.approver.forEach((a) => {
        params.append('approver_id', a.value)
      })
      this.filter.order.forEach((a) => {
        params.append('requester_id', a.value)
      })
      console.log('isExcel', isExcel)
      await this.$bizCardProvider.getOrderReportPrinting(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('getOrderReport :>> ', data)
            if (Array.isArray(data)) {
              if (isExcel) {
                data.forEach((a) => {
                  this.itemsExportExcel.push({
                    index: a.row,
                    id: a.id,
                    cusID: a.account_offline_id,
                    companyCode: a.company_code, // company code
                    orderID: a.order_id,
                    status: a.order_status,
                    orderDate: a.order_date,
                    approveDate: a.approval_date,
                    orderName:
                      this.$i18n.lang === 'th'
                        ? a.contact_name_th
                        : a.contact_name_en,
                    approveName:
                      this.$i18n.lang === 'th'
                        ? a.approver_name_th
                        : a.approver_name_en,
                    departmentID: a.costcenter_code,
                    departmentName:
                      this.$i18n.lang === 'th'
                        ? a.costcenter_name_th
                        : a.costcenter_name_en,
                    templateName:
                      this.$i18n.lang === 'th'
                        ? a.template_name_th
                        : a.template_name_en, // ชื่อนามบัตร (template_name_th,template_name_en)
                    productName:
                      this.$i18n.lang === 'th'
                        ? a.product_name_th
                        : a.product_name_en, // วัสดุ (product_name_th,product_name_en)
                    templateDescription:
                      this.$i18n.lang === 'th'
                        ? a.template_description_th
                        : a.template_description_en, // รายละเอียด (template_description_th,template_description_en)
                    displayQuantity: a.display_quantity, // จำนวนสินค้า (display_quantity)
                    discount: a.discount_rate,
                    price_disc_excvat: a.price_disc_excvat,
                    price_disc_incvat: a.price_disc_incvat,
                    vat_prod_net_amt: a.vat_prod_net_amt,
                    totalPrice:
                      (a.price_disc_incvat * a.display_quantity) / 100,
                  })
                })
              } else {
                data.forEach((a) => {
                  this.itemCount = a.total_row
                  this.items.push({
                    index: a.row,
                    id: a.id,
                    cusID: a.account_offline_id,
                    companyCode: a.company_code, // company code
                    orderID: a.order_id,
                    status: a.order_status,
                    orderDate: a.order_date,
                    approveDate: a.approval_date,
                    orderName:
                      this.$i18n.lang === 'th'
                        ? a.contact_name_th
                        : a.contact_name_en,
                    approveName:
                      this.$i18n.lang === 'th'
                        ? a.approver_name_th
                        : a.approver_name_en,
                    departmentID: a.costcenter_code,
                    departmentName:
                      this.$i18n.lang === 'th'
                        ? a.costcenter_name_th
                        : a.costcenter_name_en,
                    templateName:
                      this.$i18n.lang === 'th'
                        ? a.template_name_th
                        : a.template_name_en, // ชื่อนามบัตร (template_name_th,template_name_en)
                    productName:
                      this.$i18n.lang === 'th'
                        ? a.product_name_th
                        : a.product_name_en, // วัสดุ (product_name_th,product_name_en)
                    templateDescription:
                      this.$i18n.lang === 'th'
                        ? a.template_description_th
                        : a.template_description_en, // รายละเอียด (template_description_th,template_description_en)
                    displayQuantity: a.display_quantity, // จำนวนสินค้า (display_quantity)
                    discount: a.discount_rate,
                    price_disc_excvat: a.price_disc_excvat,
                    price_disc_incvat: a.price_disc_incvat,
                    vat_prod_net_amt: a.vat_prod_net_amt,
                    totalPrice: a.grand_total_amt,
                  })
                })
                this.isMsg = false
              }
            }
            if (this.items.length === 0) {
              this.itemCount = 0
              this.isMsg = true
            }
          } else if (result.status === 412) {
            this.$bvToast.toast(
              this.$i18n.locale === 'th'
                ? result.data.error_info.message_th
                : result.data.error_info.message_en,
              {
                title: `Warning`,
                variant: 'warning',
                solid: true,
              }
            )
          }
        },
        (error) => {
          console.log('getOrderReport', error.responsed)
        }
      )
    },
    // userOrderRangeSelected(event) {
    //   this.filter.date = event
    // },
    async exportExcel(isExcel) {
      this.itemsExportExcel = []
      await this.getOrderReport(0, isExcel)
      exportExcelOrderPrintingReportDocument(this.itemsExportExcel)
    },
    onAddApprover() {
      this.$nextTick(() => {
        if (this.approverData) this.filter.approver.push(this.approverData)
        this.approverData = ''

        // disabled approver option
        this.filter.approver.forEach((v) => {
          this.optionsApprover[
            this.optionsApprover.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveApprover(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsApprover[
          this.optionsApprover.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove approver
        this.filter.approver.splice(
          this.filter.approver.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onAddOrder() {
      this.$nextTick(() => {
        if (this.orderData) this.filter.order.push(this.orderData)
        this.orderData = ''

        // disabled order option
        this.filter.order.forEach((v) => {
          this.optionsOrder[
            this.optionsOrder.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveOrder(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsOrder[
          this.optionsOrder.findIndex((e) => e.email === email)
        ]['$isDisabled'] = false
        // remove order
        this.filter.order.splice(
          this.filter.order.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onAddDepartment() {
      this.$nextTick(() => {
        if (this.departmentData)
          this.filter.department.push(this.departmentData)
        this.departmentData = ''

        // disabled order option
        this.filter.department.forEach((v) => {
          this.optionsDepartment[
            this.optionsDepartment.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveDepartment(id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsDepartment[
          this.optionsDepartment.findIndex((e) => e.id === id)
        ]['$isDisabled'] = false
        // remove department
        this.filter.department.splice(
          this.filter.department.findIndex((e) => e.id === id),
          1
        )
      })
    },
    async onSearch() {
      this.items = []
      console.warn('filter :>> ', this.filter)
      let keys = Object.keys(this.filter)
      let validateInputCount = 0

      Object.keys(this.filter).forEach((fieldName) => {
        // console.log('fieldName', typeof this.filter[fieldName])
        if (typeof this.filter[fieldName] === 'object') {
          if (Object.keys(this.filter[fieldName]).length === 0) {
            // console.log(fieldName)
            validateInputCount++
          }
        }
        if (
          this.filter[fieldName] === '' ||
          this.filter[fieldName] === undefined
        ) {
          validateInputCount++
        }
      })
      // console.log(validateInputCount + '===' + keys.length)

      if (validateInputCount === keys.length) {
        this.$bvToast.toast('กรุณาระบุเงื่อนไขการค้นหา', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        })
        return
      }
      await this.getOrderReport()
    },
    onReset() {
      this.filter = {
        selected: [],
        order_status: [],
        // dateFrom: '',
        // dateTo: '',
        requester_date_from: '',
        requester_date_to: '',
        approve_date_from: '',
        approve_date_to: '',
        order_id: '',
        approver: [],
        department: [],
        order: [],
      }
      // this.filter.order_id = ''

      // reset option
      this.approverData = []
      this.orderData = []
      this.departmentData = []
      this.optionsOrder.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsApprover.forEach((e) => {
        e['$isDisabled'] = false
      })
      this.optionsDepartment.forEach((e) => {
        e['$isDisabled'] = false
      })
    },
    dateApproveSelected(event) {
      this.filter.approve_date_from = event.from
      this.filter.approve_date_to = event.to
    },
    dateRequesterSelected(event) {
      this.filter.requester_date_from = event.from
      this.filter.requester_date_to = event.to
    },
  },
}
