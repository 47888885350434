
export default {
  name: 'SummaryComponent',
  props: {
    isCheckout: { type: Boolean, default: false },
    isUseBudget: { type: Boolean, default: false },
    summary: { type: Object, default: Object },
    checkoutText: { type: String, default: String },
    budget: { type: Object, default: Object },
    isEprocuerment: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isAlertCreditCardPromotionOnTop: { type: Boolean, default: false },
    isPrinting: { type: Boolean, default: false },
    segment: { type: String, default: '' },
    isUseCoupons: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
  },
  data() {
    return {
      couponsType: [],
      isCouponCollapsed: false,
      isCodeCollapsed: true,
    }
  },
  watch: {
    summary: {
      handler: function (val) {
        console.log('summary', val)
        if (Object.keys(val).length) this.getHeight()
        if (!this.summary.delivery_fee_voucher_no && !this.summary.voucher_no) {
          this.isCouponCollapsed = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const _this = this
    window.addEventListener('resize', function () {
      _this.getHeight()
    })
    this.couponsType = ['Platform', 'NewCustomer', 'DeliveryDiscount']
  },
  computed: {
    alertVoucher_no() {
      console.log('alertVoucher_no', this.summary)
      if (Object.keys(this.summary).length) {
        return this.$utils.isValid(this.summary.voucher_no) &&
          this.summary.is_use_coupon == false
          ? true
          : false
      } else return false
    },
    alertVoucher_delivery() {
      if (Object.keys(this.summary).length) {
        return this.$utils.isValid(this.summary.delivery_fee_voucher_no) &&
          this.summary.is_use_coupon_delivery == false
          ? true
          : false
      } else return false
    },
    isPaymentDiscAmtExcvat() {
      return (
        this.$utils.isObjectValid(this.summary) &&
        this.$utils.isValid(this.summary.payment_disc_amt_excvat) &&
        (this.summary.payment_disc_amt_excvat > 0 ||
          this.isAlertCreditCardPromotionOnTop)
      )
    },
    summaryPaymentDiscAmtExcvat() {
      return this.isPaymentDiscAmtExcvat &&
        this.summary.payment_disc_amt_excvat > 0
        ? this.summary.payment_disc_amt_excvat * -1
        : 0
    },
  },
  methods: {
    getHeight() {
      setTimeout(() => {
        console.log('getHeight')
        let height = this.$refs['summary-checkout']
          ? this.$refs['summary-checkout'].clientHeight
          : 0
        if (height > 0) this.$emit('collapseSummary', height)
      }, 50)
    },
    async onRemoveVoucher(type) {
      // this.$root.$emit('bv::toggle::collapse', 'coupon-collapse', false)
      this.$emit('onRemoveVoucher', type)
    },
    onFilterWarning(group, key) {
      return group?.warning_messages?.filter((msg) => msg.key === key)[0]
    },
  },
}
