
export default {
  name: 'ListOrderProcess',
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      fields: [
        {
          key: 'create_info',
          label: this.$t('orderActivityComponent.timestamp'),
          thClass: 'text-left w-180',
          sortable: false,
        },
        {
          key: 'name',
          label: this.$t('orderActivityComponent.name'),
          thClass: 'text-left',
          sortable: false,
        },
        {
          key: 'detail',
          label: this.$t('orderActivityComponent.detail'),
          thClass: 'text-left',
          sortable: false,
        },
        {
          key: 'remark',
          label: this.$t('orderActivityComponent.remark'),
          thClass: 'text-left',
          sortable: false,
        },
      ],
    }
  },
}
