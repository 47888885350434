
export default {
  name: 'ListOtherSellerComponent',
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
    // console.log(this.item)
  },
  methods: {},
}
