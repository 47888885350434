
export default {
  layout: 'blank-nav',
  name: '123Payment',
  components: {},
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'purchase',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      paymentDetail: {
        qrcode: '',
        barcode: '',
      },
    }
  },
  async mounted() {
    //validate order id
    if (
      this.$route.params === null ||
      !Object.keys(this.$route.params).length ||
      !this.$utils.isValid(this.$route.params.id)
    ) {
      //TODO await this.get2c2p123PaymentDetail(this.$route.params.id)
    }

    // validate paymentCode
    if (
      this.$route.query !== null &&
      Object.keys(this.$route.query).length &&
      this.$utils.isValid(this.$route.query.paymentCode)
    ) {
      await this.get2c2p123PaymentDetail(this.$route.query.paymentCode)
    }
  },
  methods: {
    async get2c2p123PaymentDetail(paymentCode) {
      const result = await this.$orderProvider.get2c2p123PaymentDetail(
        paymentCode
      )
      if (result.status === 200) {
        this.paymentDetail = result.data
      } else {
        //TODO
      }
    },
    onClickPrintPayment() {
      document.title = this.paymentDetail.merchant_reference
      window.print()
    },
    onClickContinue() {
      // this.$router.push({ path: this.$i18n.path('') })
      let redirectLink = this.checkRedirectLink()
      console.log('redirectLink', redirectLink)
      this.$router.push({
        path: this.$i18n.path(redirectLink.path),
        name: redirectLink.name,
        params: redirectLink.params,
      })
    },
    checkRedirectLink() {
      let redirectLink = {
        path: '',
        name: '',
        params: {},
      }
      if (
        this.$route.query !== null &&
        Object.keys(this.$route.query).length &&
        this.$utils.isValid(this.$route.query.callback)
      ) {
        redirectLink.path = this.$route.query.callback
        if (this.$i18n.locale === 'th') {
          if (this.$route.query.callback === 'checkout/complete')
            redirectLink.name = 'checkout-complete-orderId'
          else redirectLink.name = 'account-order-id'
        } else {
          if (this.$route.query.callback === 'checkout/complete')
            redirectLink.name = 'lang-checkout-complete-orderId'
          else redirectLink.name = 'lang-account-order-id'
        }
      } else {
        redirectLink.name =
          this.$i18n.locale === 'th'
            ? 'checkout-complete-orderId'
            : 'lang-checkout-complete-orderId'
      }
      redirectLink.params =
        this.$route.query.callback === 'checkout/complete'
          ? { orderId: this.$route.params.id }
          : { id: this.$route.params.id }
      return redirectLink
    },
  },
}
