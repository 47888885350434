
import InputQty from '@/components/share/input-qty.vue'
export default {
  name: 'ListItemCatalog',
  components: {
    'input-qty': InputQty,
  },
  props: {
    catalogId: { type: String, default: '' },
  },
  data() {
    return {
      catalog: [
        {
          catId: 'cat001',
          items: [
            {
              index: 1,
              productId: 'OFMY000631',
              productName:
                'ต้นคริสต์มาส ชนิดต้นสด KASSA HOME Tree Size XL พร้อมฐานรอง ขนาด 245-280 ซม. สีเขียว',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/y/0/y000631.jpg?imwidth=320',
              productPrice: 6980,
              productDiscount: 1000,
              productPriceNormal: 7980,
              qty: 1,
            },
            {
              index: 2,
              productId: 'OFMY000630',
              productName:
                'ต้นคริสต์มาส ชนิดต้นสด KASSA HOME Tree Size L พร้อมฐานรอง ขนาด 214-245 ซม. สีเขียว',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/y/0/y000630.jpg?imwidth=320',
              productPrice: 5980,
              productDiscount: 1000,
              productPriceNormal: 6980,
              qty: 1,
            },
            {
              index: 3,
              productId: 'MKP0303375',
              productName:
                'ต้นคริสต์มาส ชนิดต้นสด KASSA HOME Tree Size L พร้อมฐานรอง ขนาด 214-245 ซม. สีเขียว',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/h/t/httpss3-ap-southeast-1.amazonaws.compim-prod-product-images7aa27aa2e5f0deb369c166afbdcce06ca4e6a612cfe0_bc34cbecb2884fdbbc95f4aa816bea66dummy.jpg?imwidth=640',
              productPrice: 1080,
              productDiscount: 210,
              productPriceNormal: 1290,
              qty: 1,
            },
            {
              index: 4,
              productId: 'OFM9001167',
              productName:
                'หน้ากากคาร์บอน PM2.5 วาล์วคู่ 1ชิ้น/แพ็ค YAMADA 8242',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM9001167.jpg?imwidth=640',
              productPrice: 23,
              productDiscount: 0,
              productPriceNormal: 23,
              qty: 1,
            },
          ],
        },
        {
          catId: 'cat002',
          items: [
            {
              index: 1,
              productId: 'OFM4007354',
              productName: 'เครื่องปริ้นเตอร์เลเซอร์ HP 1000w',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/4/0/4007354.jpg?imwidth=320',
              productPrice: 8390,
              productDiscount: 0,
              subCatagories: 101,
              qty: 1,
            },
            {
              index: 2,
              productId: 'OFM4003395',
              productName: 'ผงหมึก สีดำ FujiXerox CT202137',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
              productPrice: 990,
              productDiscount: 0,
              subCatagories: 101,
              qty: 1,
            },
            {
              index: 3,
              productId: 'OFM4550005',
              productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
              productImg:
                'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
              productPrice: 7990,
              productDiscount: 0,
              subCatagories: 101,
              qty: 1,
            },
          ],
        },
      ],
    }
  },
  computed: {
    catalogItems() {
      return this.catalog[
        this.catalog.findIndex((x) => x.catId === this.catalogId)
      ]
    },
  },
  methods: {
    addToCart() {
      console.log(this.catalogItems)
    },
  },
}
