import { render, staticRenderFns } from "./_tempId.vue?vue&type=template&id=71a79504"
import script from "./_tempId.vue?vue&type=script&lang=js"
export * from "./_tempId.vue?vue&type=script&lang=js"
import style0 from "./_tempId.vue?vue&type=style&index=0&id=71a79504&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApproverDropdown: require('/usr/src/nuxt-app/components/checkout/approver-dropdown.vue').default,SearchSelect: require('/usr/src/nuxt-app/components/share/search-select.vue').default,InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,DeliveryItems: require('/usr/src/nuxt-app/components/checkout/delivery-items.vue').default,SummaryComponent: require('/usr/src/nuxt-app/components/checkout/summary-component.vue').default,ModalWarning: require('/usr/src/nuxt-app/components/modal/modalWarning.vue').default})
