
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      userDetail: {},
    }
  },
  computed: {
    ...mapGetters('authen', ['user']),
    getGroupPermission() {
      let permission = this.$authen.getters.getPermission
      if (Object.keys(permission).length) return permission.groups
      else return ''
    },
  },
  mounted() {},
  methods: {
    getPrefixName() {
      console.log(this.user)
      // let authenData = this.user
      // let matches = authenData.fullnameeenglish.match(/\b(\w)/g)
      // let acronym = matches.join('')
      // return acronym
    },
  },
}
