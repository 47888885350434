
export default {
  layout: 'blank-nav',
  name: 'RequestCredit',
  components: {},
  data() {
    return {
      //   reloadResgiter: true,
      //   duplicateEmailFlag: false,
      //   dataForRequest: {
      //     // page 1
      //     company: '',
      //     customerId: '',
      //     invest: '',
      //     businessType: {},
      //     website: '',
      //     address: {
      //       address_1: '',
      //       address_2: '',
      //       province: '',
      //       zipcode: '',
      //       district: '',
      //       sub_district: '',
      //     },
      //     employee: {},
      //     amountPerMonth: {},
      //     workingDay: {},
      //     workingTime: {},
      //     contactName: '',
      //     contactPosition: '',
      //     contactPhone: '',
      //     contactMobile: '',
      //     contactFax: '',
      //     contactEmail: '',
      //     contactDate: '',
      //     // page 2
      //     selectedDelivery: '',
      //     deliveryAddress: {
      //       address_1: '',
      //       address_2: '',
      //       province: '',
      //       zipcode: '',
      //       district: '',
      //       sub_district: '',
      //     },
      //     selectedPO: '',
      //     selectedBill: '',
      //     selectedBillDate_1: '',
      //     selectedBillDate_2: '',
      //     selectedBillPO: '',
      //     selectedBillAddress: '',
      //     billAddress: {
      //       address_1: '',
      //       address_2: '',
      //       province: '',
      //       zipcode: '',
      //       district: '',
      //       sub_district: '',
      //     },
      //     contactBillName: '',
      //     contactBillDepartment: '',
      //     contactBillPhone: '',
      //     contactBillFax: '',
      //     // page 3
      //     selectedPayment: '',
      //     paymentAddress: {
      //       address_1: '',
      //       address_2: '',
      //       province: '',
      //       zipcode: '',
      //       district: '',
      //       sub_district: '',
      //     },
      //     paymentName: '',
      //     paymentDepartment: '',
      //     paymentPhone: '',
      //     paymentFax: '',
      //     selectedCheque: '',
      //     chequeAddress: {
      //       address_1: '',
      //       address_2: '',
      //       province: '',
      //       zipcode: '',
      //       district: '',
      //       sub_district: '',
      //     },
      //     paymentBank: {},
      //     paymentCheque: '',
      //     selectedSchedule: '',
      //     scheduleOther: '',
      //     paymentBillDate: '',
      //     paymentChequeExample: '',
      //   },
      //   verify: 0,
      //   hasVerify: false,
      //   openId: '',
      //   options: {
      //     businessType: [],
      //     province: [],
      //     district: [],
      //     sub_district: [],
      //     employee: [
      //       {
      //         value: 100,
      //         text: '100 คน',
      //       },
      //     ],
      //     // delivery
      //     provinceDelivery: [],
      //     districtDelivery: [],
      //     sub_districtDelivery: [],
      //     // bill
      //     provinceBill: [],
      //     districtBill: [],
      //     sub_districtBill: [],
      //     // payment
      //     provincePayment: [],
      //     districtPayment: [],
      //     sub_districtPayment: [],
      //     // cheque
      //     provinceCheque: [],
      //     districtCheque: [],
      //     sub_districtCheque: [],
      //     amountPerMonth: [],
      //     workingDay: [],
      //     workingTime: [],
      //     paymentBank: [],
      //   },
      //   zoneList: [],
      //   zoneListDelivery: [],
      //   zoneListBill: [],
      //   zoneListPayment: [],
      //   zoneListCheque: [],
    }
  },
  computed: {},
  watch: {
    // 'dataForRequest.address.district': function (val) {
    //   console.log('val', val)
    //   let filterZone = this.zoneList.filter((q) => q.district.th === val.th)
    //   this.options.sub_district = []
    //   if (filterZone != undefined && filterZone.length) {
    //     filterZone.forEach((item) => {
    //       this.options.sub_district.push({
    //         text: item.sub_district.th,
    //         value: item.sub_district,
    //       })
    //     })
    //   }
    // },
    // 'dataForRequest.deliveryAddress.district': function (val) {
    //   console.log('val', val)
    //   let filterZone = this.zoneListDelivery.filter(
    //     (q) => q.district.th === val.th
    //   )
    //   this.options.sub_districtDelivery = []
    //   if (filterZone != undefined && filterZone.length) {
    //     filterZone.forEach((item) => {
    //       this.options.sub_districtDelivery.push({
    //         text: item.sub_district.th,
    //         value: item.sub_district,
    //       })
    //     })
    //   }
    // },
    // 'dataForRequest.billAddress.district': function (val) {
    //   console.log('val', val)
    //   let filterZone = this.zoneListBill.filter((q) => q.district.th === val.th)
    //   this.options.sub_districtBill = []
    //   if (filterZone != undefined && filterZone.length) {
    //     filterZone.forEach((item) => {
    //       this.options.sub_districtBill.push({
    //         text: item.sub_district.th,
    //         value: item.sub_district,
    //       })
    //     })
    //   }
    // },
    // 'dataForRequest.paymentAddress.district': function (val) {
    //   console.log('val', val)
    //   let filterZone = this.zoneListPayment.filter(
    //     (q) => q.district.th === val.th
    //   )
    //   this.options.sub_districtPayment = []
    //   if (filterZone != undefined && filterZone.length) {
    //     filterZone.forEach((item) => {
    //       this.options.sub_districtPayment.push({
    //         text: item.sub_district.th,
    //         value: item.sub_district,
    //       })
    //     })
    //   }
    // },
    // 'dataForRequest.chequeAddress.district': function (val) {
    //   console.log('val', val)
    //   let filterZone = this.zoneListCheque.filter(
    //     (q) => q.district.th === val.th
    //   )
    //   this.options.sub_districtCheque = []
    //   if (filterZone != undefined && filterZone.length) {
    //     filterZone.forEach((item) => {
    //       this.options.sub_districtCheque.push({
    //         text: item.sub_district.th,
    //         value: item.sub_district,
    //       })
    //     })
    //   }
    // },
    // 'dataForRequest.selectedDelivery': {
    //   handler: function () {
    //     this.dataForRequest.deliveryAddress.address_1 = ''
    //     this.dataForRequest.deliveryAddress.address_2 = ''
    //     this.dataForRequest.deliveryAddress.zipcode = ''
    //     this.dataForRequest.deliveryAddress.sub_district = ''
    //     this.dataForRequest.deliveryAddress.district = ''
    //     this.dataForRequest.deliveryAddress.province = ''
    //     this.options.sub_districtDelivery = []
    //     this.options.districtDelivery = []
    //     this.options.provinceDelivery = []
    //   },
    //   deep: true,
    // },
    // 'dataForRequest.selectedBillAddress': {
    //   handler: function () {
    //     this.dataForRequest.billAddress.address_1 = ''
    //     this.dataForRequest.billAddress.address_2 = ''
    //     this.dataForRequest.billAddress.zipcode = ''
    //     this.dataForRequest.billAddress.sub_district = ''
    //     this.dataForRequest.billAddress.district = ''
    //     this.dataForRequest.billAddress.province = ''
    //     this.options.sub_districtBill = []
    //     this.options.districtBill = []
    //     this.options.provinceBill = []
    //   },
    //   deep: true,
    // },
    // 'dataForRequest.selectedPayment': {
    //   handler: function () {
    //     this.dataForRequest.paymentAddress.address_1 = ''
    //     this.dataForRequest.paymentAddress.address_2 = ''
    //     this.dataForRequest.paymentAddress.zipcode = ''
    //     this.dataForRequest.paymentAddress.sub_district = ''
    //     this.dataForRequest.paymentAddress.district = ''
    //     this.dataForRequest.paymentAddress.province = ''
    //     this.options.sub_districtPayment = []
    //     this.options.districtPayment = []
    //     this.options.provincePayment = []
    //   },
    //   deep: true,
    // },
    // 'dataForRequest.selectedCheque': {
    //   handler: function () {
    //     this.dataForRequest.chequeAddress.address_1 = ''
    //     this.dataForRequest.chequeAddress.address_2 = ''
    //     this.dataForRequest.chequeAddress.zipcode = ''
    //     this.dataForRequest.chequeAddress.sub_district = ''
    //     this.dataForRequest.chequeAddress.district = ''
    //     this.dataForRequest.chequeAddress.province = ''
    //     this.options.sub_districtCheque = []
    //     this.options.districtCheque = []
    //     this.options.provinceCheque = []
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    // onLog(date) {
    //   console.log(date)
    // },
    // async searchZone(event, target) {
    //   if (event.length >= 5) {
    //     // var data
    //     var zoneList = ''
    //     var district = ''
    //     var sub_district = ''
    //     var province = ''
    //     var address = ''
    //     if (target === 'company') {
    //       zoneList = 'zoneList'
    //       district = 'district'
    //       sub_district = 'sub_district'
    //       province = 'province'
    //       address = 'address'
    //     } else if (target === 'delivery') {
    //       zoneList = 'zoneListDelivery'
    //       district = 'districtDelivery'
    //       sub_district = 'sub_districtDelivery'
    //       province = 'provinceDelivery'
    //       address = 'deliveryAddress'
    //     } else if (target === 'bill') {
    //       zoneList = 'zoneListBill'
    //       district = 'districtBill'
    //       sub_district = 'sub_districtBill'
    //       province = 'provinceBill'
    //       address = 'billAddress'
    //     } else if (target === 'payment') {
    //       zoneList = 'zoneListPayment'
    //       district = 'districtPayment'
    //       sub_district = 'sub_districtPayment'
    //       province = 'provincePayment'
    //       address = 'paymentAddress'
    //     } else if (target === 'cheque') {
    //       zoneList = 'zoneListCheque'
    //       district = 'districtCheque'
    //       sub_district = 'sub_districtCheque'
    //       province = 'provinceCheque'
    //       address = 'chequeAddress'
    //     }
    //     const params = new URLSearchParams()
    //     params.append('keyword', event)
    //     await this.$logisticProvider
    //       .getZone(params)
    //       .then((result) => {
    //         console.log(result.data)
    //         this[zoneList] = result.data
    //         if (result.data.length) {
    //           result.data.forEach((item) => {
    //             if (
    //               this.options[district].find(
    //                 (q) => q.value.th === item.district.th
    //               ) === undefined
    //             ) {
    //               this.options[district].push({
    //                 text: item.district.th,
    //                 value: item.district,
    //               })
    //             }
    //           })
    //           this.options[province].push({
    //             text: result.data[0].province.th,
    //             value: result.data[0].province,
    //           })
    //           this.dataForRequest[address].province = result.data[0].province
    //         }
    //       })
    //       .catch((err) => {
    //         console.log('searchZone', err)
    //       })
    //       .finally(() => {
    //         console.log('finally!!')
    //       })
    //   } else {
    //     this.dataForRequest[address].sub_district = ''
    //     this.dataForRequest[address].district = ''
    //     this.dataForRequest[address].province = ''
    //     this.options[sub_district] = []
    //     this.options[district] = []
    //     this.options[province] = []
    //   }
    // },
    // async checkEmail() {
    //   console.log('before validate email')
    //   if (this.dataForRequest.email.includes('@')) {
    //     const validateResult = this.$refs['อีเมล'].validationInput()
    //     console.log('validateResult', validateResult)
    //     if (validateResult) {
    //       const responsed = await this.$profileProvider.checkEmail(
    //         this.dataForRequest.email
    //       )
    //       console.log('responsed', responsed)
    //       if (responsed.status === 200) this.duplicateEmailFlag = true
    //       else if (responsed.status === 404) this.duplicateEmailFlag = false
    //     }
    //   }
    // },
    // validateBeforeSubmit() {
    //   console.log(this.dataForRequest.selectedDelivery)
    //   this.$refs.registerForm.validate().then((result) => {
    //     if (result) {
    //       this.register()
    //     }
    //   })
    // },
    // async register() {
    //   const password = this.$utils.cryptoJS(this.dataForRequest.password)
    //   let apiData = Object.assign({}, this.dataForRequest)
    //   apiData.password = password
    //   console.log('registerBody', apiData)
    //   const responsed = await this.$profileProvider.register(apiData)
    //   console.log('responsed: ', responsed)
    //   if (responsed.status === 201) {
    //     //
    //   }
    //   if (responsed.status === 409) {
    //     //
    //   }
    // },
  },
}
