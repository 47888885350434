
export default {
  name: 'SwiperBrandsComponent',
  props: {
    items: { type: Array, default: () => [] },
    loop: { type: Boolean, default: false },
    hideButton: { type: Boolean, default: true },
    banner: { type: Boolean, default: false },
    // selector: { type: Boolean, default: false },
    spaceBetween: { type: Number, default: 0 },
    count: { type: Number, default: 1 },
    bannerCreative: { type: String, default: '' },
    imageType: { type: String, default: '' },
    imageField: { type: String, default: '' },
    prefixBanner: {
      type: String,
      default: 'HP',
    },
    autoPlay: { type: Number, default: 0 },
    speed: { type: Number, default: 800 },
    position: { type: String, default: '' },
    height: { type: String, default: 'auto' },
    width: { type: String, default: 'auto' },
    groupslide: { type: Boolean, default: false },
    slideStyle: { type: String, default: '' },
    rows: { type: Number, default: 1 },
    showPagination: { type: Boolean, default: false },
    isHideLogo: { type: Boolean, default: false },
    type: { type: String, default: '' },
  },
  data() {
    return {
      ref: '',
      isActivePrev: false,
      isActiveNext: false,
      swiperOption: {
        pagination: {
          el: '#brands-pagination-' + this.ref,
          clickable: true,
        },
        // slidesPerView: this.count,
        // loop: this.loop,
        // slidesPerGroup: this.groupslide ? this.count : 1,
        // Number of rows per group (for slidesPerView > 1)
        lazy: true,
        breakpoints: this.breakpoints(),
        // loopFillGroupWithBlank: true,
        speed: this.speed,
      },
      sizes: '',
      // height: '385px',
    }
  },
  watch: {
    items: {
      handler: function (val) {
        if (val.length > 0) {
          this.onSlide()
        }
        // this.$refs[this.ref].$swiper.params.slidesPerView = this.responsive()
      },
      deep: true,
    },
  },
  created() {
    this.ref = Math.random().toString(36).substring(7)
    if (this.autoPlay > 0) {
      this.swiperOption.autoplay = { delay: this.autoPlay }
      // this.swiperOption.speed = this.speed
    }
    this.swiperOption.spaceBetween = this.spaceBetween
    // this.swiperOption.loop = this.loop
    this.swiperOption.pagination = {
      el: '#brands-pagination-' + this.ref,
      clickable: true,
    }
  },
  mounted() {
    console.log('this.itemsx', this.items)
    this.onSlide()
    if (this.position === 'promotion')
      this.sizes = 'xs:204 sm:405 md:255px lg:330px xl:223px'
  },
  methods: {
    groupedItems(items) {
      let itemsPerRow = items.length <= 3 ? 1 : this.rows
      // Number of items per row
      let rows = []
      for (let i = 0; i < items.length; i += itemsPerRow) {
        rows.push(items.slice(i, i + itemsPerRow))
      }
      return rows
    },
    onSwiperReady() {
      this.$refs[this.ref].$swiper.$el[0].children[0].onclick =
        this.onClickPerSlide
    },
    onClickPerSlide(event) {
      const image = JSON.parse(event.target.dataset.image)
      if (image !== undefined) {
        if (image.brandName) {
          // this.$router.push({
          //   path: this.$i18n.path(`brand/${image.brandName}`),
          // })
        } else {
          if (
            this.$utils.isValid(this.imageField) &&
            this.$utils.isValid(this.imageType) &&
            this.imageType === 'searchBrand'
          ) {
            this.$router.push({
              path: this.$i18n.path(`brand/${image[this.imageField]}`),
            })
          } else {
            if (
              this.$utils.isValid(this.bannerCreative) &&
              this.$utils.isValid(this.imageType) &&
              this.imageType === 'banner'
            ) {
              let title = image.title_tag?.[this.$i18n.locale] || image.title

              // console.log('image.title', title)
              //gtm promoClicked
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'promoClicked',
                ecommerce: {
                  promoClick: {
                    promotions: [
                      {
                        id: image.src,
                        name: `${this.prefixBanner}|${
                          image.gtmSeq
                        }|${title}|${this.$utils.formatDateGTM(
                          image.active_from
                        )}_${this.$utils.formatDateGTM(image.active_to)}`,
                        creative: this.bannerCreative,
                        position: image.gtmSeq,
                      },
                    ],
                  },
                },
              })

              //gtm GA4
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'select_promotion',
                ecommerce: {
                  creative_name: this.bannerCreative,
                  creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
                  promotion_id: image.src,
                  promotion_name: `${this.prefixBanner}|${
                    image.gtmSeq
                  }|${title}|${this.$utils.formatDateGTM(
                    image.active_from
                  )}_${this.$utils.formatDateGTM(image.active_to)}`,
                },
              })

              // gtm GA4
              const promotion = {
                creative_name: this.bannerCreative,
                creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
                promotion_id: image.src,
                promotion_name: `${this.prefixBanner}|${
                  image.gtmSeq
                }|${title}|${this.$utils.formatDateGTM(
                  image.active_from
                )}_${this.$utils.formatDateGTM(image.active_to)}`,
              }
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                user: this.$store.getters['user/userInfoTrack'],
                ...promotion,
              })
              this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
            }

            // if (this.$utils.isValid(image.url)) {
            //   this.$nextTick(() => {
            //     window.open(image.url, '_blank')
            //   })
            // }
          }
        }
      }
    },
    breakpoints() {
      console.log('img', this.items)
      let mobile = 1
      let tablet = 2
      let desktop = 3

      return {
        0: {
          slidesPerView: mobile,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: tablet,
          slidesPerGroup: this.groupslide ? tablet : 1,
          loop: this.loop && this.items.length >= tablet,
        },
        1179: {
          slidesPerView: desktop,
          slidesPerGroup: this.groupslide ? desktop : 1,
          loop: this.loop && this.items.length >= desktop,
        },
      }
    },
    // responsive() {
    //   let slidesPerView = this.count
    //   let windowWidth = document.documentElement.clientWidth
    //   switch (this.count) {
    //     case 7:
    //       if (windowWidth < 767) {
    //         slidesPerView = 3
    //       } else if (windowWidth > 767 && windowWidth < 1280) {
    //         slidesPerView = 5
    //       } else {
    //         slidesPerView = 7
    //       }
    //       break
    //     case 6:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1280) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //     case 5:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 5
    //       }
    //       break
    //     case 4:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 2
    //       } else {
    //         slidesPerView = 4
    //       }
    //       break
    //     case 3:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 2
    //       } else {
    //         slidesPerView = 3
    //       }
    //       break
    //     case 2:
    //       slidesPerView = 2
    //       break
    //     case 1:
    //       slidesPerView = 1
    //       break
    //     default:
    //       if (windowWidth < 767) {
    //         slidesPerView = 1
    //       } else if (windowWidth > 767 && windowWidth < 1199) {
    //         slidesPerView = 3
    //       } else {
    //         slidesPerView = 6
    //       }
    //       break
    //   }
    //   return slidesPerView
    // },
    onSlide() {
      if (this.hideButton) {
        this.isActivePrev =
          this.$refs[this.ref].$swiper.activeIndex === 0 && !this.loop
            ? false
            : true
        this.isActiveNext =
          this.$refs[this.ref].$swiper.activeIndex ===
            this.groupedItems(this.items).length -
              this.$refs[this.ref].$swiper.params.slidesPerView && !this.loop
            ? false
            : true
      }
    },
    next() {
      this.$refs[this.ref].$swiper.slideNext()
    },
    prev() {
      this.$refs[this.ref].$swiper.slidePrev()
    },
    // onClickImage(image) {
    //   if (image !== undefined) {
    //     if (image.brandName) {
    //       this.$router.push({
    //         path: this.$i18n.path(`brand/${image.brandName}`),
    //       })
    //     } else {
    //       if (
    //         this.$utils.isValid(this.imageField) &&
    //         this.$utils.isValid(this.imageType) &&
    //         this.imageType === 'searchBrand'
    //       ) {
    //         this.$router.push({
    //           path: this.$i18n.path(`brand/${image[this.imageField]}`),
    //         })
    //       } else {
    //         if (
    //           this.$utils.isValid(this.bannerCreative) &&
    //           this.$utils.isValid(this.imageType) &&
    //           this.imageType === 'banner'
    //         ) {
    //           //gtm promoClicked
    //           this.$gtm.push({ ecommerce: null })
    //           this.$gtm.push({
    //             event: 'promoClicked',
    //             ecommerce: {
    //               promoClick: {
    //                 promotions: [
    //                   {
    //                     id: image.src,
    //                     name: `${this.prefixBanner}|${image.gtmSeq}|${
    //                       image.title
    //                     }|${this.$utils.formatDateGTM(
    //                       image.active_from
    //                     )}_${this.$utils.formatDateGTM(image.active_to)}`,
    //                     creative: this.bannerCreative,
    //                     position: image.gtmSeq,
    //                   },
    //                 ],
    //               },
    //             },
    //           })

    //           // gtm GA4
    //           const promotion = {
    //             creative_name: this.bannerCreative,
    //             creative_slot: `${this.bannerCreative}_${image.gtmSeq}`,
    //             promotion_id: image.src,
    //             promotion_name: `${this.prefixBanner}|${image.gtmSeq}|${
    //               image.title
    //             }|${this.$utils.formatDateGTM(
    //               image.active_from
    //             )}_${this.$utils.formatDateGTM(image.active_to)}`,
    //           }
    //           this.$gtm.push({ ecommerce: null })
    //           this.$gtm.push({
    //             user: this.$store.getters['user/userInfoTrack'],
    //             ...promotion,
    //           })
    //           this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
    //         }

    //         if (this.$utils.isValid(image.url)) {
    //           this.$nextTick(() => {
    //             window.open(image.url, '_blank')
    //           })
    //         }
    //       }
    //     }
    //   }
    // },
    getImage(componentSection, item) {
      const coverBannerComponent = item?.shop_components?.find(
        (component) => component?.component_masters_id?.code === 'cover_banner'
      )
      const imageComponent = coverBannerComponent?.components_images?.find(
        (component) => component?.component_section === componentSection
      )
      const webImageId = imageComponent?.web_image?.id
      return webImageId ? `${this.sharkURL}assets/${webImageId}` : ''
    },

    // getDisplayName(item) {
    //   let languages = item?.translations?.filter((component) => component)

    //   let name = languages.find((component) => component.languages_code)

    //   const currentLang = this.$i18n.locale === 'th' ? 'th-TH' : 'en-US'

    //   if (name.languages_code.code === currentLang) {
    //     return name.display_name
    //   } else if (name.languages_code.code === 'th-TH') {
    //     return name.display_name
    //   }
    // },

    getDisplayName(item) {
      const currentLang = this.$i18n.locale === 'th' ? 'th-TH' : 'en-US'

      const name = item.translations.find(
        (translation) => translation.languages_code.code === currentLang
      )

      return name?.display_name || 'No display name'
    },

    // getDescription(item) {
    //   const currentLang = this.$i18n.locale === 'th' ? 'th-TH' : 'en-US'
    //   let description = item?.translations?.find(
    //     (component) => component.description
    //   )
    //   return description ? description.description : 'ยังไม่มีข้อมูล'
    // },

    getDescription(item) {
      const currentLang = this.$i18n.locale === 'th' ? 'th-TH' : 'en-US'

      const description = item.translations.find(
        (translation) => translation.languages_code.code === currentLang
      )

      return description?.description ? description.description : ''
    },

    getLink(item, type) {
      const uniqueName = item?.unique_name
      if (type === 'brand') {
        return this.$i18n.path(`ofm-shop/${uniqueName}`)
      } else if (type === 'shop') {
        return this.$i18n.path(`shop/${uniqueName}`)
      }
    },
  },
}
