
export default {
  props: {
    coupon: {
      type: Object,
      default: {},
    },
    getSelectedCoupon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedCoupon: '',
      isShowFullText: false,
      //   demoType: {
      //     type: 'platform',
      //     coupon_color: '#FD932F',
      //   },
    }
  },
  mounted() {
    // this.generateCSSClass(this.coupon)
  },
  methods: {
    onSelectCoupon(coupon_code) {
      //   console.log('selectedCoupon', this.selectedCoupon)
      //   this.selectedCoupon = coupon_code
      //   // this.selectedCoupon === coupon_code ? '' : coupon_code
      //   console.log('coupon_code', this.selectedCoupon)
      this.$emit('onSelectCoupon', coupon_code)
    },
    onSetFullText() {
      this.isShowFullText = false
    },
    onMaxLength(text, length, isShow) {
      //  && isShow === false
      return text.length > length ? text.substring(0, length) + '...' : text
    },
    onTimeLeft(targetDate) {
      // const currentDate = new Date()
      const differenceInMillis = new Date(targetDate) - new Date()
      return Math.ceil(differenceInMillis / (1000 * 60 * 60))
    },
  },
}
