
export default {
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page
  computed: {
    isDisplayErrorMsg() {
      return process.env.NODE_ENV !== 'production' ? true : false
    },
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  mounted() {
    console.log('route', this.$route.path)
  },
}
