
import asideComponent from '@/components/template/aside-user.vue'
import TableManageProduct from '@/components/company/table-manage-product.vue'

import ConfirmRemoveModal from '@/components/modal/confirmRemoveManageProduct.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageRole',
  components: {
    'aside-component': asideComponent,
    TableManageProduct,
    ConfirmRemoveModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      search: '',
      dataId: '',
      showConfirmModal: false,
      showEmpty: false,
      itemCount: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      items: [],
      searchItem: [],
      fields: [
        {
          key: 'productImage',
          label: '',
          class: 'text-center fs-12 w-90 border-right',
          sortable: false,
        },
        {
          key: 'productId',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'reference_code',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'productName',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'price',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 w-160',
          sortable: false,
        },

        {
          key: 'action',
          label: '',
          class: 'text-right w-160',
          sortable: false,
        },
      ],
      is_add_product: false,
      // sku_code: {
      //   skus: [],
      // },
      skus: [],
      //reference_code: '',
      product_list: {
        reference_code: '',
        productImage: '',
        productId: '',
        productName: '',
        price: '',
      },
      params: {
        skus: [],
        user_open_id: '',
        profile_id: '',
        account_channel: '',
        costcenter_id: '',
      },
      totalrecords: 0,
      perpage: 0,
      totalrows: 0,
      is_reference: false,
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      is_free: false,
      skucode: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('authen', ['openId']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.items.length / this.perPage)) {
        last = this.items.length
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.items.length === 0) {
        first = 0
        last = 0
      } else if (this.items.length < this.perPage) {
        last = this.items.length
      }
      return first + '-' + last
    },
  },
  watch: {},
  mounted() {
    this.getCompaniesProductskus(1)
  },
  created() {},
  methods: {
    onEdit(productId) {
      this.$router.push({
        path: this.$i18n.path('company/products/edit/' + productId),
      })
    },
    async onSearch(productId) {
      this.is_free = false
      console.log('skucode', this.$refs['skucode'].input)
      this.$refs['skucode'].input = ''
      if (productId) {
        this.product_list = {}
        this.showEmpty = false
        await this.$profileProvider
          .checkproductskusData(this.userProfile.id, productId)
          .then(
            (result) => {
              if (result.status === 200) {
                this.is_add_product = false
                let params = new URLSearchParams()
                params.append('company_id', this.userProfile.id)
                params.append('sku_code', productId)
                this.$profileProvider.getCompaniesProductskusData(params).then(
                  (result) => {
                    if (result.status === 200) {
                      let com_product = result.data
                      this.skus = []
                      this.skus.push(productId)
                      this.params = {
                        user_open_id: this.openId,
                        profile_id: this.userProfile.id,
                        account_channel: this.profileType,
                        costcenter_id: this.userCostcenter.id,
                        skus: this.skus,
                      }
                      this.$productProvider
                        .productSkusbulkSearch(this.params)
                        .then(
                          (result) => {
                            if (result.status === 201) {
                              this.searchItem = []
                              let data = result.data
                              data.skus.forEach((prpduct) => {
                                this.product_list = {
                                  productImage:
                                    this.imageNormalShowcase(prpduct),
                                  productId: prpduct.sku_code,
                                  productName:
                                    prpduct.product_names !== undefined
                                      ? prpduct.product_names.find(
                                          (a) => a.type === 'Online'
                                        ).display_name.th
                                      : '',
                                  price: prpduct.price_exclude_vat,
                                  reference_code:
                                    com_product.items[0].reference_code,
                                }
                              })
                              console.log('product_list', this.product_list)
                              this.searchItem.push(this.product_list)
                            } else {
                              this.showEmpty = true
                              this.searchItem = []
                            }
                          },
                          (error) => {
                            console.log('getProductSkus', error.responsed)
                          }
                        )
                    }
                  },
                  (error) => {
                    console.log('getCompaniesProductskusData', error.responsed)
                  }
                )
              } else {
                this.is_add_product = true
                this.getCompaniesProductskusById(productId)
              }
            },
            (error) => {
              console.log('getCompaniesProductskus', error.responsed)
            }
          )
      } else {
        this.searchItem = []
        this.showEmpty = true
      }
    },
    onCreate(productId) {
      this.$router.push({
        path: this.$i18n.path('company/products/create/' + productId),
      })
    },
    onConfirmRemoveModal(id) {
      this.dataId = id
      this.showConfirmModal = true
      console.log('delectId', id)
    },
    async onRemove(id) {
      console.log('remove', id)
      this.items.splice(
        this.items.findIndex((e) => e.productId === id),
        1
      )
      await this.$profileProvider
        .delectproductskusById(this.userProfile.id, id)
        .then(
          (result) => {
            if (result.status === 204) {
              console.log('object', true)
              this.getCompaniesProductskus(1)
            }
          },
          (error) => {
            console.log('delectproductskusById', error.responsed)
          }
        )
    },
    async getCompaniesProductskus(pageNo) {
      console.log('pageNo', pageNo)
      this.skus = []
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('pageNo', pageNo)
      await this.$profileProvider.getCompaniesProductskusData(params).then(
        (result) => {
          if (result.status === 200) {
            let com_product = result.data
            console.log('com_product', com_product)
            this.totalrecords = com_product.totalRecords
            this.perpage = com_product.pageSize
            this.totalRows = com_product.totalPages

            com_product.items.forEach((e) => {
              this.skus.push(e.sku_code)
              this.is_reference =
                e.companies_custom.is_use_reference_product_code
            })
            console.log('is_reference', this.is_reference)
            this.params = {
              user_open_id: this.openId,
              profile_id: this.userProfile.id,
              account_channel: this.profileType,
              costcenter_id: this.userCostcenter.id,
              skus: this.skus,
            }
            this.$productProvider
              .productSkusbulkSearch(this.params)
              .then((result) => {
                if (result.status === 201) {
                  let data = result.data
                  console.log('productSkusbulkSearch', data)
                  this.items = []

                  data.skus.forEach((a, index) => {
                    this.items.push({
                      index: index + 1,
                      productImage: this.imageNormalShowcase(a),
                      productId: a.sku_code,

                      productName:
                        a.product_names !== undefined
                          ? a.product_names.find((a) => a.type === 'Online')
                              .display_name.th
                          : '',
                      price: a.price_exclude_vat,
                      reference_code: com_product.items.find(
                        (e) => e.sku_code === a.sku_code
                      ).reference_code,
                    })
                  })
                  console.log('this.items', this.items)
                }
              })
          }
        },
        (error) => {
          console.log('getCompaniesProductskus', error.responsed)
        }
      )
    },
    async getCompaniesProductskusById(productId) {
      this.skus = []
      this.skus.push(productId)
      this.params = {
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        costcenter_id: this.userCostcenter.id,
        skus: this.skus,
      }
      await this.$productProvider.productSkusbulkSearch(this.params).then(
        (result) => {
          if (result.status === 201) {
            console.log('result', result.data)
            if (result.data.is_free) {
              this.is_free = true
            }
            this.searchItem = []
            let data = result.data
            data.skus.forEach((product) => {
              this.product_list = {
                productImage: this.imageNormalShowcase(product),
                productId: product.sku_code,
                productName:
                  product.product_names !== undefined
                    ? product.product_names.find((a) => a.type === 'Online')
                        .display_name.th
                    : '',
                price: product.price_exclude_vat,
              }
            })
            this.searchItem.push(this.product_list)
            console.log(1, this.searchItem)
          } else {
            this.showEmpty = true
            this.searchItem = []
          }
        },
        (error) => {
          console.log('getProductSkus', error.responsed)
        }
      )
    },
    async getCompaniesProductData(query) {
      await this.$profileProvider.getCompaniesProductskusData(query).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            this.product_list.reference_code = data.items[0].reference_code
            this.searchItem = []
            this.searchItem.push(this.product_list)
            console.log(2, this.searchItem)
          }
        },
        (error) => {
          console.log('getCompaniesProductskusData', error.responsed)
        }
      )
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return sku.images.some(
          (q) => q.size === 'thumbnail' && q.sequence === 1
        )
          ? sku.images.find((q) => q.size === 'thumbnail' && q.sequence === 1)
              .url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
  },
}
