
import FooterComponent from '@/components/template/footer.vue'
import preconditionFailed from '@/components/share/precondition-failed'
import responseFailed from '@/components/share/response-failed'
import modalComponent from '@/components/share/modal'
import loading from '@/components/share/loading'
import { mapGetters } from 'vuex'

export default {
  components: {
    'footer-component': FooterComponent,
    'precondition-failed': preconditionFailed,
    'response-failed': responseFailed,
    loading,
    modalComponent,
  },
  data() {
    return {
      timeline: {
        'checkout-shipping-tempId': true,
        'checkout-payment-tempId': false,
        'checkout-complete-orderId': false,
        'company-printing-complete-id': false,
      },
      timelineEn: {
        'lang-checkout-shipping-tempId': true,
        'lang-checkout-payment-tempId': false,
        'lang-checkout-complete-orderId': false,
        'lang-company-printing-complete-id': false,
      },
      navigators: [
        {
          key: 'checkout-shipping-tempId',
          shipping: false,
          payment: false,
          complete: false,
        },
        {
          key: 'checkout-payment-tempId',
          shipping: true,
          payment: false,
          complete: false,
        },
        {
          key: 'checkout-complete-orderId',
          shipping: false,
          payment: false,
          complete: false,
        },
        {
          key: 'lang-checkout-shipping-tempId',
          shipping: false,
          payment: false,
          complete: false,
        },
        {
          key: 'lang-checkout-payment-tempId',
          shipping: true,
          payment: false,
          complete: false,
        },
        {
          key: 'lang-checkout-complete-orderId',
          shipping: false,
          payment: false,
          complete: false,
        },
        {
          key: 'company-printing-complete-id',
          shipping: false,
          payment: false,
          complete: false,
        },
        {
          key: 'lang-company-printing-complete-id',
          shipping: false,
          payment: false,
          complete: false,
        },
      ],
    }
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('order', ['displayPaymentStep']),
    ...mapGetters('user', ['profileType']),
    isEprocurementUser() {
      return this.$utils.isValid(this.profileType) &&
        this.profileType === 'eprocurement'
        ? true
        : false
    },
  },
  watch: {
    $route: {
      handler() {
        this.checkActiveTimeline()
      },
      deep: true,
    },
  },
  mounted() {
    this.checkActiveTimeline()
  },
  methods: {
    checkCanClickNavigator(navPosition) {
      let navigator = this.navigators.find((q) => q.key === this.$route.name)
      if (navigator !== undefined) {
        return navigator[navPosition]
      } else return false
    },
    checkActiveTimeline() {
      let timelineKey =
        this.$i18n.locale === 'th'
          ? Object.keys(this.timeline)
          : Object.keys(this.timelineEn)
      if (timelineKey.includes(this.$route.name)) {
        let timeline =
          this.$i18n.locale === 'th' ? this.timeline : this.timelineEn
        Object.keys(timeline).forEach((key) => {
          timeline[key] = false
        })
        for (let key in timeline) {
          timeline[key] = true
          if (key === this.$route.name) break
        }
      }
    },
    redirect() {
      if (
        this.$route.name === 'checkout-payment-tempId' ||
        this.$route.name === 'lang-checkout-payment-tempId'
      ) {
        this.$router.push(
          this.$i18n.path(`checkout/shipping/${this.$route.params.tempId}`)
        )
      }
    },
  },
}
