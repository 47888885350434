
import FooterComponent from '@/components/template/footer.vue'
import preconditionFailed from '@/components/share/precondition-failed'
import responseFailed from '@/components/share/response-failed'
import modalComponent from '@/components/share/modal'

export default {
  components: {
    'footer-component': FooterComponent,
    'precondition-failed': preconditionFailed,
    'response-failed': responseFailed,
    modalComponent,
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
}
