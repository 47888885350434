
import { mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userProfile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      swiperOption: {},
      page: 1,
    }
  },
  watch: {
    show: function (value) {
      if (Object.keys(this.userProfile.id).length === 0) {
        if (value && !Cookie.get('OnBoard')) {
          this.$refs['modal-onboard'].show()
          Cookie.set('OnBoard', true, {
            expires: 365,
            secure: true,
            // domain: 'localhost',
          })
        }
      } else {
        Cookie.set('OnBoard', true, {
          expires: 365,
          secure: true,
          // domain: 'localhost',
        })
      }
    },
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    onClose() {
      this.$refs['modal-onboard'].hide()
      // this.$emit('hidden')
    },
    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
    gotoRegister() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('register') })
    },
  },
}
