
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import SearchSelect from '@/components/share/search-select.vue'
import { mapGetters } from 'vuex'
// import { relativeTimeThreshold } from 'moment'

export default {
  layout: 'main',
  name: 'CostcenterCreate',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    'search-select': SearchSelect,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isDrop_Error: false,
      isCheckbox_Error: false,
      isError: false,
      taxAddress: [],
      filter: '',
      isstatus: true,
      invoices: [],
      info: {
        company_code: '',
        accounts_id: '',
        name: {
          th: '',
          en: '',
        },
      },
      costCenter: {
        id: '',
        company_id: '',
        department_id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        taxAddress: {
          value: '',
          text: '',
        },
        status: '',
        account_id: '',
        shippings: [],
        invoices: [],
        user_id: 'System',
        user_name: 'OnlineEpro',
      },
      department: {
        code: '',
        name: {
          th: '',
          en: '',
        },
      },
      shippings: [],
      fields: [
        {
          key: 'selectCheckbox',
          label: '',
          class: 'd-flex justify-content-center',
          thClass: 'w-30-px',
          sortable: false,
        },
        // {
        //   key: 'shippingid',
        //   label: 'รหัส',
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },
        {
          key: 'contact',
          label: this.$i18n.t('company-setting-costcenter-create.contact'),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'shipPhone',
          label: this.$i18n.t('company-setting-costcenter-create.shipPhone'),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'shipAddress',
          label: this.$i18n.t('company-setting-costcenter-create.shipAddress'),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  mounted() {
    this.getCompaniesInfoById()
  },
  created() {
    this.dept = {
      isActive: true,
    }
  },
  methods: {
    validateCostcenterSubmit() {
      this.$refs.createCostcenterForm.validate().then((result) => {
        if (
          result &&
          this.costCenter.shippings.length > 0 &&
          this.costCenter.taxAddress.value !== ''
        ) {
          this.checkCostCenter()
        } else {
          if (this.costCenter.shippings.length === 0) {
            this.isCheckbox_Error = true
          }
          if (this.costCenter.taxAddress.value === '') {
            this.isDrop_Error = true
          }
        }
      })
    },
    onSelectCheckbox(value, object) {
      if (value) {
        this.costCenter.shippings.push({ id: object.id })
      } else {
        this.costCenter.shippings.splice(
          this.costCenter.shippings.findIndex((e) => e === object.id),
          1
        )
      }
      console.log('selectItems', this.costCenter.shippings)
    },
    onCancel() {
      if (this.userProfile.company_level === 3) {
        this.$router.push({
          path: this.$i18n.path('company/setting'),
        })
      }
      if (this.userProfile.company_level === 2) {
        this.$router.push({
          path: this.$i18n.path('company/setting/costcenter'),
        })
      }
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(data)
              this.info = {
                company_code: data.company_code,
                name: data.name,
                account_id: data.accounts.find((a) => a.is_default === true).id,
                offline_id: data.account_id_default,
              }
              if (
                this.info.account_id !== undefined &&
                this.info.account_id !== ''
              ) {
                if (this.userProfile.company_level === 3) {
                  this.getDepartmentById()
                }

                this.getAllShippingById(this.info.account_id)
                data.accounts.forEach((a) => {
                  this.getAllInvoiceById(a)
                })
              }
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
    async getDepartmentById() {
      await this.$profileProvider
        .getDepartmentDataById(this.userProfile.id, this.$route.query.dept)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data.data_level_three.items
              console.log('resultdata', data)
              if (data != undefined && data.length) {
                this.department = {
                  id: data[0].id,
                  code: data[0].code,
                  name: data[0].name,
                }
                console.log('department', this.department)
              }
            }
          },
          (error) => {
            console.log('getDepartmentById', error.responsed)
          }
        )
    },
    async getAllShippingById(account_id) {
      this.shippings = []
      await this.$profileProvider.getAllShippingDataById(account_id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('shipping', data)
            data.items.forEach((e) => {
              let temp_phone = []
              let temp = ''
              let phone_ext = ''
              if (e.phones.length > 0) {
                e.phones.forEach((a) => {
                  if (a.type === 'Phone') {
                    phone_ext =
                      a.extension !== ''
                        ? a.value + ' #' + a.extension
                        : a.value
                    temp_phone.push({
                      value: phone_ext,
                    })
                  }
                  if (a.type === 'Mobile') {
                    temp_phone.push({ value: a.value })
                  }
                })

                temp = temp_phone.map((item) => item.value).toString()
                console.log('object', temp)
              }
              this.shippings.push({
                id: e.shippingid,
                contact: e.contact,
                shippingid: e.offlineid,
                shipAddress: e.shipAddress,
                shipPhone: temp,
                checkbox: false,
              })
            })
          }
        },
        (error) => {
          console.log('getAllShippingById', error.responsed)
        }
      )
    },
    async getAllInvoiceById(account) {
      await this.$profileProvider.getAllInvoiceDataById(account.id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('invoice', data)
            data.items.forEach((e) => {
              if (account.is_default) {
                this.invoices = []
                this.invoices.push({
                  id: e.invoiceid,
                })

                this.costCenter.account_id = account.id

                this.costCenter.taxAddress = {
                  account_id: account.id,
                  value: e.invoiceid,
                  text:
                    e.invoiceAddress2 +
                    ' ' +
                    e.invoiceAddress3 +
                    ' ' +
                    e.invoiceAddress4,
                }
              }
              this.taxAddress.push({
                account_id: account.id,
                value: e.invoiceid,
                text:
                  e.invoiceAddress2 +
                  ' ' +
                  e.invoiceAddress3 +
                  ' ' +
                  e.invoiceAddress4,
              })
            })
          }
        },
        (error) => {
          console.log('getAllInvoiceById', error.responsed)
        }
      )
    },
    async checkCostCenter() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('company_code', this.info.company_code.toUpperCase())
      params.append('department_id', this.department.id)
      params.append('code', this.costCenter.code.toUpperCase())
      await this.$profileProvider.checkCostCenterData(params).then(
        (result) => {
          if (result.status === 404) {
            this.createCostcenterById()
          }
          if (result.status === 200) {
            this.isError = true
          }
        },
        (error) => {
          console.log('createCostcenterById', error.responsed)
        }
      )
    },
    async createCostcenterById() {
      this.costCenter.department_id = this.$route.query.dept ?? null
      this.costCenter.company_id = this.userProfile.id
      this.costCenter.status = this.isstatus ? 'Active' : 'Inactive'
      this.costCenter.invoices = this.invoices

      await this.$profileProvider
        .createCostcenterDataById(this.userProfile.id, this.costCenter)
        .then(
          (result) => {
            if (result.status === 201) {
              this.onCancel()
            }
          },
          (error) => {
            console.log('createCostcenterById', error.responsed)
          }
        )
    },
    onSelectInvoice(val) {
      console.log('val :>> ', val)
      this.invoices = []
      this.invoices.push({ id: val.value })
      this.costCenter.account_id = val.id
      this.costCenter.taxAddress = val
      this.getAllShippingById(val.id)
      this.costCenter.shippings = []
    },
  },
}
