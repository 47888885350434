
import { mapGetters } from 'vuex'

export default {
  name: 'TablereportOrder',
  props: {
    items: { type: Array, default: () => [] },
    itemCount: { type: Number, default: () => 0 },
    isPrinting: { type: Boolean, default: false },
  },
  data() {
    return {
      search: '',
      // itemCount: 100,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'index',
          label: this.$t(`company-report-order.labelNo`),
          class: 'text-center w-70',
          sortable: false,
        },
        {
          key: 'cusID',
          label: this.$t(`company-report-order.labelCustomerID`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'orderID',
          label: this.$t(`company-report-order.labelPONumber`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'status',
          label: this.$t(`company-report-order.labelStatus`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'orderDate',
          label: this.$t(`company-report-order.labelPODate`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'approveDate',
          label: this.$t(`company-report-order.labelApprovalDate`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
          formatter: (value, key, item) => {
            if (value === '1969/12/31 17:00:00') {
              return ''
            }
            if (value === '1970/01/01 00:00:00') {
              return ''
            }
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'orderName',
          label: this.$t(`company-report-order.labelPurchaser`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'approveName',
          label: this.$t(`company-report-order.labelApprover`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'departmentID',
          label: this.$t(`company-report-order.labelDivisionID`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'departmentName',
          label: this.$t(`company-report-order.labelDivisionName`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'discount',
          label: this.$t(`company-report-order.labelDiscount`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'vat_prod_net_amt',
          label: this.$t(`company-report-order.labelTotalTax`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'totalPrice',
          label: this.$t(`company-report-order.labelPriceTax`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.itemCount / this.perPage)) {
        last = this.itemCount
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.itemCount === 0) {
        first = 0
        last = 0
      } else if (this.itemCount < this.perPage) {
        first = 1
        last = this.itemCount
      }
      console.warn('first :>> ', first)
      return first + '-' + last
    },
  },
  // watch: {
  //   items: {
  //     handler: function (val) {
  //       this.itemCount = val.length
  //     },
  //     deep: false,
  //   },
  // },
  methods: {
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
    },
    onPreview() {
      //this.$nextTick(() => {
      if (this.currentPage <= Math.ceil(this.itemCount / this.perPage)) {
        this.currentPage++
        this.$emit('getOrderReport', this.currentPage, false)
      }
      //})
    },
    onLast() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.$emit('getOrderReport', this.currentPage, false)
      }
    },
    orderDetail(id) {
      let order_id = this.items.find((a) => a.orderID === id)?.id
      return !this.isPrinting
        ? this.$i18n.path(`account/order/${order_id}?mode=view`)
        : this.$i18n.path(`company/printing/order/${order_id}?mode=view`)
    },
  },
}
