
// import ImageDefault from '@/assets/images/image-product-deafult-Large.jpg'
import PrintingModal from '@/components/printing/modal-preview'

export default {
  name: 'CheckoutDeliveryItems',
  components: {
    PrintingModal,
  },
  props: {
    productPrinting: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    complete: { type: Boolean, default: false },
  },
  data() {
    return {
      groups: [],
      modalPreviewData: {},
    }
  },
  watch: {
    productPrinting: {
      handler: function () {
        this.groups = this.productPrinting
      },
      deep: true,
    },
  },
  mounted() {
    this.groups = this.productPrinting
    // var value = this.items
    // value = value.reduce((h, value) => {
    //   return Object.assign(h, {
    //     [value.soldBy]: (h[value.soldBy] || []).concat(value),
    //   })
    // }, {})
    // Object.keys(value).forEach((element) => {
    //   this.groups.push({
    //     soldBy: element,
    //     FulfilledBy: value[element][0].FulfilledBy,
    //     items: value[element],
    //   })
    // })
    // console.log(this.groups)
  },
  methods: {
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    onModalPreview(data) {
      this.modalPreviewData = data
    },
  },
}
