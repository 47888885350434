
import InputText from '@/components/share/input-text.vue'
export default {
  layout: 'blank-nav',
  name: 'ForgetPassword',
  components: {
    'input-text': InputText,
  },
  data() {
    return {
      reloadEmail: true,
      dataForReset: {
        email: '',
      },
    }
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    async onSubmit() {
      this.$refs.emailVerifyForm.validate().then(async (result) => {
        if (result) {
          await this.forgotPassword()
        }
      })
    },
    async forgotPassword() {
      const result = await this.$authenProvider.forgotPassword(
        this.dataForReset.email
      )
      console.log('forgotPassword', result)
      if (result.status === 201) {
        this.$bvToast.toast(this.$t('forgotpassword.success'), {
          title: `Success`,
          variant: 'success',
          solid: true,
        })
        this.dataForReset.email = ''
        this.$refs.emailVerifyForm.reset()
      } else if (result.status === 412) {
        if (result.data) this.showToast(result.data.message[this.$i18n.locale])
      }
    },
    showToast(message) {
      const id = `error-toast-${this.toast++}`

      const Msg = this.$createElement('div', { class: ['mr-auto'] }, [message])
      const CloseToast = this.$createElement('b-icon-x', {
        class: ['ml-auto'],
        on: { click: () => this.$bvToast.hide(id) },
      })

      this.$bvToast.toast([Msg, CloseToast], {
        id: id,
        toaster: 'b-toaster-top-right error-toast',
        noCloseButton: true,
      })
    },
  },
}
