
export default {
  name: 'QuestionAnswerComponent',
  props: {
    data: { type: Array, default: () => [] },
    length: { type: Number, default: 2 },
  },
  data() {
    return {}
  },
}
