
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import { /* mapActions, mapState,*/ mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'
export default {
  name: 'Similar',
  components: {
    breadcrumb: BreadCrumb,
    // 'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    'skeleton-result': SekletonResult,
  },
  data() {
    return {
      breadcrumbItems: [
        // {
        //   text: this.$nuxt._route.params.department.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department,
        // },
        // {
        //   text: this.$nuxt._route.params.category.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department +
        //     '/' +
        //     this.$nuxt._route.params.category,
        // },
        // {
        //   text: this.$nuxt._route.params.subcategory.split('-')[1],
        //   href:
        //     '/' +
        //     this.$nuxt._route.params.lang.split(' ').join('') +
        //     '/category' +
        //     '/' +
        //     this.$nuxt._route.params.department +
        //     '/' +
        //     this.$nuxt._route.params.category +
        //     '/' +
        //     this.$nuxt._route.params.subcategory,
        // },
      ],
      sku: {},
      skuName: '',
      itemsRelated: [],
      lang: '',
      sortBy: [
        {
          text: this.$t('similar_id.dropdownSortByDefault'),
          value: '0',
        },
        {
          text: this.$t('similar_id.dropdownSortByDiscount'),
          value: '1',
        },
        {
          text: this.$t('similar_id.dropdownPriceLowHigh'),
          value: '2',
        },
        {
          text: this.$t('similar_id.dropdownPriceHighLow'),
          value: '3',
        },
        {
          text: this.$t('similar_id.dropdownAZ'),
          value: '4',
        },
        {
          text: this.$t('similar_id.dropdownZA'),
          value: '5',
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      filter: {},
      sort: 'promotion-descending',
      view: 'grid',
      loading: false,
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      // IsInactive: false,
      // IsDelete: false,
      productStatusNotReady: ['not_ready', 'cancel'],
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      isGetProduct: false,
      itemsRecentlyViewedProducts: [],
      isNotFound: false,
      overlayImages: [],
      overlayImagesItemsRecent: [],
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
    ]),
    isItemsRelated() {
      return (
        this.itemsRelated !== undefined &&
        this.itemsRelated !== null &&
        this.itemsRelated.length > 0
      )
    },
    imageNormalShowcase() {
      if (this.sku.images != null && this.sku.images.length) {
        return (
          this.sku.images.some((q) => q.size === 'thumbnail')
            ? this.sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'thumbnail').url
            : this.imageTemp
        ).replace('/thumbnail', '/original')
      } else {
        return this.imageTemp.replace('/thumbnail', '/original')
      }
    },
    isDiscount() {
      return (
        this.sku.is_promotion ||
        this.sku.is_fixprice ||
        this.sku.is_online_only ||
        this.sku.is_flash_sale
      )
    },
    checkProductStatusReady() {
      return !this.productStatusNotReady.includes(this.sku.display_status)
    },
  },
  watch: {
    // perPage: {
    //   handler: function () {
    //     console.log(this.perPage)
    //     this.getProduct()
    //   },
    //   deep: true,
    // },
  },
  created() {
    // this.categoryId = this.$nuxt._route.params.subcategory.split('-')[0]
    this.lang = this.$store.state.locale
    // this.skuCode = this.$route.params.id
    if (this.$route.params.id.split('.')[1] === undefined) {
      this.skuCode = this.$route.params.id
    } else {
      this.skuCode = this.$route.params.id.split('.')[1]
    }
    console.warn('sku_code :>> ', this.sku_code)
  },
  mounted() {
    this.getProductDetail()
    // this.getProduct()
  },
  methods: {
    async createParams() {
      let params = new URLSearchParams()
      params.append('accountChannel', this.profileType)
      params.append('skuCode', this.skuCode)
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }
      return params
    },
    async getProductDetail() {
      this.isGetProduct = false
      let params = await this.createParams()
      // let responsed = await this.$productProvider.getProductBySkuCode(params)
      let responsed = await this.$productProvider.getProductSimilarBySkuCode(
        this.skuCode,
        params
      )
      if (responsed.status === 200) {
        this.isNotFound = false
        this.sku = responsed.data.skus.find((q) => q.sku_code === this.skuCode)
        console.log('this.sku', this.sku)
        this.skuName = this.sku.product_names.find(
          (q) => q.type.toLowerCase() === 'online'
        ).display_name[this.lang]
        this.skuSEOName = this.sku.meta.seo_name[this.lang]
        this.getProductRelated()
        // if (this.checkProductStatusReady) {
        //   this.$router.push({
        //     path: this.$i18n.path(
        //       `similar/${this.skuSEOName}-p.${this.skuCode}`
        //     ),
        //   })
        // } else this.getProductRelated()
      } else {
        this.loading = true
        this.isNotFound = true
        this.getUserProductHistory()
      }

      this.isGetProduct = true
    },
    async getProductRelated() {
      this.isGetProduct = false
      let params = await this.createParams()
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      params.append('type', 'Related')
      params.append('page', this.page)
      //   params.append('pageSize', this.perPage)
      params.append('isFree', false)

      console.log('params', params)
      await this.$productProvider
        .getProductRelatedData(params)
        .then(async (result) => {
          if (result.status === 200) {
            let data = result.data
            this.itemsRelated = data.items ?? undefined
            this.pageTotal = result.data.totalRecords
            this.loading = true

            // get overlay
            if (this.itemsRelated.length) {
              let pid = this.itemsRelated.map((item) => {
                return item.skus[0].sku_code
              })
              const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
                pid
              )
              console.log('graphqlResponse', graphqlResponse)
              if (graphqlResponse) {
                this.overlayImages = graphqlResponse
              }
            }
          }
        })
      this.isGetProduct = true
    },
    // async createParams() {
    //   let params = new URLSearchParams()
    //   params.append('skuCode', this.skuCode)
    //   params.append('accountId', this.userProfile.id)
    //   params.append('accountChannel', this.profileType)
    //    params.append(
    //     'categoryType',
    //     this.userProfile.categories_type ?? 'Standard'
    //   )
    //   params.append('pageSize', this.perPage)
    //   params.append('sort', this.sort)
    //   params.append('type', 'Related')
    //   params.append('page', this.page)
    //   params.append('pageSize', this.perPage)

    //   params.append('isFree', false)
    //   return params
    // },
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProductRelated()
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProductRelated()
    },
    async selectSort(e) {
      this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
      console.log('selectSort : ', e)
      if (e.value === '0') this.sort = 'promotion-descending'
      else if (e.value === '1') {
        this.sort = 'price-ascending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
      } else if (e.value === '2') {
        this.sort = 'price-descending'
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
      } else if (e.value === '3') this.sort = 'new-descending'
      else this.sort = 'old-ascending'
      console.log('selectSort : ' + this.sort)
      await this.getProductRelated()
    },
    async getUserProductHistory() {
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )

      // console.log('user product history', result.data)
      this.itemsRecentlyViewedProducts = []
      if (result.status === 200 && result.data.length) {
        result.data.forEach((sku, index) => {
          if (this.$utils.isObjectValid(sku)) {
            this.itemsRecentlyViewedProducts.push({
              productId: sku.sku_code,
              productName: sku.product_names.find(
                (b) => b.type.toLowerCase() === 'online'
              ).display_name,

              // productImg: sku.images.find(
              //   (c) => c.size === 'thumbnail' && c.sequence === 1
              // ).url,
              productImg: this.imageNormalShowcase(sku),
              price_include_vat: sku.price_include_vat,
              retail_price: sku.retail_price.include_vat,
              is_promotion: sku.is_promotion,
              is_fixprice: sku.is_fixprice,
              is_hot_flash_sale: sku.is_hot_flash_sale,
              is_flash_sale: sku.is_flash_sale,
              is_online_only: sku.is_online_only,
              meta: sku.meta,
              seoMeta: sku.meta.seo_name,
              seq: index + 1,
              display_ratings: sku.display_ratings,
              display_ratings_count: sku.display_ratings_count,
              display_discount_rate: sku.display_discount_rate,
              promotion_prices: sku.promotion_prices,
            })
          }
        })
        // overlay promotion
        if (this.itemsRecentlyViewedProducts.length) {
          let pid = this.itemsRecentlyViewedProducts.map((item) => {
            return item.productId
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesItemsRecent = graphqlResponse
          }
        }
      }
    },
  },
}
