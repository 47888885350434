
export default {
  name: 'ModalComponent',
  data() {
    return {
      title: '',
      input: false,
      bodyText: '',
      size: '', // sm , md , lg , xl
      pattern: '', // success , info , danger
      confirmFunction: '',
      cancelFunction: '',
      placeholder: '',
      remark: '',
    }
  },

  mounted() {
    this.$nuxt.$on('modalComponent', (data) => {
      this.remark = ''
      this.title = data.title || ''
      this.input = data.input || false
      this.bodyText = data.bodyText || ''
      this.size = data.size || 'md'
      this.pattern = data.pattern
      this.confirmFunction = data.confirmFunction || ''
      this.cancelFunction = data.cancelFunction || ''
      this.placeholder = data.placeholder || 'Please enter remark'
      this.$refs.modal.show()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('modalComponent', () => {
      this.confirmFunction = ''
      this.cancelFunction = ''
      this.$refs.modal.hide()
    })
  },
  methods: {
    onClose() {
      this.$refs.modal.hide()
      if (this.cancelFunction !== '') {
        this.$nuxt.$emit(this.cancelFunction)
      }
    },
    onConfirm() {
      this.$refs.modal.hide()
      if (this.confirmFunction !== '') {
        this.$nuxt.$emit(this.confirmFunction, this.remark)
      }
    },
  },
}
