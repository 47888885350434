
import asideComponent from '@/components/template/aside-user.vue'
// import CKEditor from '@/components/share/ckeditor'
// import datePicker from '@/components/share/date-picker.vue'
import { mapGetters } from 'vuex'
// import tinymce from '@tinymce/tinymce-vue'

export default {
  layout: 'main',
  name: 'NewsCreate',
  components: {
    'aside-component': asideComponent,
    // CKEditor,
    datePicker: async () => await import('@/components/share/date-picker.vue'),
    tinymce: () => import('@tinymce/tinymce-vue'),
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      tinymce: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | styleselect | fontsizeselect | \
           bold italic underline backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent |  \
           link image | \
           removeformat | help',
        height: 200,
        branding: false,
        // toolbar1:
        //   'styleselect fontselect fontsizeselect bold italic strikethrough underline subscript superscript forecolor backcolor link',
        // toolbar2:
        //   'alignleft aligncenter alignright alignjustify bullist numlist outdent indent blockquote undo redo',
        // options: {
        //   height: 200,
        //   menubar: false,
        //   branding: false,
        // },
      },
      news: {
        title: '',
        detail: '',
        startDate: '',
        endDate: '',
        is_active: true,
        attach_file: {
          name: '',
          url: '',
        },
        valid_from: '',
        valid_to: '',
        attach_file_display: '',
        company_id: '',
        news_channel: '',
      },
      activeDate: {},
      error: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  methods: {
    async onCreate() {
      this.$refs.tempCreateNews.validate().then((result) => {
        if (result) {
          this.createContent()
        }
      })
    },
    async selected({ target: { files } }) {
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              // this.importFile(files[0])
              await this.onUploadFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async onUploadFile(file) {
      this.error = ''
      this.news.attach_file_display = file.name.toLowerCase()
      let formData = new FormData()

      formData.append('file', file)
      formData.append('prefix_path', 'personal-document')
      formData.append('content_name', 'news')
      formData.append('is_private', true)

      await this.$profileProvider.uploadContentImage(formData).then(
        (result) => {
          if (result.status === 201) {
            console.log('uploadContentImage :>> ', result.data)
            if (result.data.url !== null) {
              this.news.attach_file = {
                name: this.news.attach_file_display,
                url: result.data.url,
              }
            } else {
              this.error = 'รูปแบบชื่อไฟล์ไม่ถูกต้องค่ะ'
            }
          }
        },
        (error) => {
          console.log('onUploadFile', error.responsed)
        }
      )
    },
    onCancel() {
      this.$router.push({ path: this.$i18n.path('company/news') })
    },
    dateRangeSelected(event) {
      console.log('dateRangeSelected :>> ', event)
      this.news.startDate = event.from
      this.news.endDate = event.to
    },
    async createContent() {
      if (this.error === '') {
        console.warn('this.news :>> ', this.news)
        this.news.company_id = this.userProfile.id
        this.news.valid_from = this.news.startDate
        this.news.valid_to = this.news.endDate
        this.news.news_channel = 'Private'
        await this.$profileProvider.createContentData(this.news).then(
          (result) => {
            if (result.status === 201) {
              this.onCancel()
            }
          },
          (error) => {
            console.log('onUploadFile', error.responsed)
          }
        )
      }
    },
  },
}
