
import BreadCrumb from '@/components/template/bread-crumb.vue'

export default {
  name: 'StoreLocation',
  components: {
    breadcrumb: BreadCrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: this.$route.params.name,
        },
      ],
      filter: {
        isKerry: false,
        isSameDay: false,
        isDriveThru: false,
      },
      activeStore: {},
      locationsFilter: [],
      locations: [
        {
          index: 1,
          id: 'STORE001',
          name: 'ฟิวเจอร์พาร์ค (รังสิต)',
          address: 'เลขที่ 98 ถนนพหลโยธิน ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130',
          businessHours: 'Monday - Sunday: 09:00-19:00',
          phone: ['064-587-1491', '02-958-5594-96'],
          email: 'OFMSTRangsit@ofm.co.th',
          line: '@OFM_Rangsit',
          isKerry: true,
          isSameDay: true,
          isDriveThru: true,
        },
        {
          index: 2,
          id: 'STORE002',
          name: 'เซ็นทรัลพลาซา พระราม 2 ชั้น G',
          address: 'เลขที่ 98 ถนนพหลโยธิน ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130',
          businessHours: 'Monday - Sunday: 09:00-19:00',
          phone: ['064-587-1491', '02-958-5594-96'],
          email: 'OFMSTRangsit@ofm.co.th',
          line: '@OFM_Rangsit',
          isKerry: true,
          isSameDay: true,
          isDriveThru: false,
        },
        {
          index: 3,
          id: 'STORE003',
          name: 'บิ๊กซีเอ็กซ์ตร้า ลาดพร้าว 2 ชั้น 1',
          address: 'เลขที่ 98 ถนนพหลโยธิน ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130',
          businessHours: 'Monday - Sunday: 09:00-19:00',
          phone: ['064-587-1491', '02-958-5594-96'],
          email: 'OFMSTRangsit@ofm.co.th',
          line: '@OFM_Rangsit',
          isKerry: true,
          isSameDay: true,
          isDriveThru: false,
        },
        {
          index: 4,
          id: 'STORE004',
          name: 'บิ๊กซีเอ็กซ์ตร้า รามอินทรา ชั้น 1',
          address: 'เลขที่ 98 ถนนพหลโยธิน ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130',
          businessHours: 'Monday - Sunday: 09:00-19:00',
          phone: ['064-587-1491', '02-958-5594-96'],
          email: 'OFMSTRangsit@ofm.co.th',
          line: '@OFM_Rangsit',
          isKerry: true,
          isSameDay: false,
          isDriveThru: false,
        },
        {
          index: 5,
          id: 'STORE005',
          name: 'เซ็นทรัลเฟสติวัล ภูเก็ต ชั้น P2',
          address: 'เลขที่ 98 ถนนพหลโยธิน ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130',
          businessHours: 'Monday - Sunday: 09:00-19:00',
          phone: ['064-587-1491', '02-958-5594-96'],
          email: 'OFMSTRangsit@ofm.co.th',
          line: '@OFM_Rangsit',
          isKerry: false,
          isSameDay: false,
          isDriveThru: true,
        },
      ],
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
    }
  },
  watch: {
    filter: {
      handler: function (val) {
        let data = JSON.parse(JSON.stringify(this.locations))
        if (val.isKerry) data = data.filter((e) => e.isKerry === true)
        if (val.isSameDay) data = data.filter((e) => e.isSameDay === true)
        if (val.isDriveThru) data = data.filter((e) => e.isDriveThru === true)
        this.activeStore = {}
        this.locationsFilter = data
      },
      deep: true,
    },
  },
  mounted() {
    this.locationsFilter = JSON.parse(JSON.stringify(this.locations))
  },
  methods: {
    onCheckLocation() {
      console.log('this location')
    },
  },
}
