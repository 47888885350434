
import InputText from '@/components/share/input-text.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TableCostcenter',
  components: {
    'input-text': InputText,
  },
  props: {
    deptId: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    isCreate: { type: Boolean, default: true },
    // perPage: { type: Number, default: () => 0 },
  },
  data() {
    return {
      filter: '',
      itemCount: 0,
      test: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'code',
          label: '',
          class: 'text-left fs-12 w-110 border-right',
          sortable: false,
        },
        {
          key: 'name.th',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14',
          sortable: false,
        },
        // {
        //   key: 'status',
        //   label: '',
        //   thClass: '',
        //   tdClass: 'text-left fw-light',
        //   sortable: false,
        // },
        {
          key: 'action',
          label: '',
          class: 'text-center w-60 fw-light',
          sortable: false,
        },
      ],
      costcenter: {
        id: '',
        offline_id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        account_id: '',
        shippings: [],
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.items.length / this.perPage)) {
        last = this.items.length
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.items.length === 0) {
        first = 0
        last = 0
      } else if (this.items.length < this.perPage) {
        last = this.items.length
      }
      return first + '-' + last
    },
  },
  watch: {
    perPage: {
      handler: function () {
        this.currentPage = 1
        this.$emit('onGetHeight')
      },
      deep: true,
    },
    currentPage: {
      handler: function () {
        this.$emit('onGetHeight')
      },
      deep: true,
    },
  },
  created() {
    //this.itemCount = this.items.length
  },
  methods: {
    onEdit(id) {
      console.log(id)
    },
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
      this.$emit('onGetHeight')
    },
    onEditCostcenter(detp_id, costcenter_id) {
      this.$router.push({
        path: this.$i18n.path(
          'company/setting/costcenter/edit/' + costcenter_id
        ),
        query: { dept: detp_id },
      })
    },
  },
}
