
import { mapActions, mapGetters, mapState } from 'vuex'
import InputQty from '@/components/share/input-qty.vue'
import ProductNotifyModal from '@/components/modal/productNotify.vue'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'
export default {
  name: 'SKuItemComponent',
  components: {
    'input-qty': InputQty,
    ProductNotifyModal,
  },
  props: {
    item: { type: Object, default: () => {} },
    source: { type: String, default: 'cartPage' },
    view: { type: String, default: 'grid' },
    listName: { type: String, default: '' },
    isShowNormalPrice: { type: Boolean, default: true },
    queryIdEventAlgolia: { type: String, default: '' },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    showFlashSale: { type: Boolean, default: false },
    overlayImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      notifyProductName: {},
      notifySku: '',
      notifyModal: false,
      flip: false,
      compare: [],
      qty: 1,
      maxQty: 999,
      skuName: '',
      skuSEOName: '',
      salePrice: 0,
      promotionPrice: 0,
      data: {},
      lang: '',
      sku: {
        retail_price: {
          include_vat: 0.0,
        },
      },
      skuMechanics: [],
      selectedMaterial: {},
      skuMechanicKeys: [],
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      body: {
        id: null,
        user_open_id: null,
        email: null,
        name: null,
        sku_code: null,
        qty: 0,
      },
      isAlgolia: {
        addCart: false,
      },
      categories: [],
    }
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
    ]),
    isMyGuest() {
      return this.isAuthenticated && this.isGuest
    },
    imageNormalShowcase() {
      let timeStamp = this.$utils.isObjectKeyValid(
        this.item.update_info,
        'timestamp'
      )
        ? /*? '?v=' + this.$utils.getDateString(this.item.update_info.timestamp)
        : ''*/
          ''
        : ''

      if (this.sku.images != null && this.sku.images.length) {
        return (
          this.sku.images.some((q) => q.size === 'large')
            ? this.sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'large').url + timeStamp
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    imageSmallShowcase() {
      if (this.sku.images != null && this.sku.images.length) {
        return this.sku.images.some((q) => q.size === 'sp')
          ? this.sku.images.find((q) => q.size === 'sp').url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
    isOnlineOnly() {
      return this.sku.is_online_only || this.sku.is_flash_sale
    },
    isDiscountRate() {
      return (
        this.$utils.isObjectValid(this.sku) &&
        this.$utils.anyArrayObjectValid(this.sku.promotion_prices) &&
        this.$utils.isObjectValid(this.sku.display_discount_rate) &&
        this.sku.display_discount_rate > 0 &&
        (this.sku.is_promotion ||
          this.sku.is_online_only ||
          this.sku.is_flash_sale)
        // this.sku.is_promotion
      )
    },
    isDiscount() {
      return (
        this.sku.is_promotion ||
        this.sku.is_fixprice ||
        this.sku.is_online_only ||
        this.sku.is_flash_sale
      )
    },
    isAddCart() {
      return ['ready_to_ship', 'lead_time', 'preorder'].includes(
        this.sku.display_product_status
      )
    },
    isOverlay() {
      if (this.overlayImages.length && this.sku.sku_code) {
        const selectedProduct = this.overlayImages.filter(
          (product) => product.sku_code === this.sku.sku_code
        )
        console.log('overlayImages', selectedProduct[0]?.overlay_web_image)
        return selectedProduct[0]
      }
      // return this.$utils.isObjectValid(this.sku.overlay_web_image)
      //   ? this.sku.overlay_web_image.some((e) => e.size === 'thumbnail')
      //   : false
    },
    isFlashSale() {
      return this.sku.is_flash_sale
    },
    isHotFlashSale() {
      return this.sku.is_hot_flash_sale
    },
    isPromotionDiscount() {
      return this.sku.is_promotion_discount
    },
    // isOutofstockFlash() {
    //   return this.sku.is_outofstock_flash_sale
    // },
    isInstallmentBySku() {
      return this.sku?.is_installment_sku
    },
  },
  watch: {
    item: {
      handler: function () {
        // this.data = this.item
        this.sku = this.item
        if (
          Object.keys(this.sku).length &&
          this.sku.product_names != undefined &&
          this.sku.product_names.length
        ) {
          let productName = this.item.product_names.find(
            (q) => q.type.toLowerCase() === 'online'
          )
          this.skuName = productName.display_name
          this.skuSEOName = this.item.meta.seo_name[this.lang]
          // this.sku.product_names.forEach((element) => {
          //   if (element.type === 'Online') {
          //     this.skuName = element.display_name
          //   }
          // })
        }
      },
      deep: true,
    },
    '$i18n.locale': function (val) {
      this.lang = val
    },
  },
  mounted() {
    this.notifyProductName = this.item.product_name
    console.log('notifyProductName : ', this.notifyProductName)
    this.lang = this.$i18n.locale
    // this.data = this.item
    this.sku = this.item
    if (
      Object.keys(this.sku).length &&
      this.sku.product_names != undefined &&
      this.sku.product_names.length
    ) {
      // this.sku.product_names.forEach((element) => {
      //   if (element.type === 'Online') {
      //     this.skuName = element.display_name
      //   }
      // })
      let productName = this.sku.product_names.find(
        (q) => q.type.toLowerCase() === 'online'
      )
      this.skuName = productName.display_name
      this.skuSEOName = this.item.meta.seo_name[this.$i18n.locale]
      this.qty = this.sku.minimum_quantity <= 0 ? 1 : this.sku.minimum_quantity

      this.$utils.onPreload(
        this.$utils.genImgSrcSet(
          this.$utils.imageTimeStamp(
            this.imageNormalShowcase,
            this.item?.update_info?.timestamp,
            true
          ),
          [
            { w: 115, h: 115, q: 90, ws: '115w' },
            { w: 150, h: 150, q: 90, ws: '150w' },
          ]
        ),
        '(max-width: 360px) 115px, (max-width: 412px) 115px, (max-width: 1024px) 150px'
      )
    }
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    ...mapActions('cart', ['addCart', 'setCartCount']),
    async onSubmitProductNotify(data) {
      // console.log('onSubmitProductNotify', data)
      let apiData = JSON.stringify(data)
      // console.log('data', apiData)
      const result = await this.$productNotifyMeProvider.sendNotifyMe(apiData)
      if (result.status === 201) {
        console.log('onSubmitProductNotify success')
      }
    },
    onRoute() {
      // console.log('route')
      if (this.$utils.isValid(this.listName)) {
        //gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'productClicked',
          currencyCode: 'THB',
          ecommerce: {
            click: {
              actionField: { list: this.listName },
              products: [
                {
                  id: this.sku.sku_code,
                  price: this.$utils.formatPrice(
                    this.sku.price_include_vat,
                    2,
                    true
                  ),
                  position: this.item.seq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const item_list_id =
          (this.sku.is_flash_sale && 'flash_sale') ||
          (this.sku.is_promotion && 'promotions') ||
          ''
        const item_list_name =
          (this.sku.is_flash_sale && 'flash sale') ||
          (this.sku.is_promotion && 'Promotions') ||
          ''
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_item',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            item_list_id,
            item_list_name,
            items: [
              {
                item_list_id,
                item_list_name,
                item_id: this.sku.sku_code,
                item_name: this.sku.product_names[1]?.display_name.th,
                item_brand: this.item.brand?.display_name.en,
                price: this.sku.price_include_vat,
                discount:
                  this.sku.retail_price.include_vat -
                  this.sku.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }

      if (this.$utils.isValid(this.triggerEventAlgolia)) {
        this.$eventInsightsAlgolia.sendEvent(
          this.triggerEventAlgolia,
          this.isMyGuest ? this.guestId : this.openId,
          this.queryIdEventAlgolia,
          [this.sku.sku_code],
          [this.positionOnPage],
          this.indexEventAlgolia
        )
      }

      // this.$nextTick(() => {
      //   this.$router.push({
      //     path: this.$i18n.path(`product/${this.skuSEOName}-p.${this.sku.id}`),
      //     query: { queryIdEventAlgolia: this.queryIdEventAlgolia },
      //   })
      //   // this.$router.push({
      //   //   path: this.$i18n.path(
      //   //     'product/' +
      //   //       this.skuName[this.lang].split(/[.\-_/#]/).join('') +
      //   //       '-p.' +
      //   //       this.sku.id
      //   //   ),
      //   // })
      // })
    },
    onChangeQty(qty) {
      let max = Number(this.sku.maximum_quantity > 0)
        ? Number(this.sku.maximum_quantity)
        : this.maxQty

      // console.log('item', qty, max, Number(qty), max < Number(qty))
      if (max < Number(qty)) this.qty = String(max)

      // this.qty =
      //   this.sku.minimum_quantity <= qty ? qty : this.sku.minimum_quantity
    },
    async onAddToCart() {
      if (this.isMyGuest) return this.gotoLogin()

      let parseData = this.userProfile
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: parseData.id,
        account_channel: this.profileType,
        skus: [
          {
            sku: this.sku.sku_code,
            qty: this.qty,
          },
        ],
      })
      const result = await this.$shoppingCartProvider.addCart(apiData)
      if (result.status === 201) {
        if (this.source === 'cartPage') {
          this.$nuxt.$emit('reloadCart')
        }
        // gtm
        if (result.data.success_items.length) {
          this.$nuxt.$emit('addToCart')
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'addToCart',
            ecommerce: {
              add: {
                products: result.data.success_items.length
                  ? result.data.success_items.map((item) => {
                      let catLv1 = ''
                      let catLv2 = ''
                      let catLv3 = ''
                      let catIdLv1 = ''
                      let catIdLv2 = ''
                      let catIdLv3 = ''
                      if (this.$utils.isObjectKeyValid(item, 'categories')) {
                        if (item.categories.find((key) => key.level === 1)) {
                          catLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).display_name['en']
                          catIdLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 2)) {
                          catLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).display_name['en']
                          catIdLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 3)) {
                          catLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).display_name['en']
                          catIdLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).id
                        }
                      }
                      return {
                        id: item.sku_code,
                        price: this.$utils.formatPrice(
                          item.price_include_vat,
                          2,
                          true
                        ),
                        categoryLv1: catLv1,
                        categoryLv2: catLv2,
                        categoryLv3: catLv3,
                        categoryIdLv1: catIdLv1,
                        categoryIdLv2: catIdLv2,
                        categoryIdLv3: catIdLv3,
                        quantity: item.qty,
                      }
                    })
                  : [],
              },
            },
          })

          // gtm GA4
          const item_list_id =
            (this.sku.is_flash_sale && 'flash_sale') ||
            (this.sku.is_promotion && 'promotions') ||
            ''
          const item_list_name =
            (this.sku.is_flash_sale && 'flash sale') ||
            (this.sku.is_promotion && 'Promotions') ||
            ''
          const categories = result.data.success_items[0].categories
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'add_to_cart',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              item_list_id,
              item_list_name,
              items: [
                {
                  item_list_id,
                  item_list_name,
                  item_id: this.sku.sku_code,
                  item_name: this.sku.product_names[1]?.display_name.th,
                  item_brand: this.item.brand?.display_name.en,
                  ...this.$utils.productCategories(categories),
                  quantity: this.qty,
                  price: this.sku.price_include_vat,
                  discount:
                    this.sku.retail_price.include_vat -
                    this.sku.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                },
              ],
            },
          })

          if (
            this.$utils.isValid(this.triggerEventAlgolia) &&
            !this.isAlgolia.addCart
          ) {
            this.$eventInsightsAlgolia.sendEvent(
              this.triggerEventAlgolia ===
                TriggerInsightsAlgoliaEnum.SEARCHVIEWPDP
                ? TriggerInsightsAlgoliaEnum.SEARCHPLPADDCART
                : TriggerInsightsAlgoliaEnum.NONSEARCHPLPADDCART,
              this.isMyGuest ? this.guestId : this.openId,
              this.queryIdEventAlgolia,
              [item.sku.product_id],
              [this.positionOnPage],
              this.indexEventAlgolia
            )
            this.isAlgolia.addCart = true
          }
        }
        if (
          result.data.warning_messages.length &&
          result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          ).length
        ) {
          const sku_limit = result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          )
          this.$nuxt.$emit('addToCart', {
            message: {
              title: sku_limit[0].message_title,
              description: sku_limit[0].message_description,
            },
            isWarning: true,
          })
        }
        const cartResponsed = await this.$shoppingCartProvider.getCartCount(
          this.cartId,
          parseData.id
        )
        if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
      }
    },
    onNotify() {
      if (this.isMyGuest) return this.gotoLogin()
      // console.log(this.sku.sku_code)
      this.notifyModal = true
      this.notifySku = this.sku.sku_code
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
  },
}
