
import InputText from '@/components/share/input-text.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import modalWarning from '@/components/modal/checkout-warning'
import Consent from '@/components/share/consent.vue'
import { mapActions, mapGetters } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  layout: 'checkout-nav',
  name: 'CheckoutPayment',
  components: {
    'input-text': InputText,
    'summary-component': SummaryComponent,
    'modal-warning': modalWarning,
    consent: Consent,
    'media-items': async () =>
      await import('@/components/installment/media-items.vue'),
    'radio-banks': async () =>
      await import('@/components/installment/radio-banks.vue'),
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'cart',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      sortPaymentList: [
        'CreditTerm',
        'FullPayment',
        'FullPayment',
        'FullPayment',
        'PayOnDelivery',
        'Installment',
      ],
      hasPaymentList: false,
      paymentList: [],
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderTemp: {
        payment_option: '',
        installment_option: '',
        payment_bank_installment_id: '',
      },
      installment_type: '',
      cashOnDelivery: 'cash',
      counterBank: '',
      onlineBanking: '',
      budget: {
        value: 15000,
        max: 30000,
        variant: 'primary',
      },
      payment: {
        cardNo: '',
        cardName: '',
        cardExpYear: '',
        cardExpMonth: '',
        cardCVV: '',
        isSaveCard: true,
        encCardData: '',
        maskedCardNo: '',
        language: this.$i18n.locale,
      },
      // originalPayment: {},
      installment: {
        cardNo: '',
        cardName: '',
        cardExpYear: '',
        cardExpMonth: '',
        cardCVV: '',
        isSaveCard: true,
        encCardData: '',
        maskedCardNo: '',
        selectedMonth: '',
        orderId: '',
        user_open_id: '',
        code: '',
        period: '',
        periods: [],
        agentCode: '',
        language: this.$i18n.locale,
        plan_id: '',
      },
      selectedPayment: 0,
      consentStatus: false,
      modal: {
        warning: false,
      },
      warningMessages: [],
      forceRedirect: false,
      agentList: [],
      paymentChannels: [],
      selectAgent: {},
      selectPaymentChannel: {},
      loading: false,
      isShowPrivacy: false,
      isDisableButton: false,
      creditLimit: 0,
      t1c_no: '',
      hasActiveInstallment: false,
      hasAmountInstallment: false,
      payments: [],
      paymentsCart: [],
      paymentsSku: [],
      periods: [],
      periodsInstallment: [],
      error_messange: {
        en: '',
        th: '',
      },
      is_message: false,
      period_months: [],
      payment_installment: '',
      installment_data: [],
      bank_cardName: '',
      isInstallmentOptionCart: false,
      isMonthInstallmentEmpty: false,
      creditCard: {
        promotions: [],
        isShow: false,
        promotion_id: '',
        promotion_option: '',
      },
      is_delivery: false,
      bankInstallmentOptions: [],
      selectedPlan: {},
      isShowInActive: false,
      inActiveItems: [],
      warningMessage: {},
      item_ga: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'userProfile']),
    ...mapGetters('authen', ['openId']),
    isPayByCreditCard() {
      if (Object.keys(this.orderTemp).length) {
        return this.orderTemp.payment_type === '2C2P Credit Card Online' ||
          this.payment_installment === '2C2P Installment'
          ? true
          : false
      } else return false
    },
    isPayBy123Payment() {
      if (Object.keys(this.orderTemp).length) {
        return this.orderTemp.payment_type === '2C2P 1-2-3 Payment'
          ? true
          : false
      } else return false
    },
    securePayUrl() {
      return process.env.NUXT_ENV_2C2P_SECURE_PAY_URL
    },
    isCreditCardPromotionOption() {
      return this.$utils.anyArrayObjectValid(this.creditCard.promotions)
    },
    isShowCreditTerm() {
      return (
        this.$utils.anyArrayObjectValid(this.paymentList) &&
        this.paymentList.some((s) => s.payment_group === 'CreditTerm')
      )
    },
    // isUserCorporate() {
    //   return (
    //     this.$utils.isObjectValid(this.orderTemp) &&
    //     this.$utils.isObjectValid(this.orderTemp.account_master) &&
    //     this.$utils.isValid(this.orderTemp.account_master.type) &&
    //     this.orderTemp.account_master.type.toLowerCase().trim() === 'corporate'
    //   )
    // },
    // isUserCorporateEprocurement() {
    //   return (
    //     this.isUserCorporate &&
    //     this.orderTemp.account_channel.toLowerCase().trim() === 'eprocurement'
    //   )
    // },
    conditionCredit() {
      if (!this.isShowCreditTerm) return ''
      let paymentCreditTerm = this.paymentList.find(
        (s) => s.payment_group === 'CreditTerm'
      )
      if (
        this.$utils.isObjectKeyValid(paymentCreditTerm, 'is_credit') &&
        !paymentCreditTerm.is_credit
      )
        return 'no_credit'
      else if (
        !this.$utils.isObjectValid(paymentCreditTerm) ||
        (this.$utils.isObjectKeyValid(paymentCreditTerm, 'is_payment') &&
          !paymentCreditTerm.is_payment)
      )
        return 'no_payment_credit_term_sku'
      else if (
        this.$utils.isObjectKeyValid(paymentCreditTerm, 'is_over_credit') &&
        paymentCreditTerm.is_over_credit
      )
        return 'is_over_credit'
      return ''
    },
  },
  watch: {
    // selectedPayment: function (val) {
    //   console.log(val)
    //   this.orderTemp.payment_option = ''
    // },
    // 'creditCard.promotion_option': {
    //   async handler(val) {
    //     // if (this.$utils.isValid(this.orderTemp.promotion_option))
    //     this.orderTemp.promotion_option = val
    //   },
    // },
    installment_type: {
      async handler(val) {
        this.clearInstallment()
      },
    },
    'payment.cardNo': {
      async handler(val) {
        if (
          this.$utils.isObjectValid(this.orderTemp) &&
          this.$utils.isValid(this.orderTemp.payment_type) &&
          this.orderTemp.payment_type === '2C2P Credit Card Online' &&
          this.$utils.anyArrayObjectValid(this.creditCard.promotions) &&
          this.$utils.isValid(val) &&
          val.length === 16
        ) {
          console.log('watch payment', val)
          await this.generateActiveCreditCardPromotions()
        } else {
          this.resetEnableCreditCardPromotion()
        }

        // this.originalPayment = Object.assign({}, val)
      },
    },
    // payment: {
    //   async handler(val) {
    //     // console.log('payment')
    //     if (
    //       this.$utils.isObjectValid(this.orderTemp) &&
    //       this.$utils.isValid(this.orderTemp.payment_type) &&
    //       this.orderTemp.payment_type === '2C2P Credit Card Online' &&
    //       this.$utils.anyArrayObjectValid(this.creditCard.promotions) &&
    //       this.$utils.isValid(val.cardNo) &&
    //       val.cardNo.length === 16
    //       // this.$utils.isValid(val.cardName) &&
    //       // this.$utils.isValid(val.cardExpMonth) &&
    //       // val.cardExpMonth.length === 2 &&
    //       // this.$utils.isValid(val.cardExpYear) &&
    //       // val.cardExpYear.length === 4 &&
    //       // this.$utils.isValid(val.cardCVV) &&
    //       // val.cardCVV.length >= 3
    //     ) {
    //       // let isValidCardName =
    //       //   this.$utils.isValid(this.originalPayment.cardName) &&
    //       //   this.originalPayment.cardName !== val.cardName
    //       // let _this = this
    //       // await this.$refs['payment_cardnumber']
    //       //   .validate()
    //       //   .then(async function (valid) {
    //       //     if (valid) {
    //       // console.log('isValidCardName', isValidCardName)
    //       // if (isValidCardName && val.cardName.length > 1) return

    //       console.log('watch payment', val)
    //       await this.generateActiveCreditCardPromotions()
    //       //   }
    //       // })
    //     } else {
    //       this.resetEnableCreditCardPromotion()
    //     }

    //     this.originalPayment = Object.assign({}, val)
    //     // this.validateCreditCard()
    //   },
    //   deep: true,
    // },
  },
  async created() {
    // if (this.$utils.isValid(this.$route.params.tempId)) {
    //   //get temp
    //   await this.getOrderTemporary().then(
    //     async () => await this.checkTabActive()
    //   )
    // } else this.$router.push({ path: this.$i18n.path('cart') })
    this.$profileProvider
      .getUserConsetStatus(this.openId, this.userInfo.email)
      .then((result) => {
        if (result.status === 200) {
          this.isShowPrivacy =
            result.data.consent_marketing_status === true ? false : true
        } else this.isShowPrivacy = true
      })
  },
  async mounted() {
    if (this.$utils.isValid(this.$route.params.tempId)) {
      this.hasPaymentList = false
      //get temp
      await this.getOrderTemporary().then(async () => {
        if (process.client) await this.checkTabActive()
      })
    } else this.$router.push({ path: this.$i18n.path('cart') })
  },
  // async updated() {
  //   await this.checkTabActive()
  // },
  methods: {
    ...mapActions('user', ['setUserInfo']),
    ...mapActions('order', ['setDisplay2C2PPaymentStep']),
    isCheckPaymentInList(payment) {
      /* No credit term */
      // console.log('this.isUserCorporate:', this.isUserCorporate)
      console.log('paymentList', this.paymentList)
      if (!this.$utils.anyArrayObjectValid(this.paymentList)) return false

      let _payment = this.paymentList.find((s) => s.payment_group === payment)
      if (!this.$utils.isObjectValid(_payment)) return false
      return (
        this.$utils.isObjectKeyValid(_payment, 'is_payment') &&
        _payment.is_payment
      )
    },
    onSelectAgent(agent, tab) {
      if (agent !== undefined) {
        this.selectAgent = agent
        this.paymentChannels = agent.channels.filter(
          (q) => q.channel_code !== 'OTC'
        )
        if (tab === 'Counter' || tab === 'BankCounter')
          this.selectPaymentChannel = agent.channels.find(
            (q) => q.channel_code === 'OTC'
          )
      } else this.paymentChannels = []
    },
    onCheckout() {
      if (
        this.$utils.isValid(this.orderTemp.payment_code) &&
        this.$utils.isValid(this.orderTemp.payment_type) &&
        this.$utils.isValid(this.orderTemp.payment_option)
      ) {
        if (
          this.orderTemp.payment_type !== '2C2P Credit Card Online' &&
          this.orderTemp.payment_type !== '2C2P 1-2-3 Payment' &&
          this.payment_installment !== '2C2P Installment'
        ) {
          this.loading = true
          // this.isDisableButton = true
          this.updateOrderTemporaryPaymentAndCreateOrder()
        } else {
          if (this.orderTemp.payment_type === '2C2P 1-2-3 Payment') {
            if (
              Object.keys(this.selectAgent).length &&
              Object.keys(this.selectPaymentChannel).length
            ) {
              this.updateOrderTemporaryPaymentAndCreateOrder()
            } else {
              if (!Object.keys(this.selectAgent).length) {
                this.forceRedirect = true
                this.warningMessages = []
                this.modal.warning = true
                this.warningMessages = [
                  {
                    key: '',
                    message: {
                      th: 'กรุณาเลือกช่องทางชำระเงิน',
                      en: 'Please choose your payment method',
                    },
                  },
                ]
              } else if (!Object.keys(this.selectPaymentChannel).length) {
                this.forceRedirect = true
                this.warningMessages = []
                this.modal.warning = true
                this.warningMessages = [
                  {
                    key: '',
                    message: {
                      th: 'กรุณาเลือกช่องทางชำระเงิน',
                      en: 'Please choose your payment method',
                    },
                  },
                ]
              }
            }
          } else if (
            this.orderTemp.payment_type === '2C2P Credit Card Online' &&
            this.payment_installment !== '2C2P Installment'
          ) {
            this.$refs['2c2p-payment-form'].validate().then((result) => {
              console.log('object :>> ', this.$refs['2c2p-payment-form'])
              if (result) {
                this.validateCreditCard()
              }
            })
          } else {
            if (this.payment_installment === '2C2P Installment') {
              this.isMonthInstallmentEmpty = !this.$utils.isValid(
                this.installment.selectedMonth
              )
              console.log('this.installment', this.installment)
              if (this.orderTemp.installment_option)
                this.$refs['2c2p-installment-payment-form']
                  .validate()
                  .then((result) => {
                    if (
                      result &&
                      this.$utils.isValid(this.installment.selectedMonth) &&
                      this.installment.cardNo
                    ) {
                      this.validateCreditCard()
                    }
                  })
            } else {
              this.$refs['2c2p-payment-form'].validate().then((result) => {
                if (result) {
                  this.validateCreditCard()
                }
              })
            }
          }
        }
      }
    },

    // this.$router.push({
    //   path: '/checkout/complete',
    // })

    async getOrderTemporary() {
      const result = await this.$shoppingCartProvider.getOrderTemporary(
        this.$route.params.tempId
      )
      if (result.status === 404 || result.status === 400) {
        this.$router.push({ path: this.$i18n.path('cart') })
      }
      console.log('result : ', result)
      if (result.status === 200) {
        this.orderTemp = result.data
        this.t1c_no = this.orderTemp.t1c_no
        console.log('this.orderTemp', this.orderTemp)
        this.paymentList = result.data.payments
        this.creditLimit = result.data.creditLimit

        // this.summary = {
        //   net_amt: this.orderTemp.net_amt,
        //   net_deliveryfee_excvat: this.orderTemp.net_deliveryfee_excvat,
        //   nonvat_prod_net_amt: this.orderTemp.nonvat_prod_net_amt,
        //   vat_amt: this.orderTemp.vat_amt,
        //   vat_prod_net_amt: this.orderTemp.vat_prod_net_amt,
        //   voucher_disc_amt_excvat: this.orderTemp.voucher_disc_amt_excvat,
        //   voucher_no: this.orderTemp.voucher_no,
        //   other_disc_amt_incvat: this.orderTemp.other_disc_amt_incvat,
        //   other_disc_amt: this.orderTemp.other_disc_amt,
        //   payment_disc_amt_excvat: this.orderTemp.payment_disc_amt_excvat,
        //   payment_disc_code: this.orderTemp.payment_disc_code,
        //   is_use_coupon: this.orderTemp.is_use_coupon,
        //   used_voucher_type: this.orderTemp.used_voucher_type,
        //   promo_disc_amt_incvat: this.orderTemp.promo_disc_amt_incvat,
        //   promo_disc_amt_excvat: this.orderTemp.promo_disc_amt_excvat,
        // }
        this.summary = {
          net_amt: this.orderTemp.summary.net_amt,
          net_deliveryfee_excvat: this.orderTemp.summary.net_deliveryfee_excvat,
          nonvat_prod_net_amt: this.orderTemp.summary.nonvat_prod_net_amt,
          vat_amt: this.orderTemp.summary.vat_amt,
          vat_prod_net_amt: this.orderTemp.summary.vat_prod_net_amt,
          voucher_disc_amt_excvat:
            this.orderTemp.summary.voucher_disc_amt_excvat,
          voucher_no: this.orderTemp.voucher_no,
          other_disc_amt_incvat: this.orderTemp.summary.other_disc_amt_incvat,
          other_disc_amt_excvat: this.orderTemp.summary.other_disc_amt_excvat,
          payment_disc_amt_excvat:
            this.orderTemp.summary.payment_disc_amt_excvat,
          payment_disc_code: this.orderTemp.payment_disc_code,
          is_use_coupon: this.orderTemp.is_use_coupon,
          used_voucher_type: this.orderTemp.voucher_type, // used_voucher_type
          promo_disc_amt_incvat: this.orderTemp.summary.promo_disc_amt_incvat,
          promo_disc_amt_excvat: this.orderTemp.summary.promo_disc_amt_excvat,
          // dcc
          net_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.net_discount_delivery_fee_exc_vat,
          total_delivery_fee_exc_vat:
            this.orderTemp.summary.total_delivery_fee_exc_vat,
          segment_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.segment_discount_delivery_fee_exc_vat,
          other_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.other_discount_delivery_fee_exc_vat,
          account_segment: this.orderTemp.account_segment,
          // multi coupon
          seller_voucher_disc_amt_excvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_excvat,
          seller_voucher_disc_amt_incvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_incvat,
          seller_voucher_no: this.orderTemp.summary?.seller_voucher_no,
          seller_voucher_type: this.orderTemp.summary?.seller_voucher_type,
          delivery_fee_voucher_no: this.orderTemp?.delivery_fee_voucher_no,
          is_use_coupon_delivery: this.orderTemp?.is_use_coupon_delivery,
          voucher_discount_delivery_fee_exc_vat:
            this.orderTemp?.summary?.voucher_discount_delivery_fee_exc_vat,
        }
        this.hasPaymentList = true
        this.item_ga = this.orderTemp.sub_orders.flatMap(
          (subOrder) => subOrder.items
        )
        // gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'checkout',
          basketTotal: this.$utils.formatPrice(
            this.summary.net_amt + this.summary.vat_amt,
            2,
            true
          ),
          ecommerce: {
            checkout: {
              actionField: { step: 3 },
              products: this.item_ga
                ? this.item_ga.map((item) => {
                    return {
                      id: item.sku_code,
                      price: this.$utils.formatPrice(
                        item.price_incvat,
                        2,
                        true
                      ),
                      quantity: item.quantity,
                    }
                  })
                : [],
            },
          },
        })
      }
    },
    async generateCreditCardPromotions() {
      this.creditCard.promotions = []
      let result = await this.getPaymentCreditCardPromotions()
      if (this.$utils.anyArrayObjectValid(result)) {
        result.forEach((value) => {
          this.creditCard.promotions.push({
            id: value.id,
            img: value.payment_bank_credit_card.card_image.url, //require(`~/assets/images/payment/THE1CARD-MC-BLACK.png`),
            bank_name: {
              th: value.payment_bank_master.name.th,
              en: value.payment_bank_master.name.en,
            },
            credit_card: {
              id: value.payment_bank_credit_card.id,
              th: value.payment_bank_credit_card.name.th,
              en: value.payment_bank_credit_card.name.en,
            },
            promotion_remark: {
              th: value.description.th,
              en: value.description.en,
            },
            is_enable: false,
          })
        })
      }
    },
    async getPaymentCreditCardPromotions(cardNo = '') {
      this.loading = true
      const params = new URLSearchParams()
      if (this.$utils.isValid(cardNo)) params.append('card_no', cardNo)
      let result =
        await this.$shoppingCartProvider.getPaymentCreditCardPromotionsOrderTemporary(
          this.$route.params.tempId,
          params
        )
      this.loading = false
      if (result.status === 200) return result.data
      return null
    },
    maskedCardInfo(text, index, replacement) {
      return (
        text.substring(0, index) +
        replacement +
        text.substring(index + replacement.length)
      )
    },
    async generateActiveCreditCardPromotions() {
      this.resetEnableCreditCardPromotion()
      // this.payment.maskedCardNo = this.maskedCardInfo(
      //   this.payment.cardNo,
      //   6,
      //   'XXXXXX'
      // )
      // let result = await this.getPaymentCreditCardPromotions(
      //   this.payment.maskedCardNo
      // )
      this.payment.maskedCardNo = this.$encrypt.generateKeysAndEncrypt(
        this.payment.cardNo
      )
      let result = await this.getPaymentCreditCardPromotions(
        this.payment.maskedCardNo
      )

      if (this.$utils.anyArrayObjectValid(result)) {
        let creditCardPromotionIds = result.map((q) => q.id)
        let mapCreditCardPromotionId = this.creditCard.promotions.map((x) => {
          x.is_enable = creditCardPromotionIds.includes(x.id)
          return x
        })
        this.creditCard.promotions.length = 0
        this.creditCard.promotions.push(...mapCreditCardPromotionId)

        this.creditCard.promotions.sort((x, y) => y.is_enable - x.is_enable)
        // console.log('this.creditCard.promotions', this.creditCard.promotions)
        return true
      }
      // }
      return false
    },
    async checkTabActive() {
      // !this.$utils.isValid(this.orderTemp.payment_code) &&
      //   !this.$utils.isValid(this.orderTemp.payment_type)
      if (
        !this.$utils.isValid(this.orderTemp?.payment_code) &&
        !this.$utils.isValid(this.orderTemp?.payment_type)
      ) {
        // let channelCodeList = []
        console.log('checkTabActive')
        setTimeout(async () => {
          let element = this.$refs['tabs-payment'].registeredTabs.find(
            (q) => q.localActive
          )

          if (element !== undefined) {
            let elementId = element.id
            if (!this.isShowCreditTerm) this.sortPaymentList.splice(0, 1)

            if (this.paymentList.some((q) => q.is_payment)) {
              let _payments = this.paymentList.filter((q) => q.is_payment)
              let _paymentGroups = _payments.map((q) => q.payment_group)
              let isPaymentDefaultSelected = false
              this.sortPaymentList.forEach((item) => {
                if (
                  !isPaymentDefaultSelected &&
                  _paymentGroups.includes(item)
                ) {
                  elementId = item
                  this.selectedPayment = this.sortPaymentList.indexOf(item)
                  isPaymentDefaultSelected = true
                }
              })
            }
            console.log('elementId', elementId)
            if (elementId === 'CreditTerm') {
              this.orderTemp.payment_code = 'Credit'
              this.orderTemp.payment_type =
                this.orderTemp.account_master.payment.type
              this.orderTemp.payment_option =
                this.orderTemp.account_master.payment.type

              if (this.isCheckPaymentInList('CreditTerm')) {
                let payment = this.paymentList.find(
                  (s) => s.payment_group == 'CreditTerm'
                )

                let isNotPayment = !payment.is_payment || !payment.is_credit
                // payment.is_over_credit ||

                this.isDisableButton = isNotPayment
              } else this.isDisableButton = true
            } else if (elementId === 'PayOnDelivery') {
              this.orderTemp.payment_code = 'Cash'
              this.orderTemp.payment_type = 'Cash on Delivery'
              this.orderTemp.payment_option = 'Cash on Delivery'

              if (this.isCheckPaymentInList('PayOnDelivery')) {
                let payment = this.paymentList.find(
                  (s) => s.payment_group == 'PayOnDelivery'
                )
                console.log('element PayOnDelivery', payment)
                this.isDisableButton = !payment.is_payment
              } else this.isDisableButton = true
            } else if (elementId === 'FullPayment') {
              this.orderTemp.payment_code = 'Cash'
              this.orderTemp.payment_type = '2C2P Credit Card Online'
              this.orderTemp.payment_option = '2C2P Credit Card Online'

              if (this.isCheckPaymentInList('FullPayment')) {
                let payment = this.paymentList.find(
                  (s) => s.payment_group == 'FullPayment'
                )
                console.log('element FullPayment', payment)
                this.isDisableButton = !payment.is_payment

                this.$nextTick(async () => {
                  this.creditCard.isShow = true
                  console.log('refs', this.$refs['2c2p-payment-form'])
                  if (
                    this.$utils.isObjectValid(this.$refs['2c2p-payment-form'])
                  )
                    this.$refs['2c2p-payment-form'].reset()
                  await this.generateCreditCardPromotions()
                })
              } else this.isDisableButton = true
            } else if (elementId === 'Installments') {
              this.orderTemp.payment_code = 'Cash'
              // this.orderTemp.installment_type = ''
              this.orderTemp.installment_option = ''
              this.isInstallmentOptionCart = false
              this.orderTemp.payment_type = '2C2P Credit Card Online'
              this.orderTemp.payment_option = '2C2P Credit Card Online'
              this.payment_installment = '2C2P Installment'
              this.$nextTick(() => {
                this.getInstallment2c2p()
              })
              // if (this.isCheckPaymentInList('Installments')) {
              //   this.$nextTick(() => {
              //     this.getInstallment2c2p()
              //   })
              // } else this.isDisableButton = true
            }
          }

          // else if (element.id === 'FullPayment3') {
          //   this.orderTemp.payment_code = 'Cash'
          //   this.orderTemp.payment_type = '2C2P Credit Card Online'
          //   this.orderTemp.payment_option = '2C2P Credit Card Online'
          //   this.creditCard.isShow = true
          //   this.$nextTick(async () => {
          //     console.log('refs', this.$refs['2c2p-payment-form'])
          //     if (this.$utils.isObjectValid(this.$refs['2c2p-payment-form']))
          //       this.$refs['2c2p-payment-form'].reset()
          //     await this.generateCreditCardPromotions()
          //   })
          // } else {
          //   this.orderTemp.payment_code = 'Cash'
          //   this.orderTemp.payment_type = '2C2P 1-2-3 Payment'
          //   if (element.id === 'FullPayment') channelCodeList = ['OTC']
          //   else if (element.id === 'FullPayment2')
          //     channelCodeList = ['IMB', 'ATM']
          //   if (this.$utils.anyArrayObjectValid(channelCodeList))
          //     await this.get2c2p123Agent(channelCodeList)
          // }
          // else if (elementId === 'FullPayment2') {
          //   this.orderTemp.payment_code = 'Cash'
          //   this.orderTemp.payment_type = '2C2P 1-2-3 Payment'
          //   channelCodeList = ['OTC']
          //   if (this.isCheckPaymentInList('FullPayment')) {
          //     let payment = this.paymentList.find(
          //       (s) => s.payment_group == 'FullPayment'
          //     )
          //     console.log('element FullPayment2', payment)
          //     this.isDisableButton = !payment.is_payment

          //   } else this.isDisableButton = true
          // } else if (elementId === 'FullPayment3') {
          //   this.orderTemp.payment_code = 'Cash'
          //   this.orderTemp.payment_type = '2C2P 1-2-3 Payment'
          //   channelCodeList = ['IMB', 'ATM']
          //   if (this.isCheckPaymentInList('FullPayment')) {
          //     let payment = this.paymentList.find(
          //       (s) => s.payment_group == 'FullPayment'
          //     )
          //     console.log('element FullPayment3', payment)
          //     this.isDisableButton = !payment.is_payment
          //   } else this.isDisableButton = true
          // }

          // if (this.$utils.anyArrayObjectValid(channelCodeList))
          //   await this.get2c2p123Agent(channelCodeList)

          // console.log(document.getElementsByClassName('tab-pane active')[0])
          // console.log(this.$refs['tabs-payment'].registeredTabs)
          // console.log(
          //   'klasjdklasj',
          //   this.$refs['tabs-payment'].registeredTabs.filter(
          //     (q) => q.localActive === true
          //   )
          // )
        }, 100)
      }
      // else {
      // this.$nextTick(function () {
      //   let indexTab = -1
      //   let tabId = ''
      //   if (this.orderTemp.payment_code === 'Credit') {
      //     tabId = 'CreditTerm'
      //   } else if (
      //     this.orderTemp.payment_type === 'Cash on Delivery' &&
      //     this.orderTemp.payment_option === 'Cash on Delivery'
      //   ) {
      //     tabId = 'PayOnDelivery'
      //   } else if ((this.orderTemp.payment_type = '2C2P 1-2-3 Payment')) {
      //     tabId = ''
      //   } else if (
      //     this.orderTemp.payment_type === '2C2P Credit Card Online' &&
      //     this.orderTemp.payment_option === '2C2P Credit Card Online' &&
      //     this.payment_installment === '2C2P Installment'
      //   ) {
      //     tabId = 'Installment'
      //   } else if (
      //     this.orderTemp.payment_type === '2C2P Credit Card Online' &&
      //     this.orderTemp.payment_option === '2C2P Credit Card Online'
      //   ) {
      //     tabId = 'FullPayment'
      //   }
      //   if (indexTab !== -1)
      //     this.selectedPayment = this.$refs['tabs-payment'].tabs.findIndex(
      //       (q) => q.id === tabId
      //     )
      //   console.log('testttttttttttttt', this.selectedPayment)
      // })
      // }
    },
    async onTabClick(event, channelCodeList = []) {
      this.resetPaymentInstallment()
      // this.hasActiveInstallment = false
      this.creditCard.isShow = false
      this.isDisableButton = false
      this.clearInstallment()
      this.clearCreditCard()
      switch (event) {
        case 'CreditTerm':
          this.orderTemp.payment_code = 'Credit'
          this.orderTemp.payment_type =
            this.orderTemp.account_master.payment.type
          this.orderTemp.payment_option =
            this.orderTemp.account_master.payment.type
          if (this.isCheckPaymentInList('CreditTerm')) {
            let payment = this.paymentList.find(
              (s) => s.payment_group == 'CreditTerm'
            )
            this.isDisableButton = !payment.is_payment || !payment.is_credit
          } else this.isDisableButton = true
          break
        case 'Installments':
          this.orderTemp.payment_code = 'Cash'
          this.installment_type = ''
          this.selectedPlan = {}
          this.orderTemp.installment_option = ''
          this.isInstallmentOptionCart = false
          this.orderTemp.payment_type = '2C2P Credit Card Online'
          this.orderTemp.payment_option = '2C2P Credit Card Online'
          this.payment_installment = '2C2P Installment'
          this.$nextTick(() => {
            this.getInstallment2c2p()
          })
          // if (this.isCheckPaymentInList('Installments')) {
          //   this.$nextTick(() => {
          //     this.getInstallment2c2p()
          //   })
          // } else this.isDisableButton = true
          break
        case 'PayOnDelivery':
          this.checkCashOnDelivery()
          this.orderTemp.payment_code = 'Cash'
          this.orderTemp.payment_type = 'Cash on Delivery'
          this.orderTemp.payment_option = 'Cash on Delivery'
          if (this.isCheckPaymentInList('PayOnDelivery')) {
            let payment = this.paymentList.find(
              (s) => s.payment_group == 'PayOnDelivery'
            )

            this.isDisableButton = !payment.is_payment // || payment.is_over_credit
          } else this.isDisableButton = true
          break
        case 'FullPayment':
          this.orderTemp.payment_code = 'Cash'
          this.orderTemp.payment_type = '2C2P 1-2-3 Payment'
          this.orderTemp.payment_option = ''
          if (this.isCheckPaymentInList('FullPayment')) {
            let payment = this.paymentList.find(
              (s) => s.payment_group == 'FullPayment'
            )
            this.isDisableButton = !payment.is_payment // || payment.is_over_credit
          } else this.isDisableButton = true
          break
        case 'FullPaymentCreditCard':
          this.orderTemp.payment_code = 'Cash'
          this.orderTemp.payment_type = '2C2P Credit Card Online'
          this.orderTemp.payment_option = '2C2P Credit Card Online'
          this.orderTemp.promotion_option = ''

          console.log(this.paymentList)
          if (this.isCheckPaymentInList('FullPayment')) {
            let payment = this.paymentList.find(
              (s) => s.payment_group == 'FullPayment'
            )
            this.isDisableButton = !payment.is_payment // || payment.is_over_credit
          } else this.isDisableButton = true

          this.creditCard.promotion_option = {}
          this.$nextTick(async () => {
            this.creditCard.isShow = true
            console.log('refs', this.$refs)
            if (this.$utils.isObjectValid(this.$refs['2c2p-payment-form']))
              this.$refs['2c2p-payment-form'].reset()
            await this.generateCreditCardPromotions()
          })
          break
        default:
          // isEvent = false
          break
      }
      console.log(event)
      this.paymentChannels = []
      this.selectPaymentChannel = {}
      this.selectAgent = {}
      if (this.$utils.anyArrayObjectValid(channelCodeList))
        await this.get2c2p123Agent(channelCodeList)
    },
    async get2c2p123Agent(channelCodeList) {
      // console.log('channelCodeList', channelCodeList)
      this.loading = true
      this.agentList = []
      const params = new URLSearchParams()
      channelCodeList.forEach((channelCode) =>
        params.append('channels_code', channelCode)
      )
      await this.$orderProvider.get2c2p123Agent(params).then((result) => {
        if (result.status === 200) {
          this.agentList = result.data
          this.loading = false
        } else {
          //TODO
          this.loading = false
        }
      })
    },
    onChangePaymentCOD(event) {
      this.orderTemp.payment_type = event
    },
    onSelectInstallmentCart(data) {
      console.log('onSelectInstallmentCart', data)
      this.installment.selectedMonth = ''
      this.isShowInActive = false
      this.inActiveItems = []
      this.selectedPlan = {}
      this.installment.plan_id = ''
      this.installment.period = ''
      this.isInstallmentOptionCart = true
      this.bankInstallmentOptions = data?.installment_plan
      this.isMonthInstallmentEmpty = false
      console.log('installment_option :>> ', this.orderTemp.installment_option)
      this.installment.agentCode = this.orderTemp.installment_option

      this.$nextTick(() => {
        this.periods = this.periodsInstallment.filter(
          (a) => a.bank_id === this.orderTemp.installment_option && a.is_status
        )
        this.periods.forEach((a) => {
          this.period_months.push(a.month)
        })
        // this.bank_cardName = this.installment_data.installment.find(
        //   (a) => a.bank_id === this.orderTemp.installment_option
        // )?.bank_name.en

        this.bank_cardName = data?.bank_name
        console.warn('cardName :>> ', data)
      })
    },
    async onSelectCreditCardPromotion(promotion) {
      console.log('reCalulateOrderTemporary', promotion)
      if (this.$utils.isValid(promotion)) {
        this.creditCard.promotion_id = promotion.id
        let params = new URLSearchParams()
        params.append('credit_card_promotion_id', promotion.id)
        params.append('credit_card_id', promotion.credit_card.id)
        params.append('card_no', this.payment.maskedCardNo)
        let result =
          await this.$shoppingCartProvider.reCalulateOrderTemporaryCreditCard(
            this.$route.params.tempId,
            params
          )
        if (result.status === 200) {
          console.log('reCalulateOrderTemporary success')
          let _orderTemp = result.data
          this.summary = {
            net_amt: _orderTemp.summary.net_amt,
            net_deliveryfee_excvat: _orderTemp.summary.net_deliveryfee_excvat,
            nonvat_prod_net_amt: _orderTemp.summary.nonvat_prod_net_amt,
            vat_amt: _orderTemp.summary.vat_amt,
            vat_prod_net_amt: _orderTemp.summary.vat_prod_net_amt,
            voucher_disc_amt_excvat: _orderTemp.summary.voucher_disc_amt_excvat,
            voucher_no: _orderTemp.voucher_no,
            other_disc_amt_incvat: _orderTemp.summary.other_disc_amt_incvat,
            other_disc_amt_excvat: _orderTemp.summary.other_disc_amt_excvat,
            payment_disc_amt_excvat: _orderTemp.summary.payment_disc_amt_excvat,
            payment_disc_code: _orderTemp.payment_disc_code,
            promo_disc_amt_incvat: _orderTemp.summary.promo_disc_amt_incvat,
            promo_disc_amt_excvat: _orderTemp.summary.promo_disc_amt_excvat,
            used_voucher_type: _orderTemp.voucher_type, // used_voucher_type
            is_use_coupon: _orderTemp.is_use_coupon,
            // dcc
            net_discount_delivery_fee_exc_vat:
              _orderTemp.summary.net_discount_delivery_fee_exc_vat,
            total_delivery_fee_exc_vat:
              _orderTemp.summary.total_delivery_fee_exc_vat,
            segment_discount_delivery_fee_exc_vat:
              _orderTemp.summary.segment_discount_delivery_fee_exc_vat,
            other_discount_delivery_fee_exc_vat:
              _orderTemp.summary.other_discount_delivery_fee_exc_vat,
            account_segment: _orderTemp.account_segment,
            // multi coupon
            seller_voucher_disc_amt_excvat:
              _orderTemp.summary?.seller_voucher_disc_amt_excvat,
            seller_voucher_disc_amt_incvat:
              _orderTemp.summary?.seller_voucher_disc_amt_incvat,
            seller_voucher_no: _orderTemp.summary?.seller_voucher_no,
            seller_voucher_type: _orderTemp.summary?.seller_voucher_type,
            delivery_fee_voucher_no: _orderTemp?.delivery_fee_voucher_no,
            is_use_coupon_delivery: _orderTemp?.is_use_coupon_delivery,
            voucher_discount_delivery_fee_exc_vat:
              _orderTemp.summary?.voucher_discount_delivery_fee_exc_vat,
          }
        } else {
          console.log('reCalulateOrderTemporary error')
          await this.resetSelectedCreditCardPromotionOnTop()
        }
      }
    },
    async updateOrderTemporaryPayment() {
      console.log(this.payment.cardNo)
      let apiData = JSON.stringify({
        payment_code: this.orderTemp.payment_code,
        payment_type: this.orderTemp.payment_type,
        payment_option: this.orderTemp.payment_option,
        credit_card_method:
          this.payment_installment !== '2C2P Installment'
            ? 'Normal'
            : 'Installment',
        // credit_card_no:
        //   this.payment.maskedCardNo !== '' ? this.payment.maskedCardNo : null,
        credit_card_no:
          this.payment.cardNo !== ''
            ? this.$encrypt.generateKeysAndEncrypt(this.payment.cardNo)
            : null,
        credit_card_name:
          this.payment_installment === '2C2P Installment'
            ? this.bank_cardName ?? ''
            : '',
        t1c_no: this.t1c_no,
        credit_card_promotion_id: this.$utils.isValid(
          this.creditCard.promotion_id
        )
          ? this.creditCard.promotion_id
          : '',
        // : this.payment.cardName !== ''
        // ? this.payment.cardName
        // : null,
        payment_bank_installment_id: this.orderTemp.payment_bank_installment_id,
      })

      return await this.$shoppingCartProvider.updateOrderTemporaryPayment(
        this.orderTemp.id,
        apiData
      )
    },
    async updateOrderTemporaryPaymentAndCreateOrder() {
      // disabled checkout
      this.isDisableButton = true

      let result = await this.updateOrderTemporaryPayment()
      console.log('updateOrderTemporaryPaymentAndCreateOrder', result)
      if (result.status === 204) {
        //TODO Create Order
        // this.$router.push({
        //   path: '/checkout/complete',
        // })
        this.createOrder()
        if (this.isShowPrivacy) {
          this.createConsentStatus()
        }
      } else if (result.status === 412) {
        if (this.$utils.isValid(this.creditCard.promotion_id)) {
          await this.resetSelectedCreditCardPromotionOnTop()
          this.resetDefaultSummary()
        }
      } else {
        this.isDisableButton = false
      }
    },
    async createOrder() {
      console.warn('555555555 :>> ', this.isPayByCreditCard)
      //gtm
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'checkout',
        basketTotal: this.$utils.formatPrice(
          this.summary.net_amt + this.summary.vat_amt,
          2,
          true
        ),
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option:
                this.orderTemp.payment_code === 'Credit'
                  ? 'Credit'
                  : this.orderTemp.payment_type,
            },
            products: this.item_ga
              ? this.item_ga.map((item) => {
                  return {
                    id: item.sku_code,
                    price: this.$utils.formatPrice(item.price_incvat, 2, true),
                    quantity: item.quantity,
                  }
                })
              : [],
          },
        },
      })

      // gtm GA4
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'add_payment_info',
        ecommerce: {
          user: this.$store.getters['user/userInfoTrack'],
          currency: 'THB',
          value: this.summary.net_amt + this.summary.vat_amt,
          payment_type:
            this.orderTemp.payment_code === 'Credit'
              ? 'Credit'
              : this.orderTemp.payment_type,
          coupon_discount: this.summary.voucher_disc_amt_excvat,
          coupon: this.summary.voucher_no,
          total_price_no_tax: this.summary.nonvat_prod_net_amt,
          total_price_before_tax: this.summary.vat_prod_net_amt,
          shipping: this.summary.net_deliveryfee_excvat || 0.0,
          tax: this.summary.vat_amt,
          total_discount: this.summary.other_disc_amt_incvat,
          items: this.item_ga.map((item, index) => {
            return {
              item_id: item.sku_code,
              item_name: item.product_names[1]?.display_name.th,
              index: index,
              item_brand: item.brand?.display_name.en,
              item_category: item.categories[2]?.display_name.en,
              item_category2: item.categories[1]?.display_name.en,
              item_category3: item.categories[0]?.display_name.en,
              price: item.price_incvat,
              quantity: item.quantity,
              discount: item.price_disc_incvat - item.price_incvat,
              ...this.$store.getters['user/userPromotionTrack'],
            }
          }),
        },
      })

      let apiData = JSON.stringify({
        temp_id: this.$route.params.tempId,
        user_id: this.openId,
        user_name: `${this.userInfo.first_name.th} ${this.userInfo.last_name.th}`,
        email: `${this.userInfo.email}`,
      })

      await this.$orderProvider.createOrder(apiData).then(async (result) => {
        if (result.status === 201) {
          // remove shopback session
          sessionStorage.removeItem('sessionShopback')
          // multi coupon
          Cookie.set(
            `coupons_${this.openId}-${this.userProfile.id}`,
            {
              voucher_no: '',
              delivery_fee_voucher_no: '',
              selected_suborders: [],
            },
            {
              expires: 1,
              secure: true,
              sameSite: 'none',
            }
          )
          console.log(result.data)
          if (
            result.data.warning_messages.length != null &&
            result.data.warning_messages.length
          ) {
            this.modal.warning = true
            this.warningMessages = result.data.warning_messages
          } else {
            if (this.isPayByCreditCard && !this.is_message) {
              await this.submitTo2c2pGateway(result.data.order_id)
            } else if (this.isPayBy123Payment) {
              await this.create2c2p123Payment(
                result.data.unique_transaction_code,
                result.data.order_id
              )
            } else {
              this.$router.push({
                path: this.$i18n.path('checkout/complete'),
                name:
                  this.$i18n.locale === 'th'
                    ? 'checkout-complete-orderId'
                    : 'lang-checkout-complete-orderId',
                params: { orderId: result.data.order_id },
              })
            }
          }
        } else if (result.status === 412) {
          if (this.$utils.isValid(this.creditCard.promotion_id)) {
            await this.resetSelectedCreditCardPromotionOnTop()
            this.resetDefaultSummary()
          }
        } else {
          this.isDisableButton = false
        }
      })
    },
    createConsentStatus() {
      let payload = JSON.stringify({
        email: this.userInfo.email,
        user_open_id: this.openId,
        consent_status: this.consentStatus,
      })
      this.$profileProvider.createUserConsentStatus(payload)
      let tempUserData = this.userInfo
      tempUserData.consent.consent_marketing_status = this.consentStatus
      console.log('tempUserData', tempUserData)
      this.setUserInfo({ payload: tempUserData })
    },
    onConfirmModalWarning() {
      if (!this.forceRedirect) {
        this.$router.push({
          path: this.$i18n.path('cart'),
        })
      } else this.forceRedirect = false
    },
    changeLanguage(errorDescription) {
      this.isDisableButton = false
      let messageDescription = {}

      // if (this.$i18n.locale === 'th') {
      switch (errorDescription) {
        case 6:
          // messageDescription = 'กรุณากรอก ปีที่บัตรหมดอายุ เป็นปี ค.ศ. 4 หลัก'
          messageDescription = {
            th: 'กรุณากรอก ปีที่บัตรหมดอายุ เป็นตัวเลข 2 หลัก',
            en: 'the length of expiry year field does not match',
          }
          break
        case 2:
          messageDescription = {
            th: 'หมายเลขบัตร หรือ จำนวนหลักที่ระบุไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้งด้วยตัวเลข 16 หลัก',
            en: 'card number is invalid ',
          }
          break
        case 10:
          messageDescription = {
            th: 'หมายเลข CVV ไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้งด้วยตัวเลข 3 หลัก',
            en: 'invalid CVV ',
          }
          break
        case 8:
        case 9:
          messageDescription = {
            th: 'เดือนที่บัตรหมดอายุไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้ง',
            en: 'invalid card expiry month',
          }
          break
        case 7:
          messageDescription = {
            th: 'บัตรเครดิตที่คุณระบุหมดอายุแล้ว โปรดตรวจสอบปีที่บัตรหมดอายุที่ระบุหรือเปลี่ยนบัตรใบใหม่',
            en: 'card already expired(year)',
          }
          break
        default:
          messageDescription
          break
      }
      return messageDescription
      // } else {
      //   return messageDescription
      // }
    },
    async validateCreditCard() {
      // console.log('l;sakl;dkasdl;kasdk')
      // let validateFlag = false
      // let encryptedCardInfo = ''
      // let errorDescription = ''
      // let maskedCardInfo = ''
      // let expMonthCardInfo = ''
      // let expYearCardInfo = ''
      // eslint-disable-next-line no-undef
      let result = this.checkCreditCard()
      if (result.validateFlag) {
        this.payment.encCardData = result.encryptedCardInfo
        this.payment.maskedCardNo = result.maskedCardInfo
        await this.updateOrderTemporaryPaymentAndCreateOrder()
      } else {
        if (result.errorDescription !== '') {
          console.log('errorDescription', result)
          this.warningMessages = []
          this.warningMessages.push({
            message: this.changeLanguage(result.errorDescription),
          })
          this.forceRedirect = true
          this.modal.warning = true
        }
      }
    },
    checkCreditCard() {
      let encryptedCardInfo = ''
      let errorDescription = ''
      let maskedCardInfo = ''
      let validateFlag = false
      let payment_form =
        this.payment_installment === '2C2P Installment'
          ? '2c2p-installment-payment-form'
          : '2c2p-payment-form'
      My2c2p.getEncrypted(
        payment_form,
        function (encryptedData, errCode, errDesc) {
          // console.log('encryptedData', encryptedData, errCode, errDesc)
          if (errCode != 0) {
            // alert(errDesc + ' (' + errCode + ')')}
            errorDescription = errCode
          } else {
            encryptedCardInfo = encryptedData.encryptedCardInfo
            maskedCardInfo = encryptedData.maskedCardInfo
            validateFlag = true
          }
        }
      )

      if (
        (payment_form === '2c2p-payment-form' &&
          this.payment.cardExpMonth.length === 1) ||
        (payment_form === '2c2p-installment-payment-form' &&
          this.installment.cardExpMonth.length === 1)
      ) {
        console.warn('installment :>> ', this.installment.cardExpMonth)
        errorDescription = 6
        validateFlag = false
      }

      if (
        (payment_form === '2c2p-payment-form' &&
          this.payment.cardExpMonth === '00') ||
        (payment_form === '2c2p-installment-payment-form' &&
          this.installment.cardExpMonth === '00')
      ) {
        console.warn('installment :>> ', this.installment.cardExpMonth)
        errorDescription = 8
        validateFlag = false
      }

      if (
        payment_form === '2c2p-installment-payment-form' &&
        this.installment.cardNo.length < 16
      ) {
        errorDescription = 2
        validateFlag = false
      }

      return {
        encryptedCardInfo: encryptedCardInfo,
        errorDescription: errorDescription,
        maskedCardInfo: maskedCardInfo,
        validateFlag: validateFlag,
      }
    },
    async submitTo2c2pGateway(orderId) {
      if (this.payment_installment !== '2C2P Installment') {
        this.payment.orderId = orderId
        this.payment.user_open_id = this.openId
      } else {
        this.installment.orderId = orderId
        this.installment.user_open_id = this.openId
        this.installment.code = 'IPP'
        this.installment.period = this.installment.selectedMonth
        this.installment.periods = this.period_months
      }
      console.warn('object :>> ', this.installment)
      // await this.$orderProvider
      //   .create2c2pPayment(
      //     this.payment_installment === '2C2P Installment'
      //       ? this.installment
      //       : this.payment
      //   )
      let dataPayment =
        this.payment_installment === '2C2P Installment'
          ? this.installment
          : this.payment
      delete dataPayment['encCardData']
      let body = {
        data_encrypt: this.$encrypt.generateKeysAndEncrypt(
          JSON.stringify(dataPayment)
        ),
      }
      await this.$orderProvider.create2c2pPayment(body).then((result) => {
        let payload = {
          respCode: result.data.respCode,
          respDesc: result.data.respDesc,
          statusCode: result.data.statusCode,
        }
        console.warn('payload :>> ', payload)
        if (result.status === 201) {
          console.warn('object :>> ', result.data)
          this.setDisplay2C2PPaymentStep(payload)
          if (result.data.respCode === '1001') {
            window.location.href = result.data.url
          } else {
            this.$router.push({
              path: this.$i18n.path(`checkout/callback/${orderId}`),
            })
          }
        } else {
          payload.statusCode = 'C'
          this.setDisplay2C2PPaymentStep(payload)
          this.$router.push({
            path: this.$i18n.path(`checkout/callback/${orderId}`),
          })
        }
      })
    },
    async create2c2p123Payment(uniqueTransactionCode, orderId) {
      let totalAmt = this.summary.net_amt + this.summary.vat_amt
      let apiData = JSON.stringify({
        merchant_id: '',
        merchant_reference: uniqueTransactionCode,
        amount: totalAmt,
        currency_code: 'THB',
        redirect_url: process.env.NUXT_ENV_2C2P_123_NOTIFICATION_URL,
        notification_url: process.env.NUXT_ENV_2C2P_123_NOTIFICATION_URL,
        buyer_email: this.userInfo.email,
        buyer_language: 'TH',
        payment_expiry: this.$utils.get2c2pPaymentExpire(),
        preferred_agent: this.selectAgent.agent_code,
        preferred_channel: this.selectPaymentChannel.channel_code,
        preferred_sub_channel: this.selectPaymentChannel.sub_channel_code,
        include_instructions_url: true,
        notify_buyer: true,
        checksum: '',
      })
      const result = await this.$orderProvider.create2c2p123Payment(apiData)
      if (result.status === 201) {
        if (this.$utils.isValid(result.data)) {
          this.$router.push({
            path: this.$i18n.path('payment/123payment'),
            name:
              this.$i18n.locale === 'th'
                ? 'payment-123payment-id'
                : 'lang-payment-123payment-id',
            params: { id: orderId },
            query: { paymentCode: result.data, callback: 'checkout/complete' },
          })
        }
      } else {
        //TODO
        this.$router.push({
          path: this.$i18n.path(`checkout/callback/${orderId}`),
        })
      }
    },
    // installment skus
    async getInstallment2c2p() {
      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('order_id', this.$route.params.tempId)
      const result = await this.$productProvider.paymentnstallment2c2p(params)
      if (result.status === 200) {
        let data = result.data
        this.installment_data = data
        console.warn('installment_data :>> ', this.installment_data)
        console.warn('payments :>> ', this.payments)
        this.paymentsCart = []
        this.paymentsSku = []
        this.periodsInstallment = []

        // cart
        if (
          data.installment_cart?.is_active &&
          data.installment_cart?.installment_banks.length
        ) {
          data.installment_cart.installment_banks.forEach((a) => {
            console.log('data.installment', a)
            // let paymentName = a.plan_name[this.$i18n.lang]
            let paymentImage =
              this.$utils.isValid(a.bank_images) &&
              a.bank_images.some((a) => a.type === 'image')
                ? a.bank_images.find((a) => a.type === 'image')?.cdn_url ?? ''
                : ''
            this.paymentsCart.push({
              bank_sequence: a.bank_sequence,
              bank_id: a.bank_id,
              bank_name: a.bank_name[this.$i18n.locale],
              name: a.title[this.$i18n.locale],
              image: paymentImage,
              is_active: a.is_active,
              description: a.description[this.$i18n.locale],
              installment_plan: a.installment_plan,
              warning_message: a.warning_message,
            })
          })
        }

        // sku
        if (
          data.installment_sku?.is_active &&
          data.installment_sku?.installment_banks.length
        ) {
          data.installment_sku.installment_banks.forEach((a) => {
            console.log('data.installment', a)
            // let paymentName = a.plan_name[this.$i18n.lang]
            let paymentImage =
              this.$utils.isValid(a.bank_images) &&
              a.bank_images.some((a) => a.type === 'image')
                ? a.bank_images.find((a) => a.type === 'image')?.cdn_url ?? ''
                : ''
            this.paymentsSku.push({
              bank_sequence: a.bank_sequence,
              bank_id: a.bank_id,
              bank_name: a.bank_name[this.$i18n.locale],
              name: a.title[this.$i18n.locale],
              image: paymentImage,
              is_active: a.is_active,
              description: a.description[this.$i18n.locale],
              installment_plan: a.installment_plan,
              warning_message: a.warning_message,
            })
          })
        }
        console.log('paymentsSku', this.paymentsSku)
        this.hasActiveInstallment = data.is_active
        this.hasAmountInstallment =
          data.is_active &&
          ((this.$utils.anyArrayObjectValid(this.paymentsCart) &&
            this.paymentsCart.some((q) => q.is_active)) ||
            (this.$utils.anyArrayObjectValid(this.paymentsSku) &&
              this.paymentsSku.some((q) => q.is_active)))
        this.isDisableButton =
          !this.hasActiveInstallment || !this.hasAmountInstallment
        // if (
        //   this.$utils.anyArrayObjectValid(this.paymentsCart) &&
        //   this.paymentsCart.some((q) => q.is_active) &&
        //   this.$utils.anyArrayObjectValid(this.paymentsSku) &&
        //   this.paymentsSku.some((q) => q.is_active)
        // ) {
        //   this.installment_type = 'installment_sku'
        // }
        if (
          this.installment_data?.installment_sku?.is_active === false &&
          this.installment_data?.installment_cart?.is_active === true
        ) {
          this.installment_type = 'installment_cart'
        } else if (
          this.installment_data?.installment_sku?.is_active === true &&
          this.installment_data?.installment_cart?.is_active === false
        ) {
          this.installment_type = 'installment_sku'
        } else if (
          this.installment_data?.installment_sku?.is_active === true &&
          this.installment_data?.installment_cart?.is_active === true
        ) {
          this.installment_type = 'installment_sku'
        }
      }
    },
    async checkValidateCardNo() {
      console.log('object :>> ', this.orderTemp.installment_option)
      this.error_messange = ''
      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('bank_id', this.orderTemp.installment_option)
      // params.append('bin', this.installment.cardNo)
      params.append(
        'bin',
        this.$encrypt.generateKeysAndEncrypt(this.installment.cardNo)
      )
      const result = await this.$productProvider.paymentnstallment2c2pValidate(
        params
      )
      if (result.status === 200) {
        this.is_message = false
        this.error_messange = ''
      } else {
        this.is_message = true
        this.error_messange = {
          en: 'Invalid card',
          th: 'เลขบัตรผิด',
        }
      }
    },
    async clearInstallment() {
      this.installment.selectedMonth = ''
      this.installment.cardNo = ''
      this.installment.cardName = ''
      this.installment.cardExpYear = ''
      this.installment.cardExpMonth = ''
      this.installment.cardCVV = ''
      this.bankInstallmentOptions = []
      this.isInstallmentOptionCart = false
      this.orderTemp.installment_option = ''
      this.selectedPlan = {}
      this.isShowInActive = false
      this.orderTemp.payment_bank_installment_id = ''
      this.inActiveItems = []
    },
    clearCreditCard() {
      console.log('clear credit card')
      this.payment.cardNo = ''
      this.payment.cardName = ''
      this.payment.cardExpYear = ''
      this.payment.cardExpMonth = ''
      this.payment.cardCVV = ''
      this.payment.isSaveCard = true
      this.payment.encCardData = ''
      this.payment.maskedCardNo = ''
      this.creditCard.promotion_id = ''
      this.resetDefaultSummary()
    },
    async resetSelectedCreditCardPromotionOnTop() {
      this.creditCard.promotion_option = {}
      this.creditCard.promotion_id = ''
      await this.generateCreditCardPromotions()
      await this.generateActiveCreditCardPromotions()
    },
    resetEnableCreditCardPromotion() {
      console.log('resetEnableCreditCardPromotion')
      this.creditCard.promotion_option = {}
      this.creditCard.promotion_id = ''
      this.resetDefaultSummary()
      let mapCreditCardPromotionId = this.creditCard.promotions.map((x) => {
        x.is_enable = false
        return x
      })
      this.creditCard.promotions.length = 0
      this.creditCard.promotions.push(...mapCreditCardPromotionId)
    },
    resetDefaultSummary() {
      // this.summary = {
      //   net_amt: this.orderTemp.net_amt,
      //   net_deliveryfee_excvat: this.orderTemp.net_deliveryfee_excvat,
      //   nonvat_prod_net_amt: this.orderTemp.nonvat_prod_net_amt,
      //   vat_amt: this.orderTemp.vat_amt,
      //   vat_prod_net_amt: this.orderTemp.vat_prod_net_amt,
      //   voucher_disc_amt_excvat: this.orderTemp.voucher_disc_amt_excvat,
      //   voucher_no: this.orderTemp.voucher_no,
      //   other_disc_amt_incvat: this.orderTemp.other_disc_amt_incvat,
      //   other_disc_amt: this.orderTemp.other_disc_amt,
      //   payment_disc_amt_excvat: this.orderTemp.payment_disc_amt_excvat,
      //   payment_disc_code: this.orderTemp.payment_disc_code,
      //   is_use_coupon: this.orderTemp.is_use_coupon,
      //   used_voucher_type: this.orderTemp.used_voucher_type,
      //   promo_disc_amt_incvat: this.orderTemp.promo_disc_amt_incvat,
      //   promo_disc_amt_excvat: this.orderTemp.promo_disc_amt_excvat,
      // }
      this.isDisableButton = false
      this.summary = {
        net_amt: this.orderTemp.summary.net_amt,
        net_deliveryfee_excvat: this.orderTemp.summary.net_deliveryfee_excvat,
        nonvat_prod_net_amt: this.orderTemp.summary.nonvat_prod_net_amt,
        vat_amt: this.orderTemp.summary.vat_amt,
        vat_prod_net_amt: this.orderTemp.summary.vat_prod_net_amt,
        voucher_disc_amt_excvat: this.orderTemp.summary.voucher_disc_amt_excvat,
        voucher_no: this.orderTemp.voucher_no,
        other_disc_amt_incvat: this.orderTemp.summary.other_disc_amt_incvat,
        other_disc_amt_excvat: this.orderTemp.summary.other_disc_amt_excvat,
        payment_disc_amt_excvat: this.orderTemp.summary.payment_disc_amt_excvat,
        payment_disc_code: this.orderTemp.payment_disc_code,
        is_use_coupon: this.orderTemp.is_use_coupon,
        used_voucher_type: this.orderTemp.voucher_type, // used_voucher_type
        promo_disc_amt_incvat: this.orderTemp.summary.promo_disc_amt_incvat,
        promo_disc_amt_excvat: this.orderTemp.summary.promo_disc_amt_excvat,
        // dcc
        net_discount_delivery_fee_exc_vat:
          this.orderTemp.summary.net_discount_delivery_fee_exc_vat,
        total_delivery_fee_exc_vat:
          this.orderTemp.summary.total_delivery_fee_exc_vat,
        segment_discount_delivery_fee_exc_vat:
          this.orderTemp.summary.segment_discount_delivery_fee_exc_vat,
        other_discount_delivery_fee_exc_vat:
          this.orderTemp.summary.other_discount_delivery_fee_exc_vat,
        account_segment: this.orderTemp.account_segment,
        // multi coupon
        seller_voucher_disc_amt_excvat:
          this.orderTemp.summary?.seller_voucher_disc_amt_excvat,
        seller_voucher_disc_amt_incvat:
          this.orderTemp.summary?.seller_voucher_disc_amt_incvat,
        seller_voucher_no: this.orderTemp.summary?.seller_voucher_no,
        seller_voucher_type: this.orderTemp.summary?.seller_voucher_type,
        delivery_fee_voucher_no: this.orderTemp?.delivery_fee_voucher_no,
        is_use_coupon_delivery: this.orderTemp?.is_use_coupon_delivery,
        voucher_discount_delivery_fee_exc_vat:
          this.orderTemp?.summary?.voucher_discount_delivery_fee_exc_vat,
      }
    },
    resetPaymentInstallment() {
      this.payments = []
      this.paymentsCart = []
      this.paymentsSku = []
      this.periods = []
      this.periodsInstallment = []
      this.error_messange = {
        en: '',
        th: '',
      }
      this.installment = {
        cardNo: '',
        cardName: '',
        cardExpYear: '',
        cardExpMonth: '',
        cardCVV: '',
        isSaveCard: true,
        encCardData: '',
        maskedCardNo: '',
        selectedMonth: '',
        orderId: '',
        user_open_id: '',
        code: '',
        period: '',
        periods: [],
        agentCode: '',
        language: this.$i18n.locale,
        plan_id: '',
      }
      this.is_message = false
      this.period_months = []
      this.payment_installment = ''
      this.installment_data = []
      this.bank_cardName = ''
      this.isInstallmentOptionCart = false
      this.isMonthInstallmentEmpty = false
      this.selectedPlan = {}
    },
    async checkCashOnDelivery() {
      const result = await this.$orderProvider.getPaymentCashOnDelivery(
        this.$route.params.tempId
      )
      if (result.status === 200) {
        this.is_delivery = result.data?.is_cash_on_delivery
        console.log('getPaymentCashOnDelivery :>> ', this.is_delivery)
      }
    },
    onSetInstallment(data) {
      this.selectedPlan = this.bankInstallmentOptions.find(
        (option) => option.plan_id === data
      )
      this.orderTemp.payment_bank_installment_id = data
      this.installment.selectedMonth = this.selectedPlan?.period
      this.installment.period = this.selectedPlan?.period
      console.log('selectedPlan', this.orderTemp)
    },
    async onShowInActive(data) {
      console.log('data', data)
      this.installment.agentCode = ''
      this.orderTemp.installment_option = ''
      // this.inActiveItems = data.installment_plan
      this.isShowInActive = true
      this.warningMessage = data.warning_message
      this.inActiveItems = await this.mergeItemsInstallment(
        data.installment_plan
      )
    },
    mergeItemsInstallment(items) {
      // const allInactiveSkus = items.flatMap((item) =>
      //   item.inactive_skus.map((inactiveSku) => ({ ...inactiveSku }))
      // )
      // return allInactiveSkus

      // const uniqueInactiveSkus = []
      // const skuCodesSet = new Set()

      // items.forEach((item) => {
      //   item.inactive_skus.forEach((inactiveSku) => {
      //     if (!skuCodesSet.has(inactiveSku.sku_code)) {
      //       skuCodesSet.add(inactiveSku.sku_code)
      //       uniqueInactiveSkus.push({ ...inactiveSku })
      //     }
      //   })
      // })
      // return uniqueInactiveSkus

      const allInactiveSkus = items
        .flatMap((item) =>
          item.inactive_skus.map((inactiveSku) => ({ ...inactiveSku }))
        )
        .reduce((acc, curr) => {
          const existingSku = acc.find((sku) => sku.sku_code === curr.sku_code)
          if (existingSku) {
            Object.assign(existingSku, curr)
          } else {
            acc.push(curr)
          }
          return acc
        }, [])

      return allInactiveSkus
    },
  },
  head: {
    script: [
      {
        src: process.env.NUXT_ENV_2C2P_JS_LIBRARY_URL,
        type: 'text/javascript',
      },
    ],
  },
}
