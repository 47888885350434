
import SwiperSlider from '@/components/share/swiper-item.vue'

export default {
  name: 'TabProductBrand',
  components: {
    // 'slider-items': SliderItemsComponent,
    'swiper-item': SwiperSlider,
  },
  props: {
    brands: { type: Array, default: () => [] },
    activeBrand: { type: String, default: '' },
    brandHomeImage: { type: Array, default: Array },
    items: { type: Array, default: Array },
    isloanding: { type: Boolean, default: false },
    bannerCreative: { type: String, default: '' },
  },
  data() {
    return {
      //items: [],
      isActivePrev: false,
      isActiveNext: false,
      swiperOption: {
        loop: false,
        spaceBetween: 0,
        breakpoints: {
          0: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 6,
          },
        },
        // on: {
        //   resize: () => {
        // this.$refs[
        //   'brand-tab'
        // ].$swiper.params.slidesPerView = this.responsive()
        // this.$refs['brand-tab'].$swiper.update()
        // },
        // },
      },
      slidesPerView: 0,
      count: 1,
      overlayImagesSwiper: [],
    }
  },
  computed: {
    //**Bam note: เปลี่ยนมาใช้ computed เพื่อหา object ของ Brand Image แทนการวน for
    //ของเดิมใช้วิธีการ วน for บน html แล้วใช้ v-if ดักให้โชว์เฉพาะ brand ที่กำลัง active ซึ่งไม่จำเป็นเพราะยังไงแต่ละ brand ที่ active ก็จะมีรูป banner แค่ 1 รูปเสมอ
    imgBrandBanner: function () {
      if (this.$utils.anyArrayObjectValid(this.brandHomeImage)) {
        let src = this.brandHomeImage.find((a) => a.id === this.activeBrand)
        return src !== undefined ? src : ''
      } else return ''
    },
    isItems() {
      return this.$utils.anyArrayObjectValid(this.items)
    },
  },
  watch: {
    brands: {
      handler: function (val) {
        if (val.length) {
          val.forEach((brand) => {
            this.$utils.onPreload(
              this.$utils.genImgSrcSet(
                this.$utils.imageTimeStamp(brand.src, brand.update_info, false),
                [{ w: 115, h: 0, q: 90, ws: '115w' }]
              ),
              '(max-width: 1024px) 115px'
            )
          })
        }
      },
      deep: true,
    },
    imgBrandBanner: {
      handler: function (val) {
        if (val) {
          this.$utils.onPreload(
            this.$utils.genImgSrcSet(
              this.$utils.imageTimeStamp(val?.src, val?.update_info, false),
              [
                { w: 228, h: 188, q: 90, ws: '228w' },
                { w: 328, h: 270, q: 90, ws: '328w' },
              ]
            ),
            '(max-width: 360px) 228px, (max-width: 412px) 328px, (max-width: 768px) 228px, (max-width: 1024px) 228px 328px'
          )
        }
      },
      deep: true,
    },
    items: {
      handler: async function (val) {
        // overlay promotion
        if (val.length) {
          let pid = val.map((item) => {
            return item.productId
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesSwiper = graphqlResponse
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.onSlide()
    console.log('brands', this.brands)
  },
  methods: {
    onSlide() {
      this.isActivePrev =
        this.$refs['brand-tab'].$swiper.activeIndex === 0 ? false : true
      this.isActiveNext =
        this.$refs['brand-tab'].$swiper.activeIndex ===
        this.brands.length -
          this.$refs['brand-tab'].$swiper.params.slidesPerView
          ? false
          : true
    },
    next() {
      this.$refs['brand-tab'].$swiper.slideNext()
    },
    prev() {
      this.$refs['brand-tab'].$swiper.slidePrev()
    },
    async onActiveBrand(brandLogo) {
      this.$emit('onActiveBrand', brandLogo)
    },
    // onClickBanner(banner) {
    //   if (banner !== undefined) {
    //     if (this.$utils.isValid(this.bannerCreative)) {
    //       //gtm promoClicked
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'promoClicked',
    //         ecommerce: {
    //           promoClick: {
    //             promotions: [
    //               {
    //                 id: banner.src,
    //                 name: `HP|${banner.gtmSeq}|${
    //                   banner.title
    //                 }|${this.$utils.formatDateGTM(
    //                   banner.active_from
    //                 )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //                 creative: this.bannerCreative,
    //                 position: banner.gtmSeq,
    //               },
    //             ],
    //           },
    //         },
    //       })

    //       // gtm GA4
    //       const promotion = {
    //         creative_name: banner.title,
    //         creative_slot: `${banner.title}_${banner.gtmSeq}`,
    //         promotion_id: banner.src,
    //         promotion_name: `HP|${banner.gtmSeq}|${
    //           banner.title
    //         }|${this.$utils.formatDateGTM(
    //           banner.active_from
    //         )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //       }
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'select_promotion',
    //         ecommerce: {
    //           user: this.$store.getters['user/userInfoTrack'],
    //           ...promotion,
    //         },
    //       })
    //       this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
    //     }
    //     // if (this.$utils.isValid(banner.url)) {
    //     //   this.$nextTick(() => {
    //     //     window.location.href = banner.url
    //     //   })
    //     // }
    //   }
    // },
    getImgUrl(pic) {
      return pic //+ '?x-image-process=image/format,webp/resize,m_fixed,h_353,w_1172'
    },

    // genImgSrcSet(pathName, options = [{ w: 150, h: 150, q: 90, ws: '150w' }]) {
    //   let srcsets = []
    //   if (options.length > 0) {
    //     for (let index = 0; index < options.length; index++) {
    //       const option = options[index]
    //       srcsets = [
    //         ...srcsets,
    //         `${pathName}${this.genXimage(option.w, option.h, option.q)} ${
    //           option.ws
    //         }`,
    //       ]
    //     }
    //     return srcsets.join(',')
    //   }
    //   return `${pathName}`
    // },
    // genXimage(w, h, q) {
    //   return `?x-image-process=image/format,webp/resize,m_fixed,w_${w},h_${h}/quality,q_${q}/marker,u_plus/sharpen,50`
    // },
  },
}
