
import { mapGetters } from 'vuex'
// mapActions
export default {
  name: 'Aside',
  data() {
    return {
      asideActive: false,
      isDisplayIcon: false,
      // getCartCount: 0,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'userInfo', 'profileType']),
    ...mapGetters('cart', ['cartCountPrinting']),
    isStandard() {
      return this.profileType === 'standard' ? true : false
    },
    isEprocurementUser() {
      if (this.profileType === 'eprocurement') {
        return this.userProfile.roles !== undefined &&
          this.userProfile.roles.filter(
            (a) => a.role_id === 1 || a.role_id === 2
          ).length > 0
          ? false
          : true
      }
      return false
    },
    isEprocurementAdmin() {
      if (this.profileType === 'eprocurement') {
        if (
          this.userProfile.roles !== undefined &&
          this.userProfile.roles.filter(
            (a) => a.role_id === 1 || a.role_id === 2
          ).length > 0
        ) {
          return true
        }
      }
      return false
    },
    isEprocurementStandard() {
      return this.userProfile.categories_type !== 'Standard' ? true : false
    },
    isUseBudgetControl() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.is_use_budget_control) {
          return true
        }
      }
      return false
    },
    // -นามบัตรในตะกร้า
    // ตรวจสอบจาก companies_master.is_use_product_printing
    // และ user_master.companies.roles.role_id=5

    // -รายการสั่งซื้อนามบัตร
    // ตรวจสอบจาก companies_master.is_use_product_printing
    isOrderPrinting() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing
      )
    },
    isUsePrintingMenu() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 3).length > 0 &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
    isUsePrintingMenuReport() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 1 || a.role_id === 2)
          .length > 0
      )
    },
    isAccountTypePersonal() {
      return (
        this.profileType === 'standard' && this.userProfile.type === 'Personal'
      )
    },
  },
  watch: {
    asideActive: {
      handler: function (val) {
        val
          ? document.getElementsByTagName('body')[0].classList.add('lock')
          : document.getElementsByTagName('body')[0].classList.remove('lock')
      },
      deep: true,
    },
  },
  mounted() {
    console.log('userProfile', this.userProfile)
    if (
      Object.keys(this.userProfile).length &&
      this.$utils.isValid(this.profileType)
    ) {
      if (this.profileType === 'standard') {
        if (!this.userProfile.is_activate)
          this.$router.push({
            path: this.$i18n.path(
              `account/new-register/check-tax?callback=account/information`
            ),
          })
      }
      console.log('profileType', this.profileType)
      console.log(
        'this.userProfile.categories_type ',
        this.userProfile.categories_type
      )
      if (
        this.profileType === 'eprocurement' &&
        this.userProfile.categories_type !== 'Standard'
      ) {
        this.onCheckProductControl()
      }
    }
    this.$nextTick(() => {
      document.getElementsByTagName('body')[0].classList.remove('lock')
      this.asideActive = false
    })
  },
  methods: {
    onReload() {
      this.$nextTick(() => {
        document.getElementsByTagName('body')[0].classList.remove('lock')
        this.asideActive = false
      })
    },
    async onCheckProductControl() {
      console.log('onCheckProductControl')

      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('company_id', this.userProfile.id)
      await this.$productProvider
        .headProductControl(params)
        .then((result) => {
          if (result.status === 200) {
            this.isDisplayIcon = true
          } else this.isDisplayIcon = false
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
  },
}
