
export default {
  props: {
    coupon: {
      type: Object,
      default: {},
    },
    getSelectedCoupon: {
      type: String,
      default: '',
    },
    isSwiper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCoupon: '',
      isShowFullText: false,
      copySuccessMessage: false,
      //   demoType: {
      //     type: 'platform',
      //     coupon_color: '#FD932F',
      //   },
    }
  },
  mounted() {
    // this.generateCSSClass(this.coupon)
  },
  methods: {
    async onCopyText(coupon_code) {
      try {
        await navigator.clipboard.writeText(coupon_code) // Copy text to clipboard
        this.copySuccessMessage = true
        setTimeout(() => {
          this.copySuccessMessage = false
        }, 3000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    onSetFullText() {
      this.isShowFullText = false
    },
    onMaxLength(text, length, isShow) {
      //  && isShow === false
      return text.length > length ? text.substring(0, length) + '...' : text
    },
    onTimeLeft(targetDate) {
      // const currentDate = new Date()
      const differenceInMillis = new Date(targetDate) - new Date()
      return Math.ceil(differenceInMillis / (1000 * 60 * 60))
    },
  },
}
