
import asideComponent from '@/components/template/aside-user.vue'
import OrderItems from '@/components/order/order-items.vue'
import ApproverDropdown from '@/components/checkout/approver-dropdown.vue'
import ListOrderProcess from '@/components/order/list-process.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import ApproveModal from '@/components/modal/confirmApproveOrder.vue'
import GoodsRecieveModal from '@/components/modal/confirmGoodsRecieve.vue'
import ModalWarning from '@/components/modal/modalWarning.vue'
import { mapGetters, mapActions } from 'vuex'
import { exportOrderDocument } from '@/plugins/export-pdf.js'
import {
  exportExcelOrderDocument,
  exportCSVOrderDocument,
} from '@/plugins/export-excel.js'
import ConfirmModal from '@/components/modal/confirmModal.vue'

export default {
  layout: 'main',
  name: 'OrderDetail',
  components: {
    'aside-component': asideComponent,
    'order-item': OrderItems,
    ApproverDropdown,
    ListOrderProcess,
    'summary-component': SummaryComponent,
    ApproveModal,
    ModalWarning,
    // ModalSuccessWarning,
    GoodsRecieveModal,
    ConfirmModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      modalTitle: '',
      isApproved: true,
      isRejected: false,
      isWaiting: false,
      showApproveModal: false,
      showGoodsRecieveModal: false,
      goodsReceiveEvent: {
        action: '',
        subOrderId: '',
      },
      input: {
        sku: '',
      },
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      orderTemp: {},
      productGroups: [],
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        period: {
          used_amt: 0,
          budget_amt: 0,
          remain_amt: 0,
          original_amt: 0,
        },
        sum_order_total_amt: 0,
        control_level: '',
        period_type: '',
        variant: 'primary',
      },
      items: [],
      approval: [],
      approverLevel: 1,
      currentLevel: 1,
      approverAction: '',
      activityLogs: [],
      warningMessages: [],
      warningMessagesInfo: [],
      modalWarning: false,
      removeItemFlag: false,
      isEditPONo: false,
      editPONo: '',
      isEditPOFile: false,
      uploadingPOFlag: false,
      isUploadPOFail: false,
      isUpdatePONoFail: false,
      isModalSuccessWarning: false,
      successWarningMessagesArr: [],
      creditcard: {
        isAlertCreditCardPromotionOnTop: false,
      },
      isOverYearBudget: false,
      isShowConfirmCancelOrder: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userInfo']),
    ...mapGetters('authen', ['openId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
          ? true
          : false
      else return false
    },
    isUseBudget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.account_channel === 'eprocurement'
      )
        return this.orderDocument.approval_information
          .companies_budget_control_id !== null
          ? true
          : false
      else return false
    },
    isAccountTypePersonal() {
      return this.profileType === 'standard' &&
        this.userProfile.type === 'Personal'
        ? true
        : false
    },
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    hasApprovePermission() {
      if (!Object.keys(this.orderDocument).length) {
        return false
      }
      if (this.orderDocument.status !== 'Waiting') {
        return false
      } else if (this.orderDocument.account_channel === 'eprocurement') {
        if (
          this.orderDocument.status === 'Waiting' &&
          this.orderDocument.approval_status === 'WaitingAdmin' &&
          this.isAdmin
        )
          return true
        else if (
          this.orderDocument.status === 'Waiting' &&
          this.orderDocument.approval_status === 'Waiting'
        ) {
          if (this.orderDocument.approval_information !== null) {
            let currentLevel =
              this.orderDocument.approval_information.current_level
            let approvalCurrentLevel =
              this.orderDocument.approval_information.approval.find(
                (q) => q.level === currentLevel
              )
            console.log('approvalCurrentLevel', approvalCurrentLevel)
            if (approvalCurrentLevel !== undefined) {
              let approvePermission = approvalCurrentLevel.approver.some(
                (q) =>
                  q.user_open_id === this.openId &&
                  q.approval_status !== 'Approve'
              )
              return approvePermission
            } else return false
          } else return false
        } else return false
      } else if (
        this.orderDocument.account_channel === 'standard' &&
        this.orderDocument.account_type === 'Corporate' &&
        this.orderDocument.approval_status === 'Waiting' &&
        this.orderDocument.approver_email === this.userInfo.email
      ) {
        return true
      } else {
        return false
      }
    },
    isAdminAllowApproveOverBuget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.isEprocuerment &&
        this.orderDocument.approval_information !== null
      ) {
        return this.orderDocument.approval_information
          .is_admin_allow_over_budget
      } else return false
    },
    isOverBudget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.isUseBudget &&
        this.orderDocument.status === 'Waiting' &&
        this.orderDocument.approval_status === 'Waiting'
      ) {
        if (this.isAdminAllowApproveOverBuget) return false
        else {
          let grandTotalAmt =
            this.mode === 'view'
              ? this.orderDocument.net_amt + this.orderDocument.vat_amt
              : this.orderTemp.net_amt + this.orderTemp.vat_amt

          return grandTotalAmt > this.budget.period.remain_amt ? true : false
        }
      } else return false
    },
    // isOverYearBudget() {
    //   if (
    //     Object.keys(this.orderDocument).length &&
    //     this.isUseBudget &&
    //     this.orderDocument.status === 'Waiting' &&
    //     this.orderDocument.approval_status === 'Waiting'
    //   ) {
    //     if (this.orderDocument.budget !== null) {
    //       return this.orderDocument.budget.isOverYearBudget
    //     } else
    //     {return false
    //     }
    //   } else return false
    // },
    isOverLastAppovalCreditLimit() {
      if (
        Object.keys(this.orderDocument).length &&
        this.isEprocuerment &&
        this.orderDocument.approval_information !== null
      ) {
        let lastIndex =
          this.orderDocument.approval_information.approval.length - 1
        let lastApprovalCreditLimit =
          this.orderDocument.approval_information.approval[lastIndex]
            .credit_limit
        let grandTotalAmt =
          this.mode === 'view'
            ? this.orderDocument.net_amt + this.orderDocument.vat_amt
            : this.orderTemp.net_amt + this.orderTemp.vat_amt
        return grandTotalAmt > lastApprovalCreditLimit ? true : false
      } else return false
    },
    hasEditPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting' &&
        this.orderDocument.payment_status !== 'Complete'
      ) {
        if (this.orderDocument.approval_status === 'WaitingAdmin') {
          return this.isAdmin ? true : false
        } else if (this.orderDocument.approval_status === 'Waiting') {
          return this.hasApprovePermission ? true : false
        } else if (
          this.orderDocument.approval_status === 'Reject' ||
          this.orderDocument.approval_status === 'AdminReject'
        ) {
          return this.openId === this.orderDocument.contact_id ? true : false
        } else return false
      } else return false
    },
    hasAddItemPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        if (
          this.orderDocument.approval_status === 'Reject' ||
          this.orderDocument.approval_status === 'AdminReject'
        ) {
          return this.openId === this.orderDocument.contact_id ? true : false
        } else return false
      } else return false
    },
    hasEditPOPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        if (this.orderDocument.approval_status === 'WaitingAdmin') {
          return this.isAdmin ? true : false
        } else if (this.orderDocument.approval_status === 'Waiting') {
          return this.hasApprovePermission ||
            this.openId === this.orderDocument.contact_id
            ? true
            : false
        } else {
          //case approval_status === none,reject,admin reject
          return this.openId === this.orderDocument.contact_id ? true : false
        }
      } else return false
    },
    canGRAllSubOrder() {
      if (Object.keys(this.orderDocument).length) {
        return this.orderDocument.sub_orders.every(
          (sub) =>
            sub.delivery_status === 'Shipping' ||
            sub.delivery_status === 'Delivered'
        )
      } else return false
    },
    mode() {
      return this.$route.query !== null &&
        this.$utils.isValid(this.$route.query.mode)
        ? this.$route.query.mode
        : 'view'
    },
    isReserveStock() {
      if (Object.keys(this.orderDocument).length) {
        return this.orderDocument.reserve_status === 'Active'
      } else return false
    },
    canRepayment() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting' &&
        this.orderDocument.payment_status === 'Waiting'
      ) {
        return !this.$utils.isExpireDate(this.orderDocument.payment_expire_date)
      } else return false
    },
    eproRePayment() {
      if (
        this.orderDocument.account_channel === 'eprocurement' &&
        this.profileType === 'eprocurement'
      ) {
        return this.orderDocument.is_payment
      } else {
        return true
      }
    },
    hasCancelPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        return this.orderDocument.payment_status !== 'Complete'
      } else return false
    },
    isOwnerOrder() {
      if (Object.keys(this.orderDocument).length) {
        return this.openId === this.orderDocument.contact_id
      } else return false
    },
    hasWrongConditionLimitMinMaxQty() {
      if (this.productGroups.length) {
        let orderTempItem = this.productGroups.flatMap(
          (subOrder) => subOrder.items
        )
        return orderTempItem.filter((q) => q.is_limit_qty).length ? true : false
      } else {
        return false
      }
    },
    isStatusExpire() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Expire'
      ) {
        return true
      }
    },
    isPaymentComplete() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        return this.orderDocument.payment_status === 'Complete' ? true : false
      } else return false
    },
  },
  watch: {
    orderDocument: {
      handler(val) {
        if (
          Object.keys(val).length &&
          this.isUseBudget &&
          val.status === 'Waiting' &&
          (val.approval_status === 'Waiting' ||
            val.approval_status === 'WaitingAdmin')
        ) {
          if (val.budget !== null) {
            this.isOverYearBudget = val.budget.is_over_year_budget
          }
        }
      },
    },
    isOverLastAppovalCreditLimit: {
      handler(val) {
        console.log('val', val)
        let index = this.warningMessagesInfo.findIndex(
          (q) => q === 'over_approval_budget'
        )
        if (index !== -1) this.warningMessagesInfo.splice(index)
        if (val) {
          this.warningMessagesInfo.push('over_approval_budget')
        }
      },
    },
    isOverBudget: {
      handler(val) {
        let index = this.warningMessagesInfo.findIndex(
          (q) => q === 'over_budget'
        )
        if (index !== -1) this.warningMessagesInfo.splice(index)
        if (val) {
          this.warningMessagesInfo.push('over_budget')
        }
      },
    },
    isOverYearBudget: {
      handler(val) {
        let index = this.warningMessagesInfo.findIndex(
          (q) => q === 'over_year_budget'
        )
        if (index !== -1) this.warningMessagesInfo.splice(index)
        if (val) {
          this.warningMessagesInfo.push('over_year_budget')
        }
      },
    },
  },
  async mounted() {
    if (this.$utils.isValid(this.$route.params.id)) {
      console.log('getxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
      await this.getOrderDocument()
      if (this.$route.query.mode === 'edit') {
        await this.getOrderTemporary(this.$route.query.tempId)
      }
    } else this.$router.push({ path: this.$i18n.path('') })
  },
  methods: {
    ...mapActions('cart', ['setCartCount']),
    getFile() {
      this.approve.fileName = ''
      document.getElementById('file').value = ''
      document.getElementById('file').click()
    },
    async importFile(e) {
      if (e.target.files.length) {
        await this.$refs['uploadFile']
          .validate(e.target.files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              await this.updatePODetail('updatePOFile', e.target.files[0])
            } else console.log('not valid')
          })
      }
    },
    async onUploadPOFile() {},
    async updatePODetail(action, file) {
      let formData = new FormData()
      formData.append('action', action)
      formData.append('user_id', this.openId)
      formData.append('user_name', `${this.userInfo.email}`)
      if (action === 'updatePONo') {
        formData.append('po_no', this.editPONo)
      } else if (action === 'updatePOFile') {
        this.uploadingPOFlag = true
        formData.append('action', 'updatePOFile')
        formData.append('po_upload_name', file.name)
        formData.append('file', file)
      }
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      }
      const result = await this.$orderProvider.updateOrderPODetail(
        this.orderDocument.id,
        formData,
        config
      )
      if (result.status === 204) {
        this.getOrderDocument()
        if (action === 'updatePONo') {
          this.isEditPONo = false
          this.isUpdatePONoFail = false
        } else {
          this.isEditPOFile = false
          this.uploadingPOFlag = false
          this.isUploadPOFail = false
        }
      } else {
        if (action === 'updatePOFile') {
          this.isUploadPOFail = true
          this.uploadingPOFlag = false
        }
        if (action === 'updatePONo') this.isUpdatePONoFail = true
      }
    },
    async validateAddCart() {
      this.$refs['input-sku'].validate().then(async (result) => {
        console.log('validate result', result)
        if (result.valid) await this.addCart()
      })
    },
    async addCart() {
      let promotion_items = this.productGroups.flatMap((subOrder) =>
        subOrder.promotion_discounts.flatMap((promo) => promo.items)
      )
      let checkItems = this.productGroups
        .flatMap((subOrder) => [...subOrder.items])
        .concat(promotion_items)
      let findItem = checkItems.find((q) => q.sku_code === this.input.sku)

      let apiData = JSON.stringify({
        sku_code: this.input.sku,
        quantity: 1,
        reserve_quantity:
          findItem !== undefined && this.isReserveStock
            ? findItem.reserve_quantity
            : 0,
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.orderDocument.account_channel,
        costcenterId: this.orderDocument.costcenter_id,
      })
      const result = await this.$shoppingCartProvider.createOrderTemporaryItem(
        this.$route.query.tempId,
        apiData
      )
      if (result.status === 201) {
        if (result.data.warning_messages.length) {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
        this.input.sku = ''
        this.$nextTick(() => this.$refs['input-sku'].reset())
        this.getOrderTemporary(this.$route.query.tempId)
      }
    },
    onApproverAction(action) {
      if (action === 'None') {
        this.approveOrder(action, '')
      } else {
        this.approverAction = action
        this.showApproveModal = true
      }
    },
    displayApproveBadge(status, approval) {
      return status === approval.level_status ? true : false
    },
    async getOrderDocument() {
      await this.$orderProvider
        .getOrderDocument(
          this.$route.params.id,
          this.openId,
          this.userProfile.id,
          this.isAdmin
        )
        .then((result) => {
          if (result.status === 200) {
            this.orderDocument = result.data
            console.log('ek', result.data)
            this.getOrderActivityLogs()
            if (this.mode === 'view') {
              this.summary = {
                net_amt: this.orderDocument.summary.net_amt,
                net_deliveryfee_excvat:
                  this.orderDocument.summary.net_deliveryfee_excvat,
                nonvat_prod_net_amt:
                  this.orderDocument.summary.nonvat_prod_net_amt,
                vat_amt: this.orderDocument.summary.vat_amt,
                vat_prod_net_amt: this.orderDocument.summary.vat_prod_net_amt,
                voucher_disc_amt_excvat:
                  this.orderDocument.summary.voucher_disc_amt_excvat,
                voucher_no: this.orderDocument?.voucher_no,
                other_disc_amt_incvat:
                  this.orderDocument.summary.other_disc_amt_incvat,
                other_disc_amt_excvat:
                  this.orderDocument.summary.other_disc_amt_excvat,
                payment_disc_amt_excvat:
                  this.orderDocument.summary.payment_disc_amt_excvat,
                payment_disc_code: this.orderDocument.summary.payment_disc_code,
                is_use_coupon: this.orderDocument.is_use_coupon,
                used_voucher_type: this.orderDocument?.voucher_type, //used_voucher_type
                promo_disc_amt_incvat:
                  this.orderDocument.summary?.promo_disc_amt_incvat,
                promo_disc_amt_excvat:
                  this.orderDocument.summary?.promo_disc_amt_excvat,

                // dcc
                net_discount_delivery_fee_exc_vat:
                  this.orderDocument.summary.net_discount_delivery_fee_exc_vat,
                total_delivery_fee_exc_vat:
                  this.orderDocument.summary.total_delivery_fee_exc_vat,
                segment_discount_delivery_fee_exc_vat:
                  this.orderDocument.summary
                    .segment_discount_delivery_fee_exc_vat,
                other_discount_delivery_fee_exc_vat:
                  this.orderDocument.summary
                    .other_discount_delivery_fee_exc_vat,
                account_segment: this.orderDocument.account_segment,
                // multi coupon
                seller_voucher_disc_amt_excvat:
                  this.orderDocument.summary?.seller_voucher_disc_amt_excvat,
                seller_voucher_disc_amt_incvat:
                  this.orderDocument.summary?.seller_voucher_disc_amt_incvat,
                seller_voucher_no:
                  this.orderDocument.summary?.seller_voucher_no,
                seller_voucher_type:
                  this.orderDocument.summary?.seller_voucher_type,
                delivery_fee_voucher_no:
                  this.orderDocument?.delivery_fee_voucher_no,
                is_use_coupon_delivery:
                  this.orderDocument.is_use_coupon_delivery,
                voucher_discount_delivery_fee_exc_vat:
                  this.orderDocument?.summary
                    ?.voucher_discount_delivery_fee_exc_vat,
                warning_messages: this.orderDocument?.warning_messages,
              }
              // this.summary = {
              //   net_amt: this.orderDocument.net_amt,
              //   net_deliveryfee_excvat:
              //     this.orderDocument.net_deliveryfee_excvat,
              //   nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
              //   vat_amt: this.orderDocument.vat_amt,
              //   vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
              //   voucher_disc_amt_excvat:
              //     this.orderDocument.voucher_disc_amt_excvat,
              //   voucher_no: this.orderDocument.voucher_no,
              //   other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
              //   other_disc_amt: this.orderDocument.other_disc_amt_excvat,
              //   payment_disc_amt_excvat:
              //     this.orderDocument.payment_disc_amt_excvat,
              //   payment_disc_code: this.orderDocument.payment_disc_code,
              //   is_use_coupon: this.orderDocument.is_use_coupon,
              //   used_voucher_type: this.orderDocument.used_voucher_type,
              //   promo_disc_amt_incvat: this.orderDocument.promo_disc_amt_incvat,
              //   promo_disc_amt_excvat: this.orderDocument.promo_disc_amt_excvat,
              // }
              console.log('summary', this.summary)
              // reset
              this.creditcard.isAlertCreditCardPromotionOnTop = false
            }
            if (this.isEprocuerment) {
              // this.budget = this.orderDocument.budget
              if (this.orderDocument.status === 'Waiting' && this.isUseBudget)
                this.getOrderBudget(this.orderDocument)
              this.approval = this.orderDocument.approval_information.approval
              this.approverLevel =
                this.orderDocument.approval_information.current_level
              this.currentLevel =
                this.orderDocument.approval_information.current_level
            }

            if (this.mode === 'view') {
              this.productGroups = []
              this.orderDocument.sub_orders.forEach((subOrder) => {
                this.productGroups.push({
                  account_segment: this.orderDocument?.account_segment,
                  soldBy: subOrder?.vendor?.sold_by?.[this.$i18n.locale],
                  FulfilledBy:
                    subOrder?.vendor?.fulfilled_by?.[this.$i18n.locale],
                  // soldBy:
                  //   subOrder?.vendor.vendor_type === 'OFMStock' ||
                  //   subOrder?.vendor.vendor_type.includes('DropShip')
                  //     ? 'OfficeMate'
                  //     : `${
                  //         this.$i18n.locale === 'th'
                  //           ? subOrder?.vendor.vendor_name_th
                  //           : subOrder?.vendor.vendor_name_en
                  //       }`,
                  // FulfilledBy:
                  //   subOrder?.vendor.vendor_type === 'OFMStock'
                  //     ? 'OfficeMate'
                  //     : `${
                  //         this.$i18n.locale === 'th'
                  //           ? subOrder?.vendor.vendor_name_th
                  //           : subOrder?.vendor.vendor_name_en
                  //       }`,
                  items: subOrder.items,
                  delivery_lead_time: subOrder?.vendor.delivery_lead_time_end,
                  showTrackingTimeline: [
                    'InProgress',
                    'ReadyToShip',
                    'Shipping',
                    'Delivered',
                    'Complete',
                  ].includes(subOrder.delivery_status),
                  canGR:
                    subOrder.delivery_status === 'Shipping' ||
                    subOrder.delivery_status === 'Delivered'
                      ? true
                      : false,
                  subOrderId: subOrder.sub_order_id,
                  deliveryStatus: subOrder.delivery_status,
                  trackingNo: subOrder.items[0].delivery_tracking_no,
                  vendorType: subOrder?.vendor.vendor_type,
                  premiums: [],
                  // shipping
                  shippingOption: [
                    {
                      type: 'OFFICEMATE SHIPPING',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 0,
                    },
                    {
                      type: 'BANGKOK SAMEDAY',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 80,
                    },
                    {
                      type: 'EXPRESS DELEVERY',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 200,
                    },
                  ],
                  // dcc
                  delivery_lead_time_start:
                    subOrder.vendor?.delivery_lead_time_start,
                  delivery_lead_time_end:
                    subOrder.vendor?.delivery_lead_time_end,
                  delivery_area_type: subOrder.vendor.delivery_area_type,
                  summary: {
                    // is_use_coupon: subOrder.is_use_coupon,
                    // used_voucher_type: subOrder.used_voucher_type,
                    promo_disc_amt_excvat:
                      subOrder.summary.promo_disc_amt_excvat,
                    voucher_disc_amt_excvat:
                      subOrder.summary.voucher_disc_amt_excvat,

                    nonvat_prod_net_amt: subOrder.summary.nonvat_prod_net_amt,
                    vat_prod_net_amt: subOrder.summary.vat_prod_net_amt,
                    total_delivery_fee_exc_vat:
                      subOrder.summary.total_delivery_fee_exc_vat,
                    net_discount_delivery_fee_exc_vat:
                      subOrder.summary.net_discount_delivery_fee_exc_vat,
                    vat_amt: subOrder.summary.vat_amt,
                    net_amt: subOrder.summary.net_amt,

                    // modal summary
                    minimum_order_delivery_fee_exc_vat:
                      subOrder.summary.minimum_order_delivery_fee_exc_vat,
                    weight_order_delivery_fee_exc_vat:
                      subOrder.summary.weight_order_delivery_fee_exc_vat,
                    // total_delivery_fee_exc_vat:
                    //   subOrder.total_delivery_fee_exc_vat,
                    voucher_discount_delivery_fee_exc_vat:
                      subOrder.summary.voucher_discount_delivery_fee_exc_vat,
                    segment_discount_delivery_fee_exc_vat:
                      subOrder.summary.segment_discount_delivery_fee_exc_vat,
                    other_discount_delivery_fee_exc_vat:
                      subOrder.summary.other_discount_delivery_fee_exc_vat,
                    net_deliveryfee_excvat:
                      subOrder.summary.net_deliveryfee_excvat,
                    other_disc_amt_incvat:
                      subOrder.summary.other_disc_amt_incvat,
                    other_disc_amt_excvat:
                      subOrder.summary.other_disc_amt_excvat,
                    // multi coupon
                    seller_voucher_disc_amt_excvat:
                      subOrder.summary?.seller_voucher_disc_amt_excvat,
                    seller_voucher_disc_amt_incvat:
                      subOrder.summary?.seller_voucher_disc_amt_incvat,
                    seller_voucher_no: subOrder.summary?.seller_voucher_no,
                    seller_voucher_type: subOrder.summary?.seller_voucher_type,
                    delivery_fee_voucher_no: subOrder?.delivery_fee_voucher_no,
                    is_use_coupon_delivery: subOrder?.is_use_coupon_delivery,
                    voucher_discount_delivery_fee_exc_vat:
                      subOrder?.summary?.voucher_discount_delivery_fee_exc_vat,
                  },
                  // warning_messages
                  warning_messages: subOrder.warning_messages,
                })
              })
              this.productGroups.map((group) => {
                group.premiums = group.items.filter((q) => q.is_free)
                group.items = group.items.filter((q) => !q.is_free)
                group.items.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
                if (group.premiums.length) {
                  group.premiums.map((item) => {
                    item.display_price_exclude_vat = item.price_disc_excvat
                    item.product_name = {
                      th: item.product_name_th,
                      en: item.product_name_en,
                    }
                  })
                }
              })
            }
          }
        })
    },
    async getOrderTemporary(tempId) {
      const result = await this.$shoppingCartProvider.getOrderTemporary(
        tempId,
        'edit',
        false
      )
      console.log('result', result)
      if (result.status === 200) {
        console.log('jame', result.data)
        this.orderTemp = result.data
        // this.summary = {
        //   net_amt: this.orderTemp.net_amt,
        //   net_deliveryfee_excvat: this.orderTemp.net_deliveryfee_excvat,
        //   nonvat_prod_net_amt: this.orderTemp.nonvat_prod_net_amt,
        //   vat_amt: this.orderTemp.vat_amt,
        //   vat_prod_net_amt: this.orderTemp.vat_prod_net_amt,
        //   voucher_disc_amt_excvat: this.orderTemp.voucher_disc_amt_excvat,
        //   voucher_no: this.orderTemp.voucher_no,
        //   other_disc_amt_incvat: this.orderTemp.other_disc_amt_incvat,
        //   other_disc_amt: this.orderTemp.other_disc_amt_excvat,
        //   payment_disc_amt_excvat: this.orderTemp.payment_disc_amt_excvat,
        //   payment_disc_code: this.orderTemp.payment_disc_code,
        //   is_use_coupon: this.orderTemp.is_use_coupon,
        //   used_voucher_type: this.orderTemp.used_voucher_type,
        //   promo_disc_amt_incvat: this.orderTemp.promo_disc_amt_incvat,
        //   promo_disc_amt_excvat: this.orderTemp.promo_disc_amt_excvat,
        // }
        this.summary = {
          net_amt: this.orderTemp.summary.net_amt,
          net_deliveryfee_excvat: this.orderTemp.summary.net_deliveryfee_excvat,
          nonvat_prod_net_amt: this.orderTemp.summary.nonvat_prod_net_amt,
          vat_amt: this.orderTemp.summary.vat_amt,
          vat_prod_net_amt: this.orderTemp.summary.vat_prod_net_amt,
          voucher_disc_amt_excvat:
            this.orderTemp.summary.voucher_disc_amt_excvat,
          voucher_no: this.orderTemp?.voucher_no,
          other_disc_amt_incvat: this.orderTemp.summary.other_disc_amt_incvat,
          other_disc_amt_excvat: this.orderTemp.summary.other_disc_amt_excvat,
          payment_disc_amt_excvat:
            this.orderTemp.summary.payment_disc_amt_excvat,
          payment_disc_code: this.orderTemp.summary.payment_disc_code,
          is_use_coupon: this.orderTemp.is_use_coupon,
          used_voucher_type: this.orderTemp?.voucher_type, // used_voucher_type
          promo_disc_amt_incvat: this.orderTemp.summary?.promo_disc_amt_incvat,
          promo_disc_amt_excvat: this.orderTemp.summary?.promo_disc_amt_excvat,

          // dcc
          net_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.net_discount_delivery_fee_exc_vat,
          total_delivery_fee_exc_vat:
            this.orderTemp.summary.total_delivery_fee_exc_vat,
          segment_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.segment_discount_delivery_fee_exc_vat,
          other_discount_delivery_fee_exc_vat:
            this.orderTemp.summary.other_discount_delivery_fee_exc_vat,
          account_segment: this.orderTemp.account_segment,
          // multi coupon
          seller_voucher_disc_amt_excvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_excvat,
          seller_voucher_disc_amt_incvat:
            this.orderTemp.summary?.seller_voucher_disc_amt_incvat,
          seller_voucher_no: this.orderTemp.summary?.seller_voucher_no,
          seller_voucher_type: this.orderTemp.summary?.seller_voucher_type,
          delivery_fee_voucher_no: this.orderTemp?.delivery_fee_voucher_no,
          is_use_coupon_delivery: this.orderTemp?.is_use_coupon_delivery,
          voucher_discount_delivery_fee_exc_vat:
            this.orderTemp?.summary?.voucher_discount_delivery_fee_exc_vat,
          warning_messages: this.orderTemp?.warning_messages,
        }
        console.log(
          'this.orderTemp.payment_disc_code',
          this.orderTemp.payment_disc_code
        )
        console.log(
          'this.orderDocument.payment_disc_code',
          this.orderDocument.payment_disc_code
        )
        // Alert Credit card promotion on top
        this.creditcard.isAlertCreditCardPromotionOnTop =
          this.orderTemp.payment_disc_code !==
          this.orderDocument.payment_disc_code

        console.log('summary', this.summary)
        this.productGroups = []
        if (
          this.orderTemp.sub_orders !== null &&
          this.orderTemp.sub_orders.length
        ) {
          // this.removeItemFlag =
          //   this.orderTemp.sub_orders.length > 1 ? true : false

          this.orderTemp.sub_orders.forEach((subOrder) => {
            // console.log(
            //   subOrder?.promotion_discounts[0]?.items[0].delivery_tracking_no
            // )
            this.productGroups.push({
              account_segment: this.orderTemp?.account_segment,
              soldBy: subOrder?.vendor?.sold_by?.[this.$i18n.locale],
              FulfilledBy: subOrder?.vendor?.fulfilled_by?.[this.$i18n.locale],
              // soldBy:
              //   subOrder?.vendor.vendor_type === 'OFMStock' ||
              //   subOrder?.vendor.vendor_type.includes('DropShip')
              //     ? 'OfficeMate'
              //     : `${
              //         this.$i18n.locale === 'th'
              //           ? subOrder.vendor_name_th
              //           : subOrder.vendor_name_en
              //       }`,
              // FulfilledBy:
              //   subOrder?.vendor.vendor_type === 'OFMStock'
              //     ? 'OfficeMate'
              //     : `${
              //         this.$i18n.locale === 'th'
              //           ? subOrder?.vendor.vendor_name_th
              //           : subOrder?.vendor.vendor_name_en
              //       }`,
              items: [...subOrder.items],
              premiums: subOrder.premium_items,
              promotion_discounts: subOrder.promotion_discounts,
              delivery_lead_time: subOrder?.vendor.delivery_lead_time_end,
              showTrackingTimeline: [
                'InProgress',
                'ReadyToShip',
                'Shipping',
                'Delivered',
                'Complete',
              ].includes(subOrder.delivery_status),
              canGR:
                subOrder.delivery_status === 'Shipping' ||
                subOrder.delivery_status === 'Delivered'
                  ? true
                  : false,
              subOrderId: subOrder.sub_order_id,
              deliveryStatus: subOrder.delivery_status,
              trackingNo:
                subOrder?.items[0]?.delivery_tracking_no ||
                subOrder?.promotion_discounts[0]?.items[0].delivery_tracking_no,
              vendorCode: subOrder?.vendor?.code,
              vendorType: subOrder?.vendor.vendor_type,
              // premiums: [],
              // shipping
              shippingOption: [
                {
                  type: 'OFFICEMATE SHIPPING',
                  description:
                    'Delivery to your preferred address in 2-5 working days',
                  cost: 0,
                },
                {
                  type: 'BANGKOK SAMEDAY',
                  description:
                    'Delivery to your preferred address in 2-5 working days',
                  cost: 80,
                },
                {
                  type: 'EXPRESS DELEVERY',
                  description:
                    'Delivery to your preferred address in 2-5 working days',
                  cost: 200,
                },
              ],
              // dcc
              delivery_lead_time_start:
                subOrder.vendor?.delivery_lead_time_start,
              delivery_lead_time_end: subOrder.vendor?.delivery_lead_time_end,
              delivery_area_type: subOrder.vendor.delivery_area_type,
              summary: {
                // is_use_coupon: subOrder.is_use_coupon,
                // used_voucher_type: subOrder.used_voucher_type,
                promo_disc_amt_excvat: subOrder.summary.promo_disc_amt_excvat,
                voucher_disc_amt_excvat:
                  subOrder.summary.voucher_disc_amt_excvat,

                nonvat_prod_net_amt: subOrder.summary.nonvat_prod_net_amt,
                vat_prod_net_amt: subOrder.summary.vat_prod_net_amt,
                total_delivery_fee_exc_vat:
                  subOrder.summary.total_delivery_fee_exc_vat,
                net_discount_delivery_fee_exc_vat:
                  subOrder.summary.net_discount_delivery_fee_exc_vat,
                vat_amt: subOrder.summary.vat_amt,
                net_amt: subOrder.summary.net_amt,

                // modal summary
                minimum_order_delivery_fee_exc_vat:
                  subOrder.summary.minimum_order_delivery_fee_exc_vat,
                weight_order_delivery_fee_exc_vat:
                  subOrder.summary.weight_order_delivery_fee_exc_vat,
                // total_delivery_fee_exc_vat:
                //   subOrder.total_delivery_fee_exc_vat,
                voucher_discount_delivery_fee_exc_vat:
                  subOrder.summary.voucher_discount_delivery_fee_exc_vat,
                segment_discount_delivery_fee_exc_vat:
                  subOrder.summary.segment_discount_delivery_fee_exc_vat,
                other_discount_delivery_fee_exc_vat:
                  subOrder.summary.other_discount_delivery_fee_exc_vat,
                net_deliveryfee_excvat: subOrder.summary.net_deliveryfee_excvat,
                other_disc_amt_incvat: subOrder.summary.other_disc_amt_incvat,
                other_disc_amt_excvat: subOrder.summary.other_disc_amt_excvat,
                // multi coupon
                seller_voucher_disc_amt_excvat:
                  subOrder.summary?.seller_voucher_disc_amt_excvat,
                seller_voucher_disc_amt_incvat:
                  subOrder.summary?.seller_voucher_disc_amt_incvat,
                seller_voucher_no: subOrder.summary?.seller_voucher_no,
                seller_voucher_type: subOrder.summary?.seller_voucher_type,
                delivery_fee_voucher_no: subOrder?.delivery_fee_voucher_no,
                is_use_coupon_delivery: subOrder?.is_use_coupon_delivery,
                voucher_discount_delivery_fee_exc_vat:
                  subOrder?.summary?.voucher_discount_delivery_fee_exc_vat,
              },
              // multi coupon
              seller_voucher_no: subOrder.seller_voucher_no,
              seller_voucher_type: subOrder.seller_voucher_type,
              // warning_messages
              warning_messages: subOrder.warning_messages,
            })
          })

          // let groupVendorType = this.orderTemp.items.reduce((h, value) => {
          //   return Object.assign(h, {
          //     [value.vendor_type]: (h[value.vendor_type] || []).concat(value),
          //   })
          // }, {})
          // console.log('groupVendorType', groupVendorType)
          // let groupProduct = {}
          // Object.keys(groupVendorType).forEach((groupKey) => {
          //   if (groupKey === 'OFMStock')
          //     groupProduct[groupKey] = groupVendorType[groupKey]
          //   else {
          //     //DropShip,Marketplace
          //     let subGroup = groupVendorType[groupKey].reduce((h, value) => {
          //       return Object.assign(h, {
          //         [value.vendor_code]: (h[value.vendor_code] || []).concat(
          //           value
          //         ),
          //       })
          //     }, {})
          //     console.log(`${groupKey}`, subGroup)
          //     Object.keys(subGroup).forEach((subgroupKey) => {
          //       return (groupProduct[`${groupKey}_${subgroupKey}`] =
          //         subGroup[subgroupKey])
          //     })
          //   }
          // })
          // console.log('groupProduct', groupProduct)

          // Object.keys(groupProduct).forEach((groupKey) => {
          //   return this.productGroups.push({
          //     soldBy:
          //       groupKey === 'OFMStock' || groupKey.includes('DropShip')
          //         ? 'OfficeMate'
          //         : groupProduct[groupKey][0].vendor_name[this.$i18n.locale],
          //     FulfilledBy:
          //       groupKey === 'OFMStock'
          //         ? 'OfficeMate'
          //         : groupProduct[groupKey][0].vendor_name[this.$i18n.locale],
          //     items: groupProduct[groupKey],
          //     premiums: [],
          //     // shipping
          //     shippingOption: [
          //       {
          //         type: 'OFFICEMATE SHIPPING',
          //         description:
          //           'Delivery to your preferred address in 2-5 working days',
          //         cost: 0,
          //       },
          //       {
          //         type: 'BANGKOK SAMEDAY',
          //         description:
          //           'Delivery to your preferred address in 2-5 working days',
          //         cost: 80,
          //       },
          //       {
          //         type: 'EXPRESS DELEVERY',
          //         description:
          //           'Delivery to your preferred address in 2-5 working days',
          //         cost: 200,
          //       },
          //     ],
          //   })
          // })
          this.productGroups.map((group) => {
            console.log('group', group)
            // group.premiums = group.items.filter((q) => q.is_free)
            // group.items = group.items.filter((q) => !q.is_free)
            // if (group.items.length) {
            //   const maxLeadTime = group.items.reduce((prev, current) => {
            //     return prev.delivery_lead_time > current.delivery_lead_time
            //       ? prev
            //       : current
            //   })
            //   group.delivery_lead_time = this.$utils.formatDateExcludeTime(
            //     maxLeadTime.delivery_lead_time
            //   )
            // }
            group.items.map((item) => {
              item.product_name = item.product_names.find(
                (q) => q.type === 'Online'
              ).display_name
            })
            if (group.premiums.length) {
              group.premiums.map((item) => {
                item.product_name = item.product_names.find(
                  (q) => q.type === 'Online'
                ).display_name
              })
            }
          })

          let promotion_items = this.productGroups.flatMap((subOrder) =>
            subOrder.promotion_discounts.flatMap((promo) => promo.items)
          )
          let checkItems = this.productGroups
            .flatMap((subOrder) => [...subOrder.items])
            .concat(promotion_items)

          this.removeItemFlag = checkItems.length > 1
          console.log('checkItems', checkItems)
        }
      } else {
        this.$router.push({
          path: this.$i18n.path('account/order'),
          name: 'account-order-id',
          params: { id: this.$route.params.id },
          query: { mode: 'view' },
        })
        this.getOrderDocument()
      }
    },
    async getOrderActivityLogs() {
      await this.$orderProvider
        .getOrderActivityLogs(this.$route.params.id)
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    async getOrderBudget(order) {
      const result = await this.$orderProvider.getOrderBudget(
        order.approval_information.companies_budget_control_id,
        order.company_id,
        false
      )
      if (result.status === 200) {
        this.budget = {
          id: result.data.id,
          period: {
            used_amt:
              result.data.period !== null ? result.data.period.used_amt : 0,
            budget_amt:
              result.data.period !== null ? result.data.period.budget_amt : 0,
            remain_amt:
              result.data.period !== null ? result.data.period.remain_amt : 0,
            original_amt:
              result.data.period !== null ? result.data.period.original_amt : 0,
          },
          sum_order_total_amt: result.data.sum_order_total_amt,
          control_level: result.data.control_level,
          period_type: result.data.period_type,
          variant: 'primary',
        }
      }
    },
    async approveOrder(action, remark) {
      let apiData = JSON.stringify({
        documents: [
          {
            order_object_id: this.orderDocument.id,
            order_id: this.orderDocument.order_id,
            action: action,
          },
        ],
        user_id: this.openId,
        remark: remark,
      })
      const result = await this.$orderProvider.approveOrders(apiData)
      console.log(result)
      if (result.status === 200) {
        // gtm GA4
        for (const event of ['approver', 'epro_purchase']) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: event,
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              value: this.summary.net_amt + this.summary.vat_amt,
              coupon: this.orderDocument.voucher_no,
              order: {
                customerName: this.orderDocument.contact_name_th,
                Telephone:
                  this.orderDocument.contact_phone_extension !== ''
                    ? `${this.orderDocument.contact_phone}#${this.orderDocument.contact_phone_extension}`
                    : this.orderDocument.contact_phone,
                Mobile: this.orderDocument.contact_mobile,
                OrgName: `[${this.orderDocument.company_code}] ${this.orderDocument.company_name_th}`,
                Department: this.orderDocument.department_code
                  ? `[${this.orderDocument.department_code}] ${this.orderDocument.department_name_th}`
                  : '-',
                ShippingAddress: `${this.orderDocument.ship_contact} ${this.orderDocument.ship_address_1} ${this.orderDocument.ship_address_2} ${this.orderDocument.ship_address_3} ${this.orderDocument.ship_address_4} เบอร์โทร ${this.orderDocument.ship_mobile} ${this.orderDocument.ship_phone}`,
                BillingAddress: `${this.orderDocument.invoice_address_1} ${this.orderDocument.invoice_address_2} ${this.orderDocument.invoice_address_3} ${this.orderDocument.invoice_address_4} เลขประจำตัวผู้เสียภาษี : ${this.orderDocument.account_tax_id}`,
                PaymentMethod: this.orderDocument.payment_name_th,
                TransactionID: this.orderDocument.order_id,
                TransactionDate: this.orderDocument.order_date,
                ApproveStatus: this.$t(
                  this.$utils.displayOrderStatus(this.orderDocument)[0]
                ),
              },
            },
          })
        }

        if (!result.data.warning_messages.length) {
          if (
            this.$utils.anyArrayObjectValid(
              result.data.success_warning_messages
            )
          ) {
            this.successWarningMessagesArr = []
            this.successWarningMessagesArr =
              result.data.success_warning_messages
            this.isModalSuccessWarning = true
          } else {
            await this.getOrderDocument()
            await this.getOrderActivityLogs()
          }
        } else {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
      }
    },
    async onCloseModalSuccessWarning() {
      this.successWarningMessagesArr = []
      this.isModalSuccessWarning = false
      await this.getOrderDocument()
      await this.getOrderActivityLogs()
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      console.log(this.approverLevel)
    },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    onConfirmApprove(payload) {
      console.log(payload)
      this.approveOrder(payload.action, payload.remark)
    },
    onClickGoodsRecieve(action = 'GRAll', subOrderId = '') {
      this.showGoodsRecieveModal = true
      this.goodsReceiveEvent = {
        action: action,
        subOrderId: subOrderId,
      }
    },
    async onConfirmGoodsRecieve(payload) {
      let apiData = JSON.stringify({
        user_id: this.openId,
        user_name: `${this.userInfo.first_name.th} ${this.userInfo.last_name.th}`,
        email: `${this.userInfo.email}`,
        document_log_remark: payload.remark,
      })
      let result = null
      if (this.goodsReceiveEvent.action === 'GRAll') {
        result = await this.$orderProvider.updateOrderGoodsRecieve(
          this.$route.params.id,
          apiData
        )
      } else {
        result = await this.$orderProvider.updateOrderGoodsRecieveBySubOrder(
          this.$route.params.id,
          this.goodsReceiveEvent.subOrderId,
          apiData
        )
      }
      console.log(result)
      if (result !== null) {
        if (result.status === 204) {
          this.getOrderDocument()
          this.getOrderActivityLogs()
          this.createOrderApproveGoodsRecieve()
        } else if (result.status === 412) {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
        this.goodsReceiveEvent = { action: '', subOrderId: '' }
      }
    },
    async createOrderApproveGoodsRecieve() {
      try {
        console.warn('orderDocument :>> ', this.orderDocument)
        if (this.goodsReceiveEvent.action === 'GRAll') {
          if (
            this.orderDocument.status === 'Delivered' ||
            this.orderDocument.status === 'Shipping'
          ) {
            const res = await this.$orderProvider.createApproveGoodsRecieveAll({
              order_id: this.$route.params.id,
              delivery_status: this.orderDocument.status,
            })
          }
        } else {
          if (
            this.orderDocument?.sub_orders.some(
              (sub) =>
                sub.delivery_status === 'Shipping' ||
                sub.delivery_status === 'Delivered'
            )
          ) {
            const res =
              await this.$orderProvider.createApproveGoodsRecieveBySubOrder({
                order_id: this.$route.params.id,
                sub_order_id: this.goodsReceiveEvent.subOrderId,
                delivery_status: this.orderDocument?.sub_orders.find(
                  (a) => a.sub_order_id === this.goodsReceiveEvent.subOrderId
                )?.delivery_status,
              })
          }
        }
      } catch (error) {
        console.log('createOrderApproveGoodsRecieve :>> ', error)
      }
    },
    orderDocumentStatus() {
      switch (this.orderDocument.status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
    async onEditDocument(orderObjectId) {
      const result =
        await this.$shoppingCartProvider.createOrderTemporaryFromDocument(
          orderObjectId
        )
      console.log('result', result)
      if (result.status === 201) {
        if (result.data.warning_messages.length) {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        } else {
          this.$router.push({
            path: this.$i18n.path('account/order'),
            name: 'account-order-id',
            params: { id: this.$route.params.id },
            query: { mode: 'edit', tempId: result.data.temp_id },
          })
          // this.orderTemp.promotion_discounts = []
          this.getOrderTemporary(result.data.temp_id)
        }
      }
    },
    async onCancelEditOrder() {
      this.$router.push({
        path: this.$i18n.path('account/order'),
        name: 'account-order-id',
        params: { id: this.$route.params.id },
        query: { mode: 'view' },
      })
      this.orderTemp.promotion_discounts = []
      await this.getOrderDocument()
    },
    async onChangeQty(payload) {
      console.log('onChangeQty', payload)
      let apiData = JSON.stringify({
        sku_code: payload.sku_code,
        quantity: payload.quantity,
        reserve_quantity: this.isReserveStock ? payload.reserve_quantity : 0,
      })
      const result = await this.$shoppingCartProvider.updateOrderTemporaryQty(
        this.$route.query.tempId,
        apiData
      )
      this.getOrderTemporary(this.$route.query.tempId)
    },
    async onRemoveItem(payload) {
      const result = await this.$shoppingCartProvider.removeOrderTemporaryItem(
        this.$route.query.tempId,
        payload.sku_code
      )
      if (result.status === 200) {
        this.getOrderTemporary(this.$route.query.tempId)
      }
    },
    async onSaveEditOrder() {
      let apiData = JSON.stringify({
        temp_id: this.$route.query.tempId,
        user_id: this.openId,
        user_name: `${this.userInfo.first_name.th} ${this.userInfo.last_name.th}`,
        email: `${this.userInfo.email}`,
        document_log_remark: '',
      })
      const result = await this.$orderProvider.updateOrderDocument(
        this.$route.params.id,
        apiData
      )
      if (result.status === 200) {
        if (result.data.warning_messages.length) {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        } else {
          this.$router.push({
            path: this.$i18n.path('account/order'),
            name: 'account-order-id',
            params: { id: this.$route.params.id },
            query: { mode: 'view' },
          })
          this.orderTemp.promotion_discounts = []
          this.getOrderDocument()
        }
      } else {
        this.warningMessages = []
        this.warningMessages = JSON.parse(result.data.message).warning_messages
        this.modalWarning = true
      }
    },
    onBackToOrderHistory() {
      this.$router.push({
        path: this.$i18n.path('order/history'),
        name:
          this.$i18n.locale === 'th' ? 'order-history' : 'lang-order-history',
        query: { tab: 'waiting' },
      })
    },
    onClickRepayment() {
      if (this.orderDocument.payment_type === '2C2P Credit Card Online') {
        this.$router.push({
          path: this.$i18n.path('payment/re-payment'),
          name:
            this.$i18n.locale === 'th'
              ? 'payment-re-payment-id'
              : 'lang-payment-re-payment-id',
          params: { id: this.$route.params.id },
        })
      } else if (this.orderDocument.payment_type === '2C2P 1-2-3 Payment') {
        this.$router.push({
          path: this.$i18n.path('payment/123payment'),
          name:
            this.$i18n.locale === 'th'
              ? 'payment-123payment-id'
              : 'lang-payment-123payment-id',
          params: { id: this.$route.params.id },
          query: {
            paymentCode: this.orderDocument.payment_ref_no,
            callback: 'account/order',
          },
        })
      }
    },
    async cancelOrder() {
      let apiData = JSON.stringify({
        user_id: this.openId,
        user_name: `${this.userInfo.first_name.th} ${this.userInfo.last_name.th}`,
        email: `${this.userInfo.email}`,
        document_log_remark: '',
      })
      const result = await this.$orderProvider.updateOrderDocumentStatus(
        this.$route.params.id,
        'Cancel',
        apiData
      )
      if (result.status === 204) this.getOrderDocument()
    },
    async exportPDF(type, language) {
      await this.$orderProvider
        .getOrderDocument(
          this.$route.params.id,
          this.openId,
          this.userProfile.id,
          this.isAdmin,
          type === 'purchase' ? true : false
        )
        .then((result) => {
          if (result.status === 200) {
            if (
              result.data !== null &&
              result.data !== undefined &&
              Object.keys(result.data).length
            )
              exportOrderDocument(result.data, language, type)
          }
        })
    },
    async exportExcel(language) {
      exportExcelOrderDocument(this.orderDocument, language)
    },
    async exportCSV(language) {
      exportCSVOrderDocument(this.orderDocument, language)
    },
    async rePurchase() {
      if (Object.keys(this.orderDocument).length) {
        let listItems = []
        this.orderDocument.sub_orders.forEach((subOrder) => {
          subOrder.items.forEach((item) => {
            if (!item.is_free)
              listItems.push({
                sku: item.sku_code,
                qty: item.quantity,
              })
          })
        })
        let parseData = this.userProfile
        let apiData = JSON.stringify({
          user_open_id: this.openId,
          profile_id: parseData.id,
          account_channel: this.profileType,
          skus: listItems,
        })
        // console.log('apiData', apiData)
        const result = await this.$shoppingCartProvider.addCart(apiData)
        if (result.status === 201) {
          // console.log('result', result.data)
          if (result.data.success_items.length) {
            this.$nuxt.$emit('addToCart')
          }
          if (result.data.warning_messages.length) {
            await this.getCartCount()
            this.warningMessages = []
            this.warningMessages = result.data.warning_messages
            this.modalWarning = true
          } else {
            if (
              result.data.warning_messages.length &&
              result.data.warning_messages.filter(
                (item) => item.key === 'is_sku_limit'
              ).length
            ) {
              const sku_limit = result.data.warning_messages.filter(
                (item) => item.key === 'is_sku_limit'
              )
              this.$nuxt.$emit('addToCart', {
                message: {
                  title: sku_limit[0].message_title,
                  description: sku_limit[0].message_description,
                },
                isWarning: true,
              })
            }
            await this.getCartCount()
            this.$nextTick(() => {
              this.$router.push(this.$i18n.path('cart'))
            })
          }
        }
      }
    },
    async getCartCount() {
      const cartResponsed = await this.$shoppingCartProvider.getCartCount(
        this.openId,
        this.userProfile.id
      )
      if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
    },
    onShowConfirmCancelOrderModal() {
      this.isShowConfirmCancelOrder = true
    },
    onConfirmCancelOrder() {
      this.cancelOrder()
    },
    onCancelToCancelOrder() {
      this.isShowConfirmCancelOrder = false
    },
    async onAddItemsAddon(items) {
      let count = 0
      for (const e of items) {
        await new Promise(async (resolve) => {
          await this.addCartAddon(e)
          count++
          resolve()
        })
      }
      if (count === items.length)
        this.getOrderTemporary(this.$route.query.tempId)
    },
    async addCartAddon(e) {
      let promotion_items = this.productGroups.flatMap((subOrder) =>
        subOrder.promotion_discounts.flatMap((promo) => promo.items)
      )
      let checkItems = this.productGroups
        .flatMap((subOrder) => [...subOrder.items])
        .concat(promotion_items)
      let findItem = checkItems.find((q) => q.sku_code === e)

      let apiData = JSON.stringify({
        sku_code: e,
        quantity: 1,
        reserve_quantity:
          findItem !== undefined && this.isReserveStock
            ? findItem.reserve_quantity
            : 0,
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.orderDocument.account_channel,
        costcenterId: this.orderDocument.costcenter_id,
      })
      const result = await this.$shoppingCartProvider.createOrderTemporaryItem(
        this.$route.query.tempId,
        apiData
      )
      if (result.status === 201) {
        if (result.data.warning_messages.length) {
          this.warningMessages = []
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
      }
    },
  },
}
