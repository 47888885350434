
import { mapActions, mapGetters, mapState } from 'vuex'
import InputQty from '@/components/share/input-qty.vue'
import ProductNotifyModal from '@/components/modal/productNotify.vue'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'ItemComponent',
  components: {
    'input-qty': InputQty,
    ProductNotifyModal,
  },
  props: {
    item: { type: Object, default: () => {} },
    view: { type: String, default: 'grid' },
    listName: { type: String, default: '' },
    isShowNormalPrice: { type: Boolean, default: true },
    positionOnPage: { type: Number, default: 0 },
    queryIdEventAlgolia: { type: String, default: '' },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    showFlashSale: { type: Boolean, default: false },
    isAddon: { type: Boolean, default: false },
    showOthers: { type: Boolean, default: true },
    gtmItemListId: { type: String, default: '' },
    gtmItemListName: { type: String, default: '' },
    overlayImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      notifySku: '',
      notifyModal: false,
      flip: false,
      compare: [],
      qty: 1,
      maxQty: 999,
      skuName: '',
      skuSEOName: '',
      salePrice: 0,
      promotionPrice: 0,
      data: {},
      lang: '',
      sku: {
        retail_price: {
          include_vat: 0.0,
        },
      },
      skuMechanics: [],
      selectedMaterial: {},
      skuMechanicKeys: [],
      clickMaterial: {},
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      body: {
        id: null,
        user_open_id: null,
        email: null,
        name: null,
        sku_code: null,
        qty: 0,
      },
      notifyProductName: {},
      productStatusNotReady: ['not_ready', 'cancel', 'sold_out'],
      isAlgolia: {
        addCart: false,
      },
      imgOverlay: '',
      categories: [],
    }
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
    ]),
    isMyGuest() {
      return this.isAuthenticated && this.isGuest
    },
    isSku() {
      return this.$utils.isObjectValid(this.sku)
    },
    imageNormalShowcase() {
      let timeStamp = this.$utils.isObjectKeyValid(
        this.sku.update_info,
        'timestamp'
      )
        ? /*? '?v=' + this.$utils.getDateString(this.item.update_info.timestamp)
        : ''*/
          ''
        : ''
      if (
        this.$utils.isObjectValid(this.sku) &&
        this.$utils.anyArrayObjectValid(this.sku.images)
      ) {
        return (
          this.sku.images.some((q) => q.size === 'large')
            ? this.sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'large').url + timeStamp
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    imageSmallShowcase() {
      if (
        this.$utils.isObjectValid(this.sku) &&
        this.$utils.anyArrayObjectValid(this.sku.images)
      ) {
        return this.sku.images.some((q) => q.size === 'sp')
          ? this.sku.images.find((q) => q.size === 'sp').url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
    isAddCart() {
      return ['ready_to_ship', 'lead_time', 'preorder'].includes(
        this.sku.display_status
      )
    },
    isOnlineOnly() {
      return this.sku.is_online_only || this.sku.is_flash_sale
    },
    isDiscountRate() {
      return (
        this.$utils.isObjectValid(this.sku) &&
        this.$utils.anyArrayObjectValid(this.sku.promotion_prices) &&
        this.$utils.isObjectValid(this.sku.display_discount_rate) &&
        this.sku.display_discount_rate > 0 &&
        (this.sku.is_promotion ||
          this.sku.is_online_only ||
          this.sku.is_flash_sale)
        // this.sku.is_promotion
      )
    },
    hasSkuMechanics() {
      return (
        this.$utils.isObjectValid(this.item) &&
        this.$utils.anyArrayObjectValid(this.item.sku_mechanics) &&
        this.item.sku_mechanics.some(
          (q) =>
            this.$utils.anyArrayObjectValid(q.values) && q.values.length > 1
        ) &&
        this.$utils.anyArrayObjectValid(this.item.skus)
      )
    },
    isOthersSetting() {
      return (
        this.showOthers &&
        this.$utils.isObjectValid(this.data) &&
        this.$utils.anyArrayObjectValid(this.data.sku_mechanics) &&
        this.data.sku_mechanics.some(
          (q) =>
            this.$utils.anyArrayObjectValid(q.values) && q.values.length > 1
        ) &&
        this.$utils.anyArrayObjectValid(this.data.skus)
      )
    },
    isDiscount() {
      return (
        this.sku.is_promotion ||
        this.sku.is_fixprice ||
        this.sku.is_online_only ||
        this.sku.is_flash_sale
      )
    },
    isOverlay() {
      if (this.overlayImages.length && this.sku.sku_code) {
        const selectedProduct = this.overlayImages.filter(
          (product) => product.sku_code === this.sku.sku_code
        )
        console.log('overlayImages', selectedProduct[0]?.overlay_web_image)
        return selectedProduct[0]
      }

      // return this.$utils.isObjectValid(this.sku.overlay_web_image)
      //   ? this.sku.overlay_web_image.some((e) => e.size === 'thumbnail')
      //   : false
    },
    isFlashSale() {
      return this.sku.is_flash_sale
    },
    isFlashSaleStock() {
      return !this.sku.is_outofstock_flash_sale
    },
    isHotFlashSale() {
      return this.sku.is_hot_flash_sale
    },
    isPromotionDiscount() {
      return this.sku.is_promotion_discount
    },
    isInstallmentBySku() {
      return this.sku?.is_installment_sku
    },
  },
  watch: {
    // view: function (val) {
    //   if (val !== 'grid') this.flip = false
    // },
    item: {
      handler: function () {
        this.data = this.item

        if (this.$utils.anyArrayObjectValid(this.data.skus)) {
          this.skuMechanics = this.data.sku_mechanics
          // console.log('this.selectedMaterial', this.selectedMaterial, this.data)
          if (
            this.$utils.anyArrayObjectValid(this.skuMechanics) &&
            this.$utils.anyArrayObjectValid(this.selectedMaterial) &&
            this.skuMechanics.length !==
              Object.keys(this.selectedMaterial).length
          ) {
            this.randomSku(this.data.skus)
          }
        }
      },
      deep: true,
    },
    clickMaterial: {
      handler: function (obj) {
        if (!this.disableMaterial(obj)) this.changeSku(obj)
      },
      deep: true,
    },
    // queryIdEventAlgolia(val) {
    //   console.log('queryIdEventAlgolia', val)
    //   console.log('queryIdEventAlgolia', this.queryIdEventAlgolia)
    // },
    // typeEventAlgolia(val) {
    //   console.log('typeEventAlgolia', val)
    //   console.log('typeEventAlgolia', this.typeEventAlgolia)
    // },
  },
  created() {
    // this.$nuxt.$on('productOverlay', (data) => {
    //   const imgOverlay = data.find((q) => q.id === this.data.skus[0]?.sku_code)
    //   if (imgOverlay) {
    //     this.imgOverlay = imgOverlay.img
    //   }
    // })
  },
  mounted() {
    this.lang = this.$store.state.locale
    this.data = this.item
    if (this.$utils.anyArrayObjectValid(this.data.skus)) {
      this.skuMechanics = this.data.sku_mechanics
      this.randomSku(this.data.skus)
      // @load
      this.$utils.onPreload(
        this.$utils.genImgSrcSet(
          this.$utils.imageTimeStamp(
            this.imageNormalShowcase,
            this.sku?.update_info?.timestamp,
            true
          ),
          [
            { w: 115, h: 115, q: 90, ws: '115w' },
            { w: 150, h: 150, q: 90, ws: '150w' },
          ]
        ),
        '(max-width: 360px) 115px,(max-width: 412px) 115px,(max-width: 1024px) 150px'
      )
      /* Create Object for check sku mechanic */
      this.data.skus.forEach((element) => {
        let values = []
        element.mechanics.forEach((mechanic) => {
          let mechanicSku =
            this.data.sku_mechanics !== null
              ? this.data.sku_mechanics.find((q) => q.id.includes(mechanic.id))
              : undefined
          if (mechanicSku !== undefined && mechanicSku !== null) {
            let id = mechanicSku.id
            let type = mechanicSku.type.toLowerCase()
            let key = this.mechanicKeyValue(type, mechanic.value)
            values.push({ id, key, type })
            if (element.sku_code === this.sku.sku_code)
              this.selectedMaterial[id] = key
          }
          // // console.log('m', m)
          // var mechanicValue = this.mechanicKeyValue(type, mechanic.value)
          // if (mechanicValue.id !== '000000000000000000000000') {
          //   key = m.id
          //   type = 'database'
          // } else {
          //   key = this.bsonDocument(
          //     this.bsonDocument(m.value, 'display_name'),
          //     this.lang
          //   )
          //   type = 'freetext'
          // }
          // console.log('fff', m.id)
          // // let mechanicId = this.data.sku_mechanics.find((q) =>
          // //   q.values.map((q) => this.mechanicKeyValue(type, q)).includes(key)
          // // )
          // values.push({ id: m.id, key: mechanic.id, type })
          // // values.push({ id: mechanic.id, key, type })
        })

        this.skuMechanicKeys.push({
          sku_code: element.sku_code,
          isOutOfStock: this.productStatusNotReady.includes(
            element.display_status
          ),
          values,
        })
      })
    }
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    ...mapActions('cart', ['addCart', 'setCartCount']),
    async onSubmitProductNotify(data) {
      console.log('onSubmitProductNotify', data)
      let apiData = JSON.stringify(data)
      // console.log('data', apiData)
      const result = await this.$productNotifyMeProvider.sendNotifyMe(apiData)
      console.log('onSubmitProductNotify : ' + result)
      if (result.status === 201) {
        console.log('onSubmitProductNotify success')
      }
    },
    colorCode(obj) {
      return obj.type.toLowerCase() === 'color_code'
        ? `padding: 12px; background-color: ${obj.value}`
        : ''
    },
    classMaterial(obj) {
      let _class = ''
      if (
        this.$utils.isValid(obj.valueKey) &&
        this.selectedMaterial[obj.id] === obj.valueKey
      )
        _class += ' active'
      if (this.disableMaterial(obj)) _class += ' btn-line-disable'
      return _class
    },
    disableMaterial(obj) {
      let _keys = Object.keys(this.selectedMaterial)
      let _values = Object.values(this.selectedMaterial)

      // let _isMyMechanic = _keys.length < 2 && _keys.some((q) => q === obj.id)
      let _objFilters = []
      if (this.$utils.anyArrayObjectValid(_values)) {
        _objFilters = _objFilters.filter((q) =>
          q.values.map((q) => q.key).some((s) => _values.includes(s))
        )
      }
      _objFilters = this.skuMechanicKeys.filter(
        (q) =>
          q.values.map((q) => q.key).includes(obj.valueKey) && !q.isOutOfStock
      )
      // console.log('_isMyMechanic', _isMyMechanic)
      return _objFilters.length === 0 // && !_isMyMechanic
    },
    mechanicKeyValue(type, values) {
      if (type === undefined) return ''
      return type.toLowerCase() === 'database'
        ? this.bsonDocument(values, '_id')
        : this.bsonDocument(
            this.bsonDocument(values, 'display_name'),
            this.lang
          )
    },
    onRoute() {
      console.log('route', this.listName)
      if (this.$utils.isValid(this.listName)) {
        //gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'productClicked',
          currencyCode: 'THB',
          ecommerce: {
            click: {
              actionField: { list: this.listName },
              products: [
                {
                  id: this.sku.sku_code,
                  price: this.$utils.formatPrice(
                    this.sku.price_include_vat,
                    2,
                    true
                  ),
                  position: this.item.seq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const item_list_id =
          (this.gtmItemListId && this.gtmItemListId) ||
          (this.sku.is_flash_sale && 'flash_sale') ||
          (this.sku.is_promotion && 'promotions') ||
          ''
        const item_list_name =
          (this.gtmItemListName && this.gtmItemListName) ||
          (this.sku.is_flash_sale && 'flash sale') ||
          (this.sku.is_promotion && 'Promotions') ||
          ''

        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_item',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            item_list_id,
            item_list_name,
            items: [
              {
                item_list_id,
                item_list_name,
                item_id: this.sku.sku_code,
                item_name: this.sku.product_names[1]?.display_name.th,
                item_brand: this.item.brand?.display_name.en,
                price: this.sku.price_include_vat,
                discount:
                  this.sku.retail_price.include_vat -
                  this.sku.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }

      console.log(
        'queryIdEventAlgolia',
        this.queryIdEventAlgolia,
        this.triggerEventAlgolia
      )
      if (this.$utils.isValid(this.triggerEventAlgolia)) {
        this.$eventInsightsAlgolia.sendEvent(
          this.triggerEventAlgolia,
          this.isMyGuest ? this.guestId : this.openId,
          this.queryIdEventAlgolia,
          [this.sku.product_id],
          [this.positionOnPage],
          this.indexEventAlgolia
        )
      }

      // this.$nextTick(() => {
      //   this.$router.push({
      //     path: this.$i18n.path(
      //       `product/${this.skuSEOName}-p.${this.sku.sku_code}`
      //     ),
      //     query: { queryIdEventAlgolia: this.queryIdEventAlgolia },
      //   })
      //   // this.$router.push({
      //   //   path: this.$i18n.path(
      //   //     'product/' +
      //   //       this.skuName[this.lang].split(/[.\-_/#]/).join('') +
      //   //       '-p.' +
      //   //       this.sku.sku_code
      //   //   ),
      //   // })
      // })
    },
    randomSku(skus) {
      // **Bam Note logic ไม่ถูก ต้อง top 1 ไม่ใช่ random
      // const randomeNumber = Math.floor(Math.random() * skus.length)
      // const selectSku = skus[randomeNumber]
      // this.sku = selectSku
      // this.sku = skus[0]

      let firstSkuIndex = skus.findIndex((item) => {
        const condition =
          item.onhand - item.reserve > 0 &&
          ['ready_to_ship', 'lead_time', 'preorder'].includes(
            item.display_status
          )
        return condition
      })
      firstSkuIndex = firstSkuIndex !== -1 ? firstSkuIndex : 0

      this.sku = skus[firstSkuIndex]
      this.qty = this.sku.minimum_quantity <= 0 ? 1 : this.sku.minimum_quantity
      this.changeSkuName()
    },
    changeSkuName() {
      // this.skuMechanics.forEach((mechanic) => {
      //   console.log('mechanic.value', mechanic.value)
      //   var _id = this.bsonDocument(mechanic.value, '_id')
      //   if (_id !== '')
      //     this.selectedMaterial[mechanic.id] = _id
      // })
      if (this.$utils.isObjectValid(this.sku)) {
        let productName = this.sku.product_names.find(
          (q) => q.type.toLowerCase() === 'online'
        )
        this.skuName = productName.display_name
        this.skuSEOName = this.sku.meta.seo_name[this.lang]
      }
      // this.sku.product_names.forEach((element) => {
      //   if (element.type.toLowerCase() === 'online') {
      //     this.skuName = element.display_name
      //   }
      // })
    },
    changeSku(obj) {
      if (
        this.selectedMaterial[obj.id] !== undefined &&
        this.selectedMaterial[obj.id] === obj.valueKey
      ) {
        delete this.selectedMaterial[obj.id]
        return
      } else this.selectedMaterial[obj.id] = obj.valueKey

      if (
        this.skuMechanics !== null &&
        this.selectedMaterial !== null &&
        this.skuMechanics.length === Object.keys(this.selectedMaterial).length
      ) {
        let selectSku = this.findSKUByMechanics()
        if (selectSku !== undefined) {
          this.sku = {
            retail_price: {
              include_vat: 0.0,
            },
          }
          this.sku = selectSku
          this.qty =
            this.sku.minimum_quantity <= 0 ? 1 : this.sku.minimum_quantity
          this.changeSkuName()
        }
      }
    },
    onChangeQty(qty) {
      let max = Number(this.sku.maximum_quantity > 0)
        ? Number(this.sku.maximum_quantity)
        : this.maxQty

      // console.log('item', qty, max, Number(qty), max < Number(qty))
      if (max < Number(qty)) this.qty = String(max)

      // this.qty =
      //   this.sku.minimum_quantity <= qty ? qty : this.sku.minimum_quantity
    },
    async onAddToCart() {
      if (this.isMyGuest) return this.gotoLogin()

      console.log('add to cart', this.qty)
      let parseData = this.userProfile
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: parseData.id,
        account_channel: this.profileType,
        skus: [
          {
            sku: this.sku.sku_code,
            qty: this.qty,
          },
        ],
      })
      const result = await this.$shoppingCartProvider.addCart(apiData)
      if (result.status === 201) {
        // gtm
        if (result.data.success_items.length) {
          this.$nuxt.$emit('addToCart')
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'addToCart',
            ecommerce: {
              add: {
                products: result.data.success_items.length
                  ? result.data.success_items.map((item) => {
                      let catLv1 = ''
                      let catLv2 = ''
                      let catLv3 = ''
                      let catIdLv1 = ''
                      let catIdLv2 = ''
                      let catIdLv3 = ''
                      if (this.$utils.isObjectKeyValid(item, 'categories')) {
                        if (item.categories.find((key) => key.level === 1)) {
                          catLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).display_name['en']
                          catIdLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 2)) {
                          catLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).display_name['en']
                          catIdLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 3)) {
                          catLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).display_name['en']
                          catIdLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).id
                        }
                      }
                      return {
                        id: item.sku_code,
                        price: this.$utils.formatPrice(
                          item.price_include_vat,
                          2,
                          true
                        ),
                        categoryLv1: catLv1,
                        categoryLv2: catLv2,
                        categoryLv3: catLv3,
                        categoryIdLv1: catIdLv1,
                        categoryIdLv2: catIdLv2,
                        categoryIdLv3: catIdLv3,
                        quantity: item.qty,
                      }
                    })
                  : [],
              },
            },
          })

          // gtm GA4
          const item_list_id =
            (this.gtmItemListId && this.gtmItemListId) ||
            (this.sku.is_flash_sale && 'flash_sale') ||
            (this.sku.is_promotion && 'promotions') ||
            ''
          const item_list_name =
            (this.gtmItemListName && this.gtmItemListName) ||
            (this.sku.is_flash_sale && 'flash sale') ||
            (this.sku.is_promotion && 'Promotions') ||
            ''

          const categories = result.data.success_items[0].categories
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'add_to_cart',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              item_list_id,
              item_list_name,
              items: [
                {
                  item_list_id,
                  item_list_name,
                  item_id: this.sku.sku_code,
                  item_name: this.sku.product_names[1]?.display_name.th,
                  item_brand: this.item.brand?.display_name.en,
                  ...this.$utils.productCategories(categories),
                  quantity: this.qty,
                  price: this.sku.price_include_vat,
                  discount:
                    this.sku.retail_price.include_vat -
                    this.sku.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                },
              ],
            },
          })

          if (
            this.$utils.isValid(this.triggerEventAlgolia) &&
            !this.isAlgolia.addCart
          ) {
            this.$eventInsightsAlgolia.sendEvent(
              this.triggerEventAlgolia ===
                TriggerInsightsAlgoliaEnum.SEARCHVIEWPDP
                ? TriggerInsightsAlgoliaEnum.SEARCHPLPADDCART
                : TriggerInsightsAlgoliaEnum.NONSEARCHPLPADDCART,
              this.isMyGuest ? this.guestId : this.openId,
              this.queryIdEventAlgolia,
              [this.sku.product_id],
              [this.positionOnPage],
              this.indexEventAlgolia
            )
            this.isAlgolia.addCart = true
          }
        }
        if (
          result.data.warning_messages.length &&
          result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          ).length
        ) {
          const sku_limit = result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          )
          this.$nuxt.$emit('addToCart', {
            message: {
              title: sku_limit[0].message_title,
              description: sku_limit[0].message_description,
            },
            isWarning: true,
          })
        }
        const cartResponsed = await this.$shoppingCartProvider.getCartCount(
          this.cartId,
          parseData.id
        )
        if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
      }
    },
    isShowMechanic(values) {
      // let images = this.bsonDocument(values, 'images')
      let image = this.bsonDocument(values, 'image')
      let color_code = this.bsonDocument(values, 'color_code')
      let displayName = this.bsonDocument(
        this.bsonDocument(values, 'display_name'),
        this.lang
      )
      // if (images !== undefined && images.length > 0) {
      //   let url = this.bsonDocument(
      //     images.find((q) =>
      //       q.some((s) => s.name === 'size' && s.value === 'sp')
      //     ),
      //     'url'
      //   )
      //   return { type: 'image', value: url }
      // }
      if (image !== undefined && image !== '')
        return { type: 'image', value: this.bsonDocument(image, 'url') }
      else if (color_code !== undefined && color_code !== '')
        return { type: 'color_code', value: color_code }
      return { type: 'name', value: displayName }
    },
    findSKUByMechanics() {
      let _this = this
      let _values = Object.values(this.selectedMaterial)
      let sku = undefined
      this.skuMechanicKeys.forEach((element) => {
        let ids = element.values.map((e) => e.key)
        let compareLength = this.getArraysIntersection(_values, ids).length

        if (
          _values !== null &&
          ids !== null &&
          _values.length === ids.length &&
          _values.length === compareLength
        ) {
          sku = _this.data.skus.find((q) => q.sku_code === element.sku_code)
          // console.log('sku', sku)
        }
      })

      return sku
    },
    bsonDocument(objArray, key) {
      if (objArray == null || objArray.length == 0) return ''

      for (const element of objArray) {
        if (element.name === key) return element.value
      }
    },
    // Compare two items
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      )
    },
    getArraysIntersection(a1, a2) {
      return a1.filter(function (n) {
        return a2.indexOf(n) !== -1
      })
    },
    onNotify() {
      if (this.isMyGuest) return this.gotoLogin()

      // console.log(this.sku.sku_code)
      this.notifyModal = true
      this.notifySku = this.sku.sku_code
      let productName = this.sku.product_names.find(
        (q) => q.type.toLowerCase() === 'online'
      )
      this.notifyProductName = productName.display_name
      console.log('notifyProductName L2: ' + this.notifyProductName)
      // this.sku.product_names.forEach((element) => {
      //   if (element.type.toLowerCase() === 'online') {
      //     this.notifyProductName = element.display_name
      //   }
      // })
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },

    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
    addOnSelected(sku) {
      if (this.isAddon) {
        this.$emit('addOnSelected', sku)
      }
    },
  },
}
