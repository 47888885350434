
export default {
  name: 'ConfirmGoodsRecieve',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      remark: '',
    }
  },
  computed: {},
  watch: {
    show: function (value) {
      if (value) {
        this.$refs.confirmGoodsRecieve.show()
        this.remark = ''
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onClose() {
      this.$refs.confirmGoodsRecieve.hide()
      this.$emit('hidden')
    },
    async onSubmit() {
      // this.$refs.approveForm.validate().then((result) => {
      //   if (result) this.confirm()
      // })
      this.$emit('confirm', {
        remark: this.remark,
      })
      this.$refs.confirmGoodsRecieve.hide()
    },
  },
}
