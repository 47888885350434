
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'Dashboard',
  components: {
    'aside-component': asideComponent,
    TotalStackedBar: async () => await import('@/components/dashboard/total-1'),
    OrderStackedBar: async () => await import('@/components/dashboard/order-2'),
    RatioStackedBar: async () => await import('@/components/dashboard/ratio-3'),
    Budget: async () => await import('@/components/dashboard/budget-4'),
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      companies: {
        company_code: '',
        name: {
          th: '',
          en: '',
        },
        company_logo_url: '',
        discount_rate: '',
        company_level: '',
        price_type: '',
        categories_type: '',
        password_period: '',
        default_expiration_period: '',
        is_use_budget_control: false,
        is_site_active: false,
        is_show_banner_promotion: false,
        accounts: {
          id: '',
          is_default: true,
        },
        document_setting_desc: '',
        control_level_desc: '',
        period_type_desc: '',
        categories_type_desc: '',
        url: '',
      },
      costcenters: [],
      costcenter_all: [],
      costcenterOptions: [],
      is_use_budget_control: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {
    slide: function (value) {
      console.log(value)
    },
  },

  mounted() {
    this.getCompaniesById()
    this.getCostCenterCode()
    this.is_use_budget_control = this.userProfile.is_use_budget_control
  },
  methods: {
    prev() {
      this.$refs.news.prev()
    },
    next() {
      this.$refs.news.next()
    },
    filterChart() {
      this.chartData = {
        labels: ['รอการพิจารณาอนุมัติ', 'รอการแก้ไข', 'อนุมัติเบื้องต้น'],
        datasets: [
          {
            backgroundColor: ['#ff495d', '#d2b2ed', '#0171cd'],
            data: [40, 20, 40],
          },
        ],
      }
    },
    async getCompaniesById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              this.companies = result.data
            }
          },
          (error) => {
            console.log('getCompaniesById', error.responsed)
          }
        )
    },
    async getCostCenterCode() {
      this.costcenterOptions = []
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      await this.$reportProvider.getCostCenterCodeById(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (Array.isArray(data)) {
              data.forEach((a) => {
                this.costcenterOptions.push({
                  name: '[' + a.code + '] ' + a.name[this.$i18n.locale],
                  value: a.id,
                })
              })
            }
          }
        },
        (error) => {
          console.log('getCostCenterCode', error.responsed)
        }
      )
    },
  },
}
