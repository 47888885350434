
import asideComponent from '@/components/template/aside-user.vue'
// import Multiselect from 'vue-multiselect'
// import PrintingSummary from '@/components/printing/summary.vue'
import PrintingModal from '@/components/printing/modal-preview'
import ConfirmModal from '@/components/modal/confirmModal.vue'
import notfound from '@/components/share/notfound.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: 'main',
  name: 'PrintingCart',
  components: {
    'aside-component': asideComponent,
    // Multiselect,
    // PrintingSummary,
    PrintingModal,
    ConfirmModal,
    notfound,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'cart',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isSkeleton: true,
      isShowCreateModal: false,
      key: '',
      items: [],
      account: {},
      orderDetail: {
        default_shipping: { remark: '' },
        delivery_remark: '',
        remark_to_approver: '',
      },
      departmentId: '',
      isShowDetail: false,
      isShowShippingDetail: false,
      popoverDelivery: false,
      tempPhones: [
        {
          type: 'Phone',
          value: '',
          extension: '',
        },
        {
          type: 'Mobile',
          value: '',
          extension: '',
        },
      ],
      selectedShipping: {
        text: '',
        value: '',
      },
      shippingList: [],
      uploadingPOFlag: false,
      isUploadPOFail: false,
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
      },
      showErrCoupon: false,
      is_use_coupon: false,
      cart: {
        voucherNo: '',
      },
      costcenterList: [],
      selectedCostcenter: {
        // value: '507f191e810c19729de860ea',
        // text: '[CAD] CAD',
      },
      companyControlType: '',
      budget: {},
      requesterLineId: '',
      requesterLineList: [],
      approvals: [],
      approverLevel: '1',
      approverLevelOptions: [],
      selectedApprover: {},
      checkoutWarningMessages: [],
      isNotPurchaser: false,
      isover_goodsreceive_period: false,
      openCat: false,
      hasBudgetValue: false,
      optionsQty: [
        { text: 100, value: 1 },
        { text: 200, value: 2 },
        { text: 300, value: 3 },
        { text: 400, value: 4 },
        { text: 500, value: 5 },
        { text: 600, value: 6 },
        { text: 700, value: 7 },
        { text: 800, value: 8 },
        { text: 900, value: 9 },
      ],
      // approve: {
      //   remark: '',
      //   officemate: '',
      //   fileName: '',
      //   sentEmail: '',
      // },
      modalPreviewData: {},
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('cart', ['cartCountPrinting']),
    isUsePrintingMenu() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 3).length > 0 &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
    overBudgetInfo() {
      if (
        this.profileType !== undefined &&
        this.profileType === 'eprocurement'
      ) {
        if (this.is_allow_create_order_over_budget === false) return false
        return this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
          ? true
          : false
      } else return false
    },
    // hasWrongConditionLimitMinMaxQty() {
    //   if (this.items.length) {
    //     return this.items.filter((q) => q.is_selected && q.is_limit_qty).length
    //       ? true
    //       : false
    //   } else return false
    // },
    // hasWrongConditionOverStock() {
    //   if (this.items.length) {
    //     return this.items.filter((q) => q.is_selected && q.is_over_stock).length
    //       ? true
    //       : false
    //   } else return false
    // },
    hasCheckoutItem() {
      // if (this.items.length) {
      //   return this.items.filter((q) => q.is_selected && !q.is_wrong_condition)
      //     .length
      //     ? true
      //     : false
      // } else return false
      return true
    },
    displayMobile() {
      if (Object.keys(this.account).length && this.account.phones.length) {
        let mobileObj = this.account.phones.find((q) => q.type === 'Mobile')
        return mobileObj !== undefined ? mobileObj.value : '-'
      } else return '-'
    },
    displayPhone() {
      if (Object.keys(this.account).length && this.account.phones.length) {
        let phoneObj = this.account.phones.find((q) => q.type === 'Phone')
        if (phoneObj !== undefined)
          return this.$utils.isValid(phoneObj.extension)
            ? `${phoneObj.value} #${phoneObj.extension}`
            : phoneObj.value
        else return '-'
      } else return '-'
    },
    displayShippingPhone() {
      if (
        Object.keys(this.orderDetail).length &&
        this.orderDetail.default_shipping.phones &&
        this.orderDetail.default_shipping.phones.length
      ) {
        let phoneObj = this.orderDetail.default_shipping.phones.find(
          (q) => q.type === 'Phone'
        )
        if (phoneObj !== undefined)
          return this.$utils.isValid(phoneObj.extension)
            ? `${phoneObj.value} #${phoneObj.extension}`
            : phoneObj.value
        else return '-'
      } else return '-'
    },
    displayShippingMobile() {
      if (
        Object.keys(this.orderDetail).length &&
        this.orderDetail.default_shipping.phones &&
        this.orderDetail.default_shipping.phones.length
      ) {
        let phoneObj = this.orderDetail.default_shipping.phones.find(
          (q) => q.type === 'Mobile'
        )
        if (phoneObj !== undefined) return phoneObj.value
        else return '-'
      } else return '-'
    },
    isInactive() {
      console.log(
        'this.orderDetail',
        this.items.some((q) => q.status === 'Active')
      )
      return !this.items.some((q) => q.status === 'Active')
    },
  },
  watch: {
    summary: {
      handler() {
        if (
          this.profileType !== undefined &&
          this.profileType === 'eprocurement'
        ) {
          this.checkOverBudget()
        }
        // this.$_onInsiderBasket({
        //   page: {
        //     type: 'Basket',
        //     custom: {
        //       category_name: null,
        //     },
        //   },
        //   total: this.$utils.formatPrice(
        //     this.summary.net_amt + this.summary.vat_amt,
        //     2
        //   ),
        //   voucher_discount: this.$utils.formatPrice(
        //     this.summary.voucher_disc_amt_excvat,
        //     2
        //   ),
        //   shipping_cost: this.$utils.formatPrice(
        //     this.summary.net_deliveryfee_excvat,
        //     2
        //   ),
        //   vouchers: this.cart.voucherNo,
        //   line_items: this.items,
        // })
      },
      deep: true,
    },
    selectedCostcenter: {
      async handler(val) {
        if (val.value) {
          console.log('selectedCostcenter', val)
          await this.getCalculateCart()
          await this.getCoscenterDataById()
          await this.checkOverLastAppovalBudget()
        }
      },
      deep: true,
    },
    // cartCountPrinting: {
    //   handler: function (val) {
    //     console.log('length', val)
    //     if (val) {
    //       this.onSetCookieShift()
    //     }
    //   },
    //   immediate: true,
    // },
    // departmentId: {
    //   async handler(val) {
    //     if (val) console.log('departmentId', val)
    //   },
    //   deep: true,
    // },
  },
  async created() {
    // this.$nuxt.$on('reloadCart', await this.getCalculateCart)
    // await this.getCalculateCart(false)
    if (this.profileType !== undefined && this.profileType === 'eprocurement') {
      await this.getUserCompanyRequesterlineById() // get user detail
      await this.getShoppingCarts() // get item
      await this.getRequesterLineByUser() // get coscenter
      await this.checkOverLastAppovalBudget() // get budget
      this.isSkeleton = false
      this.isShowDetail = true
      // await this.checkOverGoodsReceivePeroid()
      if (!this.userProfile.is_site_active)
        this.checkoutWarningMessages.push('closed_site')
    }
  },
  async beforeDestroy() {
    // this.$nuxt.$off('reloadCart', await this.getCalculateCart)
    this.$nuxt.$emit('cookieShift', { isShift: false })
  },
  async mounted() {
    // if (this.profileType === 'eprocurement') {
    //   this.getUserCompanyRequesterlineById()
    // }
    this.key = `${this.openId}-${this.userProfile.id}`
    let _this = this
    window.addEventListener('resize', function () {
      _this.onSetCookieShift()
    })
  },
  methods: {
    ...mapActions('cart', ['setCartCountPrinting']),
    async getShoppingCarts() {
      this.items = []
      let params = new URLSearchParams()
      params.append('account_channel', 'eprocurement')

      const result = await this.$bizCardProvider.getCartItems(this.key, params)
      if (result.status === 200) {
        this.items = JSON.parse(
          JSON.stringify(
            result.data.sort((a, b) => {
              if (a.status === 'Active' && b.status !== 'Active') {
                return -1 // "a" comes first
              } else {
                return 0 // no change in order
              }
            })
          )
        )
      }
    },
    async getUserCompanyRequesterlineById() {
      await this.$profileProvider
        .getUserCompanyRequesterlineDataById(this.openId, this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('userProfile', this.userProfile)
              console.log('data', data)
              this.account = {
                company_id: data.company_id,
                company_name: data.company_name,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                profile_image: data.profile_image,
                phones: data.phones,
                default_language: data.default_language?.toUpperCase(),
                last_active: data.last_active,
                mobile:
                  data.phones.find((a) => a.type === 'Mobile') ?? this.mobile,
                phone:
                  data.phones.find((a) => a.type === 'Phone') ?? this.phone,
              }
              console.log('this.account :>> ', this.account)
              if (data.phones === null) {
                this.account.phones = this.tempPhones
              }
              if (this.account.phones.length == 1) {
                this.account.phones.push({
                  type: this.account.phones.find(
                    (a) => a.type === 'Phone' && a.value !== ''
                  )
                    ? 'Mobile'
                    : 'Phone',
                  value: '',
                  extension: '',
                })
              }
              //  this.account = {
              //     companyId: data.company_id,
              //     companyName: data.company_name,
              //     firstName: data.first_name,
              //     lastName: data.last_name,
              //     profileImage: data.profile_image,
              //     last_active: data.last_active,
              //   }
            }
          },
          (error) => {
            console.log('getUserCompanyRequesterlineById', error.responsed)
          }
        )
    },
    async getCoscenterDataById() {
      // this.isShowShippingDetail = false

      let params = new URLSearchParams()
      // params.append('account_id', this.userProfile.id)
      params.append('costcenter_id', this.selectedCostcenter.value)

      const result = await this.$bizCardProvider.getShippingByCoscenter(
        this.key,
        params
      )
      if (result.status === 200) {
        this.shippingList = []
        // this.orderDetail = {}
        console.log('getShippingByCoscenter', result.data)
        this.orderDetail = JSON.parse(JSON.stringify(result.data))
        this.orderDetail.shipping_id =
          this.orderDetail.default_shipping.id || ''
        if (this.orderDetail.account_shippings.length) {
          this.orderDetail.account_shippings.forEach((item) => {
            this.shippingList.push({
              value: item.id,
              text: `${item.contact} \r\n${item.address_1} ${item.address_2}`,
              custom: {
                contact: item.contact,
                address_1: item.address_1,
                address_2: item.address_2,
                address_3: item.address_3,
                address_4: item.address_4,
                phones: item.phones,
              },
            })
          })
        }
        // check credit
        let index = this.checkoutWarningMessages.findIndex(
          (q) => q === 'no_credit'
        )
        if (this.orderDetail.payment_code !== 'Credit') {
          if (index === -1) this.checkoutWarningMessages.push('no_credit')
        } else {
          if (index !== -1) this.checkoutWarningMessages.splice(index)
        }

        // data.shippings.forEach((e) => {
        //   // if (e.is_cost_ship) {
        //     let temp_phone = []
        //     let temp = ''
        //     let phone_ext = ''
        //     if (e.phone !== undefined && e.phone.length > 0) {
        //       e.phone.forEach((a) => {
        //         if (a.type === 'Phone') {
        //           phone_ext =
        //             a.extension !== '' ? a.value + ' #' + a.extension : a.value
        //           temp_phone.push({
        //             value: phone_ext,
        //           })
        //         }
        //         if (a.type === 'Mobile') {
        //           temp_phone.push({ value: a.value })
        //         }
        //       })
        //       temp = temp_phone.map((item) => item.value).toString()
        //       console.log('object', temp)
        //     }
        //     this.shippingList.push({
        //       value: e.id,
        //       shippingid: e.offline_id,
        //       contact: e.contact,
        //       text: e.contact + ' ' + e.ship_address,
        //       shipPhone: temp,
        //     })
        //   // }
        // })
        // this.selectedShipping
        this.selectedShipping.text = this.shippingList[0].text
        this.selectedShipping.value = this.shippingList[0].value

        // data.invoices.forEach((e) => {
        //   if (e.is_cost_ship) {
        //     let temp_phone = []
        //     let temp = ''
        //     let phone_ext = ''
        //     if (e.phone !== undefined && e.phone.length > 0) {
        //       e.phone.forEach((a) => {
        //         if (a.type === 'Phone') {
        //           phone_ext =
        //             a.extension !== ''
        //               ? a.value + ' #' + a.extension
        //               : a.value
        //           temp_phone.push({
        //             value: phone_ext,
        //           })
        //         }
        //         if (a.type === 'Mobile') {
        //           temp_phone.push({ value: a.value })
        //         }
        //       })
        //       temp = temp_phone.map((item) => item.value).toString()
        //       console.log('object', temp)
        //     }
        //     this.orderDetail.push({
        //       id: e.id,
        //       shippingid: e.offline_id,
        //       contact: e.contact,
        //       shipAddress: e.ship_address,
        //       shipPhone: temp,
        //     })
        //   }
        // })
        console.log('shippingList', this.shippingList)
        console.log('orderDetail', this.orderDetail)
        // this.shipAddress = data
        // this.accountid = this.userProfile.id
        // this.isShow = this.shipAddress.length > 0 ? data[0].isShow : false
        this.isShowShippingDetail = true
        this.onSetCookieShift()
      }
      //   },
      //   (error) => {
      //     console.log('getCoscenterDataById', error.responsed)
      //   }
      // )
      // this.isSkeleton = false
    },
    async onSelectedShipping(event) {
      this.selectedShipping.text = event.text
      this.selectedShipping.value = event.value

      console.log('onSelectedShipping', event)
      this.orderDetail.shipping_id = event.value
      this.popoverDelivery = false
      this.orderDetail.default_shipping.contact = event.custom.contact
      this.orderDetail.default_shipping.address_1 = event.custom.address_1
      this.orderDetail.default_shipping.address_2 = event.custom.address_2
      this.orderDetail.default_shipping.address_3 = event.custom.address_3
      this.orderDetail.default_shipping.address_4 = event.custom.address_4
      this.orderDetail.default_shipping.phones = event.custom.phones

      // await this.updateorderDetailoraryShipping(event.value)
    },
    // async reloadPage() {
    //   if (this.profileType === 'eprocurement') {
    //     await this.getCalculateCart()
    //     await this.checkOverLastAppovalBudget()
    //   }
    // },
    checkOverLastAppovalBudget() {
      console.log('over_approval_budget')
      let reqesterLine = this.requesterLineList.find(
        (q) => q.costcenter.id === this.selectedCostcenter.value
      )
      if (reqesterLine !== undefined && reqesterLine.approvals.length) {
        if (
          reqesterLine.approvals[reqesterLine.approvals.length - 1]
            .credit_limit <
          this.summary.net_amt + this.summary.vat_amt
        ) {
          let index = this.checkoutWarningMessages.findIndex(
            (q) => q === 'over_approval_budget'
          )
          if (index !== -1) this.checkoutWarningMessages.splice(index)
          this.checkoutWarningMessages.push('over_approval_budget')
        } else {
          let index = this.checkoutWarningMessages.findIndex(
            (q) => q === 'over_approval_budget'
          )
          if (index !== -1) this.checkoutWarningMessages.splice(index)
        }
      }
      // else {
      //   let index = this.checkoutWarningMessages.findIndex(
      //     (q) => q === 'requesterline_notfound'
      //   )
      //   if (index !== -1) this.checkoutWarningMessages.splice(index)
      //   this.checkoutWarningMessages.push('requesterline_notfound')
      // }
    },
    checkOverBudget() {
      if (this.budget.id !== '') {
        if (
          !this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
          this.checkoutWarningMessages.push('over_budget')
        } else if (
          !this.is_allow_create_order_over_budget &&
          this.budget.remain_amt >= this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
        } else if (
          this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
        }
      }
    },
    async getRequesterLineByUser() {
      console.log('getRequesterLineByUser', this.userProfile, this.openId)
      const result = await this.$bizCardProvider.getRequesterLineByUser(
        this.userProfile.id,
        this.openId
      )

      if (result.status === 200) {
        if (result.data.length) {
          console.log('getRequesterLineByUser', result.data)
          this.requesterLineList = result.data
          result.data.forEach((item) => {
            this.costcenterList.push({
              value: item.costcenter.id,
              text: `[${item.costcenter.code}] ${
                item.costcenter.name[this.$i18n.locale]
              }`,
            })
          })
          if (Object.keys(this.userCostcenter).length) {
            console.log(
              'klsajdkljasdkljasdklj',
              result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              )
            )
            if (
              result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              ) !== undefined
            ) {
              this.selectedCostcenter = {
                value: this.userCostcenter.id,
                text: `[${this.userCostcenter.code}] ${
                  this.userCostcenter.name[this.$i18n.locale]
                }`,
              }
              this.departmentId = this.userCostcenter.department_id
              this.requesterLineId = result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              ).id
            } else {
              if (this.companyControlType !== 'Costcenter') {
                this.selectedCostcenter = {
                  value: result.data[0].costcenter.id,
                  text: `[${result.data[0].costcenter.code}] ${
                    result.data[0].costcenter.name[this.$i18n.locale]
                  }`,
                }
                this.departmentId = result.data[0].costcenter.department_id
                this.requesterLineId = result.data[0].id
              } else {
                this.isNotPurchaser = true
                this.checkoutWarningMessages.push('not_purchaser')
              }
            }
            result.data.forEach((item) => {
              if (item.costcenter.id === this.selectedCostcenter.value) {
                if (item.approvals.length) {
                  this.approvals = item.approvals
                  this.approverLevel = 1
                }
              }
            })
          } else {
            this.selectedCostcenter = {
              value: result.data[0].costcenter.id,
              text: `[${result.data[0].costcenter.code}] ${
                result.data[0].costcenter.name[this.$i18n.locale]
              }`,
            }
            this.departmentId = result.data[0].costcenter.department_id
            result.data.forEach((item) => {
              if (item.costcenter.id === this.selectedCostcenter.value) {
                if (item.approvals.length) {
                  this.approvals = item.approvals
                  this.approverLevel = 1
                }
              }
            })
          }

          if (this.userProfile.is_use_budget_control)
            if (!this.isNotPurchaser) {
              await this.getCompaniesBudgetPrintingControl()
              // await this.getShippingDetail()
            }
        } else {
          this.checkoutWarningMessages.push('requesterline_notfoud')
        }
        // console.log('default', this.departmentId)
      } else if (result.status === 404) {
        this.checkoutWarningMessages.push('requesterline_notfoud')
      }
    },
    async getCompaniesBudgetPrintingControl() {
      this.checkoutWarningMessages = []
      let params = new URLSearchParams()
      params.append('cost_center_id', this.selectedCostcenter.value)
      params.append('department_id', this.departmentId)

      const result =
        await this.$bizCardProvider.getCompaniesBudgetPrintingControl(
          this.userProfile.id,
          params
        )
      console.log('result getCompaniesBudgetPrintingControl:', result)
      if (result.status === 200) {
        const data = result.data

        if (data.period != null) {
          this.budget = data.period
          this.budget.id = data.id
          this.hasBudgetValue = true
          this.is_allow_create_order_over_budget =
            data.is_allow_create_order_over_budget
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'budget_notfound'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
          await this.checkOverBudget()
        } else {
          this.hasBudgetValue = false
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'budget_notfound'
            )

            if (index !== -1) this.checkoutWarningMessages.splice(index)
            this.checkoutWarningMessages.push('budget_notfound')
          } else {
            this.checkoutWarningMessages.push('budget_notfound')
          }
        }
      } else if (result.status === 404) {
        this.hasBudgetValue = false
        this.checkoutWarningMessages.push('budget_notfound')
      }
    },
    // async getShippingDetail() {
    //   this.isShowShippingDetail = true
    //   this.isSkeleton = false
    // },

    async onSelectedCoscenter(value) {
      console.log('departmentId', value)
      this.approverLevel = 1
      this.selectedCostcenter = value
      let temp = this.requesterLineList.find(
        (q) => q.costcenter.id === this.selectedCostcenter.value
      )
      this.approvals = temp !== undefined ? temp.approvals : []
      this.departmentId =
        temp !== undefined ? temp.costcenter.department_id : ''
      this.requesterLineId = temp !== undefined ? temp.id : ''
      if (
        this.userProfile !== undefined &&
        this.userProfile !== null &&
        this.userProfile.is_use_budget_control
      )
        await this.getCompaniesBudgetPrintingControl()
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      // console.log(this.approverLevel)
    },
    onSelectApprover(value) {
      this.selectedApprover = value
      // console.log(this.selectedApprover)
    },
    // async onCheckCoupon() {
    //   if (this.$utils.isValid(this.cart.voucherNo))
    //     await this.getCalculateCart()
    // },
    // async onRemoveVoucher() {
    //   this.cart.voucherNo = ''
    //   this.is_use_coupon = false
    //   await this.getCalculateCart()
    // },
    onCheckOutCart() {
      // this.createOrder()
      this.isShowCreateModal = true
    },
    onModalPreview(data) {
      this.modalPreviewData = data
      // this.$nuxt.$emit(
      //   'open-printing-modal',
      //   data,
      //   Math.random().toString(36).substring(7)
      // )
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    async getCalculateCart() {
      // this.isLoadingCart = true
      let params = new URLSearchParams()
      params.append('account_channel', 'eprocurement')
      params.append('costcenter_id', this.selectedCostcenter.value)
      const result = await this.$bizCardProvider.getCalculateCart(
        this.key,
        params
      )
      console.log('result', result)
      if (result.status === 200) {
        this.summary = result.data.summary
      } else if (result.status === 404) {
      }
      this.$nextTick(() => {
        // this.isLoadingCart = false
      })
    },
    uploadCSV() {
      this.$refs['upload-csv'].click()
    },
    async onChangeQty(_id, qty) {
      // console.log('onChangeQty', _id, qty)
      // let params = new URLSearchParams()
      // params.append('item_id', _id)
      // params.append('display_quantity', qty)
      const payload = {
        item_id: _id,
        display_quantity: qty,
      }
      const result = await this.$bizCardProvider.editOrderItem(
        this.key,
        payload
      )
      console.log('onChangeQty', result)
      if (result.status === 204) {
        await this.getPrintingCartsCount()
        await this.getShoppingCarts()
        await this.getCalculateCart()
        await this.checkOverLastAppovalBudget()
      } else {
        await this.getShoppingCarts()
      }
    },
    async getPrintingCartsCount() {
      if (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      ) {
        let params = new URLSearchParams()
        params.append('company_id', this.userProfile.id)
        const key = `${this.openId}-${this.userProfile.id}`
        const result = await this.$bizCardProvider.getCartCount(key, params)
        console.log('getPrintingCartsCount', result)
        if (result.status === 200) {
          this.setCartCountPrinting(result.data.cart_total)
        } else {
          this.setCartCountPrinting(0)
        }
      }
    },
    // async selected({ target: { files } }) {
    //   console.log('files', files)
    //   if (files.length) {
    //     await this.$refs['input-file']
    //       .validate(files[0])
    //       .then(async (result) => {
    //         console.log('result', result)
    //         if (result.valid) {
    //           console.log('valid')
    //           console.log('file', files[0])
    //           this.importFile(files[0])
    //         } else console.log('not valid')
    //       })
    //   }
    // },
    getFile() {
      this.orderDetail.po_upload_name = ''
      document.getElementById('file').value = ''
      document.getElementById('file').click()
    },
    async importFile(e) {
      // if (this.createModel.items.length <= 0) {
      // this.approve.fileName = e.target.files[0].name
      console.log(e.target.files[0])
      if (e.target.files.length) {
        await this.$refs['uploadFile']
          .validate(e.target.files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              await this.uploadPOFile(e.target.files[0])
            } else console.log('not valid')
          })
      }
    },
    async uploadPOFile(file) {
      this.uploadingPOFlag = true
      let formData = new FormData()
      formData.append('company_id', this.userProfile.id)
      formData.append('po_upload_name', file.name)
      formData.append('file', file)

      formData.append('content_description', 'bizcard po upload file')
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      }
      await this.$bizCardProvider
        .uploadPOFile(formData, config, false)
        .then(
          (result) => {
            console.log('uploadPOFile', result)
            if (result.status === 201) {
              if (result.data !== '') {
                this.orderDetail.po_upload_name = file.name
                this.orderDetail.po_upload_url = result.data
                this.isUploadPOFail = false
              } else this.isUploadPOFail = true
              // this.getOrderTemporary()
            } else {
              this.isUploadPOFail = true
            }
          },
          (error) => {
            console.log('uploadFile', error.responsed)
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.uploadingPOFlag = false
          }, 100)
        })
    },
    async removePOFile() {
      this.orderDetail.po_upload_name = ''
      this.orderDetail.po_upload_url = ''
      // let apiData = JSON.stringify({
      //   is_remove_po_file: true,
      // })

      // const result =
      //   await this.$shoppingCartProvider.updateOrderTemporaryDetails(
      //     this.orderDetail.id,
      //     apiData
      //   )
      // if (result.status === 204) {
      //   this.orderDetail.po_upload_name = ''
      //   this.orderDetail.po_upload_url = ''
      // }
    },
    viewPOFile() {
      window.open(this.orderDetail.po_upload_url, '_blank')
    },
    async createOrder(redirectPath) {
      console.log(this.userProfile)
      // this.isShowCreateModal = true
      let payload = {
        user_open_id: this.openId,
        account_id: this.orderDetail.account_id,
        account_channel: this.profileType,
        company_id: this.userProfile.id,
        requesterline_id: this.requesterLineId, // 64ffd8f02630f5ea6ab0ea10
        companies_budget_printing_control_id: this.budget.id, // 64d26873d60e91f6170af747
        // is_use_budget_printing_control: true,
        shipping_account_id: this.orderDetail.shipping_id,
        po_no: this.orderDetail.po_no,
        po_upload_name: this.orderDetail.po_upload_name,
        po_upload_url: this.orderDetail.po_upload_url,
        delivery_remark: this.orderDetail.delivery_remark,
        remark_to_approver: this.orderDetail.remark_to_approver,
      }
      console.log('payload', payload)
      const result = await this.$bizCardProvider.createOrder(payload)
      console.log('createOrder', result)
      if (result.status === 201) {
        // await this.getUserData()
        this.$router.push({
          path: this.$i18n.path(
            `company/printing/complete/${result.data.order_id}`
          ),
        })
      }
    },
    onRoute() {
      this.$router.push({
        path: this.$i18n.path('company/printing/template'),
      })
    },
    async onSetCookieShift() {
      setTimeout(async () => {
        let height = this.$refs['cart-checkout-printing']
          ? this.$refs['cart-checkout-printing'].clientHeight
          : 0
        console.log('height', height)
        await this.$nuxt.$emit('cookieShift', {
          isShift: true,
          height: height,
        })
      }, 50)
    },
  },
}
