
import { mapGetters } from 'vuex'

export default {
  props: {
    minimum_quantity: {
      type: Number,
      default: 1,
    },
    maximum_quantity: {
      type: Number,
      default: 0,
    },
    stock_available: {
      type: Number,
      default: 0,
    },
    is_company_product_control: {
      type: Boolean,
      default: false,
    },
    sku_code: {
      type: String,
      default: '',
    },
    show: { type: Boolean, default: false },
  },
  components: {
    SelectObject: async () =>
      await import('@/components/share/select-object.vue'),
  },
  data() {
    return {
      qty: 0,
      maxQty: 9999,
      ship_zipcode: '',
      isSearchResult: false,
      isSearchLoadingSkeleton: false,
      // typingTimeout: null,
      province: '',
      provinceList: [
        {
          text: this.$i18n.t(`account-tax-create.select`),
          value: '',
        },
      ],
      validateSearch: false,
      lattitude: 0,
      longitude: 0,
      shippingCost: {},
      isCondition: true,
      isError: false,
      isNotFound: false,
      isLoading: true,
      // geocode
      geocoder: null,
      isProvince: true,
      isShowBtnRelated: false,
      // ref: '',
      modalShow: false,
    }
  },
  created() {
    // this.ref = Math.random().toString(36).substring(7)
    // if (process.client) {
    // this.$nuxt.$on('modalShipping', (data) => {
    //   this.$nextTick(() => {
    //     this.onReset()
    //     this.qty = this.minimum_quantity <= 0 ? 1 : this.minimum_quantity
    //     // this.$refs[`modal-shipping-${this.ref}`].show()
    //     this.modalShow = true
    //   })
    // })
    // }
  },
  watch: {
    ship_zipcode: {
      handler: function (val) {
        // if (this.typingTimeout) clearTimeout(this.typingTimeout)

        if (val.length >= 5) {
          this.validateSearch = false
          this.isSearchLoadingSkeleton = true
          this.onSearchZone()
          // Clear the previous timer
          // clearTimeout(this.typingTimeout)
          // this.typingTimeout = setTimeout(() => {
          //   this.onSearchZone()
          // }, 500)
        } else {
          this.province = ''
          this.provinceList = [
            {
              text: this.$i18n.t(`account-tax-create.select`),
              value: '',
            },
          ]
          this.isSearchResult = false
        }
      },
      deep: true,
    },
    show: function (value) {
      if (value) {
        this.onReset()
        this.qty = this.minimum_quantity <= 0 ? 1 : this.minimum_quantity
        // this.$refs[`modal-shipping-${this.ref}`].show()
        this.modalShow = true
      }
    },
  },
  beforeDestroy() {
    // if (process.client) {
    // this.$nuxt.$off('modalShipping')
    // }
  },
  computed: {
    ...mapGetters('user', [
      'userProfile',
      // 'userInfo',
      'profileType',
      // 'userCostcenter',
    ]),
    isMinimumQty() {
      return this.minimum_quantity <= this.qty
    },
  },
  mounted() {
    // this.$refs['modal-shipping'].show()
    // this.onReset()
    this.onCreateGeo()
  },
  methods: {
    // warningMinimumQty(minimum_quantity) {
    //   let msg = this.$t('orderItemsComponent.warningMinimumQty')
    //   return msg.replace(
    //     '{product.minimum_quantity}',
    //     minimum_quantity.toString()
    //   )
    // },
    // warningMaximumQty(maximum_quantity) {
    //   let msg = this.$t('orderItemsComponent.warningMaximumQty')
    //   return msg.replace(
    //     '{product.maximum_quantity}',
    //     maximum_quantity.toString()
    //   )
    // },
    onCheckShipping() {
      this.shippingCost = ''
    },
    onReset() {
      this.validateSearch = false
      this.ship_zipcode = ''
      this.province = ''
      this.provinceList = [
        {
          text: this.$i18n.t(`account-tax-create.select`),
          value: '',
        },
      ]
      this.isSearchResult = false
    },
    onClose() {
      // this.$refs[`modal-shipping-${this.ref}`].hide()
      this.modalShow = false
      this.$emit('hidden')
      setTimeout(() => {
        this.onReset()
      }, 500)
    },
    onChangeQty(qty) {
      let max = Number(this.maximum_quantity > 0)
        ? Number(this.maximum_quantity)
        : this.maxQty

      // console.log('item', qty, max, Number(qty), max < Number(qty))
      if (max < Number(qty)) this.qty = String(max)

      // this.qty =
      //   this.dataSku.minimum_quantity <= qty
      //     ? qty
      //     : this.dataSku.minimum_quantity
    },
    async onCheckCurrent() {
      console.log('this location')
      window.navigator.geolocation.getCurrentPosition((Position) => {
        console.log('this location', Position.coords)
        this.lattitude = Position.coords.latitude
        this.longitude = Position.coords.longitude
        // this.onGetStoreLocation()
        this.onGeocode(this.lattitude, this.longitude)
      })
    },
    // async onGetStoreLocation() {
    //   // let headers = {
    //   //   'access-control-allow-origin': '*',
    //   // }
    //   const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json`

    //   let params = {
    //     latlng: `${this.lattitude},${this.longitude}`,
    //     key: process.env.NUXT_ENV_GOOGLE_GEOCODE_API_KEY,
    //   }
    //   try {
    //     const response = await this.$axios.get(geocodeUrl, {
    //       params,
    //       // headers,
    //     })
    //     const data = response.data
    //     console.log('onGetStoreLocation', data)
    //     if (data.results && data.results.length > 0) {
    //       // Postal code might be available in the address components
    //       const postalCode = data.results[0].address_components.find(
    //         (component) => component.types.includes('postal_code')
    //       )

    //       if (postalCode) {
    //         this.ship_zipcode = postalCode.long_name
    //         console.log('Postal Code:', postalCode.long_name)
    //       } else {
    //         this.ship_zipcode = ''
    //         console.log('Postal code not found.')
    //       }
    //     } else {
    //       console.log('No results found.')
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error)
    //   }
    // },
    onValidate() {
      if (this.ship_zipcode.length < 5 || !this.province) {
        this.validateSearch = true
      }
      console.log('ship_zipcode', this.ship_zipcode.length)
      if (this.ship_zipcode >= 5 && this.province) {
        this.validateSearch = false
        this.onSubmit()
      }
    },
    async onSubmit() {
      this.isCondition = true
      this.shippingCost = {}
      console.log('province', this.province)
      let params = new URLSearchParams()
      params.append('quantity', this.qty)
      params.append('ship_province', this.province[this.$i18n.locale])
      params.append('ship_zipcode', this.ship_zipcode)
      params.append('profile_id', this.userProfile.id)
      params.append('account_channel', this.profileType)
      // params.append('no-cache', true)
      params.append(
        'company_id',
        this.profileType === 'eprocurement' ? this.userProfile.id : ''
      )

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      let responsed =
        await this.$productProvider.getDeliveryChargeByDeliveryAreaSku(
          this.sku_code,
          params
        )
      console.log('getDeliveryChargeByDeliveryAreaSku', responsed)
      this.isSearchResult = true

      if (responsed) {
        this.isLoading = false
        if (responsed.status === 200) {
          this.isCondition = false
          this.shippingCost = { ...responsed.data }
        } else if (responsed.status === 404) {
          this.isShowBtnRelated = false
          this.isNotFound = true
          if (responsed.data?.key !== 'C802') {
            this.isShowBtnRelated = true
          }
        } else {
          this.isError = true
        }
      } else {
        this.isError = true
      }
    },
    async onSearchZone() {
      this.province = ''
      const params = new URLSearchParams()
      params.append('keyword', this.ship_zipcode)
      await this.$logisticProvider
        .getZone(params)
        .then((result) => {
          console.log(result.data)
          if (result.data.length) {
            this.isProvince = true
            // groups
            this.provinceList = [
              {
                text: this.$i18n.t(`account-address-edit-id.select`),
                value: '',
              },
            ]
            let value = result.data
            let groups = []
            value = value.reduce((h, value) => {
              return Object.assign(h, {
                [value['province']['en']]: (
                  h[value['province']['en']] || []
                ).concat(value),
              })
            }, {})
            console.log('value', value)
            Object.keys(value).forEach((element) => {
              groups.push({
                province: value[element][0].province,
                items: value[element],
              })
            })

            groups.forEach((e) => {
              this.provinceList.push({
                text: e.province[this.$i18n.locale],
                value: e.province,
              })
            })
            this.province = groups[0].province
          } else {
            this.isProvince = false
          }
        })
        .catch((err) => {
          console.log('searchZone', err)
        })
        .finally(() => {
          console.log('finally!!')
        })
    },
    // geocode
    onCreateGeo() {
      // Check if the Google Maps script is already loaded
      if (!window.google) {
        // Create a new script element for Google Maps API
        const script = document.createElement('script')
        // Set the API key and callback function
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NUXT_ENV_GOOGLE_GEOCODE_API_KEY}&callback=initMap`
        script.defer = true
        script.async = true
        // Attach the script to the document head
        document.head.appendChild(script)

        // Attach the onCreateGeo function to the window object
        window.initMap = () => {
          console.log('Google Maps initialized')
        }
      }
    },
    onGeocode(latitude, longitude) {
      this.geocoder = new google.maps.Geocoder()

      this.geocoder
        .geocode({ location: { lat: latitude, lng: longitude } })
        .then((result) => {
          const { results } = result
          if (results && results.length > 0) {
            console.log('geocode', results)
            const postalCode = results[0].address_components.find((component) =>
              component.types.includes('postal_code')
            )
            if (postalCode) {
              if (this.ship_zipcode !== postalCode.long_name) {
                this.isSearchResult = false
                this.ship_zipcode = postalCode.long_name
              }
            } else {
              this.ship_zipcode = ''
            }
          }
        })
        .catch((e) => {
          console.log('Geocode error: ' + e)
        })
    },
  },
}
