
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    height: {
      type: String,
      default: '',
    },
    isGotoCart: { type: Boolean, default: true },
  },
}
