import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=4cf93257"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=4cf93257&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApproverDropdown: require('/usr/src/nuxt-app/components/checkout/approver-dropdown.vue').default,InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,PrintingItems: require('/usr/src/nuxt-app/components/printing/printing-items.vue').default,SummaryComponent: require('/usr/src/nuxt-app/components/checkout/summary-component.vue').default,ModalWarning: require('/usr/src/nuxt-app/components/modal/modalWarning.vue').default,ConfirmModal: require('/usr/src/nuxt-app/components/modal/confirmModal.vue').default})
