
import asideComponent from '@/components/template/aside-user.vue'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'ManageProductEdit',
  components: {
    'aside-component': asideComponent,
    Multiselect,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      searchItem: [],
      is_categories_type: false,
      updatecuscenter: {
        sku_code: '',
        control_type: '',
        control_type_values: [],
      },
      costcenter: [],
      skus: [],
      fields: [
        {
          key: 'productImage',
          label: '',
          class: 'text-center fs-12 w-90 border-right',
          sortable: false,
          stickyColumn: true,
        },
        {
          key: 'productId',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'reference_code',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'productName',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'price',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 w-160',
          sortable: false,
        },

        {
          key: 'action',
          label: '',
          class: 'text-right w-160',
          sortable: false,
        },
      ],
      userFields: [
        {
          key: 'text',
          label: this.$i18n.t(`company-products-edit.name`),
          class: 'fs-12 w-300 border-right',
          sortable: false,
        },
        {
          key: 'email',
          label: this.$i18n.t(`company-products-edit.email`),
          thClass: '',
          tdClass: 'fs-12',
          sortable: false,
        },
      ],
      costcenterFields: [
        {
          key: 'code',
          label: this.$i18n.t(`company-products-edit.costcenterID`),
          class: 'fs-12 w-180 border-right',
          sortable: false,
        },
        {
          key: 'value',
          label: this.$i18n.t('company-products-edit.costcenter'),
          class: 'fs-12',
          sortable: false,
        },
      ],
      companyFields: [
        {
          key: 'code',
          label: this.$i18n.t('company-products-edit.companyID'),
          class: 'fs-12 w-180 border-right',
          sortable: false,
        },
        {
          key: 'name',
          label: this.$i18n.t('company-products-edit.companyName'),
          thClass: '',
          tdClass: 'fs-12',
          sortable: false,
        },
      ],
      userSelect: '',
      optionsUser: [],
      userList: [],
      costcenterSelect: '',
      optionsCostcenter: [],
      costcenterList: [],
      is_company: false,
      is_costcenter: false,
      is_user: false,
      companyList: [],
      control_type: '',
      users: '',
      reference_code: '',
      is_error: false,
      is_reference: false,
      is_Show: false,
      is_Show_Cost: false,
      skus_code: '',
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('authen', ['openId']),
  },
  watch: {
    costcenterList: function (value) {
      this.is_Show_Cost = value.length > 1 ? true : false
    },
    userList: function (value) {
      this.is_Show = value.length > 1 ? true : false
    },
  },
  mounted() {
    this.getCompaniesProducts()
  },
  methods: {
    onAddUser() {
      this.$nextTick(() => {
        console.log('userList', this.userList)
        this.userList.push(this.userSelect)
        this.userSelect = ''

        this.userList.forEach((v) => {
          this.optionsUser[
            this.optionsUser.findIndex((e) => e.email === v.email)
          ]['$isDisabled'] = true
        })
      })
    },
    onAddCostcenter() {
      this.$nextTick(() => {
        console.log('costcenterSelect', this.costcenterSelect)
        this.costcenterList.push(this.costcenterSelect)
        console.log('costcenterList', this.costcenterList)
        this.costcenterSelect = ''

        this.costcenterList.forEach((v) => {
          this.optionsCostcenter[
            this.optionsCostcenter.findIndex((e) => e.id === v.id)
          ]['$isDisabled'] = true
        })
      })
    },
    onRemoveUser(email) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsUser[this.optionsUser.findIndex((e) => e.email === email)][
          '$isDisabled'
        ] = false
        // remove user
        this.userList.splice(
          this.userList.findIndex((e) => e.email === email),
          1
        )
      })
    },
    onCancel() {
      this.$router.push({
        path: this.$i18n.path('company/products/'),
      })
    },
    async getCompaniesProducts() {
      let params = new URLSearchParams()
      params.append('company_id', this.userProfile.id)
      params.append('sku_code', this.$route.params.id)
      await this.$profileProvider.getCompaniesProductskusData(params).then(
        (result) => {
          if (result.status === 200) {
            let com_product = result.data
            this.is_costcenter = false
            this.is_company = false
            this.is_user = false
            this.is_reference = false
            this.is_categories_type =
              this.userProfile.categories_type === 'Costcenter' ? true : false
            com_product.items.forEach((e) => {
              this.is_reference =
                e.companies_custom.is_use_reference_product_code
              this.skus_code = []
              this.skus_code.push(e.sku_code)
              this.params = {
                user_open_id: this.openId,
                profile_id: this.userProfile.id,
                account_channel: this.profileType,
                costcenter_id: this.userCostcenter.id,
                skus: this.skus_code,
              }
              this.$productProvider.productSkusbulkSearch(this.params).then(
                (result) => {
                  if (result.status === 201) {
                    let data = result.data
                    console.log(666666, data)
                    data.skus.forEach((product) => {
                      this.searchItem = [
                        {
                          productImage: this.imageNormalShowcase(product),
                          productId: product.sku_code,
                          productName:
                            product.product_names !== undefined
                              ? product.product_names.find(
                                  (a) => a.type === 'Online'
                                ).display_name.th
                              : '',
                          price: product.price_exclude_vat,
                        },
                      ]
                    })
                    this.reference_code = com_product.items[0].reference_code
                  } else {
                    this.showEmpty = true
                    this.searchItem = []
                  }
                },
                (error) => {
                  console.log('getProductSkus', error.responsed)
                }
              )
              this.control_type = e.control_type
              if (e.control_type === 'Costcenter') {
                this.getNameCostcenter()
                this.is_costcenter = true
                e.costcenter_custom.forEach((a, i) => {
                  this.costcenterList.push({
                    index: i + 1,
                    id: a.id,
                    code: a.code,
                    text: a.name.th,
                    value: a.name.th,
                    $isDisabled: false,
                  })
                })
              }
              if (e.control_type === 'User') {
                this.is_user = true
                this.getRoleRequestersEmail()
                e.user_custom.forEach((c, ci) => {
                  this.userList.push({
                    index: ci + 1,
                    id: c.user_open_id,
                    text: c.first_name.th + ' ' + c.last_name.th,
                    email: c.email,
                    $isDisabled: false,
                  })
                })
              }
              if (e.control_type === 'Company') {
                this.is_company = true
                this.getCompaniesInfoById()
              }
            })
          }
          console.log('is_company', this.is_company)
          console.log('is_company', this.is_reference)
        },
        (error) => {
          console.log('getCompaniesUserRolesInfoById', error.responsed)
        }
      )
    },
    async getNameCostcenter() {
      await this.$profileProvider
        .getNameCostcenterData(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              data.forEach((e, index) => {
                this.optionsCostcenter.push({
                  index: index + 1,
                  id: e.id,
                  code: e.code,
                  text: '[' + e.code + '] ' + e.name.th,
                  value: e.name.th,
                  $isDisabled: false,
                })
              })
              this.costcenterList.forEach((v) => {
                this.optionsCostcenter[
                  this.optionsCostcenter.findIndex((e) => e.id === v.id)
                ]['$isDisabled'] = true
              })
            }
          },
          (error) => {
            console.log('getNameCostcenterData', error.responsed)
          }
        )
    },
    async updateCostcenterData() {
      this.updateCostcenter = []
      if (this.control_type === 'Costcenter') {
        this.costcenter = []
        this.is_error = this.costcenterList.length > 0 ? true : false
        this.costcenterList.forEach((e) => {
          this.costcenter.push(e.id)
        })
      }

      if (this.control_type === 'User') {
        this.users = []
        this.is_error = this.userList.length > 0 ? true : false
        this.userList.forEach((e) => {
          this.users.push(e.id)
        })
      }
      if (this.control_type === 'Company') {
        this.is_error = true
      }

      if (this.is_error) {
        this.updateCostcenter = {
          sku_code: this.$route.params.id,
          reference_code: this.reference_code,
          control_type: this.control_type,
          control_type_values:
            this.control_type === 'Costcenter' ? this.costcenter : this.users,
        }
        await this.$profileProvider
          .updateCostcenterById(this.userProfile.id, this.updateCostcenter)
          .then(
            (result) => {
              if (result.status === 204) {
                if (this.control_type === 'Costcenter') {
                  this.costcenterList = []
                  this.optionsCostcenter = []
                }
                if (this.control_type === 'User') {
                  this.userList = []
                  this.optionsUser = []
                }
                //this.getCompaniesProducts()
                this.onCancel()
              }
            },
            (error) => {
              console.log('updateCostcenterById', error.responsed)
            }
          )
      }
    },
    async updateCompanyData() {
      this.updateCostcenter = {
        sku_code: this.$route.params.id,
        reference_code: this.reference_code,
      }
      await this.$profileProvider
        .updateCostcenterById(this.userProfile.id, this.updateCostcenter)
        .then(
          (result) => {
            if (result.status === 204) {
              this.onCancel()
            }
          },
          (error) => {
            console.log('updateCostcenterById', error.responsed)
          }
        )
    },
    delectCostcenterData(costcenter_id) {
      this.$nextTick(() => {
        // remove disabled
        this.optionsCostcenter[
          this.optionsCostcenter.findIndex((e) => e.id === costcenter_id)
        ]['$isDisabled'] = false
        // remove user
        this.costcenterList.splice(
          this.costcenterList.findIndex((e) => e.id === costcenter_id),
          1
        )
      })
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(data)
              this.companyList = [
                {
                  code: data.company_code,
                  name: data.name.th,
                },
              ]
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
    async getRoleRequestersEmail() {
      await this.$profileProvider
        .getRoleRequestersEmailById(this.userProfile.id, false)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log('data', data)
              this.optionsUser = []
              data.forEach((e, i) => {
                this.optionsUser.push({
                  index: i + 1,
                  id: e.user_open_id,
                  text: e.first_name.th + ' ' + e.last_name.th,
                  email: e.email,
                  $isDisabled: false,
                })
              })
              this.userList.forEach((v) => {
                this.optionsUser[
                  this.optionsUser.findIndex((e) => e.email === v.email)
                ]['$isDisabled'] = true
              })
            }
          },
          (error) => {
            console.log('getRoleRequestersEmail', error.responsed)
          }
        )
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return sku.images.some(
          (q) => q.size === 'thumbnail' && q.sequence === 1
        )
          ? sku.images.find((q) => q.size === 'thumbnail' && q.sequence === 1)
              .url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
  },
}
