
export default {
  name: 'TabProductBrand',
  components: {
    // 'swiper-item': SwiperSlider,
  },
  props: {
    brands: { type: Array, default: () => [] },
    activeBrand: { type: String, default: '' },
  },
  data() {
    return {
      isActivePrev: false,
      isActiveNext: false,
      swiperOption: {
        loop: false,
        spaceBetween: 0,
        on: {
          resize: () => {
            this.$refs['brand-tab'].$swiper.params.slidesPerView =
              this.responsive()
            this.$refs['brand-tab'].$swiper.update()
          },
        },
      },
      slidesPerView: 0,
      count: 1,
    }
  },
  computed: {},
  watch: {
    brands: {
      handler: function () {
        this.$refs['brand-tab'].$swiper.params.slidesPerView = this.responsive()
      },
      deep: true,
    },
  },
  async created() {
    this.$nextTick(() => {})
  },
  mounted() {
    this.$nextTick(() => {})
    this.onSlide()
    let elem = document.querySelector('#brand-tab-swiper > .swiper-wrapper')
    elem.style.width = elem.clientWidth / 7 + 'px'
    if (this.$utils.isObjectValid(this.brands)) {
      this.$refs['brand-tab'].$swiper.params.slidesPerView = this.responsive()
    }
    console.log('brands-img', this.brands)
  },
  methods: {
    redirect(item) {
      console.log('item', item)
      this.$router.push({ path: this.$i18n.path(`brand/${item.brandName}`) })
    },
    responsive() {
      let slidesPerView = 0
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth < 767) {
        slidesPerView = 1
      } else if (windowWidth > 767 && windowWidth < 1199) {
        slidesPerView = 3
      } else {
        slidesPerView = 6
      }
      return slidesPerView
    },
    onSlide() {
      this.isActivePrev =
        this.$refs['brand-tab'].$swiper.activeIndex === 0 ? false : true
      this.isActiveNext =
        this.$refs['brand-tab'].$swiper.activeIndex ===
        this.brands.length - this.responsive()
          ? false
          : true
    },
    next() {
      this.$refs['brand-tab'].$swiper.slideNext()
    },
    prev() {
      this.$refs['brand-tab'].$swiper.slidePrev()
    },
    async onActiveBrand(brandID) {
      this.$emit('onActiveBrand', brandID)
    },
  },
}
