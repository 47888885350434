
import ConfirmRemoveModal from '@/components/modal/confirmRemoveCostCenter.vue'
import asideComponent from '@/components/template/aside-user.vue'
import InputText from '@/components/share/input-text.vue'
import SearchSelect from '@/components/share/search-select.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'CostcenterEdit',
  components: {
    'aside-component': asideComponent,
    'input-text': InputText,
    'search-select': SearchSelect,
    ConfirmRemoveModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showConfirmModal: false,
      isDrop_Error: false,
      isCheckbox_Error: false,
      isSuccess: false,
      taxAddress: [],
      filter: '',
      isstatus: true,
      info: {
        company_code: '',
        accounts_id: '',
        name: {
          th: '',
          en: '',
        },
      },
      costCenter: {
        id: '',
        company_id: '',
        department_id: '',
        code: '',
        name: {
          th: '',
          en: '',
        },
        taxAddress: {
          value: '',
          text: '',
        },
        status: '',
        account_id: '',
        shippings: [],
        invoices: [],
        system: 'Online-Epro',
      },
      department: {
        code: '',
        name: {
          th: '',
          en: '',
        },
      },
      temp_shippings: [],
      shippings: [],
      fields: [
        {
          key: 'selectCheckbox',
          label: '',
          class: 'text-center',
          thClass: 'w-30-px',
          sortable: false,
        },
        // {
        //   key: 'shippingid',
        //   label: 'รหัส',
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },
        {
          key: 'contact',
          label: this.$i18n.t(`company-setting-costcenter-edit-id.contact`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'shipPhone',
          label: this.$i18n.t(`company-setting-costcenter-edit-id.shipPhone`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'shipAddress',
          label: this.$i18n.t(`company-setting-costcenter-edit-id.shipAddress`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  watch: {},
  mounted() {
    this.getCompaniesInfoById()
  },
  created() {
    this.dept = {
      isActive: true,
    }
  },
  methods: {
    onConfirmRemoveModal() {
      this.showConfirmModal = true
      console.log('delectId')
    },
    validateCostcenterSubmit() {
      this.$refs.updateCostcenterForm.validate().then((result) => {
        if (result && this.costCenter.shippings.length > 0) {
          if (!this.isstatus) {
            this.onConfirmRemoveModal()
          } else {
            this.updateCostCenterById()
          }
        } else {
          if (this.costCenter.shippings.length === 0) {
            this.isCheckbox_Error = true
          }
          // if (this.costCenter.taxAddress !== '') {
          //   this.isDrop_Error = true
          // }
        }
      })
    },
    onSelectCheckbox(value, object) {
      if (value) {
        this.costCenter.shippings.push({ id: object.id })
      } else {
        this.costCenter.shippings.splice(
          this.costCenter.shippings.findIndex((e) => e !== object.id),
          1
        )
      }
    },
    onCancel() {
      if (this.userProfile.company_level === 3) {
        this.$router.push({
          path: this.$i18n.path('company/setting'),
        })
      }
      if (this.userProfile.company_level === 2) {
        this.$router.push({
          path: this.$i18n.path('company/setting/costcenter'),
        })
      }
    },
    async getCompaniesInfoById() {
      await this.$profileProvider
        .getCompaniesDataById(this.userProfile.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(data)
              this.info = {
                company_code: data.company_code,
                name: data.name,
                account_id: data.accounts.find((a) => a.is_default === true).id,
                offline_id: data.account_id_default,
              }
              if (
                this.info.account_id !== undefined &&
                this.info.account_id !== ''
              ) {
                if (this.userProfile.company_level === 3) {
                  this.getDepartmentById()
                }
                this.getCoscenterById()
                data.accounts.forEach((a) => {
                  this.getAllInvoiceById(a)
                })
              }
              console.log('getCompaniesInfoById', this.info)
            }
          },
          (error) => {
            console.log('getCompaniesInfoById', error.responsed)
          }
        )
    },
    async getCoscenterById() {
      await this.$profileProvider
        .getCoscenterDataById(this.userProfile.id, this.$route.params.id)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data

              data.shippings.forEach((e) => {
                if (e.is_cost_ship) {
                  this.temp_shippings.push({
                    id: e.id,
                  })
                }
                let temp_phone = []
                let temp = ''
                let phone_ext = ''
                if (e.phone !== undefined && e.phone.length > 0) {
                  e.phone.forEach((a) => {
                    if (a.type === 'Phone') {
                      phone_ext =
                        a.extension !== ''
                          ? a.value + ' #' + a.extension
                          : a.value
                      temp_phone.push({
                        value: phone_ext,
                      })
                    }
                    if (a.type === 'Mobile') {
                      temp_phone.push({ value: a.value })
                    }
                  })
                  temp = temp_phone.map((item) => item.value).toString()
                  console.log('object', temp)
                }
                this.shippings.push({
                  id: e.id,
                  shippingid: e.offline_id,
                  contact: e.contact,
                  shipAddress: e.ship_address,
                  shipPhone: temp,
                  checkbox: e.is_cost_ship,
                })
              })
              console.warn('object :>> ', data.invoice)
              this.costCenter = {
                id: data.id,
                code: data.code,
                name: data.name,
                shippings: this.temp_shippings,
                account_id: data.account_id,
                taxAddress: {
                  value: data.invoice?.id ?? '',
                  text: data.invoice?.invoice_address ?? '',
                },
                // invoice: { id: data.invoice.account_id },
              }
            }
            console.log('costcenter', this.costCenter)
          },
          (error) => {
            console.log('getCoscenterById', error.responsed)
          }
        )
    },
    async getDepartmentById() {
      await this.$profileProvider
        .getDepartmentDataById(this.userProfile.id, this.$route.query.dept)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data.data_level_three.items
              if (data != undefined && data.length) {
                this.department = {
                  code: data[0].code,
                  name: data[0].name,
                }
                console.log('department', this.department)
              }
            }
          },
          (error) => {
            console.log('getDepartmentById', error.responsed)
          }
        )
    },
    async updateCostCenterById() {
      this.costCenter.company_id = this.userProfile.id
      this.costCenter.status = this.isstatus ? 'Active' : 'Inactive'
      this.costCenter.invoices = []
      this.costCenter.invoices.push({
        id: this.costCenter.taxAddress.value,
      })
      await this.$profileProvider
        .updateCostCenterDataById(this.userProfile.id, this.costCenter)
        .then(
          (result) => {
            if (result.status === 204) {
              console.log('return', 'omg')
              this.isSuccess = true
            }
          },
          (error) => {
            console.log('updateCostCenterById', error.responsed)
          }
        )
      console.log('object :>> ', this.costCenter)
    },
    async getAllInvoiceById(account) {
      await this.$profileProvider.getAllInvoiceDataById(account.id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('invoice', data)
            data.items.forEach((e) => {
              this.taxAddress.push({
                account_id: account.id,
                value: e.invoiceid,
                text:
                  e.invoiceAddress2 +
                  ' ' +
                  e.invoiceAddress3 +
                  ' ' +
                  e.invoiceAddress4,
              })
            })
          }
        },
        (error) => {
          console.log('getAllInvoiceById', error.responsed)
        }
      )
    },
    onSelectInvoice(val) {
      this.costCenter.account_id = val.id
      this.costCenter.taxAddress = val
      this.getAllShippingById(val.id)
      this.costCenter.shippings = []
    },
    async getAllShippingById(account_id) {
      this.shippings = []
      await this.$profileProvider.getAllShippingDataById(account_id).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('shipping', data)
            data.items.forEach((e) => {
              let temp_phone = []
              let temp = ''
              let phone_ext = ''
              if (e.phones.length > 0) {
                e.phones.forEach((a) => {
                  if (a.type === 'Phone') {
                    phone_ext =
                      a.extension !== ''
                        ? a.value + ' #' + a.extension
                        : a.value
                    temp_phone.push({
                      value: phone_ext,
                    })
                  }
                  if (a.type === 'Mobile') {
                    temp_phone.push({ value: a.value })
                  }
                })

                temp = temp_phone.map((item) => item.value).toString()
                console.log('object', temp)
              }
              this.shippings.push({
                id: e.shippingid,
                contact: e.contact,
                shippingid: e.offlineid,
                shipAddress: e.shipAddress,
                shipPhone: temp,
                checkbox: false,
              })
            })
          }
        },
        (error) => {
          console.log('getAllShippingById', error.responsed)
        }
      )
    },
  },
}
