
import { mapGetters } from 'vuex'

export default {
  name: 'TableProduct',
  props: {
    items: { type: Array, default: () => [] },
    totalrecords: { type: Number, default: 0 },
    filter: { type: String, default: '' },
    perpage: { type: Number, default: 0 },
    totalrows: { type: Number, default: 0 },
    isreference: { type: Boolean, default: false },
  },
  data() {
    return {
      itemCount: 0,
      totalRows: 1,
      currentPage: 1,
      // perPage: 25,
      fields: [
        {
          key: 'productImage',
          label: '',
          class: 'text-center fs-12 w-90 border-right',
          sortable: false,
          stickyColumn: true,
        },
        {
          key: 'productId',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'reference_code',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'productName',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 border-right',
          sortable: false,
        },
        {
          key: 'price',
          label: '',
          thClass: '',
          tdClass: 'text-left fs-14 w-160',
          sortable: false,
        },

        {
          key: 'action',
          label: '',
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perpage + 1
      if (this.currentPage === Math.ceil(this.totalrecords / this.perpage)) {
        last = this.totalrecords
      } else {
        last = this.currentPage * this.perpage
      }
      if (this.totalrecords === 0) {
        first = 0
        last = 0
      } else if (this.totalrecords < this.perpage) {
        last = this.totalrecords
      }
      return first + '-' + last
    },
  },
  watch: {},
  created() {},
  methods: {
    onEdit(productId) {
      this.$router.push({
        path: this.$i18n.path('company/products/edit/' + productId),
      })
    },
    async onDelete(productId) {
      this.$emit('remove', productId)
    },
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
    },
    Preview() {
      this.$nextTick(() => {
        // this.currentPage === Math.ceil(this.totalrecords / this.perpage)
        //   ? ''
        //   : this.currentPage++
        if (this.currentPage !== Math.ceil(this.totalrecords / this.perpage))
          this.currentPage++
        this.$emit('getCompaniesProductskus', this.currentPage)
      })
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
  },
}
