
export default {
  name: 'CountdownComponent',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    end: {
      type: String,
      default: '',
    },
    currentDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateEnd: '',
      countdownDate: {
        days: 0,
        hrs: 0,
        mins: 0,
        secs: 0,
      },
      refreshIntervalId: '',
      remaining: '',
      showCountdown: true,
      userAgent: false,
    }
  },
  computed: {},
  watch: {
    currentDate: {
      handler: function (val) {
        this.remaining = new Date(val).getTime()
      },
      immediate: true,
    },
  },
  created() {
    if (this.$store.state.user.profileType !== 'eprocurement') {
      this.onSetTime()
      this.refreshIntervalId = setInterval(this.onSetTime, 1000)
    }
    // this.userAgent = navigator.userAgent.includes('Chrome-Lighthouse')
  },
  mounted() {
    // const userAgent = navigator.userAgent.includes('Chrome-Lighthouse')
    if (this.userAgent) {
      this.showCountdown = false
    }
    // }
  },
  beforeDestroy() {},
  methods: {
    convertDigit(number) {
      return number < 10 ? '0' + number : number
    },
    onSetTime() {
      // Time remaining
      let diff = new Date(this.end).getTime() - this.remaining

      let days = diff <= 0 ? 0 : (diff / 8.64e7) | 0
      let hrs = diff <= 0 ? 0 : ((diff % 8.64e7) / 3.6e6) | 0
      let mins = diff <= 0 ? 0 : ((diff % 3.6e6) / 6e4) | 0
      let secs = diff <= 0 ? 0 : ((diff % 6e4) / 1e3) | 0

      this.countdownDate.days = days
      this.countdownDate.hrs = this.convertDigit(hrs)
      this.countdownDate.mins = this.convertDigit(mins)
      this.countdownDate.secs = this.convertDigit(secs)

      this.remaining += 1000

      if (diff <= 0) {
        this.$emit('onFlashsaleTimeout', false)
        clearInterval(this.refreshIntervalId)
      }
    },
  },
}
